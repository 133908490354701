// Replaced these polyfills with Polyfill.io
// import "core-js/features/map"
// import "core-js/features/object/assign"
// import "core-js/features/promise"
// import "core-js/features/set"
// import "raf/polyfill"
// https://hackernoon.com/polyfills-everything-you-ever-wanted-to-know-or-maybe-a-bit-less-7c8de164e423

import React from "react"
import { render } from "react-dom"
import App from "components/App"
import initSentry from "services/initSentry"

require.context("../images/meta", true)

initSentry({
  debug: debugUI,
  tags: {
    current_brand: window.globals.brand.id,
    app: "portal",
  },
})

// Raven.context(() => {
if (document.getElementById("root")) {
  render(React.createElement(App), document.getElementById("root"))
}
// })

if ("ontouchstart" in document) {
  document.querySelector("body")!.classList.remove("no-touch")
}
