import * as React from "react"
import * as cx from "classnames"

const sizes = {
  element: 3,
  page: 6,
}

export type Size = number | keyof typeof sizes

export interface Props {
  size?: Size
  className?: string
  isSingleCheckout?: boolean
}

// TODO: Replace this with something great
const Spinner: React.StatelessComponent<Props> = ({ size, className, isSingleCheckout }) => {
  const sizeNumber = typeof size === "number" ? size : sizes[size || "page"]
  return (
    <div
      className={cx("d-flex", "justify-content-center", "float-in", className, {
        "mt-5": size === "page",
        "mb-3": size === "page",
        "my-3": size === "element",
      })}
      style={{height: "100%"}}
    >
      <div
        className={cx("spinner-border", { "text-primary": !isSingleCheckout })}
        style={{
          width: `${sizeNumber}rem`,
          height: `${sizeNumber}rem`,
          borderWidth: `${sizeNumber}px`,
          display: "block",
          position: "fixed",
          top: "5%"
        }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default Spinner
