import { Badge, BrandData } from "themes/base"
import akt from "themes/akt"
import bft from "themes/bft"
import clubpilates from "themes/clubpilates"
import clubpilates_aus from "themes/clubpilates_aus"
import clubpilates_uk from "themes/clubpilates_uk"
import cyclebar from "themes/cyclebar"
import cyclebar_aus from "themes/cyclebar_aus"
import kinrgy from "themes/kinrgy"
import purebarre from "themes/purebarre"
import rowhouse from "themes/rowhouse"
import rumble from "themes/rumble"
import rumble_aus from "themes/rumble_aus"
import stretchlab from "themes/stretchlab"
import stretchlab_aus from "themes/stretchlab_aus"
import stride from "themes/stride"
import testbrand from "themes/testbrand"
import xponential from "themes/xponential"
import yogasix from "themes/yogasix"

const brands: Record<string, BrandData> = {
  akt,
  bft,
  clubpilates,
  clubpilates_aus,
  clubpilates_uk,
  cyclebar,
  cyclebar_aus,
  kinrgy,
  purebarre,
  rowhouse,
  rumble,
  rumble_aus,
  stretchlab,
  stretchlab_aus,
  stride,
  testbrand,
  xponential,
  yogasix,
}

export { Badge, BrandData, brands }
