import React from "react"
import { observer } from "mobx-react"
import cx from "classnames"
import CountUp from "react-countup"

import Svg, { SvgProps } from "components/Svg"
import { easeInCubic } from "helpers/easings"

import { Metric } from "apps/history/models/Stats"
import { formatDuration } from "helpers/stringHelper"
import { formatNumber } from "helpers/numberFormatter"

// A slightly revised version of SummaryStat
// the idea was to be able to pass in a metric so that we can keep the names
// consistent more easily. Haven't actually done that anywhere.

interface Props {
  icon?: SvgProps

  metric?: Metric
  label?: string
  values: number[]
  iconSize?: string | number
  hideIcon?: boolean
  appleWatchIcon?: string

  className?: string
  iconClassName?: string
  statWrapperClassName?: string
  labelClassName?: string
  valueClassName?: string
  alwaysShowAppleWatchIcon?: boolean
}

@observer
export default class SummaryStat extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    iconClassName: "col-4 p-0 m-0",
    statWrapperClassName: "col-8 p-0 m-0",
    labelClassName: "h5 mb-0",
    valueClassName: "h5 text-primary mb-0",
  }

  render() {
    const {
      metric,
      iconSize,
      values,
      className,
      iconClassName,
      statWrapperClassName,
      labelClassName,
      valueClassName,
      hideIcon,
      appleWatchIcon,
      alwaysShowAppleWatchIcon,
    } = this.props

    const label = this.props.label || (metric && metric.label)
    // i h8js
    const icon =
      this.props.icon ||
      (metric
        ? { name: metric.logo, size: iconSize || metric.logoSize }
        : undefined)

    const isValid = values.length > 0 && values.some(item => item)
    const format = metric ? metric.format : formatNumber
    const { decimalPlaces = undefined, suffix = undefined } =
      format && format.options ? format.options : {}

    const appleWatchIconBlock = appleWatchIcon && (
      <img
        src={appleWatchIcon}
        alt="Apple watch icon"
        style={{ paddingRight: "6px" }}
      />
    )
    let inner
    if (isValid) {
      inner = (
        <div className={statWrapperClassName}>
          <div className={labelClassName}>{label}</div>
          <div className={cx(valueClassName, "text-nowrap", "text-nocase")}>
            {appleWatchIconBlock}
            {values.map((v, i) => (
              <span key={`${label}-${i}`}>
                {i !== 0 && "/"}
                <CountUp
                  key={label}
                  end={v}
                  duration={1.25}
                  suffix={suffix}
                  easingFn={easeInCubic}
                  decimals={decimalPlaces}
                  formattingFn={
                    metric && metric.format ? metric.format : formatNumber
                  }
                />
              </span>
            ))}
          </div>
        </div>
      )
    } else if (
      !isValid &&
      alwaysShowAppleWatchIcon &&
      metric!.key === "avgHr"
    ) {
      inner = (
        <div className={statWrapperClassName}>
          <div className={labelClassName}>{label}</div>
          {appleWatchIconBlock}
        </div>
      )
    } else {
      inner = (
        <div className={statWrapperClassName}>
          <div className={labelClassName}>{label}</div>
          <div className="h5 text-primary mb-0">
            <div style={{ fontSize: "0.6em" }}>No data available</div>
          </div>
        </div>
      )
    }

    if (!!icon && !hideIcon) {
      return (
        <div className={cx("row", className, {})}>
          <div
            className={cx(
              "d-flex",
              "align-items-center",
              "justify-content-center",
              iconClassName
            )}
          >
            <Svg {...icon} />
          </div>

          {inner}
        </div>
      )
    } else {
      return inner
    }
  }
}
