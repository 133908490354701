import { JsonObject, JsonProperty } from "json2typescript"
import ServiceDuration from "apps/book/models/ServiceDuration"
import InstructorSummary from "models/InstructorSummary"
import ServiceTypeSummary from "apps/book/models/ServiceTypeSummary"

@JsonObject("ServiceType")
export default class ServiceType extends ServiceTypeSummary {
  /**
   * ClubReady `sessionSizes`
   */
  @JsonProperty("durations", [ServiceDuration])
  durations: ServiceDuration[] = []

  /**
   * Lists the instructors that offer this service at this location. Hopefully
   * going away once CR's APIs are updated.
   */
  @JsonProperty("instructors", [InstructorSummary], true)
  instructors?: InstructorSummary[] = []
}
