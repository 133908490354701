import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import { isApiError } from "helpers/errorHandling"
import PlanV2Store, { Response } from "apps/buy/stores/PlanV2Store"
import PackagePlan from "models/PackagePlan"

// a clone of PlanV2Store for trying codes
export default class PromoCodeV2Store extends PlanV2Store {
  @computed
  get plan() {
    return this.planStore.plan!
  }
  set plan(plan: PackagePlan) {
    this.planStore.plan = plan
  }

  @computed
  get promoCode() {
    return this.planStore.promoCode
  }
  set promoCode(promoCode: string | undefined) {
    this.planStore.promoCode = promoCode
  }

  constructor(public planStore: PlanV2Store) {
    super(planStore.brandStore, planStore.packageId)
    this.status = planStore.status
    this.package = planStore.package
  }

  @action.bound
  addCode(code: string) {
    this.planStore.brandStore!.track.event("purchase_add promo code")
    this.updateCode(code)
  }

  @action.bound
  removeCode() {
    this.promoCode = undefined
    this.planStore.plan = this.planStore.originalPlan
    this.planStore.errorCode = ''
    this.fetch()
  }

  @action.bound
  handleCodeError(ex: AxiosError) {
    this.planStore.brandStore!.track.event("promo code_failure")
    if (!isApiError(ex.response) || ex.response.data.code !== "invalid_code") {
      throw ex
    }
  }
}
