import * as React from "react"
import ReactTooltip from "react-tooltip"
import { InjectedFormikProps, withFormik, Form } from "formik"
import * as yup from "yup"
import omitBy from "lodash/omitBy"

import { observer, inject } from "mobx-react"

import UserProfile, { userProfileFields } from "models/UserProfile"

import BrandStore from "stores/BrandStore"
import ProfileStore from "apps/account/stores/ProfileStore"
import ProfileUpdateStore from "apps/account/stores/ProfileUpdateStore"
import FormButtons from "components/forms/FormButtons"
import FormInput from "components/forms/FormInput"
import FormSelect from "components/forms/FormSelect"
import FormControl from "components/forms/FormControl"
import FormAlert from "components/forms/FormAlert"
import FormDatePicker from "components/forms/FormDatePicker"
import {
  birthDateValidator,
  phoneNumberValidator,
  nameValidator,
  stateValidator,
  zipValidator,
} from "helpers/validations"
import { withAxiosErrorMessage } from "helpers/errorHandling"
import shoeSizes from "helpers/shoeSizes"

type FormValues = Pick<
  UserProfile,
  Exclude<keyof UserProfile, "height" | "hideMetrics">
> & {
  // heightFeet?: number
  // heightInches?: number
  showMetrics?: boolean
}

export interface Props {
  profileStore: ProfileStore
  profileUpdateStore: ProfileUpdateStore
}

@observer
class InnerForm extends React.Component<
  InjectedFormikProps<Props, FormValues>
> {
  public render() {
    const { touched, errors, isSubmitting } = this.props
    const base = "base"
    const { settings, copy, isXponential } = this.props.profileStore.brandStore

    return (
      <Form noValidate className="float-in delay-3">
        {/* TODO: Deal with this errors["base"] -> errors.base with proper typing. */}
        <FormAlert message={errors[base]} />

        <div className="row">
          <FormInput
            label="First Name"
            name="firstName"
            autoComplete="given-name"
            className="col-lg-6 required"
            tooltipId={!isXponential ? "name-tooltip" : undefined}
            maxLength={50}
            disabled={!isXponential}
          />
          <FormInput
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            className="col-lg-6 required"
            tooltipId={!isXponential ? "name-tooltip" : undefined}
            maxLength={50}
            disabled={!isXponential}
          />
          {!isXponential && (
          <ReactTooltip
            id="name-tooltip"
            type="info"
            effect="solid"
            place="right"
            resizeHide={false}
          >
            <span className="tooltip-message">
              Contact your home studio to change your name
            </span>
          </ReactTooltip>
          )}
        </div>

        <div className="row">
          <FormInput
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            className="col-lg-6 required"
            disabled
          />
          <FormInput
            label="Mobile Phone"
            name="phoneMain"
            placeholder={copy.phonePlaceholder}
            autoComplete="mobile tel-national"
            className="col-lg-6 required"
            maxLength={20}
          />
        </div>
        {!isXponential &&
          <>
            <div className="row">
              <FormInput
                label="Address"
                name="address"
                placeholder="Street Address"
                autoComplete="address-line1"
                className="col"
                maxLength={95}
              />
            </div>
            <div className="row">
              <FormInput
                label="Address Line 2"
                name="address2"
                placeholder="Address Line 2"
                autoComplete="address-line2"
                className="col"
                maxLength={95}
              />
            </div>

            <div className="row">
              <FormInput
                label="City"
                placeholder="City"
                name="city"
                autoComplete="address-level2"
                className="col-lg-4"
                maxLength={35}
              />
              <FormInput
                label="State/Province"
                placeholder="State/Province"
                name="state"
                autoComplete="address-level1"
                className="col-lg-4"
                maxLength={2}
              />
              <FormInput
                label={copy.zipLabel}
                placeholder={copy.zipLabel}
                name="zip"
                autoComplete="postal-code"
                className="col-lg-4"
                maxLength={10}
              />
            </div>
          </>
        }
        {!isXponential &&
          <hr />
        }
        <div className="row">
          <FormSelect
                label="Gender"
                name="gender"
                autoComplete="sex"
                className="col-lg-6"
          >
            <option value="">Not Specified</option>
            <option value="m">Male</option>
            <option value="f">Female</option>
          </FormSelect>
          {!isXponential ?
            <FormDatePicker
              label="Birthday"
              name="birthDate"
              autoComplete="bday"
              className="col-lg-6"
            />
          :
            <FormInput
              label="Birthday"
              placeholder="MM-DD-YYYY"
              name="birthDate"
              autoComplete="bday"
              className="col-lg-6"
              type="date"
            />
          }

        </div>
        {isXponential &&
         <div className="row">
            <FormInput
              label={copy.zipLabel}
              placeholder={copy.zipLabel}
              name="zip"
              autoComplete="postal-code"
              className="col-lg-6 required"
              maxLength={10}
              required={true}
            />
          </div>
        }
        {(settings.hasWeight || settings.hasShoeSize) && (
          <div className="row">
            {settings.hasWeight && (
              <FormInput
                label="Weight"
                name="weight"
                placeholder={copy.weightUnit}
                autoComplete="weight"
                className="col-lg-6"
                type="number"
              />
            )}
            {settings.hasShoeSize && (
              <FormSelect
                label="Shoe Size"
                name="shoeSize"
                placeholder="Size"
                autoComplete="shoe-size"
                className="col-lg-6"
              >
                <option value="">Not Specified</option>
                {shoeSizes.map(([value, label]) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </FormSelect>
            )}
          </div>
        )}
        {settings.hasLeaderboard && (
          <div className="row">
            <FormInput
              label="Screen Name"
              name="screenName"
              className="col-lg-6"
              maxLength={50}
            />
            <FormControl
              label="&nbsp;"
              name="showMetrics"
              className="col-lg-6 d-flex flex-column"
            >
              {({ id, field }) => (
                <div className="custom-control custom-checkbox flex-grow-1 d-flex align-items-center">
                  <input
                    {...field}
                    id={id}
                    className="custom-control-input"
                    name="showMetrics"
                    type="checkbox"
                    value="true"
                    checked={field.value}
                  />
                  <label className="custom-control-label" htmlFor={id}>
                    Show screen name on leaderboard during {copy.class}.
                  </label>
                </div>
              )}
            </FormControl>
          </div>
        )}

        <hr />
        {!isXponential &&
          <div className="row">
            <FormInput
              label="Emergency Contact Name"
              name="emergencyName"
              className="col-lg-6"
              maxLength={80}
            />
            <FormInput
              label="Emergency Contact Phone"
              name="emergencyPhone"
              className="col-lg-6"
              placeholder={copy.phonePlaceholder}
              maxLength={20}
            />
          </div>
        }
        {/* <FormInput label="Home Phone" name="phoneHome" className="col-lg-6" />
          <FormInput label="Work Phone" name="phoneWork" className="col-lg-6" /> */}
        <FormButtons
          submitText={`${copy.saveChanges}`}
          disabled={isSubmitting}
        />
      </Form>
    )
  }
}

const schema = yup.object<FormValues>().shape({
  // TODO: might make these more parameterizable
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  phoneMain: phoneNumberValidator.required().label("Mobile Phone"),
  emergencyPhone: phoneNumberValidator.label("Emergency Contact Phone"),
  weight: yup
    .number()
    .label("Weight")
    .nullable(true),
  // heightFeet: yup.number().nullable(true),
  // heightInches: yup.number().nullable(true),
  shoeSize: yup
    .string()
    .label("Shoe Size")
    .nullable(true),
  screenName: yup.string().label("Screen Name"),
  showMetrics: yup.boolean(),
  birthDate: birthDateValidator.required().label("Date of Birth"),
  state: stateValidator.label("State/Province").nullable(true),
})

const xpassSchema = yup.object<FormValues>().shape({
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  phoneMain: phoneNumberValidator.required().label("Mobile Phone"),
  emergencyPhone: phoneNumberValidator.label("Emergency Contact Phone"),
  weight: yup
    .number()
    .label("Weight")
    .nullable(true),
  shoeSize: yup
    .string()
    .label("Shoe Size")
    .nullable(true),
  screenName: yup.string().label("Screen Name"),
  showMetrics: yup.boolean(),
  birthDate: birthDateValidator.required().label("Date of Birth"),
  state: stateValidator.label("State/Province").nullable(true),
  zip: zipValidator.required().label("Zip Code"),
})

const ProfileForm = inject((store?: BrandStore) => ({ store }))(
  observer(
    withFormik<Props & { store?: BrandStore }, FormValues>({
      mapPropsToValues: ({ store, profileStore }) => {
        const user = store!.userStore.session!
        const profile = profileStore.profile || {}

        const compactedProfile = omitBy(profile, v => v == null)

        return {
          ...userProfileFields,
          ...compactedProfile,
          email: user.email,
          showMetrics: !compactedProfile.hideMetrics,
        }
      },
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: (props: Props) => (
        props.profileStore.brandStore.isXponential ? xpassSchema : schema
      ),
      handleSubmit: (values, formikBag) => {
        const { profileUpdateStore, store } = formikBag.props

        const { firstName, lastName, showMetrics, ...formValues } = values // Omit firstName and lastName

        const compiledValues = (omitBy(
          {
            ...(store!.isXponential ? { firstName, lastName } : {}),
            ...formValues,
            hideMetrics: !showMetrics,
          },
          v => v == null || v === ""
        ) as unknown) as UserProfile

        profileUpdateStore
          .update(compiledValues)
          .then(res => {
            formikBag.setSubmitting(false)
            if (res.data.errors) {
              formikBag.setErrors(res.data.errors)
              store!.track.event("profile update_failure")
            } else {
              store!.track.event("profile update_success")
            }

            // Update session
            const profileKeys = Object.keys(
              profileUpdateStore.profileStore.profile!
            )
            profileKeys.map(k => {
              if (store!.userStore.session![k] !== undefined) {
                store!.userStore.session![
                  k
                ] = profileUpdateStore.profileStore.profile![k]
              }
            })

            return res
          })
          .catch(ex => {
            formikBag.setSubmitting(false)

            const error = withAxiosErrorMessage(ex)
            if (error) {
              // TODO: should really get rid of this errors.base nonsense
              formikBag.setErrors({ base: error.data.message } as any)
              store!.track.event("profile update_failure")
            } else {
              store!.uiStore.openError(ex)
              store!.track.event("profile update_failure")
              throw ex
            }
          })
        store!.track.event("profile update_submit")
      },
    })(InnerForm)
  )
)

export default ProfileForm

// height stuff, which if we ever reintroduce it should be a custom input
/* <FormControl name="height" label="Height" className="col-lg-6">
    {({ id, field }) => (
      <div className="row">
        <FormSelect name="heightFeet" className="col-lg-6">
          <option value="">Feet</option>
          {range(2, 9).map(feet => (
            <option key={`heightFeet:${feet}`} value={feet}>
              {feet}'
            </option>
          ))}
        </FormSelect>
        <FormSelect name="heightInches" className="col-lg-6">
          <option value="">Inches</option>
          {range(0, 12).map(inches => (
            <option key={`heightInches:${inches}`} value={inches}>
              {inches}"
            </option>
          ))}
        </FormSelect>
      </div>
    )}
  </FormControl> */
// const heightFeet = compactedProfile.height
//   ? Math.floor((compactedProfile.height as number) / 12)
//   : 0
// const heightInches = compactedProfile.height
//   ? (compactedProfile.height as number) - heightFeet * 12
//   : 0

// const height =
// parseInt((heightFeet || 0).toString(), 10) * 12 +
// parseInt((heightInches || 0).toString(), 0)
