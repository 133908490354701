import React from "react"
import { Link, withRouter, RouteComponentProps } from "react-router-dom"
import cx from "classnames"
import BrandStore from "stores/BrandStore"
import { observer, inject } from "mobx-react"

interface BreadcrumbLink {
  label: string
  url?: string
}

interface BreadcrumbsProps {
  store?: BrandStore
  className?: string
  forceBackURL?: string
  links?: BreadcrumbLink[]
  noBackLink?: boolean
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class Breadcrumbs extends React.Component<BreadcrumbsProps, {}> {
  handleBackClick = (e: LinkEvent) => {
    e.preventDefault()
    const { forceBackURL } = this.props
    if (forceBackURL) {
      if (forceBackURL.startsWith("http")) {
        document.location.href = forceBackURL
      } else {
        this.props.store!.routingStore.push(forceBackURL)
      }
    } else {
      this.props.store!.routingStore.goBack()
    }
  }

  public render() {
    const { className, links, noBackLink } = this.props
    if (this.props.store!.uiStore.nav.navLinksShow) {
      return null
    }

    return (
      <div className={cx("d-flex", "align-items-center", className)}>
        {!noBackLink && (
          <a
            className="breadcrumb-back pr-3 text-decoration-none text-dark"
            href="#back"
            onClick={this.handleBackClick}
          >
            &#8592; Back
          </a>
        )}
        {links &&
          links.map(({ label, url }) => {
            // const Tag = url ? "a" : "Link"
            const props = {
              key: `Breadcrumb:${label.replace(/\s*/g, "")}`,
              className:
                "border-left border-dark px-3 font-weight-bold text-dark text-decoration-none d-none d-md-block",
              to: url ? url : "",
              children: label,
            }

            return url ? <Link {...props} /> : <span {...props} />
          })}
      </div>
    )
  }
}
