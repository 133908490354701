import * as React from "react"

export interface Props {
  closeBanner?: Function
  clickBanner?: Function
  style?: string
  bannerMessage?: string
}

const FreeClassBanner = (props: Props) => {
  const { clickBanner, closeBanner, bannerMessage, style } = props

  if (!bannerMessage) return null

  return (
    <div className={style || "free-class-banner"}>
      <div
        className="py-3 py-md-0 d-md-flex text-center align-items-center justify-content-center small"
        onClick={() => clickBanner ? clickBanner() : undefined}
      >
        <div className="position-relative w-100 px-4">
          {bannerMessage}
          <div
            className="free-class-banner--close-btn"
            onClickCapture={(e) => closeBanner ? closeBanner(e) : undefined}
          />
        </div>
      </div>
    </div>
  )
}

export default FreeClassBanner
