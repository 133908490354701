import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("HeroImage")
export class HeroImage {
  @JsonProperty("url", String)
  url: string = undefined!
  @JsonProperty("id", Number)
  id: number = undefined!
}

@JsonObject("Deal")
export default class Deal {

  @JsonProperty("id", Number)
  id: number = undefined!

  @JsonProperty("title", String)
  title: string = undefined!
  
  @JsonProperty("description", String)
  description: string = undefined!

  @JsonProperty("end_date", String)
  endDate: string = undefined!
  
  @JsonProperty("start_date", String)
  startDate: string = undefined!

  @JsonProperty("partner_name", String)
  partnerName: string = undefined!

  @JsonProperty("partner_logo", String)
  partnerLogo: string = undefined!

  @JsonProperty("partner_url", String)
  partnerUrl: string = undefined!

  @JsonProperty("eligible_streak_count", Number)
  eligibleStreakCount: number = undefined!

  @JsonProperty("redeemable", Boolean, true)
  redeemable?: boolean = false

  @JsonProperty("coupon_code", String, true)
  couponCode?: string = undefined!

  @JsonProperty("terms", String, true)
  terms?: string = undefined!

  @JsonProperty("daystab_thumbnail_image", String, true)
  daystabThumbnailImage?: string = undefined!

  @JsonProperty("featured_thumbnail_image", String, true)
  featuredThumbnailImage?: string = undefined!

  @JsonProperty("hero_images", [HeroImage], true)
  heroImages?: HeroImage[] = []

  @JsonProperty("redemption_method", String, true)
  redemptionMethod?: string = undefined!

  @JsonProperty("qr_code_image", String, true)
  qrCodeImage?: string = undefined!

  @JsonProperty("url", String, true)
  url?: string = undefined!

}
