import { Middleware } from "services/Middleware"
import UserStore from "stores/UserStore"
import { isAuthError } from "helpers/errorHandling"
import { AxiosError } from "axios"

export const authHeaderName = "X-Authorization"

export default function TokenAuthMiddleware(
  userStore: UserStore,
  signOutOn403: boolean = true
): Middleware {
  return (ctx, next) => {
    if (userStore.session) {
      if (!ctx.headers) ctx.headers = {}
      ctx.headers[authHeaderName] = `Bearer ${userStore.session.accessToken}`
    }
    return next().catch((ex: AxiosError) => {
      if (isAuthError(ex.response) && signOutOn403) {
        userStore.session = undefined
      }
      throw ex
    })
  }
}
