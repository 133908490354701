import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import Challenge from "apps/challenge/models/Challenge"
import LocationSummary from "models/LocationSummary"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export interface ChallengeResponse {
  challenges: Challenge[]
}

export default class ChallengeStore extends APIStore {
  @observable challenges: Challenge[] = []

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    TokenAuthMiddleware(this.brandStore.userStore),
    DeserializeMiddleware("challenges", Challenge),
  ])

  constructor(public brandStore: BrandStore) { super() }

  fetch(completed?: boolean) {
    return this.api.get(`/api/v2/locations/${ this.brandStore.locStore.currentLocationId!}/challenges`, { params: completed })
  }

  @action
  brandOptIn(challengeId: number) {
    return this.api.post(`/api/v3/challenges/${challengeId}/user_opt_ins`)
  }

  @action
  userOptIn() {
    return this.api.post(`/api/locations/${ this.brandStore.locStore.currentLocationId!}/user_challenges`)
  }

  @action
  optIn() {
    return this.api.post(`/api/v2/user_opt_ins`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<ChallengeResponse>) {
    this.challenges = res.data.challenges
    return res
  }
}
