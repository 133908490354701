import { observable, action } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import ScheduleEntry from "apps/book/models/xpass/ScheduleEntry"
import APIStore from "stores/APIStore"
import UserStore from "stores/UserStore"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export interface ScheduleEntryResponse {
  scheduleEntry?: ScheduleEntry
  upcomingClasses?: ScheduleEntry[]
}

export default class ScheduleEntryStore extends APIStore {
  @observable scheduleEntry?: ScheduleEntry
  @observable upcomingClasses?: ScheduleEntry[] = []

  constructor(protected userStore: UserStore) {
    super()
  }

  api = this.createClient<ScheduleEntryResponse>([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("schedule_entry", ScheduleEntry),
    DeserializeMiddleware("upcoming_classes", ScheduleEntry),
    TokenAuthMiddleware(this.userStore)
  ])

  fetch(id: string) {
    return this.api.get(`/api/xpass/v2/schedule_entries/${id}`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.scheduleEntry = res.data.schedule_entry
    this.upcomingClasses = res.data.upcoming_classes
    return res
  }
}