import { saveState, loadState, clearState } from "services/savedState"

const key = "lastPath"

export function setFriendlyRoute(imperativeRedirect?: string) {
  const value = imperativeRedirect
    ? imperativeRedirect
    : location.pathname + location.search

  saveState(key, value, true)
}

export function popFriendlyRoute(): string {
  const path = peekFriendlyRoute()
  clearState(key, true)

  return path
}

export function peekFriendlyRoute(): string {
  return loadState(key, true) || "/"
}

export function clearFriendlyRoute() {
  clearState(key, true)
}
