import * as React from "react"
import { RouteComponentProps, Switch, Route, Redirect } from "react-router"
// import RegistrationForm from "apps/auth/RegistrationForm"
import { observer, inject } from "mobx-react"

import { BrandCopy } from "themes/base"
import BrandStore from "stores/BrandStore"
import MembershipsPage from "apps/account/components/MembershipsPage"
import BillingPage from "apps/account/components/BillingPage"
import ProfilePage from "apps/account/components/ProfilePage"
import ClaimAccountsPage from "apps/account/components/xpass/ClaimAccountsPage"
import FavoritesPage from "apps/account/components/FavoritesPage"
import SubMenu from "components/SubMenu"
import LocationRoute from "components/wrappers/LocationRoute"
import MyTokensPage from "./xpass/MyTokens"

export interface Props {
  copy?: BrandCopy
  store?: BrandStore
}

// account pages for logged-in users
@inject((store: BrandStore) => ({ store }))
@observer
export default class AccountController extends React.Component<
  Props & RouteComponentProps
> {
  isXponential = this.props.store!.isXponential

  public render() {
    const links = [
      { children: this.isXponential ? "Purchases" : "Memberships", to: `/account/memberships` },
      { children: "Personal Details", to: `/account/profile` },
      { children: "Billing Details", to: `/account/billing` },
      ...(this.isXponential
        ? [
            { children: "Tokens", to: `/account/tokens`},
            { children: "Claim Accounts", to: `/account/claim` }
          ]
        : []),
      // { label: "Favorites", url: `/account/favorites` },
    ]

    return (
      <>
        <SubMenu className="row" linkClassName="h5" links={links} />

        <div className="row">
          <Switch>
            <LocationRoute
              path={`${this.props.match.path}/memberships`}
              locationInPath
              inComingSoon
              render={props => (
                <MembershipsPage {...props} store={this.props.store!} />
              )}
            />
            <LocationRoute
              path={`${this.props.match.path}/billing`}
              inComingSoon
              render={props => <BillingPage {...props} />}
            />
            <LocationRoute
              path={`${this.props.match.path}/profile`}
              inComingSoon
              render={props => <ProfilePage {...props} />}
            />
            {this.isXponential && (
              <LocationRoute
                path={`${this.props.match.path}/tokens`}
                render={props => (
                  <MyTokensPage
                    {...props}
                    store={this.props.store!}
                  />
                )}
              />
            )}
            {this.isXponential && (
              <LocationRoute
                path={`${this.props.match.path}/claim`}
                render={props => (
                  <ClaimAccountsPage
                    {...props}
                    store={this.props.store!}
                  />
                )}
              />
            )}
            {debugUI && (
              <LocationRoute
                path={`${this.props.match.path}/favorites`}
                render={props => <FavoritesPage {...props} />}
              />
            )}

            <Redirect
              path={this.props.match.path}
              to={`${this.props.match.path}/memberships`}
            />
          </Switch>
        </div>
      </>
    )
  }
}