import { observable, action, computed } from "mobx"
import LocationSummary from "models/LocationSummary"
import LocationsStore from "stores/LocationsStore"

// local store for the location finder modal
export default class LocationFinderStore {
  @observable highlightedLocationId: string = ""
  @observable hoveredLocationId: string = ""
  @observable homeLocationId: string = ""
  locationsStore: LocationsStore

  @computed
  get orderedLocations() {
    const hl = this.locationsStore.locations.find(
      ({ id }) => id === this.homeLocationId
    )

    let orderedLocations: LocationSummary[]
    if (hl) {
      orderedLocations = this.locationsStore.locations
        .slice()
        .sort((l1, l2) => {
          const d1 = Math.sqrt(
            Math.pow(hl!.lat - l1.lat, 2) + Math.pow(hl!.lng - l1.lng, 2)
          )
          const d2 = Math.sqrt(
            Math.pow(hl!.lat - l2.lat, 2) + Math.pow(hl!.lng - l2.lng, 2)
          )
          return d1 > d2 ? 1 : d1 < d2 ? -1 : 0
        })
    } else {
      orderedLocations = this.locationsStore.locations
    }
    return orderedLocations
  }

  constructor(locationsStore: LocationsStore) {
    this.locationsStore = locationsStore
  }

  @action
  setHighlightedLocationId(locationId: string) {
    this.highlightedLocationId = locationId
    this.hoveredLocationId = locationId
  }
}
