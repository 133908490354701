import React, { useState } from "react"
import cx from "classnames"
import * as moment from "moment"
import { Link } from "react-router-dom"
import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import RewardsStore from "apps/classpoints/stores/RewardsStore"

const UpcomingRewardsCard = ({store}: {store: BrandStore}) => {
  const [rewardsStore, setRewardsStore] = useState(new RewardsStore(store))

  return (
    <div className={cx("card", "ways-to-earn-card")}>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="text-dark">
          Rewards you can earn
        </h5>
      </div>
      <APILoader
        apiStore={rewardsStore}
        elementSize="element"
        render={() => {
          let { upcomingRewards, featuredReward } = rewardsStore
          let allRewards
          if (featuredReward) {
              // Make sure the featured reward isn't duplicated in the upcoming rewards
            allRewards = [featuredReward, ...upcomingRewards.filter(val => val.id !== featuredReward.id)]
          } else {
            allRewards = upcomingRewards
          }

          return (
            <div className="card-body d-flex flex-column p-0">
              {!!allRewards.length && (
                <ul className="list-group list-group-flush flex-grow-1 mb-0 rows-in pt-2">
                  {allRewards.map(entry => (
                    <li key={entry.id} className={cx("list-group-item d-flex align-items-center mx-3 px-0")}>
                      <div className="list-group-item__image">
                        <img src={entry.thumbnailImageUrl} />
                        <div className="list-group-item__image-overlay">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="white"/>
                            <path d="M12 14V15.3333M7.99996 18H16C16.7363 18 17.3333 17.403 17.3333 16.6667V12.6667C17.3333 11.9303 16.7363 11.3333 16 11.3333H7.99996C7.26358 11.3333 6.66663 11.9303 6.66663 12.6667V16.6667C6.66663 17.403 7.26358 18 7.99996 18ZM14.6666 11.3333V8.66667C14.6666 7.19391 13.4727 6 12 6C10.5272 6 9.33329 7.19391 9.33329 8.66667V11.3333H14.6666Z" stroke="#111827" strokeWidth="1.5" strokeLinecap="round"/>
                          </svg>
                        </div>
                      </div>
                      <div className="pl-3">
                        <div className="font-16 font-600">{entry.name}</div>
                        <div className="font-14 color-primary pt-1">{entry.shortDescription}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {!allRewards.length && (
                <div className="list-group list-group-flush flex-grow-1 mb-0 rows-in py-4 mx-3">
                  No upcoming rewards found.
                </div>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

export default UpcomingRewardsCard