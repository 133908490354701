import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action, computed } from "mobx"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import UserStore from "stores/UserStore"
import Goal, { GoalId } from "models/Goal"

export default class OptInStore extends APIStore {
  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    TokenAuthMiddleware(this.userStore),
  ])

  @observable optIns?: string[]

  constructor(protected userStore: UserStore) {
    super()
  }

  fetch() {
    return this.api.get(`/api/user_opt_ins`)
  }

  add(name: string) {
    return this.api.put(`/api/user_opt_ins/${name}`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<{ opt_ins: string[] }>) {
    this.optIns = res.data.opt_ins
    return res
  }
}
