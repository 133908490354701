import { Moment } from "moment"
import { JsonObject, JsonProperty } from "json2typescript"
import ServiceBookingReason from "apps/book/models/ServiceBookingReason"
import ServiceScheduleEntry from "apps/book/models/ServiceScheduleEntry"
import DateConverter from "utils/DateConverter"

@JsonObject("ServiceBookingStatus")
export default class ServiceBookingStatus {
  /**
   * Whether the current user can book this class, though it doesn't tell us much as to why.
   */
  @JsonProperty("can_book", Boolean)
  canBook: boolean = undefined!

  /**
   * Whether class credits are required to book this class
   */
  @JsonProperty("consumes_credit", Boolean)
  consumesCredit: boolean = undefined!

  /**
   * Status code, used to indicate
   * - whether the service is already booked
   * - whether a package is required to book
   * - whether the booking would violate cross-club booking rules
   */
  @JsonProperty("status", String)
  status:
    | "booked"
    | "needs_package"
    | "cross_club"
    | "unbooked"
    | "cannot_book" = undefined!

  /**
   * CR `reason`
   */
  @JsonProperty("reasons", [ServiceBookingReason])
  reasons: ServiceBookingReason[] = []

  @JsonProperty("schedule_entry", ServiceScheduleEntry)
  scheduleEntry: ServiceScheduleEntry = undefined!
}
