import React, { useEffect } from 'react'
import HomePromo from 'models/HomePromo'
import thirdPartyScript from 'helpers/thirdPartyScript'

type Props = {
  card: HomePromo
}

export default function GooglePromoCard(props: Props) {
  const { displayId, slotPath, width, height } = props.card

  useEffect(() => {
    thirdPartyScript({
        id: "google-tag-script",
        src: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
        charset: "utf-8",
      })

    googletag.cmd.push(function() { googletag.display(displayId) })

    const googleContainer = window.document.querySelector<HTMLElement>('.google-tag-container')
    const adjustAdStyle = () => {
      const iFrame = googleContainer!.querySelector('iframe')
      if (iFrame) {
        const iDoc = iFrame.contentDocument || iFrame.contentWindow!.document;
        const imgDiv = iDoc.querySelector<HTMLElement>('#google_image_div')
        const img = iDoc.querySelector<HTMLElement>('.img_ad')
        imgDiv!.style.width = '100%'
        imgDiv!.style.height = '100%'
        img!.style.width = '100%'
        img!.style.height = '100%'
        img!.style.borderRadius = '10px 0 10px 10px'
      }
    };
    const observer = new MutationObserver(adjustAdStyle)
    observer.observe(googleContainer!, { childList: true, subtree: true });
  })

  const isFirstTime = !window.googletag;
  const googletag = window.googletag = window.googletag || {};
  googletag.cmd = googletag.cmd || [];

  if (isFirstTime) {
    googletag.cmd.push(function() {
      googletag.defineSlot(slotPath, [width, height], displayId).addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
  }

  return (
    <div className="google-tag-container" id={displayId}></div>
  )
}
