import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"
import getIn from "lodash/get"
import APIStore from "stores/APIStore"
import UIStore from "stores/UIStore"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import PackagePlan from "models/PackagePlan"
import { withAxiosErrorMessage } from "helpers/errorHandling"
import Offer from "models/Offer"
import Package from "models/Package"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import BrandStore from "stores/BrandStore"
import { isApiError, withAxiosError } from "helpers/errorHandling"

export interface Response {
  plan: PackagePlan
  package: Package
  offer: Offer
}

export default class PlanV3Store extends APIStore {
  @observable plan?: PackagePlan
  @observable package?: Package
  @observable offer?: Offer
  @observable promoCode?: string

  @observable errorMessage?: string
  @observable errorCode?: string
  @observable errorPromo?: string

  @observable originalPlan?: PackagePlan

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    TokenAuthMiddleware(this.brandStore.userStore),
    DeserializeMiddleware("plan", PackagePlan),
    DeserializeMiddleware("package", Package),
  ])

  @computed get discountApplied() {
    return (
      !!this.promoCode &&
      !!this.plan &&
      !!this.originalPlan &&
      this.originalPlan.todayTotal.numeric > this.plan.todayTotal.numeric
    )
  }

  @computed get originalPrice() {
    if (!this.discountApplied) return undefined

    return this.originalPlan!.todayTotal
  }

  @computed get discount() {
    if (!this.discountApplied) return 0

    return this.originalPrice!.numeric - this.plan!.todayTotal.numeric
  }

  constructor(
    public brandStore: BrandStore,
    public packageId: string,
    public isAuth?: boolean
  ) {
    super()
  }

  // Manually add location name to location picker
  async addLocationName(locData: any) {
    const url = `/api/locations/${locData}`
    const respLocInfo =  await fetch(url)
    const respLocInfoData = await respLocInfo.json()
    const locPicker = document.getElementsByClassName('location-picker__toggle')
    if(locPicker.length){
      locPicker[0].innerHTML = respLocInfoData.location.name
    }
    return respLocInfoData.location.id
  }

   async fetch() {

    const queryParams = new URLSearchParams(document.location.search);
    let locId = queryParams.has('offer_id') ? queryParams.get('location_id') : this.brandStore.locStore.currentLocationId
    const packID = queryParams.has('offer_id') ? queryParams.get('offer_id') : this.packageId
    let loc;

    if(locId){
      this.addLocationName(locId)
    }
    else if(queryParams.get('location')){
      loc = queryParams.has('offer_id') ? queryParams.get('location') : ""
      await this.addLocationName(loc).then((result) => {
        locId = result
        this.brandStore.locStore.setCurrent({ locationId: locId! })
      })
    }
    
    const url = `/api/locations/${locId}/offers/${packID}`
    const respOffer =  await fetch(url)
    const respOfferData = await respOffer.json()

    if(respOffer.status !== 200){
      if(respOffer.status === 404){
        document.location.href= `/auth/register?statusText=Unavailable`
      } else {
        document.location.href= `/checkout-error?statusText=${respOffer && respOffer.statusText}`
      }
    }
      
    // Check local storage if this is a completely new user
    if(this.brandStore.userStore.session  && localStorage.getItem("newUser") !== "true")  {
      const userOfferURL = new URL(respOfferData.offer.offer_url)
      document.location.href= document.location.origin + userOfferURL.pathname + `?subtotal=false` // Remove subtotal from UI
    }

    const packagePathCondition = window.location.pathname.includes('simple-checkout') ? this.isAuth : this.brandStore.userStore.session
    const urlPackage = `/api/locations/${locId}/${packagePathCondition ? 'packages': 'package_details' }/${respOfferData.offer.package_id}`
    // const urlPackage = `/api/locations/${locId}/package_details/${respOfferData.offer.package_id}`

    this.offer = respOfferData.offer

    try {
      await this.api
        .get(
          urlPackage,
          { params: { promo_code: this.promoCode } }
        )
      } catch (err){
        const respErr = withAxiosErrorMessage(err)    
        console.log(respErr, err, 'planV3Store')
        if(respErr && respErr.data.message === "Sorry, you cannot purchase this package again at this time."){
          document.location.href= `/checkout-error?statusText=NoPurchase`
        } else {
          document.location.href= `/checkout-error?statusText=BadRequest`
        }

      }

      

  }

  updateCode(promoCode?: string) {
    this.promoCode = promoCode || ''
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response>) {
    this.plan = res.data.plan
    this.package = res.data.package
    if (this.promoCode) this.brandStore.track.event("promo code_success")
    if (!this.originalPlan) this.originalPlan = res.data.plan

    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    const res = withAxiosError(ex)
    if (isApiError(res) && res.data.code === "invalid_code") {
      this.errorCode = res.data.code
      this.errorMessage = res.data.message
      this.errorPromo = this.promoCode
      this.updateCode()
      this.setStatus("error")
      console.error(`log error - ${this.errorMessage}`, res)
      this.brandStore.uiStore.openModal({
        type: "error",
        message: "Invalid Promo Code",
        modalShow: true,
      })
      this.brandStore.track.event("promo code_failure")
    } else if (res) {
      this.errorCode = res.data.code
      this.errorMessage = res.data.message
      throw ex
    }
  }

  respondIneligible() {
    this.errorCode = "ineligible_specific"
    this.errorMessage = `An account is required to proceed.`
    this.status = "error"
    return Promise.resolve()
  }
}
