/**
 * This was initially used to show offers set in DASHBOARD_OFFER config var
 * We are using home promo cards feature so this is not used anymore
 * Keeping this around just in case we want to use this to fine-tune the position of the cards
 */
import * as React from "react"
import { inject, observer } from "mobx-react"
import getIn from "lodash/get"

import BrandStore from "stores/BrandStore"
import BannerCard from "apps/dashboard/BannerCard"

interface Props {
  className?: string
  store?: BrandStore
}

const OfferCard: React.FunctionComponent<Props> = inject(
  (store: BrandStore) => ({ store })
)(
  observer(({ className, store }) => {
    const dashboardOffer = getIn(window.globals, "dashboardOffer")
    if (!dashboardOffer) return null

    const { enabled, title, description, background_image_url, link, link_copy } = dashboardOffer

    if (enabled !== true || !title || !description || !background_image_url || !link || !link_copy) {
      return null
    }

    // We are expecting `link` to be append-able with location CR ID
    // e.g. link: https://example.com/page?studio_id=
    const { locStore: { currentLocation } } = store!
    const offerLink = `${link}${currentLocation!.clubreadyId}`

    return (
      <BannerCard
        className={className}
        title=""
        largeTitle={title}
        subtitle=""
        description={description}
        bannerImage={background_image_url}
        overlay={false}
        links={[
          {
            label: link_copy,
            url: offerLink,
            track: "home_tap offer link" as const
          },
        ]}
      />
    )
  })
)

export default inject((store: BrandStore) => ({ store }))(OfferCard)
