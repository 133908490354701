import React, { useEffect } from "react"
import BrandStore from "stores/BrandStore"
import ClaimAccountModal from "apps/dashboard/ClaimAccountModal"

export interface Props {
  store: BrandStore
}

export interface FormValuesSPC {
  password: string
  password_confirmation: string
}

export default function ClaimAccountSPC({store}: Props) {
  // On component mount
  useEffect(() => {
    store!.uiStore.hideNavLinks()
    store!.uiStore.lockLocationPicker()
  }, [])

  // On component unmount
  useEffect(() => () => {
    store!.uiStore.showNavLinks()
    store!.uiStore.unlockLocationPicker()
  }, [])

  return (
    <ClaimAccountModal
      UIStore={store!.uiStore}
      store={store!}
      showSkip={true}
      notModal={true}
    />
  )
}
