import * as React from "react"
import cx from "classnames"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import HomePromo from "models/HomePromo"

interface Props {
  className?: string
  card: HomePromo
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class PromoCard extends React.Component<Props> {

  render() {
    const { title, description, cta, externalUrl, homePromoCardBackgroundImageUrl } = this.props.card
    const background = `url(${homePromoCardBackgroundImageUrl}) center / cover`

    const inner = (
      <div
        className={cx(
          "card-body",
          "d-flex",
          "flex-column",
          "justify-content-center",
          "align-items-center",
        )}
        style={{ zIndex: 1 }}
      >
        <div className={cx("text-center")}>
          { title &&
            <h2 className={cx("d-block", "mb-3", "text-white")}><strong>{title}</strong></h2>
          }
          { description && <h4 className="text-white">{description}</h4> }
          {cta && (
            <div className={cx("mt-4", "d-block banner-card-links")}>
              <a href={externalUrl} target="_blank" className={cx("text-white d-block")}>
                <span>{cta}</span>
              </a>
            </div>
          )}
        </div>
      </div>
    )

    return (
      <>
        {cta ? (
          <div
            className={cx("text-decoration-none", "card", "banner-card", "NEW2", this.props.className)}
            style={{ background }}
          >
            {inner}
          </div>
        ) : (
          <a
            className={cx("text-decoration-none", "card", "banner-card", "NEW", this.props.className)}
            href={externalUrl}
            target="_blank"
            style={{ background }}
          >
            {inner}
          </a>
        )}
      </>
    )
  }
}
