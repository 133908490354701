import * as React from "react"
import * as cx from "classnames"
import * as moment from "moment"
import { observer } from "mobx-react"
import Heading from "components/Heading"
import LocationSummary from "models/LocationSummary"
import InstructorSummary from "models/InstructorSummary"
import BrandModel from "models/Brand"
import { Brand } from "apps/book/models/xpass/ScheduleEntry"
import ScheduleEntryStore from "../stores/xpass/ScheduleEntryStore"
import UserCoordinatesStore from "../stores/xpass/UserCoordinatesStore"
import { BrandCopy } from "themes/base"
import { Moment } from "moment"
import BrandStore from "stores/BrandStore"

export interface ScheduleEntrySummary {
  title: string
  subtitle?: string
  startsAt: Moment
  endsAt: Moment
  startTime: string
  endTime: string
  instructor?: InstructorSummary
  brand?: Brand
  location?: LocationSummary
  brandId?: string
  brandName?: string
  locationName?: string
  duration?: number
}

interface Props {
  title: React.ReactNode
  subtitle?: React.ReactNode
  scheduleEntry?: ScheduleEntrySummary
  brand: BrandModel
  locationSummary: LocationSummary
  seatId?: string
  seatLabel?: string
  waitlistPosition?: number
  waitlistIsEstimate?: boolean
  copy: BrandCopy
  store?: BrandStore
}

const BookingInfoCard: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  scheduleEntry,
  brand,
  locationSummary,
  children,
  seatId,
  seatLabel,
  copy,
  waitlistPosition,
  waitlistIsEstimate,
  store,
}) => {
  const { isXponential, styleClasses, brandId } = store!
  const userCoordinatesStore = isXponential
    ? new UserCoordinatesStore()
    : undefined
  const scheduleEntryStore = userCoordinatesStore
    ? new ScheduleEntryStore(userCoordinatesStore, store!.userStore)
    : undefined
  const isRumble = brandId === "rumble"

  const seatName = (id: string) => {
    switch (id) {
      case "cyclebar":
        return "Bike"
      default:
        return "Spot"
    }
  }

  if (!scheduleEntry) {
    return <></>
  }

  const xpassBrandId =
    (scheduleEntry.location && scheduleEntry.location.brandId) ||
    scheduleEntry.brandId

  return (
    <div className={styleClasses.BookingInfoCard__container}>
      <Heading center>{title}</Heading>
      <div className="row">
        <div className="col-md-8 col-lg-6 col-xl-4 mx-auto">
          {subtitle && (
            // make it a little narrow than the card when centered (shrug)
            <p
              className={cx(
                "text-sm-center px-sm-2 slide-in delay-1",
                styleClasses.BookingInfoCard__subtitle
              )}
            >
              {subtitle}
            </p>
          )}

          <div className="confirm-body mb-5 card-in delay-3">
            <div className="card">
              <div className="card-body text-left">
                {isXponential ? (
                  <>
                    {scheduleEntryStore && (
                      <div className="my-2" style={{ maxWidth: "48px" }}>
                        <img
                          className="w-100"
                          src={
                            xpassBrandId &&
                            scheduleEntryStore.getBrandIcon(xpassBrandId)
                          }
                          alt={`${xpassBrandId}-icon`}
                        />
                      </div>
                    )}
                    <h2 className="mb-0">{scheduleEntry.title}</h2>
                    {scheduleEntry.instructor && (
                      <p>with {scheduleEntry.instructor.name}</p>
                    )}

                    <div className="mt-3 mb-4">
                      <p className="text-sm">
                        <strong>
                          {scheduleEntry.brandName || scheduleEntry.brand!.name}{" "}
                          {scheduleEntry.locationName ||
                            scheduleEntry.location!.name}
                        </strong>
                        <br />
                        {scheduleEntry.startsAt
                          .local()
                          .format("dddd MMMM Do, YYYY")}
                        <br />
                        <span className="text-uppercase">
                          {scheduleEntry.startsAt.local().format("h:mma")}
                        </span>
                        {scheduleEntry.duration
                          ? `${" "}| ${scheduleEntry.duration} min`
                          : scheduleEntry.endsAt &&
                            `${" "}- ${scheduleEntry.endsAt
                              .local()
                              .format("h:mma")}`}
                      </p>

                      {seatId && (
                        <div>
                          <span className="font-weight-bold text-sm">
                            {seatName(scheduleEntry.brand!.id)}:
                          </span>{" "}
                          {seatLabel || seatId}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <h3
                      className={cx(
                        "mb-0",
                        styleClasses.BookingInfoCard__entryTitle
                      )}
                    >
                      {scheduleEntry.title}
                    </h3>
                    {scheduleEntry.subtitle && (
                      <p
                        className={styleClasses.BookingInfoCard__entrySubtitle}
                      >
                        <strong>{scheduleEntry.subtitle}</strong>
                      </p>
                    )}
                    {isRumble && <hr />}
                    {waitlistPosition && (
                      <div
                        className={cx(
                          "text-18",
                          styleClasses.BookingInfoCard__entryWaitlist
                        )}
                      >
                        <strong>
                          {waitlistIsEstimate ? "Estimated " : ""}#
                          {waitlistPosition} on Waitlist
                        </strong>
                      </div>
                    )}

                    <div
                      className={cx(
                        "mt-3 mb-4 text-18",
                        styleClasses.BookingInfoCard__entryDescription
                      )}
                    >
                      <p
                        className={
                          styleClasses.BookingInfoCard__entryLocationName
                        }
                      >
                        <strong>
                          {brand.name} {locationSummary.name}
                        </strong>
                      </p>
                      <p>
                        {scheduleEntry.startsAt.format("dddd MMMM Do, YYYY")}
                        <br />
                        {scheduleEntry.startTime} – {scheduleEntry.endTime}
                      </p>

                      {scheduleEntry.instructor && !isRumble && (
                        <div className="text-dark">
                          <span className="font-weight-bold">
                            {copy.instructor}:
                          </span>{" "}
                          {scheduleEntry.instructor.name}
                        </div>
                      )}
                      {seatId && (
                        <p className="mb-0">
                          <span className="font-weight-bold">{copy.seat}:</span>{" "}
                          {seatLabel || seatId}
                        </p>
                      )}
                    </div>
                  </>
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default observer(BookingInfoCard)
