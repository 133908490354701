import * as React from "react"
import * as moment from "moment"
import cx from "classnames"

import Invoice from "models/Invoice"
import ResponsiveTable, { TableColumn } from "components/ResponsiveTable"
import BrandStore from "stores/BrandStore"

export interface Props {
  store?: BrandStore
  invoices: Invoice[]
  accessToken: string
}

export default class BillingHistoryPanel extends React.Component<Props, {}> {
  columns: TableColumn<Invoice>[] = [
    {
      // use a non-breaking space so we don't wrap
      heading: "Invoice #".replace(/ /g, "\u00a0"),
      cell: invoice => invoice.id,
    },
    {
      heading: "Date",
      cell: invoice => moment(invoice.paidAt)
        .format(this.props.store!.styleClasses.BillingHistory__dateFormat),
    },
    {
      heading: "Item",
      cell: invoice => invoice.name,
    },
    {
      heading: "Amount",
      cell: invoice => invoice.total.formatted,
    },
    {
      heading: "Paid",
      cell: invoice => (invoice.paidAt ? invoice.total.formatted : ""),
    },
    {
      heading: "",
      cell: invoice => (
        <div className="text-md-right">
          <a
            className={cx("btn btn-primary btn-sm px-4", { "btn-xpo2": this.props.store!.isXponential})}
            target="_blank"
            href={`/api/invoices/${invoice.id}.pdf?access_token=${this.props.accessToken}`}
          >
            {this.props.store!.copy.print} {invoice.paidAt ? "Receipt" : "Invoice"}
          </a>
        </div>
      ),
    },
  ]

  emptyPlaceholder = <p>You haven't made any purchases yet.</p>

  rowKey = (invoice: Invoice) => invoice.id

  render() {
    return (
      <ResponsiveTable
        columns={this.columns}
        rows={this.props.invoices}
        rowKey={this.rowKey}
        hasControls
        emptyPlaceholder={this.emptyPlaceholder}
      />
    )
  }
}
