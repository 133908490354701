import * as React from "react"
import cx from "classnames"
import { inject, observer } from "mobx-react"
import { Link } from "react-router-dom"
import BrandStore from "stores/BrandStore"
import DashboardApiStore from "apps/dashboard/stores/DashboardApiStore"
import APILoader from "components/wrappers/APILoader"
import Package from "models/Package"

interface Props {
  className?: string
  store?: BrandStore
  dashboardStore: DashboardApiStore
}

@inject((store: BrandStore) => ({ store }))
@observer
class MembershipCard extends React.Component<Props> {
  handleHeaderClick = (_: LinkEvent) => {
    const store = this.props.store!
    store.track.event("home_tap become a member", {
      loc: store.locStore.currentLocation,
    })
  }

  handleBuyClick = (pkg: Package) => (_: LinkEvent) => {
    const store = this.props.store!
    store.track.event("home_tap buy package", {
      pkg,
      loc: store.locStore.currentLocation,
    })
  }

  render() {
    const { userStore } = this.props.store!

    if (!userStore.session) {
      return
    }

    return (
      <div className={cx("card", this.props.className)}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="text-dark">Membership Options</h5>
          <small>
            <Link to={`/buy`} onClick={this.handleHeaderClick}>
              View All
            </Link>
          </small>
        </div>

        <div className="card-body d-flex flex-column justify-content-center">
          <APILoader
            apiStore={this.props.dashboardStore}
            elementSize="element"
            propagateErrors
            render={() => {
              const { packages } = this.props.dashboardStore
              if (!packages || packages.length === 0) {
                return (
                  <p className="my-auto text-center">
                    {`We're sorry, it doesn't look like there are any membership options available right now.`}
                  </p>
                )
              }
              return (
                <>
                  {packages.slice(0, 2).map(pkg => (
                    <div
                      key={pkg.id}
                      className="d-flex justify-content-between align-items-center rounded p-3 mb-3 bg-light"
                    >
                      <div>
                        <h5 className="mb-0">{pkg.name}</h5>

                        {pkg.isRecurring && (
                          <small>
                            Auto renews{" "}
                            {pkg.intervalCount! > 1
                              ? `every ${pkg.intervalCount} ${pkg.interval!}s`
                              : `${pkg.interval}ly`}
                            <br />
                          </small>
                        )}
                      </div>

                      <Link
                        className="btn btn-primary ml-1"
                        onClick={this.handleBuyClick(pkg)}
                        to={`/buy/${
                          this.props.store!.locStore.currentLocationId
                        }/packages/${pkg.id}`}
                      >
                        Buy
                      </Link>
                    </div>
                  ))}
                </>
              )
            }}
          />
        </div>
      </div>
    )
  }
}

export default MembershipCard
