import { AxiosResponse } from "axios"
import { action } from "mobx"

import UserStore from "stores/UserStore"
import APIStore from "stores/APIStore"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import { UserSession } from "models/UserSession"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export default class CovidWaiverStore extends APIStore {
  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("user", UserSession),
    TokenAuthMiddleware(this.userStore),
  ])

  constructor(public userStore: UserStore) {
    super()
  }

  recordWaiverAgreement() {
    return this.api.post("/api/covid_waiver_agreements")
  }

  @action.bound
  protected handleSuccess(res: AxiosResponse<{ user: UserSession }>) {
    this.userStore.session = res.data.user
    return res
  }
}
