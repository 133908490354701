import React, { Component } from "react"
import cx from "classnames"
import Svg from "components/Svg"
import range from "lodash/range"
import { formatNumber } from "helpers/numberFormatter"
import BrandStore from "stores/BrandStore"

type Props = {
  store?: BrandStore
  target: number
  now: number
  className?: string
  labels: number[]
  labelFormatter(val: number): string
  // for testing various values
  onDoubleClick?(offset: number): void
}

export default class AchievementProgressBar extends Component<Props> {
  static defaultProps: Partial<Props> = {
    labelFormatter: formatNumber,
  }

  handleDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!this.props.onDoubleClick) {
      return
    }
    const rect = e.currentTarget.getBoundingClientRect()

    const pixelOffset = e.clientX - rect.left
    const fractionalOffset = pixelOffset / rect.width

    // make it easy to go past the max or to zero
    let offset: number
    if (fractionalOffset > 0.99) {
      offset = this.props.target + 10
    } else if (fractionalOffset < 0.01) {
      offset = 0
    } else {
      offset = fractionalOffset * this.props.target
    }

    this.props.onDoubleClick(offset)
  }

  render() {
    if (this.props.store!.brand.id === "yogasix" && window.globals.y6WarriorPromotion) {
      const { target, now, labelFormatter, labels } = this.props
      const progress = Math.min((now * 100) / target, 100)
      // would be better to either make these show increments of the progress
      // or just hide them by default

      // TODO: need to allow setting of both offsets and labels
      const formattedLabels = labels.map(
        l => [(l * 100) / target, labelFormatter(l)] as const
      )

      return (
        <div className={this.props.className}>
          <div className="goal-bar">
            <div
              className="goal-bar-track"
              onDoubleClick={this.handleDoubleClick}
            >
              <div
                className="goal-bar-progress"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="goal-bar-ui">
              <div className="achievement-bar-markers">
                {formattedLabels.map(([val, label]) => (
                  <Svg
                    key={label}
                    name="progress-step"
                    size="4"
                    style={{ left: `${val}%` }}
                  />
                ))}
              </div>
              <div className="achievement-bar-labels">
                {formattedLabels.map(([val, label]) => (
                  <div
                    className="outer"
                    key={label}
                    style={{ left: `${val}%` }}
                  >
                    <div className="inner">{label}</div>
                  </div>
                ))}
              </div>
              <div
                className="goal-bar-scrubber-wrapper"
                style={{ width: `${progress}%` }}
              >
                <div title={now.toString(10)} className="goal-bar-scrubber" />
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      const { target, now, labelFormatter, labels } = this.props
      const progress = Math.min((now * 100) / target, 100)

      const formattedLabels = labels.map(
        l => [(l * 100) / target, labelFormatter(l)] as const
      )

      return (
        <div className="achievement-progress-bar">
          <div className="goal-bar">
            <div className="goal-bar-track">
              <div
                className="goal-bar-progress"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="goal-bar-ui">
              <div className="achievement-bar-markers">
                {formattedLabels.map(([val, label]) => (
                  <Svg
                    key={label}
                    name="progress-step"
                    size="4"
                    style={{ left: `${val}%` }}
                  />
                ))}
              </div>
              <div className="achievement-bar-labels">
                {formattedLabels.map(([val, label]) => (
                  <div
                    className="outer"
                    key={label}
                    style={{ left: `${val}%` }}
                  >
                    <div className="inner">{label}</div>
                  </div>
                ))}
              </div>
              <div
                className="goal-bar-scrubber-wrapper"
                style={{ width: `${progress}%` }}
              >
                <div title={now.toString(10)} className="goal-bar-scrubber" />
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}
