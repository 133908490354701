import * as React from "react"
import * as cx from "classnames"
import Booking from "models/Booking"
import { Link, Redirect } from "react-router-dom"
import BrandStore from "stores/BrandStore"
import { inject, observer } from "mobx-react"
import Heading from "components/Heading"
import ScheduleEntry from "models/ScheduleEntry"
import * as moment from "moment"
import LocationSummary from "models/LocationSummary"
import SocialLinks from "./SocialLinks"
import BookingStatus from "models/BookingStatus"
import BookingInfoCard from "apps/book/components/BookingInfoCard"
import PageTracker from "components/wrappers/PageTracker"

interface Props {
  booking?: Booking | BookingStatus
  store?: BrandStore
  scheduleEntry: ScheduleEntry
  locationSummary: LocationSummary
  shareLink?: string
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class BookingSuccessPage extends React.Component<Props, {}> {
  copyShareInput: React.RefObject<HTMLInputElement> = React.createRef()

  handleCopyLinkClick = () => {
    this.copyShareInput.current!.select()
    document.execCommand("copy")
  }

  render() {
    const {
      store,
      booking,
      scheduleEntry,
      locationSummary,
      shareLink,
    } = this.props

    if (!booking) return <Redirect to="/bookings" />

    return (
      <BookingSuccess
        booking={booking}
        brandStore={store!}
        locationSummary={locationSummary}
        scheduleEntry={scheduleEntry}
      />
    )
  }
}

interface SuccessProps {
  booking: Booking | BookingStatus
  brandStore: BrandStore
  locationSummary: LocationSummary
  scheduleEntry: ScheduleEntry
}

export const BookingSuccess: React.SFC<SuccessProps> = ({
  booking,
  brandStore,
  locationSummary,
  scheduleEntry,
}) => {
  const { brand, brandId, copy, styleClasses } = brandStore
  const isWaitlist = booking.status === "waitlisted"
  const seatId =
    scheduleEntry.roomId && "seatId" in booking ? booking.seatId : undefined
  const seatLabel =
    scheduleEntry.roomId && "seatLabel" in booking ? booking.seatLabel : undefined

  return (
    <div>
      <PageTracker name="book > success" />
      <BookingInfoCard
        title={
          isWaitlist ? (
            <>You&apos;re on the Waitlist!</>
          ) : (
            "Your Booking is Confirmed!"
          )
        }
        subtitle={
          isWaitlist
            ? `We will email you if a ${String(
                copy.seat
              ).toLowerCase()} becomes available. We hope to see you soon!`
            : `Here's your ${copy.class}
          information. We'll see you soon!`
        }
        scheduleEntry={scheduleEntry}
        locationSummary={locationSummary}
        brand={brand}
        copy={copy}
        seatId={seatId}
        seatLabel={seatLabel}
        waitlistPosition={
          "waitlistPosition" in booking ? booking.waitlistPosition : undefined
        }
        store={brandStore}
      >
        {!(brandId === "rumble") && (
          <hr />
        )}
        <div className={cx("confirmed-links", styleClasses.BookingSuccessPage__confirmedLinks)}>
          {process.env.FEATURE_MVP_ONLY !== "1" && (
            <div className="confirm-link">
              <a href="#">Add to Calendar</a>
            </div>
          )}
          <div className="confirm-link">
            <a href={locationSummary.directionsUrl} target="_blank">
              Get Directions
            </a>
          </div>
          {locationSummary.email && (
            <div className="confirm-link">
              <a href={`mailto:${locationSummary.email}`}>Contact Location</a>
            </div>
          )}
        </div>
        <div className={cx("mt-4 text-center", styleClasses.BookingSuccessPage__viewScheduleLink)}>
          <Link className="btn btn-primary w-100" to={`/bookings`}>
            View My Schedule
          </Link>
        </div>
      </BookingInfoCard>
      {/* make sure this stays gone - we are NOT giving out free rides */}
      {/* <hr className="my-5" />
      <div className="text-center">
        <h4 className="mb-3">Refer a Friend</h4>
        <div className="confirmed-body d-flex justify-content-center text-center mb-5">
          <p>
            Riding with people you love is what we are all about. Feel free to
            invite your friend or two and they will get their first ride for
            free.
          </p>
        </div>
        <h4 className="mb-3">Share With Your Friends!</h4>
        <div className="row d-flex justify-content-center mb-5">
          <div className="row col-md-6 col-lg-4 col-xl-4">
            <input
              ref={copyShareInput}
              className="form-control col-8 h-100"
              type="text"
              readOnly={true}
              value={this.shareLink}
            />
            <button
              className="btn btn-secondary bg-dark text-white rounded-0 col-4 h-100"
              onClick={this.handleCopyLinkClick}
            >
              Copy Link
            </button>
          </div>
        </div>
        {process.env.FEATURE_MVP_ONLY !== "1" && (
          <>
            <h4 className="mb-2">Or Share On Social:</h4>
            <div className="confirmed-social mx-auto">
              <SocialLinks />
            </div>
          </>
        )}
      </div> */}
    </div>
  )
}
