import React, { useState } from "react"
import cx from "classnames"
import * as moment from "moment"
import { Link } from "react-router-dom"
import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import EarnedRewardsStore from "apps/classpoints/stores/EarnedRewardsStore"

const MyRewardsCard = ({store}: {store: BrandStore}) => {
  const [earnedRewardsStore, setEarnedRewardsStore] = useState(new EarnedRewardsStore(store))

  return (
    <div className={cx("card", "ways-to-earn-card")}>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="text-dark">
          My Rewards
        </h5>
        <small>
          <Link to={`/classpoints/rewards`} onClick={() => store!.track.event("classpoints_tap view my rewards")}>
            View All
          </Link>
        </small>
      </div>
      <APILoader
        apiStore={earnedRewardsStore}
        elementSize="element"
        render={() => {
          let { earnedRewards } = earnedRewardsStore
          earnedRewards = earnedRewards.slice(0, 3) // Only display first three

          return (
            <div className="card-body d-flex flex-column p-0">
              {!!earnedRewards.length && (
                <ul className="list-group list-group__no-scroll list-group-flush flex-grow-1 mb-0 rows-in pt-2">
                  {earnedRewards.map(entry => (
                    <li key={entry.id} className={cx("list-group-item d-flex align-items-center mx-3 px-0")}>
                      <div className="list-group-item__image">
                        <img src={entry.thumbnailImageUrl} />
                      </div>
                      <div className="pl-3">
                        <div className="font-14">Earned on {moment(entry.earnedAtDate).format('M/D/yyyy')}</div>
                        <div className="font-16 font-600">{entry.name}</div>
                        <div className="font-14 color-primary pt-1">{entry.shortDescription}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {!earnedRewards.length && (
                <div className="list-group list-group-flush flex-grow-1 mb-0 rows-in py-4 mx-3">
                  No rewards earned yet.
                </div>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

export default MyRewardsCard
