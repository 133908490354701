import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import { Link } from "react-router-dom"

import aktIcon from "images/xpass/icons/brands/akt.png"
import bftIcon from "images/xpass/icons/brands/bft.png"
import clubpilatesIcon from "images/xpass/icons/brands/clubpilates.png"
import cyclebarIcon from "images/xpass/icons/brands/cyclebar.png"
import purebarreIcon from "images/xpass/icons/brands/purebarre.png"
import rowhouseIcon from "images/xpass/icons/brands/rowhouse.png"
import rumbleIcon from "images/xpass/icons/brands/rumble.png"
import stretchlabIcon from "images/xpass/icons/brands/stretchlab.png"
import strideIcon from "images/xpass/icons/brands/stride.png"
import yogasixIcon from "images/xpass/icons/brands/yogasix.png"
import kinrgyIcon from "images/xpass/icons/brands/kinrgy.png"
import tokenIcon from "images/xpass/token.png"

import { RouteComponentProps } from "react-router"
import APILoader from "components/wrappers/APILoader"
import ScheduleEntryStore from "apps/xpass/stores/ScheduleEntryStore"
import BookingsStore from "apps/bookings/stores/BookingsStore"
import DetailsPageMap from "apps/xpass/DetailsPageMap"
import BrandStore from "stores/BrandStore"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import nl2br from "helpers/nl2br"
import { ClassListItem } from "apps/book/components/xpass/EntryListItem"
import ScheduleEntry from "apps/book/models/xpass/ScheduleEntry"

export interface Props
  extends RouteComponentProps<{ scheduleEntryId?: string }> {
  store?: BrandStore
  scheduleEntryId?: string
  onBookingModal: Function
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ClassDetails extends Component<
  Props & RouteComponentProps<{ scheduleEntryId: string }>
> {
  scheduleEntryStore = new ScheduleEntryStore(this.props.store!.userStore)
  bookingsStore = new BookingsStore(this.props.store!)
  balancesStore = new BalancesStore(this.props.store!)

  componentWillMount() {
    this.balancesStore.fetch().catch(err => {
      this.props.history.push("/error")
      throw err
    })
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.history.go(0)
    }
  }

  componentWillUnmount() {
    this.bookingsStore.dispose()
    this.balancesStore.dispose()
  }

  brandIconImage = (brand: string) => {
    switch (brand) {
      case "akt":
        return aktIcon
      case "bft":
        return bftIcon
      case "clubpilates":
        return clubpilatesIcon
      case "cyclebar":
        return cyclebarIcon
      case "purebarre":
        return purebarreIcon
      case "rowhouse":
        return rowhouseIcon
      case "rumble":
        return rumbleIcon
      case "stretchlab":
        return stretchlabIcon
      case "stride":
        return strideIcon
      case "yogasix":
        return yogasixIcon
      case "kinrgy":
        return kinrgyIcon
      default:
        return undefined
    }
  }

  render() {
    const { match, store } = this.props
    const { copy } = store!
    const scheduleEntryId = match.params.scheduleEntryId
    const { bookedBookings } = this.bookingsStore
    return (
      <div className="pt-3 pt-md-4">
        <APILoader
          apiStore={this.scheduleEntryStore}
          fetchProps={scheduleEntryId}
          propagateErrors
          render={() => {
            const entry = this.scheduleEntryStore.scheduleEntry
            const startTime = entry!.startTimeTZ
            const upcomingClasses = this.scheduleEntryStore.upcomingClasses
            const iconUrl = this.brandIconImage(entry!.brand.id)
            const img = entry!.mapPin
            const brandId = entry!.brand.id
            const location = entry!.location
            const {
              freeClassesPurchased,
              freeClassBookings,
              balances,
            } = this.balancesStore!
            const userBalances = {
              freeClassesPurchased,
              freeClassBookings,
              balances,
            }

            const isFreeClass = (entry: ScheduleEntry) => {
              const hasFreeClasses = freeClassesPurchased && balances && balances.freeClasses > 0
              const bookedLocations = freeClassBookings && freeClassBookings.map(booking => booking.scheduleEntry.location.id)
              return hasFreeClasses && entry.isXpassFree && bookedLocations
                ? !bookedLocations.includes(location.id)
                : false
            }
          

            const classes = upcomingClasses && upcomingClasses.map((entry) => {
              const logoUrl = this.brandIconImage(entry!.brand.id)!
              const bookedEntry = bookedBookings.find(booking => booking.id === entry.id)
              const isFree = isFreeClass(entry)
              const locationId = store!.userStore.session!.locationId
              const onBookingModal = this.props.onBookingModal
              return {logoUrl, bookedEntry, isFree, entry, onBookingModal, locationId}
            })
            const hasPremiumPaidClasses = upcomingClasses && upcomingClasses.find((entry) => entry.isPremiumPaid) 

            return (
              <div
                className="details-page my-5"
              >
                <div className="container">
                  <div className="class-details-page__grid">
                    <div className="class-details__head">
                      <div className="row m-0 d-flex">
                        <div className="p-0 pr-md-3 mb-lg-4 mb-xl-0 w-100">
                          {entry!.classImage && (
                            <div className="position-relative">
                              <img
                                className="class-image w-100"
                                src={entry!.classImage}
                              />
                              <img className="icon-image" src={iconUrl} />
                            </div>
                          )}
                          <div className="class-details d-flex">
                            <div className="class-details__info">
                              <h2 className="details-page__title">
                                {entry!.title}
                              </h2>
                              <p className="mt-4 mb-0">
                                {entry!.brand.name} {entry!.location.name}
                              </p>
                              <p className="mt-0">
                                {startTime} | {entry!.duration} minutes
                              </p>
                              <p className="mb-0">
                                {entry!.instructor.headshotUrl && (
                                  <img
                                    className="mr-3"
                                    src={entry!.instructor.headshotUrl}
                                    width="40"
                                    height="40"
                                    style={{ borderRadius: "50%" }}
                                  />
                                )}
                                {entry!.instructor.name}
                              </p>
                            </div>
                            <div className="class-details__book">
                              <APILoader
                                apiStore={this.bookingsStore}
                                elementSize="element"
                                render={() => {
                                  const {
                                    balances,
                                    freeClassesPurchased,
                                    freeClassBookings,
                                  } = userBalances
                                  const hasFreeClasses =
                                    freeClassesPurchased &&
                                    balances &&
                                    balances.freeClasses > 0
                                  const bookedLocations =
                                    freeClassBookings &&
                                    freeClassBookings.map(
                                      booking => booking.scheduleEntry.location.id
                                    )
                                  const isFree =
                                    hasFreeClasses &&
                                    entry!.isXpassFree &&
                                    bookedLocations
                                      ? !bookedLocations.includes(location!.id)
                                      : false
                                  const { tooEarlyToBook } = entry!
                                  const bookedEntry = bookedBookings.find(
                                    item => item.id === entry!.id
                                  )

                                  return (
                                    <>
                                      {!tooEarlyToBook ? (
                                        bookedEntry ? (
                                          <button
                                            className="btn btn-primary btn-block px-0 d-block booked"
                                            disabled
                                          >
                                            Booked
                                          </button>
                                        ) : (
                                          <button
                                            style={{
                                              paddingLeft: "2px",
                                              paddingRight: "2px",
                                            }}
                                            className="xpass-cta xpass-cta-large w-100"
                                            onClick={() =>
                                              this.props.onBookingModal(entry)
                                            }
                                          >
                                            {isFree ? "Book For Free" : "Book"}
                                          </button>
                                        )
                                      ) : (
                                        <button
                                          className="btn btn-primary btn-block px-0 d-block"
                                          disabled
                                        >
                                          Too Early
                                        </button>
                                      )}

                                      <div className="credits mt-2">
                                        {bookedEntry ? (
                                          <span>
                                            {/* TODO: update to show real data */}
                                            {/* {bookedEntry.xpassCredits} {creditsCopy} */}
                                          </span>
                                        ) : (
                                          <div>
                                            <div className="class-details__tokens-price">
                                              <img src={tokenIcon} alt="token" />
                                              {entry && entry.userPremiumCost.raw > 0 ? `+ $${entry.userPremiumCost.raw}` : ""}{entry && entry.isPremiumPaid&& "*"}
                                            </div>
                                            {entry && entry.userPremiumCost.raw > 0 && (
                                              <div className="class-details__premium-badge text-center mt-2">Premium Class</div>
                                            )}
                                           {entry && entry.isPremiumPaid && (
                                              <div className="class-details__premium-paid mt-2 mb-0">* You have a cash balance equal to this amount</div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="class-details__body">
                      <hr className="divider my-4" />
                      {entry!.description && (
                        <>
                          <div className="row m-0">
                            <div className="col-md-12 p-0 pr-md-3 mb-lg-4 mb-xl-0">
                              <h3 className="details-page__section-title">
                                Description
                              </h3>
                              <p className="mb-0">
                                {nl2br(entry!.description)}
                              </p>
                            </div>
                          </div>
                          <hr className="divider my-4" />
                        </>
                      )}

                      {entry!.location.whatToBring && (
                        <>
                          <div className="row m-0">
                            <div className="col-md-12 p-0 pr-md-3 mb-lg-4 mb-xl-0">
                              <h3 className="details-page__section-title">
                                What To Bring
                              </h3>
                              <p className="mb-0">
                                {entry!.location.whatToBring}
                              </p>
                            </div>
                          </div>
                          <hr className="divider my-4" />
                        </>
                      )}

                      {entry!.location.parkingInfo && (
                        <>
                          <div className="row m-0">
                            <div className="col-md-12 p-0 pr-md-3 mb-lg-4 mb-xl-0">
                              <h3 className="details-page__section-title">
                                Parking Info
                              </h3>
                              <p className="mb-0">
                                {entry!.location.parkingInfo}
                              </p>
                            </div>
                          </div>
                          <hr className="divider my-4" />
                        </>
                      )}

                      {entry!.cancellationPolicy && (
                        <>
                          <div className="row m-0">
                            <div className="col-md-12 p-0 pr-md-3 mb-lg-4 mb-xl-0">
                              <h3 className="details-page__section-title">
                                Cancellation Policy
                              </h3>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: entry!.cancellationPolicy,
                                }}
                              ></div>
                            </div>
                          </div>
                          <hr className="divider" />
                        </>
                      )}

                      {classes && (
                        <div className="row m-0">
                          <div className="location-schedule-list col-md-12 p-0 pr-md-3 mb-1 mb-lg-4">
                            <h3 className="details-page__section-title">
                              Upcoming Classes
                            </h3>
                            {classes.length > 0 ? (
                              <>
                                {classes.map((item, i) => (
                                  <ClassListItem key={item.entry.id} {...item} />
                                ))}
                                <Link
                                  className="xpass-cta xpass-cta__reversed xpass-cta-large shuffle-btn text-center mx-auto"
                                  to={`/book/${
                                    store!.userStore.session!.locationId
                                  }/location/${entry!.location.id}`}
                                 >
                                  VIEW FULL SCHEDULE
                                </Link>
                              </>
                            ) : (
                              <>
                                <p>No upcoming classes at this studio</p>
                              </>
                            )}
                          </div>
                          {hasPremiumPaidClasses && <p className="paid-premium">* You have a cash balance equal to this amount</p>}
                        </div>
                      )}
                    </div>
                    <div className="class-details__map">
                      <DetailsPageMap
                        img={img}
                        brandId={brandId}
                        brandName={entry!.brand.name}
                        location={location}
                        store={store}
                        isDetailsPage
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          }}
        />
      </div>
    )
  }
}
