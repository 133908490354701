import { JsonObject, JsonProperty } from "json2typescript"

type CCType =
  | "visa"
  | "mastercard"
  | "discover"
  | "amex"
  | "diners"
  | "jcb"
  | "maestro"
  | "solo"
  | "unionpay"

type BankAccountTypes =
  | "pc"
  | "ps"
  | "bc"

@JsonObject("PaymentSource")
export default class PaymentSource {
  /**
   * Whether this is the default payment source
   */
  @JsonProperty("is_default", Boolean, true)
  isDefault: boolean = undefined!

  /**
   * Bank account type
   */
  @JsonProperty("bank_account_type", String, true)
  bankAccountType?: BankAccountTypes = undefined!

  /**
   * Last four digits of bank account#
   */
  @JsonProperty("bank_account_number_last4", String, true)
  bankAccountNumberLast4?: string = undefined!

  /**
   * Last four digits of CC#
   */
  @JsonProperty("cc_last4", String)
  ccLast4?: string = undefined!

  /**
   * Credit card type.
   */
  @JsonProperty("cc_type", String, true)
  ccType?: CCType = undefined

  /**
   * Two-digit month of cc expiration, as a string.
   */
  @JsonProperty("cc_exp_month", String)
  ccExpMonth?: string = undefined!

  /**
   * Four-digit year of cc expiration, as a string.
   */
  @JsonProperty("cc_exp_year", String)
  ccExpYear?: string = undefined!

  /**
   * First name on the card
   */
  @JsonProperty("first_name", String, true)
  firstName?: string = undefined!

  /**
   * Last name on the card
   */
  @JsonProperty("last_name", String, true)
  lastName?: string = undefined!

  @JsonProperty("name", String, true)
  name?: string = undefined!

  /**
   * Address on the card
   */
  @JsonProperty("address", String, true)
  address?: string = undefined!

  /**
   * Address line two
   */
  @JsonProperty("address2", String, true)
  address2?: string = undefined!

  @JsonProperty("city", String, true)
  city?: string = undefined!

  @JsonProperty("state", String, true)
  state?: string = undefined!

  @JsonProperty("zip", String, true)
  zip?: string = undefined

  /**
   * Two-digit, uppercase country code
   */
  @JsonProperty("country_code", String)
  countryCode?: "US" | "CA" | "MX" | "GB" | "AU" = undefined!

  @JsonProperty("email", String)
  email?: string = undefined!

  @JsonProperty("phone", String, true)
  phone?: string = undefined!

  /**
   * Payment source type
   */
  @JsonProperty("type", String, true)
  type: string = undefined!
}
