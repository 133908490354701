import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action } from "mobx"
import DeserializeRootMiddleware from "services/middleware/DeserializeRootMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import BrandStore from "stores/BrandStore"
import Deal from "models/xpass/Deal"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"


export default class DealDetailStore extends APIStore {
  @observable deal?: Deal

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("deal", Deal),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(protected brandStore: BrandStore) {
    super()
  }

  @action
  fetch(id: string) {
    return this.api.get(`/api/xpass/v3/deals/${id}`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.deal = res.data.deal
    return res.data
  }
}