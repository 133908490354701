import { observable, action } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import {Instructor, Details} from "apps/book/models/xpass/Instructor"
import ScheduleEntry from "apps/book/models/xpass/ScheduleEntry"
import APIStore from "stores/APIStore"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"

export interface InstructorDetailResponse {
    instructor?: Instructor
    upcomingClasses?: ScheduleEntry[]
    details?: Details
}

export default class InstructorDetailStore extends APIStore {
  @observable instructor?: Instructor
  @observable upcomingClasses?: ScheduleEntry[] = []
  @observable details?: Details

  api = this.createClient<InstructorDetailResponse>([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("instructor", Instructor),
    DeserializeMiddleware("upcoming_classes", ScheduleEntry),
    DeserializeMiddleware("details", Details),
  ])

  fetch(id: string) {
    return this.api.get(`/api/xpass/v2/instructors/${id}`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.instructor = res.data.instructor
    this.details = res.data.details
    this.upcomingClasses = res.data.upcoming_classes
    return res
  }
}