import BaseStore from "stores/BaseStore"
import { observable, action, computed } from "mobx"
import { withAxiosErrorMessage } from "helpers/errorHandling"
import LocationSummary from "models/LocationSummary"
import { UserSession } from "models/UserSession"
import ScheduleEntryLocation from "apps/book/models/xpass/ScheduleEntryLocation"

export interface ModalProps {
  type?: "error" | "message"
  message?: React.ReactNode // If present it'll be the displayed error or message.
  title?: string // Title to be dislayed in the modal header
  modalShow: boolean // If true it launches + displays the modal, it hides it otherwise.
  children?: React.ReactNode // If present, children to be displayed in the Modal body.
  size?: string // "sm" or " lg"
  locationSummary?: LocationSummary
  emptyHeader?: boolean
  classes?: string
  onClose?(): void
}

export type SubmenuTab = "featured" | "value" | "stretch" | "studios" | "favorites" | "now"

interface NavProps {
  navLinksShow: boolean
  signOutLinkShow: boolean
  lockLocation: boolean
  activeTab: SubmenuTab
  showFiltersModal: boolean
  hasAppliedFilters: boolean
  searchSuggestions: ScheduleEntryLocation[]
  selectedLocation: string
}

// still doesn't work right if you click the overlay to close
export default class UIStore extends BaseStore {
  @observable modal: ModalProps = {
    title: "",
    modalShow: false,
  }
  @observable nav: NavProps = {
    // hide all links in nav except for location selector (which will be locked)
    navLinksShow: true,
    signOutLinkShow: true,
    activeTab: "featured",
    showFiltersModal: false,
    hasAppliedFilters: false,
    searchSuggestions: [],
    selectedLocation: "",
    // just lock location picker
    // TODO: should lock to their home store?
    lockLocation: false,
  }

  @observable seenAccountWarning: boolean = false

  private modalId = "#main-app-modal"

  @action
  hideNavLinks() {
    this.nav.navLinksShow = false
  }

  @action
  showNavLinks() {
    this.nav.navLinksShow = true
  }

  @action
  lockLocationPicker() {
    this.nav.lockLocation = true
  }

  @action
  unlockLocationPicker() {
    this.nav.lockLocation = false
  }

  @computed
  get isBrandsNavActive() {
   if (this.nav.activeTab === 'stretch' || this.nav.activeTab === 'favorites') {
      return false
   }
   else return true
  }

  @computed
  get showDateFilter() {
   return this.nav.activeTab === 'stretch' || this.nav.activeTab === 'value' || this.nav.activeTab === 'featured'
  }

  @action
  setActiveTab(tab: SubmenuTab) {
    this.nav.activeTab = tab
  }

  @action
  setSearchSuggestions(locations: ScheduleEntryLocation[]) {
    this.nav.searchSuggestions = locations
  }

  @action
  showFiltersModal() {
    this.nav.showFiltersModal = true
    document.body.style.overflowY = "hidden"
    document.body.style.position = "fixed"
    document.body.style.top = "0"
    document.body.style.left = "0"
    document.body.style.right = "0"
    document.body.style.bottom = "0"
    document.querySelector<HTMLElement>('.main-menu')!.style.zIndex = '0'
    document.querySelector<HTMLElement>('.footer')!.style.zIndex = '-1'
  }

  @action
  hideFiltersModal() {
    this.nav.showFiltersModal = false
    document.body.style.overflowY = "scroll"
    document.body.style.position = "static"
    document.querySelector<HTMLElement>('.main-menu')!.style.zIndex = '1030'
    document.querySelector<HTMLElement>('.footer')!.style.zIndex = '1030'
  }
  @action
  setHasAppliedFilters(hasAppliedFilters: boolean) {
    this.nav.hasAppliedFilters = hasAppliedFilters
  }
  @action
  setSelectedLocation(location: string) {
    this.nav.selectedLocation = location
  }

  @action
  openModal(props: ModalProps) {
    this.modal = props
  }

  @action.bound
  closeModal() {
    if (this.modal.onClose) {
      this.modal.onClose()
    }
    this.modal = { modalShow: false }
  }

  openMessage(
    type: "error" | "message",
    message: string,
    title: string = "",
    locationSummary?: LocationSummary,
    classes?: string
  ) {
    this.openModal({
      type: type,
      title: title,
      message: message,
      modalShow: true,
      locationSummary: locationSummary,
      classes: classes
      // size: "sm",
    })
  }

  // error message displayed is:
  // 1. the message passed, if one was passed. if not:
  // 2. the message in the error response, if ex was passed and it's an axios error
  // 3. otherwise, a generic error message
  // supply an optional location to add contact info for the location to the modal
  openError(): void
  openError(message: string, locationSummary?: LocationSummary): void
  openError(ex: Error, locationSummary?: LocationSummary): void
  openError(messageOrEx?: string | Error, locationSummary?: LocationSummary) {
    let message: string | undefined

    if (typeof messageOrEx === "string") {
      message = messageOrEx
    } else if (messageOrEx) {
      const response = withAxiosErrorMessage(messageOrEx)
      if (response) {
        message = response.data.message
      }
    }
    if (!message) {
      message =
        "We apologize for the inconvenience. We're looking into the problem, but for now please try again."
    }

    this.openMessage("error", message, "Something went wrong", locationSummary)
  }

  @action.bound
  openAccountWarning(user: UserSession) {
    if (this.seenAccountWarning) return
    this.seenAccountWarning = true

    if (user.isFrozen) {
      this.openMessage(
        "message",
        "Your account has been temporarily frozen. To book classes, please contact your home studio to reactivate it.",
        "Please Reactivate Account",
        user.homeLocation
      )
    } else if (user.inDebtCollection) {
      this.openMessage(
        "message",
        "Your account has been frozen. Please contact your home studio to resolve this.",
        "Please Contact Studio",
        user.homeLocation
      )
    }
  }
}
