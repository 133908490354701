import APIStore from "stores/APIStore"
import { observable, action } from "mobx"
import { AxiosResponse } from "axios"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"
import ClassCategoryDetail from "models/ClassCategoryDetail"

export default class ClassCategoryStore extends APIStore {
  @observable classCategory?: ClassCategoryDetail

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    MemoizeMiddleware,
    DeserializeMiddleware("class_category", ClassCategoryDetail),
  ])

  constructor(public readonly id: string) {
    super()
  }

  fetch() {
    return this.api.get(`/api/class_categories/${this.id}`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<{ class_category: ClassCategoryDetail }>) {
    this.classCategory = res.data.class_category
    return res
  }
}
