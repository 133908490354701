import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import { isApiError } from "helpers/errorHandling"
import PlanStore, { Response } from "apps/buy/stores/PlanStore"
import PackagePlan from "models/PackagePlan"

// a clone of PlanStore for trying codes
export default class PromoCodeStore extends PlanStore {
  @computed
  get plan() {
    return this.planStore.plan!
  }
  set plan(plan: PackagePlan) {
    this.planStore.plan = plan
  }

  @computed
  get promoCode() {
    return this.planStore.promoCode
  }
  set promoCode(promoCode: string | undefined) {
    this.planStore.promoCode = promoCode
  }

  constructor(public planStore: PlanStore) {
    super(planStore.brandStore, planStore.packageId, planStore.locationSummary)
    this.status = planStore.status
    this.package = planStore.package
  }

  @action.bound
  addCode(code: string) {
    this.planStore.brandStore!.track.event("purchase_add promo code")
    return this.fetch(code).then(() => {
      this.brandStore!.track.event("promo code_success")
    }).catch(this.handleCodeError)
  }

  @action.bound
  removeCode() {
    this.promoCode = undefined
    this.planStore.plan = this.planStore.originalPlan
    this.fetch()
  }

  @action.bound
  handleCodeError(ex: AxiosError) {
    this.planStore.brandStore!.track.event("promo code_failure")
    if (!isApiError(ex.response) || ex.response.data.code !== "invalid_code") {
      throw ex
    }
  }
}
