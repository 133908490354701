import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

import Reward from "apps/classpoints/models/Reward"

interface Response {
  upcomingRewards: Reward[]
  featuredReward: Reward
}

export default class RewardsStore extends APIStore {
  @observable upcomingRewards: Reward[] = []
  @observable featuredReward: Reward = new Reward

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("upcoming_rewards", Reward),
    DeserializeMiddleware("featured_reward", Reward),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])
  
  constructor(public brandStore: BrandStore) { super() }

  fetch() {
    return this.api.get(`/api/class_points/dashboard/rewards`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response | any>) {
    this.upcomingRewards = res.data.upcoming_rewards
    this.featuredReward = res.data.featured_reward
    return res.data
  }
}
