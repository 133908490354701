import { JsonObject, JsonProperty } from "json2typescript"
import ClassStatsBase from "apps/history/models/ClassStatBase"

@JsonObject("HeartRateClass")
export default class HeartRateClass extends ClassStatsBase {
  type: "heartRate" = "heartRate"

  /**
   * Average heart rate during this class.
   */
  @JsonProperty("avg_hr", Number)
  avgHr: number = undefined!

  /**
   * Overall average heart rate as percent of target (max) HR.
   */
  @JsonProperty("avg_hr_percent", Number)
  avgHrPercent: number = undefined!

  /**
   * The highest heart rate they acheived during this class.
   */
  @JsonProperty("high_hr", Number)
  highHr: number = undefined!

  /**
   * Highest heart rate as percent of target (max) HR.
   */
  @JsonProperty("high_hr_percent", Number)
  highHrPercent: number = undefined!

  /**
   * This is their benchmark or target heart rate. It's an input rather than something based on performance data. It's used to compute their iq_points, heart rate %, and time in zones.
   */
  @JsonProperty("max_hr", Number)
  maxHr: number = undefined!

  /**
   * "Target Points" for AKT - get one for every minute they're over 70% or something. Goal is 30 per class.
   *
   */
  @JsonProperty("iq_points", Number)
  iqPoints: number = undefined!

  /**
   * Calories burned during this class.
   */
  @JsonProperty("calories", Number)
  calories: number = undefined!

  /**
   * Percentage of class time spent in each zone (1-5)
   */
  @JsonProperty("zones", [Number])
  zones: [number, number, number, number, number] = undefined!

  /**
   * Number of seconds spent in each zone (1-5)
   */
  @JsonProperty("zone_times", [Number])
  zoneTimes: [number, number, number, number, number] = undefined!
}
