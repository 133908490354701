import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosError } from "axios"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import { observable, action } from "mobx"
import LocationDetails from "models/LocationDetails"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"

type Response = { location: LocationDetails }

// Currently unused
export default class LocationDetailsStore extends APIStore {
  @observable location?: LocationDetails

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess),
    MemoizeMiddleware,
    DeserializeMiddleware("location", LocationDetails),
  ])

  constructor(public locationId: string) {
    super()
  }

  fetch() {
    return this.api.get(`/api/locations/${this.locationId}`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response>) {
    this.location = res.data.location
    return res
  }
}
