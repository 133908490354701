import { JsonObject, JsonProperty } from "json2typescript"
import LocationSummary from "models/LocationSummary"

export type DayOfWeek =
  | "sundary"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"

type HoursRange = [string, string]
type StoreHours = { [day in DayOfWeek]?: HoursRange[] }

@JsonObject("LocationDetails")
export default class LocationDetails extends LocationSummary {
  @JsonProperty("country_code", String)
  countryCode: string = undefined!

  @JsonProperty("phone", String)
  phone: string = undefined!

  @JsonProperty("lat", Number)
  lat: number = undefined!

  @JsonProperty("lng", Number)
  lng: number = undefined!

  @JsonProperty("emails", [String])
  emails: string[] = undefined!

  @JsonProperty("seq", Number)
  seq: number = undefined!

  @JsonProperty("hours", Object, true)
  hours?: StoreHours = undefined!

  @JsonProperty("holiday_hours", Object, true)
  holidayHours?: { [key: string]: HoursRange[] }
}
