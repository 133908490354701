import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("CyclingSummary")
export default class CyclingSummary {
  type: "cycling" = "cycling"

  @JsonProperty("avg_power", Number)
  avgPower: number = undefined!

  @JsonProperty("avg_rpm", Number)
  avgRpm: number = undefined!

  @JsonProperty("total_energy", Number)
  totalEnergy: number = undefined!

  @JsonProperty("distance_f", Number)
  distance: number = undefined!
}
