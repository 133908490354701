import { Middleware } from "services/Middleware"
import {
  Deserializable,
  deserializeArray,
  deserialize,
} from "helpers/serializationHelpers"

// Replaces the blob at 'response.data[key]' with a deserialized representation
export default function DeserializeMiddleware<T>(
  key: string,
  model: Deserializable<T>,
  optional: boolean = false,
  useMeta: boolean = false,
) {
  const middleware: Middleware = (_, next) => {
    return next().then(res => {
      if (!res.data[key] && res.status === 204) {
        return res
      }

      // allows missing keys
      if (res.data[key] == null && optional) return res

      // check against nested key when response contains multiple datasets (data.data)
      const responseData = res.data.data && !useMeta ? res.data.data[key] : res.data[key]

      // this runtime check is only needed for the compile-time type signature :/
      res.data[key] = Array.isArray(responseData)
        ? deserializeArray(responseData, model)
        : deserialize(responseData, model)
      return res
    })
  }
  return middleware
}
