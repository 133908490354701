import React, { useState } from "react"
import * as moment from "moment"
import { observer, inject } from "mobx-react"

import BrandStore from "stores/BrandStore"
import APILoader from "components/wrappers/APILoader"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import Reward from "apps/classpoints/models/Reward"
import ProfileStore from "apps/account/stores/ProfileStore"
import DashboardMainStore from "apps/classpoints/stores/DashboardMainStore"
import EarnedRewardsStore from "apps/classpoints/stores/EarnedRewardsStore"
import RewardStore from "apps/classpoints/stores/RewardStore"

import Heading from "components/Heading"
import PageTracker from "components/wrappers/PageTracker"
import backArrow from "images/classpoints/arrow-narrow-left.svg"


import {
  openModal,
  StudioRewardModal,
  StudioLimitedUseRewardModal,
  RedeemStudioRewardModal,
  GuestPassModal,
  RedeemGuestPassModal,
  SuccessGuestPassModal,
  ErrorGuestPassModal,
  ErrorRewardModal,
} from "./ClassPointsModals"

export interface Props extends RouteComponentProps {
  store?: BrandStore
  progressColors: Object
  optedIn: boolean
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class RewardsPage extends React.Component<Props, {}> {
  profileStore = new ProfileStore(this.props.store!)
  dashboardMainStore = new DashboardMainStore(this.props.store!)
  earnedRewardsStore = new EarnedRewardsStore(this.props.store!)

  componentWillUnmount() {
    this.profileStore.dispose()
    this.dashboardMainStore.dispose()
  }

  public render() {
    return (
      <APILoader
        apiStore={this.profileStore}
        render={({ apiStore: {profile} }) => {
          const optedIn = profile!.classPointsOptedIn || false

          return (
            <>
              <APILoader
                apiStore={this.dashboardMainStore}
                render={({ apiStore: { banner }}) => {
                  return (
                    <>
                      {banner && (
                        <div className="classpoints-banner">{banner}</div>
                      )}
                      <div className={`row dashboard-hero justify-content-center ${banner && 'pt-3'}`}>
                        <Link
                          to={`/classpoints`}
                          className="classpoints-back-link d-flex col col-12 align-items-center back-arrow text-decoration-none text-dark mt-4"
                        >
                          <img src={backArrow} className="pr-2" /> ClassPoints<span className="tm">™</span> Home
                        </Link>
                        <Heading className="h1 col col-12 text-small">ClassPoints<span className="tm">™</span> Rewards</Heading>
                        <PageTracker name="classpoints > rewards" />
                      </div>
                      <APILoader
                        apiStore={this.earnedRewardsStore}
                        elementSize="element"
                        render={() => {
                          let { earnedRewards } = this.earnedRewardsStore

                          return (
                            <div className="classpoints classpoints-rewards row">
                              {earnedRewards.map(reward => (
                                <RewardRow key={reward.id} reward={reward} store={this.props.store!} progressColors={this.props.progressColors} profileStore={this.profileStore} />
                              ))}
                              {!earnedRewards.length && (
                                <div className="d-flex flex-column col-12 pt-5 pb-4">
                                  No rewards earned yet.
                                </div>
                              )}
                            </div>
                          )
                        }}
                      />
                    </>
                  )
                }}
              />
            </>
          )
        }}
      />
    )
  }
}

const RewardRow = ({reward, store, progressColors, profileStore}: {reward: Reward, store: BrandStore, progressColors: Object, profileStore: ProfileStore}) => {
  const rewardStore = new RewardStore(store)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const openStudioRewardModal = (reward: Reward) => {
    const children = <StudioRewardModal store={store} reward={reward} />
    openModal(store, children)
  }

  const openStudioLimitedUseRewardModal = (reward: Reward) => {
    const children = <StudioLimitedUseRewardModal store={store} reward={reward} openRedeemStudioRewardModal={openRedeemStudioRewardModal} />
    openModal(store, children)
  }

  const openRedeemStudioRewardModal = () => {
    const children = <RedeemStudioRewardModal store={store} reward={rewardStore.reward} rewardStore={rewardStore} profileStore={profileStore} openErrorRewardModal={openErrorRewardModal} />
    openModal(store, children)
  }

  const openGuestPassModal = (reward: Reward) => {
    const children = <GuestPassModal store={store} reward={reward} openRedeemGuestPassModal={openRedeemGuestPassModal} />
    openModal(store, children)
  }

  const openRedeemGuestPassModal = () => {
    const children = <RedeemGuestPassModal store={store} reward={reward} openSuccessGuestPassModal={openSuccessGuestPassModal} openErrorGuestPassModal={openErrorGuestPassModal} />
    openModal(store, children)
  }

  const openSuccessGuestPassModal = () => {
    const children = <SuccessGuestPassModal store={store} profileStore={profileStore} />
    openModal(store, children)
  }

  const openErrorGuestPassModal = () => {
    const children = <ErrorGuestPassModal store={store} />
    openModal(store, children)
  }

  const openErrorRewardModal = () => {
    const children = <ErrorRewardModal store={store} />
    openModal(store, children)
  }

  const handleRetailDiscountClick = () => {
    rewardStore.fetch(reward.id).then(() => {
      rewardStore.reward.isLimitedUse && rewardStore.reward.isRedemptionTracked
        ? openStudioLimitedUseRewardModal(rewardStore.reward)
        : openStudioRewardModal(rewardStore.reward)
    })
    store!.track.event("classpoints_tap redeem retail discount")
  }

  const rewardButton = (rewardType: string) => {
    // Possible reward types: (not all are in use as of launch)
    // class_token, xpass_deal, package, retail_discount, guest_pass
    switch (rewardType) {
      case "guest_pass":
        return reward!.redeemableCount! > 0
          ? (<button className="btn btn-primary btn-block" onClick={() => openGuestPassModal(reward)} disabled={reward!.expired}>Redeem</button>)
          : (<button className="btn btn-primary btn-block btn-light" disabled>Redeemed</button>)
      case "retail_discount":
        return (<button className="btn btn-primary btn-block" onClick={handleRetailDiscountClick}>Use at Studio</button>)
      case "class_token":
        return (
          <a 
            className="btn btn-primary btn-block" 
            href="https://members.xpass.fit" 
            target="_blank"
            onClick={() => store!.track.event("classpoints_tap redeem on xpass")}
          >
            Redeem on XPASS
          </a>
        )
      default:
        return
    }
  }

  return (
    <div className="d-flex flex-column col-12 pt-2 pb-4">
      <div className="d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap">
        <div className="classpoints-rewards__left d-flex align-items-center">
          <div className="classpoints-rewards__img mr-4">
            <img src={reward.thumbnailImageUrl} />
          </div>
          <div className="font-24 font-600">{reward.name}</div>
        </div>
        <button className="classpoints-rewards__center btn-none d-flex align-items-start" onClick={() => setIsOpen(!isOpen)}>
          <span className={`arrow ${isOpen ? 'open' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M8.10084 8.6249L6.73364 10.0249L12.5931 16.0249L18.4525 10.0249L17.0853 8.6249L12.5931 13.2249L8.10084 8.6249Z" fill="black"/>
            </svg>
          </span>
          <span className="pl-2 text-left">{reward.shortDescription}</span>
        </button>
        <div className="classpoints-rewards__right d-flex align-items-center justify-content-between justify-content-md-end font-14 py-2 py-md-0">
          <div className="font-14 pr-md-4">{moment(reward.earnedAtDate).format('M/D/yyyy')}</div>
          <div className="classpoints-rewards__action">
            {rewardButton(reward.rewardType || '')}
          </div>
        </div>
      </div>
      <div className={`classpoints-rewards__drawer font-14 pt-3 ${isOpen && 'open'}`}>
        <div>{reward.description}</div>
      </div>
    </div>
  )
}