import { JsonCustomConvert, JsonConverter } from "json2typescript"
import moment, { Moment } from "moment"

@JsonConverter
export default class DateConverter implements JsonCustomConvert<Moment> {
  serialize(date: Moment): string {
    return date.toISOString()
  }
  deserialize(date: string): Moment {
    if (!date) {
      // how is this supposed to work..?
      return undefined!
    }
    return moment.parseZone(date)
  }
}
