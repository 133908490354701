import { observable, action, computed } from "mobx"
import moment, { Moment } from "moment"
import BaseStore from "stores/BaseStore"
import BookingStore from "apps/book/stores/BookingStore"
import BrandStore from "stores/BrandStore"
import ScheduleEntryStore from "apps/xpass/stores/ScheduleEntryStore"
import LocationSummary from "models/LocationSummary"
import ServiceBookingStore, {
  ServiceBookingProps,
} from "apps/book/stores/ServiceBookingStore"
import {
  parse,
  fromServiceBookingProps,
  toServiceBookingProps,
  stringify,
} from "helpers/queryString"
import { formatTime } from "helpers/dateHelper"

interface Props {
  location: LocationSummary
  brandStore: BrandStore
  serviceBookingProps?: ServiceBookingProps
  scheduleEntryId?: string
  offer?: string
}

/**
 * This store tracks whether a purchase (or another flow) is happening as a part
 * of a booking flow. If so, it tracks the details needed to provide basic info
 * about the booking and make sure the user is able to resume the proper flow
 * after payment
 */
export default class BookingInfoStore extends BaseStore {
  location: LocationSummary
  brandStore: BrandStore

  @observable scheduleEntryId?: string
  @observable serviceBookingProps?: ServiceBookingProps
  @observable bookingStore: BookingStore
  @observable serviceBookingStore: ServiceBookingStore
  @observable bookingSummary: string = ""
  @observable offer?: string
  @observable scheduleEntryStore?: ScheduleEntryStore

  @computed
  get bookingStatus() {
    return this.bookingStore.bookingStatus
  }

  @computed
  get hasBookingInfo() {
    return Boolean(this.serviceDurationId || this.scheduleEntryId)
  }

  @computed
  get serviceDurationId() {
    return this.serviceBookingProps && this.serviceBookingProps.durationId
  }

  @computed
  get serviceTypeId() {
    const { serviceType } = this.serviceBookingStore
    return serviceType && serviceType.id
  }

  @computed
  get bookingPath() {
    if (this.scheduleEntryId) {
      return `/book/${this.location.id}/${this.scheduleEntryId}?${stringify({
        offer: this.offer,
      })}`
    } else if (this.serviceBookingProps) {
      return `/book/${this.location.id}/services/${this.serviceTypeId}/confirm?${this.serviceBookingQueryString}`
    } else {
      return `/book/${this.location.id}`
    }
  }

  @computed
  get bookingTypeCopy() {
    const { copy } = this.brandStore
    return (
      (this.scheduleEntryId && copy.class) ||
      (this.serviceBookingProps && copy.session) ||
      null
    )
  }

  @computed
  get serviceBookingQueryString() {
    return fromServiceBookingProps(this.serviceBookingProps!, {
      offer: this.offer,
    })
  }

  static fromQueryString(
    location: LocationSummary,
    brandStore: BrandStore,
    queryString: string = ""
  ) {
    const query = parse(queryString)
    let props: Props = {
      location,
      brandStore,
      scheduleEntryId: query.schedule_entry_id,
      offer: query.offer,
    }
    let serviceBookingProps = toServiceBookingProps(queryString)
    const bookingProps = brandStore.isXponential
      ? serviceBookingProps.durationId &&
        serviceBookingProps.instructorId &&
        serviceBookingProps.startsAt &&
        serviceBookingProps.locationId &&
        serviceBookingProps.userPremiumCost
      : serviceBookingProps.durationId &&
        serviceBookingProps.instructorId &&
        serviceBookingProps.startsAt

    if (bookingProps) {
      props.serviceBookingProps = serviceBookingProps
    }
    return new BookingInfoStore(props)
  }

  constructor({
    location,
    brandStore,
    serviceBookingProps,
    scheduleEntryId,
    offer,
  }: Props) {
    super()
    this.brandStore = brandStore
    this.location = location
    this.scheduleEntryId = scheduleEntryId
    this.offer = offer
    this.bookingStore = new BookingStore(this.location, this.brandStore)
    this.serviceBookingProps = serviceBookingProps

    this.serviceBookingStore = new ServiceBookingStore(
      this.location,
      this.brandStore
    )
  }

  @action
  fetchBookingInfo(): Promise<void> {
    if (this.scheduleEntryId) {
      return this.fetchClassBookingInfo()
    } else if (this.serviceBookingProps) {
      return this.fetchServiceBookingInfo()
    } else {
      return Promise.resolve()
    }
  }

  private async fetchClassBookingInfo() {
    const setSummary = () => {
      const { scheduleEntry } = this.bookingStore
      if (scheduleEntry) {
        const startTime = this.brandStore.isXponential
          ? scheduleEntry.startTimeTZ
          : scheduleEntry.startTime
        const fullTitle = [scheduleEntry!.title, scheduleEntry!.subtitle]
          .filter(Boolean)
          .join(" - ")
        this.bookingSummary = `${fullTitle}: ${scheduleEntry.startsAt.format(
          "dddd, MMM D"
        )}, ${startTime}`
      }
    }

    if (this.brandStore.isXponential) {
      this.scheduleEntryStore = new ScheduleEntryStore(this.brandStore.userStore)

      return await this.scheduleEntryStore.fetch(this.scheduleEntryId!).then(() => {
        const locationId = this.scheduleEntryStore!.scheduleEntry!.location.id
        this.bookingStore.fetch({ id: this.scheduleEntryId!, locationId }).then(() => setSummary())
      })
    }
    return this.bookingStore.fetch({ id: this.scheduleEntryId! }).then(() => setSummary())
  }

  private fetchServiceBookingInfo() {
    return this.serviceBookingStore
      .fetch(this.serviceBookingProps!)
      .then(() => {
        const { bookingStatus, serviceType } = this.serviceBookingStore
        const { startsAt } = bookingStatus!.scheduleEntry!

        if (serviceType) {
          this.bookingSummary = `${serviceType.name}: ${startsAt.local().format(
            "dddd, MMM D"
          )}, ${this.brandStore.isXponential ? startsAt.local().format("h:mma") : formatTime(startsAt!)}`
        }
      })
  }
}
