import { observable, action } from "mobx"
import { AxiosResponse, AxiosError } from "axios"
import * as geolib from "geolib"

import ScheduleEntry from "apps/book/models/xpass/ScheduleEntry"
import ScheduleEntryLocation from "apps/book/models/xpass/ScheduleEntryLocation"
import UserCoordinatesStore from "apps/book/stores/xpass/UserCoordinatesStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import APIStore from "stores/APIStore"
import { getLocal, setLocal } from "utils/LocalStorage"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"

export interface ScheduleEntryLocationResponse {
  locations: ScheduleEntryLocation[]
}

export default class ScheduleEntryLocationStore extends APIStore {
  @observable scheduleEntryLocations: ScheduleEntryLocation[] = []
  @observable optedInLocations: ScheduleEntryLocation[] = []
  @observable enabledLocations: ScheduleEntryLocation[] = []
  @observable disabledLocations: ScheduleEntryLocation[] = []
  @observable selectedCity: string = ""
  @observable isLoadingLocations: boolean = false
  @observable initialLoaded: boolean = false

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("locations", ScheduleEntryLocation),
  ])

  constructor(protected scheduleEntryStore: ScheduleEntryStore, protected userCoordinatesStore: UserCoordinatesStore) {
    super()
  }

  // https://github.com/manuelbieh/geolib
  // Returns the center coordinates of the bounds of geo coordinates
  @action.bound
  findCenterCoordinates(locations: ScheduleEntryLocation[]) {
    const getCoordinates = locations.map(location => {
      return { latitude: location.lat, longitude: location.lng }
    })
    return geolib.getCenterOfBounds(getCoordinates)
  }

  filterLocations(locations: ScheduleEntryLocation[]) {
    const { userCoordinates, locationCoordinates } = this.userCoordinatesStore
    const { selectedBrands, distanceMiles } = this.scheduleEntryStore

    const userCoord = userCoordinates && { latitude: userCoordinates.lat, longitude: userCoordinates.lng }
    // 1km = 1000, 1mi = 1.61km
    const radius = distanceMiles && distanceMiles !== 10 ? (distanceMiles * 1.61) * 1000 : 40000
    const centerPoint = locationCoordinates ? { latitude: locationCoordinates.lat, longitude: locationCoordinates.lng } : userCoord!

    // Find locations within radius
    const locationsWithinRadius = locations.filter((location) => {
      const locationCoord = { latitude: location.lat, longitude: location.lng }
      const isWithinRadius = location.lat && location.lng && userCoord ? geolib.isPointWithinRadius(locationCoord, centerPoint, radius) : false
      return isWithinRadius === true
    })

    // Sort locations by distance to center point
    const locationsWithinRadiusCoordinates = locationsWithinRadius.map(loc => { return { latitude: loc.lat, longitude: loc.lng }})
    const orderLocationsByDistance = geolib.orderByDistance(centerPoint, locationsWithinRadiusCoordinates)
    const arr = orderLocationsByDistance.map((loc: any) => loc.longitude)
    const sortedArr = locationsWithinRadius.sort((a, b) => {
      return arr.indexOf(a.lng) - arr.indexOf(b.lng)
    })

    // Remove duplicate locations
    const duplicatesRemoved = sortedArr.filter((loc, index, arr) => arr.findIndex(item => item.address === loc.address && item.slug === loc.slug) === index)

    // Filter if selected brands
    return selectedBrands && selectedBrands.length > 0 ? duplicatesRemoved.filter(loc => selectedBrands.includes(loc.brandSlug)) : duplicatesRemoved
  }

  fetch() {
    this.isLoadingLocations = true

    const xpassLocations = getLocal<any>("xpassS3Locations")
    if (xpassLocations && xpassLocations.expiry > Date.now()) {
      return this.handleSuccess(xpassLocations.value)
    } else {
      // If xpassS3Locations does not exist in localStorage, set with expiry time
      const { xpassS3LocationsUrl } = window.globals
      return xpassS3LocationsUrl && this.api.get(xpassS3LocationsUrl).then(res => {
        const currentTime = Date.now()
        // Expiry time = 15 minutes in milliseconds
        const expiry = currentTime + (15 * 60000)
        const data = { value: { data: res.data }, expiry }
        setLocal("xpassS3Locations", data)
        return res
      }).catch((err) => {
        this.handleError(err)
      })
    }
  }

  // Selected city from search by city
  @action setSelectedCity(city: string) {
    this.selectedCity = city
  }
  // Reset sorted locations
  @action resetDisabledLocations() {
    this.disabledLocations = []
  }
  @action resetEnabledLocations() {
    this.enabledLocations = []
  }

  @action.bound
  handleSuccess(res: AxiosResponse<ScheduleEntryLocationResponse>) {
    const { locations } = res.data
    const filterLocations = this.filterLocations(locations)

    this.scheduleEntryLocations = locations.sort((a, b) => {
      return a.city!.localeCompare(b.city!)
    })

    // `isLoadingLocations` is used to remove/re-add the pins on the Book a Class page map when the Search by City filter is used and the map location changes.
    // Set a delay so that there is enough time for the map pins to be removed before they are re-added.
    setTimeout(() => {
      this.isLoadingLocations = false
    }, 1000)

    this.optedInLocations = filterLocations.filter(loc => loc.xpassOptedIn)
    this.enabledLocations = filterLocations.filter(loc => loc.xpassOptedIn && (loc.classesAvailable || loc.servicesAvailable))
    this.disabledLocations = filterLocations.filter(loc => !loc.xpassOptedIn || (!loc.classesAvailable && !loc.servicesAvailable))
    return res
  }

  @action.bound
  handleError(err: AxiosError) {
    console.log(err.response)
    if (err.response && err.response.status === 404) {
      this.disabledLocations = []
      this.enabledLocations = []
      this.optedInLocations = []
      return err.response
    }
    throw err
  }
}
