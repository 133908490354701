import {
  BrandData,
  baseSettings,
  baseStyles,
  baseStyleClasses,
  baseCopy,
  BrandCopy,
  BrandStyles,
  BrandStyleClasses,
  BadgeBitmap,
  eywAffiliateLinks,
} from "themes/base"

import badgeImage from "images/badges/purebarre-badge.png"
import logoUrl from "images/logos/purebarre-logo.svg"
import bannerImage from "images/dashboard/purebarre_banner_card.jpg"
import appPromoImage_Video from "images/video/purebarre-app-promo.png"
import videoLandingLogo from "images/video/purebarre-go-logo.png"
import appleWatchIcon from "images/apple_health/watch/purebarre.svg"
import classpointsHero from "images/classpoints/classpoints-hero_purebarre.png"
import brandIcon from "images/classpoints/brands/PB.svg"

import badge10Active from "images/badges/purebarre/PureBarre-10-Classes-Active.png"
import badge50Active from "images/badges/purebarre/PureBarre-50-Classes-Active.png"
import badge100Active from "images/badges/purebarre/PureBarre-100-Classes-Active.png"
import badge250Active from "images/badges/purebarre/PureBarre-250-Classes-Active.png"
import badge500Active from "images/badges/purebarre/PureBarre-500-Classes-Active.png"
import badge750Active from "images/badges/purebarre/PureBarre-750-Classes-Active.png"
import badge1000Active from "images/badges/purebarre/PureBarre-1000-Classes-Active.png"
import badge1500Active from "images/badges/purebarre/PureBarre-1500-Classes-Active.png"
import badge2000Active from "images/badges/purebarre/PureBarre-2000-Classes-Active.png"
import badge2500Active from "images/badges/purebarre/PureBarre-2500-Classes-Active.png"
import badge3000Active from "images/badges/purebarre/PureBarre-3000-Classes-Active.png"
import badge3500Active from "images/badges/purebarre/PureBarre-3500-Classes-Active.png"
import badge4000Active from "images/badges/purebarre/PureBarre-4000-Classes-Active.png"
import badge4500Active from "images/badges/purebarre/PureBarre-4500-Classes-Active.png"
import badge5000Active from "images/badges/purebarre/PureBarre-5000-Classes-Active.png"
import badge5500Active from "images/badges/purebarre/PureBarre-5500-Classes-Active.png"
import badge6000Active from "images/badges/purebarre/PureBarre-6000-Classes-Active.png"
import badge6500Active from "images/badges/purebarre/PureBarre-6500-Classes-Active.png"
import badge7000Active from "images/badges/purebarre/PureBarre-7000-Classes-Active.png"

import badge10Inactive from "images/badges/purebarre/PureBarre-10-Classes-Inactive.png"
import badge50Inactive from "images/badges/purebarre/PureBarre-50-Classes-Inactive.png"
import badge100Inactive from "images/badges/purebarre/PureBarre-100-Classes-Inactive.png"
import badge250Inactive from "images/badges/purebarre/PureBarre-250-Classes-Inactive.png"
import badge500Inactive from "images/badges/purebarre/PureBarre-500-Classes-Inactive.png"
import badge750Inactive from "images/badges/purebarre/PureBarre-750-Classes-Inactive.png"
import badge1000Inactive from "images/badges/purebarre/PureBarre-1000-Classes-Inactive.png"
import badge1500Inactive from "images/badges/purebarre/PureBarre-1500-Classes-Inactive.png"
import badge2000Inactive from "images/badges/purebarre/PureBarre-2000-Classes-Inactive.png"
import badge2500Inactive from "images/badges/purebarre/PureBarre-2500-Classes-Inactive.png"
import badge3000Inactive from "images/badges/purebarre/PureBarre-3000-Classes-Inactive.png"
import badge3500Inactive from "images/badges/purebarre/PureBarre-3500-Classes-Inactive.png"
import badge4000Inactive from "images/badges/purebarre/PureBarre-4000-Classes-Inactive.png"
import badge4500Inactive from "images/badges/purebarre/PureBarre-4500-Classes-Inactive.png"
import badge5000Inactive from "images/badges/purebarre/PureBarre-5000-Classes-Inactive.png"
import badge5500Inactive from "images/badges/purebarre/PureBarre-5500-Classes-Inactive.png"
import badge6000Inactive from "images/badges/purebarre/PureBarre-6000-Classes-Inactive.png"
import badge6500Inactive from "images/badges/purebarre/PureBarre-6500-Classes-Inactive.png"
import badge7000Inactive from "images/badges/purebarre/PureBarre-7000-Classes-Inactive.png"

const copy: BrandCopy = {
  ...baseCopy,
  seat: "Class",
  bookClass: "Reserve",
  classPackages: "Our memberships offer a better value than class packs. Call your local studio to find out more about our membership options and specials."
}

const styles: BrandStyles = {
  ...baseStyles,
  badgeImage,
  logoUrl,
  bannerImage,
  appPromoImage_Video,
  videoLandingLogo,
  appleWatchIcon,
  classpointsHero,
  brandIcon
}

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
}

const badges: BadgeBitmap[] = [
  {
    label: "10 Classes",
    value: 10,
    active: badge10Active,
    inactive: badge10Inactive,
  },
  {
    label: "50 Classes",
    value: 50,
    active: badge50Active,
    inactive: badge50Inactive,
  },
  {
    label: "100 Classes",
    value: 100,
    active: badge100Active,
    inactive: badge100Inactive,
  },
  {
    label: "250 Classes",
    value: 250,
    active: badge250Active,
    inactive: badge250Inactive,
  },
  {
    label: "500 Classes",
    value: 500,
    active: badge500Active,
    inactive: badge500Inactive,
  },
  {
    label: "750 Classes",
    value: 750,
    active: badge750Active,
    inactive: badge750Inactive,
  },
  {
    label: "1000 Classes",
    value: 1000,
    active: badge1000Active,
    inactive: badge1000Inactive,
  },
  {
    label: "1500 Classes",
    value: 1500,
    active: badge1500Active,
    inactive: badge1500Inactive,
  },
  {
    label: "2000 Classes",
    value: 2000,
    active: badge2000Active,
    inactive: badge2000Inactive,
  },
  {
    label: "2500 Classes",
    value: 2500,
    active: badge2500Active,
    inactive: badge2500Inactive,
  },
  {
    label: "3000 Classes",
    value: 3000,
    active: badge3000Active,
    inactive: badge3000Inactive,
  },
  {
    label: "3500 Classes",
    value: 3500,
    active: badge3500Active,
    inactive: badge3500Inactive,
  },
  {
    label: "4000 Classes",
    value: 4000,
    active: badge4000Active,
    inactive: badge4000Inactive,
  },
  {
    label: "4500 Classes",
    value: 4500,
    active: badge4500Active,
    inactive: badge4500Inactive,
  },
  {
    label: "5000 Classes",
    value: 5000,
    active: badge5000Active,
    inactive: badge5000Inactive,
  },
  {
    label: "5500 Classes",
    value: 5500,
    active: badge5500Active,
    inactive: badge5500Inactive,
  },
  {
    label: "6000 Classes",
    value: 6000,
    active: badge6000Active,
    inactive: badge6000Inactive,
  },
  {
    label: "6500 Classes",
    value: 6500,
    active: badge6500Active,
    inactive: badge6500Inactive,
  },
  {
    label: "7000 Classes",
    value: 7000,
    active: badge7000Active,
    inactive: badge7000Inactive,
  },
]

const purebarre: BrandData = {
  settings: baseSettings,
  styles,
  styleClasses,
  copy,
  dashboardCards: [
    "NextClassCard",
    "TodaysClassCard",
    "AchievementsCard",
    "AttendanceCard",
    "BadgeCard",
    "ClassPointsCard",
    "AppleHealthCard",
    "ProfileCard",
    "MobileAppCard",
    "MobileOfferCard",
    "PromoCards",
  ],
  badges,
  metrics: [],
  summaryMetrics: [],
  eywAffiliateLinks: eywAffiliateLinks["purebarre"],
  eywLegalLink: "https://www.purebarre.com/terms-earn-your-watch",
  eywParticipatingLink: "https://www.purebarre.com/earn-your-watch-locations"
}

export default purebarre
