import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import APIStore from "stores/APIStore"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import PurchaseSummary from "apps/account/models/PurchaseSummary"
import Package from "models/Package"
import PlanStore from "apps/buy/stores/PlanStore"
import GiftCardStore from "apps/buy/stores/GiftCardStore"
import { isFormError } from "helpers/errorHandling"

interface PurchaseResponse {
  purchase: PurchaseSummary
}

export default class PurchaseStore extends APIStore {
  @observable purchase?: PurchaseSummary

  api = this.createClient<PurchaseResponse>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    TokenAuthMiddleware(this.planStore.brandStore.userStore),
    DeserializeMiddleware("purchase", PurchaseSummary),
  ])

  @computed get locationSummary() {
    return this.planStore.locationSummary
  }

  @computed get plan() {
    return this.planStore.plan!
  }

  @computed get pkg() {
    // pkg will be optional some day
    return this.planStore.package!
  }

  @computed get promoCode() {
    return this.planStore.promoCode
  }

  constructor(public planStore: PlanStore, public giftCardStore: GiftCardStore | undefined, public isXponential: boolean) {
    super()
  }

  makePurchase() {
    const url = this.isXponential
      ? `/api/xpass/packages/${this.pkg.id}/purchases`
      : `/api/locations/${this.locationSummary.id}/packages/${this.pkg.id}/purchases`

    return this.api.post(url, {
        payment_amount: this.plan.todayTotal.numeric,
        promo_code: this.planStore.promoCode,
        gift_card_acct_token: this.giftCardStore ? this.giftCardStore!.acctToken : undefined,
        gift_card_number: this.giftCardStore ? this.giftCardStore!.giftCardNumber : undefined,
        gift_card_payment_amount: this.giftCardStore && this.giftCardStore.paymentAmount > 0 ? this.giftCardStore!.paymentAmount : undefined,
      }
    )
  }

  updatePurchase(purchaseId: string, pkg: Package, amount?: String) {
    const paymentAmount = amount || pkg.price.raw
    const baseUrl = this.isXponential
    ? "/api/xpass/purchases"
    : `/api/locations/${this.locationSummary.id}/purchases`

    return this.api.put(`${baseUrl}/${purchaseId}`, {
      payment_amount: paymentAmount,
      target_package_id: pkg.id,
    })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<PurchaseResponse>) {
    this.purchase = res.data.purchase
    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    // TODO: nicer error handling
    if (isFormError(ex.response)) {
      this.planStore.brandStore.uiStore.openMessage(
        "error",
        ex.response.data.error_messages.join("\n"),
        "Something went wrong"
      )
      throw ex
    }

    const message = ex.response && ex.response.data.message ?
      ex.response.data.message :
      "Something went wrong"
    this.planStore.brandStore.uiStore.openMessage(
      "error",
      message
    )
    throw ex
  }
}
