import React from "react"
import {
  NavLink,
  withRouter,
  RouteComponentProps,
  NavLinkProps,
} from "react-router-dom"
import cx from "classnames"

interface SubMenuProps extends RouteComponentProps {
  className?: string
  linkClassName?: string
  itemClassName?: string
  // className passed to these will override the one above
  links: NavLinkProps[]
}

const SubMenu = ({
  className,
  links,
  linkClassName,
  itemClassName,
}: SubMenuProps) => {
  if (links.length < 2) {
    return null
  }

  return (
    <ul
      className={cx(
        "submenu",
        "nav",
        "flex-nowrap",
        "justify-content-md-start",
        "align-items-center",
        "w-100",
        className,
        {
          "justify-content-start": links.length < 3,
          "justify-content-between": links.length >= 3,
        }
      )}
    >
      {links.map((props, i) => {
        const clsName = cx("nav-link m-0 py-1", linkClassName)
        return (
          <li key={`${props.to}${i}`} className={`nav-item ${itemClassName}`}>
            <NavLink className={clsName} {...props} />
          </li>
        )
      })}
    </ul>
  )
}

export default withRouter(SubMenu)
