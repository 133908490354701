import APIStore from "stores/APIStore"

import BrandStore from "stores/BrandStore"
import { FormValues } from "apps/account/components/PasswordUpdateForm"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export default class PasswordUpdateStore extends APIStore {
  api = this.createClient([TokenAuthMiddleware(this.brandStore.userStore)])

  constructor(public brandStore: BrandStore) {
    super()
  }

  update(values: FormValues) {
    const url = this.brandStore.isXponential ?
      "/api/xpass/user_passwords" :
      "/api/user_passwords"
    return this.api.put(url, { user: values })
  }
}
