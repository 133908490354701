import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import { observable, action, computed } from "mobx"
import getIn from "lodash/get"
import Package from "models/Package"
import LocationSummary from "models/LocationSummary"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import BookingInfoStore from "apps/buy/stores/BookingInfoStore"
import BrandStore from "stores/BrandStore"

export interface Params {
  forVod?: boolean
  showAll?: boolean
  nonPurchaseFlow?: boolean
}

export default class PackagesStore extends APIStore {
  @observable allPackages: Package[] = []
  @observable isXpassEligible: boolean = false

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    TokenAuthMiddleware(this.brandStore.userStore),
    DeserializeMiddleware("packages", Package),
  ])

  @computed get scheduleEntryId() {
    return this._bookingInfoStore.scheduleEntryId
  }

  @computed get serviceDurationId() {
    return this._bookingInfoStore.serviceDurationId
  }

  constructor(
    public brandStore: BrandStore,
    public location: LocationSummary,
    private _bookingInfoStore: BookingInfoStore
  ) {
    super()
  }

  fetch(paramProps: Params = {}) {
    let params = {}
    if (this.scheduleEntryId) {
      params = { schedule_entry_id: this.scheduleEntryId }
    } else if (this.serviceDurationId) {
      params = { duration_id: this.serviceDurationId }
    }

    if (paramProps.forVod) params["for_vod"] = true

    if (this.brandStore.isXponential) {
      // We don't want to send the schedule entry ID or service duration ID to
      params["schedule_entry_id"] = undefined
      params["duration_id"] = undefined
      if (paramProps.nonPurchaseFlow) params["non_purchase_flow"] = true
      this.api.get("/api/xpass/v3/offer_slots/get_credits", { params })
    }
    return this.api.get(`/api/locations/${this.location.id}/packages`, {
      params,
    })
  }

  @computed
  get packages() {
    return this.allPackages.filter(p => !p.isMembership)
  }

  @computed
  get memberships() {
    return this.allPackages.filter(p => p.isMembership)
  }

  @computed
  get addOns() {
    return this.allPackages.filter(p => !p.isMembership && !p.isXpassFree)
  }

  @computed
  get tokens() {
    return this.allPackages.filter(p => !p.isMembership && !p.isXpassFree)
  }

  @computed
  get cacheKey() {
    return (
      (this.scheduleEntryId && `sid_${this.scheduleEntryId}`) ||
      (this.serviceDurationId && `did_${this.serviceDurationId}`) ||
      "all"
    )
  }

  @computed
  get multibrandVodPackage() {
    return this.allPackages.find(p => p.multiBrand)
  }

  @action
  setBookingInfo(newStore: BookingInfoStore) {
    this._bookingInfoStore = newStore
    this.allPackages = []
    this.setStatus("idle")
  }

  @action.bound
  handleSuccess(res: AxiosResponse<{
    data: any; packages: Package[] 
}>) {
    this.allPackages = res.data.packages
    if (this.brandStore.isXponential) {
      this.isXpassEligible = res.data.data.xpass_eligible
    }
    return res
  }
}
