import * as React from "react"
import { observer } from "mobx-react"
import { Moment } from "moment"
import nl2br from "helpers/nl2br"
import BrandStore from "stores/BrandStore"
import Package from "models/Package"
import Purchase from "apps/account/models/Purchase"

interface Props {
  pkg: Package
  expiresAt?: Moment
  pad?: boolean
  store?: BrandStore
  purchase?: Purchase
  xpassCredits?: number
  paymentPage?: boolean
}

const PackageRenewalText: React.FunctionComponent<Props> = ({
  pkg,
  pad,
  expiresAt,
  store,
  purchase,
  xpassCredits,
  paymentPage,
}) => {
  const { isXponential } = store!
  const padding = pad && pkg.description ? <br /> : ""
  const {
    isCancelled, scheduledCancelDateUtc,
    cancelledAt, creditCountTotal, creditsExpireAt
  } = purchase || {} as Purchase

  const effectiveCancelDate = cancelledAt || scheduledCancelDateUtc

  // Show package description if package has description and:
  // * Non-XPASS: user purchased the package and it's not expired yet
  // * XPASS: this is included in PaymentPage component (check out flow)
  if (pkg.description &&
    (!isXponential && !expiresAt) || (isXponential && !!paymentPage)
  ) {
    return nl2br(pkg.description)
  }

  // Show "Cancelled ...." if package is cancelled and still active:
  // * Non-XPASS: that's it
  if (pkg.isRecurring && pkg.intervalCount && pkg.interval &&
    !isXponential && isCancelled && pkg.packageType !== "package"
  ) {
    return (
      <>
        <h5>Cancelled</h5>
        {scheduledCancelDateUtc &&
          <>Effective {scheduledCancelDateUtc.format(store!.styleClasses.PackageRenewalText_dateFormat)}</>
        }
      </>
    )
  }

  // Show "Auto-renews ...." if package is recurring and has interval and intervalCount and:
  // * Non-XPASS: that's it
  // * XPASS: package purchase is not cancelled and package packageType is "package"
  if (pkg.isRecurring && pkg.intervalCount && pkg.interval &&
    (!isXponential || (!isCancelled && pkg.packageType !== "package"))
  ) {
    return (
      <>
        Auto-renews{" "}
        {pkg.intervalCount > 1
          ? `every ${pkg.intervalCount} ${pkg.interval}s`
          : `${pkg.interval}ly`}
        <br />
        {!!pkg.paymentCount && pkg.paymentCount > 1 ? (
          <>over {pkg.paymentCount} payments</>
        ) : (
          padding
        )}
      </>
    )
  }

  // Show "Expires ..." if user purchased the package and it's expiredand:
  // * Non-XPASS: that's it
  // * XPASS: package packageType is "package"
  if (expiresAt && (!isXponential || pkg.packageType === "package")) {
    return (
      <>
        Expires {expiresAt.format(store!.styleClasses.PackageRenewalText_dateFormat)}
        {padding}
        {padding}
      </>
    )
  }

  // Show "Subscription cancelled...." if:
  // * XPASS and package purchase is cancelled and package purchase has effectiveCancelDate
  if (isXponential && isCancelled && effectiveCancelDate) {
    return (
      <>
        <h3>Subscription cancelled</h3>
        Cancellation effective {effectiveCancelDate.format(store!.styleClasses.PackageRenewalText_dateFormat)}
        {padding}
        {padding}
      </>
    )
  }

  // Show ".... expiration" if:
  // * XPASS and package has intervalCount and interval (and not cancelled)
  if (isXponential && pkg.intervalCount && pkg.interval) {
    return (
      <>
        {pkg.intervalCount} {pkg.interval} expiration
        {creditsExpireAt ?
          (<> <i>({creditsExpireAt.format(store!.styleClasses.PackageRenewalText_dateFormat)})</i></>)
          :
          ("")
        }
        <br />
        {xpassCredits && creditCountTotal ?
          (<>{xpassCredits} / {creditCountTotal}</>)
          :
          ("")
        }
        <br />
        {padding}
        {padding}
      </>
    )
  }

  // None of the above, just return with paddings if applicable
  return (
    <>
      {padding}
      {padding}
      {padding}
    </>
  )
}

export default observer(PackageRenewalText)
