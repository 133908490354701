import * as React from "react"
import cx from "classnames"
import { inject, observer } from "mobx-react"
import capitalize from "lodash/capitalize"
import BrandStore from "stores/BrandStore"
import { Link } from "react-router-dom"
import APILoader from "components/wrappers/APILoader"
import FavoriteHeart from "components/FavoriteHeart"
import DashboardApiStore from "apps/dashboard/stores/DashboardApiStore"
import { buttonText } from "apps/book/components/BookButton"
import BookingsStore from "apps/bookings/stores/BookingsStore"
import ScheduleEntry from "models/ScheduleEntry"
import ScheduleEntryXpass from "apps/book/models/xpass/ScheduleEntry"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
interface Props {
  className?: string
  store?: BrandStore
  dashboardStore: DashboardApiStore
  scheduleEntryStore: ScheduleEntryStore
  onBookingModal: Function
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class TodaysClassCard extends React.Component<Props> {
  bookingsStore = new BookingsStore(this.props.store!)

  handleHeaderClick = (_: LinkEvent) => {
    const store = this.props.store!
    store.track.event("home_tap view classes", {
      loc: store.locStore.currentLocation,
    })
  }

  handleBookClick = (entry: ScheduleEntry | ScheduleEntryXpass) => (_: LinkEvent) => {
    const store = this.props.store!
    store.track.event("booking_tap book", {
      entry,
      loc: store.locStore.currentLocation,
    })
  }

  isEntryBookable(entry: ScheduleEntry | ScheduleEntryXpass) {
    const { isFrozen } = this.props.store!.userStore.session!

    return (
      !entry.notBookable && !isFrozen
    )
  }

  render() {
    const { currentLocation } = this.props.store!.locStore
    const { copy, isXponential, styleClasses, brandId } = this.props.store!
    const { isFrozen } = this.props.store!.userStore.session!

    return (
      <div className={cx("card", "todays-classes-card", this.props.className)}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="text-dark">
            {currentLocation!.name} {capitalize(copy.classes)}
          </h5>
          <small>
            <Link
              onClick={this.handleHeaderClick}
              to={`/book/${currentLocation!.id}`}
            >
              View All
            </Link>
          </small>
        </div>
        {isXponential &&
          <span className="border-top border-light" />
        }
        <div className="card-body d-flex flex-column p-0">
          {isXponential ?
            <APILoader
              apiStore={this.props.scheduleEntryStore}
              elementSize="element"
              propagateErrors
              fetchProps={currentLocation!.id}
              render={() => {

                let entries = this.props.scheduleEntryStore.scheduleEntries!
                let upcomingEntries = entries.slice(0, 3)

                return (
                  <>
                    {!entries.length && (
                      <div className="h-100 text-center d-flex flex-column justify-content-center">
                        <p>No upcoming classes.</p>
                      </div>
                    )}

                    {entries.length > 0 && (
                      <ul className="list-group list-group-flush flex-grow-1 mb-0 rows-in">
                        {upcomingEntries.map(entry => (
                          <li
                            key={entry.id}
                            className={cx(
                              "list-group-item d-flex align-items-center",
                              isXponential ? "px-4" : "px-1 px-md-4"
                            )}
                            style={{ height: `calc(100% / 3)` }}
                          >
                            <div className="d-flex justify-content-between w-100">
                              <div className={styleClasses.TodaysClassCard__favoriteHeartWrapper}>
                                {entry.classCategory && entry.classCategory.id && (
                                  <FavoriteHeart
                                    type="class"
                                    id={entry.classCategory.id}
                                    width="20"
                                    height="20"
                                  />
                                )}
                              </div>
                              <div className="flex-grow-1">
                                {isXponential ? (
                                  <>
                                    <h4 className="mb-0">{entry.title}</h4>
                                    <p className="mb-0 todays-classes-card__start-time">
                                      {entry.startTime} {entry.timezone}
                                      &nbsp;&ndash;&nbsp;{entry.duration}&nbsp;min
                                    </p>
                                    <p className="mb-0 todays-classes-card__location-name">
                                      {entry.brand.name} {entry.location.name}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <h5>{entry.title}</h5>
                                    <span className="small">
                                      {entry.startTime}&ndash;{entry.endTime}&nbsp;
                                    </span>
                                  </>
                                )}
                              </div>
                              <div className="col-md-3 p-0 position-static my-3 my-md-0 todays-classes-card__btn">
                                <APILoader
                                  apiStore={this.bookingsStore}
                                  elementSize="element"
                                  render={() => {
                                    const { bookedBookings } = this.bookingsStore
                                    const bookedIds = bookedBookings.map(
                                      booking => booking.scheduleEntry.id
                                    )
                                    const isBooked = bookedIds.indexOf(entry!.id) > -1

                                    return isBooked ? (
                                      <button
                                        className="btn btn-primary btn-block px-0 d-block booked"
                                        disabled
                                      >
                                        Booked
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-primary btn-block px-0 d-block"
                                        onClick={() => this.props.onBookingModal(entry)}
                                      >
                                        Book
                                      </button>
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )
              }}
            />
          :
            <APILoader
              apiStore={this.props.dashboardStore}
              elementSize="element"
              propagateErrors
              fetchProps={currentLocation!.id}
              render={() => {

                let entries = this.props.dashboardStore.entries
                let upcomingEntries = entries.slice(0, 3)

                return (
                  <>
                    {!entries.length && (
                      <div className="h-100 text-center d-flex flex-column justify-content-center">
                        <p>No upcoming classes.</p>
                      </div>
                    )}

                    {entries.length > 0 && (
                      <ul className="list-group list-group-flush flex-grow-1 mb-0 rows-in">

                        {upcomingEntries.map(entry => (
                          <li
                            key={entry.id}
                            className="list-group-item d-flex align-items-center px-3 px-md-4"
                            style={{ height: `calc(100% / 3)` }}
                          >
                            <div className="d-flex justify-content-between w-100">
                              {(brandId !== "rumble") && (
                                <div className="mr-2 mr-md-3">
                                  {entry.classCategory && entry.classCategory.id ? (
                                    <FavoriteHeart
                                      type="class"
                                      id={entry.classCategory.id}
                                      width="20"
                                      height="20"
                                    />
                                  ) : (
                                    <div style={{ width: 20 }} />
                                  )}
                                </div>
                              )}
                              <div className="flex-grow-1">
                                <h5>{entry.title}</h5>
                                <span>
                                  {entry.startTime}&ndash;{entry.endTime}
                                </span>
                              </div>
                              <Link
                                onClick={this.handleBookClick(entry)}
                                className={cx(
                                  "btn",
                                  "btn-primary",
                                  "ml-3",
                                  "text-nowrap",
                                  "align-self-center",
                                  "px-2",
                                  "px-md-4",
                                  "dashboard-card-button",
                                  {
                                    disabled: !this.isEntryBookable(entry),
                                  }
                                )}
                                to={{
                                  pathname: `${entry.bookingPath}`,
                                  state: { forceBackURL: "/" },
                                }}
                              >
                                {/* {entry.id} */}
                                {/* {entry.dateString} */}
                                {buttonText(entry, copy, isFrozen)}
                              </Link>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )
              }}
            />
         }
        </div>
      </div>
    )
  }
}
