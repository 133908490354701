import { JsonObject, JsonProperty } from "json2typescript"
import ClassStatsBase from "apps/history/models/ClassStatBase"

@JsonObject("CyclingClass")
export default class CyclingClass extends ClassStatsBase {
  type: "cycling" = "cycling"

  @JsonProperty("avg_power", Number)
  avgPower: number = undefined!

  @JsonProperty("high_power", Number)
  highPower: number = undefined!

  @JsonProperty("avg_rpm", Number)
  avgRpm: number = undefined!

  @JsonProperty("high_rpm", Number)
  highRpm: number = undefined!

  @JsonProperty("calories", Number)
  calories: number = undefined!

  @JsonProperty("total_energy", Number)
  totalEnergy: number = undefined!

  @JsonProperty("calories", Number)
  ftp: number = undefined!

  @JsonProperty("distance_f", Number)
  distance: number = undefined!

  @JsonProperty("rank", Number)
  rank: number = undefined!
}
