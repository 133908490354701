import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"

import AbstractStatsStore from "apps/history/stores/AbstractStatsStore"
import RowingSummary from "apps/history/models/RowingSummary"
import RowingClass from "apps/history/models/RowingClass"
import { UserSession } from "models/UserSession"

interface Response {
  summary_stats: RowingSummary
  class_stats: RowingClass[]
  user?: UserSession
}

export default class RowingStatsStore extends AbstractStatsStore<Response> {
  type = "rowing" as const

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    MemoizeMiddleware,
    DeserializeMiddleware("summary_stats", RowingSummary, true),
    DeserializeMiddleware("class_stats", RowingClass),
    TokenAuthMiddleware(this.userStore),
  ])

  fetch() {
    return this.api.get(`/api/performance_stats/rowing`)
  }
}
