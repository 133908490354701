import { JsonObject, JsonProperty } from "json2typescript"
import Package from "models/Package"
import Offer from "models/Offer"
import Price from "models/Price"
import Credit from "apps/account/models/Credit"
import Invoice from "models/Invoice"
import DateConverter from "utils/DateConverter"
import { Moment } from "moment"

@JsonObject("Purchase")
export default class Purchase {
  /**
   * ClubReady `ContractSaleID` / `contractPurchaseId` / `agreementId`. Unique identifier for the agreement
   */
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("package", Package)
  package: Package = undefined!

  /**
   * This is probably the best date to use. It indicates the contract was agreed to or something. May be different from the date it was bought and/or the date when it became effective. ClubReady `agreedDateUtc`.
   */
  @JsonProperty("agreed_at", DateConverter, true)
  agreedAt?: Moment = undefined!

  /**
   * When the agreement was cancelled, if it was cancelled. ClubReady `cancelDateUtc`
   */
  @JsonProperty("cancelled_at", DateConverter, true)
  cancelledAt?: Moment = undefined!

  /**
   * When credits on this package will start expiring.
   */
  @JsonProperty("credits_expire_at", DateConverter, true)
  creditsExpireAt?: Moment = undefined!

  /**
   * When credits on this package will start expiring.
   */
  @JsonProperty("renews_at", DateConverter, true)
  renewsAt?: Moment = undefined!

  /**
   * Whether it auto-renews. ClubReady `isAutoRenewed`
   */
  @JsonProperty("is_recurring", Boolean)
  isRecurring: boolean = undefined!

  /**
   * Whether the agreement has been cancelled. Determined by presence of a `cancelled_at` date
   */
  @JsonProperty("is_cancelled", Boolean)
  isCancelled: boolean = undefined!

  @JsonProperty("seems_expired", Boolean)
  seemsExpired: boolean = undefined!

  @JsonProperty("can_purchase_again", Boolean)
  canPurchaseAgain: boolean = undefined!

  /**
   * ClubReady `totalSessions`. Total credits dropped by this package over its lifetime. This sometimes comes back `null`, for packages without credits or sometimes if you purchased the package that day (the latter being a bug.)
   */
  @JsonProperty("credit_count_total", Number)
  creditCountTotal: number = undefined!

  /**
   * How many credits they have remaining. Just a count of the credits array.
   */
  @JsonProperty("credit_count_remaining", Number)
  creditCountRemaining: number = undefined!

  @JsonProperty("paid_upfront", Price)
  paidUpfront: Price = undefined!

  @JsonProperty("total_price", Price)
  totalPrice: Price = undefined!

  @JsonProperty("recurring_price", Price, true)
  recurringPrice?: Price = undefined!

  @JsonProperty("credits", [Credit], true)
  credits?: Credit[] = []
  /**
   * An array of line items and invoices associated with this purchase.
   */
  @JsonProperty("invoice_items", [Invoice])
  invoices: Invoice[] = []

  @JsonProperty("scheduled_cancel_date_utc", DateConverter, true)
  scheduledCancelDateUtc?: Moment = undefined!

  @JsonProperty("cancellable", Boolean, true)
  cancellable?: boolean = undefined!

  @JsonProperty("cancel_incentive_offer", Offer, true)
  cancelIncentiveOffer?: Offer = undefined!
}
