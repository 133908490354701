import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("Location")
export class Location {
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("brand_id", String)
  brandId: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("clubready_id", String)
  clubreadyId: string = undefined!

  @JsonProperty("city", String)
  city: string = undefined!

  @JsonProperty("state", String)
  state: string = undefined!

  @JsonProperty("zip", String)
  zip: string = undefined!

  @JsonProperty("lat", Number)
  lat: number = undefined!

  @JsonProperty("lng", Number)
  lng: number = undefined!

  @JsonProperty("address", String)
  address: string = undefined!

}

