import { Middleware } from "services/Middleware"
import { AxiosResponse, AxiosRequestConfig } from "axios"
import { stringify } from "helpers/queryString"
import { authHeaderName } from "services/middleware/TokenAuthMiddleware"

// Saves unauthenticated GET requests based on url & query string, then serves
// them out of memory if they're requested again.
//
// TODO: this is somewhat naive. Do we ever hit the same endpoint and want to
// reload it? I hope not.
//
// Also this completely disregards headers so hopfully two GET requests won't
// ever be distinguished only by their headers.
//
// Some ideas:
// - Be able to set some context param that causes this to always refetch
// - Maybe also a setting that ignores or clears a cached response
// - Scope per store or per brand or something
// - Certainly should delete it on logout
// - Also this doesn't solve the problem of requests that are in-flight

const memoizedResponseCache: Record<string, AxiosResponse | undefined> = {}

const MemoizeMiddleware: Middleware = (ctx, next) => {
  if (!ctx.headers) ctx.headers = {}
  if (!ctx.params) ctx.params = {}

  const isCacheable =
    ctx.method === "get" &&
    !ctx.headers[authHeaderName] &&
    !ctx.params.auth_token

  if (!isCacheable) {
    return next()
  }

  if (ctx.url && memoizedResponseCache[urlWithQuery(ctx)]) {
    const res = memoizedResponseCache[urlWithQuery(ctx)]!
    console.log(`${urlWithQuery(ctx)} served from cache`)
    if (res) return res
  }

  return next().then(res => {
    // TODO: should make this a global helper
    const isStoreable =
      isCacheable && res.status >= 200 && res.status < 300 && ctx.url

    if (isStoreable) {
      console.log(`${urlWithQuery(ctx)} saved to cache`)
      memoizedResponseCache[urlWithQuery(ctx)] = res
    }
    return res
  })
}

function urlWithQuery(ctx: AxiosRequestConfig) {
  return `${ctx.url}?` + stringify(ctx.params)
}

function clearCache() {
  for (const key in memoizedResponseCache) {
    delete memoizedResponseCache[key];
  }
}

export { memoizedResponseCache, clearCache }
export default MemoizeMiddleware
