// number formatter for metrics
// replicates the behavior of countup

export interface FormatNumber {
  (val: number): string
}

export interface FormatNumberOptions {
  decimalPlaces: number
  useEasing: boolean
  useGrouping: boolean
  separator: string
  decimal: string
  prefix: string
  suffix: string
  numerals?: string[]
}

// https://github.com/inorganik/countUp.js/blob/master/src/countUp.ts#L1
export const defaultOptions: FormatNumberOptions = {
  decimalPlaces: 0,
  useEasing: true,
  useGrouping: true,
  separator: ",",
  decimal: ".",
  prefix: "",
  suffix: "",
}

// this whole business is super gross, consider a different approach
export type NumberFormatter = {
  (val: number, userOptions?: Partial<FormatNumberOptions>): string
  options?: Partial<FormatNumberOptions>
}

export default function numberFormatter(options: Partial<FormatNumberOptions>) {
  const f = (val: number) => formatNumber(val, options)!
  f.options = { ...defaultOptions, ...(options || {}) }
  return f
}

// logic taken from:
// https://github.com/inorganik/countUp.js/blob/master/src/countUp.ts#L244
export const formatNumber: NumberFormatter = function(val, userOptions?) {
  const options = { ...defaultOptions, ...(userOptions || {}) }

  const neg = val < 0 ? "-" : ""
  let result: string, x: string[], x1: string, x2: string, x3: string
  result = Math.abs(val).toFixed(options.decimalPlaces)
  result += ""
  x = result.split(".")
  x1 = x[0]
  x2 = x.length > 1 ? options.decimal + x[1] : ""
  if (options.useGrouping) {
    x3 = ""
    for (let i = 0, len = x1.length; i < len; ++i) {
      if (i !== 0 && i % 3 === 0) {
        x3 = options.separator + x3
      }
      x3 = x1[len - i - 1] + x3
    }
    x1 = x3
  }
  // optional numeral substitution
  if (options.numerals && options.numerals.length) {
    x1 = x1.replace(/[0-9]/g, w => options.numerals![+w])
    x2 = x2.replace(/[0-9]/g, w => options.numerals![+w])
  }
  return neg + options.prefix + x1 + x2 + options.suffix
}

formatNumber.options = defaultOptions

// https://stackoverflow.com/a/14994860
export function formatBigNumber(val: number) {
  if (val >= 1000000000) {
    return (val / 1000000000).toFixed(1).replace(/\.0$/, "") + "G"
  }
  if (val >= 1000000) {
    return (val / 1000000).toFixed(1).replace(/\.0$/, "") + "M"
  }
  if (val >= 1000) {
    return (val / 1000).toFixed(1).replace(/\.0$/, "") + "K"
  }
  return val.toString(10)
}
