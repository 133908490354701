import React, { Component } from "react"
import { observer } from "mobx-react"
import { Link } from "react-router-dom"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { Location } from "apps/book/models/xpass/Location"
import LocationSummary from "models/LocationSummary"
import BrandStore from "stores/BrandStore"

mapboxgl.accessToken = window.globals.mapboxAccessToken

interface ImageProps {
  imageUrl: string
  brandId: string
}

interface DetailsPageMapProps {
  img?: ImageProps
  brandId: string
  brandName?: string
  location: Location | LocationSummary
  hasHeader?: boolean
  store?: BrandStore
  height?: string
  dashboardCard?: boolean
  isDetailsPage?: boolean
}

@observer
export default class DetailsPageMap extends Component<DetailsPageMapProps> {
  mapContainer?: HTMLDivElement | null
  map?: mapboxgl.Map

  componentDidMount() {
    const { location } = this.props
    this.setMap(location!.lng, location!.lat)
  }

  setMap(lng: number, lat: number) {
    this.map = new mapboxgl.Map({
      container: this.mapContainer!,
      style: "mapbox://styles/mapbox/outdoors-v11",
      center: [lng, lat],
      zoom: 13,
      interactive: false,
      attributionControl: false,
    })

    this.map.on("load", this.handleMap)
  }

  setMarkerImage = (brand: string) => {
    switch (brand) {
      case "akt":
        return "akt-pin"
      case "clubpilates":
        return "clubpilates-pin"
      case "cyclebar":
        return "cyclebar-pin"
      case "purebarre":
        return "purebarre-pin"
      case "rowhouse":
        return "rowhouse-pin"
      case "stretchlab":
        return "stretchlab-pin"
      case "stride":
        return "stride-pin"
      case "yogasix":
        return "yogasix-pin"
      case "kinrgy":
        return "kinrgy-pin"
      default:
        return "default-pin"
    }
  }

  handleMap = () => {
    const { location, img, brandId } = this.props
    const imageId = this.setMarkerImage(brandId)

    this.map!.loadImage(img!.imageUrl, (error?: Error, image?: any) => {
      if (error) throw error

      this.map!.addImage(imageId, image)

      this.map!.addSource("studios", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                icon: imageId,
                brand: brandId,
                location: location,
              },
              geometry: {
                type: "Point",
                coordinates: [location!.lng, location!.lat],
              },
            },
          ],
        },
      })

      this.map!.addLayer({
        id: "studios-layer",
        source: "studios",
        type: "symbol",
        layout: {
          "icon-image": ["get", "icon"],
          "icon-size": 0.3,
          "icon-anchor": "bottom",
        },
      })
    })

    this.map!.resize()
  }

  formatPhone = (phone: string) => {
    const numbersOnly = phone.replace(/\D/g, "")
    const phoneMatch = numbersOnly.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

    if (!phoneMatch) {
      return ""
    }

    return ["(", phoneMatch[2], ") ", phoneMatch[3], "-", phoneMatch[4]].join(
      ""
    )
  }

  componentWillUnmount() {
    if (this.map) {
      this.map.off("load", this.handleMap)
      this.map.remove()
    }
  }

  render() {
    const { brandName, location, hasHeader, store } = this.props
    const locationId = store && store.userStore.session!.locationId
    const height = this.props.height ? this.props.height : '250px'

    if (this.props.dashboardCard) {
      return (
        <div className="flex-grow-1 d-flex">
          <div className="w-50">
            <div className="mapbox-container" style={{ height }}>
              <div
                ref={r => (this.mapContainer = r)}
                className="mapbox-map"
                style={{ height }}
              />
            </div>
          </div>
          <div className="card-body w-50 p-3">
            <h4>{location.name}</h4>
            <address className="mb-0">
              {location.address} {location.address2}
              <br />
              {location.city}, {location.state} {location.zip}
            </address>
          </div>
        </div>
      )
    }

    return (
      <div className={`${!this.props.isDetailsPage && "col-lg-5 col-xl-4 col-xxl-3"}`}>
        {hasHeader && <h2 className="details-page__title pb-2 pb-md-1">Studio</h2>}
        <div className="mapbox-container" style={{ maxHeight: height }}>
          <Link to="/book">
            <div
              ref={r => (this.mapContainer = r)}
              className="mapbox-map"
              style={{ maxHeight: height }}
            />
          </Link>
        </div>

        {store ? (
          <Link to={`/book/${locationId}/location/${location.id}`}>
            <h3 className="details-page__section-title mb-2 mt-3">
              {brandName} {location.name}
            </h3>
          </Link>
        ) : (
          <div className="mb-2 mt-3">
            <h3 className="details-page__section-title mb-1">
              {brandName} {location.name}
            </h3>
          </div>
        )}

        <p className="mb-0">
          {location.address}
          {location.address2 && ` ${location.address2}`},
        </p>
        <p className="mt-0 mb-3">
          {location.city}, {location.state} {location.zip}
        </p>

        {location.phone && (
          <p>
            <a href={`tel:${location.phone}`}>
              {this.formatPhone(location.phone)}
            </a>
          </p>
        )}
      </div>
    )
  }
}
