import * as React from "react"
import { Redirect } from "react-router-dom"
import { inject, observer } from "mobx-react"
import { observable } from "mobx"
import cx from "classnames"
import capitalize from "lodash/capitalize"
import { RouteComponentProps, withRouter } from "react-router-dom"
import APILoader from "components/wrappers/APILoader"
import NotHomeWarning from "components/NotHomeWarning"
import PageTracker from "components/wrappers/PageTracker"
import CustomPills from "components/CustomPills"
import LocationSummary from "models/LocationSummary"
import Package from "models/Package"
import BrandStore from "stores/BrandStore"
import PackagesStore from "apps/buy/stores/PackagesStore"
import PackagesAddOnStore from "apps/buy/stores/PackagesAddOnStore"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import BookingInfoStore from "apps/buy/stores/BookingInfoStore"
import PackageCard from "apps/buy/components/PackageCard"
import PurchasesStore from "apps/account/stores/PurchasesStore"
import Purchase from "apps/account/models/Purchase"

interface Props extends RouteComponentProps{
  store?: BrandStore
  locationSummary: LocationSummary
  packagesStore: PackagesStore
  packagesAddOnStore: PackagesAddOnStore
  bookingInfoStore: BookingInfoStore
  queryString: string
  routingState: object
}

type PackageType = "packages" | "memberships" | "tokens"

@inject((store: BrandStore) => ({ store }))
@observer
class PackagesList extends React.Component<Props, {}> {

  constructor(props: Props) {
    super(props)
  }

  @observable filterKey: PackageType = this.packageTypes[2]
  isXponential = this.props.store!.isXponential
  isXpassEligible = this.isXponential ? this.props.packagesAddOnStore.isXpassEligible || this.props.packagesStore.isXpassEligible : false
  @observable balancesStore = this.isXponential
    ? new BalancesStore(this.props.store!)
    : undefined
  @observable purchasesStore = new PurchasesStore(this.props.store!)

  componentDidMount() {
    if (this.forClassOrService) {
      this.props.store!.uiStore.hideNavLinks()
    } else {
      this.props.store!.uiStore.showNavLinks()
    }


    const { isXponential, routingStore } = this.props.store!
    if(isXponential && (this.props.location.state && this.props.location.state.context === "addTokens")){
      this.filterPackagesByKey("tokens")
    }
    else if (isXponential && routingStore.query.add_on === "true") {
      this.packageTypes.forEach(type => {
        if (this.props.packagesStore[type].length > 0) {
          this.filterPackagesByKey(type)
        }
      })
    } else {
      // filter by each non-empty type in reverse, so the _last_ filter we do is
      // for the _first_ non-empty package type according to this.packageTypes ordering
      this.packageTypes.reverse().forEach(type => {
        if (this.props.packagesStore[type].length > 0) {
          this.filterPackagesByKey(type)
        }
      })
    }
  }

  componentWillUnmount() {
    if (this.balancesStore) {
      this.balancesStore.dispose()
    }
  }

  get forClassOrService() {
    return this.props.bookingInfoStore.hasBookingInfo
  }

  get bookingLabel() {
    return this.props.bookingInfoStore.bookingTypeCopy
  }

  get filteredPackages() {
    return this.props.packagesStore[this.filterKey]
  }

  get packageTypes(): PackageType[] {
    const { settings } = this.props.store!.brandData

    if (this.isXponential) return ["memberships", "tokens"]

    return this.forClassOrService && settings.packagesFirstOnBooking
      ? ["packages", "memberships"]
      : ["memberships", "packages"]
  }

  get packageTypeOptions(): [PackageType, string][] {
    const { copy } = this.props.store!.brandData
    return this.packageTypes.map(key => [key, copy[key]])
  }

  get subHeading() {
    const { copy, settings, isXponential } = this.props.store!
    const havePackages = this.props.packagesStore.allPackages.length > 0
    const bookingLabel = copy.bookingLabel
      ? copy.bookingLabel
      : `Please select one of the options below to book this ${this.bookingLabel}.`

    if (!havePackages) {
      return isXponential ? (
        <>
          We weren't able to find any active {copy.memberships.toLowerCase()} to book this{" "}
          {this.bookingLabel}.
        </>
      ) : (
        <>
          We weren't able to find any active {copy.packages.toLowerCase()} or {copy.memberships.toLowerCase()} to book
          this {this.bookingLabel} at {this.props.locationSummary.name}.
        </>
      )
    }

    if (settings.morePackagesInStore) {
      return (
        <>
          Call or visit your studio for more class and membership options
          <br />
        </>
      )
    }

    if (this.forClassOrService) {
      return (
        <>
          <span>{bookingLabel}</span>
          <br />
          <br />
        </>
      )
    } else {
      return (
        <>
          {copy.classPackages}
          <br />
        </>
      )
    }
  }

  /**
   * If only one package will display and we're in the booking flow,
   * don't show the user that package and make them pick.
   * Instead, just redirect them to that package's purchase page
   */
  singlePackageRedirect() {
    if (!this.forClassOrService) {
      return null
    }

    const { allPackages } = this.props.packagesStore

    if (allPackages.length === 1) {
      const { locationSummary, queryString } = this.props
      return (
        <Redirect
          to={{
            pathname: `/buy/${locationSummary.id}/packages/${allPackages[0].id}`,
            search: queryString,
            state: this.props.routingState,
          }}
        />
      )
    }
    return null
  }

  filterPackagesByKey = (key: PackageType) => {
    this.filterKey = key
  }

  handlePackageClick = (pkg: Package, packagePosition: number) => () => {
    this.props.store!.track.event(
      "packages_tap buy",
      { pkg, loc: this.props.locationSummary },
      { packageInBookingFlow: this.forClassOrService, packagePosition }
    )
  }

  isPurchased = (pkg: Package) => {
    const { purchases } = this.purchasesStore
    return purchases.some(p => p.package.id === pkg.id)
  }

  isCancelled = (pkg: Package) => {
    const { purchases } = this.purchasesStore
    // Accounts for plans that were purchased, cancelled, and purchased again
    return !purchases.some(p => p.package.id === pkg.id && !p.isCancelled)
  }

  getPurchasedIndexes = (packagesWithPurchases: Package[]) => {
    const purchasedIndexes: number[] = []
    packagesWithPurchases.forEach((p, i) => {
      if (this.isPurchased(p) && !this.isCancelled(p)) purchasedIndexes.push(i)
    })
    return purchasedIndexes
  }

  getUpdateType = (pkg: Package, idx: number, packagesWithPurchases: Package[], purchasedIndexes: number[]) => {
    if (!pkg.isMembership || purchasedIndexes.length <= 0) return ""
    if (purchasedIndexes.includes(idx)) {
      // If package index is a purchase, return Active
      return "Active"
    } else if (idx > purchasedIndexes[0] && idx > purchasedIndexes[purchasedIndexes.length - 1]) {
      // If package index is above all purchases, return Upgrade
      return "Upgrade"
    } else if (idx < purchasedIndexes[0] && idx < purchasedIndexes[purchasedIndexes.length - 1]) {
      // If package index is below all purchases, return Downgrade
      return "Downgrade"
    } else {
      // If package index is between two purchases, return Change
      return "Change"
    }
  }

  getPackagesAndPurchases = (packages: Package[], purchases: Purchase[]) => {
    let allPackages = packages ? [...packages] : []
    if (purchases.length) {
      const uniquePurchases = purchases.filter(p => !packages.some(pkg => pkg.seq === p.package.seq) && p.package.isMembership)
      allPackages = [...packages, ...uniquePurchases.map(p => p.package)].sort((a,b) => a.seq - b.seq)
    }
    return allPackages
  }

  render() {
    const singlePackageRedirect = this.singlePackageRedirect()
    if (singlePackageRedirect) {
      return singlePackageRedirect
    }

    const packages = this.filteredPackages
    const { packagesStore, packagesAddOnStore, store } = this.props
    const { copy } = store!

    const bookingTitle = copy.packageListHeader.length > 0 ? copy.packageListHeader : `Buy ${capitalize(copy.classes)}`
    const bookingErrorTitle = copy.bookingErrorTitle
      ? copy.bookingErrorTitle
      : `You Need More ${capitalize(copy.credits)}`

    const showBuyRedirectButton = store!.settings.showBuyRedirectButton
    const buyRedirectText = copy.buyRedirectText
    const buyRedirectButton =
      showBuyRedirectButton &&
        packagesStore.scheduleEntryId &&
        packagesStore.allPackages.length === 0 ? (
        <div className="d-sm-flex justify-content-around pt-3">
          <a className="btn btn-primary" href="/buy">
            {buyRedirectText}
          </a>
        </div>
      ) : (
        ""
      )

    return (
      <div
        className={cx(
          "memberships",
          store!.styleClasses.PackagesList__memberships
        )}
      >
        {!this.isXponential && (
          <>
            <h1 className="text-sm-center mb-0 slide-in delay-1 mt-3">
              {this.forClassOrService ? bookingErrorTitle : bookingTitle}
            </h1>
            <PageTracker
              key={this.filterKey}
              name={
                this.filterKey === "memberships"
                  ? "buy > memberships"
                  : "buy > packages"
              }
              extra={{ packageInBookingFlow: this.forClassOrService }}
            />
            <div className="mb-4 slide-in delay-2">
              <p className="text-sm-center text-md-large mt-2 col-lg-6 offset-lg-3">
                {this.subHeading}
              </p>
              <NotHomeWarning className="text-danger text-sm-center drop-in delay-3">
                . These are memberships for{" "}
                <strong>{this.props.locationSummary.name}</strong>.
              </NotHomeWarning>
              {buyRedirectButton}
            </div>

            {packagesStore.memberships.length > 0 &&
              packagesStore.packages.length > 0 && (
                <CustomPills
                  onChange={this.filterPackagesByKey}
                  selected={this.filterKey}
                  options={this.packageTypeOptions}
                  className={cx(
                    "mx-auto",
                    "col-lg-5",
                    "col-xl-4",
                    "col-md-8",
                    "drop-in",
                    "delay-3",
                    "mb-4",
                    "package-types"
                  )}
                />
              )}
            <div className="row justify-content-center cards-in">
              {packages &&
                packages.map((pkg, i) => (
                  <PackageCard
                    key={pkg.id}
                    pkg={pkg}
                    locationSummary={this.props.locationSummary}
                    queryString={this.props.queryString}
                    store={this.props.store!}
                    purchaseDisabled={false}
                    onClick={this.handlePackageClick(pkg, i + 1)}
                    filterKey={this.filterKey}
                  />
                ))}
            </div>
          </>
        )}

        {this.isXponential && (
          <APILoader
            apiStore={this.purchasesStore}
            render={() => {
              const { purchases } = this.purchasesStore
              const activePurchase = purchases.filter(
                purchase =>
                !purchase.package.isXpassFree && !purchase.isCancelled && purchase.package.isMembership
              )
              const packagesWithPurchases = this.getPackagesAndPurchases(packages, activePurchase)
              const displayedPackages = { 'tokens': packages, 'memberships': packagesWithPurchases }
              const purchasedIndexes = this.getPurchasedIndexes(packagesWithPurchases)
              const plans = packagesStore.allPackages.filter(
                pkg => pkg.isMembership || pkg.isXpassFree
              )
              const availablePlans =
                purchases.length > 0
                  ? plans.filter(pkg => !pkg.isXpassFree)
                  : plans
              const xpassBookingTitle = this.filterKey === "tokens" ? "Add Tokens" : "Choose a Plan"

              return (
                <>
                  <CustomPills
                    onChange={this.filterPackagesByKey}
                    selected={this.filterKey}
                    options={this.packageTypeOptions}
                    className={cx(
                      "mx-auto",
                      "col-lg-5",
                      "col-xl-4",
                      "col-md-8",
                      "drop-in",
                      "delay-3",
                      "mb-4",
                      "package-types"
                    )}
                  />
                  <h1 className={cx("text-sm-center mb-0 slide-in delay-1", { "mt-3 text-center": this.isXponential })}>
                    {this.forClassOrService
                      ? bookingErrorTitle
                      : xpassBookingTitle}
                  </h1>
                  <PageTracker
                    key={this.filterKey}
                    name={
                      this.filterKey === "memberships"
                        ? "buy > memberships"
                        : "buy > packages"
                    }
                    extra={{ packageInBookingFlow: this.forClassOrService }}
                  />
                  <div className="mb-4 slide-in delay-2">
                    <p className={cx("text-sm-center text-md-large mt-3 packages-subhead", { "text-center": this.isXponential })}>
                      {this.subHeading}
                    </p>
                    <NotHomeWarning className="text-danger text-sm-center drop-in delay-3">
                      . These are memberships for{" "}
                      <strong>{this.props.locationSummary.name}</strong>.
                    </NotHomeWarning>
                    {buyRedirectButton}
                  </div>
                  <APILoader
                    apiStore={packagesAddOnStore}
                    fetchProps={{}}
                    elementSize="page"
                    render={() => {
                      const packageAddOns = this.props.packagesAddOnStore.addOns

                      const { freeClassesPurchased } = this.balancesStore!
                      // If service booking, remove free class package from purchase flow
                      const param = new URLSearchParams(window.location.search)
                      const isServiceBkg = param.get("duration_id") ? param.get("duration_id") : undefined

                      const filteredPlans = freeClassesPurchased || isServiceBkg
                        ? availablePlans.filter(pkg => !pkg.isXpassFree)
                        : availablePlans

                      const plans = [...new Set(packagesWithPurchases.concat(filteredPlans))]
                      const displayedPackages = { 'tokens': packageAddOns, 'memberships': plans }

                      return (
                        <div className="row justify-content-center cards-in">
                          {displayedPackages[this.filterKey].map((pkg: Package, i: number) => {
                            const updateType = this.getUpdateType(pkg, i, packagesWithPurchases, purchasedIndexes)

                            return (
                              <PackageCard
                                key={pkg.id}
                                pkg={pkg}
                                locationSummary={this.props.locationSummary}
                                queryString={this.props.queryString}
                                store={this.props.store!}
                                purchasesStore={this.purchasesStore}
                                purchaseDisabled={updateType === "Active"}
                                updateType={updateType}
                                activePurchases={this.filterKey === "memberships" ? activePurchase : []}
                                filterKey={this.filterKey}
                                isXpassEligible={this.isXpassEligible}
                              />
                            )
                          })}
                        </div>
                      )
                    }}
                  />
                </>
              )
            }}
          />
        )}
      </div>
    )
  }
}
export default withRouter(PackagesList);