import capitalize from "lodash/capitalize"
import padStart from "lodash/padStart"
import { parsePhoneNumber } from 'libphonenumber-js'

export function humanize(s: string) {
  return capitalize(s.split("_").join(" "))
}

export function ucfirst(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function formatPrice(price: number) {
  return "$" + (price / 100).toFixed(2)
}

// Phone number formatting with international support
// src: https://github.com/catamphetamine/libphonenumber-js
export function formatPhone(phone: string) {
  // defaults to US country code but can detect other country codes
  const phoneNumber = parsePhoneNumber(phone, 'US')
  return phoneNumber ? phoneNumber.formatNational() : ""
}

export function formatHeight(inches: number) {
  return `${Math.floor(inches / 12)}" ${inches - Math.floor(inches / 12)}'`
}

export function formatDuration(val: number) {
  const minutes = padStart(Math.floor(val / 60).toString(10), 1, "0")
  const seconds = padStart(Math.floor(val % 60).toString(10), 2, "0")
  return `${minutes}:${seconds}`
}
