import * as React from "react"
import { observer, inject } from "mobx-react"
import { RouteComponentProps, Switch, Route } from "react-router"
import PaymentV3Page from "apps/buy/components/PaymentV3Page"
import BrandStore from "stores/BrandStore"
import PaymentPageNoPassword from "./PaymentPageNoPassword"


export interface Props extends RouteComponentProps {
  store?: BrandStore
  isSimpleCheckout?: boolean
}

// TODO: Figure out how to use routing state properly
// w/o having to declare these special types
interface RoutingState {
  forceBackURL?: string
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class PurchaseFlowV3Controller extends React.Component<Props> {

  componentDidMount() {
     this.props.store!.uiStore.hideNavLinks()
     this.props.store!.uiStore.lockLocationPicker()

  }

  componentWillUnmount() {
     this.props.store!.uiStore.showNavLinks()
     this.props.store!.uiStore.unlockLocationPicker()

  }

  public render() {
    const path = this.props.match.path

    const renderPaymentPage = (paymentPageProps: any) => {
      const packageId = paymentPageProps.match.params.packageId
      const PaymentComponent = this.props.isSimpleCheckout
        ? PaymentPageNoPassword
        : PaymentV3Page

      return <PaymentComponent packageId={packageId} />
    }

    return (
      <>
        <Switch>
          <Route path={path} exact render={renderPaymentPage} />
          <Route path={`${path}/:packageId`} exact render={renderPaymentPage} />
        </Switch>
      </>
    )
  }
}
