import * as moment from "moment"
import { observable, computed } from "mobx"

export type DurationAnchor = "week" | "day" | "now" | "week_starting_now"

export interface DurationProps {
  date?: moment.MomentInput
  duration?: number
  anchor?: DurationAnchor
}

// models a week, with some logic for knowing if it's loaded
export default class Duration {
  @observable loaded = false
  @observable private anchor: DurationAnchor
  @observable private duration: number
  @observable private of: moment.Moment

  @computed get date() {
    return this.of.format("YYYY-MM-DD")
  }
  set date(date: moment.MomentInput) {
    const ts = moment(date || undefined)
    if (!this.inDuration(ts)) {
      this.loaded = false
    }
    this.of = ts
  }

  @computed get startAt() {
    if (this.anchor === "week_starting_now") {
      return this.weekStartRelative(this.of.startOf("day"), moment().day())
    }

    return this.anchor !== "now"
      ? this.of.clone().startOf(this.anchor)
      : this.of
  }

  @computed get endAt() {
    return this.startAt.clone().add(this.duration, "day")
  }

  @computed get through() {
    return this.endAt.clone().subtract(1, "day")
  }

  @computed get startDate() {
    return this.startAt.format("YYYY-MM-DD")
  }

  @computed get endDate() {
    return this.endAt.format("YYYY-MM-DD")
  }

  @computed get prev() {
    return this.of.clone().subtract(this.duration, "day")
  }

  @computed get next() {
    return this.endAt.clone()
  }

  @computed get nextDate() {
    return this.next.format("YYYY-MM-DD")
  }

  @computed get prevDate() {
    return this.prev.format("YYYY-MM-DD")
  }

  constructor(props: DurationProps = {}) {
    this.anchor = props.anchor || "week"
    this.duration = props.duration || 7
    this.of = moment(props.date || undefined)
  }

  inDuration(date: moment.Moment) {
    return date >= this.startAt && date < this.endAt
  }

  weekStartRelative(date: moment.Moment, day: number) {
    let dateCursor = date.clone()
    while (dateCursor.day() !== day) {
      dateCursor.subtract(1, "day")
    }
    return dateCursor
  }
}
