import { JsonObject, JsonProperty } from "json2typescript"
import { computed } from "mobx"
import DateConverter from "utils/DateConverter"
import moment, { Moment } from "moment"
import * as momenttz from "moment-timezone"
import ScheduleEntryBase from "models/ScheduleEntryBase"
import LocationSummary from "models/LocationSummary"
import { UserPremiumCost } from "apps/book/models/xpass/ScheduleEntry"
import {
  formatTimeRangeLong,
  formatTime,
  formatDateLong,
} from "helpers/dateHelper"

export type ClassLocationType = "physical" | "virtual"

@JsonObject("ScheduleEntry")
export default class ScheduleEntry extends ScheduleEntryBase {
  /**
   * A tag indicating what type of schedule entry this is. Even with CR's new API,
   * a schedule entry looks pretty different depending on where it comes from,
   * so this could be used to help distinguish.
   */
  @JsonProperty("type", String)
  type: "schedule" | "roster" | "booking" | "waitlist" = undefined!

  @JsonProperty("description", String, true)
  description?: string = undefined!

  /**
   * When users can start booking the class.
   */
  @JsonProperty("booking_opens_at", DateConverter)
  bookingOpensAt: Moment = undefined!

  /**
   * When it's too late to book the class.
   */
  @JsonProperty("booking_closes_at", DateConverter)
  bookingClosesAt: Moment = undefined!

  /**
   * When users can start checking in.
   */
  @JsonProperty("check_in_from", DateConverter, true)
  checkInFrom?: Moment = undefined!

  /**
   * When users can no longer check in.
   */
  @JsonProperty("check_in_until", DateConverter, true)
  checkInUntil?: Moment = undefined!

  /**
   * The time until which users can cancel without penalty.
   */
  @JsonProperty("free_cancel_until", DateConverter)
  freeCancelUntil: Moment = undefined!

  /**
   * The time at which the waitlist ceases to be a thing. After this time, users can't add themselves to the waitlist, and users from the waitlist will no longer be automatically added if free spots open. Any users still on the waitlist will have to cancel and rebook in order to get in.
   */
  @JsonProperty("waitlist_until", DateConverter, true)
  waitlistUntil: Moment = undefined!

  /**
   * The maximum size of the class. Use in conjunction with `free_spots` to determine if the class is full.
   */
  @JsonProperty("capacity", Number, true)
  capacity?: number = undefined!

  /**
   * The number of open spots left. For now this will be null when returning the list of user bookings, as we don't have a way to get the answer from any of the calls we're making on this page.
   *
   */
  @JsonProperty("free_spots", Number, true)
  freeSpots?: number = undefined!

  /**
   * Whether this class supports a waitlist once full.
   */
  @JsonProperty("has_waitlist", Boolean, true)
  hasWaitlist?: boolean = undefined

  /**
   * The number of users on the waitlist.
   */
  @JsonProperty("waitlist_size", Number, true)
  waitlistSize?: number = undefined!

  /**
   * ID of the room this class will take place in, if applicable. Used to see if
   * the user has a relevant favorite spot. It will be `null` if the class does
   * not support spot booking.
   */
  @JsonProperty("room_id", String, true)
  roomId?: string = undefined!

  @JsonProperty("location_id", String)
  locationId: string = undefined!

  /**
   * Unique identifier of the class type in ClubReady. Used for tracking.
   */
  @JsonProperty("clubready_class_type_id", String)
  clubreadyClassTypeId: string = undefined!

  /**
   * Name of the class type in ClubReady
   */
  @JsonProperty("clubready_name", String)
  clubreadyName: string = undefined!

  /**
   * Instructor-given playlist URL
   */
  @JsonProperty("spotify_playlist_url", String, true)
  spotifyPlaylistUrl?: string = undefined!

  @JsonProperty("is_xpass_free", Boolean, true)
  isXpassFree?: boolean = undefined!

  @JsonProperty("user_premium_cost", UserPremiumCost, true)
  userPremiumCost?: UserPremiumCost = undefined!

  @JsonProperty("is_premium_paid", Boolean, true)
  isPremiumPaid?: boolean = undefined!

  @JsonProperty("xpass_eligible", Boolean, true)
  xpassEligible?: boolean = undefined!

  @JsonProperty("has_valid_token", Boolean, true)
  hasValidToken?: boolean = undefined!

  @JsonProperty("target_package_id", String, true)
  targetPackageId?: string = undefined!

  /**
   * Indicates whether the class is attended in-person or virtually
   */
  @JsonProperty("location_type", String, true)
  locationType?: ClassLocationType = undefined!

  location?: LocationSummary
  classImage: string = ""
  altTitle:  string = ""

  // see also Booking.ts

  get tooEarly() {
    return this.bookingOpensAt > moment()
  }

  get tooLate() {
    return this.bookingClosesAt < moment() || this.startsAt < moment()
  }

  get freeCancelable() {
    return this.freeCancelUntil > moment()
  }

  get inFuture() {
    return this.startsAt > moment()
  }

  get isOver() {
    return this.endsAt < moment()
  }

  get canCheckIn() {
    return this.checkInFrom && this.checkInFrom <= moment()
  }

  get tooLateToWait() {
    return this.waitlistUntil ? this.waitlistUntil < moment() : false
  }

  get canWaitlist() {
    // comment out second predicate to make testing much easier
    return this.hasWaitlist && !this.tooLateToWait
  }

  @computed get tooEarlyToBook() {
    return moment(this.startsAt, "YYYY-MM-DD").isAfter(moment().add(7, "days").format("YYYY-MM-DD"))
  }

  @computed get bookingStatusCode() {
    if (this.tooEarly) return "early" as const
    if (this.tooLate) return "late" as const
    if (this.isFull && !this.canWaitlist) return "full" as const
    if (this.isBooked) return "booked" as const
    return undefined
  }

  @computed get notBookable() {
    return (
      this.tooEarly ||
      this.tooLate ||
      this.isBooked ||
      (this.isFull && !this.canWaitlist) ||
      this.isBusy
    )
  }

  // tri-value: null/undefined means "don't know"
  @computed get isFull() {
    if (this.freeSpots == null) return undefined
    return this.freeSpots === 0
  }

  @computed get totalSpots() {
    return this.capacity
  }

  @computed get spotsString() {
    // @note: `Fully Booked` will never be the case the way `SchedulePage` and `MiniSchedulePage` is set up right now
    if (this.freeSpots === 0) return "Fully Booked"
    if (this.freeSpots === 1) return "1 Spot Open"
    if (this.freeSpots) return `${this.freeSpots} Spots Open`
    return ""
  }

  // ScheduleEntryBase.ts
  /**
   * Unique ID for the schedule entry.
   */
  @JsonProperty("id", String)
  id: string = undefined!
  /**
   * Timestamp of the start time for the class.
   */
  @JsonProperty("starts_at", DateConverter)
  startsAt: Moment = undefined!
  /**
   * Timestamp of the end time for the class.
   */
  @JsonProperty("ends_at", DateConverter)
  endsAt: Moment = undefined!

  @computed
  get dateString() {
    return this.startsAt.format("YYYY-MM-DD")
  }
  @computed
  get startsAtDate() {
    return formatDateLong(this.startsAt)
  }
  @computed
  get startTime() {
    return formatTime(this.startsAt)
  }
  @computed
  get endTime() {
    return formatTime(this.endsAt)
  }
  @computed
  get startTimeTZ() {
    return this.location && this.location.timezone
      ? momenttz.tz(this.startsAt, this.location.timezone).format("h:mma z")
      : this.startTime
  }
  @computed
  get timezone() {
    return this.location && this.location.timezone
      ? momenttz.tz(this.startsAt, this.location.timezone).format("z")
      : ""
  }
  @computed
  get timeRange() {
    return formatTimeRangeLong(this.startsAt, this.endsAt)
  }
  @computed
  get duration() {
    return this.endsAt.diff(this.startsAt, 'minutes')
  }

  // Set to prevent type error for xpass booking flow
  get brand() {
    return {
      id: "",
      name: "",
    }
  }
}
