import * as React from "react"
import { observer, inject } from "mobx-react"
import { RouteComponentProps, Route, Redirect, Switch } from "react-router"

import BrandStore from "stores/BrandStore"
import LocationSummary from "models/LocationSummary"

import APILoader from "components/wrappers/APILoader"

// TODO: this should really be phased out.
// goal is to always have a location

export type RCProps = RouteComponentProps<{ locationId?: string }>

export interface InnerProps extends RCProps {
  store?: BrandStore
  // whether the location should be explicitly in the URL
  locationInPath?: boolean
  inComingSoon?: boolean
  path: string
  render(props: InjectedProps): React.ReactChild
}

export type InjectedProps = RCProps & { locationSummary: LocationSummary }

export type OuterProps = Subtract<InnerProps, RCProps>

@inject((store: BrandStore) => ({ store }))
@observer
class LocationWrapperInner extends React.Component<InnerProps, {}> {
  locStore = this.props.store!.locStore
  constructor(props: InnerProps) {
    super(props)
    const routeLocationId = this.props.match.params.locationId
    this.locStore.setCurrent({ locationId: routeLocationId, fromRoute: true })
  }

  public render() {
    const { store, path, render, children, ...rcProps } = this.props
    if (this.props.locationInPath && !this.locStore.isInLocationRoute) {
      // switch is needed to make the params get substituted
      // e.g. test/:id (id=123) needs to go to test/123/st-louis
      // not test/:id/st-louis
      return (
        <Switch>
          <Redirect
            from={path}
            to={!store!.isXponential && store!.settings.serviceBooking && path === '/book'
              ? `${path}/${this.locStore.currentLocationId}/services`
              : `${path}/${this.locStore.currentLocationId}`
            }
          />
        </Switch>
      )
    }
    return (
      <APILoader
        apiStore={this.locStore}
        key={`${store!.userStore.isLoggedIn}`}
        render={() => {
          if (
            this.locStore.currentLocation && this.locStore.currentLocation.comingSoon &&
            !this.props.inComingSoon
          ) {
            return <Redirect from={path} to="/" />
          }

          return (
            <React.Fragment key={this.locStore.currentLocationId}>
              {this.props.render({
                ...rcProps,
                locationSummary: this.locStore.currentLocation!,
              })}
            </React.Fragment>
          )
        }}
      />
    )
  }
}

// Wrap it in a route, inject the optional part
const LocationRoute: React.FunctionComponent<OuterProps> = ({
  path,
  render,
  locationInPath,
  inComingSoon,
}) => {
  return (
    <Route
      // why did I do this? why not be explicit and include the :locationId in
      // the path prop string?
      path={locationInPath ? `${path}/:locationId?` : path}
      render={rcProps => (
        <LocationWrapperInner
          key={rcProps.match.params.locationId}
          {...rcProps}
          path={path}
          render={render}
          locationInPath={locationInPath}
          inComingSoon={inComingSoon}
        />
      )}
    />
  )
}

export default observer(LocationRoute)
