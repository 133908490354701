import * as React from "react"
import LocationSummary from "models/LocationSummary"

interface ModalProps {
  status?: { status: string, message?: string }
  locationSummary?: LocationSummary
  onCancel(): void
  closeModal(): void
}

const ACHModal: React.SFC<ModalProps> = ({
  status,
  locationSummary,
  onCancel,
  closeModal,
}) => {
  const modalStatus = status!.status
  const showModal = modalStatus === "success" || modalStatus === "failure" ? true : false
  let title = "Bank Account set as default"
  let message = locationSummary!.achPromoMessage || ""
  let buttonText = "Great, Thanks!"

  if (modalStatus === "failure") {
    title = "Error adding bank account"
    message = status!.message
      ? status!.message
      : "There was an issue adding your bank account. Please double check the fields and try again."
    buttonText = "Okay"
  }

  const handleClick = (modalStatus: string) => {
    if (modalStatus === "success") {
      document.body.classList.remove('has-fixed-modal')
      onCancel()
    } else if (modalStatus === "failure") {
      closeModal()
    }
  }

  return (
    <>
      {showModal && (
        <div className="ach-modal">
          <div className="ach-modal--card">
            <h3>{title}</h3>
            <div>{`${message}`}</div>
            <button
              className="btn btn-primary"
              onClick={() => handleClick(modalStatus)}
            >
              {buttonText}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ACHModal
