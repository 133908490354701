import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosError } from "axios"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import { observable, action, computed } from "mobx"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ScheduleEntry from "models/ScheduleEntry"
import LocationSummary from "models/LocationSummary"

export interface ResponseBody {
  schedule_entry: ScheduleEntry
  location: LocationSummary
}

// this is basically the same thing as the booking info store
// should they be combined?
export default class ScheduleEntryStore extends APIStore {
  @observable scheduleEntry?: ScheduleEntry

  api = this.createClient<ResponseBody>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    DeserializeMiddleware("schedule_entry", ScheduleEntry),
    DeserializeMiddleware("location", LocationSummary),
  ])

  constructor(public id: string, public locationId: string) {
    super()
  }

  fetch() {
    return this.api.get(
      `/api/v2/locations/${this.locationId}/schedule_entries/${this.id}`
    )
  }

  @action.bound
  handleSuccess(res: AxiosResponse<ResponseBody>) {
    res.data.schedule_entry.location = res.data.location
    this.scheduleEntry = res.data.schedule_entry

    return res
    // const location = res.data.location
    // this.booking = res.data.booking
    // this.bookingStatus = res.data.booking_status
    // this.room = res.data.room

    // if ((this.booking = res.data.booking)) {
    //   this.booking.isBusy = false
    //   this.booking.scheduleEntry.location = location
    // }
    // if ((this.bookingStatus = res.data.booking_status)) {
    //   this.bookingStatus.scheduleEntry.location = location
    // }
    // return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    console.error(ex)
    // TODO: if this is a 403 we want to redirect them through a login/registration
    // flow of some sort.
    throw ex
  }
}
