import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("Waiver")
export default class Waiver {
  @JsonProperty("terms", String, true)
  terms?: string = undefined!

  @JsonProperty("covid", String, true)
  covid?: string = undefined!
}
