import * as React from "react"
import { inject, observer } from "mobx-react"
import { observable, action } from "mobx"
import * as cx from "classnames"
import * as yup from "yup"
import { pick } from "lodash"

import { InjectedFormikProps, withFormik, Form, FormikBag } from "formik"
import { parse } from "helpers/queryString"
import { withAxiosErrorMessage } from "helpers/errorHandling"
import {
  phoneNumberValidator,
  nameValidator
} from "helpers/validations"

import PaymentSource from "models/PaymentSource"
import BookingInfoStore from "apps/buy/stores/BookingInfoStore"
import BrandStore from "stores/BrandStore"
import PaymentSourceUpdateStore from "apps/account/stores/PaymentSourceUpdateStore"
import PaymentSourcesStore from "apps/account/stores/PaymentSourcesStore"
import XpassV3FormRequest from "apps/auth/models/xpass/XpassV3FormRequest"
import PlanSPCStore from "apps/buy/stores/xpass/PlanSPCStore"
import PurchaseSPCStore from "apps/buy/stores/xpass/PurchaseSPCStore"
import PromoCodeSPCForm from "apps/buy/components/xpass/PromoCodeSPCForm"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"
import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"
import APILoader from "components/wrappers/APILoader"
import Spinner from "components/Spinner"
import PageTracker from "components/wrappers/PageTracker"
import ContractCheckbox from "apps/buy/components/ContractCheckbox"
import PackageRenewalText from "apps/buy/components/PackageRenewalText"
import { UserSession } from "models/UserSession"

export interface Props {
  packageId: string
  store?: BrandStore
  bookingInfoStore?: BookingInfoStore
  paymentSourcesStore?: PaymentSourcesStore
  paymentSource?: PaymentSource
  setIsExistingUser: Function
  isExistingUser: boolean
}

export interface State {
  stickyPlanEl: HTMLElement | null
  emailDisabled: boolean | ''
  submitDisabled: boolean
  showSpinner: boolean
}

type XpassV3FormRequestWithoutPassword = Omit<XpassV3FormRequest, 'password'>

type FormValues = XpassV3FormRequestWithoutPassword

let query = parse(location.search)
const email = query.email ? decodeURI(query.email) : ''
let planStore: PlanSPCStore
let submitBuy: boolean

@inject((store: BrandStore) => ({ store }))
@observer
class InnerForm extends React.Component<
  InjectedFormikProps<Props, FormValues>, State
> {
  @observable contractChecked = false

  constructor(props: InjectedFormikProps<Props, FormValues>) {
    super(props)
    this.state = {
      stickyPlanEl: null,
      emailDisabled: props.values.email && props.values.email === email,
      submitDisabled: false,
      showSpinner: false
    }
  }

  isAuth = isAuthorized(this.props.store!)

  planStore = new PlanSPCStore(
    this.props.store!,
    this.props.packageId,
    this.isAuth
  )

  @action.bound
  handleTermsAccept(e: React.FormEvent<HTMLInputElement>) {
    this.contractChecked = e.currentTarget!.checked
  }

  componentDidMount() {
    this.props.store!.uiStore.hideNavLinks()
    this.props.store!.styleClasses.MainMenu__container = 'light-nav'
    this.props.store!.styles.logoUrl = this.props.store!.brandData.styles.logoUrl // logoUrl
    this.props.store!.uiStore.nav.signOutLinkShow = false
    const bodyElement = document.querySelector('body') as HTMLElement
    this.setState({stickyPlanEl : document.querySelector('.brandCheckout-callout')})
    bodyElement.classList.add("brandCheckout-theme")

    localStorage.removeItem('redirectLink')
  }

  componentWillUnmount() {
    this.props.store!.uiStore.showNavLinks()
    const bodyElement = document.querySelector("body") as HTMLElement
    bodyElement.classList.remove("brandCheckout-theme")
  }

  componentDidUpdate(prevProps: Readonly<InjectedFormikProps<Props, XpassV3FormRequestWithoutPassword>>, prevState: Readonly<State>, snapshot?: any): void {

   if(prevProps.status === "" && this.props.status === 'error'){
    console.log("general error")
      this.setState({
        showSpinner: false
      })
    }
  }

  formatCcNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.replace(/[^\d]/g, "")
    const ccNumberPartial = [
      value.slice(0, 4),
      value.slice(4, 8),
      value.slice(8, 12),
      value.slice(12, 16),
    ].filter(v => v)

    this.props.setFieldValue("ccNumber", ccNumberPartial.join(" "))
  }

  formatNumbersOnly = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.setFieldValue(
      e.currentTarget.name,
      e.currentTarget.value.replace(/[^\d]/g, "")
    )

  get isLoggedIn() {
    return isAuthorized(this.props.store!)
  }

  handleBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    const fieldName = e.target.name
    const fieldValue = e.target.value
    const schema = getSchema(this.props)
    const phoneMain = this.props.values.phoneMain as string
    const ccNumber = this.props.values.ccNumber! as string
    const ccExpMonth = parseInt(this.props.values.ccExpMonth!, 10) as number
    const ccExpYear = parseInt(this.props.values.ccExpYear!, 10) as number
    const ccSecurityCode = parseInt(this.props.values.ccSecurityCode!, 10) as number

    this.props.setFieldValue(fieldName, fieldValue)
    this.props.setFieldError(fieldName, "")
    try {
      // @ts-ignore
      await schema.validateAt(fieldName, {...this.props.values, phoneMain, ccNumber, ccExpMonth, ccExpYear, ccSecurityCode})
      e.target.classList.add('is-valid')
    } catch(ex) {
      this.props.setFieldError(fieldName, ex.errors)
      e.target.classList.remove('is-valid')
    }
  }

  public render() {
    const { touched, errors, isSubmitting, isValid, store } = this.props
    const userEmail =  store && store.userStore && store.userStore.session && store.userStore.session.email

    const renderError = () => {
      return (
        <div className="alert alert-danger mx-auto">
          <h4>An Error Occurred</h4>
          <p>We apologize for the inconvenience</p>
        </div>
      )
    }

    // Ensure the purchase form submission isn't triggered by the promo code form
    const onSubmit = async () => {
      const errors = await this.props.validateForm()
      if (JSON.stringify(errors) === '{}') {
        this.setState({
          showSpinner: true
        },()=>{
          localStorage.setItem("newUser", "true")
          submitBuy = true
         this.props.handleSubmit()
        })
      }
    }

    return (
      <>
        {this.isLoggedIn && !this.state.showSpinner &&
          <>
            <div className="brandCheckout-email-to-login">
              <p>You are logged in as: <span>{userEmail}</span></p>
            </div>
            <br />
            <br />
          </>
        }
        <div className="text-center">
          <h1 className="brandCheckout-h1 spc">Complete Your Purchase</h1>
          <p>
            { this.planStore.package  && this.planStore.package.description}
          </p>
        </div>
        <Form
          noValidate
          id="BrandCheckoutForm"
          className="brandCheckout brandCheckout my-4 mx-auto spc"
        >
          {!this.state.showSpinner &&
          <>
            <PageTracker name={'checkout'} />
            <div className="brandCheckout-column">
              <div className="row">
                <FormInput
                  label="Email"
                  name="email"
                  autoComplete="email"
                  className={cx("col-12 form-group",{ 'hidden': this.isLoggedIn })}
                  maxLength={50}
                  disabled={this.state.emailDisabled || isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <div className={cx("mx-auto col-lg-12 mb-4 brandCheckout-text-fine brandCheckout-text-muted", { 'hidden': this.isLoggedIn })}>
                  This will be your account username
                </div>
                <FormInput
                  label="Mobile Number"
                  name="phoneMain"
                  autoComplete="tel-national"
                  className={cx("col-12 form-group", { 'hidden': this.isLoggedIn })}
                  maxLength={50}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <div className="col text-left mb-3">
                  <h3>Set up your debit or credit card</h3>
                </div>
                <FormInput
                  label="First Name"
                  name="firstName"
                  autoComplete="cc-given-name"
                  className="col-12"
                  maxLength={50}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Last Name"
                  name="lastName"
                  autoComplete="cc-family-name"
                  className="col-12"
                  maxLength={50}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Credit Card Number"
                  placeholder="1234 1234 1234 1234"
                  name="ccNumber"
                  autoComplete="cc-number"
                  className="col-12 col-lg-6"
                  onChange={this.formatCcNumber}
                  maxLength={20}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Exp Month"
                  name="ccExpMonth"
                  autoComplete="cc-exp-month"
                  placeholder="MM"
                  className="col-6 col-lg-3"
                  onChange={this.formatNumbersOnly}
                  maxLength={2}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Exp Year"
                  name="ccExpYear"
                  autoComplete="cc-exp-year"
                  placeholder="YYYY"
                  className="col-6 col-lg-3"
                  onChange={this.formatNumbersOnly}
                  maxLength={4}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="CVC"
                  name="ccSecurityCode"
                  autoComplete="cc-csc"
                  placeholder="CVV"
                  className="col-6 col-lg-3"
                  onChange={this.formatNumbersOnly}
                  maxLength={4}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
                <FormInput
                  label="Zip Code"
                  name="zip"
                  placeholder="12345"
                  autoComplete="billing postal-code"
                  className="col-6 col-lg-3"
                  maxLength={10}
                  disabled={isSubmitting}
                  onBlur={(e) => {this.handleBlur(e)}}
                />
              </div>
            </div>
            <div className="card px-4 col-md-5 ml-md-auto text-center purchase-card">
              <APILoader
                apiStore={this.planStore}
                renderError={renderError}
                render={() => {
                  planStore = this.planStore
                  const pkg = planStore.package!
                  const { plan, promoCode, discount, originalPrice } = planStore
                  const usesPromo = promoCode && discount > 0
                  return (
                    <>
                    <br />
                      <div className="row brandCheckout-callout sticky">
                        <div className="col-12 px-2 text-left purchase-card__plan-details">
                          <p className="mb-1">Your Selected Plan</p>
                          <h3 className="text-left">{pkg.name}</h3>
                          <small><PackageRenewalText pkg={pkg} store={this.props.store!} /></small>
                        </div>
                      </div>
                      <PromoCodeSPCForm planStore={planStore} />
                      {plan!.payments
                        .map((payment, i) => (
                          <div className="brandCheckout-lineItem" key={i}>
                            <div className="text-left">
                              <div>Subtotal</div>
                            </div>
                            <div className="text-right">
                              {payment.subtotal.formatted}
                            </div>
                          </div>
                        ))
                      }
                      {plan!.fees.length > 0 && <hr />}
                      {plan!.fees
                        .filter(f => f.isDueToday)
                        .map((fee, i) => (
                          <div className="brandCheckout-lineItem" key={i}>
                            <div className="text-left">{fee.name} </div>
                            <div className="text-right">{fee.subtotal.formatted}</div>
                          </div>
                        ))}
                      <div className="brandCheckout-lineItem">
                        <div className="text-left">Tax </div>
                        <div className="text-right">{plan!.todayTax.formatted} </div>
                      </div>
                      <hr />
                      {usesPromo && (
                        <>
                          <div className="brandCheckout-lineItem brandCheckout-text-muted">
                            <div className="text-left">Original Total </div>
                            <div className="text-right">
                              {this.planStore.originalPrice!.formatted}
                            </div>
                          </div>
                          <div className="brandCheckout-lineItem">
                            <div className="text-left text-success">
                              Discount Code {promoCode}
                            </div>
                            <div className="text-right text-success">
                              - ${discount.toFixed(2)}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="brandCheckout-lineItem brandCheckout-text-bold">
                        <div className="text-left ">Order Total </div>
                        <div className="text-right">{plan!.todayTotal.formatted}</div>
                      </div>
                      <hr />
                      {pkg.hasContract && pkg.contractUrl && (
                        <ContractCheckbox
                          pkg={pkg}
                          store={this.props.store}
                          checked={this.contractChecked}
                          onChange={this.handleTermsAccept}
                          isSingleCheckout
                        />
                      )}
                      <FormButtons
                        submitText="Complete Purchase"
                        disabled={(pkg.hasContract && pkg.contractUrl) ? (!this.contractChecked || isSubmitting): this.state.submitDisabled }
                        className="brandCheckout-submit"
                        onSubmit={onSubmit}
                        isSingleCheckout={true}
                        minWidth={240}
                      />

                      <br />
                    </>
                  )
                }}
              />
            </div>
          </>
        }
        {this.state.showSpinner &&
          <Spinner size={"page"} className="mx-auto brandCheckout-spinner" isSingleCheckout={true} />
        }
        </Form>
      </>
    )
  }
}

const schemaAuthorized = yup.object<FormValues>().shape({
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  ccNumber: yup
    .string()
    .label("Credit / Debit Card Number")
    .required(),
  ccSecurityCode: yup
    .number()
    .required()
    .label("Security Code"),
  ccExpMonth: yup
    .number()
    .required()
    .label("Expiration Month")
    .min(1)
    .max(12),
  ccExpYear: yup
    .number()
    .required()
    .label("Expiration Year")
    .min(new Date().getFullYear()),
  zip: yup
    .string()
    .required()
    .label("Postal Code"),
})

const schemaUnauthorized = yup.object<FormValues>().shape({
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  phoneMain: phoneNumberValidator.required().label("Mobile Phone"),
  ccNumber: yup
    .string()
    .label("Credit / Debit Card Number")
    .required(),
  ccSecurityCode: yup
    .number()
    .required()
    .label("Security Code"),
  ccExpMonth: yup
    .number()
    .required()
    .label("Expiration Month")
    .min(1)
    .max(12),
  ccExpYear: yup
    .number()
    .required()
    .label("Expiration Year")
    .min(new Date().getFullYear()),
  zip: yup
    .string()
    .required()
    .label("Postal Code"),
})

const timeout = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}


/* Registers User and Signs In */
const registerUser = async (
  values: XpassV3FormRequestWithoutPassword,
  formikBag: FormikBag<{ store?: BrandStore, userAuthStore?: UserAuthStore }, XpassV3FormRequestWithoutPassword>,
): Promise<any> => {
  const { authStore, uiStore, locStore, track } = formikBag.props.store!
  const loc = locStore.currentLocation
  const type = new XpassV3FormRequest()
  delete type.password

  const accountValues = pick(values, [
    'email',
    'firstName',
    'lastName',
    'phoneMain',
    'zip',
    'countryCode',
    'clubreadyReferralTypeId',
    'clubreadyReferralTypeIdCa',
    'clubreadyProspectTypeId',
    'clubreadyProspectTypeIdCa',
  ]);

  const handleSignInClick = () => {
    uiStore.closeModal()
    track.event("log in_tap sign in", { loc }, { cta: "sign in" })
  }

  try {
    const data = await authStore.authWithoutPasswordXpass(
      Object.assign(type, {...accountValues}),
    )
    await timeout(1) // pause for 1ms to allow user sign up to happen
    track.event("authentication_success", { loc }, { cta: "complete purchase" })
    if(data.data.error_messages && data.data.error_messages.length){
      return Promise.reject( data.data.error_messages[0])
    }
    else {
      return Promise.resolve(data.data.user)
    }
  } catch (err) {
    console.log(err)
    const response = withAxiosErrorMessage(err)
    formikBag.setSubmitting(false)
    formikBag.setErrors(err)
    track.event("authentication_failure", { loc }, { cta: "complete purchase" })

    if (response && response.data.code === "service_conflict") {
      uiStore.openModal({
        type: "error",
        title: "Something went wrong",
        message: response.data.message,
        modalShow: true,
        locationSummary: loc,
      })
    } else {
      // If there are any account creation issues, the user needs to be able to change the email address.
      // Set it to null and remove it from the query string
      values.email = ""
      formikBag.setStatus('unlockEmail')
      formikBag.props.store!.routingStore.history.push(location.pathname)
      query = {}
      if (response && response.data.message.includes('User exists')) {
        uiStore.openModal({
          message: "User creation failed. User exists.",
          modalShow: true,
          children: (
            <div className="my-4 text-center">
              <div className="mb-3">An account already exists with this e-mail.</div>
              <a
                className="btn btn-primary mr-2"
                href="/auth/login"
                onClick={() => handleSignInClick()}
              >
                Sign In
              </a>
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={() => uiStore.closeModal()}
              >
                Close
              </button>
            </div>
          ),
        })
      } else {
        uiStore.openError(err)
      }
    }
    return Promise.reject()
  }

}

/* Submits payment method and saves it as default on user profile */
const savePaymentMethod = async (
  values: XpassV3FormRequestWithoutPassword,
  formikBag: FormikBag<{ store?: BrandStore }, XpassV3FormRequestWithoutPassword>,
  session: UserSession
): Promise<void> => {
  const { accessToken } = session
  const { store } = formikBag.props
  const paymentSourcesStore = new PaymentSourcesStore(store!)
  const paymentSourceUpdateStore = new PaymentSourceUpdateStore(paymentSourcesStore)

  store!.track.event("purchase_add new credit card")

  // Delete locationId
  if(values.hasOwnProperty('locationId')){
    delete values.locationId
  }

  // Inject Email (logged in users)
  const userEmail = store! && store!.userStore && store!.userStore.session && store!.userStore.session.email
  if(values.email === ""){
    values = {
      ...values,
      email: userEmail ? userEmail : ''
    }
  }

  try {
    const { data } = await paymentSourceUpdateStore.update({ ...values, accessToken })
    if (data.errors) {
      const err = data.errors
      formikBag.setErrors(err)
      store!.track.event("add new credit card_failure")
      return Promise.reject(data)
    } else {
      store!.track.event("add new credit card_success")
      return Promise.resolve()
    }
  } catch (error) {
    console.error("An error occurred during the API call:", error)
    formikBag.setSubmitting(false)
    store!.track.event("add new credit card_failure")
    return Promise.reject(error)
  }
}


/* Submit purchase request (uses default payment method saved from savePaymentMethod) */
const purchase = async (
  formikBag: FormikBag<{ store?: BrandStore }, XpassV3FormRequestWithoutPassword>, session: UserSession,
) => {
  const { store } = formikBag.props
  const purchaseStore = new PurchaseSPCStore(planStore)
  const loc = store!.locStore.currentLocation

  const userSession = session ? session : formikBag.props.store!.userStore.session

  formikBag.props.store!.track.event(
    "purchase_tap purchase",
    {
      loc: loc,
      pkg: purchaseStore.pkg,
      plan: purchaseStore.plan,
    },
    {
      purchasePromoCode: !!purchaseStore.promoCode,
    }
  )

  try {
    formikBag.setSubmitting(true)
    const res: any = await purchaseStore.makePurchase(userSession!)
    formikBag.setSubmitting(false)
    const purchaseData = res.data.purchase
    store!.track.revenue(
      "purchase_success",
      {
        loc: loc!,
        pkg: purchaseStore.pkg,
        plan: purchaseStore.plan,
      },
      {
        clubreadyAgreementId: purchaseData.id,
        purchasePromoCode: !!purchaseStore.promoCode,
      }
    )
    return Promise.resolve(purchaseData)
  } catch (ex) {
    console.log(ex)
    formikBag.setSubmitting(false)
    store!.track.event(
      "purchase_failure",
      {
        loc: loc,
        pkg: purchaseStore.pkg,
        plan: purchaseStore.plan,
      },
      {
        purchasePromoCode: !!purchaseStore.promoCode,
      }
    )
    return Promise.reject(ex.response.data)
  }
}

const isAuthorized = (store: BrandStore) => {
  return store.userStore!.isLoggedIn
}

const getSchema = (props: Props) => {
  return isAuthorized(props.store!) ? schemaAuthorized : schemaUnauthorized
}

const PaymentPageNoPasswordSPC = inject((store: BrandStore) => ({ store }))(
  observer(
    withFormik<Props & { store?: BrandStore, setIsExistingUser: Function, isExistingUser: boolean }, XpassV3FormRequestWithoutPassword>({
      mapPropsToValues: (props) => {
        const user = props.store!.userStore.session!
        const isAuth = isAuthorized(props.store!)
        const query = parse(location.search)
        const locationVal = query.location

        // If user is logged in but an email is passed that does not match the logged in user,
        // log out that user and use the passed email instead for sign up.
        if (isAuth && email && email !== user.email) {
          props.store!.authStore.signOut("", false)
        }

        // TODO: Remove form test credentials
        return {
          clubreadyReferralTypeId: query.clubready_referraltypeid,
          clubreadyReferralTypeIdCa: query.clubready_referraltypeid_ca,
          clubreadyProspectTypeId: query.clubready_prospecttypeid,
          clubreadyProspectTypeIdCa: query.clubready_prospecttypeid_ca,
          locationId: "xponential-xpass",
          location: locationVal || "",
          isDefault: true,
          email: email || "",
          firstName: isAuth ? user.firstName as string : "",
          lastName: isAuth ? user.lastName as string : "",
          phoneMain: isAuth ? user.phoneMain as string : "",
          ccNumber: "",
          ccSecurityCode:  "",
          ccExpMonth: "",
          ccExpYear: "",
          zip: "",
          countryCode: props.store!.settings.billingCountryCode,
          type: ""
        }
      },
      validateOnChange: false,
      validateOnBlur: true,
      validationSchema: (props: Props) => { return getSchema(props) },
      handleSubmit: async (values: XpassV3FormRequestWithoutPassword, formikBag: FormikBag<{ store?: BrandStore, setIsExistingUser: Function, isExistingUser: boolean }, XpassV3FormRequestWithoutPassword>) => {
        // Ensure this form submission isn't triggered by the promo code form

        formikBag.setStatus("")

        // Call 1
        if (submitBuy) {
          const { store, setIsExistingUser, isExistingUser } = formikBag.props
          const isAuth = isAuthorized(store!)
          const { history } = formikBag.props.store!.routingStore
          let session
          if (!isAuth) {
            try {
              const data = await registerUser(values, formikBag)
              session = data
              setIsExistingUser(session.isExistingUser)
            }
            catch(ex) {
                console.log('spc registration error: ', ex)
                formikBag.setStatus("error")
                formikBag.setSubmitting(false)
                store!.uiStore.openModal({
                  // type: "error",
                  title: "Something went wrong",
                  size: "md",
                  message: ex,
                  modalShow: true,
                  children: (
                    <div className="my-4 text-center">
                    <p>{ex}</p>
                    <a
                      className="btn btn-primary mr-2"
                      href={'auth/login'}
                      onClick={() =>{
                        localStorage.setItem("redirectLink", document.location.href) // This is removed in this file onComponentDidMount
                        document.location.href = 'auth/login'
                        store!.uiStore.closeModal()
                      }}>
                       Click here to log-in
                    </a>
                  </div>
                  )

                })
                throw ex
              }
          } else {
            session = formikBag.props.store!.userStore.session
          }

          // Call 2
          try { await savePaymentMethod(values, formikBag, session) }
          catch(err) {
              console.log('spc payment error', err)
              let errorMessage = err.error_messages && err.error_messages[0] ? err.error_messages[0] : "Try again later."
              if (errorMessage.includes("Cc number is invalid")) {
                errorMessage = "Credit card number is invalid"
              }
              formikBag.setStatus('error')
              formikBag.setSubmitting(false)
              store!.uiStore.openModal({
                type: "error",
                size: "md",
                title: "Something went wrong",
                message: `Sorry there was a payment error: ${errorMessage}`,
                modalShow: true,
              })
              throw err
            }

          // Call 3
          try {
            await purchase(formikBag, session)
          }
          catch(err) {
            console.log('spc purchase error')
            formikBag.setStatus("error")
            formikBag.setSubmitting(false)
            store!.uiStore.openModal({
                type: "error",
                title: "Something went wrong",
                message: `${err.message}`,
                modalShow: true,
                onClose: () => {
                  if (isAuth) {
                    history.push('/checkout-error?statusText=BadRequest')
                  } else {
                    store!.authStore.signOut("", false)
                    history.push(`/auth/login?already_purchased=true`)
                  }
                }
              })
              throw err
          }

          formikBag.setSubmitting(false)

          if (isAuth && (isExistingUser || session.isExistingUser === undefined)) {
            document.location.href = '/checkout-success'
          } else {
            formikBag.props.store!.userStore.session = session
            const accessToken: string = session.accessToken
            if (session.isExistingUser) {
              store!.authStore.signOut("", false)
              history.push("/auth/login?post_purchase=true")
            } else {
              history.push(`/simple-checkout/password?access_token=${accessToken}`)
            }
          }
        } else {
          formikBag.setSubmitting(false)
        }
        submitBuy = false
      }
    })(InnerForm)
  )
)

export default PaymentPageNoPasswordSPC
