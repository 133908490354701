import * as React from "react"
import { Link } from "react-router-dom"
import { inject, observer } from "mobx-react"
import capitalize from "lodash/capitalize"
import cx from "classnames"
import * as moment from "moment"

import APILoader from "components/wrappers/APILoader"
import DashboardApiStore from "apps/dashboard/stores/DashboardApiStore"
import BookingsStore from "apps/bookings/stores/BookingsStore"
import BrandStore from "stores/BrandStore"
import Booking from "models/Booking"
import ServiceBooking from "apps/book/models/ServiceBooking"
import FavoriteHeart from "components/FavoriteHeart"
import markerPin from "images/markers/pin.png"
import DetailsPageMap from "apps/xpass/DetailsPageMap"

interface Props {
  className?: string
  store?: BrandStore
  dashboardStore: DashboardApiStore
  bookingsStore: BookingsStore
  getBrandIcon: Function
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class NextClassCard extends React.Component<Props, {}> {
  handleHeaderClick = (_: LinkEvent) => {
    this.props.store!.track.event("home_tap view my schedule")
  }

  handleBookClick = (_: LinkEvent) => {
    this.props.store!.track.event("home_tap book")
  }

  bookingStatusString(booking: Booking | ServiceBooking) {
    if (booking.type === "waitlist") {
      const position = booking.waitlistPosition
        ? ` #${booking.waitlistPosition}`
        : ""
      return `Waitlist${position}`
    }

    return booking.status === "completed" ? "Checked-in" : "Booked"
  }

  render() {
    // TODO: we should actually probably generate these mapbox snapshots
    // on the server-side if we're subject to a quota. To do at some point.
    const token = window.globals.mapboxAccessToken
    const {
      copy,
      settings,
      locStore,
      isXponential,
      brandId,
      styleClasses,
    } = this.props.store!
    const apiStore = isXponential
      ? this.props.bookingsStore
      : this.props.dashboardStore
    const img = {
      imageUrl: markerPin,
      brandId: brandId,
    }
    const slugBrandName = {
      akt: "AKT",
      clubpilates: "Club Pilates",
      cyclebar: "CycleBar",
      purebarre: "Pure Barre",
      rowhosue: "Row House",
      stretchlab: "StretchLab",
      stride: "Stride",
      yogasix: "YogaSix",
      kinrgy: "KINRGY",
    }
    const noUpcomingClassText = isXponential
      ? copy.noUpcomingClassesBooked
      : copy.noUpcomingClassesBooked.replace(/{CLASSES}/g, copy.classes)

    return (
      <div className={cx("card next-class-card", this.props.className)}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="card-title text-dark">
            My Next {capitalize(copy.class)}
          </h5>
          <small>
            <Link to={`/bookings`} onClick={this.handleHeaderClick}>
              {copy.nextClassHeader} My Schedule
            </Link>
          </small>
        </div>
        {isXponential && <span className="border-top border-light" />}
        <div className="flex-grow-1 d-flex flex-column p-0">
          <APILoader
            apiStore={apiStore}
            elementSize="element"
            propagateErrors
            render={() => {
              const { nextBooking: booking } = apiStore
              const entry = booking && booking.scheduleEntry
              const location = isXponential
                ? booking && booking!.scheduleEntry!.location
                : this.props.store!.locStore.currentLocation!
              // Not loving this nested ternary, but eh...
              const destination = isXponential
                ? "/book"
                : locStore.currentLocation && settings.serviceBooking
                ? `/book/${locStore.currentLocation.id}/services`
                : "/book"
              const duration =
                entry &&
                moment
                  .duration(
                    moment(entry.endsAt, "YYYY-MM-DDTHH:mm:s").diff(
                      moment(entry.startsAt, "YYYY-MM-DDTHH:mm:s")
                    )
                  )
                  .asMinutes()

              if (!entry) {
                return (
                  <div className="card-body flex-grow-1 d-flex flex-column grid-in">
                    <h4>Book Your Next {capitalize(copy.class)}</h4>
                    <p>{noUpcomingClassText}</p>
                    <Link
                      className={cx(
                        "btn btn-primary",
                        isXponential ? "mt-5 mt-md-auto" : "mt-auto mb-0"
                      )}
                      to={destination}
                      onClick={this.handleBookClick}
                    >
                      {isXponential
                        ? `Book a ${capitalize(copy.class)}`
                        : copy.findAClass}
                    </Link>
                  </div>
                )
              }

              return (
                <>
                  <div className="card-body flex-grow-1 border-bottom d-flex">
                    {entry.classCategory &&
                      entry.classCategory.id &&
                      !isXponential && (
                        <div className="mr-3">
                          <FavoriteHeart
                            type="class"
                            id={entry.classCategory.id}
                            width="20"
                            height="20"
                          />
                        </div>
                      )}

                    <div
                      className={cx(
                        isXponential &&
                          "d-flex border border-light rounded w-100 align-items-center"
                      )}
                    >
                      {isXponential && (
                        <div>
                          <img
                            src={this.props.getBrandIcon(
                              entry.location!.brandId
                            )}
                          />
                        </div>
                      )}

                      <div className="flex-grow-1 next-class-card__description">
                        {isXponential ? (
                          <>
                            <h4 className="mb-0">{entry.title}</h4>
                            <p className="mb-0 next-class-card__start-time">
                              {entry.startTime} {entry.timezone}
                              {duration && (
                                <>&nbsp;&ndash;&nbsp;{duration}&nbsp;min</>
                              )}
                            </p>
                            <p className="mb-0 next-class-card__location-name">
                              {slugBrandName[entry.location!.brandId]}{" "}
                              {entry.location!.name}
                            </p>
                          </>
                        ) : (
                          <>
                            <h4>{entry.title}</h4>
                            <div>
                              {entry.startsAt.format(styleClasses.NextClassCard__dateFormat)} &bull;&nbsp;
                              {entry.startTime}&ndash;
                              {entry.endTime}&nbsp;
                              {isXponential && entry.timezone}
                            </div>
                          </>
                        )}

                        {!isXponential && (
                          <>
                            {entry.instructor && (
                              <div>Instructor: {entry.instructor.name}</div>
                            )}
                            <div>
                              Status: {this.bookingStatusString(booking!)}
                            </div>
                          </>
                        )}
                        {debugUI && booking && (
                          <div className="small">{booking.id}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <DetailsPageMap
                    img={img}
                    brandId={brandId}
                    location={location!}
                    height="100%"
                    hasHeader={false}
                    dashboardCard={true}
                  />
                </>
              )
            }}
          />
        </div>
      </div>
    )
  }
}
