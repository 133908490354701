import * as React from "react"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import { observable } from "mobx"
import { observer, inject } from "mobx-react"
import cx from "classnames"

import BrandStore from "stores/BrandStore"
import ChallengeStore from "../challenge/stores/ChallengeStore"
import DashboardApiStore from "apps/dashboard/stores/DashboardApiStore"
import BookingsStore from "apps/bookings/stores/BookingsStore"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import HomePromoStore from "apps/dashboard/stores/HomePromoStore"
import UserCoordinatesStore from "apps/book/stores/xpass/UserCoordinatesStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import LocationBrandsStore from "apps/dashboard/stores/LocationBrandsStore"
import BookingFlowStore from "apps/book/stores/BookingFlowStore"
import FavoritesStore from "apps/account/stores/FavoritesStore"
import { LATEST_BOOKING_PATH_KEY } from "apps/book/stores/QueryStringMonitor"
import { saveState } from "services/savedState"
import ScheduleEntry from "models/ScheduleEntry"
import LocationSummary from "models/LocationSummary"

import { DashboardCardName } from "themes/base"
import NextClassCard from "apps/dashboard/NextClassCard"
import TodaysClassCard from "apps/dashboard/TodaysClassCard"
import AttendanceCard from "apps/dashboard/AttendanceCard"
import MembershipCard from "apps/dashboard/MembershipCard"
import ProfileCard from "apps/dashboard/ProfileCard"
import PerformanceCard from "apps/dashboard/PerformanceCard"
import { MobileAppCard } from "apps/dashboard/BannerCard"
import { MobileOfferCard } from "apps/dashboard/BannerAltCard"
import BadgeCard from "apps/dashboard/BadgeCard"
import ChallengeCenterCard from "apps/dashboard/ChallengeCenterCard"
import YogaSixFebruaryChallengeCard from "apps/dashboard/YogaSixFebruaryChallengeCard"
import BrandsCard from "apps/dashboard/BrandCard"
import MyStatsCard from "apps/dashboard/MyStatsCard"
import MyStudioCard from "apps/dashboard/MyStudioCard"
import AppleHealthCard from "apps/dashboard/AppleHealthCard"
import ClassPointsCard from "apps/dashboard/ClassPointsCard"
import OfferCard from "apps/dashboard/OfferCard"
import PromoCards from "apps/dashboard/PromoCards"
import AlertBanner from "apps/dashboard/AlertBanner"

import Heading from "components/Heading"
import CardWrapper from "components/wrappers/CardWrapper"
import PageTracker from "components/wrappers/PageTracker"
import FreeClassBanner from "apps/xpass/FreeClassBanner"
import BookingFlowModal from "apps/book/components/xpass/BookingFlowModal"

import aktPin from "images/xpass/icons/brands/akt.png"
import bftPin from "images/xpass/icons/brands/bft.png"
import clubpilatesPin from "images/xpass/icons/brands/clubpilates.png"
import cyclebarPin from "images/xpass/icons/brands/cyclebar.png"
import purebarrePin from "images/xpass/icons/brands/purebarre.png"
import rowhousePin from "images/xpass/icons/brands/rowhouse.png"
import rumblePin from "images/xpass/icons/brands/rumble.png"
import stretchlabPin from "images/xpass/icons/brands/stretchlab.png"
import stridePin from "images/xpass/icons/brands/stride.png"
import yogasixPin from "images/xpass/icons/brands/yogasix.png"
import kinrgyPin from "images/xpass/icons/brands/kinrgy.png"
import markerPin from "images/markers/pin.png"
import closeIcon from "images/xpass/icons/close.svg"
import RewardsCard from "./RewardsCard"
import ClaimAccountModalOnBook from "./ClaimAccountModal"
import RewardsStore from "./stores/xpass/RewardsStore"

export interface HomeProps {
  store?: BrandStore
  locationSummary: LocationSummary
  eywRedirect: boolean
  brandRedirect: boolean
  subBrand?: string
  brandLocationId?: string
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class DashboardPage extends React.Component<
  HomeProps & RouteComponentProps
> {
  isXponential = this.props.store!.isXponential
  dashboardStore = new DashboardApiStore(this.props.store!)
  challengeStore = new ChallengeStore(this.props.store!)
  rewardsStore = new RewardsStore(this.props.store!.userStore)
  locationBrandsStore = new LocationBrandsStore(this.props.store!.userStore)
  bookingsStore = new BookingsStore(this.props.store!)
  balancesStore = new BalancesStore(this.props.store!)
  homePromoStore = new HomePromoStore(this.props.store!)
  userCoordinatesStore = new UserCoordinatesStore()
  scheduleEntryStore = new ScheduleEntryStore(this.userCoordinatesStore, this.props.store!.userStore)
  favoriteLocationsStore = new FavoritesStore(
    "locations",
    "locations",
    LocationSummary,
    this.props.store!.userStore
  )
  @observable bannerOpen: boolean = true
  @observable bookingFlowStore = new BookingFlowStore(this.props.store!)

  componentWillMount() {
    const userClubreadyId = this.props.store!.userStore!.session!.clubreadyId
    const eywAffiliateLink = this.props.store!.eywAffiliateLink()
    if (this.props.eywRedirect && userClubreadyId && eywAffiliateLink) {
      window.location.href = `${eywAffiliateLink}${userClubreadyId}`
    }
    const { pathname, search } = location
    saveState(LATEST_BOOKING_PATH_KEY, { pathname, search }, true)
    this.dashboardStore.fetch()
    this.challengeStore.fetch()
    this.homePromoStore.fetch()
    if (this.isXponential) {
      const { authStore } = this.props.store!
      this.locationBrandsStore.fetch()
      this.bookingsStore.fetch()
      this.balancesStore.fetch()

      // Show claim account modal if user directed to XPASS login flow from brand member portal and unclaimed brand account
      if ((authStore.brandLocationId || this.props.brandLocationId) && !authStore.brandUser) {
        setTimeout(() => {
          this.showClaimAccountModal()
        }, 1000)
      }
    }
  }

  componentWillUnmount() {
    this.dashboardStore.dispose()
    this.challengeStore.dispose()

    if (this.isXponential) {
      this.locationBrandsStore.dispose()
      this.bookingsStore.dispose()
      this.balancesStore.dispose()
    }
    if (this.bookingFlowStore) {
      this.bookingFlowStore.dispose()
    }
  }

  showClaimAccountModal = () => {
    this.props.store!.uiStore.openModal({
      children: <ClaimAccountModal {...this.props} store={this.props.store!} />,
      modalShow: true,
    })
  }

  handleBookingModal = (entry: ScheduleEntry) => {
    this.props.store!.track.event("booking_tap book",
    {
      entry,
      loc: this.props.locationSummary,
    }
  )
   if (entry.xpassEligible) {
    this.props.store!.uiStore.openModal({
      children: (
        <BookingFlowModal
          {...this.props}
          store={this.props.store}
          handleHealthCheckConfirm={this.handleHealthCheckConfirm}
          handleHealthCheckCancel={this.handleHealthCheckCancel}
          handleIsFrozen={this.handleIsFrozen}
          entry={entry}
        />
      ),
      modalShow: true,
    })
   } else if (this.isXponential && entry.xpassEligible && entry.hasValidToken && entry.userPremiumCost && entry.userPremiumCost.raw > 0) {
    this.props.history.push(`/buy/xponential-xpass/packages/${entry.targetPackageId}?schedule_entry_id=${entry.id}`)
   } else {
    this.props.store!.track.event("blocker modal_popup")
    this.props.store!.uiStore.openModal({
      children: <ClaimAccountModalOnBook 
        {...this.props} 
        store={this.props.store!} 
        UIStore={this.props.store!.uiStore}
        />,
      modalShow: true,
    })
   }
  }

  handleHealthCheckConfirm = (_event: ButtonEvent) => {
    const { scheduleEntry: entry } = this.bookingFlowStore
    const { locationSummary: loc } = this.props

    this.props.store!.track.event("health check confirm", { entry, loc })
  }

  handleHealthCheckCancel = (_event: ButtonEvent, isModal?: boolean) => {
    const { scheduleEntry: entry } = this.bookingFlowStore
    const { locationSummary: loc, location, match } = this.props
    const stillOnSchedulePage = location.pathname === match.url

    this.props.store!.track.event("health check cancel", { entry, loc })

    if (!isModal) {
      if (stillOnSchedulePage) {
        this.bookingFlowStore = new BookingFlowStore(this.props.store!)
      } else {
        this.props.history.goBack()
      }
    }
  }

  handleIsFrozen = () => {
    const { session } = this.props.store!.userStore
    const { isFrozen } = session!
    if (isFrozen) {
      this.props.store!.uiStore.seenAccountWarning = false
      return this.props.store!.uiStore.openAccountWarning(session!)
    }
  }

  getBrandIcon = (brand: string) => {
    switch (brand) {
      case "akt":
        return aktPin
      case "bft":
        return bftPin
      case "clubpilates":
        return clubpilatesPin
      case "cyclebar":
        return cyclebarPin
      case "purebarre":
        return purebarrePin
      case "rowhouse":
        return rowhousePin
      case "rumble":
        return rumblePin
      case "stretchlab":
        return stretchlabPin
      case "stride":
        return stridePin
      case "yogasix":
        return yogasixPin
      case "kinrgy":
        return kinrgyPin
      default:
        return markerPin
    }
  }

  renderDashboardCard(name: DashboardCardName): React.ReactNode {
    switch (name) {
      case "NextClassCard":
        return (
          <NextClassCard
            className="h-100"
            dashboardStore={this.dashboardStore}
            bookingsStore={this.bookingsStore}
            getBrandIcon={this.getBrandIcon}
          />
        )
      case "TodaysClassCard":
        return (
          <TodaysClassCard
            className="h-100"
            dashboardStore={this.dashboardStore}
            scheduleEntryStore={this.scheduleEntryStore}
            onBookingModal={this.handleBookingModal}
          />
        )
      case "AttendanceCard":
        return <AttendanceCard className="h-100" store={this.props.store!} />
      case "MembershipCard":
        return (
          <MembershipCard
            className="h-100"
            dashboardStore={this.dashboardStore}
          />
        )
      case "ProfileCard":
        return <ProfileCard className="h-100" />
      case "PerformanceCard":
        return <PerformanceCard className="h-100" />
      case "MobileAppCard":
        return <MobileAppCard className="h-100" />
      case "MobileOfferCard":
        return <MobileOfferCard className="h-100" />
      case "BadgeCard":
        return (
          <BadgeCard className="h-100" dashboardStore={this.dashboardStore} />
        )
      case "AchievementsCard":
        return (
          <ChallengeCenterCard
            className="h-100 challenge-center"
            store={this.props.store!}
            challengeStore={this.challengeStore}
          />
        )
      case "YogaSixFebruaryChallengeCard":
        return <YogaSixFebruaryChallengeCard className="h-100" />
      case "RewardsCard":
        return (
          <RewardsCard
            className="h-100"
            store={this.props.store!}
            rewardsStore={this.rewardsStore}
          />
        )
      case "MyStatsCard":
        return (
          <MyStatsCard
            className="h-100"
            locationBrandsStore={this.locationBrandsStore}
            store={this.props.store!}
            dashboardStore={this.dashboardStore}
            bookingsStore={this.bookingsStore}
            balancesStore={this.balancesStore}
          />
        )
      case "MyStudioCard":
        return (
          <MyStudioCard
            className="h-100"
            favoriteLocationsStore={this.favoriteLocationsStore}
            store={this.props.store!}
            getBrandIcon={this.getBrandIcon}
          />
        )
      case "ClassPointsCard":
        return <ClassPointsCard className="h-100" store={this.props.store!} dashboardStore={this.dashboardStore} />
      case "AppleHealthCard":
        return <AppleHealthCard className="h-100" store={this.props.store!} dashboardStore={this.dashboardStore} />
      case "OfferCard":
        // See the note in app/javascript/apps/dashboard/OfferCard.tsx
        return <OfferCard className="h-100" store={this.props.store!} />
      case "PromoCards":
        return (
          <PromoCards
            className="h-100"
            store={this.props.store!}
            homePromoStore={this.homePromoStore}
          />
        )
    }
  }

  closeBanner = (e: ButtonEvent) => {
    e.stopPropagation()
    this.bannerOpen = false
  }

  clickBanner = () => {
    this.props.history.push({
      pathname: `/book`,
    })
    this.props.store!.track.event("home_tap banner link")
  }

  render() {
    const {
      dashboardCards,
      locStore,
      userStore,
      copy,
      brand,
    } = this.props.store!
    const session = userStore.session
    const isComingSoon =
      locStore.currentLocation && locStore.currentLocation.comingSoon
    const filteredCards = () => {
      let cards = dashboardCards;
      if (this.challengeStore.challenges.length === 0) {
        cards = cards.filter(card => card !== "AchievementsCard")
      }
      return cards
    }
    const { freeClassesPurchased, bannerMessage } = this.balancesStore!

    if (isComingSoon) {
      return (
        <>
          <div className="row">
            <Heading center className="h1 col">
              This studio is opening soon
            </Heading>
            <PageTracker name="coming soon" />
          </div>
          <div className="row">
            <div className="col-lg-6 offset-lg-3 text-sm-center">
              <p className="mb-3 mb-md-4">{copy.comingSoonOffersDescription}</p>
              <Link className="btn btn-primary" to="/buy">
                {copy.comingSoonOffersCta}
              </Link>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          {this.isXponential && freeClassesPurchased && this.bannerOpen && bannerMessage && (
            <div className={`free-class-banner-ctn ${this.props.subBrand ? 'free-class-banner-ctn__brand' : ''}`}>
              <FreeClassBanner
                closeBanner={this.closeBanner}
                clickBanner={this.clickBanner}
                style="free-class-banner"
                bannerMessage={bannerMessage}
              />
            </div>
          )}
          <div className="row dashboard-hero justify-content-center">
            <Heading className="h1 col col-md-8 col-lg-12 text-small">
              {session ? `Welcome, ${session.firstName}` : "Hello, Stranger"}
            </Heading>
            <PageTracker name="dashboard" />
          </div>

          <AlertBanner />

          {/* https://www.codeply.com/go/k2zBsujRhJ */}

          <div className={cx("row", "justify-content-center", "cards-in")}>
            {filteredCards().map((cardName, i) => (
              cardName === "PromoCards" ? (
                <React.Fragment key={`${cardName}-${i}`}>
                  {this.renderDashboardCard(cardName)}
                </React.Fragment>
              ) : (
                <CardWrapper key={`${cardName}-${i}`}>
                  {this.renderDashboardCard(cardName)}
                </CardWrapper>
              )
            ))}
          </div>
        </>
      )
    }
  }
}

interface Props extends RouteComponentProps {
  store: BrandStore
  brandRedirect: boolean
  brandLocationId?: string
}

const ClaimAccountModal = (props: Props) => {
  const closeModal = () => {
    props.store!.uiStore.closeModal()
    props.store!.authStore.brandLocationId = undefined
  }

  const redirectToClaimAccount = () => {
    const brandLocationId = props.store!.userStore!.brandLocationId || props.brandLocationId
    const brandRedirect = props.store!.userStore!.brandRedirect || props.brandRedirect
    props.store!.uiStore.closeModal()
    props.history.push(`/account/claim?brand_location_id=${brandLocationId}&redirect=${brandRedirect}`)
    props.store!.authStore.brandLocationId = undefined
  }

  return (
    <div className="dashboard-modal text-center">
    <div
      className="dashboard-modal__close"
      onClick={() => closeModal()}
    >
      <img src={closeIcon} alt="Close modal" />
    </div>
    <h2>Studio Account Not Linked</h2>
    <p>
      This studio account is not currently linked to your XPASS Login.
    </p>
    <p>
      Do you want to claim this account, along with other accounts, using
      this email address?
    </p>
    <div className="mt-2 py-2 mt-sm-3 py-sm-3">
      <button
        className="btn btn-primary"
        onClick={() => redirectToClaimAccount()}
      >
        Claim Account &#38; Continue
      </button>
    </div>
  </div>
  )
}