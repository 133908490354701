import { observable, action } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import { Location, Details } from "apps/book/models/xpass/Location"
import APIStore from "stores/APIStore"
import ScheduleEntry from "apps/book/models/xpass/ScheduleEntry"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"

export interface LocationDetailResponse {
  location?: Location
  upcomingClasses?: ScheduleEntry[]
  details?: Details
}

export default class LocationDetailStore extends APIStore {
  @observable location?: Location
  @observable upcomingClasses?: ScheduleEntry[] = []
  @observable details?: Details

  api = this.createClient<LocationDetailResponse>([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("location", Location),
    DeserializeMiddleware("upcoming_classes", ScheduleEntry),
    DeserializeMiddleware("details", Details),
  ])

  fetch(id: string) {
    return this.api.get(`/api/xpass/v2/locations/${id}`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.location = res.data.location
    this.details = res.data.details
    this.upcomingClasses = res.data.upcoming_classes
    return res
  }
}