import * as React from "react"
import cx from "classnames"
import { InjectedFormikProps, withFormik, Form } from "formik"
import * as yup from "yup"
import { observer, inject } from "mobx-react"
import { observable } from "mobx"
import FormCheck from "react-bootstrap/FormCheck"
import { nameValidator } from "helpers/validations"
import getIn from "lodash/get"

import BrandStore from "stores/BrandStore"
import PaymentSource from "models/PaymentSource"
import PaymentSourceRequest from "apps/account/models/PaymentSourceRequest"
import PaymentSourceUpdateStore from "apps/account/stores/PaymentSourceUpdateStore"
import PaymentSourcesStore from "../stores/PaymentSourcesStore"

import FormInput from "components/forms/FormInput"
import FormSelect from "components/forms/FormSelect"
import FormButtons from "components/forms/FormButtons"
import FormAlert from "components/forms/FormAlert"
import ACHModal from "./ACHModal"

const isDefaultSource = (paymentSourcesStore?: PaymentSourcesStore) => {
  const { isDefaultSource: defaultSource, isNewSource, paymentSources } = paymentSourcesStore!
  return isNewSource ||
    (defaultSource &&
      paymentSources &&
      getIn(paymentSources[0], "type") === 'ach')
}

export interface Props {
  store?: BrandStore
  paymentSourceUpdateStore: PaymentSourceUpdateStore
  paymentSourcesStore?: PaymentSourcesStore
  paymentSource?: PaymentSource
  modalStatus?: { status: string, message?: string }
  modalOpen: boolean
  isPaymentPage: boolean
  onCancel(): void
  setModal(status: string, message?: string): void
  closeModal(): void
}

type FormValues = PaymentSourceRequest

@observer
class InnerForm extends React.Component<
  InjectedFormikProps<Props, FormValues>
> {
  @observable isDefault =
    (this.props.paymentSource && this.props.paymentSource.isDefault) || false

  handleCancel = (e: ButtonEvent) => {
    e.preventDefault()
    this.props.onCancel()
  }

  handleCheckbox = () => {
    this.isDefault = !this.isDefault
    this.props.setFieldValue("isDefault", this.isDefault)
  }

  public render() {
    const { touched, errors, isSubmitting } = this.props
    return (
      <>
        <Form
          noValidate
          className={cx({
            "col-md-10 col-lg-8 col-xl-6": !this.props.isPaymentPage,
            "col-md-11 col-xl-8": this.props.isPaymentPage,
          })}
        >
          <FormAlert message={errors.base!} />

          <div className="row">
            <FormSelect
              label="Bank Account Type"
              name="bankAccountType"
              className="col-lg-12"
            >
              <option value="pc">Personal Checking</option>
              <option value="ps">Personal Savings</option>
              <option value="bc">Business Checking</option>
            </FormSelect>
          </div>
          <div className="row">
            <FormInput
              label="Account Holder First Name"
              name="firstName"
              autoComplete="account-first-name"
              className="col-lg-6"
              maxLength={50}
            />
            <FormInput
              label="Account Holder Last Name"
              name="lastName"
              autoComplete="account-last-name"
              className="col-lg-6"
              maxLength={50}
            />
          </div>
          <div className="row">
            <FormInput
              label="Routing Number"
              placeholder="XXXXXXXXX"
              name="bankRoutingNumber"
              autoComplete="routing-number"
              className="col-lg-12"
              minLength={9}
              maxLength={9}
            />
          </div>
          <div className="row">
            <FormInput
              label="Account Number"
              placeholder="XXXXXXXXXXXXXXXXX"
              name="bankAccountNumber"
              autoComplete="account-number"
              className="col-lg-12"
              formNoValidate
              minLength={9}
              maxLength={17}
            />
          </div>
          <div className="row">
            <FormInput
              label="Re-Enter Account Number"
              placeholder="XXXXXXXXXXXXXXXXX"
              name="accountNumberConfirmation"
              autoComplete="account-number-confirmation"
              className="col-lg-12"
              formNoValidate
              minLength={9}
              maxLength={17}
            />
          </div>
          <div className="row">
            <FormSelect
              label="Country"
              name="countryCode"
              className="col-lg-12"
            >
              <option value="">Not Specified</option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="MX">Mexico</option>
            </FormSelect>
          </div>
          <div className="d-md-flex align-items-center">
            <FormButtons
              submitText="Save"
              onCancel={this.props.onCancel}
              disabled={isSubmitting}
              showCancelButton={true}
            />
            {!this.props.isPaymentPage ? (
              <div className="pl-md-4 pl-lg-5 pt-3">
                <FormCheck
                  type="checkbox"
                  label="Make Default Payment Method"
                  className="font-weight-bold"
                  checked={isDefaultSource(this.props.paymentSourcesStore!) || this.isDefault}
                  disabled={isDefaultSource(this.props.paymentSourcesStore!)}
                  onChange={this.handleCheckbox}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Form>
        {this.props.modalOpen ? (
          <ACHModal
            status={this.props.modalStatus}
            locationSummary={this.props.store!.locStore.currentLocation!}
            onCancel={this.props.onCancel}
            closeModal={this.props.closeModal}
          />
        ) : (
          <></>
        )}
      </>
    )
  }
}

const schema = yup.object<FormValues>().shape({
  isDefault: yup.boolean(),
  bankAccountType: yup
    .string()
    .required()
    .label("Bank Account Type")
    .oneOf(["pc", "ps", "bc"]),
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  bankRoutingNumber: yup
    .number()
    .required()
    .typeError("Routing number must be a 9-digit number")
    .label("Routing Number"),
  bankAccountNumber: yup
    .number()
    .required()
    .typeError("Account number must be a number")
    .test("length", "Account number must be between 9 and 17 digits", function(val) {
      return val ? val.toString().length >= 9 && val.toString().length <= 17 : false
    })
    .label("Account Number"),
  accountNumberConfirmation: yup
    .number()
    .required()
    .typeError("Account number must be a number")
    .test("match", "Account numbers much match", function() {
      return (
        this.parent.bankAccountNumber === this.parent.accountNumberConfirmation
      )
    })
    .label("Re-Enter Account Number"),
  countryCode: yup
    .string()
    .required()
    .label("Country")
    .oneOf(["US", "CA", "MX"]),
})

const ACHForm = inject((store: BrandStore) => ({ store }))(
  observer(
    withFormik<Props & { store?: BrandStore }, FormValues>({
      mapPropsToValues: ({ store, paymentSource, paymentSourcesStore }) => {
        const source = paymentSource
        const user = store!.userStore.session!

        return {
          isDefault: getIn(source, ["isDefault"], false) || isDefaultSource(paymentSourcesStore!) || false,
          bankAccountType: getIn(source, ["bankAccountType"], "pc") || "pc",
          firstName: getIn(source, ["firstName"], user.firstName || ""),
          lastName: getIn(source, ["lastName"], user.lastName || ""),
          bankRoutingNumber: getIn(source, ["bankRoutingNumber"], "") || "",
          bankAccountNumber: getIn(source, ["bankAccountNumber"], "") || "",
          accountNumberConfirmation: "",
          countryCode: getIn(source, ["countryCode"], "") || "",
          type: "ach",
        }
      },
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: schema,
      handleSubmit: (values, formikBag) => {
        const {
          paymentSourceUpdateStore,
          store,
          setModal,
          onCancel,
        } = formikBag.props
        paymentSourceUpdateStore
          .update(values)
          .then(res => {
            formikBag.setSubmitting(false)
            if (res.data.errors) {
              if (res.data.errors.bankRoutingNumber) {
                setModal("failure", res.data.error_messages[0])
              } else {
                setModal("failure")
              }
              formikBag.setErrors(res.data.errors)
              store!.track.event("add new bank account failure")
            } else {
              if (values.isDefault === true) {
                setModal("success")
              } else {
                onCancel()
              }
              store!.track.event("add new bank account success")
            }
            return res
          })
          .catch(ex => {
            formikBag.setSubmitting(false)
            formikBag.props.store!.uiStore.openError(ex)
            store!.track.event("add new bank account failure")
            throw ex
          })
        store!.track.event("purchase_add new credit card")
      },
    })(InnerForm)
  )
)

export default ACHForm
