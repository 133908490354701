import * as React from "react"
import { observer } from "mobx-react"
import Dropdown from "react-bootstrap/Dropdown"

import Spinner from "components/Spinner"
import eatBsPrefix from "helpers/eatBsPrefix"
import Svg from "components/Svg"

// actions dropdown menu used in schedule and history tables

interface Props {
  name: string
  id: string
  isBusy?: boolean
  disabledWith?: string
}

const TableMenu: React.SFC<Props> = observer(
  ({ name, isBusy, disabledWith, id, children }) => {
    if (isBusy) {
      return <Spinner size={2} />
    }

    if (disabledWith) {
      return <span className="h4 mb-0">{disabledWith}</span>
    }

    return (
      <Dropdown>
        <Dropdown.Toggle
          as={eatBsPrefix("div")}
          id={id}
          className="table-menu-toggle"
        >
          {name}
          <Svg
            className="ml-2"
            name={false ? "caret-up" : "caret-down"}
            size="12"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="border-0 shadow-sm dropdown-menu"
          // this doesn't help - z indexes on the dropdowns get messed up when
          // applying animation to the table rows
          style={{ zIndex: 5 }}
        >
          {children}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
)

export default TableMenu
