import { JsonObject, JsonProperty } from "json2typescript"
import Price from "models/Price"

@JsonObject("Invoice")
export default class Invoice {
  /**
   * ClubReady's invoice ID
   */
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * Name of the item on the invoice.
   */
  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * Status from ClubReady. Need to figure out what this means, if there are
   * more, and whether some of them shouldn't be user-facing.
   */
  @JsonProperty("status", String)
  status: "err" | "regular" | "collect" | "cancel" | "other" = undefined!

  @JsonProperty("amount_due", Price)
  amountDue: Price = undefined!

  @JsonProperty("sales_tax_due", Price)
  salesTaxDue: Price = undefined!

  @JsonProperty("due_at", String)
  dueAt: string = undefined!

  /**
   * Unclear if this means that they already paid?
   */
  @JsonProperty("paid_at", String, true)
  paidAt?: string = undefined!

  get total(): Price {
    return Price.fromCents(
      this.amountDue.cents + this.salesTaxDue.cents,
      this.amountDue.currencyCode
    )
  }
}
