import * as React from "react"

interface WarningMessageProps {
  message?: string
}

const WarningMessage: React.FunctionComponent<WarningMessageProps> = ({
  message,
}) => {
  return (
    <>
    {message &&
        <h5 className="alert alert-warning text-center">{message}</h5>
    }
    </>
  )
}

export default WarningMessage
