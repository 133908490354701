import axios, {
  AxiosRequestConfig,
  AxiosPromise,
  CancelToken,
  Canceler,
  AxiosResponse,
  AxiosError,
} from "axios"

import AxiosProxy from "services/AxiosProxy"
import { Middleware } from "services/Middleware"
import { compose } from "throwback"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import ApiClientBreadcrumbs from "services/APIClientBreadcrumbs"

export default class APIClient<T = any> extends AxiosProxy<T> {
  currentRequest?: AxiosPromise<T>
  context: { class?: string } = {}

  private _lastRequest?: AxiosPromise<T>
  private breadCrumbs = new ApiClientBreadcrumbs()

  get lastRequest() {
    return this.currentRequest || this._lastRequest
  }

  private canceler?: Canceler

  constructor(
    public middleware: Middleware[] = [],
    public config: AxiosRequestConfig = {}
  ) {
    super()
    this.middleware.push(
      ResponseMiddleware(this.handleSuccess, this.handleError)
    )
  }

  request(config: AxiosRequestConfig) {
    // don't run two requests at once. first one wins.
    // if need to run two at once, second one needs to take responsibility for
    // cancelling the first one.
    if (this.currentRequest) return this.currentRequest
    // alternatively could cancel it and let the second one win
    // if (this.currentRequest) this.cancel()

    const stack = compose(this.middleware)
    config = { ...this.config, ...config }
    this.currentRequest = stack(config, ctx => {
      this.breadCrumbs.addRequestBreadcrumb(ctx, this.context)

      return super.request({
        ...ctx,
        cancelToken: new axios.CancelToken(this.setCanceler),
      })
    }) as AxiosPromise<T>
    return this.currentRequest
  }

  cancel() {
    if (this.canceler) {
      // this behavior might not be right. might make more sense to check
      // for cancels in the error handle and return currentRequest if req
      // failed b/c of cancel
      // FE is hard
      this.canceler("cancelled")
      this._lastRequest = this.currentRequest
      this.currentRequest = undefined
    }
  }

  handleSuccess = (res: AxiosResponse<unknown>) => {
    this.breadCrumbs.addResponseBreadcrumb(true, res, this.context)
    this._lastRequest = this.currentRequest
    this.currentRequest = undefined
    return res
  }

  handleError = (ex: AxiosError) => {
    if (ex.response) {
      this.breadCrumbs.addResponseBreadcrumb(false, ex.response, this.context)
    }

    this._lastRequest = this.currentRequest
    this.currentRequest = undefined
    throw ex
  }

  private setCanceler = (c: Canceler) => {
    this.canceler = c
  }
}
