import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("Activity")
export default class Activity {
  @JsonProperty("id", Number)
  id: number = undefined!

  @JsonProperty("type", String)
  type: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("short_description", String)
  shortDescription: string = undefined!

  @JsonProperty("description", String, true)
  description: string = undefined!

  @JsonProperty("rewards_text", String, true)
  rewardsText?: string = undefined!

  @JsonProperty("thumbnail_image_url", String)
  thumbnailImageUrl: string = undefined!

  @JsonProperty("earned_at_date", String)
  earnedAtDate?: string = undefined!
}

@JsonObject("Pagination")
export class Pagination {
  @JsonProperty("current_page", Number, true)
  currentPage?: number = undefined!

  @JsonProperty("next_page", Number, true)
  nextPage?: number = undefined!

  @JsonProperty("per_page", Number, true)
  perPage?: number = undefined!

  @JsonProperty("prev_page", Number, true)
  prevPage?: number = undefined!

  @JsonProperty("total_count", Number, true)
  totalCount?: number = undefined!

  @JsonProperty("total_pages", Number, true)
  totalPages?: number = undefined!
}

@JsonObject("ActivityMeta")
export class ActivityMeta {
  @JsonProperty("pagination", Pagination, true)
  pagination?: Pagination = undefined!
}
