import { JsonObject, JsonProperty } from "json2typescript"

// A very basic API object

@JsonObject("GenericSummary")
export default class GenericSummary {
  @JsonProperty("id", String, true)
  id?: string = undefined

  @JsonProperty("name", String)
  name: string = undefined!
}
