import {
  BrandData,
  BrandCopy,
  BrandStyleClasses,
  baseStyleClasses,
  baseSettings,
  baseStyles,
  baseCopy,
  baseDashboardCards,
  BadgeSvg,
  BadgeBitmap,
  BrandDashboardCards,
} from "themes/base"

import logoUrl from "images/logos/xpass-logo-dark.svg"
import numberFormatter, { formatNumber } from "helpers/numberFormatter"
import { Metric } from "apps/history/models/Stats"
import videoLandingLogo from "images/video/purebarre-go-logo.png"
import brandIcon from "images/classpoints/brands/Y6.svg"

import bannerImage from "images/dashboard/xpass_banner_bg_2.png"
import badgeImage from "images/badges/akt-badge.svg"
import badge1Active from "images/badges/akt/milestone-0-Active-AKT.png"
import badge50Active from "images/badges/akt/milestone-1-Active-AKT.png"
import badge250Active from "images/badges/akt/milestone-2-Active-AKT.png"
import badge500Active from "images/badges/akt/milestone-3-Active-AKT.png"

import badge1Inactive from "images/badges/akt/milestone-0-Inactive-AKT.png"
import badge50Inactive from "images/badges/akt/milestone-1-Inactive-AKT.png"
import badge250Inactive from "images/badges/akt/milestone-2-Inactive-AKT.png"
import badge500Inactive from "images/badges/akt/milestone-3-Inactive-AKT.png"

const badges: BadgeBitmap[] = [
  {
    label: "First Class",
    value: 50000,
    active: badge1Active,
    inactive: badge1Inactive,
  },
  {
    label: "50 Classes",
    value: 250000,
    active: badge50Active,
    inactive: badge50Inactive,
  },
  {
    label: "250 Classes",
    value: 500000,
    active: badge250Active,
    inactive: badge250Inactive,
  },
  {
    label: "500 Classes",
    value: 1000000,
    active: badge500Active,
    inactive: badge500Inactive,
  },
]

const dashboardCards: BrandDashboardCards = [
  "MyStatsCard",
  "NextClassCard",
  "TodaysClassCard",
  "RewardsCard",
  "MyStudioCard",
  "MobileAppCard",
  "MobileOfferCard",
  "PromoCards",
]

const metrics: Metric[] = [
  {
    key: "avgHr",
    label: "Average Heart Rate",
    logo: "perf-distance",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "avgHrPercent",
    label: "Average HR %",
    logo: "perf-distance",
    logoSize: 30,
    format: numberFormatter({ suffix: "%" }),
  },
  {
    key: "highHr",
    label: "Max Heart Rate",
    logo: "perf-distance",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "highHrPercent",
    label: "Max HR %",
    logo: "perf-distance",
    logoSize: 30,
    format: numberFormatter({ suffix: "%" }),
  },
  {
    key: "iqPoints",
    label: "Points",
    logo: "perf-distance",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "calories",
    label: "Calories",
    logo: "perf-distance",
    logoSize: 30,
    format: formatNumber,
  },
]

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
  BookingInfoCard__container: "my-5 container",
  BookingInfoCard__subtitle: "text-sm pt-1 pb-3",
  ConfirmBookingPage__confirmButton: "confirm-action",
  HealthCheck__card: "",
  HealthCheck__cardBody: "text-center",
  MainMenu__container: "bg-light",
  PackageCard__card: "xpass-package-card",
  PackageCard__cardText: "d-block package-text",
  PackageCard__cardTextPackageName: "d-block",
  PackagesList__memberships: "mb-5",
  TodaysClassCard__favoriteHeartWrapper: "d-flex align-items-center mr-3",
}

const copy: BrandCopy = {
  ...baseCopy,
  activity: "History",
  addOns: "Add-On Points",
  tokens: "Tokens",
  bookingErrorTitle: "Tokens Needed",
  bookingPageLabel: "Book a Class",
  classPackages: "No enrollment fees, no brand restrictions, and cancel unused plans within 5 days of becoming a member for any reason.",
  credit: "token",
  credits: "tokens",
  dashCardBrands: "Brands Visited",
  dashCardMembership: "My Membership",
  dashCardRewards: "My Rewards",
  groupClasses: "Group Stretches",
  mainMenuClasses: "Buy Tokens",
  memberships: "Plans",
  nextClassHeader: "",
  oneOnOneSessions: "One-On-One Stretches",
  print: "Download",
  purchases: "Plans",
  myBest: "Top",
  seat: "Spot",
  seats: "Spots",
  titleProfileInfo: "Personal Information",
  serviceInstructor: "Flexologist",
  noUpcomingClassesBooked:
    "It looks like you haven't booked any upcoming classes. Let's keep this fitness thing going!",
}

const brand: BrandData = {
  settings: {
    ...baseSettings,
    billingHistoryEnabled: true,
    isPerformance: true,
    serviceBooking: true,
    showAddToCalendarButton: true,
    disallowACHPayment: true,
  },
  styles: {
    ...baseStyles,
    chartColor: "#60259e",
    logoUrl,
    badgeImage,
    bannerImage,
    videoLandingLogo,
    brandIcon
  },
  styleClasses,
  copy,
  dashboardCards,
  badges,
  metrics,
  summaryMetrics: [],
}

export default brand
