import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("UserClaim")
export default class UserClaim {
  @JsonProperty("id", Number)
  id: number = undefined!

  @JsonProperty("email", String)
  email: string = undefined!

  @JsonProperty("location_id", String)
  locationId: string = undefined!

  @JsonProperty("location_name", String)
  locationName: string = undefined!

  @JsonProperty("brand_name", String)
  brandName: string = undefined!

  @JsonProperty("brand_slug", String)
  brandSlug: string = undefined!
}
