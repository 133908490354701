import { JsonObject, JsonProperty } from "json2typescript"
import ClassCategorySummary from "models/ClassCategorySummary"

export type OfferSlot =
  | "first_timer"
  | "promo_bar"
  | "promo_banner"
  | "promo_bar2"
  | "promo_bar3"
  | "promo_bar4"
  | "promo_bar5"
  | "promo_bar6"
  | "promo_bar7"
  | "promo_bar8"
  | "promo_bar9"

@JsonObject("Offer")
export default class Offer {
  /**
   * ID of the offer (unique per brand.)
   */
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * ID of the package associated with the offer, if any.
   */
  @JsonProperty("package_id", String, true)
  packageId?: string = undefined

  /**
   * The offer's user-facing name, as displayed on offer pages and forms.
   */
  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * A call to action for the offer, e.g. "Book Now." Should be short enough to fit in a small button.
   */
  @JsonProperty("cta", String)
  cta: string = undefined!

  /**
   * A short tagline for the offer, e.g. "Receive 10% off of your first class!" Optional.
   */
  @JsonProperty("tagline", String, true)
  tagline?: string = undefined!

  /**
   * A 1-2 sentence user-facing description of the offer.
   */
  @JsonProperty("description", String, true)
  description?: string = undefined

  /**
   * A link to buy page for the offer in the portal, if it's a package offer.
   */
  @JsonProperty("offer_url", String, true)
  offerUrl?: string = undefined

  /**
   * ID of the location this offer is associated with.
   */
  @JsonProperty("location_id", String)
  locationId: string = undefined!

  /**
   * Which page slot the offer appears in for this location.
   */
  @JsonProperty("location_id", String)
  slot: OfferSlot = undefined!

  /**
   * The class category associated with the offer, if any.
   */
  @JsonProperty("class_category", ClassCategorySummary, true)
  classCategory?: ClassCategorySummary = undefined
}
