import React, { Component, useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"
import { Form, withFormik, InjectedFormikProps } from "formik"
import { observable } from "mobx"
import { observer } from "mobx-react"
import * as yup from "yup"

import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import UserCoordinatesStore from "apps/book/stores/xpass/UserCoordinatesStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"
import UserClaimsStore from "apps/account/stores/xpass/UserClaimsStore"
import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"
import FormAlert from "components/forms/FormAlert"
import UserLookup, { Users } from "apps/auth/models/xpass/UserLookup"
import closeImg from "images/xpass/icons/close.svg"

export interface Props {
  store: BrandStore
  userAuthStore: UserAuthStore
  scheduleEntryStore?: ScheduleEntryStore
  userClaimsStore?: UserClaimsStore
  handleClick: Function
  claimStatus?: string
}

// ===========================
// Claim Accounts Status
// ===========================
const ClaimStatus = (props: Props) => {
  const claimSuccess = props.claimStatus === "claim_success"
  const slugBrandName = {
    'akt': 'AKT',
    'bft': 'BFT',
    'clubpilates': 'Club Pilates',
    'cyclebar': 'Cycle Bar',
    'purebarre': 'Pure Barre',
    'rowhouse': 'Row House',
    'rumble': 'Rumble',
    'stretchlab': 'StretchLab',
    'stride': 'Stride',
    'yogasix': 'YogaSix',
    'kinrgy': 'KINRGY',
  }

  // For when user is directed to claim accounts page from dashboard and claims brand location account
  const { brandLocationId, allowRedirect, brandRedirectDomain } = props.userAuthStore
  const brandLocationClaimed = brandLocationId && props.userClaimsStore && props.userClaimsStore.userClaims.find(account => account.locationId === brandLocationId)
  const brandName = brandLocationId && slugBrandName[brandLocationId!.split("-")[0]]

  const handleClick = () => {
    if (brandLocationClaimed && allowRedirect && brandRedirectDomain && props.userClaimsStore ) {
      // Redirect user to brand member portal
      const accessToken = props.userClaimsStore.brandUser && props.userClaimsStore.brandUser.accessToken
      window.location.href = `${brandRedirectDomain}/brand-sign-in?access_token=${accessToken}`
    } else {
      location.reload()
    }
  }

  const title = claimSuccess
    ? brandLocationClaimed
      ? "Account(s) Linked"
      : "Success"
    : "Error"
  const message = claimSuccess
    ? brandLocationClaimed
      ? `Your accounts have been successfully linked. You can now login to any of your linked ${brandName} studios using your XPASS Login.`
      : "Your accounts have been claimed!"
    : "There was an error claiming your accounts. Please try again."
  const buttonText = brandLocationClaimed && allowRedirect ? `Return to ${brandName}` : "Okay"

  return (
    <div className="claim-account__modal text-center my-4">
      <div className="mb-4">
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
      <button className="btn btn-primary d-block mx-auto" onClick={handleClick}>
        {buttonText}
      </button>

      {brandLocationClaimed && allowRedirect && (
        <div className="mt-3">
          <span className="link light-link" onClick={() => location.reload()}>
            Stay in XPASS
          </span>
        </div>
      )}
    </div>
  )
}

// ===========================
// Claim Accounts
// ===========================
const ClaimAccount = (props: Props) => {
  const { userAccounts, verifiedAccounts } = props.userAuthStore
  const count = userAccounts && userAccounts.count
  const matchedAccounts = userAccounts && userAccounts.users
  const [hasVerifiedAccounts, setHasVerifiedAccounts] = useState(false)
  const [checkedAccounts, setCheckedAccounts] = useState(verifiedAccounts)
  const [hasCheckedAccounts, setHasCheckedAccounts] = useState(true)

  useEffect(() => {
    const { activeEmail, verifiedEmails } = props.userAuthStore
    if (verifiedEmails.some((email: string) => email === activeEmail)) {
      setHasVerifiedAccounts(true)
    }
  })

  const handleCheckbox = (e: any, account: Users) => {
    const checked = [...checkedAccounts]
    if (e.target.checked) {
      if (!hasCheckedAccounts) {
        setHasCheckedAccounts(true)
      }
      checked.push(account)
    } else {
      const index = checked.findIndex(item => item.id === account.id)
      checked.splice(index, 1)
    }
    setCheckedAccounts(checked)
  }

  const handleVerify = () => {
    const email = props.userAuthStore.activeEmail
    props.userAuthStore.verifyEmail(email)
    props.handleClick("verify_email")
  }

  const handleClaim = () => {
    const accounts = checkedAccounts.map(account => account.id)
    const { brandLocationId } = props.userAuthStore

    if (props.userClaimsStore) {
      if (accounts.length > 0) {
        props.userClaimsStore.create(accounts, brandLocationId!).then(res => {
          if (res.status === 200) {
            props.handleClick("claim_success")
          } else {
            props.handleClick("claim_failure")
          }
        })
      } else {
        setHasCheckedAccounts(false)
      }
    }
  }

  // Matching Accounts
  let title = "Matching Accounts Found"
  let copy = (
    <>
      <p className="my-0 mx-4">
        We found{" "}
        <span className="bold">
          {count === 1 ? `${count} studio account` : `${count} studio accounts`}{" "}
        </span>
        associated with this email address.
      </p>
      <p className="my-0 mx-4">Please verify your email address to continue.</p>
    </>
  )
  let accountsList = matchedAccounts

  // Claim Accounts
  if (hasVerifiedAccounts) {
    title = "Claim Accounts"
    copy = (
      <p className="my-0 mx-4">
        Check or uncheck the accounts you would like to claim and combine into
        your XPASS account.
      </p>
    )
    accountsList = props.userAuthStore.verifiedAccounts!
  }

  return (
    <div className="claim-account text-center my-4 pb-0">
      <h2 className="mb-3">{title}</h2>
      <div>{copy}</div>

      <div className="claim-account__list mx-0 mx-md-3">
        {accountsList &&
          accountsList.map((account: Users, index: number) => {
            return (
              <div
                className="claim-account__list-item d-flex px-3 px-md-4"
                key={index}
              >
                {props.scheduleEntryStore && (
                  <div>
                    <img
                      className="w-100"
                      src={props.scheduleEntryStore.getBrandIcon(
                        account.brandSlug
                      )}
                      alt={`${account.brandSlug}-icon`}
                    />
                  </div>
                )}

                <div className="text-left ml-3 ml-md-4 pr-5 pr-md-0 mr-5">
                  <h3 className="text-auto">
                    {account.brandName} {account.locationName}
                  </h3>
                  <p className="m-0">{account.email}</p>
                </div>

                {hasVerifiedAccounts && (
                  <div className="claim-account__checkbox">
                    <input
                      type="checkbox"
                      className={`claim-account__checkbox-item ${
                        checkedAccounts!.some(
                          (item: Users) => item.id === account.id
                        )
                          ? "checked"
                          : ""
                      }`}
                      checked={checkedAccounts!.some(
                        (item: Users) => item.id === account.id
                      )}
                      onChange={e => handleCheckbox(e, account)}
                    />
                    <div className="checkbox" />
                  </div>
                )}
              </div>
            )
          })}

        {!hasCheckedAccounts && <p className="alert alert-danger">No accounts have been selected</p>}
      </div>

      <button
        className="btn btn-primary d-block mx-auto mt-4"
        onClick={hasVerifiedAccounts ? handleClaim : handleVerify}
      >
        {hasVerifiedAccounts ? "Claim Accounts" : "Send Verification Email"}
      </button>
    </div>
  )
}

// ===========================
// Verify Accounts
// ===========================
class VerifyAccountFormInner extends Component<
  InjectedFormikProps<Props, { verificationCode: string }>
> {
  handleResend = () => {
    const email = this.props.userAuthStore.activeEmail
    this.props.userAuthStore.verifyEmail(email)
  }

  render() {
    const { errors, isSubmitting } = this.props
    const base = "base"

    return (
      <div className="my-4">
        <h2 className="text-center">Verification Code</h2>
        <p className="text-center my-0 mx-4">
          We sent a 6-digit code to the email you provided. Enter it here to
          verify your account.
        </p>

        <Form noValidate className="mt-4 pt-2 col-10 mx-auto">
          <FormAlert message={errors[base]} classNames="my-2" />
          <FormInput
            className="mb-1"
            placeholder="Enter code"
            name="verificationCode"
            type=""
          />
          <FormButtons
            submitText="Verify Account"
            disabled={isSubmitting}
            centered
            className="float-in delay-4 pt-1 d-flex justify-content-center"
          />
          <div className="text-center mt-3">
            <small>
              <span className="link light-link" onClick={this.handleResend}>
                Resend Code
              </span>
            </small>
          </div>
        </Form>
      </div>
    )
  }
}

const VerifyAccountForm = withFormik<Props, { verificationCode: string }>({
  mapPropsToValues: () => ({
    verificationCode: "",
  }),
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: yup.object<{ verificationCode: string }>().shape({
    verificationCode: yup
      .string()
      .required()
      .min(6, "Verification code must be 6 characters.")
      .max(6, "Verification code must be 6 characters.")
      .label("Verification Code"),
  }),
  handleSubmit: (values, formikBag) => {
    const { locStore, track } = formikBag.props.store!
    const loc = locStore.currentLocation

    formikBag.props.userAuthStore
      .verifyCode(values.verificationCode, formikBag.props.userAuthStore.activeEmail)
      .then(res => {
        if (res.success) {
          const { activeEmail } = formikBag.props.userAuthStore
          const matchedAccounts = formikBag.props.userAuthStore.userAccounts!
          .users

          formikBag.props.userAuthStore.setVerifiedEmails([activeEmail])
          formikBag.props.userAuthStore.setVerifiedAccounts(matchedAccounts)
          formikBag.props.handleClick("verify_success")

          track.event(
            "verification_success",
            { loc },
            { cta: "verify account" }
          )
        } else {
          const message = `${res.error}. Please try again.`
          formikBag.setSubmitting(false)
          formikBag.setFieldError("base", message)
          track.event(
            "verification_failure",
            { loc },
            { cta: "verify account" }
          )
        }
      })
      .catch(err => {
        formikBag.setSubmitting(false)
        if (err.response && err.response.data) {
          formikBag.setFieldError("base", err.response.data.message)
        }
        formikBag.setFieldError(
          "base",
          "Invalid verification code. Please try again."
        )
        track.event("verification_failure", { loc }, { cta: "verify account" })
      })
  },
})(VerifyAccountFormInner)

// ===========================
// Look Up Accounts
// ===========================
class UserLookupFormInner extends Component<
  InjectedFormikProps<Props, { email: string }>
> {
  render() {
    const { touched, errors, isSubmitting } = this.props
    const base = "base"

    return (
      <div className="my-4">
        <h2 className="text-center mb-2">Look Up Accounts</h2>
        <p className="text-center">Enter an email below to verify.</p>
        <Form noValidate className="mt-4 pt-2 col-10 mx-auto">
          <FormAlert message={errors[base]} classNames="my-2" />
          <FormInput placeholder="Email address" name="email" type="email" maxLength={100} />
          <FormButtons
            submitText="Look Up"
            disabled={isSubmitting}
            centered
            className="float-in delay-4 pt-1 d-flex justify-content-center"
          />
        </Form>
      </div>
    )
  }
}

const UserLookupForm = withFormik<Props, { email: string }>({
  mapPropsToValues: () => ({
    email: "",
  }),
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: yup.object<{ email: string }>().shape({
    email: yup
      .string()
      .required()
      .label("Email Address")
      .email(),
  }),
  handleSubmit: (values, formikBag) => {
    formikBag.props.userAuthStore.setActiveEmail(values.email)
    formikBag.props.userAuthStore
      .search(values.email)
      .then(res => {
        if (res.status === 200) {
          if (res.data.user_lookups.count > 0) {
            formikBag.props.handleClick("valid_email")
          } else {
            formikBag.setSubmitting(false)
            const message =
              "No matching or unclaimed accounts found under this email"
            formikBag.setFieldError("base", message)
          }
        } else {
          formikBag.setSubmitting(false)
          const message = "There was an error. Please try again."
          formikBag.setFieldError("base", message)
        }
      })
      .catch(err => {
        formikBag.setSubmitting(false)
        const message = "There was an error. Please try again."
        formikBag.setFieldError("base", message)
      })
  },
})(UserLookupFormInner)

// ===========================
// Claim Accounts Modal
// ===========================
export interface ClaimAccountsModalProps {
  store: BrandStore
  userAuthStore: UserAuthStore
  scheduleEntryStore: ScheduleEntryStore
  userClaimsStore: UserClaimsStore
}

@observer
export class ClaimAccountsModal extends Component<ClaimAccountsModalProps> {
  @observable status: string = ""

  handleClick = (status: string) => {
    this.status = status
  }

  handleClose = () => {
    this.props.store!.uiStore.closeModal()
  }

  handleProgress = () => {
    switch (this.status) {
      case "":
        return (
          <UserLookupForm
            store={this.props.store}
            userAuthStore={this.props.userAuthStore}
            handleClick={this.handleClick}
          />
        )
      case "valid_email":
      case "verify_success":
        return (
          <ClaimAccount
            store={this.props.store}
            userAuthStore={this.props.userAuthStore}
            scheduleEntryStore={this.props.scheduleEntryStore}
            userClaimsStore={this.props.userClaimsStore}
            handleClick={this.handleClick}
          />
        )
      case "verify_email":
        return (
          <VerifyAccountForm
            store={this.props.store}
            userAuthStore={this.props.userAuthStore}
            handleClick={this.handleClick}
          />
        )
      case "claim_success":
      case "claim_failure":
        return (
          <ClaimStatus
            store={this.props.store}
            userAuthStore={this.props.userAuthStore}
            userClaimsStore={this.props.userClaimsStore}
            handleClick={this.handleClick}
            claimStatus={this.status}
          />
        )
      default:
        return <></>
    }
  }

  render() {
    return (
      <div>
        <div
          className="claim-account__modal-close"
          onClick={() => {
            this.handleClose()
          }}
        >
          <img src={closeImg} alt="Close filters" />
        </div>
        {this.handleProgress()}
      </div>
    )
  }
}

// ===========================
// Claim Accounts Page
// ===========================
export interface ClaimAccountsPageProps extends RouteComponentProps {
  store: BrandStore
}

export default class ClaimAccountsPage extends Component<
  ClaimAccountsPageProps
> {
  userCoordinatesStore = new UserCoordinatesStore()
  scheduleEntryStore = new ScheduleEntryStore(this.userCoordinatesStore, this.props.store.userStore)
  userAuthStore = new UserAuthStore()
  userClaimsStore = new UserClaimsStore(this.props.store!)
  @observable initialClaimableAccounts?: UserLookup

  componentDidMount() {
    const { email } = this.props.store!.userStore.session!
    this.userAuthStore.search(email)
    this.userAuthStore.setInitialEmail(email)

    // Show accounts under XPASS account email
    this.userAuthStore.matchedInitialAccounts(this.props.store!.userStore.session!.email).then(res => {
      this.initialClaimableAccounts = res.user_lookups
    })
  }

  componentWillUnmount() {
    this.userAuthStore.dispose()
  }

  handleClaim = () => {
    this.props.store!.uiStore.openModal({
      children: (
        <ClaimAccountsModal
          store={this.props.store}
          userAuthStore={this.userAuthStore}
          scheduleEntryStore={this.scheduleEntryStore}
          userClaimsStore={this.userClaimsStore}
        />
      ),
      modalShow: true,
    })
  }

  render() {
    return (
      <div className="col">
        <h1 className="my-2 slide-in">Manage Accounts</h1>
        <APILoader
          apiStore={this.userClaimsStore}
          render={() => {
            const { userClaims } = this.userClaimsStore

            return (
              <div className="claim-account">
                {userClaims.length > 0 ? (
                  <>
                    <p className="my-4">
                      There {userClaims.length === 1 ? "is" : "are"}{" "}
                      {userClaims.length} studio{" "}
                      {userClaims.length === 1 ? "account" : "accounts"}{" "}
                      associated with your email address.
                    </p>
                    {userClaims.map((account, index) => {
                      return (
                        <div
                          className="claim-account__list-item d-flex"
                          key={index}
                        >
                          <div>
                            <img
                              className="w-100"
                              src={this.scheduleEntryStore.getBrandIcon(
                                account.brandSlug
                              )}
                              alt={`${account.brandSlug}-icon`}
                            />
                          </div>
                          <div className="text-left ml-4">
                            <h3 className="text-auto">
                              {account.brandName} {account.locationName}
                            </h3>
                            <p className="m-0">{account.email}</p>
                          </div>
                        </div>
                      )
                    })}
                  </>
                ) : this.initialClaimableAccounts && this.initialClaimableAccounts.count > 0 ? (
                  <>
                    <p className="my-4">
                      You do not have any studio accounts associated. Please
                      claim the accounts below.
                    </p>
                    {this.initialClaimableAccounts.users.map((account, index) => {
                      return (
                        <div
                          className="claim-account__list-item d-flex"
                          key={index}
                        >
                          <div>
                            <img
                              className="w-100"
                              src={this.scheduleEntryStore.getBrandIcon(
                                account.brandSlug
                              )}
                              alt={`${account.brandSlug}-icon`}
                            />
                          </div>
                          <div className="text-left ml-4">
                            <h3 className="text-auto">
                              {account.brandName} {account.locationName}
                            </h3>
                            <p className="m-0">{account.email}</p>
                          </div>
                        </div>
                      )
                    })}
                  </>
                ) : (
                  <p className="my-4">
                    You do not have any studio accounts associated.
                  </p>
                )}

                <button
                  className="btn btn-primary d-block mt-4"
                  onClick={this.handleClaim}
                >
                  Claim Accounts
                </button>
              </div>
            )
          }}
        />
      </div>
    )
  }
}
