import * as React from "react"

const progressBasic = (color = 'white') => {
  return (
    <svg width="291" height="143" viewBox="0 0 291 143" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.1334 23.2666C39.7769 43.9657 20.8639 75.2369 16.4746 111H18.4901C22.8575 75.9759 41.375 45.3463 68.1413 25.0124L67.1334 23.2666Z" fill={color} fillOpacity="0.25"/>
      <path d="M16.5 142C24.7843 142 31.5 135.284 31.5 127C31.5 118.716 24.7843 112 16.5 112C8.21573 112 1.5 118.716 1.5 127C1.5 135.284 8.21573 142 16.5 142Z" stroke={color} strokeWidth="2"/>
      {color !== 'white' && (<path d="M10.4405 127.466L15.0628 131.583C15.2333 131.737 15.4481 131.811 15.6618 131.811C15.9042 131.811 16.1479 131.714 16.3243 131.521L23.4697 123.708C23.627 123.537 23.705 123.32 23.705 123.106C23.705 122.613 23.3077 122.209 22.8072 122.209C22.5623 122.209 22.321 122.307 22.1422 122.501L15.597 129.657L11.6384 126.13C11.4655 125.978 11.2531 125.902 11.0394 125.902C10.5413 125.902 10.1416 126.304 10.1416 126.798C10.1416 127.044 10.2424 127.289 10.4405 127.466Z" fill={color}/>)}
      <path d="M16.5006 115C23.1227 115 28.5 120.377 28.5 126.999C28.5 133.623 23.1227 139 16.5006 139C9.87734 139 4.5 133.623 4.5 126.999C4.5 120.377 9.87734 115 16.5006 115ZM10.4403 127.466L15.0626 131.583C15.2331 131.737 15.4479 131.811 15.6616 131.811C15.904 131.811 16.1477 131.714 16.3242 131.521L23.4695 123.708C23.6268 123.537 23.7048 123.32 23.7048 123.106C23.7048 122.614 23.3075 122.209 22.807 122.209C22.5621 122.209 22.3209 122.307 22.142 122.501L15.5968 129.657L11.6382 126.13C11.4653 125.978 11.2529 125.902 11.0392 125.902C10.5411 125.902 10.1414 126.304 10.1414 126.798C10.1414 127.044 10.2422 127.289 10.4403 127.466Z" fill="white"/>
      <path d="M272.51 111C268.143 75.9754 249.624 45.3453 222.857 25.0114L223.865 23.2656C251.223 43.9647 270.136 75.2363 274.525 111H272.51Z" fill={color} fillOpacity="0.25"/>
      <path d="M195.157 11.9888C179.887 5.55588 163.109 2 145.499 2C127.889 2 111.11 5.55619 95.84 11.9896L94.832 10.2437C110.402 3.6479 127.525 0 145.499 0C163.473 0 180.595 3.64758 196.165 10.2429L195.157 11.9888Z" fill={color} fillOpacity="0.25"/>
      <path d="M81.5 31C89.7843 31 96.5 24.2843 96.5 16C96.5 7.71573 89.7843 1 81.5 1C73.2157 1 66.5 7.71573 66.5 16C66.5 24.2843 73.2157 31 81.5 31Z" stroke={color} strokeOpacity="0.25" strokeWidth="2"/>
      <path d="M209.5 31C217.784 31 224.5 24.2843 224.5 16C224.5 7.71573 217.784 1 209.5 1C201.216 1 194.5 7.71573 194.5 16C194.5 24.2843 201.216 31 209.5 31Z" stroke={color} strokeOpacity="0.25" strokeWidth="2"/>
      <path d="M274.5 142C282.784 142 289.5 135.284 289.5 127C289.5 118.716 282.784 112 274.5 112C266.216 112 259.5 118.716 259.5 127C259.5 135.284 266.216 142 274.5 142Z" stroke={color} strokeOpacity="0.25" strokeWidth="2"/>
    </svg>
  )
}

const progressBronze = (color = 'white') => {
  return (
    <svg width="291" height="146" viewBox="0 0 291 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.1334 26.2666C39.7769 46.9657 20.8639 78.2369 16.4746 114H18.4901C22.8575 78.9759 41.375 48.3463 68.1413 28.0124L67.1334 26.2666Z" fill={color}/>
      <path d="M16.5 145C24.7843 145 31.5 138.284 31.5 130C31.5 121.716 24.7843 115 16.5 115C8.21573 115 1.5 121.716 1.5 130C1.5 138.284 8.21573 145 16.5 145Z" stroke={color} strokeWidth="2"/>
      <path d="M16.5006 118C23.1227 118 28.5 123.377 28.5 129.999C28.5 136.623 23.1227 142 16.5006 142C9.87734 142 4.5 136.623 4.5 129.999C4.5 123.377 9.87734 118 16.5006 118ZM10.4403 130.466L15.0626 134.583C15.2331 134.737 15.4479 134.811 15.6616 134.811C15.904 134.811 16.1477 134.714 16.3242 134.521L23.4695 126.708C23.6268 126.537 23.7048 126.32 23.7048 126.106C23.7048 125.614 23.3075 125.209 22.807 125.209C22.5621 125.209 22.3209 125.307 22.142 125.501L15.5968 132.657L11.6382 129.13C11.4653 128.978 11.2529 128.902 11.0392 128.902C10.5411 128.902 10.1414 129.304 10.1414 129.798C10.1414 130.044 10.2422 130.289 10.4403 130.466Z" fill="white"/>
      {color !== 'white' && (<g transform="matrix(1,0,0,1,0,3)"><path d="M10.44,127.466L15.063,131.583C15.233,131.737 15.448,131.811 15.662,131.811C15.904,131.811 16.148,131.714 16.324,131.521L23.47,123.708C23.627,123.537 23.705,123.32 23.705,123.106C23.705,122.613 23.307,122.209 22.807,122.209C22.562,122.209 22.321,122.307 22.142,122.501L15.597,129.657L11.638,126.13C11.465,125.978 11.253,125.902 11.039,125.902C10.541,125.902 10.141,126.304 10.141,126.798C10.141,127.044 10.242,127.289 10.44,127.466Z" fill={color}/></g>)}
      <path d="M272.51 114C268.143 78.9754 249.624 48.3453 222.857 28.0114L223.865 26.2656C251.223 46.9647 270.136 78.2363 274.525 114H272.51Z" fill={color} fillOpacity="0.25"/>
      <path d="M195.157 14.9888C179.887 8.55588 163.109 5 145.499 5C127.889 5 111.11 8.55619 95.84 14.9896L94.832 13.2437C110.402 6.6479 127.525 3 145.499 3C163.473 3 180.595 6.64758 196.165 13.2429L195.157 14.9888Z" fill={color} fillOpacity="0.25"/>
      <path d="M81.5 34C89.7843 34 96.5 27.2843 96.5 19C96.5 10.7157 89.7843 4 81.5 4C73.2157 4 66.5 10.7157 66.5 19C66.5 27.2843 73.2157 34 81.5 34Z" stroke={color} strokeWidth="2"/>
      <path d="M81.5 31C88.1274 31 93.5 25.6274 93.5 19C93.5 12.3726 88.1274 7 81.5 7C74.8726 7 69.5 12.3726 69.5 19C69.5 25.6274 74.8726 31 81.5 31Z" fill="white"/>
      <path d="M81.5006 7C88.1227 7 93.5 12.3773 93.5 18.9994C93.5 25.6227 88.1227 31 81.5006 31C74.8773 31 69.5 25.6227 69.5 18.9994C69.5 12.3773 74.8773 7 81.5006 7ZM75.4403 19.4663L80.0626 23.5833C80.2331 23.737 80.4479 23.8114 80.6616 23.8114C80.904 23.8114 81.1477 23.7142 81.3242 23.5209L88.4695 15.7082C88.6268 15.5365 88.7048 15.3205 88.7048 15.1056C88.7048 14.6135 88.3075 14.209 87.807 14.209C87.5621 14.209 87.3209 14.3074 87.142 14.5007L80.5968 21.6569L76.6382 18.1304C76.4653 17.9779 76.2529 17.9023 76.0392 17.9023C75.5411 17.9023 75.1414 18.3044 75.1414 18.7977C75.1414 19.0438 75.2422 19.2887 75.4403 19.4663Z" fill="#A56527"/>
      <path d="M81.5006 7C88.1227 7 93.5 12.3773 93.5 18.9994C93.5 25.6227 88.1227 31 81.5006 31C74.8773 31 69.5 25.6227 69.5 18.9994C69.5 12.3773 74.8773 7 81.5006 7ZM75.4403 19.4663L80.0626 23.5833C80.2331 23.737 80.4479 23.8114 80.6616 23.8114C80.904 23.8114 81.1477 23.7142 81.3242 23.5209L88.4695 15.7082C88.6268 15.5365 88.7048 15.3205 88.7048 15.1056C88.7048 14.6135 88.3075 14.209 87.807 14.209C87.5621 14.209 87.3209 14.3074 87.142 14.5007L80.5968 21.6569L76.6382 18.1304C76.4653 17.9779 76.2529 17.9023 76.0392 17.9023C75.5411 17.9023 75.1414 18.3044 75.1414 18.7977C75.1414 19.0438 75.2422 19.2887 75.4403 19.4663Z"/>
      <path d="M209.5 34C217.784 34 224.5 27.2843 224.5 19C224.5 10.7157 217.784 4 209.5 4C201.216 4 194.5 10.7157 194.5 19C194.5 27.2843 201.216 34 209.5 34Z" stroke={color} strokeOpacity="0.25" strokeWidth="2"/>
      <path d="M274.5 145C282.784 145 289.5 138.284 289.5 130C289.5 121.716 282.784 115 274.5 115C266.216 115 259.5 121.716 259.5 130C259.5 138.284 266.216 145 274.5 145Z" stroke={color} strokeOpacity="0.25" strokeWidth="2"/>
    </svg>
  )
}

const progressSilver = (color = 'white') => {
  return (
    <svg width="291" height="146" viewBox="0 0 291 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.1334 26.2666C39.7769 46.9656 20.8639 78.2369 16.4746 114H18.4901C22.8575 78.9759 41.375 48.3463 68.1413 28.0124L67.1334 26.2666Z" fill={color}/>
      <path d="M195.157 11.9888C179.888 5.55588 163.109 2 145.5 2C127.89 2 111.11 5.55619 95.8404 11.9896L94.8325 10.2437C110.403 3.6479 127.525 0 145.5 0C163.473 0 180.595 3.64758 196.165 10.2429L195.157 11.9888Z" fill={color}/>
      <path d="M16.5 145C24.7843 145 31.5 138.284 31.5 130C31.5 121.716 24.7843 115 16.5 115C8.21573 115 1.5 121.716 1.5 130C1.5 138.284 8.21573 145 16.5 145Z" stroke={color} strokeWidth="2"/>
      <path d="M16.5006 118C23.1227 118 28.5 123.377 28.5 129.999C28.5 136.623 23.1227 142 16.5006 142C9.87734 142 4.5 136.623 4.5 129.999C4.5 123.377 9.87734 118 16.5006 118ZM10.4403 130.466L15.0626 134.583C15.2331 134.737 15.4479 134.811 15.6616 134.811C15.904 134.811 16.1477 134.714 16.3242 134.521L23.4695 126.708C23.6268 126.537 23.7048 126.32 23.7048 126.106C23.7048 125.614 23.3075 125.209 22.807 125.209C22.5621 125.209 22.3209 125.307 22.142 125.501L15.5968 132.657L11.6382 129.13C11.4653 128.978 11.2529 128.902 11.0392 128.902C10.5411 128.902 10.1414 129.304 10.1414 129.798C10.1414 130.044 10.2422 130.289 10.4403 130.466Z" fill="white"/>
      {color !== 'white' && (<g transform="matrix(1,0,0,1,0,3)"><path d="M10.44,127.466L15.063,131.583C15.233,131.737 15.448,131.811 15.662,131.811C15.904,131.811 16.148,131.714 16.324,131.521L23.47,123.708C23.627,123.537 23.705,123.32 23.705,123.106C23.705,122.613 23.307,122.209 22.807,122.209C22.562,122.209 22.321,122.307 22.142,122.501L15.597,129.657L11.638,126.13C11.465,125.978 11.253,125.902 11.039,125.902C10.541,125.902 10.141,126.304 10.141,126.798C10.141,127.044 10.242,127.289 10.44,127.466Z" fill={color}/></g>)}
      <path d="M272.51 114C268.143 78.9754 249.624 48.3453 222.857 28.0114L223.865 26.2656C251.223 46.9647 270.136 78.2363 274.525 114H272.51Z" fill={color} fillOpacity="0.25"/>
      <path d="M81.5 34C89.7843 34 96.5 27.2843 96.5 19C96.5 10.7157 89.7843 4 81.5 4C73.2157 4 66.5 10.7157 66.5 19C66.5 27.2843 73.2157 34 81.5 34Z" stroke={color} strokeWidth="2"/>
      <path d="M81.5 31C88.1274 31 93.5 25.6274 93.5 19C93.5 12.3726 88.1274 7 81.5 7C74.8726 7 69.5 12.3726 69.5 19C69.5 25.6274 74.8726 31 81.5 31Z" fill="white"/>
      <path d="M81.5006 7C88.1227 7 93.5 12.3773 93.5 18.9994C93.5 25.6227 88.1227 31 81.5006 31C74.8773 31 69.5 25.6227 69.5 18.9994C69.5 12.3773 74.8773 7 81.5006 7ZM75.4403 19.4663L80.0626 23.5833C80.2331 23.737 80.4479 23.8114 80.6616 23.8114C80.904 23.8114 81.1477 23.7142 81.3242 23.5209L88.4695 15.7082C88.6268 15.5365 88.7048 15.3205 88.7048 15.1056C88.7048 14.6135 88.3075 14.209 87.807 14.209C87.5621 14.209 87.3209 14.3074 87.142 14.5007L80.5968 21.6569L76.6382 18.1304C76.4653 17.9779 76.2529 17.9023 76.0392 17.9023C75.5411 17.9023 75.1414 18.3044 75.1414 18.7977C75.1414 19.0438 75.2422 19.2887 75.4403 19.4663Z" fill="#A56527"/>
      <path d="M81.5006 7C88.1227 7 93.5 12.3773 93.5 18.9994C93.5 25.6227 88.1227 31 81.5006 31C74.8773 31 69.5 25.6227 69.5 18.9994C69.5 12.3773 74.8773 7 81.5006 7ZM75.4403 19.4663L80.0626 23.5833C80.2331 23.737 80.4479 23.8114 80.6616 23.8114C80.904 23.8114 81.1477 23.7142 81.3242 23.5209L88.4695 15.7082C88.6268 15.5365 88.7048 15.3205 88.7048 15.1056C88.7048 14.6135 88.3075 14.209 87.807 14.209C87.5621 14.209 87.3209 14.3074 87.142 14.5007L80.5968 21.6569L76.6382 18.1304C76.4653 17.9779 76.2529 17.9023 76.0392 17.9023C75.5411 17.9023 75.1414 18.3044 75.1414 18.7977C75.1414 19.0438 75.2422 19.2887 75.4403 19.4663Z"/>
      <path d="M209.5 34C217.784 34 224.5 27.2843 224.5 19C224.5 10.7157 217.784 4 209.5 4C201.216 4 194.5 10.7157 194.5 19C194.5 27.2843 201.216 34 209.5 34Z" stroke={color} strokeWidth="2"/>
      <path d="M209.5 31C216.127 31 221.5 25.6274 221.5 19C221.5 12.3726 216.127 7 209.5 7C202.873 7 197.5 12.3726 197.5 19C197.5 25.6274 202.873 31 209.5 31Z" fill="white"/>
      <path d="M209.501 7C216.123 7 221.5 12.3773 221.5 18.9994C221.5 25.6227 216.123 31 209.501 31C202.877 31 197.5 25.6227 197.5 18.9994C197.5 12.3773 202.877 7 209.501 7ZM203.44 19.4663L208.063 23.5833C208.233 23.737 208.448 23.8114 208.662 23.8114C208.904 23.8114 209.148 23.7142 209.324 23.5209L216.47 15.7082C216.627 15.5365 216.705 15.3205 216.705 15.1056C216.705 14.6135 216.308 14.209 215.807 14.209C215.562 14.209 215.321 14.3074 215.142 14.5007L208.597 21.6569L204.638 18.1304C204.465 17.9779 204.253 17.9023 204.039 17.9023C203.541 17.9023 203.141 18.3044 203.141 18.7977C203.141 19.0438 203.242 19.2887 203.44 19.4663Z" fill="#DBDBDB"/>
      <path d="M209.501 7C216.123 7 221.5 12.3773 221.5 18.9994C221.5 25.6227 216.123 31 209.501 31C202.877 31 197.5 25.6227 197.5 18.9994C197.5 12.3773 202.877 7 209.501 7ZM203.44 19.4663L208.063 23.5833C208.233 23.737 208.448 23.8114 208.662 23.8114C208.904 23.8114 209.148 23.7142 209.324 23.5209L216.47 15.7082C216.627 15.5365 216.705 15.3205 216.705 15.1056C216.705 14.6135 216.308 14.209 215.807 14.209C215.562 14.209 215.321 14.3074 215.142 14.5007L208.597 21.6569L204.638 18.1304C204.465 17.9779 204.253 17.9023 204.039 17.9023C203.541 17.9023 203.141 18.3044 203.141 18.7977C203.141 19.0438 203.242 19.2887 203.44 19.4663Z"/>
      <path d="M274.5 145C282.784 145 289.5 138.284 289.5 130C289.5 121.716 282.784 115 274.5 115C266.216 115 259.5 121.716 259.5 130C259.5 138.284 266.216 145 274.5 145Z" stroke={color} strokeOpacity="0.25" strokeWidth="2"/>
    </svg>
  )
}

const progressGold = (color = 'white') => {
  return (
    <svg width="291" height="146" viewBox="0 0 291 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.1334 26.2666C39.7769 46.9656 20.8639 78.2369 16.4746 114H18.4901C22.8575 78.9759 41.375 48.3463 68.1413 28.0124L67.1334 26.2666Z" fill={color}/>
      <path d="M195.157 11.9888C179.888 5.55588 163.109 2 145.5 2C127.89 2 111.11 5.55619 95.8404 11.9896L94.8325 10.2437C110.403 3.6479 127.525 0 145.5 0C163.473 0 180.595 3.64758 196.165 10.2429L195.157 11.9888Z" fill={color}/>
      <path d="M272.509 114C268.142 78.9753 249.624 48.3452 222.857 28.0113L223.865 26.2656C251.222 46.9646 270.136 78.2363 274.525 114H272.509Z" fill={color}/>
      <path d="M16.5 145C24.7843 145 31.5 138.284 31.5 130C31.5 121.716 24.7843 115 16.5 115C8.21573 115 1.5 121.716 1.5 130C1.5 138.284 8.21573 145 16.5 145Z" stroke={color} strokeWidth="2"/>
      <path d="M16.5 142C23.1274 142 28.5 136.627 28.5 130C28.5 123.373 23.1274 118 16.5 118C9.87258 118 4.5 123.373 4.5 130C4.5 136.627 9.87258 142 16.5 142Z" fill="white"/>
      <path d="M16.5006 118C23.1227 118 28.5 123.377 28.5 129.999C28.5 136.623 23.1227 142 16.5006 142C9.87734 142 4.5 136.623 4.5 129.999C4.5 123.377 9.87734 118 16.5006 118ZM10.4403 130.466L15.0626 134.583C15.2331 134.737 15.4479 134.811 15.6616 134.811C15.904 134.811 16.1477 134.714 16.3242 134.521L23.4695 126.708C23.6268 126.537 23.7048 126.32 23.7048 126.106C23.7048 125.614 23.3075 125.209 22.807 125.209C22.5621 125.209 22.3209 125.307 22.142 125.501L15.5968 132.657L11.6382 129.13C11.4653 128.978 11.2529 128.902 11.0392 128.902C10.5411 128.902 10.1414 129.304 10.1414 129.798C10.1414 130.044 10.2422 130.289 10.4403 130.466Z" fill="#FFC12E"/>
      <path d="M81.5 34C89.7843 34 96.5 27.2843 96.5 19C96.5 10.7157 89.7843 4 81.5 4C73.2157 4 66.5 10.7157 66.5 19C66.5 27.2843 73.2157 34 81.5 34Z" stroke={color} strokeWidth="2"/>
      <path d="M81.5 31C88.1274 31 93.5 25.6274 93.5 19C93.5 12.3726 88.1274 7 81.5 7C74.8726 7 69.5 12.3726 69.5 19C69.5 25.6274 74.8726 31 81.5 31Z" fill="white"/>
      <path d="M81.5006 7C88.1227 7 93.5 12.3773 93.5 18.9994C93.5 25.6227 88.1227 31 81.5006 31C74.8773 31 69.5 25.6227 69.5 18.9994C69.5 12.3773 74.8773 7 81.5006 7ZM75.4403 19.4663L80.0626 23.5833C80.2331 23.737 80.4479 23.8114 80.6616 23.8114C80.904 23.8114 81.1477 23.7142 81.3242 23.5209L88.4695 15.7082C88.6268 15.5365 88.7048 15.3205 88.7048 15.1056C88.7048 14.6135 88.3075 14.209 87.807 14.209C87.5621 14.209 87.3209 14.3074 87.142 14.5007L80.5968 21.6569L76.6382 18.1304C76.4653 17.9779 76.2529 17.9023 76.0392 17.9023C75.5411 17.9023 75.1414 18.3044 75.1414 18.7977C75.1414 19.0438 75.2422 19.2887 75.4403 19.4663Z" fill="#FFC12E"/>
      <path d="M209.5 34C217.784 34 224.5 27.2843 224.5 19C224.5 10.7157 217.784 4 209.5 4C201.216 4 194.5 10.7157 194.5 19C194.5 27.2843 201.216 34 209.5 34Z" stroke={color} strokeWidth="2"/>
      <path d="M209.5 31C216.127 31 221.5 25.6274 221.5 19C221.5 12.3726 216.127 7 209.5 7C202.873 7 197.5 12.3726 197.5 19C197.5 25.6274 202.873 31 209.5 31Z" fill="white"/>
      <path d="M209.501 7C216.123 7 221.5 12.3773 221.5 18.9994C221.5 25.6227 216.123 31 209.501 31C202.877 31 197.5 25.6227 197.5 18.9994C197.5 12.3773 202.877 7 209.501 7ZM203.44 19.4663L208.063 23.5833C208.233 23.737 208.448 23.8114 208.662 23.8114C208.904 23.8114 209.148 23.7142 209.324 23.5209L216.47 15.7082C216.627 15.5365 216.705 15.3205 216.705 15.1056C216.705 14.6135 216.308 14.209 215.807 14.209C215.562 14.209 215.321 14.3074 215.142 14.5007L208.597 21.6569L204.638 18.1304C204.465 17.9779 204.253 17.9023 204.039 17.9023C203.541 17.9023 203.141 18.3044 203.141 18.7977C203.141 19.0438 203.242 19.2887 203.44 19.4663Z" fill="#FFC12E"/>
      <path d="M274.5 145C282.784 145 289.5 138.284 289.5 130C289.5 121.716 282.784 115 274.5 115C266.216 115 259.5 121.716 259.5 130C259.5 138.284 266.216 145 274.5 145Z" stroke={color} strokeWidth="2"/>
      <path d="M274.5 142C281.127 142 286.5 136.627 286.5 130C286.5 123.373 281.127 118 274.5 118C267.873 118 262.5 123.373 262.5 130C262.5 136.627 267.873 142 274.5 142Z" fill="white"/>
      <path d="M274.501 118C281.123 118 286.5 123.377 286.5 129.999C286.5 136.623 281.123 142 274.501 142C267.877 142 262.5 136.623 262.5 129.999C262.5 123.377 267.877 118 274.501 118ZM268.44 130.466L273.063 134.583C273.233 134.737 273.448 134.811 273.662 134.811C273.904 134.811 274.148 134.714 274.324 134.521L281.47 126.708C281.627 126.537 281.705 126.32 281.705 126.106C281.705 125.614 281.308 125.209 280.807 125.209C280.562 125.209 280.321 125.307 280.142 125.501L273.597 132.657L269.638 129.13C269.465 128.978 269.253 128.902 269.039 128.902C268.541 128.902 268.141 129.304 268.141 129.798C268.141 130.044 268.242 130.289 268.44 130.466Z" fill="#FFC12E"/>
      <path d="M274.501 118C281.123 118 286.5 123.377 286.5 129.999C286.5 136.623 281.123 142 274.501 142C267.877 142 262.5 136.623 262.5 129.999C262.5 123.377 267.877 118 274.501 118ZM268.44 130.466L273.063 134.583C273.233 134.737 273.448 134.811 273.662 134.811C273.904 134.811 274.148 134.714 274.324 134.521L281.47 126.708C281.627 126.537 281.705 126.32 281.705 126.106C281.705 125.614 281.308 125.209 280.807 125.209C280.562 125.209 280.321 125.307 280.142 125.501L273.597 132.657L269.638 129.13C269.465 128.978 269.253 128.902 269.039 128.902C268.541 128.902 268.141 129.304 268.141 129.798C268.141 130.044 268.242 130.289 268.44 130.466Z" />
    </svg>
  )
}

const ProgressGraphic = ({ progress = '', color = 'white' }) => {
  switch (progress) {
    case 'bronze':
      return progressBronze(color)
    case 'silver':
      return progressSilver(color)
    case 'gold':
      return progressGold(color)
    default:
      return progressBasic(color)
  }
}

export default ProgressGraphic