import React, { Component } from "react"
import { RouteComponentProps } from "react-router"
import { observer } from "mobx-react"
import { observable } from "mobx"
import getIn from "lodash/get"

import BrandStore from "stores/BrandStore"
import LocationWaiverStore from "apps/book/stores/xpass/LocationWaiverStore"
import ScheduleEntry from "models/ScheduleEntry"
import ServiceScheduleEntry from "apps/book/models/ServiceScheduleEntry"
import HealthCheck from "apps/book/components/HealthCheck"
import Spinner from "components/Spinner"

export interface Props extends RouteComponentProps {
  store?: BrandStore
  handleHealthCheckConfirm: Function
  handleHealthCheckCancel: Function
  entry: ScheduleEntry | ServiceScheduleEntry
  handleIsFrozen?: Function
  serviceBookingCheck?: Function
  postWaiverAction?: Function
}

@observer
export default class BookingFlowModal extends Component<Props> {
  @observable locationWaiverStore = new LocationWaiverStore(this.props.store!)
  @observable checkedWaiver: Array<string> = []
  @observable hasWaivers: boolean = false
  @observable isPending: boolean = false

  componentDidMount() {
    const locationId = getIn(this.props.entry, "locationId", undefined)
    const id = locationId || getIn(this.props.entry, "location.id", undefined)
    this.locationWaiverStore.fetch(id).then((res) => {
      // TODO: Remove this check if healthcheck is needed again
      // To bypass the healthcheck, check for any bookings that have no waivers
      // If there are no waivers, this will accept and continue in the booking flow
      const isWaiversEmpty = Object.values(res.data.waivers).every(x => x === undefined || x === '')
      res.data.waivers && !isWaiversEmpty
        ? this.hasWaivers = true
        : this.handleAccept()
    })
  }

  handleAccept = () => {
    this.isPending = true
    if (this.props.handleIsFrozen) {
      this.props.handleIsFrozen()
    }

    if (!this.hasWaivers) {
      this.props.handleHealthCheckConfirm()
    }

    this.props.store!.uiStore.closeModal()

    if (this.props.postWaiverAction) {
      this.props.postWaiverAction()
      return
    }

    if (this.props.serviceBookingCheck) {
      this.props.serviceBookingCheck(this.props.entry)
    } else {
      const url = `/book/${this.props.store!.userStore.session!.locationId}`
      this.props.history.push({ pathname: `${url}/${this.props.entry.id}` })
    }
  }

  handleHealthCheckConfirm = () => {
    this.props.handleHealthCheckConfirm()
    this.hasWaivers = true
  }

  handleHealthCheckCancel = (e: ButtonEvent) => {
    this.props.handleHealthCheckCancel(e, true)
    this.props.store!.uiStore.closeModal()
  }

  handleWaiverCancel = () => {
    this.props.store!.uiStore.closeModal()
  }

  handleWaiverClick = (title: string, waiver: string) => {
    const win = window.open()
    const waiverTitle = title[0].toUpperCase() + title.slice(1)
    win!.document.title = `${waiverTitle} Waiver`
    win!.document.body.innerHTML = waiver
  }

  handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, waiver: string) => {
    const checked = [...this.checkedWaiver]
    if (e.target.checked) {
      checked.push(waiver)
    } else {
      const index = checked.findIndex(item => item === waiver)
      checked.splice(index, 1)
    }
    this.checkedWaiver = checked
  }

  getWaiverName = (waiver: string) => {
    switch(waiver) {
      case "terms":
        return "Terms and Conditions of Service"
      case "covid":
        return "COVID-19 Waiver"
      default:
        return "Waiver"
    }
  }

  render() {
    const { locationWaiver } = this.locationWaiverStore
    const waivers = locationWaiver && Object.keys(locationWaiver).filter(waiver => locationWaiver[waiver])
    const isDisabled = waivers && waivers.length !== this.checkedWaiver.length

    return (
      <>
        {waivers && this.hasWaivers && (
          <div className="booking-modal p-4 text-center">
            <h3>Waiver</h3>
            <div className="booking-modal__waivers">
              {waivers.map((waiver, index) => {
                return (
                  locationWaiver &&
                  locationWaiver[waiver] && (
                    <div key={index} className="booking-modal__waiver">
                      <a
                        className="booking-modal__waiver-link"
                        onClick={() =>
                          this.handleWaiverClick(waiver, locationWaiver[waiver])
                        }
                      >
                        {this.getWaiverName(waiver)}
                      </a>
                      <input
                        type="checkbox"
                        onChange={e => this.handleCheckbox(e, waiver)}
                      />
                    </div>
                  )
                )
              })}
            </div>

            <div className="text-center">
              <button
                className="btn btn-primary d-block mx-auto"
                onClick={this.handleAccept}
                disabled={isDisabled}
              >
                I Agree
              </button>
              <button
                className="btn btn-link"
                onClick={this.handleWaiverCancel}
              >
                <small className="text-muted">Cancel</small>
              </button>
            </div>
          </div>

        // TODO: If Xpo wants to get rid of health check for good, this can be removed
        // For now, bypass healthcheck message and only show waivers
        /*) : (
           // If no waivers, go directly to booking flow
          <HealthCheck
            store={this.props.store!}
            onConfirm={
              waivers && waivers.length === 0 || !waivers
                ? this.handleAccept
                : this.handleHealthCheckConfirm
            }
            onCancel={this.handleHealthCheckCancel}
          /> */

        )}

        {this.isPending && (
          <div className="booking-modal__spinner">
            <Spinner size="element" />
          </div>
        )}
      </>
    )
  }
}
