import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"

import AbstractStatsStore from "apps/history/stores/AbstractStatsStore"
import HeartRateSummary from "apps/history/models/HeartRateSummary"
import HeartRateClass from "apps/history/models/HeartRateClass"
import { UserSession } from "models/UserSession"

interface Response {
  summary_stats: HeartRateSummary
  class_stats: HeartRateClass[]
  user?: UserSession
}

export default class HeartRateStatsStore extends AbstractStatsStore<Response> {
  type = "heart_rate" as const

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    MemoizeMiddleware,
    DeserializeMiddleware("summary_stats", HeartRateSummary, true),
    DeserializeMiddleware("class_stats", HeartRateClass),
    TokenAuthMiddleware(this.userStore),
  ])

  fetch() {
    return this.api.get(`/api/performance_stats/heart_rate`)
  }
}
