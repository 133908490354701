import React, { useState } from "react"
import cx from "classnames"
import { Link } from "react-router-dom"
import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import ActivityStore from "apps/classpoints/stores/ActivityStore"

const MyActivityCard = ({store}: {store: BrandStore}) => {
  const [activityStore, setActivityStore] = useState(new ActivityStore(store))

  return (
    <div className={cx("card", "ways-to-earn-card")}>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="text-dark">
          My Activity
        </h5>
        <small>
          <Link to={`/classpoints/activity`} onClick={() => store!.track.event("classpoints_tap view my activity")}>
            View All
          </Link>
        </small>
      </div>
      <APILoader
        apiStore={activityStore}
        elementSize="element"
        render={() => {
          let { activity } = activityStore
          activity = activity.slice(0, 3) // Only display first three

          return (
            <div className="card-body d-flex flex-column p-0">
              {!!activity.length && (
                <ul className="list-group list-group-flush flex-grow-1 mb-0 rows-in pt-2">
                  {activity.map((entry, i) => (
                    <li key={`${entry.id}_${i}`} className={cx("list-group-item d-flex align-items-center mx-3 px-0")}>
                      <div className="list-group-item__image">
                        <img src={entry.thumbnailImageUrl} />
                      </div>
                      <div className="pl-3">
                        <div className="font-16">{entry.name}</div>
                        <div className="font-14 font-600 color-green">{entry.shortDescription}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {!activity.length && (
                <div className="list-group list-group-flush flex-grow-1 mb-0 rows-in py-4 mx-3">
                  No activity found.
                </div>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

export default MyActivityCard