import * as React from "react"
import { RouteComponentProps, Switch, Route, Redirect } from "react-router"
// import RegistrationForm from "apps/auth/RegistrationForm"
import LoginForm from "apps/auth/components/LoginForm"
import { observer, inject } from "mobx-react"
import ForgotPasswordForm from "apps/auth/components/ForgotPasswordForm"

// import UpdatePasswordForm from "apps/account/UpdatePasswordForm"
import BrandStore from "stores/BrandStore"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"
import ProspectForm from "apps/auth/components/ProspectForm"
import { AuthPagePath } from "apps/auth/components/AuthController"
import APILoader from "components/wrappers/APILoader"
import { LIST_LENGTH } from "stores/LocationsStore"
import Offer from "models/Offer"
import { loadState } from "services/savedState"
import nl2br from "helpers/nl2br"
import LoginInfoModal from "apps/auth/components/xpass/LoginInfoModal"

export interface Props {
  store?: BrandStore
  onSlotPage: boolean
  offer: Offer
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class OfferAuthController extends React.Component<
  Props & RouteComponentProps
> {
  rememberKey = "xpo-remember-me"
  emailKey = "xpo-last-email"
  storedEmail: string = ""
  userAuthStore = new UserAuthStore()

  handlePageChange = (page: AuthPagePath) => {
    this.props.history.push(page)
  }

  openXpassModal = () => {
    this.props.store!.uiStore.openModal({
      children: <LoginInfoModal store={this.props.store} />,
      modalShow: true,
      emptyHeader: true,
    })
  }

  componentDidMount() {
    if (this.props.store!.isXponential) {
      this.userAuthStore.setXpassMobileData(this.props.store!.routingStore)
    }
  }

  componentWillMount() {
    const rememberMe: boolean = loadState(this.rememberKey)!
    if (rememberMe) {
      this.storedEmail = loadState(this.emailKey)
    }
  }

  public render() {
    const { onSlotPage, offer } = this.props
    return (
      <div className="mx-auto">
        {/* TODO: display flex, center vertically, etc etc */}
        {/* NOTE: fetching extra b/c we filter results by promo slot availability */}
        <APILoader
          apiStore={this.props.store!.locationsStore}
          fetchProps={{ geoip: true, limit: LIST_LENGTH * 2 }}
          alreadyLoaded
          render={() => (
            <Switch>
              <Route
                path={`${this.props.match.path}/login`}
                exact
                render={() => (
                  <LoginForm
                    lockLocation={!onSlotPage}
                    subheadBlock={<SubheadBlock offer={offer} type="signin" />}
                    onPageChange={this.handlePageChange}
                    openXpassModal={this.openXpassModal}
                    storedEmail={this.storedEmail}
                    rememberKey={this.rememberKey}
                    emailKey={this.emailKey}
                    pageName={
                      onSlotPage ? "offer slot > sign in" : "offer > sign in"
                    }
                    userAuthStore={this.userAuthStore}
                  />
                )}
              />

              <Route
                path={`${this.props.match.path}/register`}
                render={() => (
                  <ProspectForm
                    store={this.props.store}
                    lockLocation={!onSlotPage}
                    onPageChange={this.handlePageChange}
                    pageName={
                      onSlotPage ? "offer slot > register" : "offer > register"
                    }
                    subheadBlock={<SubheadBlock offer={offer} type="signup" />}
                    userAuthStore={this.userAuthStore}
                  />
                )}
                exact
              />
              <Route
                path={`${this.props.match.path}/forgot_password`}
                render={() => (
                  <ForgotPasswordForm
                    lockLocation={!onSlotPage}
                    subheadBlock={<SubheadBlock offer={offer} type="signin" />}
                    onPageChange={this.handlePageChange}
                    pageName={
                      onSlotPage
                        ? "offer slot > forgot password"
                        : "offer > forgot password"
                    }
                  />
                )}
                exact
              />
              <Redirect
                path={this.props.match.path}
                to={`${this.props.match.path}/register`}
              />
            </Switch>
          )}
        />
      </div>
    )
  }
}

export interface SubheadBlockContent {
  name: string
  description?: string
}

interface SubheadBlockProps {
  offer: SubheadBlockContent
  type: "signin" | "signup" | undefined
}

const SubheadBlock: React.SFC<SubheadBlockProps> = ({ offer, type }) => (
  <div className="text-center">
    <h2 className="mb-1">{offer.name}</h2>
    <p>{nl2br(offer.description)}</p>

    {type === "signin" ? (
      <p>Please sign in to continue purchasing this offer.</p>
    ) : type === "signup" ? (
      <p>Please create an account to continue purchasing this offer.</p>
    ) : (
      <p />
    )}
  </div>
)
