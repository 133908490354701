import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action, computed } from "mobx"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import UserStore from "stores/UserStore"
import Goal, { GoalId } from "models/Goal"

interface Reponse {
  goal: Goal
}

export default class GoalStore extends APIStore {
  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("goal", Goal),
    TokenAuthMiddleware(this.userStore),
  ])

  @observable goal?: Goal

  constructor(public id: GoalId, protected userStore: UserStore) {
    super()
  }

  @action
  fetch() {
    return this.api.get(`/api/goals/${this.id}`)
  }

  /**
   * Set a goal for a user.
   * @param target How many times they need to complete the activity to acheive the goal. 0 if the goal is unset.
   * @param period The period or interval of the goal.  i.e. `weekly`
   */
  @action
  save() {
    this.goal!.previousTarget = this.goal!.target
    return this.api.put(`/api/goals/${this.id}`, {
      target: this.goal!.target,
      period: this.goal!.period,
    })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Reponse>) {
    this.goal = res.data.goal
    return res
  }
}
