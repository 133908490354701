import { JsonObject, JsonProperty } from "json2typescript"
import Milestone from "apps/challenge/models/Milestone"

@JsonObject("Challenge")
export default class Challenges {
  @JsonProperty("id", Number)
  id: number = undefined!

  @JsonProperty("challenge_type", String, true)
  challengeType: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("description", String)
  description: string = undefined!

  @JsonProperty("start_date", String)
  startDate: string = undefined!

  @JsonProperty("end_date", String)
  endDate: string = undefined!

  @JsonProperty("cta", String)
  cta: string = undefined!

  @JsonProperty("learn_more_url", String, true)
  learnMoreUrl: string = undefined!

  @JsonProperty("active", Boolean)
  active: boolean = undefined!

  @JsonProperty("achieved", Boolean, true)
  achieved: boolean = undefined!

  @JsonProperty("opted_in", Boolean)
  optedIn: boolean = undefined!

  @JsonProperty("hide_end_date", Boolean, true)
  hideEndDate?: boolean = undefined!
  
  @JsonProperty("attendance_count", Number)
  attendanceCount: number = undefined!

  @JsonProperty("action_url_text", String, true)
  actionUrlText: string = undefined!

  @JsonProperty("action_url", String, true)
  actionUrl: string = undefined!

  @JsonProperty("terms_url_text", String, true)
  termsUrlText: string = undefined!

  @JsonProperty("terms_url", String, true)
  termsUrl: string = undefined!

  @JsonProperty("milestone_unit", String)
  milestoneUnit: string = undefined!

  @JsonProperty("challenge_milestone_default_image", String)
  challengeMilestoneDefaultImage: string = undefined!

  @JsonProperty("milestones", [Milestone])
  milestones: Milestone[] = []
}
