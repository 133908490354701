import React, { Component } from "react"
import { observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import LocationSummary from "models/LocationSummary"
import cx from "classnames"
import LocationFinderStore from "../stores/LocationFinderStore"
import FavoriteHeart from "components/FavoriteHeart"
import Svg from "components/Svg"
import laFitnessLogo from "images/logos/la-fitness-logo.svg"

export interface LocationListItemProps {
  location: LocationSummary
  onListItemClick: Function
  onLocationSelect: Function
  store: BrandStore
  locationFinderStore: LocationFinderStore
  hideActions: boolean
}

@observer
export default class LocationListItem extends Component<
  LocationListItemProps,
  {}
> {
  elRef: React.RefObject<HTMLLIElement> = React.createRef()
  showAccordion: Boolean = false
  accordionRef = React.createRef<HTMLDivElement>()

  handleAddClick = (locationId: string) => {
    this.props.store.userStore.favoriteLocationsStore.add(locationId)
  }

  handleRemoveClick = (locationId: string) => {
    this.props.store.userStore.favoriteLocationsStore.remove(locationId)
  }

  handleSelectClick = (e: ButtonEvent) => {
    e.preventDefault()
    this.props.onLocationSelect(this.props.location)
  }

  handleAccordionClick = (e: ButtonEvent) => {
    e.preventDefault()
    this.showAccordion = !this.showAccordion
  }

  renderHeight() {
    const maxHeight = this.accordionRef.current ? this.accordionRef.current.scrollHeight : '500px'
    return this.showAccordion ? maxHeight : 0;
  }

  render() {
    const { location, store, locationFinderStore } = this.props

    return (
      <li
        ref={this.elRef}
        className={cx(
          "list-group-item",
          "list-group-item-action",
          "my-1",
          "p-3",
          {
            "list-group-item-secondary":
              locationFinderStore.hoveredLocationId === location.id,
          }
        )}
        onClick={e => {
          this.props.onListItemClick(location)
        }}
      >
        <div className="row w-100 m-0">
          <div className="col-md-7 p-0 pr-md-3 mb-lg-4 mb-xl-0">
            <div className="location-name">
              <strong>{location.name}</strong>
            </div>
            {(store.brandId === "rumble" && location.locationType === "signature_training") && (
              <div className="d-flex">
                <Svg name="training" width="21" height="20" />
                <strong className="ml-2 d-none d-md-inline text-dark mb-1">Rumble Training</strong>
              </div>
            )}
            <p className="my-1">
              {location.address} {location.address2}
            </p>
            <p className="my-1">{`${location.city}, ${location.state} ${location.zip}`}</p>
          </div>
          <div className="col-md-5 p-0 position-static">
            {/* show both, hide one */}
            {[
              "btn btn-primary btn-block px-0 d-none d-md-block",
              "btn btn-primary btn-sm d-block mt-2 mx-auto w-50 d-md-none",
            ].map(cn => (
              <button key={cn} className={cn} onClick={this.handleSelectClick}>
                Select Studio
              </button>
            ))}

            {store.userStore.session && !this.props.hideActions && (
              <LocationHeart
                locationId={location.id}
                userLocationId={store.userStore.session.locationId}
              />
            )}
            {location.laFitness && (
              <button
                type="button"
                className="list-group-item-lafitness p-0 mt-3"
                data-tip data-for='la-fitness'
                data-iscapture='true'
                onClick={this.handleAccordionClick}
              >
                <img className="list-group-item-lafitness__logo" src={laFitnessLogo} />
                <span className="list-group-item-lafitness__info ml-1">i</span>
              </button>
            )}
          </div>
        </div>
        {location.laFitness && location.laFitnessDescription && (
          <div
            ref={this.accordionRef}
            className={cx(
              { "list-group-item__show": this.showAccordion },
              { "list-group-item__hide": !this.showAccordion },
              "list-group-item__accordion row w-100 m-0 mt-3 mt-md-0")}
            style={{maxHeight: this.renderHeight()}}
          >
            <button
              type="button"
              className="list-group-item__close"
              aria-label="Close"
              onClick={this.handleAccordionClick}
            ></button>
            <div className="list-group-item__accordion-title">Inside LA | Fitness</div>
            <div className="list-group-item__accordion-desc">{location.laFitnessDescription}</div>
            {location.laFitnessLink && location.laFitnessLinkUrl && (
              <a href={location.laFitnessLinkUrl} className="list-group-item__accordion-desc text-center mt-2 w-100" target="_blank">
                {location.laFitnessLink}
                <Svg name="caret-right" className="pl-2" size="12" />
              </a>
            )}
          </div>
        )}
      </li>
    )
  }
}

interface LocationHeartProps {
  locationId: string
  userLocationId: string
}
const LocationHeart: React.SFC<LocationHeartProps> = ({
  locationId,
  userLocationId,
}) => {
  const wrapperClass = cx("d-flex", "justify-content-center")
  return (
    <div className="favorite-location">
      {userLocationId !== locationId ? (
        <>
          <FavoriteHeart
            type="location"
            id={locationId}
            width="20"
            height="20"
            wrapperClass={wrapperClass}
          >
            <span className="ml-2">Favorite</span>
          </FavoriteHeart>
        </>
      ) : (
        <div className={wrapperClass}>
          <Svg name="home" width="20" height="20" className="text-primary" />
          <strong className="ml-2 d-none d-md-inline">Home Studio</strong>
        </div>
      )}
    </div>
  )
}
