import * as Sentry from "@sentry/browser"
import * as amplitude from "amplitude-js"
import { UserSession } from "models/UserSession"
import UserProfile from "models/UserProfile"
import {
  EventEntities,
  entityEvent,
  pageScreens,
  profileSchema,
  sessionSchema,
  TrackingEvent,
  RevenueEntities,
} from "models/TrackingSchemas"
import BrandStore from "stores/BrandStore"
import BaseStore from "stores/BaseStore"
import { toJS } from "mobx"

export default class Tracker extends BaseStore {
  amp = amplitude.getInstance()

  constructor(public store: BrandStore) {
    super()
    this.amp.init(store.brand.amplitudeApiKey!, undefined, {
      includeUtm: true,
      includeReferrer: true,

      batchEvents: true,
      eventUploadThreshold: 5,
      eventUploadPeriodMillis: 5000,

      deviceIdFromUrlParam: true,

      logLevel: process.env.NODE_ENV === "development" ? "INFO" : "WARN",
    })
  }

  start() {
    this.observe(this.store.routingStore, "pageName", change => {
      if (!change.newValue) return
      if (change.newValue) {
        // deferred to next frame to avoid tracking last page
        setTimeout(() => {
          // this may double-count initial pageview for HubSpot
          this.pageView(toJS(this.store.routingStore.pageExtra))
        })
      }
    })
  }

  pageView(props: {} = {}, ampOnly: boolean = false) {
    Sentry.setExtra("page_extra", props)
    if (this.store.routingStore.pageName) {
      Sentry.setTag("page_name", this.store.routingStore.pageName)
    }

    this.event("pageview", {
      loc: this.store.locStore.currentLocation,
      ...props,
    })

    if (ampOnly) return
    if (process.env.NODE_ENV === "production") {
      window._hsq.push(["setPath", this.store.routingStore.location.pathname])
      window._hsq.push(["trackPageView"])
    }
  }

  event(
    name: TrackingEvent,
    entities: EventEntities = {},
    props: Record<string, unknown> = {}
  ) {
    let data: Record<string, unknown> = {
      ...entityEvent(entities),
      ...props,
      path: this.store.routingStore.location.pathname,
      brand: this.store.brand.id,
    }
    const page = this.store.routingStore.pageName
    const extra = this.store.routingStore.pageExtra
    if (page) {
      data.page = page
      if (pageScreens[page]) data["referring screen"] = pageScreens[page]
    }
    if (extra) data = { ...extra, ...data }

    this.amp.logEvent(name, data)

    this.logMessage(name, data)

    return data
  }

  // For now we'll always create an event as well
  revenue(
    name: TrackingEvent,
    entities: RevenueEntities,
    props: Record<string, unknown> = {}
  ) {
    const data = this.event(name, entities, props)
    const revenue = new amplitude.Revenue()
    revenue
      .setPrice(entities.plan.todayTotal.numeric)
      .setProductId(entities.pkg.clubreadyId)
      .setRevenueType(entities.pkg.isRecurring ? "recurring" : "non-recurring")
      .setEventProperties(data)
    this.amp.logRevenueV2(revenue)
    this.logMessage("revenue", revenue)

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
          value: entities.plan.todayTotal.numeric,
          tax: entities.plan.todayTax.numeric,
          currency: "USD",
          coupon: data.purchasePromoCode,
          items: [
          {
            item_id: entities.pkg.clubreadyId,
            item_name: entities.pkg.name,
            location_id: this.store.locStore.currentLocationId,
            price: entities.pkg.price.numeric,
            quantity: 1
          }]
      }
    });
  }

  locationChange(locationId: string) {
    Sentry.configureScope(scope => scope.setTag("current_location", locationId))
  }

  identify(session: UserSession) {
    Sentry.configureScope(scope =>
      scope.setUser({
        email: session.email,
        id: session.clubreadyId,
        location: session.locationId,
      })
    )

    if (process.env.NODE_ENV !== "development") {
      window._hsq.push(["identify", { email: session.email }])
      window._hsq.push(["trackEvent", { id: "Identified" }])
    }

    this.logMessage("identify", session.email, session.clubreadyId)

    this.setUserProps({ session })
    this.amp.setUserId(session.clubreadyId)
  }

  setUserProps({
    session,
    profile,
  }: {
    session?: UserSession
    profile?: UserProfile
  }) {
    const props = {
      ...(session ? sessionSchema(session) : {}),
      ...(profile ? profileSchema(profile) : {}),
    }

    this.amp.setUserProperties(props)
    this.logMessage("setUserProperties", props)
  }

  private logMessage(name: string, ...props: unknown[]) {
    switch (window.globals.trackingLogLevel) {
      case "debug":
        console.debug(name, ...props)
        break
      case "log":
      case "info":
        console.log(name, ...props)
    }
  }
}
