import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosError } from "axios"
import { computed, observable, action } from "mobx"
import sortBy from "lodash/sortBy"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import BrandStore from "stores/BrandStore"
import ScheduleEntry from "models/ScheduleEntry"
import Booking from "models/Booking"
import ServiceBooking from "apps/book/models/ServiceBooking"
import LocationSummary from "models/LocationSummary"
import Package from "models/Package"
import { UserSession } from "models/UserSession"

type Response = {
  schedule_entries: ScheduleEntry[]
  bookings: Booking[]
  packages: Package[]
  service_bookings: ServiceBooking[]
  user: UserSession
}

export default class DashboardApiStore extends APIStore {
  @observable entries: ScheduleEntry[] = []
  @observable bookings: Booking[] = []
  @observable serviceBookings: ServiceBooking[] = []
  @observable packages: Package[] = []
  @observable user?: UserSession

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("schedule_entries", ScheduleEntry),
    DeserializeMiddleware("bookings", Booking),
    DeserializeMiddleware("packages", Package),
    DeserializeMiddleware("service_bookings", ServiceBooking),
    DeserializeMiddleware("user", UserSession),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  // don't want this to be computed, as we always want the location
  // that the store was initialized with
  location: LocationSummary

  @computed
  get nextBooking() {
    const allBookings = [...this.bookings, ...this.serviceBookings]
    const sortedBookings = sortBy(allBookings, b => b.scheduleEntry.startsAt)
    return sortedBookings.length > 0 ? sortedBookings[0] : undefined
  }

  constructor(protected brandStore: BrandStore) {
    super()
    this.location = brandStore.locStore.currentLocation!
  }

  fetch() {
    let params = {}
    return this.api.get(`/api/v2/locations/${this.location.id}/dashboard`, {
      params: params,
    })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response>) {
    this.entries = res.data.schedule_entries.sort(
      (entryA: ScheduleEntry, entryB: ScheduleEntry) => {
        if (entryA.startsAt.unix() < entryB.startsAt.unix()) {
          return -1
        }
        if (entryA.startsAt.unix() > entryB.startsAt.unix()) {
          return 1
        }
        return 0
      }
    )
    this.bookings = res.data.bookings.filter(b => b.isBooked)
    for (const booking of this.bookings) {
      booking.scheduleEntry.location = this.location
    }

    this.serviceBookings = res.data.service_bookings
      .filter(b => b.isBooked)
      .map(booking => {
        booking.location = this.location
        return booking
      })

    this.packages = res.data.packages
    this.user = res.data.user
    this.pairBookings()

    return res
  }

  @action.bound
  protected pairBookings() {
    for (const entry of this.entries) {
      const booking = this.bookings.find(
        b => b.scheduleEntry.clubreadyId === entry.clubreadyId
      )
      entry.booking = booking
    }
  }
}
