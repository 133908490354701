import * as React from "react"
import LocationSummary from "models/LocationSummary"
import { BrandCopy, baseCopy } from "themes/base"
import SubMenu from "components/SubMenu"

interface Props {
  copy: BrandCopy
  locationSummary: LocationSummary
}

const BookingScheduleMenu: React.SFC<Props> = ({ copy, locationSummary }) => {
  const links = [
    {
      children: copy.oneOnOneSessions,
      to: `/book/${locationSummary.id}/services`,
    },
    {
      children: copy.groupClasses,
      to: `/book/${locationSummary.id}`,
      exact: true,
    },
  ]

  return <SubMenu className="row mb-4" linkClassName="h5" links={links} />
}

export default BookingScheduleMenu
