import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import UserStore from "stores/UserStore"
import Deal from "models/xpass/Deal"

interface Response {
  deals: Deal[]
}

export default class DealStore extends APIStore {
  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("deals", Deal),
    TokenAuthMiddleware(this.userStore),
  ])

  @observable deals?: Deal[]
  @observable isLoadingDeals: boolean = false

  constructor(protected userStore: UserStore) {
    super()
  }

  @action
  fetch() {
    this.isLoadingDeals = true
    return this.api.get(`/api/xpass/v3/deals/user_deals`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response>) {
      this.deals = res.data.deals
      this.isLoadingDeals = false
    return res
  }
}