import BaseStore from "stores/BaseStore"
import { observable, action } from "mobx"

interface GenericScheduleStore {
  firstEntryId?: string
}

/**
 * Make sure the top schedule entry on a schedule page is opened
 * after each filter change
 */
export default class ExpandedEntryStore extends BaseStore {
  @observable expandedEntryId: string = ""

  constructor(private scheduleStore: GenericScheduleStore) {
    super()
  }

  @action.bound
  toggleExpandedEntry(id: string) {
    this.expandedEntryId = this.expandedEntryId === id ? "" : id
  }

  @action.bound
  openFirst() {
    this.expandedEntryId = this.scheduleStore.firstEntryId || ""
  }
}
