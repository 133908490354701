import * as React from "react"
import { observer, inject } from "mobx-react"
import { RouteComponentProps, Redirect, Switch, Route } from "react-router"

import LocationSummary from "models/LocationSummary"
import RestrictedRoute from "components/wrappers/RestrictedRoute"
import OfferAuthController from "apps/buy/components/OfferAuthController"
import APILoader from "components/wrappers/APILoader"
import OfferStore from "stores/OfferStore"
import PaymentPage from "apps/buy/components/PaymentPage"
import BrandStore from "stores/BrandStore"
import BackToBrandSite from "apps/auth/components/BackToBrandSite"
import { Link } from "react-router-dom"
import ErrorPage from "components/ErrorPage"
import { computed } from "mobx"

// http://testbrand.xpo:3000/offers/testbrand-st-louis/free-credit

type InnerProps = RouteComponentProps<{
  locationId: string
  // exactly one of these is required, not both
  offerId: string
  offerSlot: string
}> & {
  locationSummary: LocationSummary
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
class OfferControllerInner extends React.Component<InnerProps, {}> {
  offerStore = new OfferStore(
    {
      type: this.props.match.params.offerId ? "id" : "slot",
      id: this.props.match.params.offerId || this.props.match.params.offerSlot,
    },
    this.props.locationSummary
  )

  @computed get onSlotPage() {
    return !!this.props.match.params.offerSlot
  }

  componentWillMount() {
    this.props.store!.uiStore.hideNavLinks()
    this.filterLocationPicker()
  }

  // when we nav from one location to another, the old
  // component can clear filters _after_ the new one sets them
  componentDidMount() {
    // TODO: need to also let the map modal know not to show these locations
    this.filterLocationPicker()
  }

  componentWillUnmount() {
    this.props.store!.uiStore.showNavLinks()
    this.props.store!.uiStore.unlockLocationPicker()
    this.props.store!.locationPickerStore.clearFilter()
  }

  /**
   * This lets us only show locations that have an offer in the slot we're looking at
   * but the timing is ugly b/c the logic isn't tied to the state of the picker store
   */
  filterLocationPicker() {
    const { offerSlot } = this.props.match.params
    if (offerSlot) {
      this.props.store!.uiStore.unlockLocationPicker()
      this.props.store!.locationPickerStore.setFilter(
        location => (location.promoSlots || []).indexOf(offerSlot) !== -1
      )
    } else {
      this.props.store!.uiStore.lockLocationPicker()
    }
  }

  public render() {
    const url = this.props.match.url
    return (
      <>
        <BackToBrandSite />
        <APILoader
          apiStore={this.offerStore}
          alreadyLoaded
          renderError={() => (
            // TODO: Should probably put a location picker in here.
            <ErrorPage
              title="Can't redeem offer"
              message={`The offer you chose is not available at ${
                this.props.store!.brand.name
                } ${
                this.props.locationSummary.name
                }. Head back to check for other offers!`}
            />
          )}
          render={({ apiStore: { offer } }) => (
            <Switch>
              <RestrictedRoute
                publicOnly
                path={`${url}/auth`}
                roadblockPath={url}
                render={props => (
                  <OfferAuthController
                    {...props}
                    offer={offer!}
                    onSlotPage={this.onSlotPage}
                  />
                )}
              />
              <RestrictedRoute
                exact
                path={`${url}`}
                roadblockPath={`${url}/auth`}
                render={() => {
                  if (offer!.packageId) {
                    return (
                      <PaymentPage
                        locationSummary={this.props.locationSummary}
                        packageId={offer!.packageId!}
                        offer={offer}
                      />
                    )
                  } else if (offer!.classCategory) {
                    return (
                      <Redirect
                        push
                        to={{
                          pathname: `/book/${
                            this.props.locationSummary.id
                            }/class/${offer!.classCategory.id}`,
                          state: { [offer!.id]: offer },
                          search: `?offer=${offer!.id}`,
                        }}
                      />
                    )
                  } else {
                    return (
                      <>
                        <h2 className="my-3">Thanks!</h2>
                        <p>
                          Thank you for your interest! A representative will be
                          in touch shortly.
                        </p>
                        <Link className="btn btn-primary" to="/">
                          My Dashboard
                        </Link>
                      </>
                    )
                  }
                }}
              />
            </Switch>
          )}
        />
      </>
    )
  }
}

type OuterProps = RouteComponentProps<{ locationId?: string }> & {
  locationSummary: LocationSummary
}

const OfferController: React.SFC<OuterProps> = lrProps => {
  // NOTE: order matters here b/c the first path is more specific
  const paths = [
    `/offers/slot/:offerSlot/${lrProps.match.params.locationId}`,
    `/offers/${lrProps.match.params.locationId}/:offerId`,
  ]

  return (
    <Route
      path={paths}
      render={props => {
        return <OfferControllerInner {...lrProps} {...props} />
      }}
    />
  )
}

export default observer(OfferController)
