import { JsonObject, JsonProperty } from "json2typescript"
import PackagePlanPayment from "models/PackagePlanPayment"
import Price from "models/Price"

@JsonObject("PackagePlan")
export default class PackagePlan {
  @JsonProperty("today_subtotal", Price)
  todaySubtotal: Price = undefined!

  /**
   * What the upfront cost of the plan is. May make sense to also return a
   * number of boolean or something in case there's special behavior when there
   * is vs. isn't an upfront payment required.
   *
   */
  @JsonProperty("today_total", Price)
  todayTotal: Price = undefined!

  @JsonProperty("today_tax", Price)
  todayTax: Price = undefined!

  @JsonProperty("lifetime_package_price", Price)
  lifetimePackagePrice: Price = undefined!

  /**
   * TODO: we'll need to return fees associated with the package.
   */
  @JsonProperty("fees", [PackagePlanPayment])
  fees: PackagePlanPayment[] = []

  @JsonProperty("payments", [PackagePlanPayment])
  payments: PackagePlanPayment[] = undefined!

  /**
   * URL of an html version of the membership agreement for the parent package at this location.
   */
  @JsonProperty("contract_url", String, true)
  contractUrl?: string = undefined!
}
