import * as React from "react"
import { inject, observer } from "mobx-react"
import { observable } from "mobx"
import * as cx from "classnames"

import BrandStore from "stores/BrandStore"
import PaymentSourcesStore from "apps/account/stores/PaymentSourcesStore"
import PaymentSource from "models/PaymentSource"
import APILoader from "components/wrappers/APILoader"
import PaymentPreviewPanel from "apps/account/components/PaymentPreviewPanel"

import BillingForm from "apps/account/components/BillingForm"
import ACHForm from "apps/account/components/ACHForm"
import PaymentSourceUpdateStore from "apps/account/stores/PaymentSourceUpdateStore"
import ExternalLinkIcon from "images/icons/external-link-arrow-square-up-right-white.svg"

export interface Props {
  store?: BrandStore
  className?: string
  small?: boolean
  isPaymentPage?: boolean // CR checkout
  isBillingPage?: boolean // Account billing details
  paymentSourcesStore?: PaymentSourcesStore
  paymentSourceUpdateStore?: PaymentSourceUpdateStore
}

// The idea is that this thing can work on both the account page and inline
// when buying a package.

@inject((store: BrandStore) => ({ store }))
@observer
export default class PaymentSourcePanel extends React.Component<Props, {}> {
  paymentSourcesStore =
    this.props.paymentSourcesStore || new PaymentSourcesStore(this.props.store!)
  paymentSourceUpdateStore =
    this.props.paymentSourceUpdateStore ||
    new PaymentSourceUpdateStore(this.paymentSourcesStore)

  @observable formShown = false
  @observable paymentType?: string
  @observable paymentSource?: PaymentSource
  @observable modalStatus?: { status: string, message?: string }
  @observable modalOpen = false

  handleToggleFormClick = () => {
    this.formShown = !this.formShown
    this.modalOpen = false
  }

  handleForm = (
    e: React.MouseEvent,
    paymentType: string,
    paymentSource: PaymentSource
  ) => {
    this.paymentType = paymentType
    this.paymentSource = paymentSource
  }

  setModal = (status: string, message: string) => {
    this.modalStatus = { status, message }
    this.modalOpen = true
    document.body.classList.add("has-fixed-modal")
  }

  closeModal = () => {
    this.modalOpen = false
    document.body.classList.remove("has-fixed-modal")
  }

  renderPaymentForm = (paymentType?: string) => {
    switch (paymentType) {
      case "ach":
        return (
          <ACHForm
            paymentSourceUpdateStore={this.paymentSourceUpdateStore}
            paymentSourcesStore={this.paymentSourcesStore}
            paymentSource={this.paymentSource}
            onCancel={this.handleToggleFormClick}
            setModal={this.setModal}
            closeModal={this.closeModal}
            modalStatus={this.modalStatus}
            modalOpen={this.modalOpen}
            isPaymentPage={this.props.isPaymentPage || false}
          />
        )
      case "card":
        return (
          <BillingForm
            paymentSourceUpdateStore={this.paymentSourceUpdateStore}
            paymentSourcesStore={this.paymentSourcesStore}
            paymentSource={this.paymentSource}
            onCancel={this.handleToggleFormClick}
            isPaymentPage={this.props.isPaymentPage || false}
          />
        )
      default:
        return <></>
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <APILoader
          apiStore={this.paymentSourcesStore}
          render={() => {
            if (this.formShown) {
              const status = this.paymentSource ? "Update " : "Add "
              const paymentTypeName =
                this.paymentType === "card"
                  ? "Credit/Debit Card"
                  : "Bank Account"

              return (
                <React.Fragment>
                  <h3>
                    {status}
                    {paymentTypeName}
                  </h3>
                  <div className="row">
                    {this.renderPaymentForm(this.paymentType)}
                  </div>
                </React.Fragment>
              )
            }
            return (
              <div className="row">
                <div
                  className={cx(
                    this.props.small ? "col-lg-8" : "col-md-7"
                  )}
                >
                  {this.props.isPaymentPage || this.props.isBillingPage ? (
                    <>
                      <PaymentPreviewPanel
                        paymentSources={
                          this.paymentSourcesStore.hasPaymentSource
                            ? this.paymentSourcesStore.paymentSources
                            : undefined
                        }
                        paymentSource
                        onClick={this.handleToggleFormClick}
                        handleForm={this.handleForm}
                        isPaymentPage={this.props.isPaymentPage || false}
                        store={this.props.store!}
                      />
                      {this.props.store && !this.props.store.isXponential && this.props.store.settings.showGiftCardBalanceLink && (
                        <div className="mb-5">
                          <h3 className="text-left mb-2">Have a gift card?</h3>
                          <p className="text-left">
                            Use the button below to open a new browser window to
                            check your balance.
                          </p>
                          <a
                            href="https://getyourbalance.com/"
                            target="_blank"
                            className="btn btn-primary align-self-center"
                          >
                            Check your balance
                            <img src={ExternalLinkIcon} className="pl-2" />
                          </a>
                        </div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )
          }}
        />
      </div>
    )
  }
}
