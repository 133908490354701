import React from "react"
import cx from "classnames"
import { inject, observer } from "mobx-react"
import capitalize from "lodash/capitalize"

import BrandStore from "stores/BrandStore"
import { Link } from "react-router-dom"
import APILoader from "components/wrappers/APILoader"

import CyclingStatsStore from "apps/history/stores/CyclingStatsStore"
import RowingStatsStore from "apps/history/stores/RowingStatsStore"
import HeartRateStatsStore from "apps/history/stores/HeartRateStatsStore"
import { Badge, BrandData } from "themes/brands"
import { Metric } from "apps/history/models/Stats"
import SummaryStat from "apps/history/components/SummaryStat"
import BadgeProgressPanel from "apps/history/components/BadgeProgressPanel"
import StatsStore from "apps/history/stores/StatsStore"
import WorkoutStatsStore from "apps/history/stores/WorkoutStatsStore"

interface Props {
  className?: string
  store?: BrandStore
}

interface PerfMetricProps {
  metric: Metric
  values: number[]
}

// TODO: this could still use a bunch of cleanup.
// get the badging logic out of here and into a store or service
// etc
@inject((store: BrandStore) => ({ store }))
@observer
export default class PerformanceCard extends React.Component<Props> {
  performanceStore: StatsStore = this.props.store!.userStore.performanceStore!

  // too cute :/
  // also some of this should probably be moved in the brand data
  // specifically the badge metric stuff
  getMetrics() {
    const brandData = this.props.store!.copy
    const perfStore = this.performanceStore

    if (perfStore instanceof CyclingStatsStore) {
      const lastClass = perfStore.classStats![0]

      const metrics = ["avgPower", "calories", "totalEnergy"].map(key => ({
        metric: perfStore.getClassMetric(key),
        values: [lastClass[key]],
      }))

      const rankMetric = {
        metric: perfStore.getClassMetric("rank"),
        label: "Class Rank",
        values: [lastClass.rank],
      }
      if (
        lastClass.scheduleEntry.totalSpots != null &&
        lastClass.scheduleEntry.freeSpots != null
      ) {
        rankMetric.values.push(
          lastClass.scheduleEntry.totalSpots - lastClass.scheduleEntry.freeSpots
        )
      }
      metrics.unshift(rankMetric)

      return { metrics, progress: perfStore.attendanceCount }
    } else if (perfStore instanceof RowingStatsStore) {
      const lastClass = perfStore.classStats![0]

      const metrics = ["highPace", "avgPace", "distance"].map(key => {
        const metric = perfStore.getClassMetric(key)
        return {
          metric,
          values: [lastClass[key]],
        }
      })

      const distanceAgg = this.performanceStore.getAgg("distance")
      const progress = distanceAgg.sum

      const distanceMetric = {
        metric: { ...distanceAgg.metric, label: "Total Meters" },
        values: [progress],
      }
      metrics.unshift(distanceMetric)

      return { metrics, progress }
    } else if (perfStore instanceof HeartRateStatsStore) {
      const lastClass = perfStore.classStats![0]

      const metrics = ["calories", "iqPoints", "avgHr", "highHr"].map(key => {
        const metric = perfStore.getClassMetric(key)
        return {
          metric,
          values: [lastClass[key]],
        }
      })

      return { metrics, progress: perfStore.attendanceCount }
    } else if (perfStore instanceof WorkoutStatsStore) {
      const lastClass = perfStore.classStats![0]

      switch(this.props.store!.brandId) {
        case "cyclebar":
        case "cyclebar_aus":
          const metricsCB = ["avgPower", "calories", "totalEnergy"].map(key => ({
            metric: perfStore.getClassMetric(key),
            values: [lastClass[key]],
          }))

          const rankMetric = {
            metric: perfStore.getClassMetric("rank"),
            label: "Class Rank",
            values: [lastClass.rank],
          }
          if (
            lastClass.scheduleEntry.totalSpots != null &&
            lastClass.scheduleEntry.freeSpots != null
          ) {
            rankMetric.values.push(
              lastClass.scheduleEntry.totalSpots - lastClass.scheduleEntry.freeSpots
            )
          }
          metricsCB.unshift(rankMetric)

          return { metrics: metricsCB, progress: perfStore.attendanceCount }
        case "rowhouse":
          const metricsRH = ["highPace", "avgPace", "distance"].map(key => {
            const metric = perfStore.getClassMetric(key)
            return {
              metric,
              values: [lastClass[key]],
            }
          })

          const distanceSummary = this.performanceStore.getSummaryStat("distance")
          const distanceMetric = {
            metric: { ...distanceSummary.metric, label: "Total Meters" },
            values: [distanceSummary.value],
          }
          metricsRH.unshift(distanceMetric)

          return { metrics: metricsRH, progress: distanceSummary.value }
        default:
          const metrics = ["calories", "iqPoints", "avgHr", "highHr"].map(key => {
            const metric = perfStore.getClassMetric(key)
            return {
              metric,
              values: [lastClass[key]],
            }
          })

          return { metrics, progress: perfStore.attendanceCount }
      }
    } else {
      // should not be needed
      return undefined
    }
  }

  handleHeaderClick = (_: LinkEvent) => {
    this.props.store!.track.event("home_tap view performance")
  }

  render() {
    const brandData = this.props.store!.copy
    return (
      <div className={cx("card", this.props.className)}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="text-dark">
            {`My Last ${capitalize(brandData.class)}`}{" "}
          </h5>
          <small>
            <Link to={`/history/performance`} onClick={this.handleHeaderClick}>
              View All
            </Link>
          </small>
        </div>
        <APILoader
          apiStore={this.performanceStore}
          elementSize="element"
          render={() => {
            const { classStats } = this.performanceStore
            const lastClass = classStats![0]
            if (!lastClass) {
              return (
                <div className="card-body flex-grow-1 d-flex flex-column align-content-center">
                  <h4>Book Your First {capitalize(brandData.class)}</h4>
                  {/* <p className="my-auto">
                    {`We are working on pulling some information here. In the meantime book a ${brandData.class}!`}
                  </p> */}
                  <Link className="btn btn-primary mt-auto" to="/book">
                    Book a {capitalize(brandData.class)}
                  </Link>
                </div>
              )
            }

            const metricsData = this.getMetrics()
            if (!metricsData || !metricsData.progress == null) {
              return (
                <div className="card-body d-flex justify-content-center align-items-center">
                  No data available
                </div>
              )
            }

            return (
              <div className="card-body">
                <InnerCard
                  metrics={metricsData.metrics}
                  progress={metricsData.progress}
                />
              </div>
            )
          }}
        />
      </div>
    )
  }
}

interface InnerCardProps {
  metrics: PerfMetricProps[]
  progress: number
}

const InnerCard: React.FunctionComponent<InnerCardProps> = ({ metrics, progress }) => {
  return (
    <div className="row">
      <div className="col-8 border-right">
        <SummaryStat
          metric={metrics[0].metric}
          label={metrics[0].metric.label + ": "}
          statWrapperClassName="mb-3"
          labelClassName="h5 mb-0 d-inline"
          valueClassName="h5 text-primary d-inline"
          hideIcon
          values={metrics[0].values}
        />
        <BadgeProgressPanel
          progress={progress}
          className="h5 mb-3"
          progressBarClassName="mr-4"
        />
      </div>

      <div className="col-4 pr-1">
        {metrics.slice(1).map(metric => (
          <SummaryStat
            key={metric.metric.key}
            metric={metric.metric}
            className="mb-3"
            labelClassName="card-metric-label h5 mb-0"
            valueClassName="h5 text-muted"
            values={metric.values}
            hideIcon
          />
        ))}
      </div>
    </div>
  )
}
