import * as React from "react"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import cx from "classnames"

import Heading from "components/Heading"
import { Link } from "react-router-dom"
import { LocationDescriptor } from "history"

interface Props {
  title?: React.ReactNode
  message?: React.ReactNode
  code?: string
  linkTitle?: React.ReactNode
  linkTo?: LocationDescriptor
  link?: React.ReactNode
}

const ErrorPage: React.SFC<Props> = ({
  title,
  message,
  code,
  linkTitle,
  linkTo,
  link,
}) => (
  <div className="col-sm-6">
    <Heading className="h2">{title}</Heading>
    {code && (
      <p>
        <strong>{code}</strong>
      </p>
    )}

    <p>{message}</p>
    <p>
      {linkTitle && linkTo && (
        <Link to={linkTo} className="btn btn-primary">
          {linkTitle}
        </Link>
      )}{" "}
      {link}
    </p>
  </div>
)

ErrorPage.defaultProps = {
  title: "An Error Occurred",
  message: "We apologize for the inconvenience",
}

export default ErrorPage
