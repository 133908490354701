import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"
import getIn from "lodash/get"
import APIStore from "stores/APIStore"
import UIStore from "stores/UIStore"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import PackagePlan from "models/PackagePlan"
import Offer from "models/Offer"
import Package from "models/Package"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import BrandStore from "stores/BrandStore"
import { isApiError, withAxiosError } from "helpers/errorHandling"

export interface Response {
  plan: PackagePlan
  package: Package
}

export default class PlanV2Store extends APIStore {
  @observable plan?: PackagePlan
  @observable package?: Package
  @observable promoCode?: string

  @observable errorMessage?: string
  @observable errorCode?: string
  @observable errorPromo?: string

  @observable originalPlan?: PackagePlan

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    TokenAuthMiddleware(this.brandStore.userStore),
    DeserializeMiddleware("plan", PackagePlan),
    DeserializeMiddleware("package", Package),
  ])

  @computed get discountApplied() {
    return (
      !!this.promoCode &&
      !!this.plan &&
      !!this.originalPlan &&
      this.originalPlan.todayTotal.numeric > this.plan.todayTotal.numeric
    )
  }

  @computed get originalPrice() {
    if (!this.discountApplied) return undefined

    return this.originalPlan!.todayTotal
  }

  @computed get discount() {
    if (!this.discountApplied) return 0

    return this.originalPrice!.numeric - this.plan!.todayTotal.numeric
  }

  constructor(
    public brandStore: BrandStore,
    public packageId: string,
  ) {
    super()
  }

  fetch() {
    const url = `/api/xpass/package_details/${this.packageId}`

    return this.api
      .get(
        url,
        { params: { promo_code: this.promoCode } }
      )
  }

  updateCode(promoCode?: string) {
    this.promoCode = promoCode || ''
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response>) {
    this.plan = res.data.plan
    this.package = res.data.package
    if (this.promoCode) this.brandStore.track.event("promo code_success")
    if (!this.originalPlan) this.originalPlan = res.data.plan

    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    const res = withAxiosError(ex)
    if (isApiError(res) && res.data.code === "invalid_code") {
      this.errorCode = res.data.code
      this.errorMessage = res.data.message
      this.errorPromo = this.promoCode
      this.updateCode()
      this.setStatus("error")
      console.error(`log error - ${this.errorMessage}`, res)
      this.brandStore.uiStore.openModal({
        type: "error",
        message: "Invalid Promo Code",
        modalShow: true,
      })
      this.brandStore.track.event("promo code_failure")
    } else if (res) {
      this.errorCode = res.data.code
      this.errorMessage = res.data.message
      throw ex
    }
  }

  respondIneligible() {
    this.errorCode = "ineligible_specific"
    this.errorMessage = `An account is required to proceed.`
    this.status = "error"
    return Promise.resolve()
  }
}
