import getIn from "lodash/get"
import capitalize from "lodash/capitalize"
import BaseStore from "stores/BaseStore"
import RoutingStore from "stores/RoutingStore"
import UserStore from "stores/UserStore"
import LocationsStore from "stores/LocationsStore"
import UIStore from "stores/UIStore"
import ClassTypesStore from "stores/ClassTypesStore"
import CurrentLocationStore from "stores/CurrentLocationStore"
import AuthStore from "apps/auth/stores/AuthStore"
import LocationPickerStore from "apps/location_finder/stores/LocationPickerStore"

import Brand from "models/Brand"
import { brands } from "themes/brands"
import Tracker from "services/Tracker"
import { saveState, loadState, clearState } from "services/savedState"

// Basically everything happens in the context of a particular brand, so
// this is the root store that holds all the other stores.
export default class BrandStore extends BaseStore {
  brandId = this.brand.id
  brandData = brands[this.brandId]
  settings = this.brandData.settings
  copy = {
    ...this.brandData.copy,
    capitalizedCredit: capitalize(this.brandData.copy.credit),
    capitalizedCredits: capitalize(this.brandData.copy.credits),
  }
  styles = this.brandData.styles
  styleClasses = this.brandData.styleClasses
  dashboardCards = this.brandData.dashboardCards
  badges = this.brandData.badges
  metrics = this.brandData.metrics
  summaryMetrics = this.brandData.summaryMetrics
  heartRateZoneColors = this.brandData.heartRateZoneColors
  isXponential = this.brand.id === 'xponential'

  // ALL the circular dependencies
  track = new Tracker(this)
  locationsStore = new LocationsStore(this)
  userStore = new UserStore(this)
  locStore = new CurrentLocationStore(this.locationsStore, this.userStore)
  authStore = new AuthStore(this.userStore)
  classTypesStore = new ClassTypesStore(this)
  locationPickerStore = new LocationPickerStore(this)

  constructor(
    public brand: Brand,
    public routingStore: RoutingStore,
    public uiStore: UIStore
  ) {
    super()
    this.track.start()
    this.stashReferralTypeId()
  }

  get referralTypeId(): string {
    return loadState("referralTypeId", true)
  }

  vodPurchaseFlowEnabled = () => {
    return Boolean(
      this.brand.allowVodOnly &&
        this.locStore.currentLocation &&
        this.locStore.currentLocation.vodStudio
    )
  }

  stashReferralTypeId() {
    if (this.routingStore.referralTypeId) {
      saveState("referralTypeId", this.routingStore.referralTypeId, true)
    }
  }

  clearReferralTypeId() {
    if (this.referralTypeId) clearState("referralTypeId", true)
  }

  nextBadgeInfo = (progress: number) => {
    const badge = this.badges.find(b => b.value >= progress)

    if (!badge) return undefined

    const progressPercent = Math.round((100 * progress) / badge.value)
    return {
      progress,
      progressPercent,
      badge,
      chartColor: this.styles.chartColor,
      formatter: this.styles.badgeFormatter,
    }
  }

  eywAffiliateLink = (isLink?: boolean) => {
    const affiliateLinks = this.brandData.eywAffiliateLinks
    let countryCode = getIn(this.userStore.homeLocation, 'countryCode')
    if (isLink) {
      countryCode = getIn(
        this.locationsStore.locations.find(loc => loc.id === this.locStore.currentLocationId),
        'countryCode',
        'US'
      )
    }

    if (!affiliateLinks || !countryCode) return

    return getIn(affiliateLinks, `${countryCode}.eyw_affiliate_link`)
  }

  eywReferralTypeId = () => {
    const affiliateLinks = this.brandData.eywAffiliateLinks
    const countryCode = getIn(this.userStore.homeLocation, 'countryCode')

    if (!affiliateLinks || !countryCode) return this.referralTypeId

    return getIn(affiliateLinks, `${countryCode}.clubready_referral_type_id`)
  }

  isServiceLocation = (locationId: string) => {
    return locationId.includes("stretchlab")
  }
}
