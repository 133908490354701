import * as React from "react"
import FormControl from "components/forms/FormControl"

type SafeSelect = Omit<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  "name" | "children"
>

interface Props extends SafeSelect {
  name: string
  className?: string
  label?: string
}

const FormSelect: React.SFC<Props> = ({
  name,
  className,
  label,
  children,
  ...inputProps
}) => {
  return (
    <FormControl className={className} label={label} name={name}>
      {({ id, className: innerClassName, field, form }) => (
        <select
          {...field}
          {...inputProps}
          disabled={inputProps.disabled || form.isSubmitting}
          id={id}
          className={innerClassName}
          children={children}
        />
      )}
    </FormControl>
  )
}

export default FormSelect
