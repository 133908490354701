import mapKeys from "lodash/mapKeys"
import snakeCase from "lodash/snakeCase"
import camelCase from "lodash/camelCase"

export function jsToRuby(obj: Record<string, unknown>) {
  return mapKeys(obj, (_, k) => snakeCase(k))
}

export function rubyToJs(obj: Record<string, unknown>) {
  return mapKeys(obj, (_, k) => camelCase(k))
}
