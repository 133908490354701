import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("ClassCategorySummary")
export default class ClassCategorySummary {
  /**
   * Globally unique ID of the class category if it exists. It usually should.
   */
  @JsonProperty("id", String, true)
  id?: string = undefined

  /**
   * The name of the category of the class.
   */
  @JsonProperty("name", String)
  name: string = undefined!
}
