import * as React from "react"
import { action, observable } from "mobx"
import { observer, inject } from "mobx-react"
import { RouteComponentProps, Switch, Route } from "react-router"
import BrandStore from "stores/BrandStore"
import PaymentPageNoPasswordSPC from "./PaymentPageNoPasswordSPC"
import SetPasswordSPC from "./SetPasswordSPC"
import ClaimAccountSPC from "./ClaimAccountSPC"
import PaymentSourcesStore from "apps/account/stores/PaymentSourcesStore"

export interface Props extends RouteComponentProps {
  store?: BrandStore
  isSimpleCheckout?: boolean
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class PurchaseFlowV3Controller extends React.Component<Props> {
  @observable isExistingUser: boolean = false

  componentDidMount() {
     this.props.store!.uiStore.hideNavLinks()
     this.props.store!.uiStore.lockLocationPicker()
  }

  componentWillUnmount() {
     this.props.store!.uiStore.showNavLinks()
     this.props.store!.uiStore.unlockLocationPicker()
  }

  public render() {
    const path = this.props.match.path
    const isLoggedIn = this.props.store!.userStore!.isLoggedIn

    const setIsExistingUser = (value: boolean) => {
      this.isExistingUser = value
    }

    const renderPaymentPage = (paymentPageProps: any) => {
      const packageId = paymentPageProps.match.params.packageId
      return (
        <PaymentPageNoPasswordSPC
          packageId={packageId}
          setIsExistingUser={setIsExistingUser}
          isExistingUser={this.isExistingUser}
        />
      )
    }

    return (
      <>
        <Switch>
          <Route path={'/simple-checkout/password'} exact render={() => <SetPasswordSPC store={this.props.store!} />} />
          <Route path={'/simple-checkout/claim-account'} exact render={() => <ClaimAccountSPC store={this.props.store!} />} />
          <Route path={'/simple-checkout'} exact render={renderPaymentPage} />
          <Route path={`${path}/:packageId`} exact render={renderPaymentPage} />
        </Switch>
      </>
    )
  }
}
