import { observable, action, computed } from "mobx"
import startCase from "lodash/startCase"
import {
  History,
  Location,
  LocationState,
  LocationDescriptorObject,
} from "history"
import { parse, stringify, QueryString } from "helpers/queryString"
import { PageName, pageTitles } from "models/TrackingSchemas"

interface PageData {
  name?: PageName
  title?: string
  extra?: {}
}

const baseTitle = document.title

export default class RoutingStore {
  @observable location: Location
  @observable isInLocationRoute: boolean = false

  // logical current page, for tracking
  @observable pageName?: PageName
  @observable pageExtra?: {}

  @computed
  get query() {
    return parse(this.location.search) as QueryString
  }

  @computed
  get referralTypeId() {
    return this.query && this.query.clubready_referral_type_id
  }

  constructor(public history: History) {
    this.location = history.location
    history.listen(this.handleLocationChange)
    // this.pageView(true)
  }

  push = (path: string, state?: LocationState) => this.history.push(path, state)
  replace = (path: string) => this.history.replace(path)
  go = (n: number) => this.history.go(n)
  goBack = () => this.history.goBack()
  goForward = () => this.history.goForward()

  setQuery(q: QueryString, loc: LocationDescriptorObject = {}) {
    this.history.push({ ...loc, search: stringify(q) })
  }

  updateQuery(q: QueryString, loc: LocationDescriptorObject = {}) {
    this.setQuery({ ...this.query, ...q }, loc)
  }

  // this is a pure helper, doesn't change anything
  mergeQuery(q: QueryString, loc: LocationDescriptorObject = {}) {
    return { ...loc, search: stringify({ ...this.query, ...q }) }
  }

  @action
  setCurrentPage({ name, title, extra = {} }: PageData) {
    this.pageName = name
    this.pageExtra = extra
    if (!title && name) title = pageTitles[name] || startCase(name)
    title = title ? `${baseTitle} > ${title}` : baseTitle
    if (title !== document.title) document.title = title
  }

  @action
  handleLocationChange = (location: Location, locAction: string) => {
    // console.error("locChange", this.location.pathname, location.pathname)

    // pageview tracking is now handled via PackageTracker component
    //
    // if (locAction === "PUSH" && location.pathname !== this.location.pathname) {
    //   setTimeout(() => {
    //     Tracking.pageView()
    //     Tracking.ampPageView(location, this.location)
    //   })
    // }

    this.location = location
  }
}
