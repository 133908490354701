import React, { Component } from "react"
import { observer } from "mobx-react"
import BrandStore from "stores/BrandStore"

export interface Props {
  store?: BrandStore
}

@observer
export default class LoginInfoModal extends Component<Props> {
  redirectXpass = () => {
    window.location.href = window.globals.brandDomains["xponential"] + "/auth/login?redirect=true&brand_location_id=" + this.props.store!.locStore.currentLocation!.id
  }

  render() {
    return (
      <div className="xpass-login text-center">
        <div className="p-3">
          <h3 style={{ textTransform: "none" }}>About XPASS Login</h3>
          <p>
            XPASS Login allows you to link all studio accounts across 9
            Xponential Fitness brands, including:
          </p>
          <p>Club Pilates, CycleBar, Pure Barre, Rumble, StretchLab, and YogaSix.</p>
        </div>
        <button className="xpass-login-button" onClick={this.redirectXpass}>
          Get Started
        </button>
      </div>
    )
  }
}
