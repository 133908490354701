import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("Milestone")
export default class Milestone {
  @JsonProperty("title", String)
  title: string = undefined!

  @JsonProperty("description", String)
  description: string = undefined!

  @JsonProperty("goal", Number)
  goal: number = undefined!

  @JsonProperty("completed_within_days", Number, true)
  completedWithinDays: number = undefined!

  @JsonProperty("completed_within_months", Number, true)
  completedWithinMonths: number = undefined!

  @JsonProperty("prize", String, true)
  prize: string = undefined!

  @JsonProperty("prize_image", String, true)
  prizeImage: string = undefined!
}
