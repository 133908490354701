import React, { Component } from "react"
import cx from "classnames"
import LocationSummary from "models/LocationSummary"
import LocationListItem from "./LocationListItem"
import LocationFinderStore from "../stores/LocationFinderStore"
import BrandStore from "stores/BrandStore"
import { observer } from "mobx-react"
import { observable } from "mobx"

export interface LocationListProps {
  locations: LocationSummary[]
  onListItemClick: Function
  onLocationSelect: Function
  store: BrandStore
  locationFinderStore: LocationFinderStore
  hideActions?: boolean
}

@observer
export default class LocationList extends Component<LocationListProps> {
  listRef: React.RefObject<HTMLUListElement> = React.createRef()
  itemRefs: React.RefObject<LocationListItem>[] = []

  componentDidUpdate() {
    const { locations } = this.props
    const {
      highlightedLocationId,
      hoveredLocationId,
    } = this.props.locationFinderStore
    const hightlightedLocation = locations.find(
      ({ id }) => id === highlightedLocationId
    )
    if (hightlightedLocation) {
      this.focusOnLocation(hightlightedLocation)
    }

    const hoveredLocation = locations.find(({ id }) => id === hoveredLocationId)
    if (hoveredLocation) {
      this.focusOnLocation(hoveredLocation)
    }
  }

  onListItemClick = (location: LocationSummary) => {
    this.props.onListItemClick(location)
    this.focusOnLocation(location)
  }

  focusOnLocation = (location: LocationSummary) => {
    const locElement = this.itemRefs[location.id].current.elRef.current
    // this.listRef.current!.scrollTop = locElement.offsetTop     //TODO: Check if scrollIntoView is compatible cross-browser
    locElement.scrollIntoView({ behavior: "smooth" })
  }

  render() {
    // force rerender when these values change
    const {
      highlightedLocationId,
      hoveredLocationId,
    } = this.props.locationFinderStore

    this.props.locations.map(loc => (this.itemRefs[loc.id] = React.createRef()))

    return (
      <div className="pb-5 mb-5 p-lg-0 my-lg-0">
        {this.props.locations.length > 0 ? (
          <ul
            ref={this.listRef}
            className={cx(
              "col-md-12",
              "my-1",
              "p-0",
              "list-group",
              "float-in",
              "delay-5"
            )}
          >
            {this.props.locations.map(location => {
              return (
                <LocationListItem
                  location={location}
                  onListItemClick={this.onListItemClick}
                  onLocationSelect={this.props.onLocationSelect}
                  store={this.props.store}
                  locationFinderStore={this.props.locationFinderStore}
                  key={location.id}
                  ref={this.itemRefs[location.id]}
                  hideActions={this.props.hideActions || false}
                />
              )
            })}
          </ul>
        ) : (
          <h3 className="w-100 mt-5">
            We're sorry, we couldn't find any studios near you right now.
          </h3>
        )}
        {this.props.store.brand.externalLocationMapUrl && (
          <p className="w-100 my-3">
            {/* TODO: DYNAMIC COPY */}
            {/* should come from CMS */}
            See additional studios at{" "}
            <a
              href={this.props.store.brand.externalLocationMapUrl}
              target="_blank"
            >
              {this.props.store.brand.externalLocationMapUrl.replace(
                /^https?:\/\//,
                ""
              )}
            </a>
          </p>
        )}
      </div>
    )
  }
}
