import React, { Component } from "react"
import { RouteComponentProps, Redirect } from "react-router"
import cx from "classnames"
import * as yup from "yup"
import { InjectedFormikProps, withFormik, Form } from "formik"
import { Link } from "react-router-dom"

import BrandStore from "stores/BrandStore"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"
import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"
import FormAlert from "components/forms/FormAlert"
import SharedAccountControls from "apps/auth/components/SharedAccountControls"

interface FormValues {
  email: string
}

export interface Props extends RouteComponentProps {
  store?: BrandStore
  userAuthStore: UserAuthStore
}

class UserLookupFormInner extends Component<
  InjectedFormikProps<Props, FormValues>
> {
  handleClick = () => {
    this.props.history.goBack()
  }

  render() {
    const { touched, errors, isSubmitting, store, userAuthStore } = this.props
    const isAdditionalEmail = userAuthStore.isAdditional
    const base = "base"

    let title = store!.copy.createAccount
    let copy
    let buttonText = "Sign Up"

    // *** TODO: FOR PRE-LAUNCH ONLY. REMOVE THIS ONCE XPASS IS READY FOR LAUNCH ***
    // if (store!.isXponential) {
    //   return <h1 className="text-center">XPASS is not live yet. Please check back later.</h1>
    // }

    if (isAdditionalEmail) {
      title = "Look Up Additional Email"
      copy = (
        <p className="text-center">
          Do you have additional accounts at XPASS studios that you want to
          combine into one XPASS account? Enter the email below to verify.
        </p>
      )
      buttonText = "Look Up Accounts"
    }

    // Redirect user to registration form if email and brandLocationId params are present
    const { brandLocationId, brandLocationEmail, allowRedirect } = userAuthStore
    if (brandLocationId && brandLocationEmail) {
      const url = `${this.props.match.path}/register/account?brand_location_id=${brandLocationId}&email=${brandLocationEmail}&redirect=${allowRedirect}`
      return <Redirect to={url} />
    }

    return (
      <div className={cx(`mt-5`, {"mx-auto col-md-8 col-lg-6 col-xl-5 form-border": store!.isXponential})}>
        <h1 className="text-center">{title}</h1>
        <div className="col-md-6 mx-auto">{copy}</div>
        <Form
          noValidate
          className={cx(
            store!.isXponential ? "" : "col-md-8 col-lg-5",
            "mx-auto",
          )}
        >
          <FormAlert message={errors[base]} classNames="my-2" />
          <FormInput
            placeholder={store!.isXponential ? "Enter address" : "Email address"}
            label="Email Address*"
            name="email"
            type="email"
            maxLength={100}
          />
          <FormButtons
            submitText={buttonText}
            disabled={isSubmitting}
            centered
            className="float-in delay-4"
            minWidth={100}
          >
            {isAdditionalEmail ? (
              <div className="text-center mt-4">
                <small>
                  <span onClick={this.handleClick} className="link">
                    Don't claim accounts and continue
                  </span>
                </small>
              </div>
            ) : (
              <SharedAccountControls
                className="mt-3"
                brandText={store!.brand.name}
                currentPage="register"
                showForgotPassword={false}
              />
            )}
          </FormButtons>
        </Form>
      </div>
    )
  }
}

const schema = yup.object<FormValues>().shape({
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
})

const UserLookupForm = withFormik<Props, FormValues>({
  mapPropsToValues: props => ({
    email: "",
  }),
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: schema,
  handleSubmit: (values, formikBag) => {
    const { verifiedEmails, isAdditional } = formikBag.props.userAuthStore
    const { locStore, track } = formikBag.props.store!
    const loc = locStore.currentLocation
    formikBag.props.userAuthStore.setActiveEmail(values.email)
    formikBag.props.userAuthStore
      .checkNonexistentUser(values.email)
      .then(response => {
        // If user account doesn't exist yet, proceed with account setup
        if (response.user_exists === false) {
          formikBag.props.userAuthStore
            .search(values.email)
            .then(res => {
              const { path } = formikBag.props.match
              const { brandLocationId, allowRedirect } = formikBag.props.userAuthStore

              if (res.status === 200) {
                if (!isAdditional) {
                  formikBag.props.userAuthStore.setInitialEmail(values.email)
                }

                // Skip claim flow if user is directed to XPASS registration flow from brand portal
                if (brandLocationId) {
                  formikBag.props.history.push(
                    `${path}/register/account?brand_location_id=${brandLocationId}&email=${values.email}&redirect=${allowRedirect}`
                  )
                } else {
                  if (verifiedEmails.some(email => email === values.email)) {
                    formikBag.setSubmitting(false)
                    const message = "Email has already been verified."
                    formikBag.setFieldError("base", message)
                  } else {
                    // If matching accounts found, go to claim accounts
                    if (res.data.user_lookups.count > 0) {
                      formikBag.props.history.push(`${path}/register/claim`)
                    } else if (isAdditional && res.data.user_lookups.count === 0) {
                      // If adding additional emails and no matching accounts found, show error message
                      formikBag.setSubmitting(false)
                      const message = "No matching accounts found under this email"
                      formikBag.setFieldError("base", message)
                    } else {
                      // If initial email and no matching accounts, go directly to register form
                      formikBag.props.history.push(`${path}/register/account`)
                    }
                  }
                }
              } else {
                formikBag.setSubmitting(false)
                const message = "There was an error. Please try again."
                formikBag.setFieldError("base", message)
              }
            })
            .catch(err => {
              formikBag.setSubmitting(false)
              const message = "There was an error. Please try again."
              formikBag.setFieldError("base", message)
            })
        } else if (response.user_exists && isAdditional) {
          formikBag.setSubmitting(false)
          const msg = "This account cannot be claimed. Please try a different email."
          formikBag.setFieldError("base", msg)
          // If user account already exists, provide user link to sign-in page
        } else if (response.user_exists) {
          formikBag.setSubmitting(false)
          // need to ask about a  registration_failure event
          // track.event("authentication_failure", { loc }, { cta: "register" })
          const msg = (
            <>
              An account already exists with this e-mail. Please try{" "}
              <Link
                to={`/auth/login?email=${values.email}`}
                className="alert-link"
              >
                signing in
              </Link>
              , or{" "}
              <Link
                to={`/auth/forgot_password`}
                className="alert-link"
              >
                reset your password
              </Link>
              {" "}to access XPASS.
            </>
          )
          formikBag.setFieldError("base", (msg as any) as string)
        } else {
          formikBag.setSubmitting(false)
          const message = "There was an error. Please try again."
          formikBag.setFieldError("base", message)
        }
      })
      .catch(err => {
        formikBag.setSubmitting(false)
        const message = "There was an error. Please try again."
        formikBag.setFieldError("base", message)
      })
  },
})(UserLookupFormInner)

export default UserLookupForm
