import * as React from "react"
import { AuthPagePath } from "./AuthController"
import BrandStore from "stores/BrandStore"
import UserAuthStore from "../stores/xpass/UserAuthStore"
import { inject } from "mobx-react"
import { Link } from "react-router-dom"
import cx from "classnames"

interface Props {
  store?: BrandStore
  className?: string
  currentPage: AuthPagePath
  brandText?: string
  redirectPath?: string
  showForgotPassword?: boolean
  userAuthStore?: UserAuthStore
  onPageChange?(page: string): void
}

// TODO: this needs a pretty serious refactor/gutting

const SharedAccountControls: React.FunctionComponent<Props> = props => {
  const { brandText, className, showForgotPassword = true, store } = props
  return (
    <div className={cx("shared-account-controls", className)}>
      <small>
        <AccountLink {...props} page="login" prepend={store!.isXponential ? "Already a member?" : "Already have an account?"}>
          Sign In
        </AccountLink>
      </small>
      <small className="new-member-link">
        <AccountLink
          {...props}
          page="register"
          prepend={`New${brandText ? ` to ${brandText}` : ""}? `}
        >
          {store!.copy.createAccount}
        </AccountLink>
      </small>

      {showForgotPassword && (
        <small>
          <AccountLink {...props} page="forgot_password">
            Forgot your password?
          </AccountLink>
        </small>
      )}
    </div>
  )
}

interface AccountLinkProps extends Props {
  page: string
  store?: BrandStore
  prepend?: string
}

const AccountLinkInner: React.SFC<AccountLinkProps> = ({
  page,
  currentPage,
  onPageChange,
  children,
  prepend,
  store,
  userAuthStore,
}) => {
  if (page === currentPage) {
    return null
  }

  let path = `/auth/${page}`
  if (store!.isXponential && userAuthStore && userAuthStore.brandLocationId ) {
    const { brandLocationId, allowRedirect} = userAuthStore
    path = `/auth/${page}?brand_location_id=${brandLocationId}&redirect=${allowRedirect}`
  }

  return (
    <>
      {prepend && `${prepend} `}
      <Link
        to={path}
        className="account-controls-link"
        children={children}
        onClick={e => {
          if (onPageChange) {
            e.preventDefault()
            onPageChange(page)
          }
        }}
      />
    </>
  )
}

export const AccountLink = inject((store: BrandStore) => ({ store }))(
  AccountLinkInner
)

export default inject((store: BrandStore) => ({ store }))(SharedAccountControls)
