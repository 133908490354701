import * as React from "react"
import LocationRoute, { OuterProps } from "components/wrappers/LocationRoute"
import RestrictedRoute from "components/wrappers/RestrictedRoute"

export interface Props extends OuterProps {
  exact?: boolean
  shouldRedirect?: boolean
  roadblockPath?: string
  inComingSoon?: boolean
  ignoreStoredLocation?: boolean
}

export default class RestrictedLocationRoute extends React.Component<Props> {
  public render() {
    return (
      <RestrictedRoute
        publicOnly={false}
        exact={this.props.exact}
        shouldRedirect={this.props.shouldRedirect}
        roadblockPath={this.props.roadblockPath}
        locationInPath={this.props.locationInPath}
        ignoreStoredLocation={this.props.ignoreStoredLocation}
        path={
          this.props.locationInPath
            ? `${this.props.path}/:locationId?`
            : this.props.path
        }
        render={() => (
          <LocationRoute
            path={this.props.path}
            locationInPath={this.props.locationInPath}
            inComingSoon={this.props.inComingSoon}
            render={lrProps => this.props.render(lrProps)}
          />
        )}
      />
    )
  }
}
