import { JsonObject, JsonProperty } from "json2typescript"
import Price from "models/Price"
import DateConverter from "utils/DateConverter"
import { Moment } from "moment"

export type PackageType = "package" | "membership" | "service" | "addon"
export type PackageInterval = "day" | "month" | "year"

@JsonObject("PackageCreditType")
export class PackageCreditType {
  /**
   * The number of credits of this type that the package drops. ClubReady `number`.
   */
  @JsonProperty("count", Number)
  count: number = undefined!

  /**
   * Unique (?) ClubReady credit id.
   */
  @JsonProperty("id", String, true)
  id?: string = undefined!

  /**
   * Name of the credits.
   */
  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * General type of the credit. ClubReady `type`.
   */
  @JsonProperty("credit_type", String)
  creditType: "class" | "service" | "other" = undefined!
}

@JsonObject("ValueProp")
export class ValueProp {
  @JsonProperty("text", String)
  text: string = undefined!

  @JsonProperty("tool_tip", String, true)
  toolTip?: string = undefined!
}

@JsonObject("Package")
export default class Package {
  /**
   * A uuid for the package.
   */
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * What type of package it is. Currently just based on the `is_recurring`
   * and `is_addon` fields.
   */
  @JsonProperty("package_type", String)
  packageType: PackageType = undefined!

  /**
   * Display name of the package.
   */
  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * Placeholder - if the package is an "offer", what type of offer it is.
   */
  @JsonProperty("offer_type", String, true)
  offerType?: string = undefined!

  /**
   * Unique identifier of package. Used for tracking.
   */
  @JsonProperty("clubready_id", String)
  clubreadyId: string = undefined!

  /**
   * Unique identifier of installment plan for package. Used for tracking.
   */
  @JsonProperty("clubready_plan_id", String)
  clubreadyPlanId: string = undefined!

  /**
   * Small text shown below the package describing its renewal period or expiration. If blank, try to construct something sane out of the properties of the package/purchase.
   */
  @JsonProperty("description", String, true)
  description?: string = undefined!

  @JsonProperty("price", Price)
  price: Price = undefined!

  @JsonProperty("post_trial_price", Price)
  postTrialPrice: Price = undefined!

  @JsonProperty("previous_price_text", String, true)
  previousPriceText: string = undefined!

  @JsonProperty("price_text", String, true)
  priceText: string = undefined!

  @JsonProperty("current_price_text", String, true)
  currentPriceText: string = undefined!
  
  @JsonProperty("percentage_discount_text", String, true)
  percentageDiscountText: string = undefined!

  /**
   * The renewal period for the plan, if it's recurring, or the expiration period if it's not.
   * `date` refers to a fixed, specific end date for the plan. Unfortunately we don't yet have a way to know what this date is, just that the package will expire or something after a limited time.
   */
  @JsonProperty("interval", String, true)
  interval?: PackageInterval = undefined

  /**
   * How many of the specified intervals compose the renewal/expiration period. For instance, interval=month, interval_count=6 would be "every 6 months" or "6 month expiration."
   *
   */
  @JsonProperty("interval_count", Number, true)
  intervalCount?: number = undefined

  /**
   * This is used to potentially provide a discount for buying multiple months at a time. Not sure if it needs to be displayed in-app for now - may just want to hide packages with payment_count > 1. There may be other ways this field is used by ClubReady.
   */
  @JsonProperty("payment_count", Number, true)
  paymentCount?: number = undefined

  /**
   * Whether the package seems to have a contract associated with it.
   */
  @JsonProperty("has_contract", Boolean)
  hasContract: boolean = undefined!

  /**
   * URL of an html version of the membership agreement for the package at this location.
   */
  @JsonProperty("contract_url", String, true)
  contractUrl?: string = undefined

  /**
   * Whether or not the plan requires a payment method on file in order to purchase.
   */
  @JsonProperty("is_free", Boolean)
  isFree: boolean = undefined!

  /**
   * XPASS-specific field to identify whether the package is the introductory free package.
   */
  @JsonProperty("is_xpass_free", Boolean)
  isXpassFree: boolean = undefined!

  /**
   * Whether the plan automatically renews. Usually implied by `interval` / `interval_count`.
   */
  @JsonProperty("is_recurring", Boolean)
  isRecurring: boolean = undefined!

  /**
   * Whether this has been labeled, in HYFN's admin, as an "unlimited" membership. This is only as accurate as the admin data entry, so don't rely too heavily on it for now.
   */
  @JsonProperty("is_unlimited", Boolean)
  isUnlimited: boolean = undefined!

  /**
   * Order in which package should appear in a list context.
   */
  @JsonProperty("seq", Number)
  seq: number = undefined!

  /**
   * Type of credits the package drops. Or, if it drops more than one type, the type it drops the most of.
   */
  @JsonProperty("credit_type", String, true)
  creditType?: "class" | "service" | "other" = undefined

  /**
   * Name of the package category, if any
   */
  @JsonProperty("category_name", String, true)
  categoryName?: string = undefined

  /**
   * Id of the package category, if any
   */
  @JsonProperty("category_id", String, true)
  categoryId?: string = undefined

  /**
   * Flag of the purchase to determine if it's expired
   */
  @JsonProperty("seems_expired", Boolean, true)
  seemsExpired?: boolean = undefined

  /**
   * Flag of the purchase to determine if it's cancelled
   */
  @JsonProperty("is_cancelled", Boolean, true)
  isCancelled?: boolean = undefined

  /**
   * Flag of the purchase package to indicate multi brand VOD package
   */
  @JsonProperty("multi_brand", Boolean, true)
  multiBrand?: boolean = undefined

  /**
   * Flag of the purchase package to indicate restricted VOD package
   */
  @JsonProperty("restricted_video", Boolean, true)
  restrictedVideo?: boolean = undefined

  @JsonProperty("scheduled_cancel_date_utc", DateConverter, true)
  scheduledCancelDateUtc?: Moment = undefined!

  @JsonProperty("purchase_id", String, true)
  purchaseId?: string = undefined

  @JsonProperty("banner", String, true)
  banner?: string = undefined

  @JsonProperty("promo_title", String, true)
  promoTitle?: string = undefined
  
  @JsonProperty("promo_description", String, true)
  promoDescription?: string = undefined
  
  @JsonProperty("promo_name", String, true)
  promoName?: string = undefined
  
  @JsonProperty("promo_footer", String, true)
  promoFooter?: string = undefined
  
  @JsonProperty("value_props", [ValueProp], true)
  valueProps?: ValueProp[] = undefined
  
  @JsonProperty("most_popular", Boolean, true)
  mostPopular?: boolean = undefined
  
  @JsonProperty("best_value", Boolean, true)
  bestValue?: boolean = undefined

  /**
   * Details and the type and number of the tokens dropped by this package. ClubReady `defaultCredits`.
   */
  @JsonProperty("class_tokens", [PackageCreditType], true)
  classTokens: PackageCreditType[] = []

  /**
   * Total number of credits dropped by the package.
   */
  @JsonProperty("credit_count", Number, true)
  creditCount?: number = undefined!

 /**
   * Details and the type and number of the credits dropped by this package. ClubReady `defaultCredits`.
   */
  @JsonProperty("credits", [PackageCreditType], true)
  credits?: PackageCreditType[] = []

/**
 * Total number of tokens dropped by the package.
 */
  @JsonProperty("class_tokens_count", Number, true)
  classTokensCount: number = undefined!

  @JsonProperty("xpass_eligible", Boolean, true)
  xpassEligible?: boolean = undefined

  @JsonProperty("cancellation_title", String, true)
  cancellationTitle?: string = undefined

  @JsonProperty("cancellation_subtitle", String, true)
  cancellationSubtitle?: string = undefined

  @JsonProperty("cancellation_description", String, true)
  cancellationDescription?: string = undefined

  get isMembership() {
    return this.packageType === "membership"
  }
}
