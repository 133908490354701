// This component is similar to BookingSuccessPage
// This is specifically for the book and confirm flow
// where right after the user purchases the package, she is
// taken directly to the final screen instead of having to
// confirm the class manually.
// This component closes the error modal and
// pass it to BookAndConfirmInfoCard in title and/or
// subtitle.

import * as React from "react"
import { Link, Redirect } from "react-router-dom"
import { inject, observer } from "mobx-react"
import capitalize from "lodash/capitalize"
import getIn from "lodash/get"

import Booking from "models/Booking"
import ScheduleEntry from "models/ScheduleEntry"
import LocationSummary from "models/LocationSummary"
import BookingStatus from "models/BookingStatus"
import Offer from "models/Offer"

import BookAndConfirmInfoCard from "apps/book/components/BookAndConfirmInfoCard"
import PageTracker from "components/wrappers/PageTracker"

import BrandStore from "stores/BrandStore"
import { PlanInfo, GiftCardInfo } from "apps/buy/components/PaymentPage"
import BookingFlowStore from "apps/book/stores/BookingFlowStore"

interface Props {
  booking?: Booking | BookingStatus
  store?: BrandStore
  scheduleEntry: ScheduleEntry
  locationSummary: LocationSummary
  shareLink?: string
  offer?: Offer
  planInfo?: PlanInfo
  giftCardInfo?: GiftCardInfo
  bookingFlowStore?: BookingFlowStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class BookAndConfirmSuccessPage extends React.Component<
  Props,
  {}
> {
  copyShareInput: React.RefObject<HTMLInputElement> = React.createRef()

  componentDidMount() {
    this.props.store!.uiStore.closeModal()
  }

  handleCopyLinkClick = () => {
    this.copyShareInput.current!.select()
    document.execCommand("copy")
  }

  render() {
    const {
      store,
      booking,
      scheduleEntry,
      locationSummary,
      shareLink,
    } = this.props

    if (!booking) return <Redirect to="/bookings" />

    return (
      <BookAndConfirmSuccess
        booking={booking}
        brandStore={store!}
        locationSummary={locationSummary}
        scheduleEntry={scheduleEntry}
        planInfo={this.props.planInfo}
        giftCardInfo={this.props.giftCardInfo}
        bookingError={this.props.bookingFlowStore!.error}
      />
    )
  }
}

interface BookingError {
  title?: string
  message?: string
}

interface ConfirmSuccessProps {
  booking: Booking | BookingStatus
  brandStore: BrandStore
  locationSummary: LocationSummary
  scheduleEntry: ScheduleEntry
  planInfo?: PlanInfo
  giftCardInfo?: GiftCardInfo
  bookingError?: BookingError
}

export const BookAndConfirmSuccess: React.SFC<ConfirmSuccessProps> = ({
  booking,
  brandStore: { brand, copy, userStore, styleClasses },
  locationSummary,
  scheduleEntry,
  planInfo,
  giftCardInfo,
  bookingError,
}) => {
  const isWaitlist = booking.status === "waitlisted"

  const bookingErrorMessage = getIn(bookingError, "message")
  const errorMessage = bookingError
    ? bookingErrorMessage === "Already booked"
      ? `Your purchase of 1 Credit was processed successfully, but it looks like someone else grabbed that spot!\n
      Please join us for a different ${String(copy.seat).toLowerCase()} with the credit purchased using the \'BOOK A ${capitalize(copy.class)}\' button below.`
      : bookingErrorMessage
    : undefined

  const title = bookingError ? (
    "Your Booking Failed"
  ) : isWaitlist ? (
    <>You&apos;re on the Waitlist!</>
  ) : (
    "Your Booking is Confirmed!"
  )

  const subtitle = bookingError
    ? "Please try a different class using 'BOOK A CLASS' button below."
    : isWaitlist
    ? `We will email you if a ${String(
        copy.seat
      ).toLowerCase()} becomes available. We hope to see you soon!`
    : `Here's your ${copy.class} information. We'll see you soon!`

  const bookingCTA = bookingError ? (
    <Link className="btn btn-primary w-100" to={`/book`}>
      Book A {capitalize(copy.class)}
    </Link>
  ) : (
    <Link className="btn btn-primary w-100" to={`/bookings`}>
      View My Schedule
    </Link>
  )

  return (
    <div>
      <PageTracker name="book > success" />
      <BookAndConfirmInfoCard
        title={title}
        subtitle={subtitle}
        scheduleEntry={scheduleEntry}
        locationSummary={locationSummary}
        brand={brand}
        copy={copy}
        email={userStore.session!.email}
        seatId={"seatId" in booking ? booking.seatId : undefined}
        seatLabel={"seatLabel" in booking ? booking.seatLabel : undefined}
        waitlistPosition={
          "waitlistPosition" in booking ? booking.waitlistPosition : undefined
        }
        planInfo={planInfo}
        giftCardInfo={giftCardInfo}
        errorMessage={errorMessage}
        styleClasses={styleClasses}
      >
        <div className="mt-4 text-center">{bookingCTA}</div>
        <div className="confirmed-links">
          {process.env.FEATURE_MVP_ONLY !== "1" && (
            <div className="confirm-link">
              <a href="#">Add to Calendar</a>
            </div>
          )}
          <div className="confirm-link">
            <a href={locationSummary.directionsUrl} target="_blank">
              Get Directions
            </a>
          </div>
          {locationSummary.email && (
            <div className="confirm-link">
              <a href={`mailto:${locationSummary.email}`}>Contact Location</a>
            </div>
          )}
        </div>
      </BookAndConfirmInfoCard>
      {/* make sure this stays gone - we are NOT giving out free rides */}
      {/* <hr className="my-5" />
      <div className="text-center">
        <h4 className="mb-3">Refer a Friend</h4>
        <div className="confirmed-body d-flex justify-content-center text-center mb-5">
          <p>
            Riding with people you love is what we are all about. Feel free to
            invite your friend or two and they will get their first ride for
            free.
          </p>
        </div>
        <h4 className="mb-3">Share With Your Friends!</h4>
        <div className="row d-flex justify-content-center mb-5">
          <div className="row col-md-6 col-lg-4 col-xl-4">
            <input
              ref={copyShareInput}
              className="form-control col-8 h-100"
              type="text"
              readOnly={true}
              value={this.shareLink}
            />
            <button
              className="btn btn-secondary bg-dark text-white rounded-0 col-4 h-100"
              onClick={this.handleCopyLinkClick}
            >
              Copy Link
            </button>
          </div>
        </div>
        {process.env.FEATURE_MVP_ONLY !== "1" && (
          <>
            <h4 className="mb-2">Or Share On Social:</h4>
            <div className="confirmed-social mx-auto">
              <SocialLinks />
            </div>
          </>
        )}
      </div> */}
    </div>
  )
}
