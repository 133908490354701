import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import APIStore from "stores/APIStore"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import PurchaseSummary from "apps/account/models/PurchaseSummary"
import Package from "models/Package"
import PlanV3Store from "apps/buy/stores/PlanV3Store"
import { isFormError } from "helpers/errorHandling"

interface PurchaseResponse {
  purchase: PurchaseSummary
}

export default class PurchaseV3Store extends APIStore {
  @observable purchase?: PurchaseSummary

  api = this.createClient<PurchaseResponse>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    TokenAuthMiddleware(this.planV3Store.brandStore.userStore),
    DeserializeMiddleware("purchase", PurchaseSummary),
  ])

  @computed get plan() {
    return this.planV3Store.plan!
  }

  @computed get pkg() {
    // pkg will be optional some day
    return this.planV3Store.package!
  }

  @computed get promoCode() {
    return this.planV3Store.promoCode
  }


  constructor(public planV3Store: PlanV3Store) {
    super()
  }

  makePurchase(accessToken?: string) {
    const { session } = this.planV3Store.brandStore.userStore
    const id = this.planV3Store.package!.id
    const url = `/api/locations/${session && session.locationId}/packages/${id}/purchases`

    const data = {
      payment_amount: this.plan.todayTotal.numeric,
      promo_code: this.planV3Store.promoCode,
      ...(accessToken && { access_token: accessToken }), // Conditionally include access_token field
    }

    return this.api.post(url, data)
  }

  updatePurchase(purchaseId: string, pkg: Package) {
    const {session} = this.planV3Store.brandStore.userStore

    return this.api.put(`/api/locations/${session && session.locationId}/purchases/${purchaseId}`, {
      payment_amount: pkg.price.raw,
      target_package_id: pkg.id,
    })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<PurchaseResponse>) {
    this.purchase = res.data.purchase
    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    // TODO: nicer error handling
    if (isFormError(ex.response)) {
      this.planV3Store.brandStore.uiStore.openMessage(
        "error",
        ex.response.data.error_messages.join("\n"),
        "Something went wrong"
      )
      throw ex
    }

    const message = ex.response && ex.response.data.message ?
      ex.response.data.message :
      "Something went wrong"
    this.planV3Store.brandStore.uiStore.openMessage(
      "error",
      message
    )
    throw ex
  }
}
