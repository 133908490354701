import * as React from "react"
import {
  RouteComponentProps,
  Switch,
  Route,
  Redirect,
  matchPath,
} from "react-router"
import { observer, inject } from "mobx-react"
import { observable } from "mobx"

import { loadState } from "services/savedState"
import { peekFriendlyRoute } from "services/friendlyRoute"
import ScheduleEntry from "models/ScheduleEntry"
import BrandStore from "stores/BrandStore"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"
import ScheduleEntryStore from "apps/auth/stores/ScheduleEntryStore"

import LoginForm from "apps/auth/components/LoginForm"
import RegistrationForm from "apps/auth/components/RegistrationForm"
import ForgotPasswordForm from "apps/auth/components/ForgotPasswordForm"
import EmailLookupForm from "apps/auth/components/EmailLookupForm"
import UserLookupForm from "apps/auth/components/xpass/UserLookupForm"

import BackToBrandSite from "apps/auth/components/BackToBrandSite"
import AuthLocationsLoader from "apps/auth/components/AuthLocationsLoader"
import ClaimAccount from "apps/auth/components/xpass/ClaimAccount"
import VerifyAccountForm from "apps/auth/components/xpass/VerifyAccountForm"
import LoginInfoModal from "./xpass/LoginInfoModal"

export type AuthPagePath = "login" | "forgot_password" | "register" | "create_password"

export interface Props {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class AuthController extends React.Component<
Props & RouteComponentProps
> {
  rememberKey = "xpo-remember-me"
  emailKey = "xpo-last-email"
  storedEmail: string = ""
  @observable scheduleEntryStore?: ScheduleEntryStore
  @observable forBooking?: boolean

  userAuthStore = new UserAuthStore()

  handlePageChange = (page: AuthPagePath) => {
    const { brandLocationId, allowRedirect} = this.userAuthStore
    const path = this.props.store!.isXponential && brandLocationId ? `${page}?brand_location_id=${brandLocationId}&redirect=${allowRedirect}` : page
    this.props.history.push(path)
  }

  openXpassModal = () => {
    this.props.store!.uiStore.openModal({
      children: <LoginInfoModal store={this.props.store} />,
      modalShow: true,
      emptyHeader: true,
    })
  }

  componentDidMount() {
    const rememberMe: boolean = loadState(this.rememberKey)!
    if (rememberMe) {
      this.storedEmail = loadState(this.emailKey)
    }

    // if we're hitting the auth in the context of a booking, show info about
    // the booking.
    const match = matchPath<{ locationId: string; scheduleEntryId: string }>(
      peekFriendlyRoute(),
      "/book/:locationId/:scheduleEntryId"
    )

    if (match) {
      this.forBooking = true
      this.props.store!.uiStore.lockLocationPicker()
      this.scheduleEntryStore = new ScheduleEntryStore(
        match.params.scheduleEntryId,
        match.params.locationId
      )
      this.scheduleEntryStore.fetch()
    }

    if (this.props.store!.isXponential) {
      this.userAuthStore.setXpassMobileData(this.props.store!.routingStore)
    }
  }

  getSubheadBlock(type: "signup" | "signin", scheduleEntry?: ScheduleEntry) {
    if (scheduleEntry) {
      return <BookingHeader type={type} scheduleEntry={scheduleEntry} />
    }
    return null
  }

  componentWillUnmount() {
    this.props.store!.uiStore.unlockLocationPicker()
  }

  public render() {
    const scheduleEntry = this.scheduleEntryStore && this.scheduleEntryStore.scheduleEntry
    const { isXponential } = this.props.store!

    return (
      <div className="mx-auto">
        <AuthLocationsLoader
          locationId={this.props.store!.routingStore.query.location_id}
          render={() => (
            <>
              <BackToBrandSite />
              <Switch>
                <Route
                  exact
                  path={`${this.props.match.path}/login`}
                  render={() => (
                    <LoginForm
                      pageName="auth > sign in"
                      onPageChange={this.handlePageChange}
                      openXpassModal={this.openXpassModal}
                      storedEmail={this.storedEmail}
                      rememberKey={this.rememberKey}
                      emailKey={this.emailKey}
                      // if they're registering for a booking intent,
                      // don't let them change the location.
                      lockLocation={this.forBooking}
                      subheadBlock={this.getSubheadBlock("signin", scheduleEntry)}
                      userAuthStore={this.userAuthStore}
                    />
                  )}
                />

                <Route
                  path={`${this.props.match.path}/register`}
                  render={() =>
                    isXponential ? (
                      <UserLookupForm
                        {...this.props}
                        store={this.props.store}
                        userAuthStore={this.userAuthStore}
                      />
                    ) : (
                      <RegistrationForm
                        // if they're registering for a booking intent,
                        // don't let them change the location.
                        lockLocation={this.forBooking}
                        pageName="auth > register"
                        store={this.props.store}
                        onPageChange={this.handlePageChange}
                        subheadBlock={this.getSubheadBlock(
                          "signup",
                          scheduleEntry
                        )}
                        userAuthStore={this.userAuthStore}
                      />
                    )
                  }
                  exact
                />

                {isXponential && (
                  <Route
                    exact
                    path={`${this.props.match.path}/register/claim`}
                    render={() => (
                      <ClaimAccount
                        {...this.props}
                        userAuthStore={this.userAuthStore}
                      />
                    )}
                  />
                )}

                {isXponential && (
                  <Route
                    exact
                    path={`${this.props.match.path}/register/verify`}
                    render={() => (
                      <VerifyAccountForm
                        {...this.props}
                        userAuthStore={this.userAuthStore}
                      />
                    )}
                  />
                )}

                {isXponential && (
                  <Route
                    exact
                    path={`${this.props.match.path}/register/account`}
                    render={() => (
                      <RegistrationForm
                        pageName="auth > register"
                        store={this.props.store}
                        userAuthStore={this.userAuthStore}
                      />
                    )}
                  />
                )}

                <Route
                  path={`${this.props.match.path}/forgot_password`}
                  render={() => (
                    <ForgotPasswordForm
                      pageName="auth > forgot password"
                      onPageChange={this.handlePageChange}
                    />
                  )}
                  exact
                />

                <Route
                  path={`${this.props.match.path}/create_password`}
                  render={() => (
                    <ForgotPasswordForm
                      pageName="auth > create password"
                      onPageChange={this.handlePageChange}
                    />
                  )}
                  exact
                />

                {/* this is currently unused */}
                <Route
                  path={`${this.props.match.path}/email`}
                  render={p => (
                    <EmailLookupForm {...p} pageName="auth > email" />
                  )}
                />
                <Redirect
                  path={this.props.match.path}
                  to={`${this.props.match.path}/login`}
                />
              </Switch>
            </>
          )}
        />
      </div>
    )
  }
}

interface BookingHeaderProps {
  scheduleEntry: ScheduleEntry
  type: "signin" | "signup"
}
const BookingHeader: React.SFC<BookingHeaderProps> = ({ scheduleEntry, type }) => {

  const { title, startsAt, startTime } = scheduleEntry

  return (
    <div className="text-center">
      <h2 className="mb-1">{title}</h2>
      <p>
        {startsAt.format("dddd, MMM D")} at {startTime}
        {type === "signin" && (
          <>
            <br />
            Please sign in to continue your booking.
          </>
        )}
        {type === "signup" && (
          <>
            <br />
            Please create an account to continue your booking.
          </>
        )}
      </p>
    </div>
  )
}
