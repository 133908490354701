import { AxiosResponse } from "axios"
import { observable, action, computed } from "mobx"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

import Activity, { ActivityMeta } from "apps/classpoints/models/Activity"

interface ActivityResponse {
  activity: Activity[]
  activityMeta: ActivityMeta
}

export default class ActivityStore extends APIStore {
  @observable activity: Activity[] = []
  @observable activityMeta?: ActivityMeta
  @observable page: number = 1
  @observable perPage: number = 25

  api = this.createClient<ActivityResponse>([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("activity", Activity),
    DeserializeMiddleware("meta", ActivityMeta, true, true),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(public brandStore: BrandStore) { super() }

  fetch() {
    this.status = 'loaded'
    const pageParam = this.pageParam
    return this.api.get(`/api/class_points/activity?${pageParam}`)
  }

  @computed get pageParam() {
    const page = this.page ? `&page=${this.page}` : ""
    const perPage= this.perPage ? `&per_page=${this.perPage}` : ""
    return `${page}${perPage}`
  }

  // Page count number
  @action setPage(page: number) {
    this.page = page
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.activity = res.data.activity
    this.activityMeta = res.data.meta
    return res
  }
}
