import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

import UserProfile from "models/UserProfile"

interface Response {
  user: UserProfile
}

export default class GuestUserStore extends APIStore {
  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])
  
  constructor(public brandStore: BrandStore) { super() }

  submit(classPointsUserRewardsId: number, firstName: string, lastName: string, email: string, phone: string) {
    return this.api.post(`/api/class_points/v2/class_points_user_rewards/${classPointsUserRewardsId}/guest_users`,
      { 
        user: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_main: phone
        }
      }
    )
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response | any>) {
    return res.data
  }
}
