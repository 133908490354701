import * as React from "react"
import BookingUpdateStore from "apps/bookings/stores/BookingUpdateStore"
import Booking from "models/Booking"
import PickSeatPage from "apps/book/components/PickSeatPage"
import BookingStore from "apps/book/stores/BookingStore"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import APILoader from "components/wrappers/APILoader"
import ErrorPage from "components/ErrorPage"
import PageTracker from "components/wrappers/PageTracker"

export interface Props {
  booking: Booking
  store?: BrandStore
  onBookingChanged(booking: Booking): void
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ChangeSeatPage extends React.Component<Props, {}> {
  // why is this taking a booking and making its own store rather than just
  // being passed a BookingUpdateStore?
  updateStore = new BookingUpdateStore(this.props.store!, this.props.booking)
  bookingStore = new BookingStore(
    this.props.booking.scheduleEntry.location!,
    this.props.store!
  )

  handleSubmit = () => {
    this.updateStore.update().then(res => {
      const newSeat = res.data.booking!.seatLabel || res.data.booking!.seatId
      this.props.store!.uiStore.openMessage(
        "message",
        `Changed ${this.props.store!.copy.seat} to ${newSeat}`
      )
      this.props.onBookingChanged(this.props.booking)
    })
  }

  public render() {
    const bookingId = this.props.booking.id
    const submitDisabled =
      this.updateStore.status === "loading" ||
      this.updateStore.seatId === this.props.booking.seatId

    const locationId = this.props.store!.routingStore.query.location_id
    const fetchProps = this.props.store!.isXponential && locationId ? { id: bookingId, locationId } : { id: bookingId }

    return (
      <>
        <PageTracker name="my schedule > change seat" />
        <APILoader
          alreadyLoaded={
            this.bookingStore.booking &&
            this.bookingStore.booking.id === bookingId
          }
          apiStore={this.bookingStore}
          fetchProps={fetchProps}
          render={() => {
            const { booking, room } = this.bookingStore
            if (!booking || !room) {
              return (
                <ErrorPage
                  title="Can't Change Seat"
                  message="We're sorry, but it's not possible to change your seat for this booking. Please contact your studio if you need additional support."
                  linkTo="/bookings"
                  linkTitle="Back to My Schedule"
                />
              )
            }
            return (
              <PickSeatPage
                room={room}
                backPath="/bookings"
                scheduleEntry={booking.scheduleEntry}
                seatId={this.updateStore.seatId}
                seatLabel={this.updateStore.seatLabel}
                disabled={submitDisabled}
                onSubmit={this.handleSubmit}
                onChange={this.updateStore.setSeat}
              />
            )
          }}
        />
      </>
    )
  }
}
