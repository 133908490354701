import * as React from "react"
import { RouteComponentProps, Switch, Route, Redirect } from "react-router"
import { observable, action } from "mobx"
import { observer, inject } from "mobx-react"
import getIn from "lodash/get"

import BrandStore from "stores/BrandStore"
import { PlanInfo, GiftCardInfo } from "apps/buy/components/PaymentPage"
import BookingSuccessPage from "apps/book/components/BookingSuccessPage"
import BookAndConfirmSuccessPage from "apps/book/components/BookAndConfirmSuccessPage"
import ConfirmBookingPage from "apps/book/components/ConfirmBookingPage"
import PickSeatPage from "apps/book/components/PickSeatPage"
import BookingFlowStore from "apps/book/stores/BookingFlowStore"
import { loadState } from "services/savedState"
import { LATEST_BOOKING_PATH_KEY } from "apps/book/stores/QueryStringMonitor"
import { getLocal, removeLocal } from "utils/LocalStorage"
import cloneDeep from 'lodash/cloneDeep';

interface ControllerInnerFlowProps
  extends RouteComponentProps<{ scheduleEntryId: string }> {
  bookingFlowStore: BookingFlowStore
  store?: BrandStore
  bookAndConfirm?: boolean
  planInfo?: PlanInfo
  giftCardInfo?: GiftCardInfo
  // handleCantBook(): string
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class BookingDetailController extends React.Component<
  ControllerInnerFlowProps,
  {}
> {
  handleBook = (e: ButtonEvent) => {
    e.preventDefault()
    this.performBook()
  }

  performBook = () => {
    // TODO: Remove this once QA is done for purchase-and-book flow
    // TODO: Remove setTimeout below
    const timeOut = getLocal<string>("testTimeout")
    const timeOutInt = timeOut ? parseInt(timeOut, 10) : 0
    removeLocal("testTimeout")
    setTimeout(() => {
      const { history, match, bookingFlowStore, store } = this.props
      const bookingStatus = bookingFlowStore.bookingStatus!
      // TODO: replace once we can look up the schedule entry

      const xpassLocationId = store!.isXponential
        ? bookingStatus.scheduleEntry.locationId
        : undefined
      let userPremiumCost =
        store!.isXponential && bookingStatus.scheduleEntry.userPremiumCost!.raw !== undefined
          ? bookingStatus.scheduleEntry!.userPremiumCost!.raw
          : undefined

      // Set waitlist undefined here so no prop errors in bookingFlowStore
      const waitlist = undefined

      if (this.props.store!.isXponential && this.props.bookingFlowStore.hasPointsMismatch) {
        userPremiumCost = this.props.bookingFlowStore.updatePremiumCostFromMismatch()
      }

      this.props.bookingFlowStore
        .book(
          bookingStatus.scheduleEntry!.id,
          this.props.bookingFlowStore.bookingCreationStore.seatId,
          waitlist,
          xpassLocationId,
          userPremiumCost
        )
        .then(() => {
          history.push(this.getSuccessLocation())
        })
        .catch(ex => {
          bookingFlowStore.setError({
            message: getIn(
              ex,
              "data.message",
              `We were unable to book you into this ${store!.copy.class}.`
            ),
          })
        })
      const optimizedBookingStatus = cloneDeep(this.props.bookingFlowStore.bookingStatus);
      if(!this.props.store!.isXponential && optimizedBookingStatus!.scheduleEntry.hasOwnProperty("userPremiumCost")){
        delete optimizedBookingStatus!.scheduleEntry["userPremiumCost"]
      }
      store!.track.event(
        bookingStatus.scheduleEntry!.isFull && !store!.isXponential
          ? "waitlist_tap confirm"
          : "booking confirmation_tap confirm",
        {
          loc: this.props.store!.locStore.currentLocation!,
          bookingStatus: optimizedBookingStatus,
        }
      )
    }, timeOutInt)
  }

  getSuccessLocation() {
    return `${this.props.match.url}/success`
  }

  handleSeatSubmit = () => {
    this.props.history.push(`${this.props.match.url}`)
    const bookingStatus = cloneDeep(this.props.bookingFlowStore.bookingStatus);
    if(!this.props.store!.isXponential && bookingStatus!.scheduleEntry.hasOwnProperty("userPremiumCost")){
      delete bookingStatus!.scheduleEntry["userPremiumCost"]
    }
    this.props.store!.track.event("spot booking_tap confirm", {
      loc: this.props.store!.locStore.currentLocation!,
      bookingStatus,
    })
  }

  /**
   * If we have stored the last booking the user looked at (with filters),
   * redirect them there to continue browsing. Otherwise allow the default,
   * which will take them to a generic booking page
   */
  handleLastBookingPath = (e: LinkEvent) => {
    // If XPASS, redirect to Book a Class page
    if (this.props.store!.isXponential) {
      this.props.history.push("/book")
    } else {
      const { pathname = "", search = "" } =
        loadState(LATEST_BOOKING_PATH_KEY, true) || {}
      if (pathname) {
        e.preventDefault()
        this.props.history.push({ pathname, search })
      }
    }
  }

  render() {
    const { bookingFlowStore, store: brandStore } = this.props
    const {
      bookingStatus,
      booking,
      room,
      scheduleEntry,
      bookingCreationStore,
    } = bookingFlowStore
    const locationSummary = this.props.store!.locStore.currentLocation!
    const session = this.props.store!.userStore.session!
    const query = this.props.store!.routingStore.query

    // needs to dereference here to trigger re-render
    const { seatId, seatLabel } = bookingCreationStore

    const hasSpotBooking =
      bookingStatus && room && !bookingStatus.scheduleEntry.isFull

    // TODO: also handle coming from other places (marketing site)
    const { dateString } = scheduleEntry!
    let backPath = `/book/${locationSummary.id}?date=${dateString}`
    if (query && query.offer) {
      backPath = `/offers/${locationSummary.id}/${query.offer}`
    }

    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}`}
          render={({ match }) => {
            if (hasSpotBooking && !bookingCreationStore.seatId) {
              return (
                <Redirect
                  to={{
                    pathname: `${match.url}/seat`,
                    search: this.props.location.search,
                  }}
                />
              )
            } else if (booking && window.location.pathname.includes(booking.scheduleEntry.id) || (bookingStatus && bookingStatus.isBooked)) {
              return <Redirect to={this.getSuccessLocation()} />
            } else {
              return (
                <ConfirmBookingPage
                  bookingFlowStore={this.props.bookingFlowStore}
                  bookingStatus={bookingStatus!}
                  scheduleEntry={scheduleEntry!}
                  locationSummary={locationSummary}
                  seatId={seatId}
                  seatLabel={seatLabel}
                  onBook={this.handleBook}
                  bookAndConfirm={this.props.bookAndConfirm}
                  performBook={this.performBook}
                  onCancel={this.handleLastBookingPath}
                  backPath={backPath}
                  // seatId={this.bookingStatusStore.seatId}
                />
              )
            }
          }}
        />
        <Route
          exact
          path={`${this.props.match.path}/seat`}
          render={() =>
            hasSpotBooking ? (
              <PickSeatPage
                room={room!}
                seatId={seatId}
                seatLabel={seatLabel}
                scheduleEntry={scheduleEntry!}
                onChange={bookingCreationStore.setSeat}
                onSubmit={this.handleSeatSubmit}
                onCancel={this.handleLastBookingPath}
                backPath={backPath}
              />
            ) : (
              // TODO: is this url right?
              <Redirect to={`${this.props.match.url}`} />
            )
          }
        />
        <Route
          exact
          path={`${this.props.match.path}/success`}
          render={props => {
            // let shareURL = window.location.href.split("/")
            // shareURL.pop()
            return this.props.bookAndConfirm ? (
              <BookAndConfirmSuccessPage
                booking={
                  this.props.bookingFlowStore.booking ||
                  this.props.bookingFlowStore.bookingStatus
                }
                scheduleEntry={scheduleEntry!}
                locationSummary={locationSummary}
                planInfo={this.props.planInfo}
                giftCardInfo={this.props.giftCardInfo}
                bookingFlowStore={this.props.bookingFlowStore}
                // shareLink={shareURL.join("/")}
                // seatId={this.bookingCreationStore.seatId}
              />
            ) : (
              <BookingSuccessPage
                booking={
                  this.props.bookingFlowStore.booking ||
                  this.props.bookingFlowStore.bookingStatus
                }
                scheduleEntry={scheduleEntry!}
                locationSummary={locationSummary}
                // shareLink={shareURL.join("/")}
                // seatId={this.bookingCreationStore.seatId}
              />
            )
          }}
        />
      </Switch>
    )
  }
}
