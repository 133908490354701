import { JsonObject, JsonProperty } from "json2typescript"
import { Moment } from "moment"
import { computed, observable } from "mobx"

import DateConverter from "utils/DateConverter"
import ClassCategorySummary from "models/ClassCategorySummary"
import { BookingStatusEnum } from "models/Booking"
import InstructorSummary from "models/InstructorSummary"
// import {
//   formatTimeRangeLong,
//   formatTime,
//   formatDateLong,
// } from "helpers/dateHelper"

@JsonObject("ScheduleEntryBase")
export default class ScheduleEntryBase {
  @JsonProperty("title", String)
  title: string = undefined!
  /**
   * A additional second title line used sometimes to describe the class more
   * specifically. specifically. For instance:
   * ```
   *   title: "Ride and Re-Connect"
   *   subtitle: "Connect"
   *
   * ```
   */
  @JsonProperty("subtitle", String, true)
  subtitle?: string = undefined!

  @JsonProperty("clubready_id", String)
  clubreadyId: string = undefined!

  @JsonProperty("piq_id", String, true)
  piqId?: string = undefined

  @JsonProperty("class_category", ClassCategorySummary, true)
  classCategory?: ClassCategorySummary = undefined

  @JsonProperty("instructor", InstructorSummary, true)
  instructor?: InstructorSummary = undefined

  /**
   * URL in the portal where a user can book this class
   */
  @JsonProperty("booking_url", String, true)
  bookingUrl?: string = undefined!

  // a little flag for whether it's loading, like if we're still fetching a
  // booking associated with it
  @observable isBusy?: boolean

  // For pairing it up with the user bookings on the schedule page
  @observable booking?: {
    status: BookingStatusEnum
    canBookFromWaitlist?: boolean
    waitlistPosition?: number
  }

  @computed get isBooked() {
    return !!this.booking
  }

  @computed
  get bookingPath() {
    if (!this.bookingUrl) return undefined

    const parser = document.createElement("a")
    parser.href = this.bookingUrl
    return parser.pathname + parser.search + parser.hash
  }

  // ---- Moved to ScheduleEntry.ts / ScheduleEntryV1.ts
  /**
   * Unique ID for the schedule entry.
   */
  // @JsonProperty("id", String)
  // id: string = undefined!

  /**
   * Timestamp of the start time for the class.
   */
  // @JsonProperty("starts_at", DateConverter)
  // startsAt: Moment = undefined!

  /**
   * Timestamp of the end time for the class.
   */
  // @JsonProperty("ends_at", DateConverter)
  // endsAt: Moment = undefined!

  // @computed
  // get dateString() {
  //   return this.startsAt.format("YYYY-MM-DD")
  // }

  // // used e.g. on the performance card to get both date and time in a string
  // // TODO: might make sense to put this in a helper or something?

  // @computed
  // get startsAtDate() {
  //   return formatDateLong(this.startsAt)
  // }

  // @computed
  // get startTime() {
  //   return formatTime(this.startsAt)
  // }

  // @computed
  // get endTime() {
  //   return formatTime(this.endsAt)
  // }

  // @computed
  // get timeRange() {
  //   return formatTimeRangeLong(this.startsAt, this.endsAt)
  // }
}
