import React, { useEffect, useState } from "react"
import cx from "classnames"
import { observer } from "mobx-react"
import { computed } from "mobx"
import range from "lodash/range"
import padStart from "lodash/padStart"
import monthNames from "helpers/monthNames"
import * as moment from "moment"
import flatpickr from "flatpickr"
import CalendarIcon from "images/xpass/icons/calendar.svg"

import FormControl, { InnerProps } from "components/forms/FormControl"

type SafeInput = Omit<
  React.SelectHTMLAttributes<HTMLInputElement>,
  "value" | "name" | "children"
>

interface OuterProps extends SafeInput {
  name: string
  className?: string
  label?: string
  dateFormat: string
}

const FormDatePickerEnhanced: React.SFC<OuterProps> = observer(outerProps => {
  const { className, label, name } = outerProps
  return (
    <FormControl
      className={className}
      label={label}
      name={name}
      children={props => (
        <FormDatePickerInner {...props} outerProps={outerProps} />
      )}
    />
  )
})

export interface FDPInnerProps extends InnerProps {
  outerProps: OuterProps
}

const DatePicker: React.FunctionComponent<FDPInnerProps> = ({
  id,
  field,
  className,
  form,
  outerProps: { name, disabled, dateFormat },
}) => {
  const inputRef = React.createRef<HTMLInputElement>()
  const maxDate = moment().subtract(13, 'years').format("MM/DD/YYYY")
  const startDate = moment().subtract(18, 'years')
  const startDateString = moment(startDate).format("MM/DD/YYYY")
  const [dateSelected, setDateSelected] = useState(startDateString)
  let defaultDate = moment(dateSelected, "MM/DD/YYYY").format("MM/DD/YYYY")

  const setDate = (selectedDate: Date[]) => {
    const date = moment(selectedDate[0], "MM/DD/YYYY").format("MM/DD/YYYY")
    const value = moment(selectedDate[0], "MM/DD/YYYY").format("YYYY-MM-DD")
    setDateSelected(date)
    form.setFieldValue(name, value)
  }

  useEffect(() => {
    flatpickr(inputRef.current!, {
      enableTime: false,
      dateFormat: dateFormat,
      maxDate: maxDate,
      disableMobile: true,
      defaultDate: defaultDate,
      onChange: selectedDate => setDate(selectedDate),
      onClose: selectedDate => setDate(selectedDate),
    })

    setDate([startDate.toDate()])
  }, [])

  return (
    <>
      <input id={id} name={name} className="form-control w-100" type="text" ref={inputRef} />
      <a className="schedule-entries__calendar-icon schedule-entries__calendar-icon__register">
        <img src={CalendarIcon} />
      </a>
    </>
  )
}

@observer
class FormDatePickerInner extends React.Component<FDPInnerProps, {}> {
  public render() {
    return (
      <DatePicker {...this.props} />
    )
  }
}

export default FormDatePickerEnhanced
