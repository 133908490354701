import { AxiosResponse } from "axios"
import { action, observable, computed } from "mobx"
import sortBy from "lodash/sortBy"
import moment from "moment"
import APIStore from "stores/APIStore"
import LocationSummary from "models/LocationSummary"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ServiceType from "apps/book/models/ServiceType"
import ServiceScheduleEntry from "apps/book/models/ServiceScheduleEntry"
import BookingsStore from "apps/bookings/stores/BookingsStore"
import BrandStore from "stores/BrandStore"
import ServiceBooking from "apps/book/models/ServiceBooking"
import { bookingStatusMap } from "models/Booking"
import Duration from "helpers/Duration"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export interface ServiceScheduleResponse {
  service_type: ServiceType
  schedule_entries: ServiceScheduleEntry[]
}
export interface Entries {
  [date: string]: ServiceScheduleEntry[]
}

export interface Bookings {
  [date: string]: ServiceBooking[]
}

type Filters = Record<string, string | undefined>

type UpdateQuery = (key: string, value: string) => void

export default class ServiceScheduleStore extends APIStore {
  serviceType?: ServiceType
  @observable week: Duration
  @observable entries: Entries = {}
  @observable bookings: Bookings = {}

  @observable filters: Filters = { instructorId: "", durationId: "" }
  @observable bookingsStore: BookingsStore

  // remove the SetStatusMiddleware to make sure the overall status
  // reflects both the schedule and booking fetch statuses
  defaultMiddleware = []
  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("service_type", ServiceType),
    DeserializeMiddleware("schedule_entries", ServiceScheduleEntry),
    TokenAuthMiddleware(this.brandStore.userStore)
  ])

  @computed get currentDateEntries() {
    return this.entries[this.date] || []
  }

  @computed get currentDateBookings() {
    const bookingsForDate = this.bookings[this.date] || []

    return bookingsForDate.filter(entry => {
      return bookingStatusMap[entry.status]!.cancelled == false
    })
  }

  @computed get instructorId() {
    return this.filters.instructorId
  }

  @computed get durationId() {
    return this.filters.durationId
  }

  @computed get filteredEntries() {
    const entries = this.currentDateEntries.filter(entry => {
      if (this.instructorId && entry.instructorId !== this.instructorId)
        return false

      if (this.durationId && entry.serviceDuration.id !== this.durationId)
        return false

      // TODO: could handle other cases as well
      if (entry.tooLate) return false

      // Remove entries that conflict with an existing booking
      // NOTE: This could be done more efficiently with uglier code that
      // walks the combined array after merging and sorting, but this is
      // clearer and perf should be OK given that normally there's 0 or 1
      // existing bookings on a given day
      if (
        this.currentDateBookings.some(
          booking =>
            booking.scheduleEntry.startsAt < entry.endsAt &&
            booking.scheduleEntry.endsAt > entry.startsAt
        )
      ) {
        return false
      }

      return true
    })

    return sortBy(
      [...entries, ...this.currentDateBookings],
      item => item.startsAt
    )
  }

  @computed get instructors() {
    if (!this.serviceType) return []

    return sortBy(this.serviceType.instructors || [], "name")
  }

  @computed get durations() {
    if (!this.serviceType) return []

    return sortBy(this.serviceType.durations, "durationMinutes")
  }

  @computed get date(): string {
    return String(this.week.date)
  }

  @computed get isLoaded(): boolean {
    return Boolean(this.entries[this.date])
  }

  constructor(
    public serviceTypeId: string,
    public locationSummary: LocationSummary,
    public brandStore: BrandStore,
    private updateQuery: UpdateQuery
  ) {
    super()
    this.week = new Duration({ anchor: "week_starting_now", duration: 7 })
    this.bookingsStore = new BookingsStore(brandStore, false)
  }

  @action
  fetch(locationId?: string) {
    const url = this.brandStore.isXponential
      ? `/api/xpass/v2/service_types/${this.serviceTypeId}?location_id=${locationId}&date=${this.date}`
      : `/api/locations/${this.locationSummary.id}/service_types/${this.serviceTypeId}?date=${this.date}`
    const location = this.brandStore.isXponential ? locationId : undefined

    this.setStatus("loading")
    return Promise.all([
      this.fetchBookings(location),
      this.api.get(url),
    ]).then(results => {
      this.setStatus("loaded")
      return results
    })
  }

  @action.bound
  setDate(date: string) {
    this.week.date = date
  }

  @action
  fetchBookings(locationId?: string) {
    return this.bookingsStore
      .fetch({
        start_date: this.date,
        end_date: moment(this.week.date)
          .add(1, "d")
          .format("YYYY-MM-DD"),
        location_id: locationId ? locationId : this.locationSummary.id,
      })
      .then(response => {
        this.bookings[this.date] = this.bookingsStore.serviceBookings
        return response
      })
  }

  @action
  setDefaultDuration() {
    if (
      !this.durationId ||
      !this.durations.find(duration => duration.id === this.durationId)
    ) {
      // don't have a valid duration set, so use the first one
      this.updateQuery("durationId", this.durations[0].id)
    }
  }

  @action.bound
  handleSuccess(res: AxiosResponse<ServiceScheduleResponse>) {
    // if the user navigates quickly, the current date may not match the request
    // being handled, so look at the schedule entries in the response to get the correct date
    const dateKey = this.responseDateKey(res.data.schedule_entries)
    this.serviceType = res.data.service_type
    this.entries[dateKey] = res.data.schedule_entries || []
    this.entries[dateKey].forEach(entry => {
      entry.instructor = this.serviceType!.instructors!.find(
        i => i.id === entry.instructorId
      )!
    })
    this.setDefaultDuration()
    return res
  }

  // if we don't get any entries, we don't know the date
  // stash the non-results in a dummy key and `filteredEntries` will return [] anyway
  // downside is we'll re-fetch that date again if the user navs away and back
  private responseDateKey(scheduleEntries?: Array<ServiceScheduleEntry>) {
    return scheduleEntries && scheduleEntries.length > 0
      ? scheduleEntries[0].startsAt.format("YYYY-MM-DD")
      : "unknown"
  }
}
