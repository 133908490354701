import React,{useEffect} from "react"
import ClaimAccountsPurchase from "apps/account/components/xpass/ClaimAccountsPurchase"
import logoUrl from "images/logos/xpass-logo-dark.svg"

const PurchaseSuccess: React.FC = () => {
  useEffect(() => {
    const headerEl = document.getElementsByClassName("main-menu") as HTMLCollection;
    const logoEl = document.getElementsByClassName("main-menu__logo") as HTMLCollection;
    const bodyEl = document.querySelector("body") as HTMLElement;
    const logoOriginal = logoEl[0].getAttribute('src') as string

    logoEl[0].setAttribute('src', logoUrl)
    headerEl[0].classList.add('light-nav')
    headerEl[0].classList.remove('bg-dark')
    bodyEl.classList.add("xpass-theme")

    return () => {
        logoEl[0].setAttribute('src', logoOriginal)
        headerEl[0].classList.remove('light-nav')
        headerEl[0].classList.add('bg-dark')
        bodyEl.classList.remove("xpass-theme")
    }

  }, [])

  return (
    <div>
      <ClaimAccountsPurchase  />
    </div>
  )
}

export default PurchaseSuccess