import range from "lodash/range"

const minMensSize = 3
const maxMensSize = 16
const maxWomensSize = 11
const sexOffset = 1.5

// for populating shoeSize select menu. only used by cyclebar
const shoeSizes = range(minMensSize, maxMensSize + 0.5, 0.5).map(mensSize => {
  let label = `${mensSize} Mens`
  const womensSize = mensSize + sexOffset
  if (womensSize <= maxWomensSize) label += ` / ${womensSize} Womens`
  return [mensSize.toString(10), label] as const
})

export default shoeSizes
