import * as React from "react"
import cx from "classnames"
import { observer } from "mobx-react"
import { computed } from "mobx"
import range from "lodash/range"
import padStart from "lodash/padStart"
import monthNames from "helpers/monthNames"

import FormControl, { InnerProps } from "components/forms/FormControl"

type SafeInput = Omit<
  React.SelectHTMLAttributes<HTMLInputElement>,
  "value" | "name" | "children"
>

interface OuterProps extends SafeInput {
  name: string
  className?: string
  label?: string
}

const FormDatePicker: React.SFC<OuterProps> = observer(outerProps => {
  const { className, label, name } = outerProps
  return (
    <FormControl
      className={className}
      label={label}
      name={name}
      children={props => (
        <FormDatePickerInner {...props} outerProps={outerProps} />
      )}
    />
  )
})

export interface FDPInnerProps extends InnerProps {
  outerProps: OuterProps
}

@observer
class FormDatePickerInner extends React.Component<FDPInnerProps, {}> {
  handleChange = (e: SelectEvent) => {
    const newVals = []
    const { inputValues, inputNames } = this
    for (let i = 0; i < 3; i++) {
      if (e.currentTarget.name === inputNames[i]) {
        newVals.push(e.currentTarget.value)
      } else {
        newVals.push(inputValues[i])
      }
    }
    this.props.form.setFieldValue(this.props.outerProps.name, newVals.join("-"))
  }

  // [YYYY, MM, DD]
  @computed
  get inputValues() {
    const val = this.props.field.value
    if (!val) return ["", "", ""]

    return val.split("-")
  }

  @computed get inputNames() {
    const name = this.props.outerProps.name
    return [`${name}_year`, `${name}_month`, `${name}_day`]
  }

  public render() {
    const {
      id,
      field,
      className,
      form,
      outerProps: { name, disabled },
    } = this.props
    const [year, month, day] = this.inputValues
    const [yearName, monthName, dayName] = this.inputNames

    return (
      <div className="dob-component row" id={id} {...field}>
        <div className="form-group col-sm-6 mb-0">
          <select
            disabled={disabled || form.isSubmitting}
            name={monthName}
            className={className}
            onChange={this.handleChange}
            // get rid of this if using for other types of dates
            autoComplete="birthday-month"
            value={month}
          >
            <option value="" disabled hidden />
            {monthNames.map(m => (
              <option key={`month:${m.code}`} value={m.code}>
                {m.name}
              </option>
            ))}
          </select>
          <small className="form-text text-muted">Month</small>
        </div>
        <div className="form-group col-6 col-sm-3 mb-0">
          <select
            disabled={disabled || form.isSubmitting}
            name={dayName}
            className={className}
            onChange={this.handleChange}
            // get rid of this if using for other types of dates
            autoComplete="birthday-day"
            value={day}
          >
            <option value="" disabled hidden />
            {range(1, 32).map(dayNumber => (
              <option
                key={`day:${dayNumber}`}
                value={padStart(dayNumber.toString(), 2, "0")}
              >
                {dayNumber}
              </option>
            ))}
          </select>
          <small className="form-text text-muted">Day</small>
        </div>
        <div className="form-group col-6 col-sm-3 mb-0">
          <select
            disabled={disabled || form.isSubmitting}
            name={yearName}
            className={className}
            onChange={this.handleChange}
            // get rid of this if using for other types of dates
            autoComplete="birthday-year"
            value={year}
          >
            <option value="" disabled hidden />
            {range(new Date().getFullYear() - 13, 1899, -1).map(yearNumber => (
              <option key={`year:${yearNumber}`} value={yearNumber}>
                {yearNumber}
              </option>
            ))}
          </select>
          <small className="form-text text-muted">Year</small>
        </div>
      </div>
    )
  }
}

export default FormDatePicker
