import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("ClassTypeSummary")
export default class ClassTypeSummary {
  /**
   * Globally unique ID of the class type.
   */
  @JsonProperty("id", String, true)
  id?: string = undefined

  @JsonProperty("name", String)
  name: string = undefined!
}
