import React, { Component } from "react"
import { RouteComponentProps } from "react-router"
import { inject, observer } from "mobx-react"
import * as cx from "classnames"
import * as moment from "moment"
import sortBy from "lodash/sortBy"

import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import ScheduleWeekNav from "apps/book/components/ScheduleWeekNav"
import ScheduleDayNav from "apps/book/components/ScheduleDayNav"
import QueryStringMonitor from "apps/book/stores/QueryStringMonitor"
import ExpandedEntryStore from "apps/book/stores/ExpandedEntryStore"
import ScheduleStore from "apps/book/stores/ScheduleStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import LocationDetailStore from "apps/xpass/stores/LocationDetailStore"

export interface Props extends RouteComponentProps {
  store?: BrandStore
  scheduleStore: ScheduleStore
  scheduleEntryStore: ScheduleEntryStore
  onBookingModal: Function
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class LocationSchedulePage extends Component<Props> {
  locationDetailStore = new LocationDetailStore()
  private param = new URLSearchParams(window.location.search)
  private instructor = this.param.get("instructor_id")
  private location = this.param.get("location_id")

  private expandedEntryStore = new ExpandedEntryStore(this.props.scheduleStore)
  private qsMonitor = new QueryStringMonitor(
    this.props.scheduleStore,
    this.props.store!,
    this.expandedEntryStore
  )

  componentWillUnmount() {
    this.qsMonitor.dispose()
    this.expandedEntryStore.dispose()
  }

  render() {
    const { scheduleStore } = this.props

    return (
      <div className="container py-3 py-xl-4 mt-4">
        <APILoader
          apiStore={this.locationDetailStore}
          fetchProps={this.location}
          render={() => {
            const { location, details } = this.locationDetailStore

            return (
              <APILoader
                apiStore={scheduleStore}
                fetchProps={{ locations: this.location }}
                alreadyLoaded={scheduleStore.week.loaded}
                key={scheduleStore.week.startDate}
                render={() => {
                  const { entries, week } = this.props.scheduleStore

                  const dayEntries = sortBy(entries, "startsAt").filter(
                    entry => {
                      if (this.instructor) {
                        return (
                          entry.startsAt.format("YYYY-MM-DD") === week.date &&
                          entry.instructor!.name === this.instructor
                        )
                      }
                      return entry.startsAt.format("YYYY-MM-DD") === week.date
                    }
                  )

                  return (
                    <>
                      <h1 className="text-center text-lg-left">
                        {details && details.brandName}{" "}
                        {location && location.name} Schedule
                      </h1>
                      <ScheduleWeekNav
                        week={week}
                        className="mt-5 mb-4 mb-lg-5"
                        scheduleEntryStore={this.props.scheduleEntryStore}
                      />
                      <ScheduleDayNav
                        week={week}
                        pathname={this.props.location.pathname}
                        search={this.props.location.search}
                        styleClasses={this.props.store!.styleClasses}
                      />

                      <div className="row">
                        <table className="col table schedule-table">
                          <tbody className="schedule-tbody rows-in">
                            {!dayEntries.length && (
                              <tr className="location-schedule">
                                <td>
                                  <div>
                                    <h3 className="mt-5">
                                      It looks like there are no classes
                                      available
                                    </h3>
                                  </div>
                                </td>
                              </tr>
                            )}

                            {dayEntries.map((entry, index) => {
                              const endOfWeek = moment()
                                .add(7, "days")
                                .utc()
                                .format("YYYY-MM-DD")
                              const bookable = entry.startsAt.isBefore(
                                endOfWeek
                              )
                                ? true
                                : false

                              return (
                                <tr className="location-schedule" key={index}>
                                  {/* Brand icon */}
                                  <td className="location-schedule__icon">
                                    <div>
                                      <img
                                        className="w-100"
                                        src={this.props.scheduleEntryStore.getBrandIcon(
                                          entry.brand.id
                                        )}
                                      />
                                    </div>
                                  </td>
                                  {/* Class name */}
                                  <td className="location-schedule__title py-4">
                                    <div
                                      className={cx(
                                        "d-flex",
                                        "flex-wrap",
                                        "justify-content-between",
                                        "align-items-center"
                                      )}
                                    >
                                      <div>
                                        <h3 className="m-0">{entry.title}</h3>
                                      </div>
                                    </div>
                                  </td>
                                  {/* Location name */}
                                  <td className="location-schedule__location py-1 py-md-4">
                                    {entry.brand && entry.brand.name}{" "}
                                    {entry.location && entry.location.name}
                                  </td>
                                  {/* Time + duration */}
                                  <td className="location-schedule__time py-1 py-md-4 align-middle">
                                    {entry.startsAt.format("h:mm A")}{" "}
                                    <span className="d-block">
                                      {entry.duration} min
                                    </span>
                                  </td>
                                  {/* Instructor name */}
                                  <td className="location-schedule__instructor py-1 py-md-4">
                                    {entry.instructor && entry.instructor.name}
                                  </td>
                                  {/* Credits */}
                                  <td className="py-1 py-md-4">
                                    {entry.userPremiumCost!.formatted}
                                  </td>
                                  {/* Book button */}
                                  <td className="schedule-actions py-4 pl-2 text-right">
                                    {bookable && (
                                      <button
                                        className="btn btn-primary text-nowrap px-0"
                                        onClick={() =>
                                          this.props.onBookingModal(entry)
                                        }
                                      >
                                        Book
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )
                }}
              />
            )
          }}
        />
      </div>
    )
  }
}
