import * as Sentry from "@sentry/browser"

export function loadState(key: string, isSession: boolean = false) {
  Sentry.addBreadcrumb({
    level: Sentry.Severity.Debug,
    message: `loadState`,
    category: "savedState",
    data: { key, isSession },
  })

  // TODO: this should probably try both sessionStorage and localStorage
  const serializedState = store(isSession).getItem(`xpo:SavedState:${key}`)
  if (serializedState) {
    try {
      const state = JSON.parse(serializedState)

      Sentry.addBreadcrumb({
        level: Sentry.Severity.Info,
        message: `loadedState`,
        category: "savedState",
        data: { key, isSession, state },
      })

      return state
    } catch (ex) {
      Sentry.withScope(scope => {
        scope.setExtras({ key, isSession, serializedState })
        Sentry.captureException(ex)
      })

      console.error(ex, key, serializedState)
    }
  }
  return undefined
}

// tslint:disable-next-line:no-any
export function saveState(key: string, value: any, isSession: boolean = false) {
  Sentry.addBreadcrumb({
    level: Sentry.Severity.Info,
    message: `saveState`,
    category: "savedState",
    data: { key, value, isSession },
  })
  // undefined doesn't like being json
  if (value === undefined) value = null
  store(isSession).setItem(`xpo:SavedState:${key}`, JSON.stringify(value))
}

export function clearState(key: string, isSession: boolean = false) {
  Sentry.addBreadcrumb({
    level: Sentry.Severity.Info,
    message: `clearState`,
    category: "savedState",
    data: { key, isSession },
  })
  store(isSession).removeItem(`xpo:SavedState:${key}`)
}

function store(isSession: boolean = false) {
  return isSession ? sessionStorage : localStorage
}
