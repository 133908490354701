import * as React from "react"
import { inject, observer } from "mobx-react"
import { observable } from "mobx"
import * as cx from "classnames"
import capitalize from "lodash/capitalize"

import LocationSummary from "models/LocationSummary"
import Offer from "models/Offer"
import PackagePlan from "models/PackagePlan"
import Package from "models/Package"
import Price from "models/Price"
import PurchaseSummary from "apps/account/models/PurchaseSummary"
import { isLoadedOrError } from "utils/LoadStatus"
import { setLocal } from "utils/LocalStorage"

import BaseStore from "stores/BaseStore"
import BrandStore from "stores/BrandStore"
import GiftCardStore from "apps/buy/stores/GiftCardStore"
import PlanStore from "apps/buy/stores/PlanStore"
import BookingInfoStore from "apps/buy/stores/BookingInfoStore"
import PaymentSourcesStore from "apps/account/stores/PaymentSourcesStore"
import PaymentSourceUpdateStore from "apps/account/stores/PaymentSourceUpdateStore"

import APILoader from "components/wrappers/APILoader"
import Spinner from "components/Spinner"
import PaymentSourcePanel from "apps/account/components/PaymentSourcePanel"
import GiftCardForm from "apps/buy/components/GiftCardForm"
import PromoCodeForm from "apps/buy/components/PromoCodeForm"
import PurchaseForm from "apps/buy/components/PurchaseForm"
import PackageRenewalText from "apps/buy/components/PackageRenewalText"
import MissingPackagePanel from "apps/buy/components/MissingPackagePanel"
import PageTracker from "components/wrappers/PageTracker"
import thirdPartyScript from "helpers/thirdPartyScript"

export interface Props {
  locationSummary: LocationSummary
  packageId: string
  store?: BrandStore
  bookingInfoStore?: BookingInfoStore
  offer?: Offer
  bookAndConfirm?: boolean
}

export interface PlanInfo {
  pkg?: Package
  plan?: PackagePlan
  promoCode?: string
  discount?: number
  originalPrice?: Price
}

export interface GiftCardInfo {
  paymentAmount?: number
  todayTotal?: number
  originalPrice?: Price
}

type PurchaseType = "booking" | "other"

@inject((store: BrandStore) => ({ store }))
@observer
export default class PaymentPage extends React.Component<Props, {}> {
  planStore = new PlanStore(
    this.props.store!,
    this.props.packageId,
    this.props.locationSummary,
    this.props.offer
  )

  paymentSourcesStore = new PaymentSourcesStore(this.props.store!)
  paymentSourceUpdateStore = new PaymentSourceUpdateStore(
    this.paymentSourcesStore
  )
  tmpStore = new BaseStore()

  giftCardStore = new GiftCardStore(
    this.planStore,
    this.planStore.brandStore,
    this.planStore.packageId,
    this.planStore.locationSummary,
  )

  @observable purchaseOrder: PurchaseSummary | undefined
  // if any more fields get added here let's use formik

  get purchaseType(): PurchaseType {
    if (this.forClassOrService) {
      return "booking"
    } 
    else {
      return "other"
    }
  }

  // text for the button displayed after the purchase is totally complete
  get continueButtonText(): string | undefined {
    const { comingSoon } = this.props.locationSummary
    const { copy } = this.props.store!

    switch (this.purchaseType) {
      case "booking":
        return "CONFIRM BOOKING"
      case "other":
      default:
        return comingSoon
          ? "Return to my Dashboard"
          : `Book a ${capitalize(copy.class)}`
    }
  }

  get forClassOrService() {
    const { bookingInfoStore } = this.props
    return Boolean(bookingInfoStore && bookingInfoStore.hasBookingInfo)
  }

  componentDidMount() {
    if (this.props.store!.isXponential) {
      thirdPartyScript({
        id: "sleeknoteScript",
        src: "//sleeknotecustomerscripts.sleeknote.com/56834.js",
        charset: "utf-8",
      })
    }
    this.props.store!.uiStore.hideNavLinks()
    this.props.store!.uiStore.lockLocationPicker()

    this.tmpStore.autorun(() => {
      if (this.purchaseOrder || this.planStore.status === "error") {
        this.props.store!.uiStore.showNavLinks()
      } else {
        this.props.store!.uiStore.hideNavLinks()
      }
    })

    // TODO: Remove this once QA is able to reproduce the scenario
    // Where another person booked the session successfully
    // While the first person is in the process of paying
    // Remove this once QA is done for purchase-and-book flow
    const timeOutSet = window.location.href.indexOf("___testtimeout___") > -1
    const timeoutValue = new URLSearchParams(window.location.search).get(
      "___testtimeout___"
    )
    const testTimeout = timeOutSet
      ? timeoutValue
        ? parseInt(timeoutValue, 10)
        : 1
      : 0
    setLocal("testTimeout", String(testTimeout * 1000))
  }

  componentWillUnmount() {
    this.props.store!.uiStore.showNavLinks()

    this.planStore.dispose()
    this.paymentSourcesStore.dispose()
    this.paymentSourceUpdateStore.dispose()
  }

  pageName() {
    if (this.props.offer && this.purchaseOrder) {
      return "offer > purchase success"
    } else if (this.props.offer) {
      return "offer > detail"
    } else if (this.purchaseOrder) {
      return "buy > purchase success"
    } else {
      return "buy > detail"
    }
  }

  handlePurchase = (purchase: PurchaseSummary) => {
    window.scrollTo(0, 0)
    this.purchaseOrder = purchase
    const bookAndConfirm = this.props.bookAndConfirm || false

    // For class booking flow, this will bypass showing SuccessColumn
    if (
      bookAndConfirm &&
      this.purchaseOrder &&
      this.purchaseType === "booking"
    ) {
      this.updateRouteBasedOnPurchaseType()
    }
  }

  handleContinueClick = (e: ButtonEvent) => {
    e.preventDefault()
    this.updateRouteBasedOnPurchaseType()
  }

  updateRouteBasedOnPurchaseType = () => {
    const { routingStore } = this.props.store!
    const bookAndConfirm = this.props.bookAndConfirm || false
    const planInfo: PlanInfo = {
      pkg: this.planStore.package!,
      plan: this.planStore.plan,
      promoCode: this.planStore.promoCode,
      discount: this.planStore.discount,
      originalPrice: this.planStore.originalPrice!,
    }
    const giftCardInfo: GiftCardInfo = {
      paymentAmount: this.giftCardStore.paymentAmount,
      todayTotal: this.giftCardStore.todayTotal,
      // can't use this.planStore.originalPrice! because it's undefined if there's no promoCode
      originalPrice: this.planStore.originalPlan!.todayTotal,
    }

    switch (this.purchaseType) {
      case "booking":
        routingStore.push(this.props.bookingInfoStore!.bookingPath, {
          bookAndConfirm,
          planInfo,
          giftCardInfo,
        })
        break
      case "other":
      default:
        !this.props.store!.isXponential && this.props.store!.settings.serviceBooking
          ? routingStore.push(`/book/${this.props.locationSummary.id}/services`)
          : routingStore.push(`/book/${this.props.locationSummary.id}`)
        break
    }
  }

  render() {
    const offer = this.props.offer
    const pageName = this.pageName()
    if (this.planStore.status === "error") {
      return <MissingPackagePanel planStore={this.planStore} offer={offer} />
    }

    return (
      <div className="col">
        <div className="row">
          <PageTracker
            key={pageName}
            name={pageName}
            extra={{ packageInBookingFlow: this.forClassOrService }}
          />
          <APILoader
            apiStore={this.planStore}
            render={() => {
              const pkg = this.planStore.package!
              if (pkg.isFree && !this.purchaseOrder) {
                return (
                  <div className="col-md-6">
                    <PaymentPageHeading offer={offer} />
                    <PurchaseForm
                      planStore={this.planStore}
                      paymentSourcesStore={this.paymentSourcesStore}
                      onPurchase={this.handlePurchase}
                    >
                      Continue
                    </PurchaseForm>
                  </div>
                )
              }

              return (
                <>
                  {!this.purchaseOrder ? (
                    <div className="col purchase-col">
                      <PaymentPageHeading offer={offer} />
                      <PaymentSourcePanelWrapper
                        paymentSourcesStore={this.paymentSourcesStore}
                        paymentSourceUpdateStore={this.paymentSourceUpdateStore}
                      />
                    </div>
                  ) : (
                    <SuccessColumn
                      purchaseOrder={this.purchaseOrder!}
                      forClassOrService={this.forClassOrService}
                      onContinueClick={this.handleContinueClick}
                      continueButtonText={this.continueButtonText}
                      locationSummary={this.props.locationSummary}
                      showAppDownloadButtons={false}
                    />
                  )}
                  <div
                    className={cx(
                      "card col-md-5 ml-md-auto text-center purchase-card",
                      this.props.store!.styleClasses.PaymentPage__purchaseCard,
                    )}
                  >
                    <div className="card-body">
                      <h3 className="card-title">{pkg.name}</h3>
                      <PackageRenewalText pkg={pkg} paymentPage store={this.props.store!} />

                      {!this.purchaseOrder && !this.props.store!.isXponential && this.props.store!.settings.showGiftCardBalanceLink && (
                        <GiftCardForm
                          planStore={this.planStore}
                          giftCardStore={this.giftCardStore}
                        />
                      )}

                      {!this.purchaseOrder && (
                        <PromoCodeForm planStore={this.planStore} />
                      )}

                      <hr className="mt-4 mb-4" />
                      <PlanPricing
                        planStore={this.planStore}
                        giftCardStore={this.giftCardStore}
                      />
                      <hr />

                      {!this.purchaseOrder && (
                        <PurchaseForm
                          planStore={this.planStore}
                          giftCardStore={this.giftCardStore}
                          paymentSourcesStore={this.paymentSourcesStore}
                          onPurchase={this.handlePurchase}
                        />
                      )}
                    </div>
                  </div>
                </>
              )
            }}
          />
        </div>
      </div>
    )
  }
}

interface PaymentPageHeadingProps {
  store?: BrandStore
  offer?: Offer
}

const PaymentPageHeading: React.FunctionComponent<PaymentPageHeadingProps> = inject(
  (store: BrandStore) => ({ store })
)(
  observer(({ offer, store }) => {
    const [name, message] = offer
      ? [offer.name, offer.description]
      : [
        `${store!.copy.buyCredits}`,
        "You're almost done, we just need a little more information.",
      ]

    return (
      <div className="text-left mb-4">
        <h1>{name}</h1>
        <p>{message}</p>
      </div>
    )
  })
)

interface PaymentSourcePanelWrapperProps {
  paymentSourcesStore: PaymentSourcesStore
  paymentSourceUpdateStore: PaymentSourceUpdateStore
}

const PaymentSourcePanelWrapper: React.FunctionComponent<
  PaymentSourcePanelWrapperProps
> = observer(({ paymentSourcesStore, paymentSourceUpdateStore }) => {
  return (
    <div>
      <h3 className="text-left mb-3">Payment Method</h3>
      <hr />
      <PaymentSourcePanel
        small
        className="mb-4"
        paymentSourcesStore={paymentSourcesStore}
        paymentSourceUpdateStore={paymentSourceUpdateStore}
        isPaymentPage={true}
      />
    </div>
  )
})
interface SuccessColumnProps {
  store?: BrandStore
  purchaseOrder: PurchaseSummary
  locationSummary: LocationSummary
  forClassOrService: boolean
  continueButtonText?: string
  showAppDownloadButtons: boolean
  onContinueClick: (e: ButtonEvent) => void
}

@inject((store: BrandStore) => ({ store }))
@observer
class SuccessColumn extends React.Component<SuccessColumnProps> {

  constructor(props: SuccessColumnProps) {
    super(props);
  }

  @observable isButtonDisabled = this.props.store!.isXponential

  componentDidMount() {
    // we need this to avoid ClubReady delay error with canBook property
    if (this.props.store!.isXponential) {
    setTimeout(() => {
      this.isButtonDisabled = false
    }, 3000)
  }
  }

  render() {
    const {
      store,
      purchaseOrder,
      locationSummary,
      onContinueClick,
      forClassOrService,
      continueButtonText,
      showAppDownloadButtons,
    } = this.props

    return (
      <div className="payment-confirmation col mb-5">
        <div className="text-left mb-4">
          {forClassOrService ? (
            <h1>
              Purchase Complete
            <br />
              Please Confirm Your Booking
          </h1>
          ) : (
              <h1>Your Purchase is Complete</h1>
            )}
            <span><b>Order number:&nbsp;</b></span>
            <span> {purchaseOrder.id}</span>
          <p>
            {`A receipt has been sent to ${store!.userStore.session!.email}`}
          </p>
          {/* TODO: Add final copy */}
          <p>Thanks for your purchase!</p>
          {locationSummary.comingSoon && (
            <p>You'll receive an email notification when this studio opens.</p>
          )}
          {forClassOrService && (
            <p>
              <strong>
                Your booking is not complete until you confirm it on the
                following page.
            </strong>
            </p>
          )}
        </div>
        {continueButtonText && (
          <button disabled={forClassOrService && this.isButtonDisabled} className="btn btn-primary" onClick={onContinueClick}>
            {forClassOrService && this.isButtonDisabled ? <Spinner className="deficient-purchase-spinner" size={1.2} /> : continueButtonText}
          </button>
        )}
        {showAppDownloadButtons && <AppVideoDownloadButtons />}
      </div>
    )
  }
}

interface AppVideoDownloadButtonsProps {
  store?: BrandStore
}

const AppVideoDownloadButtons: React.FunctionComponent<AppVideoDownloadButtonsProps> = inject(
  (store: BrandStore) => ({ store })
)(
  observer(({ store }) => {
    const { iosAppUrl, androidAppUrl } = store!.brand
    const links = [
      { url: iosAppUrl, title: "Download for iOS" },
      { url: androidAppUrl, title: "Download for Android" },
    ].filter(link => link.url)

    return links.length === 0 ? null : (
      <>
        <hr />

        <h4 className="mt-5 mb-3">Watch Videos on the Mobile App!</h4>
        {links.map(({ url, title }) => (
          <a
            key={url}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary purchase-app-button"
          >
            {title}
          </a>
        ))}
      </>
    )
  })
)

interface PlanPricingProps {
  planStore: PlanStore
  giftCardStore: GiftCardStore
}
// TODO: observer
const PlanPricing: React.FunctionComponent<PlanPricingProps> = observer(({ planStore, giftCardStore }) => {
  if (!planStore.plan || !isLoadedOrError(planStore.status)) {
    return <Spinner size="element" />
  }

  const { plan, promoCode, discount, discountApplied } = planStore
  const queryParams = new URLSearchParams(document.location.search);

  return (
    <>
      {
        !queryParams.has('subtotal') && (
          <>
            {plan.payments
              .filter(p => p.isDueToday)
              .map((payment, i) => (
                <div className="row payment-order-line" key={i}>
                  <div className="col-md-8 text-left">Subtotal </div>
                  <div className="col-md-4 text-right">
                    {payment.subtotal.formatted}
                  </div>
                </div>
            ))}
          </>
        )
      }
    

      {plan.fees.length > 0 && <hr />}
      {plan.fees
        .filter(f => f.isDueToday)
        .map((fee, i) => (
          <div className="row payment-order-line" key={i}>
            <div className="col-md-8 text-left">{fee.name} </div>
            <div className="col-md-4 text-right">{fee.subtotal.formatted}</div>
          </div>
        ))}

      <div className="row payment-order-line">
        <div className="col-md-8 text-left">Tax </div>
        <div className="col-md-4 text-right">{plan.todayTax.formatted} </div>
      </div>
      <hr />
      {((promoCode && discount > 0) || giftCardStore.paymentAmount > 0) && (
        <>
          <div className="row payment-order-total text-muted">
            <div className="col-md-8 text-left">Original Total </div>
            <div className="col-md-4 text-right">
              {giftCardStore.paymentAmount > 0 ? planStore.originalPlan!.todayTotal.formatted : planStore.originalPrice!.formatted}
            </div>
          </div>
        </>
      )}
      {promoCode && discount > 0 && (
        <>
          <div className="row payment-order-line">
            <div className="col-md-8 text-left text-success">
              Discount Code {promoCode}
            </div>
            <div className="col-md-4 text-right text-success">
              - ${discount.toFixed(2)}
            </div>
          </div>
        </>
      )}
      {giftCardStore.paymentAmount > 0 && (
        <>
          <div className="row payment-order-line">
            <div className="col-md-8 text-left text-success">
              Gift Card
            </div>
            <div className="col-md-4 text-right text-success">
              - ${giftCardStore.paymentAmount.toFixed(2)}
            </div>
          </div>
        </>
      )}
      <div className="row payment-order-total">
        <div className="col-md-8 text-left ">Order Total </div>
        <div className="col-md-4 text-right">${giftCardStore.todayTotal.toFixed(2)}</div>
      </div>
    </>
  )
})
