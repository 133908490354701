import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosError } from "axios"
import moment from "moment"
import getIn from "lodash/get"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import { observable, action, computed } from "mobx"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import InstructorSummary from "models/InstructorSummary"
import LocationSummary from "models/LocationSummary"
import BrandStore from "stores/BrandStore"
import { ScheduleEntrySummary } from "apps/book/components/BookingInfoCard"
import ServiceBooking from "apps/book/models/ServiceBooking"
import ServiceBookingStatus from "apps/book/models/ServiceBookingStatus"
import ServiceDuration from "apps/book/models/ServiceDuration"
import ServiceScheduleEntry from "apps/book/models/ServiceScheduleEntry"
import ServiceTypeSummary from "apps/book/models/ServiceTypeSummary"
import {
  reasonCodeToUserMessage,
  BookingReasonCode,
} from "helpers/bookingStatusMessages"
import { formatTime } from "helpers/dateHelper"

export interface ServiceBookingResponse {
  booking: ServiceBooking
  location?: LocationSummary
  service_booking_status: ServiceBookingStatus
  instructor: InstructorSummary
  service_duration: ServiceDuration
  service_type: ServiceTypeSummary
}

export interface ServiceBookingProps {
  durationId: string
  instructorId: string
  startsAt: string
  locationId?: string
  userPremiumCost?: number
}

export default class ServiceBookingStore extends APIStore {
  @observable booking?: ServiceBooking
  @observable bookingProps?: ServiceBookingProps
  @observable bookingStatus?: ServiceBookingStatus
  @observable instructor?: InstructorSummary
  @observable serviceDuration?: ServiceDuration
  @observable serviceType?: ServiceTypeSummary
  @observable error?: any = undefined

  api = this.createClient<ServiceBookingResponse>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    DeserializeMiddleware("booking", ServiceBooking, true),
    DeserializeMiddleware("service_booking_status", ServiceBookingStatus, true),
    DeserializeMiddleware("instructor", InstructorSummary, true),
    DeserializeMiddleware("location", LocationSummary, true),
    DeserializeMiddleware("service_duration", ServiceDuration, true),
    DeserializeMiddleware("service_type", ServiceTypeSummary, true),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  @computed get packageNeeded() {
    return this.bookingStatus && this.bookingStatus.status === "needs_package"
  }

  @computed get isCrossClub() {
    return this.bookingStatus && this.bookingStatus.status === "cross_club"
  }

  @computed get readyToBook() {
    return this.bookingStatus && this.bookingStatus.canBook
  }

  @computed get shouldShowErrorModal() {
    return (
      this.bookingStatus &&
      !this.readyToBook &&
      !this.packageNeeded &&
      !this.isCrossClub
    )
  }

  @computed get errorModalProps() {
    if (!this.shouldShowErrorModal) {
      return null
    }

    let code: BookingReasonCode
    if (this.booking && this.booking.status !== "booked") {
      // TODO: Booking failed. We should have caught this at the status stage!
      code = "booking_failed"
    } else if (this.bookingStatus!.status === "booked") {
      // CR gives the "booked" status whether or not the user's booking matches
      // the exact service, start time, duration, and instructor requested
      code = "user_booked"
    } else {
      code = this.bookingStatus!.reasons[0].code
    }
    const copy = this.brandStore.copy
    const { title, message } = reasonCodeToUserMessage(code, copy.session, copy)

    return {
      title,
      message,
    }
  }

  @computed get durationId() {
    return this.bookingProps && this.bookingProps.durationId
  }

  @computed get instructorId() {
    return this.bookingProps && this.bookingProps.instructorId
  }

  @computed get startsAt() {
    return this.bookingProps && this.bookingProps.startsAt
  }

  @computed get locationId() {
    return this.bookingProps && this.bookingProps.locationId
  }

  @computed get userPremiumCost() {
    return this.bookingProps && this.bookingProps.userPremiumCost
  }

  @computed get isBusy() {
    return this.status === "loading"
  }

  // Used to display the booking info cards
  @computed get scheduleEntrySummary(): ScheduleEntrySummary | undefined {
    if (!this.bookingStatus!) return undefined
    const { startsAt, endsAt, startTime, endTime, brandId, brandName, locationName } = this.bookingStatus!.scheduleEntry!
    const duration = this.serviceDuration!.durationMinutes
    return {
      title: `${duration} minute ${this.brandStore.copy.session}`,
      instructor: this.instructor,
      startsAt: startsAt,
      endsAt: endsAt,
      startTime: startTime,
      endTime: endTime,
      brandId: brandId,
      brandName: brandName,
      locationName: locationName,
    }
  }

  constructor(
    public locationSummary: LocationSummary,
    public brandStore: BrandStore
  ) {
    super()
  }

  @action.bound
  setError(error: any) {
    this.error = error
  }

  @action
  reset() {
    this.status = "idle"
    this.booking = undefined
    this.bookingProps = undefined
    this.bookingStatus = undefined
    this.instructor = undefined
    this.serviceDuration = undefined
    this.serviceType = undefined
  }

  @action
  book() {
    this.status = "loading"

    if (this.brandStore.isXponential) {
      return this.api.post(
        `/api/xpass/v2/service_bookings`,
        {
          instructor_id: this.bookingProps!.instructorId,
          duration_id: this.bookingProps!.durationId,
          starts_at: moment(this.bookingProps!.startsAt).format(),
          location_id: this.bookingProps!.locationId,
          user_premium_cost: this.bookingProps!.userPremiumCost || null,
          platform: "web"
        }
      )
    }
    return this.api.post(
      `/api/locations/${this.locationSummary.id}/service_bookings`,
      {
        instructor_id: this.bookingProps!.instructorId,
        starts_at: this.bookingProps!.startsAt,
        duration_id: this.bookingProps!.durationId,
      }
    )
  }

  @action
  fetch(props: ServiceBookingProps) {
    this.status = "loading"
    this.bookingProps = props

    if (this.brandStore.isXponential) {
      return this.api.get(
        `/api/xpass/v2/service_bookings?location_id=${props.locationId}&instructor_id=${props.instructorId}&duration_id=${props.durationId}&starts_at=${props.startsAt}`
      )
    }
    return this.api.get(
      `/api/locations/${this.locationSummary.id}/service_bookings/instructors/${props.instructorId}/durations/${props.durationId}/start_times/${props.startsAt}`
    )
  }

  fetchFromEntry(entry: ServiceScheduleEntry) {
    const userPremiumCost = entry.userPremiumCost ? entry.userPremiumCost.raw : undefined
    const props = this.brandStore.isXponential
      ? {
          instructorId: entry.instructorId!,
          durationId: entry.serviceDuration.id,
          startsAt: entry.startsAt.toISOString(),
          locationId: entry.locationId!,
          userPremiumCost: userPremiumCost
        }
      : {
          instructorId: entry.instructorId!,
          durationId: entry.serviceDuration.id,
          startsAt: entry.startsAt.toISOString(),
        }

    return this.fetch(props)
  }

  isLoadedFor(props: ServiceBookingProps) {
    const loadedFor = this.brandStore.isXponential
      ? this.bookingStatus &&
        this.bookingProps &&
        this.bookingProps.durationId === props.durationId &&
        this.bookingProps.instructorId === props.instructorId &&
        this.bookingProps.startsAt === props.startsAt &&
        this.bookingProps.locationId === props.locationId &&
        this.bookingProps.userPremiumCost === props.userPremiumCost
      : this.bookingStatus &&
        this.bookingProps &&
        this.bookingProps.durationId === props.durationId &&
        this.bookingProps.instructorId === props.instructorId &&
        this.bookingProps.startsAt === props.startsAt

    return Boolean(loadedFor)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<ServiceBookingResponse>) {
    if (res.data.booking) {
      this.booking = res.data.booking
      if (this.booking.status !== "booked") {
        // TODO: This should have been caught at status stage. What went wrong?
        console.log(res)
      }
    } else if (res.data.service_booking_status) {
      this.bookingStatus = res.data.service_booking_status
      this.instructor = res.data.instructor
      this.serviceDuration = res.data.service_duration
      this.serviceType = res.data.service_type
    }

    if (this.errorModalProps) {
      this.brandStore.uiStore.openModal({
        type: "error",
        modalShow: true,
        ...this.errorModalProps,
      })
    }

    this.status = "loaded"

    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    this.status = "error"
    console.error(ex)
    // If instructor is already booked, set the error
    const errorCode = getIn(ex, "response.data.error_code")
    const errorMsg = getIn(ex, "response.data.message")
    const errorMessage = getIn(
      ex,
      "response.data.message",
      "Instructor is already booked"
    )
    if (errorCode === "instructor_booked" || errorMsg === "InstructorBooked") {
      this.setError({
        message: errorMessage,
      })
    }
    // TODO: if this is a 403 we want to redirect them through a login/registration
    // flow of some sort.
    throw ex
  }
}
