import * as React from "react"
import { observer, inject } from "mobx-react"
import * as cx from "classnames"
import BrandStore from "stores/BrandStore"
import Heading from "components/Heading"
import { Link } from "react-router-dom"
import BookingCreationStore from "apps/book/stores/BookingCreationStore"
import ScheduleEntry from "models/ScheduleEntry"
import BookingStatus from "models/BookingStatus"
import LocationSummary from "models/LocationSummary"
import BookingFlowStore from "apps/book/stores/BookingFlowStore"
import BookingInfoCard from "apps/book/components/BookingInfoCard"
import PageTracker from "components/wrappers/PageTracker"
import ScheduleEntryStore from "apps/xpass/stores/ScheduleEntryStore"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import tokenIcon from "images/xpass/token.png"

export interface Props {
  store?: BrandStore
  bookingFlowStore: BookingFlowStore
  bookingStatus: BookingStatus
  scheduleEntry: ScheduleEntry
  locationSummary: LocationSummary
  backPath: string
  seatId?: string
  seatLabel?: string
  bookAndConfirm?: boolean
  onBook(e: ButtonEvent): void
  performBook(): void
  onCancel?(e: LinkEvent): void
}

// TODO:
// - Handle states where they can't book or need a package (that can happen on
//   this page b/c they can navigate directly to it)
// - Once we can look up a schedule entry, load it so we can display instructor
//   info and stuff.
// - Handle different kind of credits and stuff.
@inject((store: BrandStore) => ({ store }))
@observer
export default class ConfirmBookingPage extends React.Component<Props, {}> {
  // TODO: if we don't have a ScheduleEntry we need to bail out for now,
  // since we can't look up an individual schedule entry

  scheduleEntryStore = this.props.store!.isXponential
    ? new ScheduleEntryStore(this.props.store!.userStore)
    : undefined
  balancesStore = this.props.store!.isXponential
    ? new BalancesStore(this.props.store!)
    : undefined

  get shouldBookImmediately() {
    if (!this.props.scheduleEntry.isFull && this.props.bookAndConfirm) {
      return true
    }
    return false
  }

  componentDidMount() {
    if (this.shouldBookImmediately) {
      this.props.performBook()
    }
    // TODO: this would be a good place to tell the UIStore we're in
    // interstitial mode, if that's something we want to do.

    if (this.scheduleEntryStore) {
      this.scheduleEntryStore.fetch(this.props.scheduleEntry.id)
    }
    if (this.balancesStore) {
      this.balancesStore.fetch()
    }
  }

  componentWillUnmount() {
    if (this.scheduleEntryStore) {
      this.scheduleEntryStore.dispose()
    }
    if (this.balancesStore) {
      this.balancesStore.dispose()
    }
  }

  public render() {
    // For class booking flow, this will bypass showing BookingInfoCard
    if (this.shouldBookImmediately) {
      return null
    }

    const {
      backPath,
      locationSummary,
      onCancel,
      scheduleEntry,
      seatId,
      seatLabel,
    } = this.props
    const { brand, copy, styleClasses, isXponential } = this.props.store!
    const isWaitlist = scheduleEntry.isFull && scheduleEntry.canWaitlist
    const waitlistPosition = isWaitlist
      ? (scheduleEntry.waitlistSize || 0) + 1
      : undefined
    const subtitle = scheduleEntry.isFull
      ? scheduleEntry.canWaitlist
        ? "Please confirm the following information to join the waitlist."
        : `The ${copy.class} you are trying to book
    is now full.`
      : `Please confirm the following information to book this ${copy.class}.`
    const userPremiumCost = scheduleEntry.userPremiumCost ? scheduleEntry.userPremiumCost.raw : null

    let xpassEntry,
      freeClassCredits,
      balances,
      freeClassBookings,
      bookedLocations,
      hasFreeClasses,
      isFreeClass = undefined
    if (isXponential) {
      xpassEntry = this.scheduleEntryStore!.scheduleEntry
      freeClassCredits = (
        <>
        <s>{userPremiumCost}</s>
          <span className={cx("capitalizedCredit")}>
            0 {copy.capitalizedCredit}
          </span>
        </>
      )
      balances = this.balancesStore!.balances
      freeClassBookings = this.balancesStore!.freeClassBookings
      bookedLocations =
        freeClassBookings &&
        freeClassBookings.map(booking => booking.scheduleEntry.location.id)
      hasFreeClasses = balances && balances.freeClasses > 0
      isFreeClass =
        scheduleEntry.isXpassFree && bookedLocations
          ? !bookedLocations.includes(scheduleEntry.location!.id)
          : false
    }

    return (
      <BookingInfoCard
        title={`Confirm ${!scheduleEntry.isFull ? "Booking" : "Waitlist"}`}
        subtitle={subtitle}
        scheduleEntry={scheduleEntry}
        locationSummary={locationSummary}
        brand={brand}
        copy={copy}
        seatId={seatId}
        seatLabel={seatLabel}
        waitlistPosition={waitlistPosition}
        waitlistIsEstimate
        store={this.props.store}
      >
        <>
          <PageTracker name="book > confirm" />
          <div
            className={cx(
              "mt-4 text-center",
              styleClasses.ConfirmBookingPage__confirmButton
            )}
          >
            {isXponential && this.props.bookingFlowStore.hasPointsMismatch ? (
              <>
                <div className="modal-title text-center w-100 py-3 h2">The premium cost has changed</div>
                <p className="text-center w-100 m-0">The amount needed to book this class has changed to ${userPremiumCost}. Would you still like to proceed? </p>
                <button
                  className={cx(
                    "btn w-100 mb-3",
                    xpassEntry ? "btn-primary" : "btn-secondary"
                  )}
                  onClick={this.props.onBook}
                  disabled={!xpassEntry}
                >
                  Confirm Booking {userPremiumCost ? `- $${userPremiumCost}` : ""}
                </button>
              </>
            ) : (
              isXponential ? (
                <button
                  className={cx(
                    "btn w-100 mb-3",
                    xpassEntry ? "btn-primary" : "btn-secondary"
                  )}
                  onClick={this.props.onBook}
                  disabled={!xpassEntry}
                >
                  {xpassEntry ? (
                    <>
                      Confirm Booking -{" "}
                      <img src={tokenIcon} alt="token" />
                      {hasFreeClasses && isFreeClass
                        ? freeClassCredits
                        : userPremiumCost ? `+ $${userPremiumCost}` : ""}
                    </>
                  ) : (
                    "One moment, checking for inventory..."
                  )}
                </button>
              ) : (
                <button
                  className="btn btn-primary w-100 mb-3"
                  onClick={this.props.onBook}
                  disabled={this.props.bookingFlowStore.status === "loading"}
                >
                  {scheduleEntry.isFull ? "Join Waitlist" : "Confirm Booking"}
                </button>
              )
            )}
            <Link className="text-muted" to={backPath} onClick={onCancel}>
              <small>Cancel</small>
            </Link>
          </div>
        </>
      </BookingInfoCard>
    )
  }
}
