import { JsonObject, JsonProperty } from "json2typescript"
import { computed } from "mobx"
import markerPin from "images/markers/pin.png"
import aktPin from "images/xpass/markers/active-selected/akt.png"
import bftPin from "images/xpass/markers/active-selected/bft.png"
import clubpilatesPin from "images/xpass/markers/active-selected/clubpilates.png"
import cyclebarPin from "images/xpass/markers/active-selected/cyclebar.png"
import purebarrePin from "images/xpass/markers/active-selected/purebarre.png"
import rowhousePin from "images/xpass/markers/active-selected/rowhouse.png"
import rumblePin from "images/xpass/markers/active-selected/rumble.png"
import stretchlabPin from "images/xpass/markers/active-selected/stretchlab.png"
import stridePin from "images/xpass/markers/active-selected/stride.png"
import yogasixPin from "images/xpass/markers/active-selected/yogasix.png"
import kinrgyPin from "images/xpass/markers/active-selected/kinrgy.png"
import { Location } from "./Location"

@JsonObject("Details")
export class Details {
  @JsonProperty("brand_name", String)
  brandName: string = undefined!

  @JsonProperty("brand_slug", String)
  brandSlug: string = undefined!

  @JsonProperty("hero_image_url", String)
  heroImageUrl: string = undefined!

  @computed get mapPin() {
    const images = [
      { imageUrl: aktPin, brandId: "akt" },
      { imageUrl: bftPin, brandId: "bft" },
      { imageUrl: clubpilatesPin, brandId: "clubpilates" },
      { imageUrl: cyclebarPin, brandId: "cyclebar" },
      { imageUrl: purebarrePin, brandId: "purebarre" },
      { imageUrl: rowhousePin, brandId: "rowhouse" },
      { imageUrl: rumblePin, brandId: "rumble" },
      { imageUrl: stretchlabPin, brandId: "stretchlab" },
      { imageUrl: stridePin, brandId: "stride" },
      { imageUrl: yogasixPin, brandId: "yogasix" },
      { imageUrl: kinrgyPin, brandId: "kinrgy" },
      { imageUrl: markerPin, brandId: "xponential" },
      { imageUrl: markerPin, brandId: "default" },
    ]
    return images.find((img) => img.brandId === this.brandSlug)
  }
}

@JsonObject("Instructor")
export class Instructor {
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("bio", String)
  bio: string = undefined!

  @JsonProperty("headshot_url", String, true)
  headshotUrl?: string = undefined!

  @JsonProperty("location", Location)
  location: Location = undefined!

}