import * as React from "react"
import {
  RouteComponentProps,
  Switch,
  Route,
  Redirect,
  matchPath,
} from "react-router"
import { observable, computed } from "mobx"
import { observer, inject } from "mobx-react"
import getIn from "lodash/get"
import * as cx from "classnames"
import { getLocal, setLocal } from "utils/LocalStorage"

import BrandStore from "stores/BrandStore"
import BookingFlowStore from "apps/book/stores/BookingFlowStore"
import ScheduleStore from "apps/book/stores/ScheduleStore"
import ServiceBookingStore from "apps/book/stores/ServiceBookingStore"
import LocationSummary from "models/LocationSummary"
import ScheduleEntry from "models/ScheduleEntry"
import ServiceScheduleEntry from "apps/book/models/ServiceScheduleEntry"
import { stringify, fromServiceBookingProps } from "helpers/queryString"
import { brandSiteURL } from "helpers/routing"
import APILoader from "components/wrappers/APILoader"

import { PlanInfo, GiftCardInfo } from "apps/buy/components/PaymentPage"
import BookingDetailController from "apps/book/components/BookingDetailController"
import ServiceBookingController from "apps/book/components/ServiceBookingController"
import SchedulePage from "apps/book/components/SchedulePage"
import MiniSchedulePage from "apps/book/components/MiniSchedulePage"
import BookAndConfirmSuccessPage from "apps/book/components/BookAndConfirmSuccessPage"
import HealthCheck from "apps/book/components/HealthCheck"
import BlockerPage from "components/BlockerPage"
import ScheduleEntriesPage from "apps/book/components/xpass/ScheduleEntriesPage"
import LocationSchedulePage from "apps/book/components/xpass/LocationSchedulePage"
import ClassDetails from "apps/xpass/ClassDetails"
import LocationDetails from "apps/xpass/LocationDetails"
import InstructorDetails from "apps/xpass/InstructorDetails"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import UserCoordinatesStore from "apps/book/stores/xpass/UserCoordinatesStore"
import BookingFlowModal from "apps/book/components/xpass/BookingFlowModal"
import Error404Page from "apps/Error404Page"
import BookClassPage from "./xpass/BookClassPage"
import cloneDeep from 'lodash/cloneDeep';
import ClaimAccountModalOnBook from "apps/dashboard/ClaimAccountModal"
import moment from "moment"

export interface Props {
  store?: BrandStore
  locationSummary: LocationSummary
  subBrand?: string
}

export interface State {
  bookAndConfirm?: boolean
  planInfo?: PlanInfo
  giftCardInfo?: GiftCardInfo
  healthCheckConfirmed: boolean
}

// TODO: Figure out how to use routing state properly
// w/o having to declare these special types
interface RoutingState {
  forceBackURL?: string
}

// UI and routing for the booking flow
@inject((store: BrandStore) => ({ store }))
@observer
export default class BookingFlowController extends React.Component<
  Props & RouteComponentProps,
  State
> {
  scheduleStore = new ScheduleStore(
    this.props.locationSummary,
    this.props.store!,
    { anchor: "week_starting_now" }
  )

  isXponential = this.props.store!.isXponential
  userCoordinatesStore = this.isXponential
    ? new UserCoordinatesStore()
    : undefined
  scheduleEntryStore =
    this.isXponential && this.userCoordinatesStore
      ? new ScheduleEntryStore(this.userCoordinatesStore, this.props.store!.userStore)
      : undefined
  serviceBookingStore = new ServiceBookingStore(
    this.props.locationSummary,
    this.props.store!
  )

  // needs to be observable b/c the instance changes
  @observable bookingFlowStore = new BookingFlowStore(this.props.store!)

  @computed get packagePurchaseRedirect() {
    const { locationSummary } = this.props
    const { status, needsPackage, scheduleEntry } = this.bookingFlowStore

    if (
      status === "loaded" &&
      needsPackage &&
      scheduleEntry &&
      !this.needsHealthCheck
    ) {
      const query = {
        schedule_entry_id: scheduleEntry.id,
        offer: this.props.store!.routingStore.query.offer,
      }

      const packagesPath = ["buy", locationSummary.id].join("/")
      const queryString = stringify(query)

      // Special 'back button' logic for the purchase flow
      // Sending people back to the schedule entry booking URL just
      // causes them to get redirected to the purchase flow again
      // forceBackURL overrides the back destination to get around that
      // see how it's used in PurchaseFlowController
      const routingState = (this.props.location.state || {}) as RoutingState
      const { isXponential } = this.props.store!
      const bookingMatch = matchPath(
        this.props.location.pathname,
        `/book/:locationId/${scheduleEntry.id}`
      )
      let referrer = bookingMatch
        ? document.referrer || brandSiteURL(this.props.store!)
        : undefined
      if (isXponential) referrer = "/book"

      return (
        <Redirect
          push
          to={{
            pathname: `/${packagesPath}`,
            search: `?${queryString}`,
            state: {
              ...routingState,
              forceBackURL: routingState.forceBackURL || referrer,
            },
          }}
        />
      )
    }
    return null
  }

  /**
   * If a health check is required and hasn't been done, we'll show an intermediate
   * confirm screen so the user can agree to COVID-19 precautions. This happens
   * in a few different circumstances due to various user flows:
   * - User on a schedule page clicks a book button, API call discovers they need
   *   to purchase a package: we show the dialog _before_ redirecting them to the
   *   payment flow
   * - User on a schedule page clicks a book button, already has a package, gets
   *   directed to the class booking confirm page: we show the dialog instead of
   *   rendering the confirm page (until they agree, then we show the confirm page)
   * - User lands on the booking confirm page directly. Depending on whether the
   *   user need a package, the same two cases ^ will apply once the booking
   *   status API call returns
   */
  @computed get needsHealthCheck() {
    // TODO: Setting this to `false` for now. If Xpo wants to get rid of this for good,
    // get rid of this and clean up all the code with `healthCheckConfirmed` and `handleHealthCheckConfirm`
    return false

    // const { locationSummary } = this.props
    // const { scheduleEntry } = this.bookingFlowStore
    // const { healthCheckConfirmed } = this.state

    // return (
    //   scheduleEntry &&
    //   scheduleEntry.locationType === "physical" &&
    //   !locationSummary.disableHealthCheck &&
    //   !healthCheckConfirmed
    // )
  }

  @computed get needsLaFitnessCheck() {
    const { locationSummary } = this.props
    const { scheduleEntry } = this.bookingFlowStore
    const laFitnessBooking = getLocal("laFitnessBooking", {}) as object
    const id = getIn(locationSummary, "id", "")
    const laFitnessBookingAccepted = laFitnessBooking[id] || false

    return (
      scheduleEntry && !!locationSummary.laFitness && !laFitnessBookingAccepted
    )
  }

  constructor(props: Props & RouteComponentProps) {
    super(props)
    this.state = {
      bookAndConfirm: false,
      planInfo: undefined,
      giftCardInfo: undefined,
      healthCheckConfirmed: false,
    }
  }

  componentDidMount() {
    // get details passed in from purchase flow
    const bookAndConfirm = getIn(
      this.props.location,
      "state.bookAndConfirm",
      false
    )
    const planInfo = getIn(this.props.location, "state.planInfo", undefined)
    const giftCardInfo = getIn(
      this.props.location,
      "state.giftCardInfo",
      undefined
    )

    // if user came from purchase flow, they already agreed to the health check
    const healthCheckConfirmed = Boolean(planInfo)

    this.setState({
      bookAndConfirm,
      healthCheckConfirmed,
      planInfo,
      giftCardInfo,
    })
  }

  componentWillUnmount() {
    if (this.bookingFlowStore) {
      this.bookingFlowStore.dispose()
    }

    if (
      this.isXponential &&
      this.userCoordinatesStore &&
      this.scheduleEntryStore
    ) {
      this.userCoordinatesStore.dispose()
      this.scheduleEntryStore.dispose()
    }
  }

  handleIsFrozen = () => {
    const { session } = this.props.store!.userStore
    const { isFrozen } = session!
    if (isFrozen) {
      this.props.store!.uiStore.seenAccountWarning = false
      return this.props.store!.uiStore.openAccountWarning(session!)
    }
  }

  handleBookingCheck = (entry: ScheduleEntry, offerId?: string) => (
    e: ButtonEvent
  ) => {
    e.preventDefault()
    if (this.bookingFlowStore) {
      this.bookingFlowStore.dispose()
    }

    this.handleIsFrozen()
    this.bookingFlowStore = new BookingFlowStore(this.props.store!)
    this.bookingFlowStore.checkScheduleEntry(entry).then(() => {
      // TODO: might combine this with checkScheduleEntry if the latter
      // isn't being used anywhere else
      const url = this.props.match.url
      const { scheduleEntry, needsPackage } = this.bookingFlowStore
      if (scheduleEntry && !needsPackage) {
        this.props.history.push({
          pathname: `${url}/${scheduleEntry.id}`,
          search: offerId ? "?" + stringify({ offer: offerId }) : undefined,
        })
      }
    })
    const optimizedEntry = cloneDeep(entry);
    if(!this.isXponential && optimizedEntry.hasOwnProperty("xpassCredits")) {
      delete optimizedEntry["xpassCredits"];
    }
    this.props.store!.track.event(
      optimizedEntry && optimizedEntry.isFull ? "waitlist_add to waitlist" : "booking_tap book",
      {
        entry: optimizedEntry,
        loc: this.props.locationSummary,
      }
    )
  }

  handleHealthCheckCancel = (_event: ButtonEvent, isModal?: boolean) => {
    const { scheduleEntry: entry } = this.bookingFlowStore
    const { locationSummary: loc, location, match } = this.props
    const stillOnSchedulePage = location.pathname === match.url

    this.props.store!.track.event("health check cancel", { entry, loc })

    if (!isModal) {
      if (stillOnSchedulePage) {
        this.bookingFlowStore = new BookingFlowStore(this.props.store!)
      } else {
        this.props.history.goBack()
      }
    }
  }

  handleHealthCheckConfirm = (_event: ButtonEvent) => {
    const { scheduleEntry: entry } = this.bookingFlowStore
    const { locationSummary: loc } = this.props

    this.props.store!.track.event("health check confirm", { entry, loc })
    this.setState({ healthCheckConfirmed: true })
  }

  handleServiceBookingCheck = (entry: ServiceScheduleEntry) => {
    this.serviceBookingStore.fetchFromEntry(entry).then(() => {
      const {
        packageNeeded,
        readyToBook,
        bookingProps,
      } = this.serviceBookingStore
      const search = fromServiceBookingProps(bookingProps!)
      this.props.store!.uiStore.closeModal()

      if (packageNeeded) {
        const path = `/buy/${this.props.locationSummary.id}`
        this.props.history.push(`${path}?${search}`)
      } else if (readyToBook) {
        const path = `/book/${this.props.locationSummary.id}/services/${entry.serviceDuration.id}/confirm`
        this.props.history.push(
          `${path}?${search}&health_checked=${this.state.healthCheckConfirmed}`
        )
      }
    })
  }

  handleBookingModal = (entry: ScheduleEntry | ServiceScheduleEntry) => {
    let serviceBookingCheck
    if (entry instanceof ServiceScheduleEntry) {
      serviceBookingCheck = this.handleServiceBookingCheck
    } else {
      this.props.store!.track.event(
        entry && entry.isFull ? "waitlist_add to waitlist" : "booking_tap book",
        {
          entry,
          loc: this.props.locationSummary,
        }
      )
    }
    if (this.isXponential && !entry.xpassEligible) {
      this.props.store!.track.event("blocker modal_popup")
      this.props.store!.uiStore.openModal({
        children: <ClaimAccountModalOnBook 
          {...this.props} 
          store={this.props.store!} 
          UIStore={this.props.store!.uiStore}
          />,
        modalShow: true,
      })
     } else if (this.isXponential && entry.xpassEligible && !entry.hasValidToken) {
        this.props.store!.track.event("blocker modal_popup")
        this.props.store!.uiStore.openModal({
          children: <ClaimAccountModalOnBook 
            {...this.props} 
            store={this.props.store!} 
            UIStore={this.props.store!.uiStore}
            hasNoToken
            />,
          modalShow: true,
        })
     } else if (this.isXponential && entry.xpassEligible && entry.hasValidToken && entry.userPremiumCost && entry.userPremiumCost.raw > 0 && !entry.isPremiumPaid) {
      let postWaiverAction
      if (entry instanceof ServiceScheduleEntry) {
        postWaiverAction = () => {
          const startsAt = moment(entry.startsAt).format()
          const userPremiumCostQuery = entry.userPremiumCost ? `&user_premium_cost=${entry.userPremiumCost.raw}` : ""
          this.props.history.push(`/buy/xponential-xpass/packages/${entry.targetPackageId}?location_id=${entry.locationId}&duration_id=${entry.serviceDuration.id}&instructor_id=${entry.instructorId}&starts_at=${startsAt}${userPremiumCostQuery}`)
        }
      } else {
        postWaiverAction = () => {
          this.props.history.push(`/buy/xponential-xpass/packages/${entry.targetPackageId}?schedule_entry_id=${entry.id}`)
        }
      }
      this.props.store!.uiStore.openModal({
        children: (
          <BookingFlowModal
            {...this.props}
            store={this.props.store}
            handleHealthCheckConfirm={this.handleHealthCheckConfirm}
            handleHealthCheckCancel={this.handleHealthCheckCancel}
            serviceBookingCheck={serviceBookingCheck}
            handleIsFrozen={this.handleIsFrozen}
            entry={entry}
            postWaiverAction={postWaiverAction}
          />
        ),
        modalShow: true,
      })
     } else {
      this.props.store!.uiStore.openModal({
        children: (
          <BookingFlowModal
            {...this.props}
            store={this.props.store}
            handleHealthCheckConfirm={this.handleHealthCheckConfirm}
            handleHealthCheckCancel={this.handleHealthCheckCancel}
            serviceBookingCheck={serviceBookingCheck}
            handleIsFrozen={this.handleIsFrozen}
            entry={entry}
          />
        ),
        modalShow: true,
      })
     }
  }

  render() {
    const { locationSummary, match, store } = this.props
    const purchaseRedirect = this.packagePurchaseRedirect
    const { needsPackage } = this.bookingFlowStore
    if (purchaseRedirect) {
      return purchaseRedirect
    }

    return (
      <div
        className={store!.isXponential ? "" : "container py-3 py-xl-4"}
      >
        <Switch>
          {this.isXponential && (
            <Route
              path={match.path}
              exact
              render={() => (
                <BookClassPage 
                  {...this.props}
                  brandStore={this.props.store!}
                  onBookingModal={this.handleBookingModal}
                  subBrand={this.props.subBrand}
                />
              )}
            />
          )}

          {this.isXponential && (
            <Route
              path={`${match.path}/class/:scheduleEntryId`}
              render={props => {
                return (
                  <ClassDetails
                    {...props}
                    onBookingModal={this.handleBookingModal}
                  />
                )
              }}
            />
          )}

          {this.isXponential && (
            <Route
              path={`${match.path}/location/:locationId`}
              render={props => {
                return (
                  <LocationDetails
                    {...props}
                    locationSummary={this.props.locationSummary}
                    scheduleStore={this.scheduleStore}
                    scheduleEntryStore={this.scheduleEntryStore!}
                    onBookingModal={this.handleBookingModal}
                  />
                )
              }}
            />
          )}

          {this.isXponential && (
            <Route
              path={`${match.path}/instructor/:instructorId`}
              render={props => {
                return (
                  <InstructorDetails
                    {...props}
                    onBookingModal={this.handleBookingModal}
                    scheduleStore={this.scheduleStore}
                    scheduleEntryStore={this.scheduleEntryStore!}
                  />
                )
              }}
            />
          )}

          {this.isXponential && this.scheduleEntryStore && (
            <Route
              path={`${match.path}/schedule`}
              render={() => {
                return (
                  <LocationSchedulePage
                    {...this.props}
                    scheduleStore={this.scheduleStore}
                    scheduleEntryStore={this.scheduleEntryStore!}
                    onBookingModal={this.handleBookingModal}
                  />
                )
              }}
            />
          )}

          {/*
            Special case where we _will_ redirect to purchase flow once
            we have the health check confirmation
          */}
          {this.needsHealthCheck && needsPackage && (
            <Route
              path={match.path}
              render={() => (
                <HealthCheck
                  store={store}
                  onConfirm={this.handleHealthCheckConfirm}
                  onCancel={this.handleHealthCheckCancel}
                />
              )}
            />
          )}

          {/* schedule */}
          <Route
            path={match.path}
            exact
            render={() => {
              return (
                <SchedulePage
                  {...this.props}
                  scheduleStore={this.scheduleStore}
                  onBookingCheck={this.handleBookingCheck}
                />
              )
            }}
          />
          {/* per-class schedule */}
          <Route
            path={`${match.path}/class/:classCategoryId`}
            exact
            render={props => {
              return (
                <MiniSchedulePage
                  {...props}
                  locationSummary={this.props.locationSummary}
                  // scheduleStore={this.scheduleStore}
                  onBookingCheck={this.handleBookingCheck}
                />
              )
            }}
          />
          {/* booking sessions for 1:1 services */}
          <Route
            path={`${match.path}/services/:serviceTypeId?`}
            render={props => {
              return (
                <div
                  className={cx({ "container mt-4 mb-5": store!.isXponential })}
                >
                  <ServiceBookingController
                    {...props}
                    locationSummary={this.props.locationSummary}
                    healthCheckConfirmed={this.state.healthCheckConfirmed}
                  />
                </div>
              )
            }}
          />
          {/* booking */}
          <Route
            path={`${this.props.match.path}/:scheduleEntryId`}
            render={props => {
              const { store } = this.props
              const scheduleEntryId = props.match.params.scheduleEntryId
              if (!this.bookingFlowStore) {
                this.bookingFlowStore = new BookingFlowStore(store!)
              }

              const renderError = this.state.bookAndConfirm
                ? () => {
                    return (
                      <BookAndConfirmSuccessPage
                        booking={
                          this.bookingFlowStore.booking ||
                          this.bookingFlowStore.bookingStatus
                        }
                        scheduleEntry={this.bookingFlowStore.scheduleEntry!}
                        locationSummary={locationSummary}
                        planInfo={this.state.planInfo}
                        giftCardInfo={this.state.giftCardInfo}
                        bookingFlowStore={this.bookingFlowStore}
                        // shareLink={shareURL.join("/")}
                        // seatId={this.bookingCreationStore.seatId}
                      />
                    )
                  }
                : () => <Redirect to={`/book/${locationSummary.id}`} />

              return (
                <APILoader
                  alreadyLoaded={
                    this.bookingFlowStore!.scheduleEntry &&
                    this.bookingFlowStore.scheduleEntry.id === scheduleEntryId
                  }
                  apiStore={this.bookingFlowStore}
                  fetchProps={scheduleEntryId}
                  elementSize="page"
                  renderError={renderError}
                  render={() => {
                    if (this.needsLaFitnessCheck) {
                      return (
                        <Route
                          path={match.path}
                          render={props => (
                            <BlockerPage
                              brandStore={this.props.store!}
                              type="laFitnessBooking"
                              {...props}
                            />
                          )}
                        />
                      )
                    }

                    if (this.needsHealthCheck) {
                      return (
                        <HealthCheck
                          store={store}
                          onConfirm={this.handleHealthCheckConfirm}
                          onCancel={this.handleHealthCheckCancel}
                        />
                      )
                    }

                    return (
                      <BookingDetailController
                        {...props}
                        bookingFlowStore={this.bookingFlowStore}
                        bookAndConfirm={this.state.bookAndConfirm}
                        planInfo={this.state.planInfo}
                        giftCardInfo={this.state.giftCardInfo}
                      />
                    )
                  }}
                />
              )
            }}
          />

          <Route component={Error404Page} />
        </Switch>
      </div>
    )
  }
}
