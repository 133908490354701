import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"
import uniqBy from "lodash/uniqBy"

import APIStore from "stores/APIStore"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"

import LocationSummary, { LocationsMeta } from "models/LocationSummary"
import BrandStore from "stores/BrandStore"
import { saveState, loadState } from "services/savedState"

export const LIST_LENGTH = 6

export interface Params {
  position?: Position
  limit?: number
  geoip?: boolean
  geoipOverride?: string
  email?: string
}

type Response = { locations: LocationSummary[]; meta: LocationsMeta }

// Currently just gets locations for a single brand
export default class LocationsStore extends APIStore {
  @observable locations: LocationSummary[] = []

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess),
    MemoizeMiddleware,
    DeserializeMiddleware("locations", LocationSummary),
    DeserializeMiddleware("meta", LocationsMeta),
  ])

  @computed
  get length() {
    return this.locations.length
  }

  constructor(public brandStore: BrandStore) {
    super()
    // TODO: set current from localstorage
  }

  fetch({ geoip, geoipOverride, position, limit, email }: Params = {}) {
    let qs: Record<string, string | number | boolean> = { open_status: "all" }
    qs = geoipOverride
      ? { geoip: geoipOverride, ...qs }
      : { ...this.getGeoParams({ geoip, position }), ...qs }
    if (limit) qs.limit = limit
    if (email) qs.user_email = email
    return this.api.get(`/api/brands/${this.brandStore.brandId}/locations`, {
      params: qs,
    })
  }

  // adds and dedupes locations
  @action
  addLocations(locations: LocationSummary[]) {
    this.locations = uniqBy(this.locations.concat(locations), "id")
  }

  @action
  replaceLocations(locations: LocationSummary[]) {
    this.locations = locations
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response>) {
    const locations = res.data.locations
    if (res.data.meta.coordinates) {
      saveState("coords", res.data.meta.coordinates, true)
    }
    this.addLocations(locations)

    return res
  }

  private getGeoParams({ geoip, position }: Params) {
    if (position) {
      return {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    } else if (geoip) {
      const coords = loadState("coords", true)
      if (coords) {
        return coords
      } else {
        return {
          geoip: process.env.NODE_ENV === "development" ? "72.34.99.58" : true,
        }
      }
    }
    return {}
  }
}
