import React, { Component } from "react"
import { observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import ServiceTypesStore from "apps/book/stores/ServiceTypesStore"

export interface Props {
  store: BrandStore
  serviceTypesStore: ServiceTypesStore
  purchaseUrl?: string
  setPurchaseUrl: Function
  updateQuery(key: string, value: string): void
}

@observer
export default class ServiceScheduleModal extends Component<Props> {
  closeModal = () => {
    this.props.store!.uiStore.closeModal()
  }

  buyCredits = () => {
    this.closeModal()
    this.props.setPurchaseUrl(this.props.purchaseUrl)
  }

  handleClick = (durationId: string) => {
    this.props.updateQuery("durationId", durationId)
    this.closeModal()
  }

  render() {
    const { serviceTypes } = this.props.serviceTypesStore

    return !this.props.purchaseUrl ? (
      <>
        <div className="service-schedule-modal px-4 pt-4 text-center">
          <h2 className="mx-4 px-3 pb-1 mb-3">
            What type of session do you want to book?
          </h2>

          {serviceTypes &&
            serviceTypes.length > 0 &&
            serviceTypes[0].durations.map(duration => {
              return (
                <button
                  key={duration.id}
                  className="service-schedule-modal-btn duration btn d-block w-100"
                  onClick={() => this.handleClick(duration.id)}
                >
                  <span className="h4">{duration.durationMinutes} min</span>
                </button>
              )
            })}
          <button className="btn btn-link my-2" onClick={this.closeModal}>
            <small className="text-muted">Cancel</small>
          </button>
        </div>
      </>
    ) : (
      <div className="service-schedule-modal px-4 pt-4 text-center">
        <h2 className="mx-4 px-4 pb-1 mb-3">You don't have enough credits!</h2>
        <div className="pb-4">
          It looks like you don't have enough credits. Check that you're booking
          the correct session length and that your credits have not expired.
        </div>
        <button
          className="btn btn-primary service-schedule-modal-btn d-block w-100 mt-2"
          onClick={() => this.buyCredits()}
        >
          <span className="h4">Buy More Credits</span>
        </button>
        <button className="btn btn-link my-2" onClick={this.closeModal}>
          <small className="text-muted">Not Now</small>
        </button>
      </div>
    )
  }
}
