import { observable, computed } from "mobx"
import sortBy from "lodash/sortBy"

import ScheduleEntryV1 from "models/v1/ScheduleEntryV1"
import { Metric } from "apps/history/models/Stats"
import ClassStatsBase from "apps/history/models/ClassStatBase"

interface DataPoint {
  scheduleEntry: ScheduleEntryV1
  value: number
}

// A view of a single metric (possible filtered by class) over
// a user's performance history
//
// TODO: the parameterized typing here is useless
// someday I will figure out how to type all this
export default class AggregateMetric {
  @observable private data: DataPoint[]

  @computed get best() {
    return this.byValue[0]
  }

  @computed get worst() {
    return this.byValue[this.data.length - 1]
  }

  // aggregations

  @computed get sum() {
    const data = this.data.filter(item => item.value)
    return data.reduce((sum, d) => sum + d.value, 0)
  }

  @computed get average() {
    const data = this.data.filter(item => item.value)
    return this.sum / data.length
  }

  @computed get length() {
    return this.data.length
  }

  // mappings

  // ordered best to worst
  @computed get byValue() {
    return sortBy(this.data, d => d.value * (this.metric.golf ? 1 : -1))
  }

  // ordered oldest to newest
  @computed get byDate() {
    return sortBy(this.data, d => d.scheduleEntry.startsAt)
  }

  // running total, oldest to newest
  @computed get accumulation() {
    // this is way cleaner with a linked list
    const accumulated: DataPoint[] = []
    this.byDate.reduce((val, d) => {
      const metric = { ...d, value: d.value + val }
      accumulated.push(metric)
      return metric.value
    }, 0)

    return accumulated
  }

  @computed get chartData() {
    return this.metric.cumulative ? this.accumulation : this.byDate
  }

  constructor(
    stats: ClassStatsBase[],
    public metric: Metric,
    public classCategoryId?: string
  ) {
    // grab just the value we're interested in
    let data = stats.map(d => ({
      // TODO: hard to get the types right
      value: d[metric.key] as number,
      scheduleEntry: d.scheduleEntry,
    }))

    // filter by class
    if (classCategoryId && classCategoryId !== "all") {
      if (classCategoryId === "other") {
        data = data.filter(d => !d.scheduleEntry.classCategory)
      } else {
        data = data.filter(
          d =>
            d.scheduleEntry.classCategory &&
            d.scheduleEntry.classCategory.id === classCategoryId
        )
      }
    }

    this.data = data
  }
}
