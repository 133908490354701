import { observable, action } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import APIStore from "stores/APIStore"
import UserClaim from "apps/account/models/xpass/UserClaim"
import { BrandUser } from "models/UserSession"
import BrandStore from "stores/BrandStore"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export default class UserClaimsStore extends APIStore {
  @observable userClaims: UserClaim[] = []
  @observable brandUser?: BrandUser

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("claimed_users", UserClaim),
    DeserializeMiddleware("brand_user", BrandUser, true),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(public brandStore: BrandStore) {
    super()
  }

  protected get url() {
    return '/api/xpass/user_claims'
  }

  fetch() {
    return this.api.get(this.url)
  }

  create(userIds: Array<number>, brandLocationId: string) {
    const params = brandLocationId
      ? {
          user_ids: userIds,
          location_id: brandLocationId,
        }
      : {
          user_ids: userIds,
        }
    return this.api.post(this.url, params)
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.userClaims = res.data.claimed_users
    this.brandUser = res.data.brand_user ? res.data.brand_user : undefined
    return res
  }

  @action.bound
  handleError(err: AxiosError) {
    console.log(err.response)
    if (err.response && err.response.status === 404) {
      this.userClaims = []
      return err.response
    }
    throw err
  }
}
