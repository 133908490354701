import React, { Component } from "react"
import { Redirect, RouteComponentProps } from "react-router"
import { AxiosError } from "axios"
import cx from "classnames"
import * as yup from "yup"
import { InjectedFormikProps, withFormik, Form } from "formik"

import BrandStore from "stores/BrandStore"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"
import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"
import FormAlert from "components/forms/FormAlert"

interface FormValues {
  verificationCode: string
}

export interface Props extends RouteComponentProps {
  store?: BrandStore
  userAuthStore: UserAuthStore
}

class VerifyAccountFormInner extends Component<
  InjectedFormikProps<Props, FormValues>
> {
  handleResend = () => {
    const email = this.props.userAuthStore.activeEmail
    this.props.userAuthStore.verifyEmail(email)
  }

  handleCancel = () => {
    this.props.history.push("/auth/register/claim")
  }

  render() {
    const { errors, isSubmitting } = this.props
    const base = "base"

    // Redirect user in the case state is lost on page refresh
    if (!this.props.userAuthStore.initialEmail) {
      return <Redirect to="/" />
    }

    return (
      <div className="mt-5">
        <h1 className="text-center">Verification Code</h1>
        <p className="text-center col-md-6 mx-auto">
          We sent a 6-digit code to the email you provided. Enter it here to
          verify your account.
        </p>

        <Form
          noValidate
          className={cx(
            "col-md-8",
            "col-lg-5",
            "mx-auto",
          )}
        >
          <FormAlert message={errors[base]} classNames="my-2" />
          <FormInput
            placeholder="Enter code"
            label="Verification Code"
            name="verificationCode"
            type=""
          />

          <small>
            <span className="link light-link" onClick={this.handleResend}>
              Resend Code
            </span>
          </small>

          <FormButtons
            submitText="Verify Account"
            disabled={isSubmitting}
            centered
            className="float-in delay-4"
          />

          <div className="text-center mt-4 link">
            <small>
              <a onClick={this.handleCancel}>Cancel</a>
            </small>
          </div>
        </Form>
      </div>
    )
  }
}

const schema = yup.object<FormValues>().shape({
  verificationCode: yup
    .string()
    .required()
    .min(6, "Verification code must be 6 characters.")
    .max(6, "Verification code must be 6 characters.")
    .label("Verification Code"),
})

const VerifyAccountForm = withFormik<Props, FormValues>({
  mapPropsToValues: props => ({
    verificationCode: "",
  }),
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: schema,
  handleSubmit: (values, formikBag) => {
    const { locStore, track } = formikBag.props.store!
    const loc = locStore.currentLocation

    formikBag.props.userAuthStore
      .verifyCode(values.verificationCode, formikBag.props.userAuthStore.activeEmail)
      .then(res => {
        const verifySuccess = res.success

        if (verifySuccess) {
          const { activeEmail, verifiedEmails} = formikBag.props.userAuthStore
          verifiedEmails.push(activeEmail)
          formikBag.props.userAuthStore.setVerifiedEmails(verifiedEmails)

          const currentMatchedAccounts = formikBag.props.userAuthStore
            .userAccounts!.users
          const verifiedAccounts =
            formikBag.props.userAuthStore.verifiedAccounts

          if (verifiedAccounts) {
            const addVerifiedAccounts = verifiedAccounts.concat(
              currentMatchedAccounts
            )
            formikBag.props.userAuthStore.setVerifiedAccounts(
              addVerifiedAccounts
            )
          } else {
            formikBag.props.userAuthStore.setVerifiedAccounts(
              currentMatchedAccounts
            )
          }
          track.event("verification_success", { loc }, { cta: "verify account" })
          formikBag.props.history.push("/auth/register/claim")
        } else {
          const message = `${res.error}. Please try again.`
          formikBag.setSubmitting(false)
          formikBag.setFieldError("base", message)
          track.event("verification_failure", { loc }, { cta: "verify account" })
        }
      })
      .catch((ex: AxiosError) => {
        formikBag.setSubmitting(false)
        if (ex.response && ex.response.data) {
          formikBag.setFieldError("base", ex.response.data.message)
        }
        formikBag.setFieldError("base", "Invalid verification code. Please try again.")
        track.event("verification_failure", { loc }, { cta: "verify account" })
      })
  },
})(VerifyAccountFormInner)

export default VerifyAccountForm
