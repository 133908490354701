// Delete this thing once Feb. is over.
// Everything is jammed into this file to keep it self-contained

import React from "react"
import cx from "classnames"
import { inject, observer } from "mobx-react"

import BrandStore from "stores/BrandStore"
import APILoader from "components/wrappers/APILoader"

import { observable } from "mobx"
import BookingsStore from "apps/bookings/stores/BookingsStore"
import OptInStore from "apps/dashboard/stores/OptInStore"

import heartBadge01 from "images/badges/yogasix/february/HeartBadges-01.png"
import heartBadge02 from "images/badges/yogasix/february/HeartBadges-02.png"
import heartBadge03 from "images/badges/yogasix/february/HeartBadges-03.png"
import heartBadge04 from "images/badges/yogasix/february/HeartBadges-04.png"
import heartBadge05 from "images/badges/yogasix/february/HeartBadges-05.png"
import heartBadge06 from "images/badges/yogasix/february/HeartBadges-06.png"
import heartBadge07 from "images/badges/yogasix/february/HeartBadges-07.png"
import heartBadge08 from "images/badges/yogasix/february/HeartBadges-08.png"
import heartBadge09 from "images/badges/yogasix/february/HeartBadges-09.png"
import heartBadge10 from "images/badges/yogasix/february/HeartBadges-10.png"
import heartBadge11 from "images/badges/yogasix/february/HeartBadges-11.png"
import heartBadge12 from "images/badges/yogasix/february/HeartBadges-12.png"
import heartBadge13 from "images/badges/yogasix/february/HeartBadges-13.png"
import heartBadge14 from "images/badges/yogasix/february/HeartBadges-14.png"
import heartBadge15 from "images/badges/yogasix/february/HeartBadges-15.png"
import heartBadge16 from "images/badges/yogasix/february/HeartBadges-16.png"
import heartBadge17 from "images/badges/yogasix/february/HeartBadges-17.png"
import heartBadge18 from "images/badges/yogasix/february/HeartBadges-18.png"
import heartBadge19 from "images/badges/yogasix/february/HeartBadges-19.png"
import heartBadge20 from "images/badges/yogasix/february/HeartBadges-20.png"
import heartBadge21 from "images/badges/yogasix/february/HeartBadges-21.png"
import heartBadge22 from "images/badges/yogasix/february/HeartBadges-22.png"
import heartBadge23 from "images/badges/yogasix/february/HeartBadges-23.png"
import heartBadge24 from "images/badges/yogasix/february/HeartBadges-24.png"
import heartBadge25 from "images/badges/yogasix/february/HeartBadges-25.png"
import heartBadge26 from "images/badges/yogasix/february/HeartBadges-26.png"
import heartBadge27 from "images/badges/yogasix/february/HeartBadges-27.png"
import heartBadge28 from "images/badges/yogasix/february/HeartBadges-28.png"
import heartBadge29 from "images/badges/yogasix/february/HeartBadges-29.png"

import emptyBadge from "images/badges/yogasix/february/empty.png"

import counterBadge from "images/badges/yogasix/february-badge.png"
import optInBadge from "images/badges/yogasix/february_opt_in_badge.png"

interface HeartBadge {
  copy: string
  image: string
}

const badgeCopy = [
  `Attend 16 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 15 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 14 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 13 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 12 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 11 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 10 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 9 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 8 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 7 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 6 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 5 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 4 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 3 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 2 more classes this month to win a limited edition Y6 shirt!`,
  `Attend 1 more class this month to win a limited edition Y6 shirt!`,
  `Way to go warrior! You flowed to the pose and earned a limited edition Y6 shirt!`,
  `You totally killed that challenge! Push your limits and keep it up!`,
  `Flow to the pose and keep it up!`,
  `You set the roof on fire! Keep up the great work!`,
  `Aspire to perspire and keep it up!`,
  `You're crushin' it! Keep up the great work!`,
  `Outer power, inner peace. Keep up the great work!`,
  `Rock your chakra and keep it up!`,
  `Hot damn! Keep up the great work!`,
  `Flow for it. Keep it up, warrior!`,
  `You're groovin'! Keep it up!`,
  `Make it happen and keep up the great work!`,
  `Get your six on! Take one more class this month & you're hotter than a Y6 Hot Class!`,
  `You totally got your six on this month! You're definitely hotter than a Y6 Hot Class!`,
]

const badgeImages = [
  emptyBadge,
  heartBadge01,
  heartBadge02,
  heartBadge03,
  heartBadge04,
  heartBadge05,
  heartBadge06,
  heartBadge07,
  heartBadge08,
  heartBadge09,
  heartBadge10,
  heartBadge11,
  heartBadge12,
  heartBadge13,
  heartBadge14,
  heartBadge15,
  heartBadge16,
  heartBadge17,
  heartBadge18,
  heartBadge19,
  heartBadge20,
  heartBadge21,
  heartBadge22,
  heartBadge23,
  heartBadge24,
  heartBadge25,
  heartBadge26,
  heartBadge27,
  heartBadge28,
  heartBadge29,
]

interface Props {
  className?: string
  store?: BrandStore
}

// Sort of a fancier version of the badges card

@inject((store: BrandStore) => ({ store }))
@observer
export default class YogaSixFebruaryChallengeCard extends React.Component<
  Props
> {
  optInStore = new OptInStore(this.props.store!.userStore)

  componentWillUnmount() {
    this.optInStore.dispose()
  }

  handleOptInClick = (e: ButtonEvent) => {
    e.preventDefault()
    this.optInStore.add("yogasix_february_2019")
  }

  render() {
    return (
      <div className={cx("february-feature", "card", this.props.className)}>
        <div className="card-header d-flex justify-content-between align-items-center">
          {/* TODO: maybe fix styles */}
          <h5>Y6 February Challenge</h5>
          <small>
            {/*
              <a
              href="https://lp.yogasix.com/?hs_preview=yUSYZOnV-24576202197"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a> */}
          </small>
        </div>
        <div className="card-body d-flex flex-column">
          <APILoader
            apiStore={this.optInStore}
            render={() => {
              if (
                this.optInStore.optIns &&
                this.optInStore.optIns.indexOf("yogasix_february_2019") !== -1
              ) {
                return <Y6FebChallengeProgress />
              } else {
                return (
                  <>
                    <div className="d-flex">
                      <img
                        src={optInBadge}
                        alt="Y6"
                        style={{
                          minWidth: 36,
                          height: 42,
                          marginRight: 24,
                        }}
                      />
                      <div>
                        <p className="h5" style={{ color: "#e96f9b" }}>
                          Sign Up Now &amp; Win a Limited Edition Y6 Shirt
                        </p>

                        <p>
                          Take the leap this year! Accept the challenge and take
                          16 classes this month to win a limited edition Y6
                          shirt!
                        </p>
                      </div>
                    </div>
                    <button
                      className="btn btn-danger mt-auto mb-0"
                      onClick={this.handleOptInClick}
                    >
                      Join Now
                    </button>
                  </>
                )
              }
            }}
          />
        </div>
      </div>
    )
  }
}

@inject((store: BrandStore) => ({ store }))
@observer
class Y6FebChallengeProgress extends React.Component<Props> {
  bookingsStore = new BookingsStore(this.props.store!, false)

  // TODO: remove - for testing only
  // @observable numClasses: number = 0

  componentWillUnmount() {
    this.bookingsStore.dispose()
  }

  // TODO: remove - for testing only
  // handleChangeNumber = (e: React.FormEvent<HTMLInputElement>) => {
  //   this.numClasses = parseInt(e.currentTarget.value, 10)
  // }

  render() {
    return (
      <APILoader
        elementSize="element"
        propagateErrors
        // stayLoaded
        apiStore={this.bookingsStore}
        fetchProps={{
          start_date: "2020-02-01",
          end_date: "2020-03-01",
          include_waitlist: false,
        }}
        render={() => {
          // TODO: kill "this.numClasses" - for testing only
          // const numClasses = this.numClasses || 0
          // TODO: uncomment this
          const numClasses = this.bookingsStore.completed.length

          const copy = badgeCopy[numClasses] || badgeCopy[badgeCopy.length - 1]

          const denominator = numClasses <= 16 ? 16 : 29

          let hearts: number[]

          if (numClasses === 0) {
            hearts = [1, 2, 3]
          } else if (numClasses >= 27) {
            hearts = [27, 28, 29]
          } else {
            hearts = [numClasses, numClasses + 1, numClasses + 2]
          }

          return (
            <>
              <div className="d-flex" style={{ marginBottom: 12 }}>
                <div
                  style={{
                    minWidth: 36,
                    height: 52,
                    background: `url(${counterBadge}) no-repeat`,
                    backgroundSize: "cover",
                    color: "#fff",
                    // fontWeight: "bold",
                    fontSize: "10px",
                    textAlign: "center",
                    paddingTop: 12,
                    marginRight: 24,
                  }}
                >
                  {numClasses}/{denominator}
                </div>
                <p className="h5">{copy}</p>
              </div>
              <ChallengeBar
                className="february-challenge-bar"
                now={numClasses}
                target={16}
              />
              <div
                className="d-flex justify-content-between"
                style={{ marginTop: 24 }}
              >
                {hearts.map(num => (
                  <HeartsBar
                    key={num}
                    now={num}
                    image={num > numClasses ? emptyBadge : badgeImages[num]}
                    className={num > 16 ? "goal-achieved" : ""}
                  />
                ))}
              </div>
              {/* TODO: remove - for testing only */}
              {/* <input
                type="number"
                value={this.numClasses}
                onChange={this.handleChangeNumber}
                min={0}
                max={100}
                step={1}
              /> */}
            </>
          )
        }}
      />
    )
  }
}

interface ChallengeBarProps {
  className?: string
  now: number
  target: number
}

const ChallengeBar: React.SFC<ChallengeBarProps> = ({
  className,
  now,
  target,
}) => {
  const progress = Math.min((now * 100) / target, 100)
  return (
    <div className={cx(className, "mb-4")}>
      <div className="goal-bar">
        <div className="goal-bar-track">
          <div
            className={cx("goal-bar-progress", {
              "goal-bar-progress-complete": progress >= 100,
            })}
            style={{
              width: `${progress}%`,
            }}
          />
        </div>

        <div className="goal-bar-ui">
          <div
            className="goal-bar-scrubber-wrapper"
            style={{ width: `${progress}%` }}
          >
            <div title={now.toString(10)} className="goal-bar-scrubber" />
          </div>
        </div>
      </div>
    </div>
  )
}

interface HeartsBarProps {
  image: string
  now: number
  className: string
}

const HeartsBar: React.SFC<HeartsBarProps> = ({ now, image, className }) => {
  return (
    <div className="text-center">
      <img src={image} style={{ maxWidth: 90 }} className="mb-2" />
      <h5 className={className}>
        {now} {now === 1 ? "Class" : "Classes"}
      </h5>
    </div>
  )
}
