import { JsonObject, JsonProperty } from "json2typescript"
import DateConverter from "utils/DateConverter"
import { Moment } from "moment"

@JsonObject("Credit")
export default class Credit {
  /**
   * Name of the credit.
   */
  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * Unique (?) ClubReady credit id.
   */
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * When the credit expires, if ever.
   */
  @JsonProperty("expires_at", DateConverter, true)
  expiresAt?: Moment = undefined

  /**
   * When the credit was generated (usually when it was purchased.)
   */
  @JsonProperty("created_at", DateConverter)
  createdAt: Moment = undefined!

  /**
   * General type of the credit
   */
  @JsonProperty("credit_type", String)
  creditType: "class" | "service" | "other" = undefined!

  /**
   * TODO: this should be replaced with the HYFN id of the class type
   */
  @JsonProperty("class_type_id", String, true)
  classTypeId?: string = undefined

  /**
   * ClubReady `onHold` field. Meaning unknown for now.
   */
  @JsonProperty("on_hold", Boolean)
  onHold?: boolean = undefined
}
