import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosPromise, AxiosError } from "axios"
import { observable, action, computed } from "mobx"
import sortBy from "lodash/sortBy"
import * as moment from "moment"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import BrandStore from "stores/BrandStore"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import Booking, { AnyBooking } from "models/Booking"
import BookingUpdateStore from "apps/bookings/stores/BookingUpdateStore"
import ServiceBookingUpdateStore from "apps/bookings/stores/ServiceBookingUpdateStore"
import LocationSummary from "models/LocationSummary"
import ServiceBooking from "apps/book/models/ServiceBooking"

interface BookingsParams {
  limit?: number
  include_waitlist?: boolean
  start_date?: string
  end_date?: string
  location_id?: string
}

interface BookingsResponse {
  bookings: Booking[]
  service_bookings: ServiceBooking[]
  locations: LocationSummary[]
  class_completed_count: number
}

export default class BookingsStore extends APIStore {
  @observable classCompletedCount?: number
  api = this.createClient<BookingsResponse>([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("bookings", Booking),
    DeserializeMiddleware("service_bookings", ServiceBooking),
    DeserializeMiddleware("locations", LocationSummary),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  @observable serviceBookingStores: ServiceBookingUpdateStore[] = []

  @observable private stores: BookingUpdateStore[] = []

  @computed get activeStores() {
    return this.stores.filter(s => s.booking)
  }

  @computed get activeServiceBookingStores() {
    return this.serviceBookingStores.filter(s => s.booking)
  }

  @computed get bookings() {
    return this.activeStores.map(s => s.booking)
  }

  @computed get serviceBookings() {
    return this.activeServiceBookingStores.map(s => s.booking)
  }

  @computed
  get historical() {
    return this.bookedBookings.filter(b => !b.scheduleEntry.inFuture)
  }

  @computed get completed() {
    return this.historical.filter(b => b.status === "completed")
  }

  /**
   * Get a sorted list of all booked bookings, including class and service bookings that are not cancelled
   * @return AnyBooking[]
   */
  @computed
  get bookedMerged() {
    return sortBy(
      [...this.bookedBookings, ...this.bookedServiceBookings],
      b => -b.scheduleEntry.startsAt.unix()
    )
  }

  /**
   * Get a sorted list of all bookings, including class and service bookings
   * @return AnyBooking[]
   */
  @computed
  get merged() {
    return sortBy(
      [...this.bookings, ...this.serviceBookings],
      b => -b.scheduleEntry.startsAt.unix()
    )
  }

  /**
   * Get a sorted list of all non-future bookings, including class and service bookings
   * If XPO, return cancelled as well
   * @return AnyBooking[]
   */
  @computed
  get mergedHistorical() {
    if (this.brandStore.isXponential) {
      return this.merged.filter(b => !b.scheduleEntry.inFuture)
    }
    return this.bookedMerged.filter(b => !b.scheduleEntry.inFuture)
  }

  // not cancelled ones
  @computed get bookedBookings() {
    return this.bookings.filter(b => b.isBooked)
  }

  @computed get bookedServiceBookings() {
    return this.serviceBookings.filter(b => b.isBooked)
  }

  @computed
  get nextBooking() {
    const allBookings = [...this.bookings, ...this.serviceBookings]
    const filterBooked = allBookings.filter(s => {
      const { startsAt } = s.scheduleEntry
      const currentTime = moment.utc()
      const startTime = moment(startsAt.format()).utc()
      return s.status == "booked" && startTime.isAfter(currentTime)
    })
    const sortedBookings = sortBy(filterBooked, b => b.scheduleEntry.startsAt)
    return sortedBookings.length > 0 ? sortedBookings[0] : undefined
  }

  constructor(public brandStore: BrandStore, public fetchForWaitlist = true) {
    super()
  }

  dispose() {
    this.stores.map(s => s.dispose())
    super.dispose()
  }

  fetch(params: BookingsParams = {}) {
    if (this.brandStore.isXponential) {
      return this.api.get("/api/xpass/v2/bookings", {
        params: { ...params, from_web: true },
      })
    }

    return this.api.get("/api/v2/bookings", { params: params })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<BookingsResponse>) {
    if (this.brandStore.isXponential) {
      this.classCompletedCount = res.data.class_completed_count
    }
    this.stores = res.data.bookings.map(booking => {
      booking.scheduleEntry.location = res.data.locations.find(
        loc => booking.scheduleEntry.locationId === loc.id
      )
      const bstore = new BookingUpdateStore(this.brandStore, booking)

      if (this.fetchForWaitlist && booking.canBookFromWaitlist === undefined) {
        // find out if we can book from waitlist
        bstore.fetch()
      }
      return bstore
    })

    this.serviceBookingStores = res.data.service_bookings.map(booking => {
      booking.location = res.data.locations.find(
        loc => booking.locationId === loc.id
      )
      return new ServiceBookingUpdateStore(this.brandStore, booking)
    })

    return res
  }

  cancel(booking: AnyBooking, params?: {}) {
    return this.storeFor(booking).cancel(params)
  }

  checkIn(booking: AnyBooking) {
    return this.storeFor(booking).checkIn()
  }

  removeCheckin(booking: AnyBooking) {
    return this.storeFor(booking).removeCheckin()
  }

  refresh(booking: Booking) {
    // might be able to avoid this fetch but meh
    return this.storeFor(booking).fetch()
  }

  private storeFor(
    booking: AnyBooking
  ): BookingUpdateStore | ServiceBookingUpdateStore {
    const store =
      booking.type === "service_booking"
        ? this.activeServiceBookingStores.find(s => s.booking.id === booking.id)
        : this.activeStores.find(s => s.booking.id === booking.id)

    if (store) return store

    this.brandStore.uiStore.openError(
      "Couldn't find that booking, please refresh and try again."
    )
    throw new Error("No Store for Booking")
  }

  // all this crap down here is to update the list of bookings with the new
  // booking, whether or not the request was successful
  // because a 400 response might still have the booking on it

  // private attachUpdateHandlers(promise: AxiosPromise<BookingResponse>) {
  //   return promise.then(this.handleUpdateSuccess).catch(this.handleUpdateError)
  // }

  // @action.bound
  // private handleUpdateSuccess(res: AxiosResponse<BookingResponse>) {
  //   return this.replaceBooking(res.data.booking.id)
  // }

  // @action.bound
  // private handleUpdateError(ex: AxiosError) {
  //   if (
  //     ex &&
  //     ex.response &&
  //     ex.response.data &&
  //     ex.response.data.booking &&
  //     ex.response.data.booking.schedule_entry &&
  //     ex.response.data.booking.schedule_entry.id
  //   ) {
  //     return this.replaceBooking(ex.response.data.booking.schedule_entry.id)
  //   }
  //   throw ex
  // }

  // @action
  // private replaceBooking(id: string) {
  //   const updateStore = this.updateStores[id]
  //   if (!updateStore) return this.bookings

  //   const booking = updateStore.booking
  //   const oldBookingIdx = this.bookings.findIndex(b => b.id === id)
  //   this.bookings[oldBookingIdx] = booking

  //   updateStore.dispose()
  //   delete this.updateStores[id]

  //   return this.bookings
  // }
}
