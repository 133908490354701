import React from "react"
import { observer, inject } from "mobx-react"
import BrandStore from "stores/BrandStore"
import { Redirect, RouteComponentProps } from "react-router"
import PageTracker from "components/wrappers/PageTracker"

export interface Props extends RouteComponentProps {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class IndigoInterstitialPage extends React.Component<Props, {}> {
  public render() {
    const { userStore, locStore } = this.props.store!;

    const indigoIosAppUrl = userStore.session! && (userStore.session!.locations[0].indigoIosAppUrl || locStore.currentLocation!.indigoIosAppUrl || "#")
    const indigoAndroidAppUrl = userStore.session! && (userStore.session!.locations[0].indigoAndroidAppUrl || locStore.currentLocation!.indigoAndroidAppUrl || "#")
    const isMemberPlatformLive = userStore.isMemberPlatformLive

    return (
      isMemberPlatformLive ?
        <>
          <div className="indigo-interstitial-page__main-container p-lg-5 position-relative">
            <div className="indigo-interstitial-page__container d-flex">
              <div className="indigo-interstitial-page__left-container text-light col-12 col-lg-6 p-lg-5 py-3">
                <h1 className="mb-3 text-light">{this.props.store!.locStore.currentLocation!.name} HAS A NEW APP!</h1>
                <p>{this.props.store!.locStore.currentLocation!.name} is making many exciting changes, including updating its member management system. During this period, your studio will be moving to a different version of the mobile app. Please download the {this.props.store!.brand.name} {this.props.store!.locStore.currentLocation!.name} app to manage your membership and bookings.</p>
                <div className="indigo-interstitial__cta">
                  <a href={indigoIosAppUrl} target="_blank" className="mr-3">
                    <img src={this.props.store!.styles.appStore} alt="Download application from App store" />
                  </a>
                  <a href={indigoAndroidAppUrl} target="_blank">
                    <img src={this.props.store!.styles.googlePlayStore} alt="Download application from Google Playstore" />
                  </a>
                </div>
              </div>
              <div className="indigo-interstitial-page__right-container position-absolute h-100 d-none d-lg-block">
                <img src={this.props.store!.styles.indigoHero} className="h-100" alt="Mobile App" />
              </div>
              <PageTracker name="IndigoInterstitialPage" />
            </div>
          </div>
        </>
        : <Redirect to="/" />
    )
  }
}