import React from "react"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"


interface Props {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class BrandPurchaseError extends React.Component<Props>  {
  constructor(props:Props){
    super(props)
  }

  componentDidMount() {
    this.props.store!.uiStore.hideNavLinks()
  }

 componentWillUnmount() {
    this.props.store!.uiStore.showNavLinks()
 }


 render(){
  const queryParams = new URLSearchParams(document.location.search);

    return (
      <>
      <div className="mx-auto mt-5 col-lg-8 col-sm-12 ">
          {
            queryParams.get('statusText') === 'NoPurchase'
            && (
              <div className="text-center">
                <h2 className="claim-accounts-header">Can't Redeem Offer</h2>
                <p>Sorry, you cannot purchase this package again at this time.</p>
              </div>
            )
          }

          {
            queryParams.get('statusText') === 'BadRequest'
            && (
              <div className="text-center">
                <h2 className="claim-accounts-header">Can't Redeem Offer</h2>
                <p>Sorry, an error has occurred</p>
              </div>
            )
          }

          {
            queryParams.get('statusText') === 'Not Found'
            && (
              <div className="text-left">
                <h2 className="claim-accounts-header">Can't Redeem Offer</h2>
                <p>{`This offer isn't available at ${this.props.store!.brand.name} ${ this.props.store!.locStore.currentLocation &&  this.props.store!.locStore.currentLocation.name} but you can still purchase a package at this location.`}</p>
              </div>
              )
          }

      </div>
         
     
      <div className="text-center">
          <a href="/book" className="btn btn-primary mt-auto mb-0">
            View Schedule
          </a> 
      </div>
      </>
    )
  }
 
}
