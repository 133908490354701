import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"

import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

import BrandStore from "stores/BrandStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import ScheduleEntryLocationStore from "apps/book/stores/xpass/ScheduleEntryLocationStore"
import UserCoordinatesStore from "apps/book/stores/xpass/UserCoordinatesStore"
import ScheduleEntryLocation from "apps/book/models/xpass/ScheduleEntryLocation"
import defaultPin from "images/markers/pin.png"

// Active
import aktActive from "images/xpass/markers/active/akt.png"
import bftActive from "images/xpass/markers/active/bft.png"
import clubpilatesActive from "images/xpass/markers/active/clubpilates.png"
import cyclebarActive from "images/xpass/markers/active/cyclebar.png"
import purebarreActive from "images/xpass/markers/active/purebarre.png"
import rowhouseActive from "images/xpass/markers/active/rowhouse.png"
import rumbleActive from "images/xpass/markers/active/rumble.png"
import stretchlabActive from "images/xpass/markers/active/stretchlab.png"
import strideActive from "images/xpass/markers/active/stride.png"
import yogasixActive from "images/xpass/markers/active/yogasix.png"
import kinrgyActive from "images/xpass/markers/active/kinrgy.png"

// Active - Selected
import aktActiveSelected from "images/xpass/markers/active-selected/akt.png"
import bftActiveSelected from "images/xpass/markers/active-selected/bft.png"
import clubpilatesActiveSelected from "images/xpass/markers/active-selected/clubpilates.png"
import cyclebarActiveSelected from "images/xpass/markers/active-selected/cyclebar.png"
import purebarreActiveSelected from "images/xpass/markers/active-selected/purebarre.png"
import rowhouseActiveSelected from "images/xpass/markers/active-selected/rowhouse.png"
import rumbleActiveSelected from "images/xpass/markers/active-selected/rumble.png"
import stretchlabActiveSelected from "images/xpass/markers/active-selected/stretchlab.png"
import strideActiveSelected from "images/xpass/markers/active-selected/stride.png"
import yogasixActiveSelected from "images/xpass/markers/active-selected/yogasix.png"
import kinrgyActiveSelected from "images/xpass/markers/active-selected/kinrgy.png"

// Inactive
import aktInactive from "images/xpass/markers/inactive/akt.png"
import bftInactive from "images/xpass/markers/inactive/bft.png"
import clubpilatesInactive from "images/xpass/markers/inactive/clubpilates.png"
import cyclebarInactive from "images/xpass/markers/inactive/cyclebar.png"
import purebarreInactive from "images/xpass/markers/inactive/purebarre.png"
import rowhouseInactive from "images/xpass/markers/inactive/rowhouse.png"
import rumbleInactive from "images/xpass/markers/inactive/rumble.png"
import stretchlabInactive from "images/xpass/markers/inactive/stretchlab.png"
import strideInactive from "images/xpass/markers/inactive/stride.png"
import yogasixInactive from "images/xpass/markers/inactive/yogasix.png"
import kinrgyInactive from "images/xpass/markers/inactive/kinrgy.png"

// Inactive - Selected
import aktInactiveSelected from "images/xpass/markers/inactive-selected/akt.png"
import bftInactiveSelected from "images/xpass/markers/inactive-selected/bft.png"
import clubpilatesInactiveSelected from "images/xpass/markers/inactive-selected/clubpilates.png"
import cyclebarInactiveSelected from "images/xpass/markers/inactive-selected/cyclebar.png"
import purebarreInactiveSelected from "images/xpass/markers/inactive-selected/purebarre.png"
import rowhouseInactiveSelected from "images/xpass/markers/inactive-selected/rowhouse.png"
import rumbleInactiveSelected from "images/xpass/markers/inactive-selected/rumble.png"
import stretchlabInactiveSelected from "images/xpass/markers/inactive-selected/stretchlab.png"
import strideInactiveSelected from "images/xpass/markers/inactive-selected/stride.png"
import yogasixInactiveSelected from "images/xpass/markers/inactive-selected/yogasix.png"
import kinrgyInactiveSelected from "images/xpass/markers/inactive-selected/kinrgy.png"

mapboxgl.accessToken = window.globals.mapboxAccessToken

export interface Props {
  store: BrandStore
  scheduleEntryStore: ScheduleEntryStore
  scheduleEntryLocationStore: ScheduleEntryLocationStore
  userCoordinatesStore: UserCoordinatesStore
  filterEntries: Function
}

@observer
export default class ScheduleEntriesMap extends React.Component<Props> {
  mapContainer?: HTMLDivElement | null
  map?: mapboxgl.Map
  popupWindow?: mapboxgl.Popup
  slugBrandName = {
    'akt': 'AKT',
    'bft': 'BFT',
    'clubpilates': 'Club Pilates',
    'cyclebar': 'CycleBar',
    'purebarre': 'Pure Barre',
    'rowhosue': 'Row House',
    'rowhouse': 'Row House',
    'rumble': 'Rumble',
    'stretchlab': 'StretchLab',
    'stride': 'Stride Fitness',
    'yogasix': 'YogaSix',
    'kinrgy': 'KINRGY',
  }
  @observable isLoaded: boolean = false
  @observable filteredLocation: string = ""
  @observable deselectStudio: boolean = false

  componentDidMount() {
    this.props.scheduleEntryLocationStore.fetch()

    const { userCoordinates } = this.props.userCoordinatesStore
    if (userCoordinates) {
      this.setMap(userCoordinates.lng, userCoordinates.lat)
    }
  }

  componentDidUpdate() {
    const { disabledLocations, enabledLocations, isLoadingLocations } = this.props.scheduleEntryLocationStore
    const { isFiltered, isLoadingEntries, highlightedStudio, scheduleEntries } = this.props.scheduleEntryStore
    const { locationCoordinates, userCoordinates, defaultCoordinates } = this.props.userCoordinatesStore

    if (this.map && isLoadingLocations) {
      this.removePopup()

      if (this.isLoaded) {
        this.removeMapPins()
      }
    }

    // Center map at selected studio coordinates, else searched city coordinates, else dragged map center or user default coordinates
    const coordinates = highlightedStudio ? [highlightedStudio.lng, highlightedStudio.lat] : locationCoordinates ? locationCoordinates : userCoordinates
    this.flyToLocation(coordinates)

    if (this.map && highlightedStudio && highlightedStudio.xpassOptedIn && !isLoadingLocations) {
      // Show info popup and selected pin when studio card is clicked
      const hasLocationPopup = document.querySelector(`.${highlightedStudio.slug}`)
      if (!hasLocationPopup) {
        this.removePopup()
        this.setPopupWindow(this.slugBrandName[highlightedStudio.brandSlug], highlightedStudio)
        const isActiveLocation = enabledLocations.find(loc => loc.slug === highlightedStudio.slug)

        if (isActiveLocation) {
          this.showActiveSelectedPin(highlightedStudio.slug)
        } else {
          this.showInactiveSelectedPin(highlightedStudio.slug)
        }
      }

      if ((highlightedStudio.classesAvailable || highlightedStudio.servicesAvailable) && this.filteredLocation !== highlightedStudio.slug) {
        // Apply filters once when location selected
        this.filteredLocation = highlightedStudio.slug
        this.props.scheduleEntryStore!.setFilteredLocations([])
        this.props.scheduleEntryStore.setIsFiltered(true)
        this.props.filterEntries()
      }
    }

    // Reset map and entries list when highlighted studio is deselected
    if (this.map && !highlightedStudio && this.filteredLocation.length > 0) {
      this.deselectStudio = false
      this.filteredLocation = ""
      this.removePopup()
      this.removeSelectedPin()
      this.props.scheduleEntryStore.setIsPaginated(false)
      this.props.scheduleEntryStore.fetchByFilters()
    }
    if (this.map && !this.deselectStudio) {
      this.map.on("click", () => {
        const popup = document.querySelectorAll(".mapboxgl-popup")
        if (popup.length === 0 && this.filteredLocation.length > 0) {
          this.deselectStudio = true
          this.props.scheduleEntryStore!.setHighlightedStudio(undefined)
        }
      })
    }

    if (!this.isLoaded && !isLoadingLocations && disabledLocations && enabledLocations && this.map) {
      this.isLoaded = true
      this.map.once('idle', this.addMapPins)
    }
  }

  flyToLocation = (coordinates: any) => {
    this.map!.flyTo({
      center: coordinates,
      zoom: 10,
    })
  }

  setMap(lng: number, lat: number) {
    this.map = new mapboxgl.Map({
      container: this.mapContainer!,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom: 10,
      touchZoomRotate: false,
      dragRotate: false,
      attributionControl: false
    })

    this.map.touchZoomRotate.enable(); // Enable pinch zoom + rotate
    this.map.touchZoomRotate.disableRotation(); // Disable rotate, leaving pinch zoom enabled
    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on("load", this.addMapPins)
  }

  componentWillUnmount() {
    this.props.scheduleEntryLocationStore.dispose()
    if (this.map) {
      this.map.off("load", this.addMapPins)
      this.map.remove()
    }
  }

  addMapPins = () => {
    const { enabledLocations, disabledLocations } = this.props.scheduleEntryLocationStore
    this.addInactivePins(disabledLocations)
    this.addActivePins(enabledLocations)
    this.addInactiveSelectedPins(disabledLocations)
    this.addActiveSelectedPins(enabledLocations)
  }

  showActiveSelectedPin = (locationSlug: string) => {
    this.map!.moveLayer("studios-selected-layer")
    this.map!.setLayoutProperty("studios-selected-layer", "visibility", "visible")
    this.map!.setLayoutProperty("studios-inactive-selected-layer", "visibility", "none")
    this.map!.setFilter("studios-selected-layer", ["==", ["get", "location"], `${locationSlug}`])
  }

  showInactiveSelectedPin = (locationSlug: string) => {
    this.map!.moveLayer("studios-inactive-selected-layer")
    this.map!.setLayoutProperty("studios-inactive-selected-layer", "visibility", "visible")
    this.map!.setLayoutProperty("studios-selected-layer", "visibility", "none")
    this.map!.setFilter("studios-inactive-selected-layer", ["==", ["get", "location"], `${locationSlug}`])
  }

  removeSelectedPin = () => {
    this.map!.setLayoutProperty("studios-inactive-selected-layer", "visibility", "none")
    this.map!.setLayoutProperty("studios-selected-layer", "visibility", "none")
  }

  removePopup = () => {
    const popup = document.querySelectorAll(".mapboxgl-popup")
    if (popup && popup.length > 0) {
      popup.forEach((el, i)=> {
        el.parentNode!.removeChild(el)
      })
    }
  }

  removeMapPins = () => {
    this.isLoaded = false
    this.removePopup()

    const layers = this.map!.getStyle().layers
    const customLayers = layers!.filter(layer => layer.id.includes("studios"))
    customLayers.forEach(layer => {
      this.map!.removeLayer(layer.id)
    })

    if (this.map!.getSource('studios-inactive')) {
      this.map!.removeSource('studios-inactive')
    }
    if (this.map!.getSource('studios')) {
      this.map!.removeSource('studios')
    }
    if (this.map!.getSource('studios-inactive-selected')) {
      this.map!.removeSource('studios-inactive-selected')
    }
    if (this.map!.getSource('studios-selected')) {
      this.map!.removeSource('studios-selected')
    }
  }

  onDragClick = () => {
    this.removeMapPins()
    this.props.scheduleEntryStore.setIsDragged(false)
    this.props.filterEntries()
  }

  // Popup window
  setPopupWindow = (brand: string, location: any) => {
    const markerHeight = 60, markerRadius = 30
    this.popupWindow = new mapboxgl.Popup({
      anchor: window.innerWidth > 768 ? "left" : "top",
      offset: {
        top: [0, 0],
        bottom: [0, -markerHeight],
        left: [markerRadius, (markerHeight - markerRadius) * -1],
        right: [-markerRadius, (markerHeight - markerRadius) * -1],
      },
      closeOnClick: true,
    })

    const brandSlug = location.xpassOptedIn && (location.classesAvailable || location.servicesAvailable) ? location.brandSlug : `${location.brandSlug}-inactive`
    const info = `<div>
      <div class="d-flex ${location.slug}">
        <div>
          <img src=${this.props.scheduleEntryStore.getBrandIcon(brandSlug)} alt=${location.siteSlug}-icon />
        </div>
        <div class="details w-100 ${location.xpassOptedIn && location.description || !location.xpassOptedIn ? 'padded' : ''} ">
          <h3>${brand} ${location.name}</h3>
          <p class="mb-0">${location.address}${location.address2 ? ` ${location.address2},` : ","}</p>
          <p class="d-flex flex-wrap mb-0"><span class="pr-2">${location.city}, ${location.state} ${location.zip}</span> ${location.xpassOptedIn
            ? `<a href="/book/${this.props.store.userStore.session!.locationId}/location/${location.slug}" target="_blank">View Studio</a>`
            : ''}
          </p>
        </div>
      </div>

      <div class="info ${location.xpassOptedIn && location.description || !location.xpassOptedIn ? 'description' : ''} ${location.xpassOptedIn ? 'opted-in' : ''}">
        <p>${location.xpassOptedIn ? location.description ? location.description : "" : "Currently Not Available on XPASS"}</p>
      </div>
    </div>`

    if (this.popupWindow) {
      this.popupWindow
        .setLngLat([location.lng, location.lat])
        .setHTML(info)
        .addTo(this.map!)
    }
  }

  // Inactive Pins
  addInactivePins(scheduleEntryLocations: ScheduleEntryLocation[]) {
    const inactivePins = [
      { imageUrl: aktInactive, id: "akt-pin-inactive" },
      { imageUrl: bftInactive, id: "bft-pin-inactive" },
      { imageUrl: clubpilatesInactive, id: "clubpilates-pin-inactive" },
      { imageUrl: cyclebarInactive, id: "cyclebar-pin-inactive" },
      { imageUrl: purebarreInactive, id: "purebarre-pin-inactive" },
      { imageUrl: rowhouseInactive, id: "rowhouse-pin-inactive" },
      { imageUrl: rumbleInactive, id: "rumble-pin-inactive" },
      { imageUrl: stretchlabInactive, id: "stretchlab-pin-inactive" },
      { imageUrl: strideInactive, id: "stride-pin-inactive" },
      { imageUrl: yogasixInactive, id: "yogasix-pin-inactive" },
      { imageUrl: kinrgyInactive, id: "kinrgy-pin-inactive" },
      { imageUrl: defaultPin, id: "default-pin-inactive" },
    ]

    const setInactivePin = (brand: string) => {
      switch (brand) {
        case "akt":
          return "akt-pin-inactive"
        case "bft":
          return "bft-pin-inactive"
        case "clubpilates":
          return "clubpilates-pin-inactive"
        case "cyclebar":
          return "cyclebar-pin-inactive"
        case "purebarre":
          return "purebarre-pin-inactive"
        case "rowhouse":
          return "rowhouse-pin-inactive"
        case "rumble":
          return "rumble-pin-inactive"
        case "stretchlab":
          return "stretchlab-pin-inactive"
        case "stride":
          return "stride-pin-inactive"
        case "yogasix":
          return "yogasix-pin-inactive"
        case "kinrgy":
          return "kinrgy-pin-inactive"
        default:
          return "default-pin-inactive"
      }
    }

   const hasSource = this.map!.getSource(`studios-inactive`)

   if (!hasSource) {
      this.map!.addSource(`studios-inactive`, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: scheduleEntryLocations.map(location => {
            return {
              type: "Feature",
              properties: {
                icon: setInactivePin(location.brandSlug),
                brandName: this.slugBrandName[location.brandSlug],
                brand: location.brandSlug,
                location: location.slug,
                data: location,
              },
              geometry: {
                type: "Point",
                coordinates: [location.lng, location.lat],
              },
            }
          }),
        },
      })

      inactivePins.forEach(img => {
        this.map!.loadImage(img.imageUrl, (error?: Error, image?: any) => {
          if (error) throw error
          if (!this.map!.hasImage(img.id)) {
            this.map!.addImage(img.id, image)
          }

          this.map!.addLayer({
            id: `studios-layer-inactive-${img.id}`,
            source: `studios-inactive`,
            type: "symbol",
            layout: {
              "icon-image": ["get", "icon"],
              "icon-size": 0.2,
              "icon-anchor": "bottom",
              "icon-allow-overlap": true,
            },
          })

          this.map!.on("mouseenter", `studios-layer-inactive-${img.id}`, () => {
            this.map!.getCanvas().style.cursor = "pointer"
          })
          this.map!.on("mouseleave", `studios-layer-inactive-${img.id}`, () => {
            this.map!.getCanvas().style.cursor = ""
          })

          this.map!.on("click", `studios-layer-inactive-${img.id}`, e => {
            if (e && e.features) {
              const [feature] = e.features
              const location = JSON.parse(feature.properties!.data)
              const popup = document.querySelector(`.${feature.properties!.location}`)

              if (!popup) {
                this.showInactiveSelectedPin(feature.properties!.location)
                this.props.scheduleEntryStore!.setHighlightedStudio(location)
                this.setPopupWindow(feature.properties!.brandName, location)
              }
            }
          })

          // Move inactive pins layer to bottom layer
          const enabled = img.id.replace("-inactive", "")
          const inactiveLayers = this.map!.getLayer(`studios-layer-inactive-${img.id}`)
          const enabledLayers = this.map!.getLayer(`studios-layer-${enabled}`)
          if (inactiveLayers && enabledLayers) {
            this.map!.moveLayer(`studios-layer-inactive-${img.id}`, `studios-layer-${enabled}`)
          }
        })
      })
    }
  }

  // Inactive - Selected Pins
  addInactiveSelectedPins(scheduleEntryLocations: ScheduleEntryLocation[]) {
    const inactiveSelectedPins = [
      { imageUrl: aktInactiveSelected, id: "akt-pin-inactive-selected" },
      { imageUrl: bftInactiveSelected, id: "bft-pin-inactive-selected" },
      { imageUrl: clubpilatesInactiveSelected, id: "clubpilates-pin-inactive-selected" },
      { imageUrl: cyclebarInactiveSelected, id: "cyclebar-pin-inactive-selected" },
      { imageUrl: purebarreInactiveSelected, id: "purebarre-pin-inactive-selected" },
      { imageUrl: rowhouseInactiveSelected, id: "rowhouse-pin-inactive-selected" },
      { imageUrl: rumbleInactiveSelected, id: "rumble-pin-inactive-selected" },
      { imageUrl: stretchlabInactiveSelected, id: "stretchlab-pin-inactive-selected" },
      { imageUrl: strideInactiveSelected, id: "stride-pin-inactive-selected" },
      { imageUrl: yogasixInactiveSelected, id: "yogasix-pin-inactive-selected" },
      { imageUrl: kinrgyInactiveSelected, id: "kinrgy-pin-inactive-selected" },
      { imageUrl: defaultPin, id: "default-pin-inactive-selected" },
    ]

    const setInactiveSelectedPin = (brand: string) => {
      switch (brand) {
        case "akt":
          return "akt-pin-inactive-selected"
        case "bft":
          return "bft-pin-inactive-selected"
        case "clubpilates":
          return "clubpilates-pin-inactive-selected"
        case "cyclebar":
          return "cyclebar-pin-inactive-selected"
        case "purebarre":
          return "purebarre-pin-inactive-selected"
        case "rowhouse":
          return "rowhouse-pin-inactive-selected"
        case "rumble":
          return "rumble-pin-inactive-selected"
        case "stretchlab":
          return "stretchlab-pin-inactive-selected"
        case "stride":
          return "stride-pin-inactive-selected"
        case "yogasix":
          return "yogasix-pin-inactive-selected"
        case "kinrgy":
          return "kinrgy-pin-inactive-selected"
        default:
          return "default-pin-inactive-selected"
      }
    }

    inactiveSelectedPins.forEach(img => {
      this.map!.loadImage(img.imageUrl, (error?: Error, image?: any) => {
        if (error) throw error
        if (!this.map!.hasImage(img.id)) {
          this.map!.addImage(img.id, image)
        }
      })
    })

    const hasSource = this.map!.getSource('studios-inactive-selected')

    if (!hasSource) {
      this.map!.addSource("studios-inactive-selected", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: scheduleEntryLocations.map(location => {
            return {
              type: "Feature",
              properties: {
                icon: setInactiveSelectedPin(location.brandSlug),
                brand: location.brandSlug,
                location: location.slug,
              },
              geometry: {
                type: "Point",
                coordinates: [location.lng, location.lat],
              },
            }
          }),
        },
      })

      this.map!.addLayer({
        id: "studios-inactive-selected-layer",
        source: "studios-inactive-selected",
        type: "symbol",
        layout: {
          "icon-image": ["get", "icon"],
          "icon-size": 0.2,
          "icon-anchor": "bottom",
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          visibility: "none",
        },
      })
    }
  }

  // Active Pins
  addActivePins(scheduleEntryLocations: ScheduleEntryLocation[]) {
    const activePins = [
      { imageUrl: aktActive, id: "akt-pin" },
      { imageUrl: bftActive, id: "bft-pin" },
      { imageUrl: clubpilatesActive, id: "clubpilates-pin" },
      { imageUrl: cyclebarActive, id: "cyclebar-pin" },
      { imageUrl: purebarreActive, id: "purebarre-pin" },
      { imageUrl: rowhouseActive, id: "rowhouse-pin" },
      { imageUrl: rumbleActive, id: "rumble-pin" },
      { imageUrl: stretchlabActive, id: "stretchlab-pin" },
      { imageUrl: strideActive, id: "stride-pin" },
      { imageUrl: yogasixActive, id: "yogasix-pin" },
      { imageUrl: kinrgyActive, id: "kinrgy-pin" },
      { imageUrl: defaultPin, id: "default-pin" },
    ]

    const setActivePin = (brand: string) => {
      switch (brand) {
        case "akt":
          return "akt-pin"
        case "bft":
          return "bft-pin"
        case "clubpilates":
          return "clubpilates-pin"
        case "cyclebar":
          return "cyclebar-pin"
        case "purebarre":
          return "purebarre-pin"
        case "rowhouse":
          return "rowhouse-pin"
        case "rumble":
          return "rumble-pin"
        case "stretchlab":
          return "stretchlab-pin"
        case "stride":
          return "stride-pin"
        case "yogasix":
          return "yogasix-pin"
        case "kinrgy":
          return "kinrgy-pin"
        default:
          return "default-pin"
      }
    }

    const hasSource = this.map!.getSource(`studios`)

    if (!hasSource) {
      this.map!.addSource(`studios`, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: scheduleEntryLocations.map(location => {
            return {
              type: "Feature",
              properties: {
                icon: setActivePin(location.brandSlug),
                brandName: this.slugBrandName[location.brandSlug],
                brand: location.brandSlug,
                location: location.slug,
                data: location,
              },
              geometry: {
                type: "Point",
                coordinates: [location.lng, location.lat],
              },
            }
          }),
        },
      })

      activePins.forEach(img => {
        this.map!.loadImage(img.imageUrl, (error?: Error, image?: any) => {
          if (error) throw error
          if (!this.map!.hasImage(img.id)) {
            this.map!.addImage(img.id, image)
          }

          this.map!.addLayer({
            id: `studios-layer-${img.id}`,
            source: `studios`,
            type: "symbol",
            layout: {
              "icon-image": ["get", "icon"],
              "icon-size": 0.2,
              "icon-anchor": "bottom",
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
            },
          })

          this.map!.on("mouseenter", `studios-layer-${img.id}`, () => {
            this.map!.getCanvas().style.cursor = "pointer"
          })
          this.map!.on("mouseleave", `studios-layer-${img.id}`, () => {
            this.map!.getCanvas().style.cursor = ""
          })

          this.map!.on("click", `studios-layer-${img.id}`, e => {
            if (e && e.features) {
              const [feature] = e.features
              const location = JSON.parse(feature.properties!.data)
              const popup = document.querySelector(`.${feature.properties!.location}`)

              if (!popup) {
                this.showActiveSelectedPin(feature.properties!.location)
                this.props.scheduleEntryStore.setHighlightedStudio(location)
                this.setPopupWindow(feature.properties!.brandName, location)
              }
            }
          })

          // Move inactive pins layer to bottom layer
          const inactive = `${img.id}-inactive`
          const inactiveLayers = this.map!.getLayer(`studios-layer-inactive-${inactive}`)
          const enabledLayers = this.map!.getLayer(`studios-layer-${img.id}`)
          if (inactiveLayers && enabledLayers) {
            this.map!.moveLayer(`studios-layer-inactive-${inactive}`, `studios-layer-${img.id}`)
          }
        })
      })
    }

    this.map!.on("dragend", e => {
      const layers = this.map!.getStyle().layers
      const customLayers = layers!.filter(layer => layer.id.includes("studios"))
      customLayers.map(layer => {
        this.map!.setLayoutProperty(layer.id, "visibility", "none")
      })

      const center = this.map!.getCenter()
      this.props.userCoordinatesStore.setCoordinates(center)
      this.props.userCoordinatesStore.setLocationCoordinates(undefined)
      this.props.scheduleEntryStore.setIsDragged(true)
      this.props.scheduleEntryStore.setHighlightedStudio(undefined)
      this.props.scheduleEntryStore.setFilteredLocations([])
      this.removePopup()
    })
  }

  // Active - Selected Pins
  addActiveSelectedPins(scheduleEntryLocations: ScheduleEntryLocation[]) {
    const activeSelectedPins = [
      { imageUrl: aktActiveSelected, id: "akt-pin-selected" },
      { imageUrl: bftActiveSelected, id: "bft-pin-selected" },
      { imageUrl: clubpilatesActiveSelected, id: "clubpilates-pin-selected" },
      { imageUrl: cyclebarActiveSelected, id: "cyclebar-pin-selected" },
      { imageUrl: purebarreActiveSelected, id: "purebarre-pin-selected" },
      { imageUrl: rowhouseActiveSelected, id: "rowhouse-pin-selected" },
      { imageUrl: rumbleActiveSelected, id: "rumble-pin-selected" },
      { imageUrl: stretchlabActiveSelected, id: "stretchlab-pin-selected" },
      { imageUrl: strideActiveSelected, id: "stride-pin-selected" },
      { imageUrl: yogasixActiveSelected, id: "yogasix-pin-selected" },
      { imageUrl: kinrgyActiveSelected, id: "kinrgy-pin-selected" },
      { imageUrl: defaultPin, id: "default-pin-selected" },
    ]

    const setActiveSelectedPin = (brand: string) => {
      switch (brand) {
        case "akt":
          return "akt-pin-selected"
        case "bft":
          return "bft-pin-selected"
        case "clubpilates":
          return "clubpilates-pin-selected"
        case "cyclebar":
          return "cyclebar-pin-selected"
        case "purebarre":
          return "purebarre-pin-selected"
        case "rowhouse":
          return "rowhouse-pin-selected"
        case "rumble":
          return "rumble-pin-selected"
        case "stretchlab":
          return "stretchlab-pin-selected"
        case "stride":
          return "stride-pin-selected"
        case "yogasix":
          return "yogasix-pin-selected"
        case "kinrgy":
          return "kinrgy-pin-selected"
        default:
          return "default-pin-selected"
      }
    }

    activeSelectedPins.forEach(img => {
      this.map!.loadImage(img.imageUrl, (error?: Error, image?: any) => {
        if (error) throw error
        if (!this.map!.hasImage(img.id)) {
          this.map!.addImage(img.id, image)
        }
      })
    })

    const hasSource = this.map!.getSource("studios-selected")

    if (!hasSource) {
      this.map!.addSource("studios-selected", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: scheduleEntryLocations.map(location => {
            return {
              type: "Feature",
              properties: {
                icon: setActiveSelectedPin(location.brandSlug),
                brand: location.brandSlug,
                location: location.slug,
              },
              geometry: {
                type: "Point",
                coordinates: [location.lng, location.lat],
              },
            }
          }),
        },
      })

      this.map!.addLayer({
        id: "studios-selected-layer",
        source: "studios-selected",
        type: "symbol",
        layout: {
          "icon-image": ["get", "icon"],
          "icon-size": 0.2,
          "icon-anchor": "bottom",
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          visibility: "none",
        },
      })
    }
  }

  render() {
    // Triggers re-render
    const { locationCoordinates, userCoordinates, defaultCoordinates } = this.props.userCoordinatesStore
    const { disabledLocations, enabledLocations, isLoadingLocations } = this.props.scheduleEntryLocationStore!
    const { isDragged, isLoadingEntries, highlightedStudio, scheduleEntries } = this.props.scheduleEntryStore!

    return (
      <>
        <div className="bg-white">
          <div className="mapbox-container">
            <div ref={r => (this.mapContainer = r)} className="mapbox-map" />
            {isDragged && (
              <div className="schedule-entries__status">
                <button onClick={this.onDragClick}>Redo Search in this area</button>
              </div>
            )}
            {isLoadingLocations && (
              <div className="schedule-entries__status">
                <span>Loading...</span>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}
