import * as React from "react"
import { Link } from "react-router-dom"
import { inject, observer } from "mobx-react"
import { observable } from "mobx"
import capitalize from "lodash/capitalize"
import cx from "classnames"

import APILoader from "components/wrappers/APILoader"
import PageTracker from "components/wrappers/PageTracker"
import Heading from "components/Heading"

import BrandStore from "stores/BrandStore"
import BookingInfoStore from "apps/buy/stores/BookingInfoStore"
import PackagesStore from "apps/buy/stores/PackagesStore"
import PurchasesStore from "apps/account/stores/PurchasesStore"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import LocationSummary from "models/LocationSummary"
import Purchase from "apps/account/models/Purchase"
import PurchaseCard from "apps/account/components/PurchaseCard"
import PurchasesTable from "apps/account/components/PurchasesTable"
import PackageCard from "apps/buy/components/PackageCard"

export interface Props {
  store: BrandStore
  locationSummary: LocationSummary
}

type ClassText = {
  one: string
  many: string
}

export interface State {
  classTokens: number
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class MembershipsPage extends React.Component<Props, State, {}> {
  constructor(props: Props) {
    super(props)
    this.state = {classTokens: 0 }
  }

  purchasesStore = new PurchasesStore(this.props.store)
  balancesStore = new BalancesStore(this.props.store)
  @observable
  bookingInfoStore = BookingInfoStore.fromQueryString(
    this.props.locationSummary,
    this.props.store!
  )
  packagesStore: PackagesStore = new PackagesStore(
    this.props.store!,
    this.props.locationSummary,
    this.bookingInfoStore
  )

  componentDidMount() {
    if (this.props.store.isXponential) {
      this.balancesStore.fetch()
      .then(res => this.setState({ classTokens: res.data.balances.classTokens }));
    }
  }

  componentWillUnmount() {
    this.purchasesStore.dispose()
    this.packagesStore.dispose()
    if (this.props.store.isXponential) {
      this.balancesStore.dispose()
    }
  }

  render() {
    const { copy, settings, isXponential } = this.props.store
    const {
      nonExpiredPurchases: regularPurchases,
      xpassNonExpiredPurchases: xpassPurchases,
      xpassActiveRecurringPurchases: activePurchases,
      xpassInactiveRecurringPurchases: inactivePurchases,
      xpassAddOnPurchases: addOnPurchases
    } = this.purchasesStore
    const showRemainingCredit = settings.showRemainingCredit
    const nonExpiredPurchases = isXponential ? xpassPurchases : regularPurchases
    const membershipText = isXponential
      ? "Purchases"
      : `${this.props.locationSummary.name} ${copy.purchases}`
    const purchasesTableDateFormat = this.props.store.styleClasses.PurchasesTable__dateFormat

    return (
      <div className="account-memberships col">
        <Heading>{`My ${membershipText}`}</Heading>
        <PageTracker name="account > memberships" />

        <APILoader
          apiStore={this.purchasesStore}
          render={() => {
            const recurringPurchases = addOnPurchases.filter(p => p.isRecurring)
            const recurringActivePurchases = activePurchases.filter(p => p.isRecurring)
            return (
              <>
                {isXponential ? (
                  <>
                    {/* TODO: based on the design we don't need this anymore, but let's keep it for now just in case. P.S. delete commented code if not needed anymore */}
                    <h2 className="slide-in delay-2 d-flex align-items-center">
                      <span className="total-credits-badge badge badge-primary mr-1">
                        {this.state.classTokens}
                      </span>{" "}
                      {capitalize(copy.credits)} Remaining
                    </h2>
                    <div className="pt-3">
                      <h2 className="mb-0">Plans</h2>
                      <div className="row cards-in">
                        {activePurchases && activePurchases.length > 0 && activePurchases.map(purchase => (
                          <PackageCard
                            key={purchase.package.id}
                            pkg={purchase.package}
                            locationSummary={this.props.locationSummary}
                            store={this.props.store!}
                            purchasesStore={this.purchasesStore}
                            purchaseDisabled={false}
                            updateType={"Cancel"}
                          />
                        ))}
                        {inactivePurchases && inactivePurchases.length > 0 && inactivePurchases.map((purchase, i) => (
                          <PurchaseCard
                            key={i}
                            purchase={purchase}
                            locationSummary={this.props.locationSummary}
                            classText={{
                              one: copy.class,
                              many: copy.credits,
                            }}
                            purchasesStore={this.purchasesStore}
                          />
                        ))}
                        <PurchaseMoreCard
                          classText={{
                            one: copy.class,
                            many: copy.classes,
                          }}
                          store={this.props.store}
                          activePurchases={activePurchases}
                          handleBuyClick={() => {
                            this.props.store!.track.event(
                              "memberships_tap view plans"
                            )
                          }}
                        />
                      </div>

                      {addOnPurchases.length > 0 || activePurchases.length > 0 ? (
                        <>
                          {(!isXponential || (isXponential && (recurringActivePurchases.length > 0 || recurringPurchases.length > 0))) && <h2 className="mb-0">Add-Ons</h2>}
                          <div className="row cards-in">
                            {addOnPurchases.length > 0 && addOnPurchases.map((purchase, i) => {
                                if (!purchase.isRecurring && isXponential) return
                                
                                return (
                                  <PurchaseCard
                                    key={i}
                                    purchase={purchase}
                                    locationSummary={this.props.locationSummary}
                                    classText={{
                                      one: copy.class,
                                      many: copy.credits,
                                    }}
                                    activePurchase={activePurchases.length > 0}
                                    purchasesStore={this.purchasesStore}
                                  />
                                )
                              })
                            }

                            {activePurchases.length > 0 && (
                              <PurchaseMoreCard
                                classText={{
                                  one: copy.class,
                                  many: copy.classes,
                                }}
                                store={this.props.store}
                                activePurchases={activePurchases}
                                locationSummary={this.props.locationSummary}
                                isAddOn={true}
                                handleBuyClick={() => {
                                  this.props.store!.track.event(
                                    "memberships_tap view plans"
                                  )
                                }}
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {showRemainingCredit && !!this.purchasesStore.totalCredits && (
                      <h3 className="total-remaining slide-in delay-2">
                        <span className="total-credits-badge badge badge-primary mr-1">
                          {this.purchasesStore.totalCredits}
                        </span>{" "}
                        {capitalize(copy.membershipsClassText)} Remaining
                      </h3>
                    )}

                    <div className="row cards-in">
                      {nonExpiredPurchases.map((purchase, i) => (
                        <PurchaseCard
                          key={i}
                          purchase={purchase}
                          locationSummary={this.props.locationSummary}
                          classText={{
                            one: copy.class,
                            many: copy.membershipsClassText,
                          }}
                          purchasesStore={this.purchasesStore}
                        />
                      ))}

                      <PurchaseMoreCard
                        classText={{
                          one: copy.class,
                          many: copy.classes,
                        }}
                        store={this.props.store}
                        handleBuyClick={() => {
                          this.props.store!.track.event(
                            "memberships_tap view plans"
                          )
                        }}
                      />
                    </div>
                  </>
                )}

                {debugUI && (
                  <PurchasesTable
                    purchases={this.purchasesStore.purchases}
                    isXponential={isXponential}
                    purchasesTableDateFormat={purchasesTableDateFormat}
                  />
                )}
              </>
            )
          }}
        />
      </div>
    )
  }
}

const PurchaseMoreCard: React.FunctionComponent<{
  classText: ClassText
  store: BrandStore
  activePurchases?: Purchase[]
  locationSummary?: LocationSummary
  isAddOn?: boolean
  handleBuyClick(): void
}> = ({ classText, store, activePurchases, locationSummary, isAddOn, handleBuyClick }) => {
  const { membershipsButtonText } = store.copy

  return (
    <div className="col-md-6 col-lg-4 col-xl-3 p-3">
      {/* TODO: track clicks on this? */}
      <Link
        className={cx(
          "placeholder-card card h-100 text-decoration-none",
          store!.isXponential && "xpass"
        )}
        to={
          isAddOn && locationSummary
            ? `/buy/${locationSummary.id}?add_on=true`
            : "/buy"
        }
        onClick={handleBuyClick}
      >
        <div className="card-body d-flex flex-column text-center">
          {store!.isXponential ? (
            isAddOn ? (
              <>
                {activePurchases && activePurchases.length > 0 && (
                  <>
                    <div className="h5 m-auto text-muted">
                      Purchase Add-on Tokens
                    </div>
                    <button className="btn btn-primary align-self-center">
                      Add Tokens
                    </button>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="h5 m-auto text-muted">
                  {activePurchases && activePurchases.length > 0
                    ? "Upgrade or Downgrade Plan"
                    : "Purchase a Plan"}
                </div>
                <button className="btn btn-primary align-self-center">
                  {activePurchases && activePurchases.length > 0
                    ? "Manage Plans"
                    : store!.isXponential
                      ? "Purchase Plan"
                      : "Buy Plans"
                  }
                </button>
              </>
            )
          ) : (
            <>
              <div className="h5 m-auto text-muted">
                Purchase a {capitalize(classText.one)} Membership or Package
              </div>
              <button className="btn btn-primary align-self-center">Buy {membershipsButtonText.length > 0 ? membershipsButtonText : capitalize(classText.many)}</button>
            </>
          )}
        </div>
      </Link>
    </div>
  )
}
