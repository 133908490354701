import { Moment } from "moment"

export const dateLongFormat = "ddd, MMM Do, YYYY"
export const timeFormat = "h:mma"

export const formatDateLong = (dt: Moment) => dt.format(dateLongFormat)
export const formatTime = (dt: Moment) => dt.format(timeFormat)
// only works when it's within a day
export const formatTimeRangeLong = (start: Moment, end: Moment) =>
  `${formatDateLong(start)} • ${formatTime(start)}–${formatTime(end)}`
