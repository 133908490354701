import { JsonObject, JsonProperty } from "json2typescript"
import { observable, action } from "mobx"

export type GoalPeriod = "weekly" | "monthly" | "lifetime"
export type GoalId = "attendance"

@JsonObject("Goal")
export default class Goal {
  /**
   * Identifier of the activity that counts towards the goal.
   * Currently always `attendance`.
   *
   */
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * How many times they need to complete the activity to acheive the goal.
   * 0 if the goal is unset.
   *
   */
  @observable
  @JsonProperty("target", Number)
  target: number = undefined!

  @observable
  @JsonProperty("target", Number)
  previousTarget: number = undefined!

  /**
   * For user-configurable goals, the minimum value to which the user can set the target.
   * Currently unused - always 1 for now.
   *
   */
  @observable
  @JsonProperty("target_min", Number, true)
  targetMin?: number = undefined!

  /**
   * For user-configurable goals, the maximum value to which the user can set the target.
   * Currently unused - always 14 for now.
   *
   */
  @observable
  @JsonProperty("target_max", Number, true)
  targetMax?: number = undefined!

  /**
   * The user's progress towards the target during the period.
   *
   */
  @observable
  @JsonProperty("progress", Number)
  progress: number = undefined!

  /**
   * Unit associated with the activity. E.g. for attendance it is just "times."
   * If the goal is "distance", the unit might be "meters."
   * Currently unused - always `times` for now.
   *
   */
  @JsonProperty("unit", String)
  unit: string = undefined!

  /**
   * The period or interval of the goal.
   * Currently unused - always `weekly` for now.
   *
   */
  @JsonProperty("period", String)
  period: GoalPeriod = undefined!
  /**
   * Whether the user can set the target for this goal (vs. it being hardcoded.)
   * Currently unused - always `true`.
   *
   */
  @JsonProperty("user_configurable", Boolean)
  userConfigurable: boolean = undefined!

  /**
   * The start date of the period associated with the goal. If empty/blank, it's
   * the current period.
   * Currently unused - always `null` (current week) for now.
   *
   */
  @JsonProperty("start_date", String, true)
  startDate?: string = undefined!

  @action.bound
  increment() {
    let value = (this.target || 0) + 1
    if (value > this.targetMax!) {
      value = this.targetMax!
    }
    this.target = Math.floor(value)
  }

  @action.bound
  decrement() {
    console.log(this.previousTarget)
    let value = (this.target || 0) - 1
    if (value < 0) {
      value = 0
    }
    this.target = Math.floor(value)
  }

  @action.bound
  setTarget(value: number) {
    this.target = value > this.targetMax! ? this.targetMax! : Math.floor(value)
  }

  @action.bound
  reset() {
    this.target = this.previousTarget
  }
}

@JsonObject("Goals")
export class Goals {
  @JsonProperty("attendance", Goal)
  attendance: Goal = undefined!
}
