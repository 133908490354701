import { Metric } from "apps/history/models/Stats" 
import {
  baseSettings,
  BrandData,
  BrandCopy,
  BrandSettings,
  BrandStyles,
  BrandStyleClasses,
  baseCopy,
  BadgeBitmap,
  baseStyles,
  baseStyleClasses,
  BrandDashboardCards,
  eywAffiliateLinks,
} from "themes/base"

import logoUrl from "images/logos/rowhouse-logo.svg"
import badgeImage from "images/badges/rowhouse-badge.png"
import bannerImage from "images/dashboard/about_hero_desktop.jpg"
import appPromoImage_Video from "images/video/rowhouse-app-promo.png"
import videoLandingLogo from "images/video/rowhouse-go-logo.png"
import appleWatchIcon from "images/apple_health/watch/rowhouse.svg"
import classpointsHero from "images/classpoints/classpoints-hero_rowhouse.png"
import brandIcon from "images/classpoints/brands/RH.svg"

import fiftyActive from "images/badges/rowhouse/50k-active.png"
import fiftyInactive from "images/badges/rowhouse/50k-inactive.png"
import hundredActive from "images/badges/rowhouse/100k-active.png"
import hundredInactive from "images/badges/rowhouse/100k-inactive.png"
import twoFiftyActive from "images/badges/rowhouse/250k-active.png"
import twoFiftyInactive from "images/badges/rowhouse/250k-inactive.png"
import fiveHundredActive from "images/badges/rowhouse/500k-active.png"
import fiveHundredInactive from "images/badges/rowhouse/500k-inactive.png"
import oneMillActive from "images/badges/rowhouse/1mill-active.png"
import oneMillInactive from "images/badges/rowhouse/1mill-inactive.png"
import oneMillFiveHundredThousandActive from "images/badges/rowhouse/1-5mill-active.png"
import oneMillFiveHundredThousandInactive from "images/badges/rowhouse/1-5mill-inactive.png"
import twoMillActive from "images/badges/rowhouse/2mill-active.png"
import twoMillInactive from "images/badges/rowhouse/2mill-inactive.png"
import twoMillFiveHundredThousandActive from "images/badges/rowhouse/2-5mill-active.png"
import twoMillFiveHundredThousandInactive from "images/badges/rowhouse/2-5mill-inactive.png"
import threeMillActive from "images/badges/rowhouse/3mill-active.png"
import threeMillInactive from "images/badges/rowhouse/3mill-inactive.png"
import fourMillActive from "images/badges/rowhouse/4mill-active.png"
import fourMillInactive from "images/badges/rowhouse/4mill-inactive.png"
import fourMillFiveHundredThousandActive from "images/badges/rowhouse/4-5mill-active.png"
import fourMillFiveHundredThousandInactive from "images/badges/rowhouse/4-5mill-inactive.png"
import fiveMillActive from "images/badges/rowhouse/5mill-active.png"
import fiveMillInactive from "images/badges/rowhouse/5mill-inactive.png"
import sixMillActive from "images/badges/rowhouse/6mill-active.png"
import sixMillInactive from "images/badges/rowhouse/6mill-inactive.png"
import sevenMillActive from "images/badges/rowhouse/7mill-active.png"
import sevenMillInactive from "images/badges/rowhouse/7mill-inactive.png"
import eightMillActive from "images/badges/rowhouse/8mill-active.png"
import eightMillInactive from "images/badges/rowhouse/8mill-inactive.png"
import nineMillActive from "images/badges/rowhouse/9mill-active.png"
import nineMillInactive from "images/badges/rowhouse/9mill-inactive.png"
import tenMillActive from "images/badges/rowhouse/10mill-active.png"
import tenMillInactive from "images/badges/rowhouse/10mill-inactive.png"
import elevenMillActive from "images/badges/rowhouse/11mill-active.png"
import elevenMillInactive from "images/badges/rowhouse/11mill-inactive.png"
import twelveMillActive from "images/badges/rowhouse/12mill-active.png"
import twelveMillInactive from "images/badges/rowhouse/12mill-inactive.png"
import thirteenMillActive from "images/badges/rowhouse/13mill-active.png"
import thirteenMillInactive from "images/badges/rowhouse/13mill-inactive.png"
import fourteenMillActive from "images/badges/rowhouse/14mill-active.png"
import fourteenMillInactive from "images/badges/rowhouse/14mill-inactive.png"
import fifteenMillActive from "images/badges/rowhouse/15mill-active.png"
import fifteenMillInactive from "images/badges/rowhouse/15mill-inactive.png"
import sixteenMillActive from "images/badges/rowhouse/16mill-active.png"
import sixteenMillInactive from "images/badges/rowhouse/16mill-inactive.png"
import seventeenMillActive from "images/badges/rowhouse/17mill-active.png"
import seventeenMillInactive from "images/badges/rowhouse/17mill-inactive.png"
import eighteenMillActive from "images/badges/rowhouse/18mill-active.png"
import eighteenMillInactive from "images/badges/rowhouse/18mill-inactive.png"
import nineteenMillActive from "images/badges/rowhouse/19mill-active.png"
import nineteenMillInactive from "images/badges/rowhouse/19mill-inactive.png"
import twentyMillActive from "images/badges/rowhouse/20mill-active.png"
import twentyMillInactive from "images/badges/rowhouse/20mill-inactive.png"


import numberFormatter, {
  formatNumber,
  formatBigNumber,
} from "helpers/numberFormatter"
import { formatDuration } from "helpers/stringHelper"

const copy: BrandCopy = {
  ...baseCopy,
}

const settings: BrandSettings = {
  ...baseSettings,
  isPerformance: true,
  hasLeaderboard: false,
  hasShoeSize: false,
  hasWeight: true,
  showPromoOnEmptyPerf: false,
  alwaysShowAppleWatchIcon: true
}

const styles: BrandStyles = {
  ...baseStyles,
  badgeImage,
  bannerImage,
  chartColor: "#ffcd03",
  logoUrl,
  appPromoImage_Video,
  videoLandingLogo,
  appleWatchIcon,
  classpointsHero,
  brandIcon
}

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
}

const dashboardCards: BrandDashboardCards = [
  "NextClassCard",
  "PerformanceCard",
  "AchievementsCard",
  "AttendanceCard",
  "TodaysClassCard",
  "ClassPointsCard",
  "AppleHealthCard",
  "ProfileCard",
  "MobileAppCard",
  "MobileOfferCard",
  "PromoCards",
]

const badges: BadgeBitmap[] = [
  {
    label: "50k",
    value: 50000,
    active: fiftyActive,
    inactive: fiftyInactive,
  },
  {
    label: "100k",
    value: 100000,
    active: hundredActive,
    inactive: hundredInactive,
  },
  {
    label: "250k",
    value: 250000,
    active: twoFiftyActive,
    inactive: twoFiftyInactive,
  },
  {
    label: "500k",
    value: 500000,
    active: fiveHundredActive,
    inactive: fiveHundredInactive,
  },
  {
    label: "1M",
    value: 1000000,
    active: oneMillActive,
    inactive: oneMillInactive,
  },
  {
    label: "1.5M",
    value: 1500000,
    active: oneMillFiveHundredThousandActive,
    inactive: oneMillFiveHundredThousandInactive,
  },
  {
    label: "2M",
    value: 2000000,
    active: twoMillActive,
    inactive: twoMillInactive,
  },
  {
    label: "2.5M",
    value: 2500000,
    active: twoMillFiveHundredThousandActive,
    inactive: twoMillFiveHundredThousandInactive,
  },
  {
    label: "3M",
    value: 3000000,
    active: threeMillActive,
    inactive: threeMillInactive,
  },
  {
    label: "4M",
    value: 4000000,
    active: fourMillActive,
    inactive: fourMillInactive,
  },
  {
    label: "4.5M",
    value: 4500000,
    active: fourMillFiveHundredThousandActive,
    inactive: fourMillFiveHundredThousandInactive,
  },
  {
    label: "5M",
    value: 5000000,
    active: fiveMillActive,
    inactive: fiveMillInactive,
  },
  {
    label: "6M",
    value: 6000000,
    active: sixMillActive,
    inactive: sixMillInactive,
  },
  {
    label: "7M",
    value: 7000000,
    active: sevenMillActive,
    inactive: sevenMillInactive,
  },
  {
    label: "8M",
    value: 8000000,
    active: eightMillActive,
    inactive: eightMillInactive,
  },
  {
    label: "9M",
    value: 9000000,
    active: nineMillActive,
    inactive: nineMillInactive,
  },
  {
    label: "10M",
    value: 10000000,
    active: tenMillActive,
    inactive: tenMillInactive,
  },
  {
    label: "11M",
    value: 11000000,
    active: elevenMillActive,
    inactive: elevenMillInactive,
  },
  {
    label: "12M",
    value: 12000000,
    active: twelveMillActive,
    inactive: twelveMillInactive,
  },
  {
    label: "13M",
    value: 13000000,
    active: thirteenMillActive,
    inactive: thirteenMillInactive,
  },
  {
    label: "14M",
    value: 14000000,
    active: fourteenMillActive,
    inactive: fourteenMillInactive,
  },
  {
    label: "15M",
    value: 15000000,
    active: fifteenMillActive,
    inactive: fifteenMillInactive,
  },
  {
    label: "16M",
    value: 16000000,
    active: sixteenMillActive,
    inactive: sixteenMillInactive,
  },
  {
    label: "17M",
    value: 17000000,
    active: seventeenMillActive,
    inactive: seventeenMillInactive,
  },
  {
    label: "18M",
    value: 18000000,
    active: eighteenMillActive,
    inactive: eighteenMillInactive,
  },
  {
    label: "19M",
    value: 19000000,
    active: nineteenMillActive,
    inactive: nineteenMillInactive,
  },
  {
    label: "20M",
    value: 20000000,
    active: twentyMillActive,
    inactive: twentyMillInactive,
  }
]

const metrics: Metric[] = [
  {
    key: "distance",
    label: "Distance",
    logo: "perf-distance",
    logoSize: 30,
    format: numberFormatter({ suffix: "m" }),
  },
  {
    key: "avgPace",
    label: "Average Split",
    logo: "perf-split",
    logoSize: 30,
    golf: true,
    format: formatDuration,
  },
  {
    key: "highPace",
    label: "Best Split",
    logo: "perf-split",
    logoSize: 30,
    golf: true,
    format: formatDuration,
  },
  {
    key: "avgPower",
    label: "Avg Watts",
    logo: "perf-power",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "highPower",
    label: "Max Watts",
    logo: "perf-power",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "calories",
    label: "Calories",
    logo: "perf-power",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "avgHr",
    label: "Avg Hr",
    logo: "perf-power",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "maxHr",
    label: "Max Hr",
    logo: "perf-power",
    logoSize: 30,
    format: formatNumber,
  },
  // {
  //   key: "totalEnergy",
  //   label: "Power Points",
  //   logo: "perf-points",
  //   logoSize: 28,
  //   format: formatNumber,
  // },
]

styles.badgeFormatter = formatBigNumber

const rowhouse: BrandData = {
  copy,
  settings,
  styles,
  styleClasses,
  dashboardCards,
  badges,
  metrics,
  summaryMetrics: [],
  logbookEnabled: true,
  includeTerms: false,
  eywAffiliateLinks: eywAffiliateLinks["rowhouse"],
  eywLegalLink: "https://www.therowhouse.com/terms-earn-your-watch",
  eywParticipatingLink: "https://www.therowhouse.com/earn-your-watch-locations"
}

export default rowhouse
