import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import UserProfile from "models/UserProfile"
import { jsToRuby } from "helpers/objectKeys"
import FormErrorMiddleware from "services/middleware/FormErrorMiddleware"
import ProfileStore from "apps/account/stores/ProfileStore"
import { serialize } from "helpers/serializationHelpers"

interface ProfileResponse {
  user_profile: UserProfile
  user: UserProfile
}

export default class ProfileUpdateStore extends APIStore {
  api = this.createClient([
    FormErrorMiddleware,
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware(this.profileStore.brandStore.isXponential ? "user" : "user_profile", UserProfile),
    TokenAuthMiddleware(this.profileStore.brandStore.userStore),
  ])

  constructor(public profileStore: ProfileStore) {
    super()
  }

  fetch() {
    if (this.profileStore.brandStore.isXponential) {
      return this.api.get(`/api/xpass/users`)
    }
    return this.api.get(`/api/user_profiles`)
  }

  update(profile: UserProfile) {
    const values = serialize(profile)
    delete values["email"]
    if(this.profileStore.brandStore.isXponential){
      delete values["need_shoes"]
      delete values["hide_metrics"]
      return this.api.put("/api/xpass/users", { user: values })
    }
    return this.api.put("/api/v2/user_profiles", { user_profile: values })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<ProfileResponse>) {
    this.profileStore.profile = res.data.user_profile
    this.profileStore.profile = this.profileStore.brandStore.isXponential ? res.data.user : res.data.user_profile
    return res
  }
}
