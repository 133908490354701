import * as React from "react"
import { observer, inject } from "mobx-react"

import BrandStore from "stores/BrandStore"

import APILoader from "components/wrappers/APILoader"
import { LIST_LENGTH } from "stores/LocationsStore"
import BaseStore from "stores/BaseStore"

interface Props {
  store?: BrandStore
  locationId?: string
  render(): React.ReactNode
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class AuthLocationsLoader extends React.Component<Props, {}> {
  public render() {
    return (
      <APILoader
        apiStore={this.props.store!.locationsStore}
        fetchProps={{ geoip: true, limit: LIST_LENGTH }}
        alreadyLoaded
        render={() => <InnerLoader {...this.props} />}
      />
    )
  }
}

@observer
class InnerLoader extends React.Component<Props, {}> {
  locStore = this.props.store!.locStore
  tmpStore = new BaseStore() // just to get the "dispose" behavior

  componentWillMount() {
    this.tmpStore.autorun(() => {
      this.props.store!.locStore.setCurrent({
        locationId: this.props.locationId,
        fromQuery: true,
      })
    })
  }

  componentWillUnmount() {
    this.tmpStore.dispose()
  }

  public render() {
    // load the current location if it's not in the list
    return (
      <APILoader
        apiStore={this.props.store!.locStore}
        alreadyLoaded
        render={this.props.render}
      />
    )
  }
}
