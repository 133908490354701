import { JsonObject, JsonProperty } from "json2typescript"
import { computed } from "mobx"
import markerPin from "images/markers/pin.png"
import aktPin from "images/xpass/markers/active-selected/akt.png"
import bftPin from "images/xpass/markers/active-selected/bft.png"
import clubpilatesPin from "images/xpass/markers/active-selected/clubpilates.png"
import cyclebarPin from "images/xpass/markers/active-selected/cyclebar.png"
import purebarrePin from "images/xpass/markers/active-selected/purebarre.png"
import rowhousePin from "images/xpass/markers/active-selected/rowhouse.png"
import rumblePin from "images/xpass/markers/active-selected/rumble.png"
import stretchlabPin from "images/xpass/markers/active-selected/stretchlab.png"
import stridePin from "images/xpass/markers/active-selected/stride.png"
import yogasixPin from "images/xpass/markers/active-selected/yogasix.png"
import kinrgyPin from "images/xpass/markers/active-selected/kinrgy.png"

@JsonObject("Details")
export class Details {
  @JsonProperty("brand_name", String)
  brandName: string = undefined!

  @JsonProperty("brand_slug", String)
  brandSlug: string = undefined!

  @JsonProperty("hero_image_url", String)
  heroImageUrl: string = undefined!

  @computed get mapPin() {
    const images = [
      { imageUrl: aktPin, brandId: "akt" },
      { imageUrl: bftPin, brandId: "bft" },
      { imageUrl: clubpilatesPin, brandId: "clubpilates" },
      { imageUrl: cyclebarPin, brandId: "cyclebar" },
      { imageUrl: purebarrePin, brandId: "purebarre" },
      { imageUrl: rowhousePin, brandId: "rowhouse" },
      { imageUrl: rumblePin, brandId: "rumble" },
      { imageUrl: stretchlabPin, brandId: "stretchlab" },
      { imageUrl: stridePin, brandId: "stride" },
      { imageUrl: yogasixPin, brandId: "yogasix" },
      { imageUrl: kinrgyPin, brandId: "kinrgy" },
      { imageUrl: markerPin, brandId: "xponential" },
      { imageUrl: markerPin, brandId: "default" },
    ]
    return images.find((img) => img.brandId === this.brandSlug)
  }
}

@JsonObject("Instructors")
export class Instructors {
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("headshot_url", String, true)
  headshotUrl?: string = undefined!

}

@JsonObject("Location")
export class Location {
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("city", String)
  city: string = undefined!

  @JsonProperty("state", String)
  state: string = undefined!

  @JsonProperty("zip", String)
  zip: string = undefined!

  @JsonProperty("clubready_id", String)
  clubreadyId: string = undefined!

  @JsonProperty("lat", Number)
  lat: number = undefined!

  @JsonProperty("lng", Number)
  lng: number = undefined!

  @JsonProperty("address", String, true)
  address?: string = undefined

  /**
   * Optional second line of street address.
   */
  @JsonProperty("address2", String, true)
  address2?: string = undefined

  @JsonProperty("phone", String)
  phone: string = undefined!

  @JsonProperty("timezone", String)
  timezone: string = undefined!

  @JsonProperty("instructors", [Instructors], true)
  instructors?: Instructors[] = []

  @JsonProperty("email", String, true)
  email?: string = undefined

  @JsonProperty("what_to_bring", String, true)
  whatToBring?: string = undefined

  @JsonProperty("parking_info", String, true)
  parkingInfo?: string = undefined

  get fullAddress() {
    return `${this.address}${this.address2 ? " " + this.address2 : ""}, ${
      this.city
    }, ${this.state} ${this.zip}`
  }

  get directionsUrl() {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      this.fullAddress
    )}`
  }

  // Set default false for non-XPASS booking flow
  get comingSoon() {
    return false
  }
}
