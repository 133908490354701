import APIStore from "stores/APIStore"
import LocationSummary from "models/LocationSummary"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ServiceType from "apps/book/models/ServiceType"
import BrandStore from "stores/BrandStore"
import { AxiosResponse } from "axios"
import { action, observable } from "mobx"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export interface ServiceTypesResponse {
  service_types: ServiceType[]
}

export default class ServiceTypesStore extends APIStore {
  @observable serviceTypes: ServiceType[] = []

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("service_types", ServiceType),
    TokenAuthMiddleware(this.brandStore.userStore)
  ])

  constructor(public brandStore: BrandStore, public locationSummary?: LocationSummary) {
    super()
  }

  fetch(locationId?: string) {
    const url = this.brandStore.isXponential
      ? `/api/xpass/v2/service_types?location_id=${locationId}`
      : `/api/locations/${this.locationSummary && this.locationSummary.id}/service_types`
    return this.api.get(url)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<ServiceTypesResponse>) {
    this.serviceTypes = res.data.service_types
    return res
  }
}
