import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

import { StatusMain, Summary, MembershipDetails } from "apps/classpoints/models/DashboardMain"

interface Response {
  banner: string
  status: StatusMain
  summary: Summary
  membershipDetails: MembershipDetails
}

export default class DashboardMainStore extends APIStore {
  @observable banner?: string
  @observable statusMain?: StatusMain
  @observable summary?: Summary
  @observable membershipDetails?: MembershipDetails

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("status", StatusMain),
    DeserializeMiddleware("summary", Summary),
    DeserializeMiddleware("membership_details", MembershipDetails),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(public brandStore: BrandStore) { super() }

  fetch() {
    return this.api.get(`/api/class_points/v2/dashboard/main`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response | any>) {
    this.banner = res.data.data.banner
    this.statusMain = res.data.status
    this.summary = res.data.summary
    this.membershipDetails = res.data.membership_details
    return res
  }
}
