import { JsonObject, JsonProperty } from "json2typescript"
import { Moment } from "moment"
import { computed, observable } from "mobx"
import DateConverter from "utils/DateConverter"
import ScheduleEntryBase from "models/ScheduleEntryBase"
import {
  formatTimeRangeLong,
  formatTime,
  formatDateLong,
} from "helpers/dateHelper"


@JsonObject("ScheduleEntryV1")
export default class ScheduleEntryV1 extends ScheduleEntryBase {
  /**
   * The number of open spots left.
   */
  @JsonProperty("free_spots", Number, true)
  freeSpots?: number = undefined!

  /**
   * The total capacity of the class.
   */
  @JsonProperty("total_spots", Number, true)
  totalSpots?: number = undefined!

  // ScheduleEntryBase.ts
  /**
   * Unique ID for the schedule entry.
   */
  @JsonProperty("id", String)
  id: string = undefined!
  /**
   * Timestamp of the start time for the class.
   */
  @JsonProperty("starts_at", DateConverter)
  startsAt: Moment = undefined!
  /**
   * Timestamp of the end time for the class.
   */
  @JsonProperty("ends_at", DateConverter)
  endsAt: Moment = undefined!

  @computed get dateString() {
    return this.startsAt.format("YYYY-MM-DD")
  }
  @computed get startsAtDate() {
    return formatDateLong(this.startsAt)
  }
  @computed get startTime() {
    return formatTime(this.startsAt)
  }
  @computed get endTime() {
    return formatTime(this.endsAt)
  }
  @computed get timeRange() {
    return formatTimeRangeLong(this.startsAt, this.endsAt)
  }
}
