import React, { useState, useEffect } from 'react'
import SwiperButton from '../../../book/components/xpass/SwiperButton'
import { Swiper, SwiperSlide } from 'swiper/react'
import DealCard from './DealCard'
import ClassToken from 'models/xpass/ClassToken'
import Deal from 'models/xpass/Deal'

interface Props {
  deals?: Deal[]
  classTokens?: ClassToken[]
  title: string
}

export default function DealList({deals, classTokens, title}: Props) {
const [viewAll, setViewAll] = useState(false)
const [showViewAll, setShowViewAll] = useState(true)

useEffect(() => {
  // determine if we need to show the ViewAll button
  const items = deals || classTokens;
  const breakpoints = [
    { width: 1309, maxItems: 5 },
    { width: 991, maxItems: 4 },
    { width: 767, maxItems: 3 },
    { width: 500, maxItems: 2 }
  ];

  const windowWidth = window.innerWidth;
  const matchingBreakpoint = breakpoints.find(breakpoint => windowWidth > breakpoint.width);

  setShowViewAll(!(matchingBreakpoint && items && items.length <= matchingBreakpoint.maxItems));
}, [deals, classTokens]);

return (
  <div className="deal-list class-list-carousel__container position-relative border-0">
      <div className="class-list-carousel__top-content d-flex justify-content-between">
          <h2>{title}</h2>
          {showViewAll && (
            <button className="deal-list__view-all-btn" onClick={() => setViewAll(prev => !prev)}>{viewAll ? "Collapse All" : "View All"}</button>
          )}
      </div>
      {viewAll ? (
        <div className="deal-list__view-all flex mt-3">
        {deals && deals.map((item) => <DealCard key={item.id} reward={item} />)}
        {classTokens && classTokens.map((item) => <DealCard key={item.id} reward={item} />)}
        </div>
      ) : (
        <Swiper
          slidesPerView="auto"
          spaceBetween={23}
          keyboard={{
          enabled: true
          }}
          className="mt-3"
        >
          <SwiperButton direction="prev" />
          <SwiperButton direction="next" />
            {deals && deals.map((item) => <SwiperSlide key={item.id}><DealCard reward={item} /></SwiperSlide>)}
            {classTokens && classTokens.map((item) => <SwiperSlide key={item.id}><DealCard reward={item} /></SwiperSlide>)}
        </Swiper>
      )}

  </div>
)
}