import {
  BrandData,
  baseSettings,
  baseStyles,
  baseStyleClasses,
  baseCopy,
  BrandCopy,
  BrandStyles,
  BrandStyleClasses,
  BadgeBitmap,
  eywAffiliateLinks,
} from "themes/base"

import badgeImage from "images/badges/bft-badge.png"
import logoUrl from "images/logos/bft-logo.svg"
import bannerImage from "images/dashboard/bft_banner_card.jpg"
import appleWatchIcon from "images/apple_health/watch/bft.svg"
import classpointsHero from "images/classpoints/classpoints-hero_bft.png"
import brandIcon from "images/classpoints/brands/BFT.svg"

import badge10Active from "images/badges/bft/milestone-10-active-bft.png"
import badge50Active from "images/badges/bft/milestone-50-active-bft.png"
import badge100Active from "images/badges/bft/milestone-100-active-bft.png"
import badge250Active from "images/badges/bft/milestone-250-active-bft.png"
import badge500Active from "images/badges/bft/milestone-500-active-bft.png"
import badge750Active from "images/badges/bft/milestone-750-active-bft.png"

import badge10Inactive from "images/badges/bft/milestone-10-inactive-bft.png"
import badge50Inactive from "images/badges/bft/milestone-50-inactive-bft.png"
import badge100Inactive from "images/badges/bft/milestone-100-inactive-bft.png"
import badge250Inactive from "images/badges/bft/milestone-250-inactive-bft.png"
import badge500Inactive from "images/badges/bft/milestone-500-inactive-bft.png"
import badge750Inactive from "images/badges/bft/milestone-750-inactive-bft.png"

const copy: BrandCopy = {
  ...baseCopy,
  seat: "Class",
  bookClass: "Reserve",
  classPackages: "Our memberships offer a better value than class packs. Call your local studio to find out more about our membership options and specials."
}

const styles: BrandStyles = {
  ...baseStyles,
  badgeImage,
  logoUrl,
  bannerImage,
  appleWatchIcon,
  classpointsHero,
  brandIcon
}

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
}

const badges: BadgeBitmap[] = [
  {
    label: "10 Classes",
    value: 10,
    active: badge10Active,
    inactive: badge10Inactive,
  },
  {
    label: "50 Classes",
    value: 50,
    active: badge50Active,
    inactive: badge50Inactive,
  },
  {
    label: "100 Classes",
    value: 100,
    active: badge100Active,
    inactive: badge100Inactive,
  },
  {
    label: "250 Classes",
    value: 250,
    active: badge250Active,
    inactive: badge250Inactive,
  },
  {
    label: "500 Classes",
    value: 500,
    active: badge500Active,
    inactive: badge500Inactive,
  },
  {
    label: "750 Classes",
    value: 750,
    active: badge750Active,
    inactive: badge750Inactive,
  },
]

const bft: BrandData = {
  settings: {
    ...baseSettings,
    badgeBitmapHeight: 65,
    badgeBitmapWidth: 48,
  },
  styles,
  styleClasses,
  copy,
  dashboardCards: [
    "NextClassCard",
    "TodaysClassCard",
    "AchievementsCard",
    "AttendanceCard",
    "BadgeCard",
    "ClassPointsCard",
    "ProfileCard",
    "MobileAppCard",
    "MobileOfferCard",
    "PromoCards",
  ],
  badges,
  metrics: [],
  summaryMetrics: [],
  eywAffiliateLinks: eywAffiliateLinks["bft"],
  eywLegalLink: "https://www.bodyfittraining.com/terms-earn-your-watch",
  eywParticipatingLink: "https://www.bodyfittraining.com/earn-your-watch-locations"
}

export default bft
