import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("ClassToken")
export default class ClassToken {

  @JsonProperty("id", Number)
  id: number = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("short_description", String)
  shortDescription: string = undefined!

  @JsonProperty("thumbnail_image", String)
  thumbnailImage: string = undefined!

  @JsonProperty("banner_image", String)
  bannerImage: string = undefined!

  @JsonProperty("expires_on", String)
  expiresOn?: string = undefined!

  @JsonProperty("earned_on", String)
  earnedOn?: string = undefined!

  @JsonProperty("purchased_on", String)
  purchasedOn?: string = undefined!

  @JsonProperty("first_timer_only", Boolean, true)
  firstTimerOnly?: boolean = false

  @JsonProperty("booking_window_days", Number, true)
  bookingWindowDays?: number = undefined!

  @JsonProperty("brands", [String], true)
  brands?: string[] = []

}
