import * as React from "react"
import { observable, action, computed, runInAction } from "mobx"
import getIn from "lodash/get"

import BrandStore from "stores/BrandStore"
import BaseStore from "stores/BaseStore"
import BookingStore from "apps/book/stores/BookingStore"
import BookingCreationStore from "apps/book/stores/BookingCreationStore"
import ScheduleEntry from "models/ScheduleEntry"
import { isCancelledPromise } from "helpers/errorHandling"
import { LoadStatus } from "utils/LoadStatus"
import { reasonCodeToUserMessage } from "helpers/bookingStatusMessages"
import nl2br from "helpers/nl2br"
import ScheduleEntryStore from "apps/xpass/stores/ScheduleEntryStore"
import cloneDeep from 'lodash/cloneDeep';

interface ApiErrorResponse {
  data: { code: string; message: string }
  status: number
}

interface ErrorModalProps {
  title?: string
  message?: string
  needsPackage?: boolean
  redirectLink?: string
  ctaCopy?: string
}

// API Calls and error messaging for the booking flow
export default class BookingFlowStore extends BaseStore {
  @observable status: LoadStatus = "idle"
  @observable needsPackage: boolean = false
  @observable scheduleEntry?: ScheduleEntry
  @observable error?: any = undefined
  @observable isWaitlist?: boolean = false
  @observable hasPointsMismatch?: boolean = false
  @observable newPremiumCost?: number = 0

  locationSummary = this.brandStore.locStore.currentLocation!
  bookingStore = new BookingStore(this.locationSummary, this.brandStore)
  bookingCreationStore = new BookingCreationStore(this.brandStore)
  isXponential = this.brandStore.isXponential

  @computed get bookingStatus() {
    // TODO: I think this guy potentially returns a booking status on 400s
    return this.bookingStore.bookingStatus
  }

  @computed get booking() {
    return this.bookingCreationStore.booking || this.bookingStore.booking
  }

  @computed get room() {
    return this.bookingStore.room
  }

  constructor(public brandStore: BrandStore) {
    super()
  }

  @action.bound
  setError(error: any) {
    this.error = error
  }

  @action.bound
  checkScheduleEntry(entry: ScheduleEntry) {
    this.scheduleEntry = entry
    entry.isBusy = true
    return this.fetch(entry.id)
      .then(r => {
        entry.isBusy = false
        return r
      })
      .catch(ex => {
        entry.isBusy = false
        throw ex
      })
  }

  @action.bound
  async fetch(id: string) {
    this.status = "loading"

    const bookingCheckSuccess = (res: any) => {
      this.scheduleEntry = this.bookingStore.scheduleEntry
      this.handleBookingCheckSuccess()
      return res
    }

    const bookingCheckError = (ex: any) => {
      if (isCancelledPromise(ex)) {
        this.status = "error"
      } else {
        this.handleBookingCheckSuccess(true)
      }
      throw ex
    }

    if (this.isXponential) {
      const scheduleEntryStore = new ScheduleEntryStore(this.brandStore.userStore)
      return await scheduleEntryStore.fetch(id).then(async () => {
        const location = scheduleEntryStore!.scheduleEntry!.location.id
        return await this.bookingStore
          .fetch({ id, locationId: location })
          .then(res => bookingCheckSuccess(res))
          .catch(ex => bookingCheckError(ex))
      })
    }
    return this.bookingStore
      .fetch({ id })
      .then(res => bookingCheckSuccess(res))
      .catch(ex => bookingCheckError(ex))
  }

  @action.bound
  book(
    id: string,
    seatId?: string,
    waitlist?: boolean,
    xpassLocationId?: string,
    userPremiumCost?: number | null
  ) {
    this.status = "loading"
    this.bookingCreationStore.showUi = false
    // If `waitlist` is passed use it
    // Else use the store's `isWaitlist`
    // (store's `isWaitlist` is true for intentional wait-listing)
    // This way, unintentional wait-listing due to book and confirm flow
    // is prevented.
    const isWaitlist = !this.isXponential && (waitlist || this.isWaitlist)

    return this.bookingCreationStore
      .book(id, seatId, isWaitlist, xpassLocationId, userPremiumCost)
      .then(res => {
        runInAction(() => {
          this.scheduleEntry = this.bookingStore.scheduleEntry
          this.status = "loaded"
        })
        this.brandStore.track.event(
          isWaitlist ? "waitlist_success" : "booking_success",
          {
            loc: this.locationSummary,
            booking: this.booking,
          }
        )
        return res
      })
      .catch(ex => {
        if (this.isXponential && getIn(ex, "data.code") === "points_mismatch") {
          this.status = "loaded"
          this.hasPointsMismatch = true
          this.newPremiumCost = getIn(ex, "data.user_premium_cost")
        } else if (isCancelledPromise(ex)) {
          this.status = "error"
        } else if (getIn(ex, "data.code") && ex.status === 400) {
          this.handleApiErrorResponse(ex)
        } else {
          // TODO: should combine these stores I guess
          if (this.bookingCreationStore.bookingStatus) {
            this.bookingStore.bookingStatus = this.bookingCreationStore.bookingStatus
          }
          if (this.bookingCreationStore.booking) {
            this.bookingStore.booking = this.bookingCreationStore.booking
          }
          this.handleBookingCheckSuccess(true)
        }
        const bookingStatus = cloneDeep(this.bookingStatus);
        if(!this.isXponential && bookingStatus!.scheduleEntry.hasOwnProperty("userPremiumCost")){
          delete bookingStatus!.scheduleEntry["userPremiumCost"]
        }
        this.brandStore.track.event(
          isWaitlist ? "waitlist_failure" : "booking_failure",
          {
            loc: this.locationSummary,
            bookingStatus,
          }
        )

        throw ex
      })
  }

  @action.bound
  handleApiErrorResponse(ex: ApiErrorResponse) {
    const { copy, uiStore } = this.brandStore
    const errorProps = reasonCodeToUserMessage(
      getIn(ex, "data.code"),
      copy.class,
      copy,
      getIn(ex, "data.message")
    )
    uiStore.openModal({
      ...errorProps,
      type: "error",
      modalShow: true,
    })
    const { title, message: messageString, redirectLink = "", ctaCopy = "" } = errorProps
    const message = nl2br(messageString)

    if (redirectLink) {
      uiStore.openModal({
        title,
        children: (
          <div key={1}>
            <p className="text-center">{message}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  uiStore.closeModal()
                  this.brandStore.routingStore.history.push(redirectLink)
                }}
              >
                {ctaCopy || "OK"}
              </button>
            </div>
          </div>
        ),
        modalShow: true,
      })
    }
    this.status = "error"
    this.setError(errorProps)
  }

  @action.bound
  handleBookingCheckSuccess(isError = false) {
    const { uiStore } = this.brandStore
    const { bookingStatus, booking } = this.bookingStore

    if (bookingStatus && bookingStatus.canBook && !isError) {
      const {
        scheduleEntry: { isFull, canWaitlist },
      } = bookingStatus
      // Set the store `isWaitlist` to true if scheduleEntry is full
      // and can be wait-listed
      if (isFull && canWaitlist) {
        this.isWaitlist = true
      }
      this.status = "loaded"
    } else if (booking && !this.errorModalProps.needsPackage) {
      const { title, message: messageString } = this.errorModalProps
      const message = nl2br(messageString)
      uiStore.openModal({
        type: "error",
        title,
        message,
        modalShow: true,
      })
      this.status = "loaded"
      this.setError({ title, message })
    } else {
      if (this.errorModalProps.needsPackage) {
        this.needsPackage = this.errorModalProps.needsPackage
        this.status = "loaded"
      } else {
        const {
          title,
          message: messageString,
          redirectLink,
          ctaCopy,
        } = this.errorModalProps
        const message = nl2br(messageString)

        if (redirectLink) {
          uiStore.openModal({
            title,
            children: (
              <div key={1}>
                <p className="text-center">{message}</p>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      uiStore.closeModal()
                      this.brandStore.routingStore.history.push(redirectLink)
                    }}
                  >
                    {ctaCopy || "OK"}
                  </button>
                </div>
              </div>
            ),
            modalShow: true,
          })
        } else {
          uiStore.openModal({
            type: "error",
            title,
            message,
            modalShow: true,
          })
        }

        this.status = "error"
        this.setError({ title, message })
      }
    }
  }

  @action.bound
  updatePremiumCostFromMismatch(): number | undefined {
    this.hasPointsMismatch = false
    return this.newPremiumCost
  }

  @computed
  private get errorModalProps(): ErrorModalProps {
    // If we want to force the booking anyway...
    // this.api.post(`/api/schedule_entries/${this.scheduleEntryId}/bookings`)
    const { booking, bookingStatus } = this.bookingStore
    const { copy } = this.brandStore

    if (!bookingStatus && booking) {
      const verb = booking.status === "waitlisted" ? "waitlisted" : "booked"
      return {
        title: `Already ${verb}`,
        message: `You are already ${verb} into this ${copy.class}.`,
      }
    } else if (!bookingStatus) {
      return {
        title: "Something went wrong",
        message: `We were unable to book you into this class.`,
      }
    }

    let code = this.scheduleEntry!.bookingStatusCode || bookingStatus.code
    if (code === "credits") {
      return { needsPackage: true }
    } else {
      return reasonCodeToUserMessage(code, copy.class, copy, bookingStatus!.message)
    }
  }

  dispose() {
    this.bookingStore.dispose()
    super.dispose()
  }
}
