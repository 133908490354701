import * as React from "react"
import cx from "classnames"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import { Link, RouteComponentProps } from "react-router-dom"
import { formatPhone } from "helpers/stringHelper"
import LocationAddressLink from "components/LocationAddressLink"

interface Props {
  className?: string
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ProfileCard extends React.Component<Props> {
  handleHeaderClick = (_: LinkEvent) => {
    this.props.store!.track.event("home_tap view my profile")
  }

  render() {
    // some other ideas:
    // - next expiring credits
    // - total credits
    // - member since
    const session = this.props.store!.userStore.session!
    const locationSummary = session.homeLocation
    const rowClass = "d-flex align-items-start"

    return (
      <div className={cx("card", "profile-card", this.props.className)}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="text-dark">My Profile Information</h5>
          <small>
            <Link to="/account/profile" onClick={this.handleHeaderClick}>
              Edit
            </Link>
          </small>
        </div>

        <div className="card-body d-flex flex-column justify-content-around">
          <div className={cx(rowClass, "float-in", "delay-3")}>
            <div className="col-6 p-0 pr-3">
              <h5>Home Studio</h5>
              <span className="small">
                {this.props.store!.brand.name} {locationSummary.name}
              </span>

              <br />
              {locationSummary.phone && (
                <a href={`tel:${locationSummary.phone}`} className="small">
                  {formatPhone(locationSummary.phone)}
                </a>
              )}
            </div>
            <div className="col-6 p-0">
              <h5>Studio Address</h5>
              <LocationAddressLink locationSummary={locationSummary} />
            </div>
          </div>

          <div className="border-top float-in delay-4 my-2" />

          <div className={cx(rowClass, "float-in", "delay-5")}>
            <div className="col p-0">
              <h5>Email Address</h5>
              <EditCtaField val={session.email}>
                {val => <p className="small">{val}</p>}
              </EditCtaField>
            </div>
          </div>

          {/* unused cards */}

          {/* <div className="col-4 p-0">
                    <h5>Height:</h5>
                    {profile.height ? (
                      `${Math.floor(profile.height / 12)}" ${profile.height -
                        Math.floor(profile.height / 12)}'`
                    ) : (
                      <Link to="account/profile">
                        Edit
                      </Link>
                    )}
                  </div> */}
          {/* <div className="col-4 p-0">
                    <h5>Weight:</h5>
                    {profile.weight ? (
                      `${profile.weight}lbs`
                    ) : (
                      <Link to="account/profile">
                        Edit
                      </Link>
                    )}
                  </div> */}
          {/* <div className="col-6 p-0">
                    <h5>Phone:</h5>
                    <EditCtaField val={profile.phoneMain}>
                      {phone =>
                        `${phone.substr(0, 3)}-${phone.substr(
                          3,
                          3
                        )}-${phone.substr(6, 4)}`
                      }
                    </EditCtaField>
                  </div> */}
        </div>
      </div>
    )
  }
}

interface EditCtaFieldProps<T extends React.ReactNode> {
  children?: (el: T) => React.ReactNode
  val: T | undefined
}

const EditCtaField = <T extends React.ReactNode>({
  val,
  children,
}: EditCtaFieldProps<T>) => (
  <>
    {val != null && val !== "" ? (
      children ? (
        children(val)
      ) : (
        <small>{val}</small>
      )
    ) : (
      <Link to="account/profile">Set It</Link>
    )}
  </>
)
