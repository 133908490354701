import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action, computed } from "mobx"
import getIn from "lodash/get"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import BrandStore from "stores/BrandStore"
import Purchase from "apps/account/models/Purchase"
import Package from "models/Package"
import Offer from "models/Offer"

interface PurchasesResponse {
  purchases: Purchase[]
  total_credits?: number
  cancel_incentive_offer?: Offer
}

export default class PurchasesStore extends APIStore {
  @observable purchases: Purchase[] = []
  @observable totalCredits: number = 0
  @observable cancelIncentiveOffer: Offer = new Offer
  isXponential = this.brandStore.isXponential

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("purchases", Purchase),
    DeserializeMiddleware("cancel_incentive_offer", Offer, true),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  @computed
  get nonExpiredPurchases() {
    return this.purchases.filter(p => !p.seemsExpired)
  }

  /**
   * VOD
   */
  @computed
  get hasRestrictedVideoPackage() {
    return this.nonExpiredPurchases.some(p => p.package.restrictedVideo)
  }

  /**
   * XPASS
   */
  @computed
  get xpassNonPromoPurchases() {
    return this.purchases.filter(p => !p.package.isXpassFree)
  }

  @computed
  get xpassNonExpiredPurchases() {
    return this.xpassNonPromoPurchases.filter(p => !p.seemsExpired)
  }

  @computed
  get xpassMembershipPurchases() {
    return this.xpassNonPromoPurchases.filter(p => p.package.isMembership || p.package.isRecurring)
  }

  @computed
  get xpassAddOnPurchases() {
    return this.xpassNonPromoPurchases.filter(p => !p.package.isMembership && !p.seemsExpired);
  }

  cancelledPurchase(purchase: Purchase) {
    // isCancelled comes from the backend and returns the result of: `cancelled_at.present? || scheduled_cancel_date_utc.present?`
    // `!!purchase.scheduledCancelDateUtc` maybe not necessary since duplicates BE logic ¯\_(ツ)_/¯
    return purchase.seemsExpired || purchase.isCancelled || !!purchase.scheduledCancelDateUtc
  }

  @computed
  get xpassActiveRecurringPurchases() {
    return this.xpassMembershipPurchases.filter(p => !this.cancelledPurchase(p))
  }

  @computed
  get xpassInactiveRecurringPurchases() {
    return this.xpassMembershipPurchases.filter(p => this.cancelledPurchase(p))
  }

  protected get baseUrl() {
    const loc = this.brandStore.locStore.currentLocation!
    return this.isXponential
      ? "/api/xpass/v2/purchases"
      : `/api/v2/locations/${loc.id}/purchases`
  }

  constructor(protected brandStore: BrandStore) {
    super()
  }

  fetch() {
    return this.api.get(this.baseUrl)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<PurchasesResponse>) {
    this.purchases = res.data.purchases
    this.totalCredits = res.data.total_credits || 0
    this.cancelIncentiveOffer = res.data.cancel_incentive_offer!
    return res
  }

  @action
  getPurchase(pkg: Package) {
    return this.purchases.find(p => p.id == pkg.purchaseId)
  }

  @action.bound
  cancelPurchase(purchase: Purchase) {
    const loc = this.brandStore.locStore.currentLocation!
    const url = this.isXponential
      ? "/api/xpass/purchases"
      : `/api/locations/${loc.id}/purchases` // Delete doesn't use v2
    return this.api.delete(`${url}/${purchase.id}`)
  }
}
