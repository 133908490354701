import React, { Component } from "react"
import { RouteComponentProps } from "react-router"
import { observer, inject } from "mobx-react"
import BrandStore from "stores/BrandStore"
import TokensStore from "apps/xpass/stores/TokensStore"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import moment from "moment"
import { Link } from "react-router-dom"
import ClassToken from "models/xpass/ClassToken"
import classTokenIcon from "images/xpass/icons/xpass-token-icon.png"
import APILoader from "components/wrappers/APILoader"

export interface MyTokensPageProps extends RouteComponentProps {
  store?: BrandStore
}

export interface State {
  showOnlyActiveTokens: boolean
  tokensData: any
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class MyTokensPage extends Component<MyTokensPageProps, State, {}> {
  tokensStore = new TokensStore(this.props.store!)
  balancesStore = new BalancesStore(this.props.store!)

  constructor(props: MyTokensPageProps) {
    super(props)
    this.state = {
      showOnlyActiveTokens: true,
      tokensData: this.tokensStore.classTokens
    }
  }

  componentDidMount() {
    this.tokensStore.fetch(true)
    this.balancesStore.fetch()
  }

  componentDidUpdate(prevProps: Readonly<MyTokensPageProps>, prevState: Readonly<State>, snapshot?: {} | undefined): void {
    if (this.tokensStore.classTokens !== prevState.tokensData) {
      this.setState({ tokensData: this.tokensStore.classTokens })
    }
  }

  componentWillUnmount() {
    this.tokensStore.dispose()
    this.balancesStore.dispose()
  }

  handleTokensFilter = (e: any) => {
    this.setState({ showOnlyActiveTokens: !this.state.showOnlyActiveTokens })
    this.tokensStore.fetch(!this.state.showOnlyActiveTokens)
  }

  render() {

    const isLoading = this.tokensStore.isLoading
    const currentDate = moment();

    return (
      <div className="col">
        <h1 className="mt-3 mb-3 slide-in">My Tokens</h1>
        <div className="mt-3 mb-2 mb-md-4 d-flex justify-content-between align-items-center">
          <h2>Status</h2>
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" checked={this.state.showOnlyActiveTokens} id="activeTokenFilter" onChange={this.handleTokensFilter} />
            <label className="custom-control-label text-black" htmlFor="activeTokenFilter">Show only Active</label>
          </div>
        </div>
        <h3>Earned Tokens</h3>
        <APILoader
          apiStore={this.tokensStore}
          elementSize="element"
          render={() => {
            return (
            <div className="tokens-list">
              {this.state.tokensData.map((item: ClassToken) => {
                return (
                  <div className="d-flex justify-content-between py-3 token-container" key={item.id}>
                    <div className="d-flex">
                      {item.thumbnailImage && <img className="mr-2 mr-md-3 token-brand-icon" src={item.thumbnailImage} alt="logo" />}
                      <div className="d-flex flex-column">
                        <Link
                          to={{
                            pathname: `/rewards/tokens/${item.id}`,
                            state: { context: "tokenDetails" }
                          }}
                          className="text-decoration-none text-black font-weight-600 token-brand-name"
                        >
                          {item.name}
                        </Link>
                        <span className="token-additional-details">Earned on {moment(item.earnedOn).format('MM/DD/YY')}</span>
                      </div>
                    </div>
                    {item.expiresOn ?
                      moment(item.expiresOn).isAfter(currentDate) ?
                        <span className="text-muted-dark d-flex token-additional-details">Expires on {moment(item.expiresOn).format('MM/DD/YYYY')}</span> :
                        <span className="text-primary d-flex token-additional-details">Expired on {moment(item.expiresOn).format('MM/DD/YYYY')}</span> : null
                    }
                  </div>
                )
              })}
            </div>
            )}}
          />

        {(!isLoading && this.state.tokensData.length === 0) &&
          <div className="d-flex flex-column align-items-center mt-5">
            <h2>You have no Earned Tokens</h2>
            <h3 className="mt-1 mb-5">Click the button below to Buy Tokens</h3>
            <Link
              to={{
                pathname: `/buy/xponential-xpass`,
                state: { context: "addTokens" }
              }}
              className="btn btn-primary"
            >
              Buy Tokens
            </Link>
          </div>
        }
        <APILoader
          apiStore={this.balancesStore}
          elementSize="element"
          render={() => {
            const tokensCount = this.balancesStore.balances!.purchasedTokenCount
            const tokensCopy = tokensCount === 1 ? "Token" : "Tokens"
            return (
              <div className="mt-3">
                <h3>Purchased Tokens</h3>
                <div className="d-flex justify-content-start py-3">
                  <div className="d-flex align-items-center">
                    <img className="mr-2 mr-md-3 token-brand-icon" src={classTokenIcon} alt="logo" />
                    <div>
                    <Link
                          to={{
                            pathname: `/rewards/tokens/purchased`,
                            state: { context: "purchasedTokensDetails" }
                          }}
                          className="text-decoration-none text-black font-weight-600 token-brand-name"
                        >
                      <p className="token-brand-name m-0">{tokensCount} XPASS {tokensCopy}</p>
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
          )}}
        />

      </div>
    )
  }
}