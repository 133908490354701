import * as React from "react"
import cx from "classnames"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import Svg from "components/Svg"
import { TrackingEvent } from "models/TrackingSchemas"
import iosIcon from "images/icons/ios-icon.svg"
import androidIcon from "images/icons/android-icon.svg"

export interface BannerCardLink {
  label: string
  track?: TrackingEvent
  url?: string
  target?: string
  icon?: string
}

interface BannerCardEvent {
  track?: TrackingEvent
  url?: string
}

interface Props {
  store?: BrandStore
  className?: string
  title: string
  largeTitle?: string
  subtitle: string
  description?: string
  overlay?: boolean
  // global URL, makes whole card clicky
  url?: string
  // global tracking event
  track?: TrackingEvent
  // individual links
  links?: BannerCardLink[]
  hasPlayButton?: boolean
  bannerImage: string
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class BannerCard extends React.Component<Props> {
  handleLinkClick = ({ track, url }: BannerCardEvent) => (_: LinkEvent) => {
    if (!track) return

    const store = this.props.store!
    store.track.event(
      track,
      {
        loc: store.locStore.currentLocation,
      },
      { linkUrl: url }
    )
  }

  render() {
    const xpassHeader = {fontSize: "24px", lineHeight: "28.8px"}
    const isXponential = this.props.store!.isXponential
    const links = this.props.links
      ? this.props.links.filter(({ url }) => url)
      : []

    const inner = (
      <>
        <div
          id={`${isXponential ? 'is-xponential-banner-card' : ''}`}
          className={cx(
            "card-body",
            "d-flex",
            "flex-column",
            "justify-content-center",
            "align-items-center",
          )}
          style={{ zIndex: 1 }}
        >
          <div className={cx("text-center", !isXponential && "text-white")}>
            {this.props.largeTitle
              ? <h2 className={cx("d-block", "mb-3", "text-white")}><strong>{this.props.largeTitle}</strong></h2>
              : <small className={cx("d-block", !isXponential && "mb-3")}>{isXponential ? "XPASS on the go" : this.props.title}</small>
            }
            {isXponential?
              <h2 style={xpassHeader}>Download the <br/> Mobile App</h2>
            :
              <>
              {this.props.description
               ? <h4 className="text-white">{this.props.description}</h4>
               : <h2 className="text-white">{this.props.subtitle}</h2>
              }
              </>
            }
            {!!links && (
              <div className={cx("mt-4", isXponential ? "row d-flex pt-2 justify-content-center" : "d-block banner-card-links")}>
                {links
                  .filter(l => l.url && l.label)
                  .map((link, i) => (
                    <a
                      href={link.url}
                      key={i}
                      target={link.target || '_blank'}
                      className={cx(isXponential ? "btn btn-dark" : "text-white d-block")}
                      onClick={this.handleLinkClick(link)}
                    >
                      {isXponential && link.icon && (
                        <img className="position-relative" src={link.icon} alt={link.label} />
                      )}
                      <span>{link.label}</span>
                    </a>
                  ))}
              </div>
            )}
          </div>

          <div
            className="btn btn-link align-self-end"
            style={{ position: "absolute", bottom: "1.7rem" }}
          >
            {this.props.hasPlayButton && <Svg name="play" size="20" />}
          </div>
        </div>
      </>
    )

    const background = `url(${this.props.bannerImage}) center / cover`
    const { overlay = "overlay" } = this.props
    const wrapperClass = cx(
      "card",
      overlay,
      "banner-card",
      this.props.className
    )
    const xpassWrapperClass = cx(
      "card",
      "banner-card",
      this.props.className
    )

   if (this.props.url && !links) {
      return (
        <a
          className={cx( isXponential ? xpassWrapperClass : wrapperClass, "text-decoration-none")}
          href={this.props.url}
          target="_blank"
          onClick={this.handleLinkClick(this.props)}
          style={{
            background,
          }}
        >
          {inner}
        </a>
      )
    } else {
      return (
        <div
          className={cx( isXponential ? xpassWrapperClass : wrapperClass, "text-decoration-none")}
          style={{
            background,
          }}
        >
          {inner}
        </div>
      )
    }
  }
}

type MobileAppCardProps = { className?: string; store?: BrandStore }
const MobileAppCard: React.FunctionComponent<MobileAppCardProps> = inject(
  (store: BrandStore) => ({ store })
)(
  observer(({ className, store }) => {
    const [title, subtitle] =
      store!.brand.iosAppUrl || store!.brand.androidAppUrl
        ? [store!.copy.mobileAppAvailable, "Available Now"]
        : ["New iOS and Android Apps", "Coming Soon"]
    const labelIos = store!.isXponential ? "iOS" : "Download for iOS"
    const labelAndroid = store!.isXponential ? "Android" : "Download for Android"
    // temp fix change back once android id ready
    const links =  store!.isXponential ?
      [
        {
          label: labelIos,
          url: store!.brand.iosAppUrl,
          track: "home_tap download ios" as const,
          icon: iosIcon
        }
      ]
    :
      [
        {
          label: labelIos,
          url: store!.brand.iosAppUrl,
          track: "home_tap download ios" as const,
        },
        {
          label: labelAndroid,
          url: store!.brand.androidAppUrl,
          track: "home_tap download android" as const,
        },
      ]
    return (
      <BannerCard
        className={className}
        title={title}
        subtitle={subtitle}
        bannerImage={store!.styles.bannerImage}
        links={links}
      />
    )
  })
)

export { MobileAppCard }
