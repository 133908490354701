import { Middleware } from "services/Middleware"
import {
  Deserializable,
  deserializeArray,
  deserialize,
} from "helpers/serializationHelpers"

// Replaces the entire 'response.data' with a deserialized representation
export default function DeserializeRootMiddleware<T>(
  model: Deserializable<T>,
  optional: boolean = false
) {
  const middleware: Middleware = (_, next) => {
    return next().then(res => {
      // No specific key needed, as we're working with the root object
      const responseData = res.data;

      if (!responseData && res.status === 204) {
        return res;
      }

      // allows for the entire object to be optional
      if (responseData == null && optional) return res;

      // this runtime check is only needed for the compile-time type signature :/
      // Deserialize the entire response data
      res.data = Array.isArray(responseData)
        ? deserializeArray(responseData, model)
        : deserialize(responseData, model);
      return res;
    })
  }
  return middleware;
}
