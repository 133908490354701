import * as React from "react"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import FavoritesStore from "apps/account/stores/FavoritesStore"
import GenericSummary from "models/GenericSummary"
import LocationSummary from "models/LocationSummary"
import Heading from "components/Heading"
import { RouteComponentProps } from "react-router"
import PageTracker from "components/wrappers/PageTracker"

export interface Props extends RouteComponentProps {
  store?: BrandStore
}

// Not sure we actually need this page, but helpful to have around for now
//
@inject((store: BrandStore) => ({ store }))
@observer
export default class FavoritesPage extends React.Component<Props, {}> {
  classTypesStore = this.props.store!.userStore.favoriteClassCategoriesStore
  instructorsStore = this.props.store!.userStore.favoriteInstructorsStore
  locationsStore = this.props.store!.userStore.favoriteLocationsStore

  componentDidMount() {
    this.props.store!.userStore.fetch()
    this.props.store!.classTypesStore.fetch()
    this.props.store!.locationsStore.fetch()
  }

  render() {
    const allClassTypes = this.props.store!.classTypesStore.classTypes
    // TODO: use the fancy locations thing
    const allLocations = this.props.store!.locationsStore.locations

    // TODO: instructors store needs to work by location or something
    return (
      <div>
        <Heading center>Favorites</Heading>
        <PageTracker name="account > favorites" />
        <div className="row">
          <FavoritesTable
            title="Locations"
            store={this.locationsStore}
            allItems={allLocations}
          />
          <FavoritesTable title="Instructors" store={this.instructorsStore} />
          <FavoritesTable
            title="Class Types"
            allItems={allClassTypes}
            store={this.classTypesStore}
          />
        </div>
      </div>
    )
  }
}

interface FavoritesTableProps {
  title: React.ReactNode
  allItems?: GenericSummary[]
  store: FavoritesStore<typeof GenericSummary>
}

const FavoritesTable = observer(
  ({ title, allItems, store }: FavoritesTableProps) => (
    <div className="col-lg-4">
      <h2>{title}</h2>
      <table className="table">
        <tbody>
          {store.favorites.map(favorite => (
            <tr key={favorite.id}>
              <td className="align-middle">{favorite.name}</td>
              <td className="align-middle text-right">
                <button
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => store.remove(favorite.id!)}
                  disabled={store.status === "loading"}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {allItems && (
        <div className="input-group">
          <select
            className="form-control"
            disabled={store.status === "loading"}
            value={store.selectedItemId}
            onChange={e => store.setSelectedItemId(e.currentTarget.value)}
          >
            {allItems.map(item => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <div className="input-group-append">
            <button
              className="btn btn-primary btn-sm"
              type="button"
              disabled={store.status === "loading" && !store.selectedItemId}
              onClick={() => store.add(store.selectedItemId!)}
            >
              Add Favorite
            </button>
          </div>
        </div>
      )}
    </div>
  )
)
