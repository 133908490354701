import * as React from "react"
import { observer, inject } from "mobx-react"
import LocationSummary from "models/LocationSummary"
import NotHomeWarning from "components/NotHomeWarning"
import BrandStore from "stores/BrandStore"

interface Props {
  locationSummary: LocationSummary
  showName?: boolean
  store?: BrandStore
}

// parameterize styling and classes as needed
const HeaderAddressPanel: React.FunctionComponent<Props> = ({
  locationSummary,
  showName,
  store,
}) => {
  return (
    <div className="row slide-in delay-2">
      <div className="col mb-4">
        {showName && (
          <strong>
            {store!.brand.name} {locationSummary.name}
          </strong>
        )}
        <address className="mb-1">
          {locationSummary.address} {locationSummary.address2}
          <br />
          {locationSummary.city}, {locationSummary.state} {locationSummary.zip}
        </address>
        <div className="small">
          <NotHomeWarning />
        </div>
      </div>
    </div>
  )
}

export default inject((store: BrandStore) => ({ store }))(
  observer(HeaderAddressPanel)
)
