import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("Reward")
export default class Reward {
  @JsonProperty("id", Number)
  id: number = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("short_description", String)
  shortDescription: string = undefined!

  @JsonProperty("description", String, true)
  description?: string = undefined!

  @JsonProperty("thumbnail_image_url", String)
  thumbnailImageUrl: string = undefined!

  @JsonProperty("earned", Boolean)
  earned?: boolean = false

  @JsonProperty("earned_at_date", String)
  earnedAtDate?: string = undefined!

  @JsonProperty("earn_by_date", String)
  earnByDate?: string = undefined!

  @JsonProperty("event_criteria_name", String, true)
  eventCriteriaName?: string = undefined!

  @JsonProperty("type", String, true)
  type?: string = undefined!

  @JsonProperty("reward_type", String, true)
  rewardType?: string = undefined!

  @JsonProperty("rewards_text", String, true)
  rewardsText?: string = undefined!

  @JsonProperty("redeemable_count", Number, true)
  redeemableCount?: number = undefined!

  @JsonProperty("is_limited_use", Boolean, true)
  isLimitedUse?: boolean = false

  @JsonProperty("is_redemption_tracked", Boolean, true)
  isRedemptionTracked?: boolean = false

  @JsonProperty("usage_limit", Number, true)
  usageLimit?: number = undefined!

  @JsonProperty("expired", Boolean, true)
  expired?: boolean = false

  @JsonProperty("class_points_user_rewards_id", Number, true)
  classPointsUserRewardsId: number = undefined!
}
