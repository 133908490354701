import React from "react"
import { observable } from "mobx"
import { observer } from "mobx-react"
import * as Sentry from "@sentry/browser"

import { LoadStatus } from "utils/LoadStatus"
import Spinner from "components/Spinner"
import ErrorMessage from "components/ErrorMessage"

type LoadedComponent<T> = React.ComponentType<T>

interface Props<T> {
  moduleProps: T
  loadModule(): Promise<{ default: LoadedComponent<T> }>
}

@observer
export default class ModuleLoader<T> extends React.Component<Props<T>, {}> {
  @observable status: LoadStatus = "idle"
  @observable component?: LoadedComponent<T>

  componentWillMount() {
    this.props
      .loadModule()
      .then(mod => {
        this.component = mod.default
        this.status = "loaded"
      })
      .catch(ex => {
        console.error(ex)
        this.status = "error"
        console.error(ex)
        Sentry.captureException(ex)
      })
  }

  render() {
    if (this.status === "loading" || this.status === "idle") {
      return <Spinner />
    } else {
      const Child = this.component
      if (Child) {
        return <Child {...this.props.moduleProps} />
      } else {
        Sentry.captureException(new Error("ModuleLoader no child"))
        return <ErrorMessage />
      }
    }
  }
}
