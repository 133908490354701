import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosError } from "axios"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import { observable, action, computed } from "mobx"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"
import Offer from "models/Offer"
import LocationSummary from "models/LocationSummary"

interface OfferIdentifier {
  id: string
  type: "id" | "slot"
}

export default class OfferStore extends APIStore {
  @observable offer?: Offer

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    MemoizeMiddleware,
    DeserializeMiddleware("offer", Offer),
  ])

  constructor(
    public offerIdentifier: OfferIdentifier,
    public locationSummary: LocationSummary
  ) {
    super()
  }

  fetch() {
    if (this.offerIdentifier.type === "id") {
      return this.api.get(
        `/api/locations/${this.locationSummary.id}/offers/${this.offerIdentifier.id}`
      )
    } else {
      return this.api.get(
        `/api/locations/${this.locationSummary.id}/offers/slot/${this.offerIdentifier.id}`
      )
    }
  }

  @action.bound
  handleSuccess(res: AxiosResponse<{ offer: Offer }>) {
    this.offer = res.data.offer
    return res
  }
}
