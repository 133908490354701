import React, { Component } from "react"
import { observer, inject } from "mobx-react"
import sortBy from "lodash/sortBy"

import Balance from "apps/buy/models/xpass/Balances"
import XpassBooking from "models/xpass/XpassBooking"
import ScheduleEntry from "models/ScheduleEntry"
import Booking from "models/Booking"
import ScheduleStore from "apps/book/stores/ScheduleStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import BrandStore from "stores/BrandStore"
import LocationDetailStore from "apps/xpass/stores/LocationDetailStore"
import ExpandedEntryStore from "apps/book/stores/ExpandedEntryStore"
import QueryStringMonitor from "apps/book/stores/QueryStringMonitor"
import ScheduleNav from "apps/book/components/xpass/ScheduleNav"
import { ClassListItem } from "apps/book/components/xpass/EntryListItem"

export interface LocationDetailsScheduleProps {
  store?: BrandStore
  scheduleStore: ScheduleStore
  scheduleEntryStore: ScheduleEntryStore
  onBookingModal: Function
  locationId?: string
  location?: any
  instructor?: string
  userBalances: {
    balances?: Balance
    freeClassesPurchased: boolean
    freeClassBookings: XpassBooking[]
  }
  bookedBookings: Booking[]
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class LocationDetailsSchedule extends Component<
LocationDetailsScheduleProps
> {
  locationDetailStore = new LocationDetailStore()
  private expandedEntryStore = new ExpandedEntryStore(this.props.scheduleStore)
  private qsMonitor = new QueryStringMonitor(
    this.props.scheduleStore,
    this.props.store!,
    this.expandedEntryStore
  )

  componentWillUnmount() {
    this.qsMonitor.dispose()
    this.expandedEntryStore.dispose()
  }

  isFreeClass = (entry: ScheduleEntry) => {
    const { balances, freeClassesPurchased, freeClassBookings } = this.props.userBalances
    const hasFreeClasses = freeClassesPurchased && balances && balances.freeClasses > 0
    const bookedLocations = freeClassBookings && freeClassBookings.map(booking => booking.scheduleEntry.location.id)
    return hasFreeClasses && entry.isXpassFree && bookedLocations
      ? !bookedLocations.includes(this.props.locationId!)
      : false
  }

  render() {
    const { scheduleStore, store } = this.props
    const { entries, week } = scheduleStore
    const dayEntries = sortBy(entries, "startsAt").filter(
      entry => {
        if (this.props.instructor) {
          return (
            entry.startsAt.format("YYYY-MM-DD") === week.date &&
            entry.instructor!.name === this.props.instructor
          )
        }
        return entry.startsAt.format("YYYY-MM-DD") === week.date
      }
    )
    const classes = dayEntries.map((entry) => {
      const logoUrl = this.props.scheduleEntryStore.getBrandIcon(entry.brand.id)
      const bookedEntry = this.props.bookedBookings.find(booking => booking.id === entry.id)
      const isFree = this.isFreeClass(entry)
      const locationId = store!.userStore.session!.locationId
      const onBookingModal = this.props.onBookingModal
      return {logoUrl, bookedEntry, isFree, entry, onBookingModal, locationId}
    })
    const hasPremiumPaidClasses = dayEntries.find((entry) => entry.isPremiumPaid) 
    return (
      <div className="container p-0 py-3 py-md-2">
        <ScheduleNav
          week={week}
          pathname={this.props.location!.pathname}
          search={this.props.location!.search}
          className="mt-5 mb-4 mb-lg-5"
          scheduleEntryStore={this.props.scheduleEntryStore}
          store={this.props.store!}
        />
            <div className="location-schedule-list">
              {!dayEntries.length && (
                <div className="location-schedule">
                  <div className="d-flex align-items-center">
                    <h3 className="mt-4">
                      It looks like there are no classes
                      available
                    </h3>
                  </div>
                </div>
              )}
              {classes.map((item) => <ClassListItem key={item.entry.id} {...item} />)}
              {hasPremiumPaidClasses && <p className="paid-premium">* You have a cash balance equal to this amount</p>}
            </div>
      </div>
    )
  }
}
