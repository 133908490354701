import { Metric } from "apps/history/models/Stats"
import {
  baseSettings,
  BrandData,
  BrandCopy,
  BrandSettings,
  BrandStyles,
  BrandStyleClasses,
  baseCopy,
  BadgeBitmap,
  baseStyles,
  baseStyleClasses,
  BrandDashboardCards,
  eywAffiliateLinks,
} from "themes/base"
import numberFormatter, { formatNumber } from "helpers/numberFormatter"

import logoUrl from "images/logos/cyclebar-logo.svg"
import badgeImage from "images/badges/cyclebar-badge.svg"
import bannerImage from "images/dashboard/july-4th-charity-ride.jpg"
import appPromoImage_Video from "images/video/cyclebar-app-promo.png"
import videoLandingLogo from "images/video/cyclebar-go-logo.png"
import appleWatchIcon from "images/apple_health/watch/cyclebar.svg"

import cyclebarClass25 from "images/badges/cyclebar/red/RideClub_Milestones_App_25-Red.png"
import cyclebarClass50 from "images/badges/cyclebar/red/RideClub_Milestones_App_50-Red.png"
import cyclebarClass100 from "images/badges/cyclebar/red/RideClub_Milestones_App_100-Red.png"
import cyclebarClass250 from "images/badges/cyclebar/red/RideClub_Milestones_App_250-Red.png"
import cyclebarClass500 from "images/badges/cyclebar/red/RideClub_Milestones_App_500-Red.png"
import cyclebarClass750 from "images/badges/cyclebar/red/RideClub_Milestones_App_750-Red.png"
import cyclebarClass1000 from "images/badges/cyclebar/red/RideClub_Milestones_App_1000-Red.png"
import cyclebarClass1250 from "images/badges/cyclebar/red/RideClub_Milestones_App_1250-Red.png"
import cyclebarClass2000 from "images/badges/cyclebar/red/RideClub_Milestones_App_2000-Red.png"
import cyclebarClass25Grey from "images/badges/cyclebar/grey/RideClub_Milestones_App_25-Grey.png"
import cyclebarClass50Grey from "images/badges/cyclebar/grey/RideClub_Milestones_App_50-Grey.png"
import cyclebarClass100Grey from "images/badges/cyclebar/grey/RideClub_Milestones_App_100-Grey.png"
import cyclebarClass250Grey from "images/badges/cyclebar/grey/RideClub_Milestones_App_250-Grey.png"
import cyclebarClass500Grey from "images/badges/cyclebar/grey/RideClub_Milestones_App_500-Grey.png"
import cyclebarClass750Grey from "images/badges/cyclebar/grey/RideClub_Milestones_App_750-Grey.png"
import cyclebarClass1000Grey from "images/badges/cyclebar/grey/RideClub_Milestones_App_1000-Grey.png"
import cyclebarClass1250Grey from "images/badges/cyclebar/grey/RideClub_Milestones_App_1250-Grey.png"
import cyclebarClass2000Grey from "images/badges/cyclebar/grey/RideClub_Milestones_App_2000-Grey.png"

const settings: BrandSettings = {
  ...baseSettings,
  isPerformance: true,
  hasLeaderboard: true,
  hasWeight: true,
  hasShoeSize: true,
  showPromoOnEmptyPerf: false,
  allowBookingOnWaitlistClosed: true,
  showBuyRedirectButton: true,
  disallowACHPayment: true,
  billingCountryCode: "AU"
}

const styles: BrandStyles = {
  ...baseStyles,
  badgeImage,
  bannerImage,
  chartColor: "#d0021b",
  logoUrl,
  appPromoImage_Video,
  videoLandingLogo,
  appleWatchIcon,
}

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
  AttendanceCard__dateFormat: "DD/MM/YYYY",
  BillingHistory__dateFormat: "DD/MM/YY",
  BookClassSubNav__dateFormat: "d/m/YY",
  ChallengeCard__dateFormat: "DD/MM/YYYY",
  FiltersModal__dateFormat: "d/m/Y",
  FormDatePickerEnhanced__dateFormat: "d/m/Y",
  MySchedulePage__dateFormat: "dddd, DD/MM",
  NextClassCard__dateFormat: "DD/MM/YY",
  PackageRenewalText_dateFormat: "D/M/YYYY",
  PurchaseCard__dateFormat: "DD/MM/YYYY",
  PurchasesTable__dateFormat: "DD/MM/YYYY",
  ScheduleEntryFilters__dateFormat: "d/m/Y",
  ScheduleWeekNav__dateFormat: "D/M/YY",
  ScheduleDayNav__dateFormat: "DD/MM",
  ServiceMembershipsCard__dateFormat: "D/M/YY",
  ServiceTypeSchedule__sessionDateFormat: "D/M/YYYY",
  ServiceTypeSchedule__whenDateFormat: "D/M",
}

const copy: BrandCopy = {
  ...baseCopy,
  attend: "rock",
  attended: "rocked",
  class: "ride",
  classPackages: "Here are our ride memberships and packages.",
  classes: "rides",
  credit: "ride",
  credits: "rides",
  seat: "Bike",
  seats: "Bikes",
  user: "Rider",
  users: "Riders",
  purchases: "Rides",
  attendanceCard:
    "Now that you've taken the first step to becoming a CycleBar star, set your weekly studio visit goal here.",
  bookClass: "Reserve",
  buyCredits: `Buy Rides`,
  comingSoonOffersDescription:
    "Check out our exclusive Founding Member Unlimited Rides offer!",
  comingSoonOffersCta: "Founder Offer",
  bookingLabel: "You may be out of rides or need a special ride credit (CycleGives, Free, Special Event) to book. Please select one of the options below to book this ride.",
  bookingErrorTitle: "Ride Credit Needed",
  buyRedirectText: "Get Ride Credit",
  distanceUnit: "km",
  phonePlaceholder: "Enter number here",
  weightUnit: "kgs",
  zipLabel: "Postal Code ",
}

const dashboardCards: BrandDashboardCards = [
  "NextClassCard",
  "AttendanceCard",
  "AchievementsCard",
  "PerformanceCard",
  "TodaysClassCard",
  "ClassPointsCard",
  // "AppleHealthCard",
  "MobileAppCard",
  "MobileOfferCard",
  "PromoCards",
]

const badges: BadgeBitmap[] = [
  {
    label: "25 Classes",
    value: 25,
    active: cyclebarClass25,
    inactive: cyclebarClass25Grey,
  },
  {
    label: "50 Classes",
    value: 50,
    active: cyclebarClass50,
    inactive: cyclebarClass50Grey,
  },
  {
    label: "100 Classes",
    value: 100,
    active: cyclebarClass100,
    inactive: cyclebarClass100Grey,
  },
  {
    label: "250 Classes",
    value: 250,
    active: cyclebarClass250,
    inactive: cyclebarClass250Grey,
  },
  {
    label: "500 Classes",
    value: 500,
    active: cyclebarClass500,
    inactive: cyclebarClass500Grey,
  },
  {
    label: "750 Classes",
    value: 750,
    active: cyclebarClass750,
    inactive: cyclebarClass750Grey,
  },
  {
    label: "1000 Classes",
    value: 1000,
    active: cyclebarClass1000,
    inactive: cyclebarClass1000Grey,
  },
  {
    label: "1250 Classes",
    value: 1250,
    active: cyclebarClass1250,
    inactive: cyclebarClass1250Grey,
  },
  {
    label: "2000 Classes",
    value: 2000,
    active: cyclebarClass2000,
    inactive: cyclebarClass2000Grey,
  },
]

const metrics: Metric[] = [
  {
    key: "avgPower",
    label: "Avg Watts",
    logo: "perf-power",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "highPower",
    label: "Max Watts",
    logo: "perf-power",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "avgRpm",
    label: "Avg RPM",
    logo: "perf-rpm",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "highRpm",
    label: "Max RPM",
    logo: "perf-rpm",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "calories",
    label: "Calories",
    logo: "perf-calories",
    logoSize: 38,
    format: formatNumber,
  },
  {
    key: "distance",
    label: "Distance",
    logo: "perf-distance-generic",
    logoSize: 30,
    format: numberFormatter({ suffix: copy.distanceUnit, decimalPlaces: 1 }),
  },
  {
    key: "avgHr",
    label: "Avg Hr",
    logo: "perf-power",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "maxHr",
    label: "Max Hr",
    logo: "perf-power",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "rank",
    label: "Rank",
    logo: "perf-rank",
    logoSize: 27,
    golf: true,
    format: formatNumber,
  },
  {
    key: "totalEnergy",
    label: "Power Points",
    logo: "perf-points",
    logoSize: 28,
    format: formatNumber,
  },
]

const cyclebar: BrandData = {
  settings,
  styles,
  styleClasses,
  copy,
  dashboardCards,
  badges,
  metrics,
  summaryMetrics: [],
  eywAffiliateLinks: eywAffiliateLinks["cyclebar"],
  eywLegalLink: "https://www.cyclebar.com.au/terms-earn-your-watch",
  eywParticipatingLink: "https://www.cyclebar.com.au/earn-your-watch-locations"
}

export default cyclebar
