import { AxiosError, AxiosPromise, AxiosResponse } from "axios"
import { action, computed } from "mobx"

import APIStore from "stores/APIStore"
import APIClient from "services/APIClient"
import UserStore from "stores/UserStore"
import { SummaryStats, Metric } from "apps/history/models/Stats"
import { UserSession } from "models/UserSession"

import ScheduleEntryV1 from "models/v1/ScheduleEntryV1"
import AggregateMetric from "apps/history/models/AggregateMetric"
import ClassStatsBase from "apps/history/models/ClassStatBase"

interface StatsResponse {
  summary_stats: SummaryStats
  class_stats: ClassStatsBase[]
  user?: UserSession
}

export default abstract class AbstractStatsStore<
  T extends StatsResponse
> extends APIStore {
  summaryStats?: T["summary_stats"]
  classStats?: T["class_stats"]
  user?: T["user"]

  abstract api: APIClient<T>

  /**
   * Total classes attended according to ClubReady, for badges, etc
   * @returns {number}
   */
  @computed get attendanceCount() {
    return this.userStore.session!.attendanceCount
  }

  /**
   * Classes for which the user has performance data
   * @returns {number}
   */
  @computed get statsCount() {
    return Array.isArray(this.classStats) ? this.classStats.length : 0
  }

  constructor(
    protected userStore: UserStore,
    public metrics: Metric<T["class_stats"][0]>[]
  ) {
    super()
  }

  abstract fetch(): AxiosPromise<T>

  getClassMetric(metricId: Metric<T["class_stats"][0]>["key"]) {
    return this.metrics.find(p => p.key === metricId)!
  }

  getSummaryStat(metricId: Metric<T["summary_stats"]>["key"]) {
    return {
      value: this.summaryStats && this.summaryStats[metricId],
      metric: this.getClassMetric(metricId),
    }
  }

  getSource() {
    // Return source from first class stat
    return (
      this.classStats &&
      this.classStats.length > 0 &&
      Object.assign(this.classStats[0]).source
    )
  }

  getAgg(metricId: Metric<T["class_stats"][0]>["key"], classTypeId?: string) {
    return new AggregateMetric(
      this.classStats!,
      this.getClassMetric(metricId),
      classTypeId
    )
  }

  filteredAttendance(classCategoryId?: string) {
    if (!classCategoryId || classCategoryId === "all" || !this.classStats) {
      return this.attendanceCount
    }

    const categoryMatch = (stat: ClassStatsBase) =>
      (classCategoryId === "other" && !stat.scheduleEntry.classCategory) ||
      (stat.scheduleEntry.classCategory &&
        stat.scheduleEntry.classCategory.id === classCategoryId)

    return this.classStats.filter(categoryMatch).length
  }

  findStatFor(entry: ScheduleEntryV1) {
    if (!this.classStats) return
    return this.classStats.find(({ scheduleEntry }) => {
      // check for clubreadyId match
      if (scheduleEntry.clubreadyId === entry.clubreadyId) {
        return true
      }

      // check for piqId match
      if (entry.piqId && scheduleEntry.piqId === entry.piqId) {
        return true
      }

      // try to match by time/class
      if (entry.startsAt.isSame(scheduleEntry.startsAt)) {
        return true
      }

      return false
    })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<T>) {
    this.summaryStats = res.data.summary_stats
    this.classStats = res.data.class_stats
    this.user = res.data.user

    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    throw ex
  }
}
