import React from "react"
import { observer } from "mobx-react"
import * as cx from "classnames"

// takes a function to calculate the td + a heading (th) and/or key
export type TableColumn<P> = {
  className?: string
  type?: "button" | "actions"
  // SFC-ish function for contents of td
  cell(props: P): React.ReactNode
} & (
  | { key: string; heading?: React.ReactNode } // heading optional
  // use heading as key. heading needs to be unique if key not supplied
  | { key?: string; heading: string })

export interface Props<P> {
  rows: P[] // row data
  columns: TableColumn<P>[] // structure of table
  noHeading?: boolean
  hasControls?: boolean
  className?: string
  emptyPlaceholder?: React.ReactNode
  rowKey(props: P): React.Key // fn to identify row
  rowClass?(props: P): string //generates the className to apply to each row of the table, if any
  divider?(
    lastProps: P | undefined,
    nextProps: P,
    columns: number
  ): React.ReactNode
}

// helper to get the key to identify column (th/td)
function cellKey<P>(column: TableColumn<P>) {
  return column.key || `${column.heading}`
}

// renders a regular table (only visible on desktop)
// and a list of little tables (only visible on mobile)
// takes a table structure (columns) plus data (rows)
const ResponsiveTable = <P extends {}>({
  columns,
  rows,
  rowKey,
  divider,
  noHeading,
  hasControls,
  className,
  emptyPlaceholder,
  rowClass,
}: Props<P>) => {
  return (
    <>
      <table
        className={`table responsive-table responsive-table-lg ${className}`}
      >
        {!noHeading && (
          <thead>
            <tr>
              {columns.map(column => (
                <th key={cellKey(column)} className={column.className}>
                  {column.heading}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="rows-in">
          {rows.map((row, i) => (
            <React.Fragment key={rowKey(row)}>
              {divider && divider(rows[i - 1], row, columns.length)}
              <tr className={rowClass && rowClass(row)}>
                {columns.map(column => (
                  <td
                    key={cellKey(column)}
                    className={cx(column.className, {
                      "row-actions": column.type === "actions",
                      "row-button": column.type === "button",
                    })}
                  >
                    {column.cell(row)}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className="responsive-table responsive-table-sm">
        {rows.map(row => (
          <div key={rowKey(row)} className="table-wrapper">
            <table className={cx("table", { "has-controls": !!hasControls })}>
              <tbody>
                {columns.map(column => (
                  <tr
                    key={cellKey(column)}
                    className={cx(column.className, {
                      "row-actions": column.type === "actions",
                      "row-button": column.type === "button",
                    })}
                  >
                    <MobileRow
                      column={column}
                      row={row}
                      noHeading={noHeading}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
      {rows.length === 0 && !!emptyPlaceholder && emptyPlaceholder}
    </>
  )
}

interface MobileRowProps<T> {
  column: TableColumn<T>
  row: T
  noHeading?: boolean
}

function MobileRow<T>({ column, row, noHeading }: MobileRowProps<T>) {
  const val = column.cell(row)

  // if (noHeading) {
  // return <td>{val}</td>
  // } else
  if (column.type) {
    return <td colSpan={2}>{val}</td>
  } else {
    return (
      <>
        <th>{column.heading}</th>
        <td>{val}</td>
      </>
    )
  }
}

export default observer(ResponsiveTable)
