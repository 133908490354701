import * as React from "react"
import { Size } from "components/Spinner"

interface ErrorMessageProps {
  size?: Size
  message?: string
  id?: string
}

const formattedErrorMessage = (errorMessage: string) => (
  errorMessage.split('\n').map((line, i) => (
    <p key={i}>{line}</p>
  ))
)

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
  size,
  message,
  id,
}) => {
  return (
    <div className="alert alert-danger">
      <h4>An Error Occurred</h4>
      {message && typeof(message) === "string"
        ? formattedErrorMessage(message)
        : "We apologize for the inconvenience"
      }
      {id && (
        <>
          <hr />
          <p className="mb-0">
            <small>Reference ID: {id}</small>
          </p>
        </>
      )}
    </div>
  )
}

export default ErrorMessage
