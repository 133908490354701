import * as React from "react"
import { observer, inject } from "mobx-react"
import cx from "classnames"
import BrandStore from "stores/BrandStore"
import { BrandCopy } from "themes/base"
import { Link } from "react-router-dom"
import APILoader from "components/wrappers/APILoader"
import classTokenImg from "images/xpass/xpass-token.png"
import rewardsLoaderImg from "images/xpass/rewards-loader.svg"
import RewardsStore from "./stores/xpass/RewardsStore"

interface Props {
  store?: BrandStore
  className?: string
  copy?: BrandCopy
  rewardsStore: RewardsStore
}

interface RewardItemProps {
  title: string
  thumbnailImage: string
  rewardId: string | number
  rewardType: "token" | "deal" | "badge"
}

const RewardItem: React.FC<RewardItemProps> = ({
  title,
  thumbnailImage,
  rewardId,
  rewardType
 }) => {
  const classNames = "reward-item flex flex-grow-1 flex-column justify-content-center align-items-center"
  const rewardUrl = rewardType === "token" ? `rewards/tokens/${rewardId}` : `rewards/${rewardId}`
  return (
    <Link to={rewardUrl} className={classNames}>
      <img src={thumbnailImage} alt={title} />
      <p className="mt-1">{title}</p>
    </Link>
  )
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class RewardsCard extends React.Component<Props> {
  componentDidMount() {
    this.props.rewardsStore.fetch()
  }
  render() {
    const { className } = this.props
    const { copy } = this.props.store!
    const { deal, classToken } = this.props.rewardsStore
    
    return (
      <div className={cx("card", className)}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="card-title">{copy.dashCardRewards}</h4>
          <small>
            <Link
              to={`/rewards`}
            >
              View All
            </Link>
          </small>
        </div>
        <span className="border-top border-light" />
        <APILoader 
          apiStore={this.props.rewardsStore}
          elementSize="element"
          render={() => (
            <div>
              <div className="rewards-card__content">
                <p>Keep on top of your Tokens and Deals</p>
                <div className="rewards-card__items w-100 mt-2">
                  {classToken && (
                    <RewardItem 
                      rewardId={classToken.id}
                      rewardType="token"
                      title={classToken.name}
                      thumbnailImage={classToken.thumbnailImage || classTokenImg}
                      />
                  )}
                  {deal && (
                    <RewardItem 
                      rewardId={deal.id}
                      rewardType="deal"
                      title={deal.title}
                      thumbnailImage={deal.daystabThumbnailImage!}
                      />
                  )}
                  {!deal && !classToken && (
                    <div className="rewards-card__loader w-100">
                      <img className="w-100 h-100 relative" src={rewardsLoaderImg} alt="rewards loader" />
                      <div className="rewards-card__loader-text">
                        <h4>No Rewards at this time</h4>
                        <p>Please come back later.</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          />
      </div>
    )
  }
}
