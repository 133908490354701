import * as React from "react"
import UIStore, { ModalProps } from "stores/UIStore"
import { observer } from "mobx-react"

import Modal from "react-bootstrap/Modal"
import cx from "classnames"
import BrandStore from "stores/BrandStore"
import { formatPhone } from "helpers/stringHelper"
import ErrorBoundary from "components/wrappers/ErrorBoundary"

interface Props extends ModalProps {
  store: BrandStore
  onClose(): void
}

@observer
export default class UIModal extends React.Component<Props, {}> {
  handleCloseClick = (e: ButtonEvent) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onClose()
  }

  handleModalClose = () => {
    this.props.onClose()
  }

  renderDefault = () => {
    const { isXponential } = this.props.store
    const isCentered =
      isXponential ||
      this.props.store.routingStore.pageName === "service schedule"

    return (
      <Modal
        show={this.props.modalShow}
        onHide={this.handleModalClose}
        // TODO: react-bootstrap doesn't support "xl"
        // remove once: https://github.com/react-bootstrap/react-bootstrap/pull/3530
        size={this.props.size as any}
        centered={isCentered}
        className={this.props.classes}
      >
        {this.props.title && (
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
        )}

        {this.props.emptyHeader && (
          <Modal.Header style={{ border: "none", paddingBottom: "0px" }} closeButton />
        )}

        <Modal.Body>
          <ErrorBoundary>{this.props.children}</ErrorBoundary>
        </Modal.Body>
      </Modal>
    )
  }

  renderMessage = () => {
    const {
      modalShow,
      locationSummary: loc,
      size,
      title,
      message,
      type,
      store: { brand },
    } = this.props

    return (
      <Modal show={modalShow} onHide={this.handleModalClose} size={size as any}>
        {title && (
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <ErrorBoundary>
            <div className="text-center">
              <p
                className={cx("text-center", {
                  // "text-danger": type === "error",
                })}
              >
                {message}
              </p>

              {loc && (
                <p>
                  <strong>
                    {brand.name} {loc.name}
                  </strong>
                  {loc.phone && (
                    <>
                      <br />
                      <a href={`tel:${loc.phone}`} className="small">
                        {formatPhone(loc.phone)}
                      </a>
                    </>
                  )}

                  {/* {loc.address} {loc.address2 || ""}
                <br />
                {loc.city} {loc.state}, {loc.zip}
                <br /> */}
                  {loc.email && (
                    <>
                      <br />
                      <a href={`mailto:${loc.email}`} className="small">
                        {loc.email}
                      </a>
                    </>
                  )}
                </p>
              )}
              <button
                className="btn btn-primary"
                onClick={this.handleCloseClick}
              >
                OK
              </button>
            </div>
          </ErrorBoundary>
        </Modal.Body>
      </Modal>
    )
  }

  public render() {
    switch (this.props.type) {
      case "message":
      case "error":
        return this.renderMessage()
      default:
        return this.renderDefault()
    }
  }
}
