import {
  BrandData,
  BrandStyleClasses,
  baseSettings,
  baseStyles,
  baseStyleClasses,
  baseCopy,
  baseDashboardCards,
  BadgeBitmap,
  eywAffiliateLinks,
} from "themes/base"

import logoUrl from "images/logos/clubpilates-logo.svg"
import badgeImage from "images/badges/clubpilates-badge.svg"
import bannerImage from "images/dashboard/clubpilates-banner-bg.jpg"
import appPromoImage_Video from "images/video/clubpilates-app-promo.png"
import videoLandingLogo from "images/video/clubpilates-go-logo.png"
import appleWatchIcon from "images/apple_health/watch/clubpilates.svg"

import badge100Active from "images/badges/clubpilates/milestone-0-Active-ClubPilates.png"
import badge250Active from "images/badges/clubpilates/milestone-1-Active-ClubPilates.png"
import badge500Active from "images/badges/clubpilates/milestone-2-Active-ClubPilates.png"
import badge750Active from "images/badges/clubpilates/milestone-3-Active-ClubPilates.png"
import badge1000Active from "images/badges/clubpilates/milestone-4-Active-ClubPilates.png"

import badge100Inactive from "images/badges/clubpilates/milestone-0-Inactive-ClubPilates.png"
import badge250Inactive from "images/badges/clubpilates/milestone-1-Inactive-ClubPilates.png"
import badge500Inactive from "images/badges/clubpilates/milestone-2-Inactive-ClubPilates.png"
import badge750Inactive from "images/badges/clubpilates/milestone-3-Inactive-ClubPilates.png"
import badge1000Inactive from "images/badges/clubpilates/milestone-4-Inactive-ClubPilates.png"

const badges: BadgeBitmap[] = [
  {
    label: "100 Classes",
    value: 100,
    active: badge100Active,
    inactive: badge100Inactive,
  },
  {
    label: "250 Classes",
    value: 250,
    active: badge250Active,
    inactive: badge250Inactive,
  },
  {
    label: "500 Classes",
    value: 500,
    active: badge500Active,
    inactive: badge500Inactive,
  },
  {
    label: "750 Classes",
    value: 750,
    active: badge750Active,
    inactive: badge750Inactive,
  },
  {
    label: "1000 Classes",
    value: 1000,
    active: badge1000Active,
    inactive: badge1000Inactive,
  },
]

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
  AttendanceCard__dateFormat: "DD/MM/YYYY",
  BillingHistory__dateFormat: "DD/MM/YY",
  BookClassSubNav__dateFormat: "d/m/YY",
  ChallengeCard__dateFormat: "DD/MM/YYYY",
  FiltersModal__dateFormat: "d/m/Y",
  FormDatePickerEnhanced__dateFormat: "d/m/Y",
  MySchedulePage__dateFormat: "dddd, DD/MM",
  NextClassCard__dateFormat: "DD/MM/YY",
  PackageRenewalText_dateFormat: "D/M/YYYY",
  PurchaseCard__dateFormat: "DD/MM/YYYY",
  PurchasesTable__dateFormat: "DD/MM/YYYY",
  ScheduleEntryFilters__dateFormat: "d/m/Y",
  ScheduleWeekNav__dateFormat: "D/M/YY",
  ScheduleDayNav__dateFormat: "DD/MM",
  ServiceMembershipsCard__dateFormat: "D/M/YY",
  ServiceTypeSchedule__sessionDateFormat: "D/M/YYYY",
  ServiceTypeSchedule__whenDateFormat: "D/M",
}

const brand: BrandData = {
  settings: {
    ...baseSettings,
    morePackagesInStore: true,
    freeSpotsThreshold: 12,
    billingHistoryEnabled: true,
    vodEarlySubscriberDiscount: 10,
    printMySchedule: true,
    showAddToCalendarButton: true,
    showRemainingCredit: false,
    disallowACHPayment: true,
    billingCountryCode: "GB"
  },
  styles: {
    ...baseStyles,
    logoUrl,
    badgeImage,
    bannerImage,
    videoLandingLogo,
    appPromoImage_Video,
    appleWatchIcon,
  },
  styleClasses,
  copy: {
    ...baseCopy,
    createAccount: "Create an Account",
    seat: "Class",
    mainMenuClasses: "credits",
    activity: "History",
    mobileAppAvailable: "Club Pilates Mobile App",
    distanceUnit: "km",
    phonePlaceholder: "Enter number here",
    weightUnit: "kgs",
    zipLabel: "Postal Code",
  },
  dashboardCards: [
    "NextClassCard",
    "TodaysClassCard",
    "AchievementsCard",
    "AttendanceCard",
    "BadgeCard",
    "ClassPointsCard",
    "ProfileCard",
    "MobileAppCard",
    "MobileOfferCard",
    "PromoCards",
  ],
  badges: badges,
  metrics: [],
  summaryMetrics: [],
  eywAffiliateLinks: eywAffiliateLinks["clubpilates"],
  eywLegalLink: "https://www.clubpilates.uk/terms-earn-your-watch",
  eywParticipatingLink: "https://www.clubpilates.uk/earn-your-watch-locations"
}

export default brand
