import * as React from "react"
import moment from "moment"
import Duration from "helpers/Duration"
import { Link } from "react-router-dom"
import * as cx from "classnames"
import { parse, stringify } from "helpers/queryString"

export interface Props {
  week: Duration
  pathname: string
  search: string
  styleClasses: { ScheduleDayNav__dateFormat: string }
}

function mobileDay(index: string) {
  switch (index) {
    case "0":
      return "Su"
    case "1":
      return "M"
    case "2":
      return "T"
    case "3":
      return "W"
    case "4":
      return "Th"
    case "5":
      return "F"
    case "6":
      return "Sa"
  }

  return ""
}

function dayStrings(week: Duration) {
  let days = []
  const { startAt, endAt } = week
  for (const d = startAt.clone(); d < endAt; d.add(1, "d")) {
    days.push(d.clone().format("YYYY-MM-DD"))
  }
  return days
}

const ScheduleDayNav: React.FunctionComponent<Props> = ({
  week,
  pathname,
  search: originalSearch,
  styleClasses,
}) => {
  const days = dayStrings(week)
  const today = moment().format("YYYY-MM-DD")

  return (
    <div className="row mb-3 mb-md-0">
      {days &&
        days.map(day => {
          const isSelected = day === week.date
          const date = moment(day)
          const search = stringify({
            ...parse(originalSearch),
            date: date.format("YYYY-MM-DD"),
          })

          return (
            <Link
              key={`entry-${date.format("MMDD")}`}
              className={cx(
                "schedule-day",
                "col",
                "text-center",
                "px-1",
                "py-3",
                "text-decoration-none",
                { "schedule-day-selected": isSelected }
              )}
              to={{ pathname, search }}
            >
              <h3
                className={cx(
                  "m-0",
                  { "text-muted": !isSelected },
                  { "text-white": isSelected }
                )}
              >
                <span className="d-lg-none">{mobileDay(date.format("d"))}</span>
                <span className="schedule-day-name d-none d-lg-inline">
                  {day === today ? "Today" : date.format("dddd")}
                </span>
              </h3>
              <span className="schedule-date">{date.format(styleClasses.ScheduleDayNav__dateFormat)}</span>
            </Link>
          )
        })}
    </div>
  )
}

export default ScheduleDayNav
