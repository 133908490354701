import { JsonObject, JsonProperty } from "json2typescript"

export type ServiceBookingReasonCode =
  | "booking_lead_time"
  | "credit_expires_before_booking"
  | "cross_club"
  | "eligibility"
  | "error"
  | "instructor_booked"
  | "invalid_time"
  | "max_open_bookings"
  | "member_frozen"
  | "member_late"
  | "no_available_credit"
  | "open_spots"
  | "unauthorized"
  | "unknown"
  | "user_booked"
  | "waitlist_locked"

@JsonObject("ServiceBookingReason")
export default class ServiceBookingReason {
  @JsonProperty("message", String)
  message: string = undefined!

  @JsonProperty("code", String)
  code: ServiceBookingReasonCode = undefined!
}
