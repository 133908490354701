import * as React from "react"
import * as cx from "classnames"
import { observer } from "mobx-react"
import { action, observable } from "mobx"
import PlanV3Store from "apps/buy/stores/PlanV3Store"
import { AxiosResponse, AxiosError } from "axios"
import { isApiError } from "helpers/errorHandling"
import PromoCodeV3Store from "apps/buy/stores/PromoCodeV3Store"

export interface Props {
  planStore: PlanV3Store
}

@observer
export default class PromoCodeV3Form extends React.Component<Props, {}> {
  promoCodeStore = new PromoCodeV3Store(this.props.planStore)

  // this is why we have formik oh well
  // refactor to formik if this gets any more complex
  @observable value: string = ""
  @observable isDirty: boolean = false

  componentWillUnmount() {
    this.promoCodeStore.dispose()
  }

  @action.bound
  handlePromoCodeChange(e: InputEvent) {
    this.isDirty = true
    this.value = e.currentTarget.value
    this.props.planStore.errorCode = ''
    this.props.planStore.errorPromo = ''
  }

  handlePromoCodeClick = (e: ButtonEvent) => {
    e.preventDefault()
    this.isDirty = false
    this.promoCodeStore.addCode(this.value)
    this.props.planStore.fetch()
  }

  handlePromoCodeRemove = (e: ButtonEvent) => {
    e.preventDefault()
    this.value = ""
    this.isDirty = false
    this.promoCodeStore.removeCode()
  }

  public render() {
    const { planStore } = this.props
    const status = this.promoCodeStore.status
    const isLoading = status === "loading"

    const inputClass = cx("form-control", {
      "is-valid": !isLoading && planStore.promoCode && !planStore.errorCode && !this.isDirty,
      "is-invalid": planStore.errorCode,
    })

    if (planStore.promoCode && !planStore.errorCode) {
      return (
        <Wrapper
          text="remove"
          disabled={isLoading}
          onClick={this.handlePromoCodeRemove}
        >
          <input
            type="text"
            className={inputClass}
            value={isLoading ? "" : planStore.promoCode}
            disabled
          />
        </Wrapper>
      )
    }

    return (
        <Wrapper
          text="Apply"
          disabled={isLoading || !this.value}
          onClick={this.handlePromoCodeClick}
        >
          <input
            type="text"
            form="PromoCodeForm"
            className={inputClass}
            placeholder="Enter Promo Code"
            value={planStore.errorPromo || this.value }
            onChange={this.handlePromoCodeChange}
            disabled={isLoading || (!!planStore.promoCode && !planStore.errorCode)}
          />
        </Wrapper>
    )
  }
}

interface WrapperProps {
  text: string
  disabled?: boolean
  onClick?(e: ButtonEvent): void
}

const Wrapper: React.FunctionComponent<WrapperProps> = ({
  children,
  text,
  disabled,
  onClick,
}) => (
  <div>
    <div className="input-group mt-4 mb-3">
      {children}
      <div className="input-group-append">
        <button
          type="button"
          className="btn btn-dark"
          disabled={disabled}
          onClick={onClick}
        >
          {text}
        </button>
      </div>
    </div>
  </div>
)
