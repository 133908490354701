import React from 'react'
import { Link } from "react-router-dom"
import { observer } from 'mobx-react'
//models
import ScheduleEntry from 'apps/book/models/xpass/ScheduleEntry';
import ScheduleEntryModel from 'models/ScheduleEntry';
import Booking from 'models/Booking';
import ServiceLocation from "apps/book/models/xpass/ServiceLocation"
// icons
import FavoriteSelectedIcon from 'images/xpass/icons/favorite-selected.svg'
import FavoriteUnselectedIcon from 'images/xpass/icons/favorite-unselected.svg'
import FireIcon from 'images/xpass/icons/great-price.svg'
import StarIcon from 'images/xpass/icons/star.svg'
import tokenIcon from "images/xpass/token.png"
// stores
import BrandStore from "stores/BrandStore"
import ScheduleEntryLocationStore from "apps/book/stores/xpass/ScheduleEntryLocationStore"
import ServiceTypesStore from "apps/book/stores/ServiceTypesStore"
import ScheduleEntryLocation from 'apps/book/models/xpass/ScheduleEntryLocation'
import ScheduleEntryStore from 'apps/book/stores/xpass/ScheduleEntryStore'
// components
import APILoader from "components/wrappers/APILoader"
import * as moment from "moment"


export interface ClassItem {
  entry: ScheduleEntry | ScheduleEntryModel
  bookedEntry?: Booking
  logoUrl: string;
  onBookingModal: Function;
  isFree: boolean;
  locationId: string;
  isCarousel?: boolean;
}

export function ClassListItem({ isCarousel, logoUrl, isFree, entry, onBookingModal, locationId, bookedEntry }: ClassItem) {
  return (
    <div className={`${isCarousel ? "class-item-carousel" : "class-item"} d-flex position-relative`}>
        <div className="class-item__image position-relative">
          <img className="class-item__logo brand-logo position-absolute" src={logoUrl} />
          <img src={entry.classImage} alt={entry.title}/>
          {/* 
          TODO: probably we need to get rid of this 
          {entry.xpassCredits! < 11 && !isCarousel && <div className="class-item__great-price">
            <img src={FireIcon} alt="Great Price" />
            <p>GREAT PRICE</p>
          </div>} */}
          {isCarousel && entry instanceof ScheduleEntry && entry.userPremiumCost.raw !== 0 ? (
            <div className="class-item__premium-badge">Premium Class</div>
          ) : null}
        </div>
        <div className="class-item__content">
          <div className="class-item__info">
              <h3 className="class-item__info__title mb-1">{entry.title}</h3>
              <p className="class-item__info__date mb-1">{`${entry.startsAt.format("ddd, MMM D, h:mm A")}`}</p>
              <p className="class-item__info__details mb-0">{`${entry.duration} min`} <span>&#8226;</span> {entry.location!.name} {!isCarousel && entry.instructor!.name && `• ${entry.instructor!.name}`}</p>
              {!isCarousel && entry instanceof ScheduleEntry && entry.userPremiumCost.raw !== 0 ? (
                <div className="class-item__premium-badge premium-badge__list-item">Premium Class</div>
              ) : null}
          </div>
        
          <div className="class-item__book d-flex align-items-center">
            <div className="flex gap-1 align-items-center">
              <img src={tokenIcon} alt="token" />
              {entry instanceof ScheduleEntry && entry.userPremiumCost.raw  > 0 ? (
                <p className="class-item__points">&nbsp;+&nbsp;${entry.userPremiumCost.raw}{entry.isPremiumPaid && "*"}</p>
               ) : null}
            </div>
              {!entry.tooEarlyToBook ? (
                bookedEntry ? (
                  <button disabled className="xpass-cta">Booked</button>
                ) : (
                  <button onClick={() => onBookingModal(entry)} className="xpass-cta">{isFree ? "Book for free" : "Book"}</button>
                )
              ) : (
                <button disabled className="xpass-cta">Too Early</button>
              )

              }
          </div>
        </div>
          <Link
          className="class-item__link"
          to={`/book/${locationId}/class/${entry.id}`}
          />
          {!isCarousel && <div className="divider position-absolute"></div>}
    </div>
  )
}


export interface StudioItem {
    logoUrl: string;
    title: string;
    location: ScheduleEntryLocation;
    distance: number;
    toggleFavorite: Function
    rating?: number;
    numberOfRatings?: number;
    isFavoriteStudio: boolean;
    path: string;
    scheduleEntryStore?: ScheduleEntryStore;
  }

  interface Props extends StudioItem {
    isCarousel?: boolean;
  }

export const StudioListItem = observer(({ 
  isCarousel,
  logoUrl,
  title,
  location,
  distance,
  rating,
  numberOfRatings,
  isFavoriteStudio,
  path,
  scheduleEntryStore,
  toggleFavorite }: Props) => {

  const {city, imageUrl} = location
  let highlightedStudio

  if (scheduleEntryStore) {
    ({highlightedStudio} = scheduleEntryStore)
  }

  function handleStudioClick(location: ScheduleEntryLocation) {
    if (
      scheduleEntryStore!.highlightedStudio &&
      scheduleEntryStore!.highlightedStudio.slug === location.slug
    ) {
      // Deselect studio if highlighted studio card is re-clicked
      scheduleEntryStore!.setHighlightedStudio(undefined)
    } else {
      scheduleEntryStore!.setHighlightedStudio(location)
    }
  }
  return (
    <div className={`${isCarousel ? "studio-item-carousel" : "studio-item justify-content-between position-relative"} ${highlightedStudio && highlightedStudio.slug === location.slug ? "highlighted-studio" : ""} d-flex`}>
        <div className="studio-item__image position-relative">
          <img className="studio-item__logo brand-logo position-absolute" src={logoUrl} />
          <img src={imageUrl} alt={title} />
        </div>
        <div className={`studio-item__content d-flex justify-content-between ${isCarousel ? "flex-column" : "align-items-center"}`}>
        <div className="studio-item__info">
            <h3 className="studio-item__info__title mb-1">{title}</h3>
            <p className="studio-item__info__city mb-1">{city}</p>
            <p className="studio-item__info__distance mb-0">{`${distance} miles away`}</p>
            {!isCarousel && rating && (
              <div className="studio-item__info__rating d-flex">
                <img src={StarIcon} alt="rating star icon" className="rating-icon" />
                <div className="rating-data d-flex">
                    <p className="rating-data__number">{rating}</p>
                    <p className="rating-data__number-of-ratings">{`(${numberOfRatings})`}</p>
                </div>
              </div>
            )}
        </div>
        <div className="studio-item__view d-flex justify-content-between align-items-center">
            <button type="button" onClick={() => toggleFavorite(location.slug)} className="studio-item__like-btn">
                <img src={isFavoriteStudio ? FavoriteSelectedIcon : FavoriteUnselectedIcon} alt="favorite"/>
            </button>
            <Link to={path} className="xpass-cta">View</Link>
        </div>
        </div>
        {isCarousel ? <Link to={path} className="studio-item__link" /> : <a className="studio-item__link" href="javascript:void(0)" onClick={()=> handleStudioClick(location)} />}
        
        {!isCarousel && <div className="divider position-absolute"></div>}
    </div>
  )
})

interface ServiceEntryItemProps {
  store: BrandStore
  scheduleEntryStore: ScheduleEntryStore
  scheduleEntryLocationStore: ScheduleEntryLocationStore
  serviceTypesStore: ServiceTypesStore
  serviceLocation: ServiceLocation
}

export const AppointmentListItem = observer((props: ServiceEntryItemProps) => {
  const { highlightedStudio } = props.scheduleEntryStore
  const handleClick = (location: ServiceLocation) => {
    const { scheduleEntryStore, scheduleEntryLocationStore } = props
    const entry = scheduleEntryLocationStore.scheduleEntryLocations.filter(
      loc => loc.slug === location.id
    )
    if (
      scheduleEntryStore.highlightedStudio &&
      scheduleEntryStore.highlightedStudio.slug === location.id
    ) {
      // Deselect studio if highlighted services card is re-clicked
      scheduleEntryStore!.setHighlightedStudio(undefined)
    } else {
      scheduleEntryStore!.setHighlightedStudio(entry[0])
    }
  }

  return (
    <APILoader
      apiStore={props.serviceTypesStore}
      fetchProps={props.serviceLocation.id}
      elementSize="element"
      render={() => {
        const {
          id,
          brandId,
          name,
          earliestAvailability,
        } = props.serviceLocation
        const date = moment(earliestAvailability, "YYYY-MM-DDTh:mm").format(
          "YYYY-MM-DD"
        )
        return (
          <div className={`class-item appointment-item ${highlightedStudio && highlightedStudio.slug === props.serviceLocation.id ? "highlighted-studio" : ""}`}>
              <div className="appointment-item__details d-flex">
                <div className="appointment-item__image">
                  <img
                    src={props.scheduleEntryStore.getBrandIcon(brandId)}
                    alt={`${brandId}-icon`}
                  />
                </div>
                <div className="appointment-item__details-text d-flex flex-column justify-content-center ml-4">
                  <div className="appointment-item__title">
                    <strong>One-On-One Stretch</strong>
                  </div>
                  <p>
                    StretchLab <span>&#8226;</span> {name}
                  </p>
                  <p className="mt-4 mt-sm-2 mb-0">25 min or 50 min</p>
                </div>
              </div>
                <Link
                  className="xpass-cta"
                  to={`/book/${
                    props.store!.userStore.session!.locationId
                  }/location/${id}?date=${date}`}
                >
                  Availability
                </Link>
            <a
              className="appointment-item__link"
              onClick={() => handleClick(props.serviceLocation)}
            />
            <div className="divider position-absolute"></div>
          </div>
        )
      }}
    />
  )
})