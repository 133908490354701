import * as React from "react"

import BaseStore from "stores/BaseStore"
import BrandStore from "stores/BrandStore"
import LocationSummary from "models/LocationSummary"
import { ConfirmDialog } from "components/ConfirmDialog"

export default class CrossClubRoadblockStore extends BaseStore {
  constructor(private store: BrandStore) {
    super()
  }

  dispose() {
    this.store.uiStore.closeModal()
    super.dispose()
  }

  maybeShow = (bookingPath: string) => {
    const {
      uiStore,
      userStore,
      authStore,
      routingStore,
      locStore,
      copy,
    } = this.store
    const locationSummary = locStore.currentLocation!

    const isHomeStudio =
      userStore.session!.homeLocation.id === locationSummary.id

    const isRumbleSignature = locStore.currentLocation!.brandId === "rumble" &&
    (locStore.currentLocation!.locationType === "signature_training" ||
      locStore.currentLocation!.locationType === "signature_boxing")

    // TODO: for all the effort that went into it, I'm not sure if we
    // even want to be checking `isInBookingGroup` here. I don't think they
    // can buy packages except at their home studio anyway?
    // TODO: If we do end up checking this again, we'll have to figure out how
    // to make it work with service booking info, as well
    //  || booking.isInBookingGroup
    if (isHomeStudio || isRumbleSignature) {
      return
    }

    uiStore.openModal({
      title: "Attention",
      modalShow: true,
      size: "md",
      children: (
        <ConfirmDialog
          okLabel="Continue"
          cancelLabel="Cancel"
          okHandler={() => {
            authStore.signOut(locationSummary.id, true, bookingPath)
            uiStore.closeModal()
            this.store.track.event("cross club_tap logout", {
              loc: locationSummary,
            })
          }}
          cancelHandler={() => {
            // routingStore.goBack()
            uiStore.closeModal()
          }}
        >
          <Message locationSummary={locationSummary} classText={copy.class} />
        </ConfirmDialog>
      ),
    })
  }
}

interface MessageProps {
  locationSummary: LocationSummary
  classText: string
}
const Message: React.FunctionComponent<MessageProps> = ({
  locationSummary,
  classText,
}) => {
  const loc = locationSummary
  return (
    <div className="text-left px-4">
      <p>
        {`To continue booking this ${classText} you will be redirected to sign in to this studio:`}
      </p>
      <div className="text-left w-100 mx-auto">
        <h4>{`${loc.name}`}</h4>
        <div>
          {`${loc.address} ${loc.address2 ? loc.address2 : ""}`}
          <br />
          {`${loc.city} ${loc.state}, ${loc.zip}`}
        </div>
      </div>
    </div>
  )
}
