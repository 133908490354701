import { ServiceBookingReasonCode } from "apps/book/models/ServiceBookingReason"
import { ClassBookingReasonCode } from "models/BookingStatus"
import { BrandCopy } from "themes/base"
import { ucfirst } from "helpers/stringHelper"

export type BookingReasonCode =
  | ServiceBookingReasonCode
  | ClassBookingReasonCode
  | "booked"
  | "booking_failed"
  | "max_bookings_reached"
  | "max_bookings_blocked"
  | "cant_use_free_class"
  | "cant_create_brand_user_upstream"
  | "cant_create_brand_user_taken"
  | "cant_use_free_class_at_same_studio"
  | "points_mismatch"

/**
 * Used for the following statuses
 * - error
 * - unknown
 * - unauthorized
 * - eligibility
 * - invalid_time
 * - waitlist_locked
 * - booking_failed
 */
function defaultBookingError(classOrSessionName: string) {
  return {
    title: "Something went wrong",
    message: `We were unable to book you into this ${classOrSessionName}.`,
  }
}

export function reasonCodeToUserMessage(
  reasonCode: BookingReasonCode,
  classOrSessionName: string,
  copy: BrandCopy,
  message?: string,
) {
  switch (reasonCode) {
    case "booked":
      return {
        title: "Already Booked",
        message: `You are already booked into this ${classOrSessionName}.`,
      }
    case "instructor_booked":
      return {
        title: `${copy.instructor} Busy`,
        message: `The ${copy.instructor} is busy during that time.`,
      }
    case "user_booked":
      return {
        title: "User Booked",
        message: `The user account has another ${classOrSessionName} at that time.`,
      }
    case "open_spots":
    case "max_open_bookings":
    case "full":
      return {
        title: `${ucfirst(classOrSessionName)} Full`,
        message: `This ${classOrSessionName} is fully booked. Check back to see if spots open up.`,
      }
    // this should not be hit, because we should detect and redirect to a purchase flow
    case "no_available_credit":
    case "insufficient_credits":
    case "insufficient_points":
    case "credits":
      return {
        title: "Credits Required",
        message: `You need more credits to book this ${classOrSessionName}. Please buy credits and then try again.`,
      }
    case "token_missing":
      return {
        title: "Token Required",
        message: `You need to have at least one token to book this ${classOrSessionName}. Please buy a token and then try again.`,
        redirectLink: "/buy",
        ctaCopy: "Buy Tokens"
      }
    case "booking_lead_time":
      return {
        title: "Can't book at this time",
        message: `It is either too early or too late to book this ${classOrSessionName}.`,
      }
    case "member_frozen":
    case "member_late":
      return {
        title: "Account Frozen",
        message: "Please contact us about your account.",
      }
    case "early":
      return {
        title: "Too Early to Book",
        message: `Booking for this ${classOrSessionName} hasn't opened yet.`,
      }
    case "late":
      return {
        title: "Too Late to Book",
        message: `Booking for this ${classOrSessionName} has closed.`,
      }
    case "credit_expires_before_booking":
      return {
        title: "Credits Set to Expire",
        message: `Your applicable credits will be expired before the ${classOrSessionName} date.`,
      }
    case "max_bookings_reached":
      return {
        title: "Max Bookings Reached",
        message:
          "You have reached your maximum allowed bookings. No more can be scheduled at this time.",
      }
    // Specific response code for max booking feature
    case "max_bookings_blocked":
      return {
        title: "Booking limit reached!",
        message:
          "You have reached your maximum bookings.",
      }
    case "upstream_error_points":
      return {
        title: "Upstream Service Error (Unable to Fetch Points)",
        message:
          "Unable to fetch your points at the moment. Please try again later.",
      }
    case "cant_use_free_class":
      return {
        title: "Free Class Not Eligible",
        message: "This class cannot be booked for free.",
      }
    case "cant_use_free_class_at_same_studio":
      return {
        title: "Free Class Not Eligible",
        message: "Unable to book. Free class credits may be used only once per studio.",
      }
    case "cant_create_brand_user_upstream":
      return {
        title: "Account Creation Failed",
        message: "Could not create an account for the studio. Please try again."
      }
    case "cant_create_brand_user_taken":
      return {
        title: "Account Creation Failed",
        message: "Could not create an account for the studio. Please claim the account and try again.",
        redirectLink: "/account/claim",
        ctaCopy: "Claim Account"
      }
    case "vip_early":
      return {
        title: "Too Early to Book",
        message: message,
        ctaCopy: "OK"
      }
    case "vip_early_waitlist":
      return {
        title: "Too Early to Waitlist",
        message: message,
        ctaCopy: "OK"
      }
    case "vip_early_credits":
      return {
        title: "Too Early to Book",
        message: message,
        ctaCopy: "OK"
      }
    case "vip_early_credits_waitlist":
      return {
        title: "Too Early to Waitlist",
        message: message,
        ctaCopy: "OK"
      }
    case "points_mismatch":
      return {
        title: "Class points have changed",
        message: message,
        redirectLink: "/buy",
        ctaCopy: "Buy"
      }

    default:
      return defaultBookingError(classOrSessionName)
  }
}
