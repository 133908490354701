import * as React from "react"
import { observer, inject } from "mobx-react"
import { Link } from "react-router-dom"
import cx from "classnames"
import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import FavoritesStore from "apps/account/stores/FavoritesStore"
import LocationSummary from "models/LocationSummary"
import FavoriteHeart from "components/FavoriteHeart"

interface Props {
  store?: BrandStore
  className?: string
  favoriteLocationsStore: FavoritesStore<typeof LocationSummary>
  getBrandIcon: Function
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class MyStudioCard extends React.Component<Props> {
  render() {
    const { className, store } = this.props
    const slugBrandName = {
      akt: "AKT",
      clubpilates: "Club Pilates",
      cyclebar: "CycleBar",
      purebarre: "Pure Barre",
      rowhosue: "Row House",
      stretchlab: "StretchLab",
      stride: "Stride Fitness",
      yogasix: "YogaSix",
      kinrgy: "KINRGY",
    }

    return (
      <div className={cx("card my-studio-card", className)}>
        <div className="card-header">
          <h4 className="card-title" style={{ textTransform: "capitalize" }}>
            My Favorite Studios
          </h4>
        </div>
        <APILoader
          key={this.props.favoriteLocationsStore.renderKey}
          apiStore={this.props.favoriteLocationsStore}
          elementSize="element"
          propagateErrors
          render={() => {
            const locations = this.props.favoriteLocationsStore.favorites.filter(
              item => item.brandId !== "xponential"
            )
            const favoriteLocations = locations.slice(0, 3)

            return (
              <>
                <span className="border-top border-light" />
                {favoriteLocations &&
                  (favoriteLocations.length > 0 ? (
                    <div className="my-studio-card__container">
                      {favoriteLocations.map((location, i) => (
                        <div
                          key={i}
                          className="d-flex border border-light rounded my-studio-card__location"
                        >
                          <img
                            src={this.props.getBrandIcon(location.brandId)}
                          />
                          <div className="d-flex align-items-center w-100">
                            <h4 className="card-text mb-0">
                              {slugBrandName[location.brandId]} {location.name}
                            </h4>
                            <div className="ml-2 mt-1">
                              <FavoriteHeart
                                type="location"
                                id={location.id}
                                width="16"
                                height="16"
                                setRenderKey={() =>
                                  this.props.favoriteLocationsStore.setRenderKey()
                                }
                              />
                            </div>
                          </div>
                          <Link
                            className="btn btn-primary"
                            to={`/book/${
                              store!.userStore.session!.locationId
                            }/location/${location.id}`}
                          >
                            <span>Book</span>
                          </Link>
                        </div>
                      ))}

                      {/* If more than 3 favorite locations, add link to Book a Class page Favorites tab */}
                      {locations.length > 3 && (
                        <div className="d-flex flex-column grid-in">
                          <Link
                            className="btn btn-primary"
                            to={`/book/${
                              store!.userStore.session!.locationId
                            }?active=favorites`}
                          >
                            More...
                          </Link>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className="card-body flex-grow-1 d-flex flex-column grid-in">
                        <p>You haven’t added any favorite studios.</p>
                        <Link
                          className="btn btn-primary mt-5 mt-md-auto mb-0"
                          to={`/book/${store!.userStore.session!.locationId}`}
                          // TODO: Amplitude click event
                          // onClick={this.handleBookClick}
                        >
                          Add Favorites
                        </Link>
                      </div>
                    </>
                  ))}
              </>
            )
          }}
        />
      </div>
    )
  }
}
