import { FormikBag } from "formik"
import UserRegistration from "apps/auth/models/UserRegistration"
import XpassUserRegistration from "apps/auth/models/xpass/XpassUserRegistration"
import BrandStore from "stores/BrandStore"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"
import { withAxiosErrorMessage } from "helpers/errorHandling"
import { setFriendlyRoute, peekFriendlyRoute, popFriendlyRoute } from "services/friendlyRoute"
import { loadState, clearState } from "services/savedState"

export default function handleRegistrationSubmit(
  isProspect: boolean,
  values: UserRegistration | XpassUserRegistration,
  formikBag: FormikBag<{ store?: BrandStore, userAuthStore?: UserAuthStore }, UserRegistration | XpassUserRegistration>,
  userIds?: Array<number>,
): void {
  const { authStore, uiStore, locStore, track, isXponential } = formikBag.props.store!
  const { brandLocationId, mobileOauth, mobileBrand, mobilePlatform } = formikBag.props.userAuthStore!
  const loc = locStore.currentLocation
  const vodOfferFlow: boolean = loadState("vodOfferFlow", true)
  const type = isXponential ? new XpassUserRegistration() : new UserRegistration()
  const data = { is_prospect: isProspect }
  const redirectParams = {
    location_id: brandLocationId,
    mobile_oauth: mobileOauth,
    mobile_brand: mobileBrand,
    mobile_platform: mobilePlatform,
  }

  if (userIds) {
    data["user_ids"] = userIds
  }

  // If the user is here to buy a VOD package, send them to that flow
  if (vodOfferFlow && formikBag.props.store!.vodPurchaseFlowEnabled()) {
    setFriendlyRoute("/vod/packages")
    clearState("vodOfferFlow", true)
  }

  // If user registers for XPASS account, send them to offers page to redeem free class package offer
  if (isXponential) {
    const packageRedirectRoute = peekFriendlyRoute().startsWith('/buy/xponential-xpass/packages')
    ? `?goTo=${popFriendlyRoute()}`
    : ''
    setFriendlyRoute(`/redeem-offer${packageRedirectRoute}`)
    clearState("xpassRegister", true)
  }

  authStore
    .register(Object.assign(type, values), data, redirectParams)
    .then(res => {
      formikBag.setSubmitting(false)

      if (res.data.errors) {
        formikBag.setErrors(res.data.errors)
        track.event("authentication_failure", { loc }, { cta: "register" })
      } else {
        track.event("authentication_success", { loc }, { cta: "register" })

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "register_submission",
          location: loc && loc.id || '',
        });
      }

      const { redirectLink } = formikBag.props.userAuthStore!
      if (redirectLink) {
        window.location.href = redirectLink + res.data.user.accessToken
      }

      return res
    })
    .catch(ex => {
      formikBag.setSubmitting(false)

      const response = withAxiosErrorMessage(ex)
      if (response && response.data.code === "service_conflict") {
        uiStore.openModal({
          type: "error",
          title: "Something went wrong",
          message: response.data.message,
          modalShow: true,
          locationSummary: loc,
        })
        track.event("authentication_failure", { loc }, { cta: "register" })
      } else {
        uiStore.openError(ex)
        track.event("authentication_failure", { loc }, { cta: "register" })
        throw ex
      }
    })
  track.event("sign waiver_tap finish", { loc }, { cta: "register" })
}
