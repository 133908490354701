import * as React from "react"

// TODO: kill this once new react-bootstrap released
// hackaround until https://github.com/react-bootstrap/react-bootstrap/pull/3507
type TagName = keyof JSX.IntrinsicElements

export type Props<T extends TagName> = JSX.IntrinsicElements[T] & {
  bsPrefix?: string
}

// this just takes a tag name and returns a component that strips out
// the bsPrefix prop and forwards everything else to the tag
const eatBsPrefix = <T extends TagName>(tagName: T) => {
  const eater: React.SFC<Props<T>> = ({ bsPrefix, children, ...props }, ref) =>
    React.createElement(tagName, { ...props, ref }, children)

  return React.forwardRef(eater)
}

export default eatBsPrefix
