import * as React from "react"
import Alert, { AlertProps } from "components/Alert"
import capitalize from "lodash/capitalize"

interface Props extends AlertProps {
  // messages?: string[]
  message: React.ReactNode | string | undefined
  dismissible?: boolean
  classNames?: string
}

const FormAlert: React.SFC<Props> = ({
  message,
  dismissible = true,
  classNames,
  ...props
}) => {
  if (!message) return null

  if (typeof message === "string" && message.match(/^Base/)) {
    message = capitalize(message.replace(/^Base /, ""))
  }

  return (
    <Alert className={`text-sm ${classNames}`} dismissible={dismissible} {...props}>
      {message}
    </Alert>
  )
}

export default FormAlert
