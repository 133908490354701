import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action, computed } from "mobx"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import BrandStore from "stores/BrandStore"
import MobilePromo from "models/MobilePromo"

type Response = {
  promo_enabled: boolean
  promo: MobilePromo
}
export default class MobileOfferStore extends APIStore {
  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("promo", MobilePromo),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  @observable mobilePromo?: MobilePromo
  @observable promoEnabled?: boolean

  constructor(public brandStore: BrandStore) {
    super()
  }

  fetch() {
    return this.api.get(`/api/mobile_offers`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response>) {
    this.promoEnabled = res.data.promo_enabled
    this.mobilePromo = res.data.promo

    return res
  }
}
