import { JsonObject, JsonProperty } from "json2typescript"
import ClassTypeSummary from "models/ClassTypeSummary"

@JsonObject("ClassType")
export default class ClassType extends ClassTypeSummary {
  /**
   * Globally unique ID of the class type.
   */
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * Description of the class type.
   */
  @JsonProperty("description", String, true)
  description?: string = undefined!

  /**
   * If classes are listed out, where it should appear in the list. Lower numbers come first.
   *
   */
  @JsonProperty("seq", Number)
  seq: number = undefined!
}
