import * as React from "react"
import cx from "classnames"

interface HeadingProps {
  children?: React.ReactNode
  className?: string
  center?: boolean
  noFade?: boolean
  level?: 1 | 2 | 3 | 4 | 5 | 6
}

const Heading = ({
  className,
  center,
  level,
  noFade,
  ...props
}: HeadingProps) =>
  React.createElement(`h${level || 1}`, {
    ...props,
    className: cx(className, "mt-3 mb-2", {
      "text-sm-center": center,
      "slide-in": !noFade,
    }),
  })

export default Heading
