import * as React from "react"
import { Provider, observer } from "mobx-react"
import { Link, Switch, Router, Route, Redirect } from "react-router-dom"
import { createBrowserHistory } from "history"
import * as Sentry from "@sentry/browser"
import Axios from "axios"

import { deserialize } from "helpers/serializationHelpers"

import Brand from "models/Brand"
import RoutingStore from "stores/RoutingStore"
import BrandStore from "stores/BrandStore"
import UIStore from "stores/UIStore"

import RestrictedLocationRoute from "components/wrappers/RestrictedLocationRoute"
import MainMenu from "components/MainMenu"
import SecondaryMainMenu from "components/SecondaryMainMenu"
import UIModal from "components/UIModal"

import BookingFlowController from "apps/book/components/BookingFlowController"
import ErrorBoundary from "components/wrappers/ErrorBoundary"

import ContainedRoutes from "components/ContainedRoutes"

const browserHistory = createBrowserHistory()
const uiStore = new UIStore()
const routingStore = new RoutingStore(browserHistory)

export default class App extends React.Component<{}, {}> {
  brand = deserialize(window.globals.brand, Brand)
  store = new BrandStore(this.brand, routingStore, uiStore)

  componentWillMount() {
    window.addEventListener("unhandledrejection", this.handleRejectedPromise)
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.handleRejectedPromise)
    this.store.dispose()
  }

  handleRejectedPromise = (e: PromiseRejectionEvent) => {
    if (Axios.isCancel(e.reason)) {
      if (debugUI) {
        console.warn(e.reason)
      }

      e.preventDefault()
    } else {
      Sentry.captureException(e.reason)
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <Provider {...this.store}>
          <Router history={browserHistory}>
            <AppInner store={this.store} />
          </Router>
        </Provider>
      </ErrorBoundary>
    )
  }
}

export interface AppInnerProps {
  store: BrandStore
}

@observer
export class AppInner extends React.Component<AppInnerProps, {}> {
  public render() {
    const { query } = this.props.store!.routingStore
    const eywRedirect = query.eyw === "true"
    const subBrand = query.brand
    const brandLocationId = query.brand_location_id
    const brandRedirect = query.redirect === "true"
    const isSingleCheckout = location.pathname.includes("/buy/xponential-xpass/checkout")
    const isSimpleCheckout = location.pathname.includes("/simple-checkout") && this.props.store.isXponential
      || (this.props.store.isXponential && location.pathname.includes("/simple-checkout"))
    const isXpassLP = location.pathname.includes("/xpassapp")
    const isMemberPlatformLive = this.props.store!.userStore.isMemberPlatformLive

    return (
      <>

       <div className="main-content-flex">
        <ErrorBoundary>
          {!isXpassLP && <MainMenu isSingleCheckout={isSingleCheckout} isSimpleCheckout={isSimpleCheckout} /> }
          {!window.globals.xpassPhase3Disabled && <SecondaryMainMenu store={this.props.store!} />}
        </ErrorBoundary>
        <ErrorBoundary>
          <Switch>
            <RestrictedLocationRoute
              path="/book"
              locationInPath
              ignoreStoredLocation
              render={props =>
                isMemberPlatformLive && !this.props.store.isXponential
                ? (<Redirect to="/indigo" />)
                : (<BookingFlowController subBrand={subBrand} {...props} />)
              }
            />

            <Route
              render={props => (
                <ContainedRoutes
                  store={this.props.store}
                  eywRedirect={eywRedirect}
                  subBrand={subBrand}
                  brandLocationId={brandLocationId}
                  brandRedirect={brandRedirect}
                  {...props}
                />
              )}
            />
          </Switch>
        </ErrorBoundary>

        {!isSingleCheckout &&
          <div className="footer fixed-bottom">
            <footer className="container">
              <div className="footer-container d-flex flex-column-reverse flex-md-row align-items-center justify-content-between py-2 justify-content-lg-start">
                {process.env.SHOW_STYLEGUIDE === "1" && (
                  <Link className="mr-lg-4 strong" to="/drydock">
                    Styleguide
                  </Link>
                )}
                {this.props.store.isXponential || isXpassLP ? (
                  <span>
                    &copy;{new Date().getFullYear()} Xponential
                  </span>
                ) : (
                  <a
                    className="text-nowrap"
                    href={this.props.store.brand.siteUrl}
                    target="_blank"
                  >
                    &copy;{new Date().getFullYear()} {this.props.store.brand.name}
                  </a>
                )}
                <div className="footer__links">
                  {this.props.store.brandData.includeTerms !== false && (
                    <a
                      className="ml-lg-4 text-nowrap"
                      href={
                        this.props.store.isXponential || isXpassLP
                          ? "https://www.xponential.com/terms-of-use"
                          : `${this.props.store.brand.siteUrl}/terms`
                      }
                      target="_blank"
                    >
                      Terms of Service
                    </a>
                  )}

                  <a
                    className="ml-lg-4 text-nowrap"
                    href={
                      this.props.store.isXponential || isXpassLP
                        ? "https://www.xponential.com/privacy-policy"
                        : `${this.props.store.brand.siteUrl}/privacy`
                    }
                    target="_blank"
                  >
                    Privacy Policy
                  </a>

                  {!this.props.store.isXponential && this.props.store.settings.showClassPoints && (
                    <a
                      className="ml-lg-4 text-nowrap"
                      href="https://xpass.app.link/e/classpointssupport"
                      target="_blank"
                    >
                      ClassPoints™ Support
                    </a>
                  )}

                  {(this.props.store.isXponential || isXpassLP) && (
                    <a
                      className="ml-lg-4 text-nowrap"
                      href={"https://help.xpass.fit"}
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  )}
                </div>
              </div>
            </footer>
          </div>
        }
           </div>
        <ModalContainer store={this.props.store} />
      </>
    )
  }
}

const ModalContainer = observer((({ store }) => (
  <div id="main-app-modal" className="">
    <UIModal
      {...store.uiStore.modal}
      store={store}
      onClose={store.uiStore.closeModal}
    />
  </div>
)) as React.FunctionComponent<{ store: BrandStore }>)
