import React from "react"
import BrandStore from "stores/BrandStore"
import { inject, observer } from "mobx-react"

interface Props {
  store?: BrandStore
  forceShow?: boolean
}

const BackToBrandSite: React.FunctionComponent<Props> = ({
  store,
  forceShow,
}) => {
  // not sure why I did this but forceShow will override it
  if (store!.uiStore.nav.navLinksShow && !forceShow) {
    return null
  }

  // ugh javascript you suck
  const url =
    store!.locStore.currentLocation && store!.locStore.currentLocation.siteUrl
      ? store!.locStore.currentLocation.siteUrl
      : store!.brand.siteUrl

  return (
    <small className="mb-2">
      <a className="pr-3 text-decoration-none text-dark" href={url}>
        &#8592; Back to {store!.brand.name}
      </a>
    </small>
  )
}

export default inject((store: BrandStore) => ({ store }))(
  observer(BackToBrandSite)
)
