import BaseStore from "stores/BaseStore"
import { QueryString } from "helpers/queryString"
import { observable, action } from "mobx"
import BrandStore from "stores/BrandStore"
import ExpandedEntryStore from "./ExpandedEntryStore"
import { saveState } from "services/savedState"

export const LATEST_BOOKING_PATH_KEY = "latestBookingPath"

/**
 * This might be a ScheduleStore for classes or a ServiceScheduleStore
 */
interface GenericScheduleStore {
  filters: Record<string, string | undefined>
  status: string
  setDate(date: string): void
}

/**
 * Monitor search params on a class or service schedule page to control
 * filters and some other behavior related to user nav
 */
export default class QueryStringMonitor extends BaseStore {
  @observable expandedEntryId: string = ""
  private bookingPath?: string = undefined

  constructor(
    private scheduleStore: GenericScheduleStore,
    brandStore: BrandStore,
    private expandedEntryStore?: ExpandedEntryStore
  ) {
    super()

    this.autorun(() => {
      this.handleChange(
        brandStore.routingStore.query,
        // this param does nothing, it is just to force scheduleStore.status to
        // be observed, so that the description opens for the first page loaded
        scheduleStore.status
      )
    })
  }

  /**
   * Some path info changed. Update the schedule store's filters,
   * store this as the last schedule page viewed, and expand the
   * details on the first schedule entry where applicable
   */
  @action.bound
  handleChange(query: QueryString, _status?: string) {
    this.saveCurrentPage()

    this.scheduleStore.setDate(query.date || "")
    Object.keys(this.scheduleStore.filters).forEach(key => {
      this.scheduleStore.filters[key] = query[key] || ""
    })

    if (this.expandedEntryStore) {
      this.expandedEntryStore.openFirst()
    }
  }

  /**
   * Store info about the last schedule page viewed (with search details)
   * so we can send the user back here if they cancel a booking in progress
   */
  @action.bound
  saveCurrentPage() {
    const { pathname, search } = location
    if (!this.bookingPath) this.bookingPath = pathname

    if (pathname === this.bookingPath) {
      saveState(LATEST_BOOKING_PATH_KEY, { pathname, search }, true)
    }
  }
}
