import React from 'react'
// stores
import ScheduleEntryStore from 'apps/book/stores/xpass/ScheduleEntryStore';
import ScheduleEntryLocationStore from 'apps/book/stores/xpass/ScheduleEntryLocationStore';
import FiltersStore from 'apps/book/stores/xpass/FiltersStore';
// components
import { DateFilter } from './FiltersModal';
import SwiperButton from './SwiperButton';
import Svg from 'components/Svg'
// utils
import * as cx from "classnames"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Player } from '@lottiefiles/react-lottie-player';

const LINKS = [
  {
    label: "Featured",
    key: "featured",
    iconName: "featuredIcon",
    iconColor: "#FFD100"
  },
  {
    label: "Value",
    key: "value",
    iconName: "valueIcon",
    iconColor: "#608E46"
  },
  {
    label: "Favorites",
    key: "favorites",
    iconName: "favoritesIcon",
    iconColor: "#EB80A8"

  },
  {
    label: "Stretch",
    key: "stretch",
    iconName: "stretchIcon",
    iconColor: "#5E75B9"
  },
  {
    label: "Studios",
    key: "studios",
    iconName: "studiosIcon",
    iconColor: "#FF671D"
  },
  {
    label: "Now",
    key: "now",
    iconName: "nowIcon",
    iconColor: "#EB80A8"
  },
]

interface NavItem {
  label: string;
  isSelected: boolean;
  itemKey: string;
  iconName: string;
  iconColor?: string;
  handleClick: Function;
  isAnimation?: boolean;
}

const NavItem = ({label, iconName, iconColor, itemKey, isSelected, handleClick, isAnimation}: NavItem) => {
  return (
    <li
    className={cx(
      "subnav-link",
      isSelected ? "active" : "",
      "d-flex",
      "flex-column",
      "align-items-center",
      "p-2",
      "text-nowrap",
      "text-decoration-none"
    )}
    key={label}
    onClick={() => handleClick(itemKey)}
    >{isAnimation ? (
      <Player
        autoplay
        loop
        src={require(`images/animations/xpass-submenu/${itemKey}.json`)}
        style={{ height: '32px', width: '32px' }}
      />
    ) :
      <Svg name={isSelected ? iconName : `${iconName}Black`} size={32} />}
      <p className="subnav-copy m-0 position-relative">
        {label}
      <span style={isSelected && iconColor ? {backgroundColor: iconColor, height: "2px", width: "100%" } : {}} className="subnav__link-underline d-block"></span>
      </p>
    </li>
)}

interface SubNavProps {
  activeTab: string;
  isBrandsActive: boolean;
  selectedBrands: string[];
  showDateFilter: boolean;
  handleActiveTab: Function;
  handleActiveBrand: Function;
  filtersStore: FiltersStore;
  scheduleEntryStore: ScheduleEntryStore;
  scheduleEntryStoreHot: ScheduleEntryStore;
  scheduleEntryLocationStore: ScheduleEntryLocationStore;
  filterEntries: Function;
  dateFormat: string;
}

export default function BookClassSubNav({
  activeTab,
  handleActiveTab,
  isBrandsActive,
  selectedBrands,
  showDateFilter,
  handleActiveBrand,
  filtersStore,
  scheduleEntryLocationStore,
  scheduleEntryStore,
  scheduleEntryStoreHot,
  filterEntries,
  dateFormat
}: SubNavProps) {

  return (
    <div className="book-class-subnav">
      <div className="book-class-subnav__container container d-flex">
      <ul className="book-class-subnav__section-links d-flex">
        {LINKS.map((link) => {
          const isSelected = activeTab === link.key
          return (
            <NavItem {...link} itemKey={link.key} isAnimation isSelected={isSelected} handleClick={handleActiveTab} />
          )
        })}
      </ul>
      <ul className={`book-class-subnav__section-brands w-100 overflow-hidden d-flex ${isBrandsActive ? "" : "brands-nav__inactive"}`}>
        <Swiper
            slidesPerView="auto"
            className="swiper-gradient position-relative"
          >
          <SwiperButton isNav direction="prev" />
          <SwiperButton isNav direction="next"/>
          {filtersStore.brands.map((link) => (
              <SwiperSlide key={link.id}>
                <NavItem
                  label={link.name}
                  iconName={link.navIcon}
                  itemKey={link.id}
                  isSelected={selectedBrands.includes(link.id)}
                  handleClick={() => handleActiveBrand(link.id)}
                  iconColor={link.iconColor}
                />
              </SwiperSlide>
            )
          )}
        </Swiper>
      </ul>
      {showDateFilter &&
      <DateFilter
        scheduleEntryStore={scheduleEntryStore}
        scheduleEntryStoreHot={scheduleEntryStoreHot}
        scheduleEntryLocationStore={scheduleEntryLocationStore}
        filterEntries={filterEntries}
        dateFormat={dateFormat}
      />}
      </div>
    </div>
  )
}
