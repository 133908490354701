import React from "react"
import cx from "classnames"

import Heading from "components/Heading"
import BrandStore from "stores/BrandStore"

interface Props {
  store?: BrandStore
  onConfirm(e: ButtonEvent): void
  onCancel?(e: ButtonEvent): void
}

/**
 * Intermediary "confirm" page for users to agree to COVID-19 precautions
 * prior to booking a class
 */
export default class HealthCheck extends React.Component<Props> {
  render() {
    const { isXponential, styleClasses } = this.props.store!
    const title = isXponential ? "COVID-19 Health Precautions" : "Health Check"

    return (
      <div className="health-check">
        {!isXponential && <Heading center>{title}</Heading>}
        <div
          className={cx(
            isXponential
              ? "booking-modal"
              : "col-md-8 col-lg-6 col-xl-4 mx-auto"
          )}
        >
          <div
            className={cx(
              "card-in delay-3",
              styleClasses.HealthCheck__card,
            )}
          >
            <div className="card">
              <div
                className={cx(
                  "card-body p-4",
                  styleClasses.HealthCheck__cardBody,
                )}
              >
                <h3 className="mb-0">COVID-19 Health Precautions</h3>

                <p className="my-4">
                  The well-being of our team and members is of top priority.
                  Please acknowledge that you are healthy, display no signs of
                  COVID-19, and will maintain proper precautions while in the
                  studio.
                </p>

                <div className={cx("text-center", styleClasses.HealthCheck__cardButton)}>
                  <button
                    className={cx(
                      "btn btn-primary",
                      isXponential ? "d-block mx-auto" : ""
                    )}
                    onClick={this.props.onConfirm}
                  >
                    I Agree
                  </button>
                  {this.props.onCancel && (
                    <button
                      className="btn btn-link"
                      onClick={this.props.onCancel}
                    >
                      <small className="text-muted">Cancel</small>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
