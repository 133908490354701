import * as React from "react"
import * as cx from "classnames"
import { action, observable } from "mobx"
import { observer } from "mobx-react"

export interface AlertProps {
  color?: string
  className?: string
  dismissible?: boolean
}

@observer
export default class Alert extends React.Component<AlertProps, {}> {
  static defaultProps = {
    color: undefined,
    className: undefined,
    dismissible: true,
  }
  @observable open = true

  @action.bound
  handleClose(e: ButtonEvent) {
    e.preventDefault()
    this.open = false
  }

  public render() {
    if (!this.open) return null

    const { className, children } = this.props
    const color = this.props.color || "danger"
    return (
      <div
        className={cx(
          "alert",
          `alert-${color}`,
          "alert-dismissible",
          className
        )}
        role="alert"
      >
        {children}
        {this.props.dismissible && (
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
    )
  }
}
