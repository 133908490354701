import { observable, action } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import LocationWaiver from "apps/book/models/xpass/LocationWaiver"
import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export default class WaiverStore extends APIStore {
  @observable locationWaiver?: LocationWaiver

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("waivers", LocationWaiver),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(public brandStore: BrandStore) {
    super()
  }

  fetch(locationId: string) {
    const url = `/api/xpass/waivers/${locationId}`
    return this.api.get(url)
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.locationWaiver = res.data.waivers
    return res
  }

  @action.bound
  handleError(err: AxiosError) {
    console.error(err)
    throw err
  }
}
