import * as React from "react"
import cx from "classnames"
import { Field, ErrorMessage, FieldProps, FormikProps } from "formik"
import helpIcon from "images/icons/help.svg"

export interface InnerProps {
  id: string
  className: string
  field: FieldProps["field"]
  form: FormikProps<{}>
  isInvalid: boolean
}

interface Props {
  name: string
  className?: string
  label?: string
  tooltipId?: string
  children: (props: InnerProps) => React.ReactNode
  inputProps?: any
}

const FormControl: React.SFC<Props> = ({
  name,
  label,
  children,
  className,
  tooltipId,
  inputProps
}) => {
  // hopefully this is globally unique!
  const id = `form-input-${name}`
  const tooltipIcon = tooltipId ? <span className="p-1" data-tip data-for={tooltipId}><img src={helpIcon} /></span> : null

  return (
    <Field name={name}>
      {({ form, field }: FieldProps) => {
        const isInvalid = !!form.errors[name]
        const inputClassName = cx({ "is-invalid": !!isInvalid })
        const disabled = inputProps && inputProps.disabled

        return (
          <div className={cx("form-group", className, { "disabled": disabled })}>
            {label && (
              <label
                htmlFor={id}
                className={cx(inputClassName, { "disabled": disabled })}
                style={{ display: "inline" }}
              >
                {label}
              </label>
            )}

            {tooltipIcon}

            {children({
              field,
              id,
              form,
              className: cx(inputClassName, "form-control"),
              isInvalid,
            })}
            {isInvalid && (
              <ErrorMessage
                component="div"
                name={name}
                className="invalid-feedback d-block"
              />
            )}
          </div>
        )
      }}
    </Field>
  )
}

export default FormControl
