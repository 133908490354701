import { JsonObject, JsonProperty } from "json2typescript"
import ClassStatsBase from "apps/history/models/ClassStatBase"

@JsonObject("RowingClass")
export default class RowingClass extends ClassStatsBase {
  type: "rowing" = "rowing"

  /**
   * Unsure
   */
  @JsonProperty("avg_rpm", Number)
  avgRpm: number = undefined!

  /**
   * Unsure
   */
  @JsonProperty("high_rpm", Number)
  highRpm: number = undefined!

  @JsonProperty("avg_power", Number)
  avgPower: number = undefined!

  @JsonProperty("high_power", Number)
  highPower: number = undefined!

  /**
   * This appears to correspond to "average split time" in seconds.
   */
  @JsonProperty("avg_pace", Number)
  avgPace: number = undefined!

  /**
   * This appears to correspond to "best split time" in seconds.
   */
  @JsonProperty("high_pace", Number)
  highPace: number = undefined!

  @JsonProperty("total_energy", Number)
  totalEnergy: number = undefined!

  /**
   * Total distance rowed, in meters.
   */
  @JsonProperty("distance", Number)
  distance: number = undefined!

  // TODO: Jun-03-2019 It doesn't seem to come in the response anymore. Confirm if true and remove.
  // @JsonProperty("power_iq", Number)
  // powerIq: number = undefined!

  @JsonProperty("calories", Number)
  ftp: number = undefined!
}
