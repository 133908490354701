import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"
import Balance from "apps/buy/models/xpass/Balances"
import XpassBooking from "models/xpass/XpassBooking"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export default class BalancesStore extends APIStore {
  @observable balances?: Balance
  @observable freeClassesPurchased: boolean = false
  @observable freeClassBookings: XpassBooking[] = []
  @observable bannerMsg?: string

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("balances", Balance),
    DeserializeMiddleware("free_class_bookings", XpassBooking),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  @computed get bannerMessage() {
    const { freeClassesPurchased, balances, bannerMsg } = this
    const freeClasses = (balances && balances.freeClasses) || 0
    const showBanner = !!bannerMsg || (freeClassesPurchased && freeClasses > 0)
    if (!showBanner) return

    const freeClassesText = `You have ${freeClasses} free ${freeClasses === 1 ? "class" : "classes"} remaining on your account. Try something new today!`
    return bannerMsg || freeClassesText
  }

  constructor(protected brandStore: BrandStore) {
    super()
  }

  fetch() {
    const url = '/api/xpass/v2/balances'
    return this.api.get(url)
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.balances = res.data.balances
    this.freeClassesPurchased = res.data.free_classes_purchased
    this.freeClassBookings = res.data.free_class_bookings
    this.bannerMsg = res.data.banner_msg
    return res
  }

  @action.bound
  handleError(err: AxiosError) {
    console.error(err)
    throw err
  }
}
