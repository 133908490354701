import { JsonObject, JsonProperty, Any } from "json2typescript"

type CurrencyCode = "USD" | "CAD"

@JsonObject("Price")
export default class Price {
  /**
   * Price formatted for display: "$00.00"
   *
   * example:
   * $20.00
   */
  @JsonProperty("formatted", String)
  formatted: string = undefined!

  /**
   * Price as a number. In dollars, b/c that's the way we get them, but it's a
   * float and can lose precision depending on your language/JSON deserializer.
   *
   * Hint: you may want to add a convenience method along the lines of "isZero"
   * to your Price model, as these often require special display logic.
   *
   */
  @JsonProperty("numeric", Number)
  numeric: number = undefined!

  /**
   * Currency code of price. Enum is for reference, could take on other values
   * if we add other countries.
   *
   */
  @JsonProperty("currency_code", String, true)
  currencyCode?: CurrencyCode = undefined

  /**
   * The raw price as provided by the underlying API. The main use of this is
   * distinguish prices that are null/missing from those that are actually 0 (as
   * they'll both come back as $0.00.) This is a hack to deal with the fact that
   * most of the places price is used it's required/non-nullable, even though it
   * CAN come back `null` from ClubReady (in which case it will come back as
   * zero.) So this property lets you tell the difference.
   */
  @JsonProperty("raw", Any, true)
  raw?: unknown = undefined!

  static fromCents(cents: number, currency?: CurrencyCode): Price {
    const p = new Price()
    p.currencyCode = currency
    p.numeric = cents / 100
    p.formatted = "$" + p.numeric.toFixed(2)
    return p
  }

  get cents(): number {
    const dollars = this.numeric || 0
    return Math.round(dollars * 100)
  }
}
