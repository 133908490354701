import React, { Component } from "react"
import { RouteComponentProps } from "react-router"
import { observer, inject } from "mobx-react"
import * as cx from "classnames"
import getIn from "lodash/get"

import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import InstructorDetailStore from "./stores/InstructorDetailStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import ScheduleStore from "apps/book/stores/ScheduleStore"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import BookingsStore from "apps/bookings/stores/BookingsStore"
import DetailsPageMap from "apps/xpass/DetailsPageMap"
import LocationDetailsSchedule from "apps/xpass/LocationDetailsSchedule"
import InstructorDefault from "images/xpass/icons/instructor_default.png"
import Spinner from "components/Spinner"

export interface Props
  extends RouteComponentProps<{ locationId: string; instructorId: string }> {
  store?: BrandStore
  onBookingModal: Function
  locationId?: string
  scheduleStore: ScheduleStore
  scheduleEntryStore: ScheduleEntryStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class InstructorDetails extends Component<Props> {
  instructorDetailStore = new InstructorDetailStore()
  balancesStore = new BalancesStore(this.props.store!)
  bookingsStore = new BookingsStore(this.props.store!)
  instructorId = this.props.match.params.instructorId!
  param = new URLSearchParams(window.location.search)
  locationId = this.param.get("location_id")

  componentWillMount() {
    this.balancesStore.fetch()
    this.bookingsStore.fetch()

    this.instructorDetailStore.fetch(this.instructorId).catch(err => {
      this.props.scheduleStore.status = "error"
      this.props.history.push("/error")
      throw err
    })
  }

  componentWillUnmount() {
    this.balancesStore.dispose()
    this.bookingsStore.dispose()
  }

  render() {
    const { store, scheduleStore } = this.props
    const { bookedBookings } = this.bookingsStore
    const {
      freeClassesPurchased,
      freeClassBookings,
      balances,
    } = this.balancesStore!
    const userBalances = { freeClassesPurchased, freeClassBookings, balances }

    const { upcomingClasses, instructor, details } = this.instructorDetailStore
    const heroImageUrl = getIn(details, "heroImageUrl", undefined)
    const headshotUrl =
      instructor && instructor.headshotUrl
        ? instructor.headshotUrl
        : InstructorDefault
    const img = getIn(details, "mapPin", undefined)
    const brandId = getIn(details, "brandSlug", undefined)
    const location = getIn(instructor, "location", undefined)

    return (
      <>
        <div
          className="details-page"
          style={{ background: instructor ? "white" : "" }}
        >
          {!instructor && (
            <div className="pt-3 pt-md-4">
              <Spinner />
            </div>
          )}

          <div className="container">
            {instructor && heroImageUrl && (
              <img className="class-image w-100" src={heroImageUrl} />
            )}

            <div
              className={cx(
                instructor
                  ? `row main-row d-flex flex-sm-column flex-lg-row position-relative ${
                      heroImageUrl ? "" : "no-image"
                    }`
                  : ""
              )}
            >
              <div className="col-lg-7 col-xl-8 col-xxl-9">
                {instructor && (
                  <>
                    <div className="row m-0">
                      <div className="col-md-9 p-0 mb-lg-0">
                        <h2 className="details-page__title mb-0">
                          {headshotUrl && (
                            <img
                              className="mr-3"
                              src={headshotUrl}
                              width="40"
                              height="40"
                              style={{ borderRadius: "50%" }}
                            />
                          )}
                          {instructor.name}
                        </h2>
                      </div>
                    </div>
                    <hr className="float-left my-4" />

                    {instructor.bio && (
                      <>
                        <div className="row m-0">
                          <div className="col-md-12 p-0 pr-md-3 mb-lg-4 mb-xl-0">
                            <h3 className="details-page__section-title">
                              Description
                            </h3>
                            <p className="mb-0">{instructor.bio}</p>
                          </div>
                        </div>
                        <hr className="float-left my-4" />
                      </>
                    )}
                  </>
                )}

                <div className="row m-0 justify-content-center">
                  <div className={cx(instructor ? "visible" : "hide")}>
                    <APILoader
                      apiStore={scheduleStore}
                      fetchProps={{ locations: this.locationId }}
                      alreadyLoaded={scheduleStore.week.loaded}
                      key={scheduleStore.week.startDate}
                      elementSize="element"
                      render={() => {
                        return (
                          <>
                            {instructor && upcomingClasses && (
                              <div className="row m-0">
                                <h3 className="details-page__section-title">
                                  {instructor.name}'s Schedule
                                </h3>
                                <LocationDetailsSchedule
                                  {...this.props}
                                  scheduleStore={this.props.scheduleStore}
                                  scheduleEntryStore={
                                    this.props.scheduleEntryStore!
                                  }
                                  onBookingModal={this.props.onBookingModal}
                                  locationId={location!.id}
                                  instructor={instructor.name}
                                  userBalances={userBalances}
                                  bookedBookings={bookedBookings}
                                />
                              </div>
                            )}
                          </>
                        )
                      }}
                    />
                  </div>
                </div>
              </div>

              {img && brandId && (
                <DetailsPageMap
                  img={img}
                  brandId={brandId}
                  brandName={details!.brandName}
                  location={location!}
                  hasHeader={true}
                  store={store}
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}
