import { JsonObject, JsonProperty } from "json2typescript"
import Price from "models/Price"

@JsonObject("PackagePlanPayment")
export default class PackagePlanPayment {
  @JsonProperty("payment_type", String)
  paymentType: "installment" | "fee" = undefined!
  /**
   * Name of fee (only for fees)
   */
  @JsonProperty("name", String, true)
  name?: string = undefined!

  /**
   * Whether this payment needs to be made as the package is purchased.
   */
  @JsonProperty("is_due_today", Boolean)
  isDueToday: boolean = undefined!

  @JsonProperty("subtotal", Price)
  subtotal: Price = undefined!

  @JsonProperty("tax", Price)
  tax: Price = undefined!

  @JsonProperty("total", Price)
  total: Price = undefined!

  /**
   * When the payment is due.
   */
  @JsonProperty("due_date", String)
  dueDate: string = undefined!

  /**
   * Tax rate, probably doesn't need to be used, since the total tax is
   * calculated automatically.
   */
  @JsonProperty("tax_rate", Number)
  taxRate: number = undefined!

  /**
   * Whether the payment is subject to tax. Probably doesn't need to be used,
   * since the total tax is calculated automatically.
   */
  @JsonProperty("is_taxed", Boolean)
  isTaxed: boolean = undefined!
}
