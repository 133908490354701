import * as React from "react"
import { observer, inject } from "mobx-react"
import { RouteComponentProps, Switch, Route } from "react-router"
import { getLocal } from "utils/LocalStorage"
import getIn from "lodash/get"

import LocationSummary from "models/LocationSummary"
import BookingInfoStore from "apps/buy/stores/BookingInfoStore"
import PackagesStore from "apps/buy/stores/PackagesStore"
import PackagesAddOnStore from "apps/buy/stores/PackagesAddOnStore"
import PackagesList from "apps/buy/components/PackagesList"
import PaymentPage from "apps/buy/components/PaymentPage"
import APILoader from "components/wrappers/APILoader"
import Breadcrumbs from "components/Breadcrumbs"
import { parse } from "helpers/queryString"
import BrandStore from "stores/BrandStore"
import { observable } from "mobx"
import CrossClubRoadblockStore from "apps/buy/stores/CrossClubRoadblockStore"
import BlockerPage from "components/BlockerPage"

export interface Props extends RouteComponentProps {
  locationSummary: LocationSummary
  store?: BrandStore
}

// TODO: Figure out how to use routing state properly
// w/o having to declare these special types
interface RoutingState {
  forceBackURL?: string
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class PurchaseFlowController extends React.Component<Props> {
  @observable
  bookingInfoStore = BookingInfoStore.fromQueryString(
    this.props.locationSummary,
    this.props.store!,
    this.props.location.search
  )
  packagesStore: PackagesStore = new PackagesStore(
    this.props.store!,
    this.props.locationSummary,
    this.bookingInfoStore
  )
  packagesAddOnStore: PackagesAddOnStore = new PackagesAddOnStore(
    this.props.store!,
    this.props.locationSummary,
    this.bookingInfoStore
  )
  crossClubRoadblockStore = new CrossClubRoadblockStore(this.props.store!)

  needsLaFitnessCheck() {
    const { locationSummary } = this.props
    const laFitnessPurchase = getLocal("laFitnessPurchase", {}) as object
    const id = getIn(locationSummary,"id", "")
    const laFitnessPurchaseAccepted = laFitnessPurchase[id] || false

    return (
      !!locationSummary.laFitness &&
      !laFitnessPurchaseAccepted
    )
  }

  componentWillMount() {
    // this.props.store!.uiStore.hideNavLinks()
    this.props.store!.uiStore.lockLocationPicker()

    // TODO: I think this needs some improvement for if they can't book
    if (this.props.store!.isXponential) {
      this.bookingInfoStore.fetchBookingInfo()
    } else {
      this.bookingInfoStore.fetchBookingInfo()
      .then(() => {
        if (this.bookingInfoStore.hasBookingInfo) {
          this.crossClubRoadblockStore.maybeShow(
            this.bookingInfoStore.bookingPath
          )
        }
      })
    }
  }

  componentWillUnmount() {
    this.props.store!.uiStore.showNavLinks()
    this.props.store!.uiStore.unlockLocationPicker()
    this.crossClubRoadblockStore.dispose()
    this.packagesStore.dispose()
  }

  componentDidUpdate(prevProps: Props) {
    const oldQuery = parse(prevProps.location.search)
    const newQuery = parse(this.props.location.search)
    const oldSid = oldQuery.schedule_entry_id
    const sid = newQuery.schedule_entry_id
    const oldDurationId = oldQuery.durationId
    const durationId = newQuery.durationId

    if (oldDurationId !== durationId || sid !== oldSid) {
      this.bookingInfoStore = BookingInfoStore.fromQueryString(
        this.props.locationSummary,
        this.props.store!,
        this.props.location.search
      )
      this.packagesStore.setBookingInfo(this.bookingInfoStore)
      this.bookingInfoStore.fetchBookingInfo()
    }
  }

  public render() {
    const locationSummary = this.props.locationSummary
    const path = this.props.match.path

    // TODO: Add XPASS brand and location name
    const breadcrumbs = this.bookingInfoStore.hasBookingInfo
      ? [
          ...(!this.props.store!.isXponential
            ? [{ label: locationSummary.name }]
            : []),
          { label: this.bookingInfoStore.bookingSummary },
        ]
      : []
    // not needed unless we do url based switching btwn packages/memberships
    // const defaultListKey =
    //   this.packagesStore.memberships.length > 0 ? "memberships" : "packages"

    const routingState = (this.props.location.state || {}) as RoutingState
    const { forceBackURL } = routingState

    return (
      <>
        <div className="mx-auto">
          <Breadcrumbs
            className="mb-3"
            links={breadcrumbs}
            forceBackURL={forceBackURL}
          />

          <Switch>
            {/*
              Special case where we will redirect to health check once
              we have the fitness check
            */}
            {this.needsLaFitnessCheck() && (
              <Route
                path={path}
                render={ props => (
                  <BlockerPage
                    brandStore={this.props.store!}
                    type="laFitnessPurchase"
                    {...props}
                  />
                )}
              />
            )}
            <Route
              // path={[`${path}/memberships`, `${path}/packages`]}
              path={path}
              exact
              render={pckProps => {
                // const pckType = pckProps.match.url.split("/").pop()
                const fetchProps = this.props.store!.isXponential ? { forVod: false } : {}

                return (
                  <div>
                    <APILoader
                      apiStore={this.packagesStore}
                      key={this.packagesStore.cacheKey}
                      fetchProps={fetchProps}
                      elementSize="page"
                      render={() => {
                        return (
                          <>
                            <PackagesList
                              locationSummary={locationSummary}
                              packagesStore={this.packagesStore}
                              packagesAddOnStore={this.packagesAddOnStore}
                              queryString={this.props.location.search}
                              bookingInfoStore={this.bookingInfoStore}
                              routingState={routingState}
                            />
                          </>
                        )
                      }}
                    />
                  </div>
                )
              }}
            />
            <Route
              path={`${path}/packages/:packageId`}
              exact
              render={paymentPageProps => {
                const bookAndConfirm = this.props.store!.brand.condensedBookingFlow
                return (
                  <PaymentPage
                    packageId={paymentPageProps.match.params.packageId}
                    locationSummary={locationSummary}
                    bookingInfoStore={this.bookingInfoStore}
                    bookAndConfirm={bookAndConfirm}
                  />
                )
              }}
            />
          </Switch>
        </div>
      </>
    )
  }
}
