import camelCase from "lodash/camelCase"
import { AxiosError, AxiosResponse } from "axios"
import { Middleware } from "services/Middleware"
import { humanize } from "helpers/stringHelper"
import { isFormError } from "helpers/errorHandling"

// This should be placed at the very top of the stack so that it runs last
const FormErrorMiddleware: Middleware = (_, next) => {
  return next().catch((ex: AxiosError) => {
    if (isFormError(ex.response)) {
      ex.response.data.errors = transformErrors(ex.response.data.errors)
      return ex.response
    } else {
      throw ex
    }
  })
}

export function transformErrors(rawErrors: Record<string, string[]>) {
  return Object.keys(rawErrors).reduce((acc, field) => {
    // TODO: maybbe capitalize?
    const errorText = rawErrors[field]
      .map(txt => humanize(field + " " + txt))
      .join(", ")
    return { ...acc, [camelCase(field)]: errorText }
  }, {})
}

export default FormErrorMiddleware
