import { observable, action, computed } from "mobx"

import { LoadStatus } from "utils/LoadStatus"
import BaseStore from "stores/BaseStore"
import { Middleware } from "services/Middleware"
import SetStatusMiddleware from "services/middleware/SetStatusMiddleware"
import APIClient from "services/APIClient"
import includes from "lodash/includes"
import { AxiosRequestConfig } from "axios"

const defaultMiddlewareStack: Middleware[] = []

// this adds a middleware that automatically attaches a debug token in dev
// which adds a bunch of extra data to each response (under the raw_data key)
// it's all wrapped in the process.env check so that even the fact of its
// existence is not revealed in other environments
//
// actually this isn't true right now since we're adding sourcemaps everywhere
if (process.env.NODE_ENV === "development") {
  if (window.globals.debugToken) {
    const DebugMiddleware: Middleware = (ctx, next) => {
      if (!ctx.headers) ctx.headers = {}
      ctx.headers["X-Debug-Token"] = window.globals.debugToken
      return next()
    }
    defaultMiddlewareStack.push(DebugMiddleware)
  }
}

export default abstract class APIStore extends BaseStore {
  @observable
  status: LoadStatus = "idle"

  defaultMiddleware: Middleware[] = defaultMiddlewareStack.concat(
    SetStatusMiddleware(this)
  )

  @computed
  get isLoading() {
    return !includes(["loaded", "idle"], this.status)
  }

  protected clients: APIClient[] = []

  createClient<T = any>(
    appended: Middleware[] = [],
    prepended: Middleware[] = [],
    config: AxiosRequestConfig = {
      responseType: "json",
      headers: { accept: "application/json" },
    }
  ) {
    const client = new APIClient<T>(
      prepended.concat(this.defaultMiddleware.concat(appended)),
      config
    )
    client.context = { class: this.constructor.name }
    this.clients.push(client)
    return client
  }

  dispose() {
    this.clients.forEach(c => c.cancel())
    super.dispose()
  }

  @action.bound
  setStatus(status: LoadStatus) {
    this.status = status
  }
}
