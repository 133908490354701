import * as React from "react"
import { inject, observer } from "mobx-react"
import cx from "classnames"
import getIn from "lodash/get"

import Package from "models/Package"
import BrandStore from "stores/BrandStore"

interface Props {
  store?: BrandStore
  pkg: Package
  checked: boolean
  onChange(e: React.FormEvent<HTMLInputElement>): void
  form?: string
  isSingleCheckout?: boolean
  isXponential?: boolean
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ContractCheckbox extends React.Component<Props, {}> {
  handleTermsOpen = (e: LinkEvent) => {
    let title = "Terms & Conditions | Xponential Fitness"
    const locationName = getIn(this.props.store, "locStore.currentLocation.name", "")
    if (!this.props.isXponential) {
      if (locationName) {
        title = `Terms & Conditions | ${locationName}`
      } else {
        title = "Terms & Conditions"
      }
    }
    e.preventDefault()

    this.props.store!.uiStore.openModal({
      title,
      children: (
        <div className="contract-wrapper d-table">
          <iframe src={e.currentTarget.href} />
        </div>
      ),
      modalShow: true,
      size: "lg",
    })
  }

  render() {
    const { checked, store, pkg, onChange } = this.props

    return (
      <label className={cx('form-group form-check mb-4 text-left', {'xpassCheckout-terms': this.props.isXponential, 'brandCheckout-terms': !this.props.isXponential})}>
        <span>
          <input
            type="checkbox"
            checked={checked}
            onChange={onChange}
            className="form-check-input"
            form={this.props.form}
          />
        </span>{" "}
        <small>
          I have read and agreed to the{" "}
          <a
            href={pkg.contractUrl}
            onClick={this.handleTermsOpen}
            target="_blank"
          >
            {this.props.isSingleCheckout ? 'Terms and Conditions' : `${store!.brand.name} Terms & Conditions of Service`}
          </a>
        </small>
      </label>
    )
  }
}
