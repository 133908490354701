import { JsonObject, JsonProperty } from "json2typescript"

/**
 * A cell within a room. Can be of the following types:
 * - `seat`: A cell with a seat in it! The user picks from these.
 * - `instructor`: Cell with an instructor in it. Instructors have seat
 * numbers, but they currently don't appear to be used for anything.
 * - `feature`: A cell with something in it that isn't a seat or an
 * instructor. At present, this can be a `speaker`, `fan`, or `tv`. It may be
 * expanded in the future.
 * - `empty`: An empty cell. No feature, no seat and no instructor here.
 */
type RoomCell =
  | RoomCellEmpty
  | RoomCellFeature
  | RoomCellInstructor
  | RoomCellSeat

// Base class used for deserialization - shouldn't be used elsewhere
@JsonObject("RoomCellConcrete")
export class RoomCellConcrete {
  @JsonProperty("type", String)
  type: "empty" | "seat" | "instructor" | "feature" = undefined!

  @JsonProperty("seat_id", String, true)
  seatId?: string = undefined!

  @JsonProperty("seat_label", String, true)
  seatLabel?: string = undefined!

  @JsonProperty("feature", String, true)
  feature?: string = undefined!

  @JsonProperty("reserved", Boolean, true)
  reserved?: boolean = undefined!
}

/**
 * An empty cell. No feature, no seat and no instructor here.
 */
export interface RoomCellEmpty {
  type: "empty"
  seatLabel?: string
}
/**
 * A cell with something in it that isn't a seat or an instructor. At present,
 * this can be a `speaker`, `fan`, or `tv`. It may be expanded in the future.
 *
 */
export interface RoomCellFeature {
  type: "feature"
  /**
   * What's in this cell.
   */
  feature: "fan" | "speaker" | "tv"
  seatLabel?: string
}
/**
 * Cell with an instructor in it. Instructors have seat numbers, but they
 * currently don't appear to be used for anything
 *
 */
export interface RoomCellInstructor {
  type: "instructor"
  /**
   * Probably safe to ignore - might be used to indicate which instructor is
   * where.
   *
   */
  seat?: string
  seatLabel?: string
}
/**
 * A cell with a seat in it! The user picks from these.
 *
 */
export interface RoomCellSeat {
  type: "seat"
  /**
   * The seat number of this cell.
   */
  seatId: string
  /**
   * The seat label of this cell.
   */
  seatLabel?: string
  /**
   * Whether or not the seat is already taken.
   */
  reserved: boolean
}

export default RoomCell
