import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("RowingSummary")
export default class RowingSummary {
  type: "rowing" = "rowing"
  /**
   * Unused?
   */
  @JsonProperty("avg_power", Number)
  avgPower: number = undefined!

  /**
   * This appears to correspond to "average split time" in seconds.
   */
  @JsonProperty("avg_pace", Number)
  avgPace: number = undefined!

  /**
   * This appears to correspond to "best split time" in seconds.
   */
  @JsonProperty("high_pace", Number)
  highPace: number = undefined!

  /**
   * Total distance rowed, in meters.
   */
  @JsonProperty("distance", Number)
  distance: number = undefined!
}
