import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import FeaturedIcon from "images/xpass/deal-featured.svg"
import CheckmarkIcon from "images/xpass/icons/checkmark-green.svg"
import ClassToken from 'models/xpass/ClassToken'
import Deal from 'models/xpass/Deal'
import classTokenImg from "images/xpass/xpass-token.png"

interface Props {
  reward: Deal | ClassToken
}

export const DealCard:React.FC<Props> = ({
  reward
}) => {
  const title = reward instanceof Deal ? reward.title : reward.name
  const image = reward instanceof Deal ? reward.featuredThumbnailImage || "https://placehold.co/200x200" : reward.thumbnailImage || classTokenImg
  const url = reward instanceof Deal ? `rewards/${reward.id}` : `rewards/tokens/${reward.id}`
  const daysTillExpiration = reward instanceof Deal && reward.redeemable ? moment(reward.endDate).diff(moment(), 'days') : null
  const showCheckmark = reward instanceof ClassToken || reward instanceof Deal && reward.redeemable

  return (
    <div className="deal-card flex flex-column position-relative">
      <div className="deal-card__image position-relative">
        <img className="thumbnail-image" src={image} alt={title} />
        {showCheckmark && (
         <img className="deal-card__checkmark-icon" src={CheckmarkIcon} alt="checkmark" />
        )}
      </div>
      <div className="deal-card__content mt-3">
        <div className="deal-card__content__title">
          {title}
        </div>
        {daysTillExpiration && (
          <div className="deal-card__content__expiration mt-1">
            {daysTillExpiration} days
            <img className="ml-1" src={FeaturedIcon} alt="lightning icon" />
          </div>
        )}
      </div>
      <Link to={url} className="deal-card__link" />
    </div>
  )
}

export default DealCard