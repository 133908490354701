import * as React from "react"
import FormControl from "components/forms/FormControl"

type SafeInput = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "value" | "name" | "children"
>

interface Props extends SafeInput {
  name: string
  className?: string
  label?: string
  placeholder?: string
  tooltipId?: string
  value?: string
}

const FormInput: React.SFC<Props> = ({
  name,
  className,
  label,
  placeholder,
  tooltipId,
  ...inputProps
}) => {
  return (
    <FormControl className={className} label={label} name={name} tooltipId={tooltipId} inputProps={inputProps}>
      {({ id, className: innerClassName, field, form }) => (
        <input
          {...field}
          {...inputProps}
          disabled={inputProps.disabled || form.isSubmitting}
          id={id}
          className={innerClassName}
          placeholder={placeholder || label}
        />
      )}
    </FormControl>
  )
}

export default FormInput
