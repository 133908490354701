const monthNames = [
  {
    abbreviation: "Jan",
    name: "January",
    number: 1,
    code: "01",
  },
  {
    abbreviation: "Feb",
    name: "February",
    number: 2,
    code: "02",
  },
  {
    abbreviation: "Mar",
    name: "March",
    number: 3,
    code: "03",
  },
  {
    abbreviation: "Apr",
    name: "April",
    number: 4,
    code: "04",
  },
  {
    abbreviation: "May",
    name: "May",
    number: 5,
    code: "05",
  },
  {
    abbreviation: "Jun",
    name: "June",
    number: 6,
    code: "06",
  },
  {
    abbreviation: "Jul",
    name: "July",
    number: 7,
    code: "07",
  },
  {
    abbreviation: "Aug",
    name: "August",
    number: 8,
    code: "08",
  },
  {
    abbreviation: "Sep",
    name: "September",
    number: 9,
    code: "09",
  },
  {
    abbreviation: "Oct",
    name: "October",
    number: 10,
    code: "10",
  },
  {
    abbreviation: "Nov",
    name: "November",
    number: 11,
    code: "11",
  },
  {
    abbreviation: "Dec",
    name: "December",
    number: 12,
    code: "12",
  },
]

export default monthNames
