import * as React from "react"
import { RouteComponentProps, Redirect } from "react-router"
import * as moment from "moment"
import { action, computed, observable } from "mobx"
import { observer, inject } from "mobx-react"

import { fromServiceBookingProps } from "helpers/queryString"
import APILoader from "components/wrappers/APILoader"
import PageTracker from "components/wrappers/PageTracker"
import LocationSummary from "models/LocationSummary"
import ServiceScheduleEntry from "apps/book/models/ServiceScheduleEntry"
import BrandStore from "stores/BrandStore"
import PurchasesStore from "apps/account/stores/PurchasesStore"
import ServiceTypesStore from "apps/book/stores/ServiceTypesStore"
import ServiceBookingStore from "apps/book/stores/ServiceBookingStore"
import ServiceLocationStore from "../stores/xpass/ServiceLocationStore"
import ScheduleEntryStore from "../stores/xpass/ScheduleEntryStore"
import UserCoordinatesStore from "../stores/xpass/UserCoordinatesStore"
import ScheduleHeader from "apps/book/components/ScheduleHeader"
import ServiceTypeSchedule from "apps/book/components/ServiceTypeSchedule"
import BookingScheduleMenu from "apps/book/components/BookingScheduleMenu"
import ServiceScheduleModal from "./ServiceScheduleModal"
import ServiceMembershipsCard from "apps/book/components/ServiceMembershipsCard"

export interface Props
  extends RouteComponentProps<{ serviceTypeId?: string; locationId?: string }> {
  store?: BrandStore
  locationSummary: LocationSummary
  serviceTypeId?: string
  serviceTypesStore: ServiceTypesStore
  serviceBookingStore: ServiceBookingStore
  onBookingModal?: Function
  setPurchaseUrl: Function
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ServiceSchedulePage extends React.Component<Props> {
  isXponential = this.props.store!.isXponential
  param = new URLSearchParams(window.location.search)
  xpassLocationId = this.props.store!.isXponential ? this.param.get("locationId") : undefined

  userCoordinatesStore = this.props.store!.isXponential
    ? new UserCoordinatesStore()
    : undefined
  scheduleEntryStore = this.userCoordinatesStore
    ? new ScheduleEntryStore(this.userCoordinatesStore, this.props.store!.userStore)
    : undefined
  serviceLocationStore = this.scheduleEntryStore
    ? new ServiceLocationStore(this.scheduleEntryStore)
    : undefined
  @observable purchasesStore = new PurchasesStore(this.props.store!)

  @computed get bookingConfirmRedirect() {
    return (
      <Redirect
        push
        to={{
          pathname: `${this.props.match.url.replace(/\/$/, "")}/confirm`,
          search: this.bookingQueryString,
        }}
      />
    )
  }

  @computed get packagePurchaseRedirect() {
    return (
      <Redirect
        push
        to={{
          pathname: `/buy/${this.props.locationSummary.id}`,
          search: this.bookingQueryString,
        }}
      />
    )
  }

  @computed get serviceType() {
    return this.props.serviceTypesStore.serviceTypes.find(
      st => st.id === this.props.serviceTypeId
    )
  }

  @computed get bookingQueryString() {
    return fromServiceBookingProps(this.props.serviceBookingStore.bookingProps!)
  }

  @action.bound
  handleServiceTypeSelect(e: SelectEvent) {
    const { serviceTypeId } = this.props
    const url = serviceTypeId
      ? // this is technically unreliable, if the id appears elsewhere in the URL
        this.props.match.url.replace(serviceTypeId, e.currentTarget.value)
      : `${this.props.match.url}/${e.currentTarget.value}`

    this.props.history.push(url)
  }

  @action.bound
  updateQuery(key: string, value: string) {
    const { history, store } = this.props

    history.push(store!.routingStore.mergeQuery({ [key]: value }))
  }

  componentDidMount() {
    if (this.isXponential && this.serviceLocationStore && this.xpassLocationId) {
      const today = moment().format("YYYY-MM-DDTHH:mm")
      this.serviceLocationStore!.fetchByLocation(today, this.xpassLocationId!)
    } else {
      this.props.serviceTypesStore.fetch()
      this.purchasesStore.fetch()
      this.handleModal()
    }
  }

  componentWillUnmount() {
    if (!this.isXponential) {
      this.props.serviceTypesStore.dispose()
      this.purchasesStore.dispose()
    }
  }

  handleBookClick = (entry: ServiceScheduleEntry) => {
    if (this.isXponential) {
      this.props.serviceBookingStore.fetchFromEntry(entry).then(res => {
        if (res.status === 200 && this.props.onBookingModal) {
          if (!res.data.service_booking_status.canBook) {
            const handleClick = () => {
              this.props.store!.uiStore.closeModal()
              if (res.data.service_booking_status.reasons[0].code === "no_available_credit") {
                this.props.history.push(`/buy/${this.props.locationSummary.id}?${this.bookingQueryString}`)
              }
            }

            const message = res.data.service_booking_status.reasons[0].code === "user_booked"
                ? "You have already booked a session at this time."
                : res.data.service_booking_status.reasons[0].message

            this.props.store!.uiStore.openModal({
              title: "Something went wrong",
              modalShow: true,
              size: "md",
              children: (
                <div className="text-center">
                  <p>{message}</p>
                  <button className="btn btn-primary" onClick={handleClick}>OK</button>
                </div>
              ),
            })
          } else {
            this.props.onBookingModal(entry)
          }
        } else {
          this.props.store!.uiStore.openError()
        }
      })
    } else {
      this.props.serviceBookingStore.fetchFromEntry(entry).then(res => {
        if (res.data.service_booking_status.status === "needs_package") {
          const { durationId, instructorId, startsAt } = this.props.serviceBookingStore.bookingProps!
          const purchaseUrl = `/buy/${this.props.locationSummary.id}?duration_id=${durationId}&instructor_id=${instructorId}&starts_at=${startsAt}`
          this.handleModal(purchaseUrl)
        }
      })
    }
  }

  handleModal = (purchaseUrl?: string) => {
    this.props.store!.uiStore.openModal({
      children: (
        <ServiceScheduleModal
          store={this.props.store!}
          serviceTypesStore={this.props.serviceTypesStore}
          purchaseUrl={purchaseUrl}
          setPurchaseUrl={this.props.setPurchaseUrl}
          updateQuery={this.updateQuery}
        />
      ),
      modalShow: true,
    })
  }

  public render() {
    const {
      locationSummary,
      serviceBookingStore,
      serviceTypesStore,
      store,
    } = this.props
    const { isCrossClub, readyToBook } = serviceBookingStore
    if (!this.props.store!.isXponential) {
      if (isCrossClub ) {
        // If the user is trying to book outside of their booking group,
        // the package purchase page will detect the cross-club booking and
        // show the appropriate roadblock modal
        return this.packagePurchaseRedirect
      } else if (readyToBook) {
        return this.bookingConfirmRedirect
      }
    }

    const serviceLocations = this.serviceLocationStore && this.serviceLocationStore.serviceLocations

    return (
      <>
        {store!.isXponential ? (
          <div className="mb-3">
            {serviceLocations && serviceLocations.length > 0 ? (
              <>
                <div className="row">
                  <h1 className="col">{serviceLocations[0].name} Schedule</h1>
                </div>
                <address className="mb-1">
                  {serviceLocations[0].address}
                  <br />
                  {serviceLocations[0].city}, {serviceLocations[0].state}{" "}
                  {serviceLocations[0].zip}
                </address>
              </>
            ) : (
              <>
                <h1 className="col">Schedule</h1>
              </>
            )}
            <h4 className="mt-3">One-On-One Stretches</h4>
          </div>
        ) : (
          <>
            {/* TODO: move this up so it doesn't flicker when changing schedule type */}
            <ScheduleHeader locationSummary={locationSummary} key="head" />
            <ServiceMembershipsCard store={store!} purchasesStore={this.purchasesStore} />
            <BookingScheduleMenu
              locationSummary={locationSummary}
              copy={this.props.store!.copy}
            />
          </>
        )}

        <PageTracker name="service schedule" />

        <APILoader
          apiStore={serviceTypesStore}
          // onLoad={this.handleLoad}
          fetchProps={store!.isXponential && this.xpassLocationId}
          render={() => {
            const st = serviceTypesStore.serviceTypes[0]
            if (st && !this.serviceType) {
              return <Redirect to={`${this.props.match.url}/${st.id}`} />
            }

            return (
              <div>
                {this.serviceType && (
                  <div>
                    <ServiceTypeSchedule
                      onBookClick={this.handleBookClick}
                      onServiceTypeSelect={this.handleServiceTypeSelect}
                      allServiceTypes={serviceTypesStore.serviceTypes}
                      locationSummary={this.props.locationSummary}
                      serviceType={this.serviceType}
                      key={this.serviceType.id}
                      isBusy={serviceBookingStore.isBusy}
                      routeInfo={this.props.location}
                      updateQuery={this.updateQuery}
                      xpassLocationId={this.xpassLocationId!}
                    />
                  </div>
                )}
              </div>
            )
          }}
        />
      </>
    )
  }
}
