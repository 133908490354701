import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import BrandStore from "stores/BrandStore"
import Invoice from "models/Invoice"

interface InvoicesResponse {
  invoices: Invoice[]
}

export default class InvoicesStore extends APIStore {
  @observable invoices: Invoice[] = []

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("invoices", Invoice),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(protected brandStore: BrandStore) {
    super()
  }

  fetch() {
    return this.api.get(`/api/invoices`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<InvoicesResponse>) {
    this.invoices = res.data.invoices
    return res
  }
}
