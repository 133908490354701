import {
  BrandData,
  BrandStyleClasses,
  baseSettings,
  baseStyles,
  baseStyleClasses,
  baseCopy,
  baseDashboardCards,
  BrandCopy,
  BrandSettings,
  BrandStyles,
  BadgeSvg,
  BadgeBitmap,
  BrandDashboardCards,
  eywAffiliateLinks,
} from "themes/base"

import logoUrl from "images/logos/akt-logo.svg"
import challengeBadge from "images/badges/akt/challenge/challenge_default.png"
import badgeImage from "images/badges/akt-badge.svg"
import bannerImage from "images/dashboard/generic.jpg"
import appPromoImage_Perf from "images/promo/akt/akt_hr_monitor.png"
import numberFormatter, { formatNumber } from "helpers/numberFormatter"
import appPromoImage_Video from "images/video/akt-app-promo.png"
import videoLandingLogo from "images/video/akt-go-logo.png"
import appleWatchIcon from "images/apple_health/watch/akt.svg"
import classpointsHero from "images/classpoints/classpoints-hero_akt.png"
import brandIcon from "images/classpoints/brands/AKT.svg"

import badge1Active from "images/badges/akt/AKT_BadassLoyaltyProgram_Badges_116x116_0.png"
import badge12Active from "images/badges/akt/AKT_BadassLoyaltyProgram_Badges_116x116_1.png"
import badge25Active from "images/badges/akt/AKT_BadassLoyaltyProgram_Badges_116x116_2.png"
import badge50Active from "images/badges/akt/AKT_BadassLoyaltyProgram_Badges_116x116_3.png"
import badge100Active from "images/badges/akt/AKT_BadassLoyaltyProgram_Badges_116x116_4.png"
import badge175Active from "images/badges/akt/AKT_BadassLoyaltyProgram_Badges_116x116_5.png"
import badge250Active from "images/badges/akt/AKT_BadassLoyaltyProgram_Badges_116x116_6.png"
import badge500Active from "images/badges/akt/AKT_BadassLoyaltyProgram_Badges_116x116_7.png"

import badge1Inactive from "images/badges/akt/AKT_BadassLoyaltyProgram_InvertedBadges_116x116_0.png"
import badge12Inactive from "images/badges/akt/AKT_BadassLoyaltyProgram_InvertedBadges_116x116_1.png"
import badge25Inactive from "images/badges/akt/AKT_BadassLoyaltyProgram_InvertedBadges_116x116_2.png"
import badge50Inactive  from "images/badges/akt/AKT_BadassLoyaltyProgram_InvertedBadges_116x116_3.png"
import badge100Inactive from "images/badges/akt/AKT_BadassLoyaltyProgram_InvertedBadges_116x116_4.png"
import badge175Inactive from "images/badges/akt/AKT_BadassLoyaltyProgram_InvertedBadges_116x116_5.png"
import badge250Inactive from "images/badges/akt/AKT_BadassLoyaltyProgram_InvertedBadges_116x116_6.png"
import badge500Inactive from "images/badges/akt/AKT_BadassLoyaltyProgram_InvertedBadges_116x116_7.png"

const badges: BadgeBitmap[] = [
  {
    label: "1 Class",
    value: 1,
    active: badge1Active,
    inactive: badge1Inactive,
  },
  {
    label: "12 Classes",
    value: 12,
    active: badge12Active,
    inactive: badge12Inactive,
  },
  {
    label: "25 Classes",
    value: 25,
    active: badge25Active,
    inactive: badge25Inactive,
  },
  {
    label: "50 Classes",
    value: 50,
    active: badge50Active,
    inactive: badge50Inactive,
  },
  {
    label: "100 Classes",
    value: 100,
    active: badge100Active,
    inactive: badge100Inactive,
  },
  {
    label: "175 Classes",
    value: 175,
    active: badge175Active,
    inactive: badge175Inactive,
  },
  {
    label: "250 Classes",
    value: 250,
    active: badge250Active,
    inactive: badge250Inactive,
  },
  {
    label: "500 Classes",
    value: 500,
    active: badge500Active,
    inactive: badge500Inactive,
  },
]

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
}

const copy: BrandCopy = {
  ...baseCopy,
  seat: "Class",
  bookClass: "Book This Class",
  myWeeklyGoal: "Badass Loyalty Program",
}

const settings: BrandSettings = {
  ...baseSettings,
  isPerformance: false,
  hasLeaderboard: false,
  hasShoeSize: false,
  hasWeight: false,
  showPromoOnEmptyPerf: true,
  vodEarlySubscriberDiscount: 15,
}

const styles: BrandStyles = {
  ...baseStyles,
  badgeImage,
  bannerImage,
  challengeBadge,
  appPromoImage_Perf,
  chartColor: "#60259e",
  logoUrl,
  appPromoImage_Video,
  videoLandingLogo,
  appleWatchIcon,
  classpointsHero,
  brandIcon
}

const dashboardCards: BrandDashboardCards = [
  "NextClassCard",
  "PerformanceCard",
  "AchievementsCard",
  "AttendanceCard",
  "TodaysClassCard",
  "ClassPointsCard",
  "AppleHealthCard",
  "ProfileCard",
  "MobileAppCard",
  "MobileOfferCard",
  "PromoCards",
]

export const heartRateZoneColors = [
  { name: "black", color: "#000000" },
  { name: "blue", color: "#62C8FF" },
  { name: "purple", color: "#4D008C" },
  { name: "pink", color: "#FE32A7" },
]

const akt: BrandData = {
  settings,
  styles,
  styleClasses,
  copy,
  dashboardCards,
  badges,
  metrics: [
    {
      key: "iqPoints",
      label: "Points",
      logo: "perf-akt-target",
      logoSize: 30,
      format: formatNumber,
    },
    {
      key: "calories",
      label: "Calories",
      logo: "perf-akt-calories",
      logoSize: 30,
      format: formatNumber,
    },
    {
      key: "avgHr",
      label: "Average HR",
      logo: "perf-akt-hr",
      logoSize: 30,
      format: formatNumber,
    },
    {
      key: "avgHrPercent",
      label: "Average HR %",
      logo: "perf-akt-hr-perc",
      logoSize: 30,
      format: numberFormatter({ suffix: "%" }),
    },
    {
      key: "highHr",
      label: "High HR",
      logo: "perf-akt-hr",
      logoSize: 30,
      format: formatNumber,
    },
    {
      key: "highHrPercent",
      label: "Max HR %",
      logo: "perf-akt-hr-perc",
      logoSize: 30,
      format: numberFormatter({ suffix: "%" }),
    },
    {
      key: "maxHr",
      label: "Max HR",
      logo: "perf-akt-hr",
      logoSize: 30,
      format: formatNumber,
    },
  ],
  summaryMetrics: [],
  heartRateZoneColors: heartRateZoneColors,
  includeTerms: false,
  eywAffiliateLinks: eywAffiliateLinks["akt"],
  eywLegalLink: "https://www.theakt.com/terms-earn-your-watch",
  eywParticipatingLink: "https://www.theakt.com/earn-your-watch-locations"
}

export default akt
