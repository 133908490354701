import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

import Reward from "apps/classpoints/models/Reward"

interface Response {
  reward: Reward
}

export default class RewardStore extends APIStore {
  @observable reward: Reward = new Reward

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("reward", Reward),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(public brandStore: BrandStore) { super() }

  fetch(rewardId: number) {
    return this.api.get(`/api/class_points/rewards/${rewardId}`)
  }

  redeem(rewardId: number) {
    return this.api.put(`/api/class_points/rewards/${rewardId}`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response | any>) {
    this.reward = res.data.reward
    return res.data
  }
}
