import { Middleware } from "services/Middleware"
import { AxiosResponse, AxiosError } from "axios"

// dumb convenience function to wrap handleSuccess
// make it easy to put the handling anywhere in the stack
export default function ResponseMiddleware(
  successHandler?: (response: AxiosResponse) => AxiosResponse,
  errorHandler?: (error: AxiosError) => any // should this not be "any"?
): Middleware {
  return (_, next) => next().then(successHandler, errorHandler)
}
