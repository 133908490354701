import * as React from "react"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import cx from "classnames"

import Heading from "components/Heading"
import { Link } from "react-router-dom"
import ErrorPage from "components/ErrorPage"

interface Props {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class Error404Page extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.store!.uiStore.hideNavLinks()
  }

  componentWillUnmount() {
    this.props.store!.uiStore.showNavLinks()
  }

  render() {
    return (
      <ErrorPage
        title="Not Found"
        code="Error Code: 404"
        linkTo="/"
        linkTitle="My Dashboard"
        message="We can't seem to find the page you're looking for. You may have
        mistyped the address or the page may have been moved."
      />
    )
  }
}
