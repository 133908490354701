import * as React from "react"
import { observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import LocationSummary from "models/LocationSummary"
import { formatPhone } from "helpers/stringHelper"

interface Props {
  locationSummary: LocationSummary
  showName?: boolean
  showPhone?: boolean
  showEmail?: boolean
  store?: BrandStore
}

const LocationAddressLink: React.SFC<Props> = ({
  locationSummary,
  showName,
  showPhone,
  showEmail,
  store,
}) => (
  <div>
    {showName && (
      <h5>
        <a href={locationSummary.siteUrl} className="text-decoration-none">
          {store!.brand.name} {locationSummary.name}
        </a>
      </h5>
    )}
    <a href={locationSummary.directionsUrl} className="small" target="_blank">
      <address className="m-0">
        {locationSummary.address}
        {locationSummary.address2 && (
          <>
            <br />
            {locationSummary.address2}
          </>
        )}
        <br />
        {locationSummary.city}, {locationSummary.state} {locationSummary.zip}
      </address>
    </a>
    {(showPhone || showEmail) && (
      <p className="mt-2">
        {showPhone && locationSummary.phone && (
          <a href={`tel:${locationSummary.phone}`} className="small">
            {formatPhone(locationSummary.phone)}
          </a>
        )}
        <br />
        {showEmail && locationSummary.email && (
          <a href={`mailto:${locationSummary.email}`} className="small">
            {locationSummary.email}
          </a>
        )}
      </p>
    )}
  </div>
)

export default observer(LocationAddressLink)
