import React, { useState, useEffect} from "react"
import * as moment from "moment"
import cx from "classnames"
import Fuse from "fuse.js"

import BrandStore from "stores/BrandStore"
import LocationsStore from "stores/LocationsStore"
import GuestUserStore from "../stores/GuestUserStore"
import LocationFinderStore from "apps/location_finder/stores/LocationFinderStore"
import LocationSummary from "models/LocationSummary"
import Reward from "apps/classpoints/models/Reward"

import Spinner from "components/Spinner"
import SearchBar from "apps/location_finder/components/SearchBar"
import LocationList from "apps/location_finder/components/LocationList"
import stateNames from "helpers/stateNames"
import ProfileStore from "apps/account/stores/ProfileStore"
import CheckmarkIcon from "images/xpass/icons/checkmark-green.svg"
import RewardStore from "../stores/RewardStore"
import { MembershipDetails, Tier } from "../models/DashboardMain"

export const openModal = (store: BrandStore, children: JSX.Element) => {
  let delay = 0
  if (store.uiStore.modal.modalShow) {
    store.uiStore.closeModal()
    delay = 250
  }
  setTimeout(() => {
    store.uiStore.openModal({
      children: children,
      classes: 'classpoints classpoints-modal',
      modalShow: true,
    })
  }, delay)
}

const CloseButton = ({store}: {store: BrandStore}) => {
  return (
    <button type="button" className="close" onClick={store!.uiStore.closeModal}>
      <span aria-hidden="true">×</span>
      <span className="sr-only">Close</span>
    </button>
  )
}

export const ClassPointsDetailModal = ({store, progressColors, membershipDetails}: {store: BrandStore, progressColors: any, membershipDetails: MembershipDetails}) => {

  const brandId = store!.brandId;

  return (
    <div className="p-2 p-sm-4">
       <CloseButton key={`close`} store={store} />
      <h2 className="text-center pb-3">{membershipDetails.title}</h2>
      <div className="font-20 pb-3">{membershipDetails.subtitle}</div>
      <div className="d-flex flex-column">
        <div className="d-flex flex-row color-gray">
          <div className="font-12 col-4 col-sm-3 p-0">Level/Benefits</div>
          <div className="font-12 col-3 col-sm-4 p-0 text-center">Points</div>
          <div className="font-12 col-5 p-0">Membership Status</div>
        </div>
        {membershipDetails.tiers.map((tier,i) => (
          <div key={`${tier.tierName}_wrap`}>
            <div key={`${tier.tierName}_hr`} className="hr mb-3"></div>
            <div key={`${tier.tierName}_1`} className="d-flex align-items-center">
              <div key={`${tier.tierName}_2`} className="col-3 p-0">
                <div key={`${tier.tierName}_3`} className="pill text-uppercase font-600 mr-1" style={{backgroundColor: tier.backgroundColorHex, color: `color-mix(in srgb, ${tier.textColorHex} 30%, #000000)`}}>{tier.tierName}</div>
              </div>
              <div key={`${tier.tierName}_4`} className="font-12 col-3 p-0 text-right">{tier.points} Points</div>
              <div key={`${tier.tierName}_5`} className="font-12 col-1 p-0 mx-1 mx-sm-2 text-center">AND</div>
              <div key={`${tier.tierName}_6`} className="font-12 col-5 p-0">
                {tier.membershipRequired && (
                  <span key={`${tier.tierName}_7`} className="mr-2 valign-text-bottom">
                    <svg key={`${tier.tierName}_8`} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle key={`${tier.tierName}_9`} cx="8" cy="8" r="7.5" fill="#4EAA33" stroke="#4EAA33"/>
                      <path key={`${tier.tierName}_10`} d="M11.6666 5.6665L6.99996 10.3332L4.66663 7.99984" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </span>
                )}
                <span key={`${tier.tierName}_11`}>{tier.membershipText}</span>
              </div>
            </div>
            <div key={`${tier.tierName}_12`} className="pt-2">
              <ul key={`${tier.tierName}_13`} className="font-14 color-gray">
                {tier.description.map((v,i) => <li key={`${tier.tierName}_${i}-desc`}>{v}</li>)}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const ClassPointsInfoModal = ({store, openDetailModal, membershipDetails}: {store: BrandStore, openDetailModal: any, membershipDetails: MembershipDetails}) => {
  return (
    <div className="p-4">
      <CloseButton key={`close`} store={store} />
      <h2 className="text-center pb-3">What is ClassPoints<span className="tm">™</span></h2>
      <div className="font-20 pb-3">Earn points within each interval window to level up your status or if you don't earn enough you will be bumped down a tier.</div>
      <div className="d-flex flex-column">
        <div className="d-flex color-gray justify-content-between">
          <div className="font-12 col-3 p-0">Interval</div>
          <div className="font-12 col-4 p-0 text-right">Window</div>
        </div>
        <div className="hr my-3"></div>
        <div className="d-flex justify-content-between">
          <div className="font-12 col-3 p-0">Interval 1</div>
          <div className="font-12 col-4 p-0 text-right">January 1 - March 31</div>
        </div>
        <div className="hr my-3"></div>
        <div className="d-flex justify-content-between">
          <div className="font-12 col-3 p-0">Interval 2</div>
          <div className="font-12 col-4 p-0 text-right">April 1 - June 30</div>
        </div>
        <div className="hr my-3"></div>
        <div className="d-flex justify-content-between">
          <div className="font-12 col-3 p-0">Interval 3</div>
          <div className="font-12 col-4 p-0 text-right">July 1 - September 30</div>
        </div>
        <div className="hr my-3"></div>
        <div className="d-flex justify-content-between">
          <div className="font-12 col-3 p-0">Interval 4</div>
          <div className="font-12 col-4 p-0 text-right">October 1 - December 31</div>
        </div>
        <div className="hr my-3"></div>
      </div>
      <button className="btn btn-primary w-100 mt-3" onClick={() => openDetailModal(membershipDetails)}>See Tiers</button>
    </div>
  )
}

export const JoinClassPointsModal = (
  {store, submitConfirmStudio, openSelectHomeStudioModal, userEmail, verified, overrideHomeLocation}:
  {
    store: BrandStore,
    submitConfirmStudio: Function,
    openSelectHomeStudioModal: Function,
    userEmail: string,
    verified: boolean,
    overrideHomeLocation: boolean,
  }) => {
  const homeLocation = overrideHomeLocation || !store!.userStore!.session!.locations[0]
    ? store!.locationPickerStore.currentLocation!
    : store!.userStore!.session!.locations[0]

  const submitConfirm = () => {
    store!.track.event("classpoints_tap send email otp")
    submitConfirmStudio(userEmail, verified)
  }

  return (
    <div className="p-3">
      <CloseButton key={`close`} store={store} />
      <div className="pb-2 font-600">Join ClassPoints<span className="tm">™</span></div>
      <div className="pb-2">In order to sign up for ClassPoints<span className="tm">™</span> you must verify your email address. An email will be sent to <span className="font-600">{userEmail}</span> with a verification code. </div>
      <div className="pb-2 font-600">Your Home Studio will be set to:</div>
      <div className="classpoints__sub-container">
        <div className="pb-2">
          <div className="font-600 pb-2">{homeLocation.name}</div>
          <div>{homeLocation.address}</div>
          {homeLocation.address2 && (<div>{homeLocation.address2}</div>)}
          <div>{homeLocation.city}, {homeLocation.state} {homeLocation.zip}</div>
        </div>
        <button className="btn btn-link p-0" onClick={() => openSelectHomeStudioModal(userEmail)}>Change Home Studio <span className="down-arrow">▼</span></button>
      </div>
      <div className="py-2 font-10">Your home studio determines your ClassPoints<span className="tm">™</span> benefits.</div>
      <button className="btn btn-primary w-100 mt-3 font-18" onClick={() => submitConfirm()}>Confirm Studio{!verified && (<> &amp; Send Code</>)}</button>
    </div>
  )
}

export const SelectHomeStudioModal = (
  {store, openJoinModal, userEmail}:
  {
    store: BrandStore,
    openJoinModal: Function,
    userEmail: string,
  }) => {
  const locationsStore = new LocationsStore(store)
  let locationFinderStore = new LocationFinderStore(locationsStore)
  const handleLocationSelect = async (location: LocationSummary) => {
    await store!.locationPickerStore.handleSelect(location.id)
    openJoinModal(userEmail, true)
  }
  const [loaded, setLoaded]  = useState(false)
  const [filter, setFilter]  = useState("")
  const [orderedLocations, setOrderedLocations]  = useState(locationFinderStore.orderedLocations)
  const [locations, setLocations]  = useState(locationFinderStore.orderedLocations)
  const onSearchTermChange = (term: string) => { setFilter(term)}

  const getFilteredLocations = () => {
    let filteredLocations: LocationSummary[] = orderedLocations

    filteredLocations = orderedLocations.map(loc => {
      loc.stateName = stateNames[loc.state!]
      return loc
    })

    const options: Fuse.FuseOptions<LocationSummary> = {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 20,
      minMatchCharLength: 1,
      keys: ["name", "city", "state", "stateName", "zip"],
    }
    const fuse = new Fuse(orderedLocations, options)
    if (filter.trim() === "") {
      filteredLocations = orderedLocations
    } else {
      filteredLocations = fuse.search(filter)
    }

    setLocations(filteredLocations)
  }

  useEffect(() => {
    locationsStore.fetch({ email: userEmail, geoip: false, geoipOverride: '1' }).then(res => {
      setLoaded(true)
      locationFinderStore = new LocationFinderStore(locationsStore)
      setOrderedLocations(locationFinderStore.orderedLocations)
    })
  }, [])

  useEffect(() => {
    getFilteredLocations()
  }, [orderedLocations, filter])

  return (
    <div className="p-3 classpoints-modal__studio-select">
      <CloseButton key={`close`} store={store} />
      <h2 className="pb-2 font-600">Select a home studio</h2>
      <div
        className={cx(
          "location-modal__list",
          "pl-lg-0",
          "mt-4",
          "mt-lg-0"
        )}
      >
        {loaded && (
          <>
            <SearchBar
            onSearchTermChange={onSearchTermChange}
            className="slide-in delay-3"
            />
            <LocationList
              locations={locations}
              onListItemClick={() => {}}
              onLocationSelect={handleLocationSelect}
              store={store}
              locationFinderStore={locationFinderStore}
              hideActions={true}
            />
          </>
        )}
        {!loaded && (
          <Spinner size="element" />
        )}
      </div>
    </div>
  )
}

export const VerifyEmailModal = (
  {store, openBirthDateModal, submitOtpCode, resendCode, userEmail}: 
  {
    store: BrandStore,
    openBirthDateModal: Function,
    submitOtpCode: Function,
    resendCode: Function,
    userEmail: string
  }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const submitCode = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const { code } = e.target as typeof e.target & {
      code: { value: number }
    }
    setHasErrors(false)
    setIsSubmitting(true)
    submitOtpCode(code.value).then((res: any) => {
      if (res.data.success) {
        store!.track.event("classpoints_email verification success")
        openBirthDateModal()
      } else {
        store!.track.event("classpoints_email verification failure")
        setIsSubmitting(false)
        setHasErrors(true)
      }
    })
  }

  // Resend code and turn on loading spinner for a moment to give user some feedback on their action
  const resend = () => {
    setIsSubmitting(true)
    resendCode()
    store!.track.event("classpoints_tap resend OTP code")
    setTimeout(() => setIsSubmitting(false), 500)
  }

  return (
    <div className="p-3">
      <CloseButton key={`close`} store={store} />
      {!isSubmitting && (
        <form id="code-form" className="" onSubmit={e => submitCode(e)}>
          <div className="pb-2 font-600">Verify your email to sign up.</div>
          <div className="pb-2">An email was sent to <span className="font-600">{userEmail}</span> with a verification code. Check your spam filter if you do not immediately see. If this email is in use in more than one {store.brand.name} studio, all your studio accounts will be automatically enrolled into ClassPoints<span className="tm">™</span>. </div>
          <div className="position-relative my-3">
            <label htmlFor="verification-code" className="classpoints__label font-12 color-neutral">Enter Verification Code</label>
            <input
              id="verification-code"
              name="code"
              className={`classpoints__input pb-2 w-100 ${hasErrors && 'error'}`}
              type="number"
              maxLength={6}
              disabled={isSubmitting}
            />
            {hasErrors && (<div className="errors">Invalid code</div>)}
          </div>
          <button className="btn btn-link d-block text-center font-600 text-decoration-none m-auto p-0" type="button" onClick={resend}>Resend Code</button>
          <button className="btn btn-primary w-100 mt-3 font-18" type="submit">Submit Code</button>
        </form>
      )}
      {isSubmitting && (
        <Spinner size={"element"} className="pb-5" />
      )}
    </div>
  )
}

export const BirthDateModal = (
  {store, optIn, locationId, birthDate}:
  {
    store: BrandStore,
    optIn: any,
    locationId: string
    birthDate: string
  }) => {
  const bDate = moment(birthDate, 'YYYY-MM-DD', true).isValid() ? moment(birthDate, 'YYYY-MM-DD') : ''
  const [month, setMonth] = bDate ? useState<number | undefined>(parseInt(bDate.format('M'))) : useState<number>()
  const [day, setDay] = bDate ? useState<number | undefined>(parseInt(bDate.format('D'))) : useState<number>()
  const [year, setYear] = bDate ? useState<number | undefined>(parseInt(bDate.format('YYYY'))) : useState<number>()
  const [checked, setChecked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)

  useEffect(() => {
    if (hasErrors && month && day && year) setHasErrors(false)
    if (day && day > maxDays()) setDay(maxDays())
  }, [month, day, year])

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const { month, day, year } = e.target as typeof e.target & {
      month: { value: number }
      day: { value: number }
      year: { value: number }
    }
    if (month.value && day.value && year.value) {
      setHasErrors(false) 
      store!.track.event("classpoints_tap opt in")
      optIn(locationId, `${year.value}-${month.value.toString().padStart(2, '0')}-${day.value.toString().padStart(2, '0')}`)
        .then(() => {
          store!.track.event("classpoints_opt in success")
          window.location.reload()
        })
        .catch((ex: Error) => {
          console.error(ex)
          store!.track.event("classpoints_opt in failure")
        })
    } else {
      setHasErrors(true) 
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent,
    setValue:  React.Dispatch<React.SetStateAction<number | undefined>>, 
    maxValue: number
  ) => {
    let { value } = e.target as typeof e.target & { value: number | undefined }
    if (value && value < 1) value = 1
    if (value && value > maxValue) value = maxValue
    setValue(value);
  }

  const maxDays = () => {
    return new Date(year || 2000, month || 1, 0).getDate()
  }

  return (
    <div className="p-3">
      <CloseButton key={`close`} store={store} />
      <form id="birth-date-form" className="classpoints__date-form" onSubmit={e => submit(e)}>
        <div className="pb-2 font-600">One more step!</div>
        <div className="pb-2">Awesome! You will be earning points right away. We just need to confirm your birthday to continue (so we can wish you a Happy Birthday with a reward!) Note: you can not change this once set.</div>
        <div className="classpoints__date-inputs d-flex">
          <div className="position-relative col-4 my-3 p-0">
            <label htmlFor="month" className="classpoints__label font-12 color-neutral">Month</label>
            <input
              id="month"
              name="month"
              className={`classpoints__input pb-2 w-100 ${hasErrors && 'error'}`}
              type="number"
              value={month}
              onChange={e => handleInputChange(e, setMonth, 12)}
              disabled={isSubmitting}
            />
          </div>
          <div className="position-relative col-4 my-3 p-0">
            <label htmlFor="day" className="classpoints__label font-12 color-neutral">Day</label>
            <input
              id="day"
              name="day"
              className={`classpoints__input pb-2 w-100 ${hasErrors && 'error'}`}
              type="number"
              value={day}
              onChange={e => handleInputChange(e, setDay, maxDays())}
              disabled={isSubmitting}
            />
          </div>
          <div className="position-relative col-4 my-3 p-0">
            <label htmlFor="year" className="classpoints__label font-12 color-neutral">Year</label>
            <input
              id="year"
              name="year"
              className={`classpoints__input pb-2 w-100 ${hasErrors && 'error'}`}
              type="number"
              value={year}
              onChange={e => handleInputChange(e, setYear, parseInt(moment().format('YYYY')) - 13)}
              disabled={isSubmitting}
            />
          </div>
        </div>
        {hasErrors && (<div className="errors pb-2">Please enter a valid date</div>)}
        <div className="">
          <input id="terms" name="terms" type="checkbox" className="m-0" checked={checked} onChange={() => setChecked(!checked)} />
          <label htmlFor="terms" className="form-check-input d-inline font-14">I agree to the <a href={`${store!.settings.classPointsTermsUrl}`} target="_blank">Terms and Conditions</a></label>
        </div>
        <button className="btn btn-primary w-100 mt-3" type="submit" disabled={!checked}>Submit</button>
      </form>
    </div>
  )
}

export const StudioRewardModal = ({store, reward}: {store: BrandStore, reward: Reward}) => {
  return (
    <div className="p-2">
      <CloseButton key={`close`} store={store} />
      <div className="font-14 font-600 pb-3">{reward.shortDescription}</div>
      <div className="font-14 font-600 pb-2">Member: {store.userStore.session!.fullName}</div>
      <div className="hr my-3"></div>
      <button className="btn btn-primary w-100" onClick={store!.uiStore.closeModal}>Close</button>
      <div className="text-center color-gray font-12 pt-3">Available At Participating Locations</div>
    </div>
  )
}

export const StudioLimitedUseRewardModal = ({
  store,
  reward,
  openRedeemStudioRewardModal
}: {
  store: BrandStore,
  reward: Reward,
  openRedeemStudioRewardModal: Function
}) => {
  const usageLimit = reward.usageLimit || 0
  const redeemableCount = reward.redeemableCount || 0
  const rewardsRedeemed = Math.max(0, usageLimit - redeemableCount)

  return (
    <div className="classpoints__limited-use-modal p-2">
      <CloseButton key={`close`} store={store} />
      <div className="font-14 font-600 pb-3">{reward.shortDescription}</div>
      <div className="font-14 pb-2">{reward.description}</div>
      <div className="classpoints__limited-use-text p-3 my-3">
        <img className="classpoints__limited-use-check mr-3" src={CheckmarkIcon} alt="checkmark" />
        <span>{reward.rewardsText}</span>
      </div>
      <div className="d-flex flex-column">
        {[...Array(rewardsRedeemed)].map((e, i) => (
          <div className="classpoints__limited-use-modal-item d-flex align-items-center justify-content-between py-3">
            <div className="d-flex align-items-center">
              <div className="classpoints__modal-img"><img src={store!.styles.brandIcon} /></div>
              <div className="pl-3">{reward.name}</div>
            </div>
            <button className="btn btn-light" disabled>Redeemed</button>
          </div>
        ))}
        {[...Array(redeemableCount)].map((e, i) => (
          <div className="classpoints__limited-use-modal-item d-flex align-items-center justify-content-between py-3">
            <div className="d-flex align-items-center">
              <div className="classpoints__modal-img"><img src={store!.styles.brandIcon} /></div>
              <div className="pl-3">{reward.name}</div>
            </div>
            <button className="btn btn-primary" onClick={() => openRedeemStudioRewardModal()}>Use at Studio</button>
          </div>
        ))}
      </div>
    </div>
  )
}

export const RedeemStudioRewardModal = ({
  store,
  reward,
  rewardStore,
  profileStore,
  openErrorRewardModal
}: {
  store: BrandStore,
  reward: Reward,
  rewardStore: RewardStore,
  profileStore: ProfileStore
  openErrorRewardModal: Function
}) => {
  const redeemReward = (id: number) => {
    store!.uiStore.closeModal()

    rewardStore.redeem(id).then(() => {
      profileStore.fetch()
    })
    .catch(ex => {
      openErrorRewardModal()
    })
  }

  return (
    <div className="classpoints__reward-confirm-modal">
      <CloseButton key={`close`} store={store} />
      <div className="font-14 font-600 pb-3">Confirm</div>
      <div className="font-14">Are you sure you want to mark as Redeemed?.</div>
      <div className="hr my-3"></div>
      <button className="btn btn-primary w-100" onClick={() => redeemReward(reward.id)}>Yes, Mark as Redeemed</button>
      <button className="btn btn-text mt-2 w-100" onClick={store!.uiStore.closeModal}>No, Do Not Redeem</button>
    </div>
  )
}

export const GuestPassModal = ({store, reward, openRedeemGuestPassModal}: {store: BrandStore, reward: Reward, openRedeemGuestPassModal: any}) => {
  const redeem = () => {
    return (
      <div className="classpoints__guest-pass-modal-item d-flex align-items-center justify-content-between py-3">
        <div className="d-flex align-items-center">
          <div className="classpoints__modal-img"><img src={store!.styles.brandIcon} /></div>
          <div className="pl-3">One Free Guest Pass</div>
        </div>
        <button className="btn btn-primary" onClick={openRedeemGuestPassModal}>Redeem</button>
      </div>
    )
  }

  return (
    <div className="classpoints__guest-pass-modal p-2">
      <CloseButton key={`close`} store={store} />
      <div className="font-14 font-600 pb-3">Bring a Friend</div>
      <div className="d-flex flex-column">
        {[...Array(reward.redeemableCount)].map((e, i) => (
          <span key={`redeem_${reward.id}_${i}`}>{redeem()}</span>
        ))}
      </div>
    </div>
  )
}

export const RedeemGuestPassModal = ({store, reward, openSuccessGuestPassModal, openErrorGuestPassModal}: {store: BrandStore, reward: Reward, openSuccessGuestPassModal: Function, openErrorGuestPassModal: Function}) => {
  const guestUserStore = new GuestUserStore(store)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasErrors, setHasErrors] = useState({firstName: false, lastName: false, email: false, phone: false})
  const toSubmitDefault = {classPointsUserRewardsId: reward.classPointsUserRewardsId, firstName: '', lastName: '', email: '', phone: ''}
  const [toSubmit, setToSubmit] = useState(toSubmitDefault)

  const getFormValues = (e: React.SyntheticEvent) => {
    return e.target as typeof e.target & { 
      firstName: { value: string },
      lastName: { value: string },
      email: { value: string },
      phone: { value: string },
    }
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const { firstName, lastName, email, phone } = getFormValues(e)
    setToSubmit({classPointsUserRewardsId: reward.classPointsUserRewardsId, firstName: firstName.value, lastName: lastName.value, email: email.value, phone: phone.value})
    validateAll(e)
    setIsSubmitting(true)
  }

  const validateEmail = (email: string) => {
    return !!String(email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  const validatePhone = (phone: string) => {
    return !!String(phone).match(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    )
  }

  const validateAll = (e: React.SyntheticEvent) => {
    const { firstName, lastName, email, phone } = getFormValues(e)

    setHasErrors(
      {
        firstName: !firstName.value,
        lastName: !lastName.value,
        email: !validateEmail(email.value),
        phone: !validatePhone(phone.value)
      }
    )
  }

  const validate = (e: React.SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value
    switch ((e.target as HTMLInputElement).id) {
      case 'firstName':
        setHasErrors({...hasErrors, firstName: !value})
        break
      case 'lastName':
        setHasErrors({...hasErrors, lastName: !value})
        break
      case 'email':
        setHasErrors({...hasErrors, email: !validateEmail(value)})
        break
      case 'phone':
        setHasErrors({...hasErrors, phone: !validatePhone(value)})
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (
      Object.values(hasErrors).every(item => item === false)
      && Object.values(toSubmit).every(item => item !== '')
    ) {
      store!.track.event("classpoints_tap redeem guestpass", {
        firstName: toSubmit.firstName,
        lastName: toSubmit.lastName,
        email: toSubmit.email,
        phone: toSubmit.phone,
      })
      guestUserStore.submit(toSubmit.classPointsUserRewardsId, toSubmit.firstName, toSubmit.lastName, toSubmit.email, toSubmit.phone)
        .then(res => {
          store!.track.event("classpoints_redeem guestpass success")
          openSuccessGuestPassModal()
        })
        .catch(ex => {
          store!.track.event("classpoints_redeem guestpass failure")
          openErrorGuestPassModal()
        })
    } else {
      setIsSubmitting(false)
    }
  }, [hasErrors, toSubmit])

  return (
    <div className="classpoints__guest-pass-modal p-2">
      <CloseButton key={`close`} store={store} />
      <form id="guest-pass-form" className="" onSubmit={e => submit(e)}>
        <div className="font-14 font-600 pb-3">Bring a Friend</div>
        <div className="font-14">Gift a friend a free class at {store.brand.name}{` ${store.locationPickerStore.currentLocation!.name}` || ''}.</div>
        <div className="position-relative my-3">
          <label htmlFor="firstName" className="classpoints__label font-12 color-neutral">First name</label>
          <input
            id="firstName"
            name="firstName"
            className={`classpoints__input pb-2 w-100 ${hasErrors.firstName && 'error'}`}
            type="text"
            disabled={isSubmitting}
            onChange={e => hasErrors.firstName && validate(e)}
          />
          {hasErrors.firstName && (<div className="errors">First name required</div>)}
        </div>
        <div className="position-relative my-3">
          <label htmlFor="firstName" className="classpoints__label font-12 color-neutral">Last name</label>
          <input
            id="lastName"
            name="lastName"
            className={`classpoints__input pb-2 w-100 ${hasErrors.lastName && 'error'}`}
            type="text"
            disabled={isSubmitting}
            onChange={e => hasErrors.lastName && validate(e)}
          />
          {hasErrors.lastName && (<div className="errors">Last name required</div>)}
        </div>
        <div className="position-relative my-3">
          <label htmlFor="first-name" className="classpoints__label font-12 color-neutral">Email</label>
          <input
            id="email"
            name="email"
            className={`classpoints__input pb-2 w-100 ${hasErrors.email && 'error'}`}
            type="email"
            disabled={isSubmitting}
            onChange={e => hasErrors.email && validate(e)}
          />
          {hasErrors.email && (<div className="errors">Valid email address required</div>)}
        </div>
        <div className="position-relative my-3">
          <label htmlFor="first-name" className="classpoints__label font-12 color-neutral">Phone</label>
          <input
            id="phone"
            name="phone"
            className={`classpoints__input pb-2 w-100 ${hasErrors.phone && 'error'}`}
            type="phone"
            disabled={isSubmitting}
            onChange={e => hasErrors.phone && validate(e)}
          />
          {hasErrors.phone && (<div className="errors">Phone number required</div>)}
        </div>
        <button className="btn btn-primary w-100" type="submit" disabled={isSubmitting}>Redeem</button>
      </form>
    </div>
  )
}

export const SuccessGuestPassModal = ({store, profileStore}: {store: BrandStore, profileStore: ProfileStore}) => {
  profileStore.fetch()

  return (
    <div className="p-2">
      <CloseButton key={`close`} store={store} />
      <div className="font-14 font-600 pb-3">Success!</div>
      <div className="font-14">Your guest pass has been sent.</div>
      <div className="hr my-3"></div>
      <button className="btn btn-primary w-100" onClick={store!.uiStore.closeModal}>Ok</button>
    </div>
  )
}

export const ErrorGuestPassModal = ({store}: {store: BrandStore}) => {
  return (
    <div className="p-2">
      <CloseButton key={`close`} store={store} />
      <div className="font-14 font-600 pb-3">Something went wrong.</div>
      <div className="font-14">Please try again.</div>
      <div className="hr my-3"></div>
      <button className="btn btn-primary w-100" onClick={store!.uiStore.closeModal}>Ok</button>
    </div>
  )
}

export const ErrorRewardModal = ({store}: {store: BrandStore}) => {
  return (
    <div className="p-2">
      <CloseButton key={`close`} store={store} />
      <div className="font-14 font-600 pb-3">Something went wrong.</div>
      <div className="font-14">Please try again.</div>
      <div className="hr my-3"></div>
      <button className="btn btn-primary w-100" onClick={store!.uiStore.closeModal}>Ok</button>
    </div>
  )
}

export const CanadaModal = ({store}: {store: BrandStore}) => {
  return (
    <div className="p-2">
      <CloseButton key={`close`} store={store} />
      <div className="font-14 font-600 pb-3">Coming Soon</div>
      <div className="font-14">ClassPoints is not currently available in Canada</div>
      <div className="hr my-3"></div>
      <button className="btn btn-primary w-100" onClick={store!.uiStore.closeModal}>Ok</button>
    </div>
  )
}
