import {
  autorun,
  observe,
  reaction,
  IAutorunOptions,
  IValueDidChange,
} from "mobx"

// self cleaning mobx-reactions, plus some serialization suger
export default class BaseStore {
  protected reactions: Array<() => void> = []

  // self-cleaning
  dispose() {
    this.reactions.map(a => a())
  }

  autorun(fn: () => void, opts: IAutorunOptions = {}) {
    const rxn = autorun(fn, opts)
    this.reactions.push(rxn)
    return rxn
  }

  observe<T, K extends keyof T>(
    object: T,
    property: K,
    fn: (change: IValueDidChange<T[K]>) => void,
    fireImmediately?: boolean
  ) {
    const rxn = observe(object, property, fn, fireImmediately)
    this.reactions.push(rxn)
    return rxn
  }

  reaction(...args: Parameters<typeof reaction>) {
    const rxn = reaction(...args)
    this.reactions.push(rxn)
    return rxn
  }
}
