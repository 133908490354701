import React, { Component } from "react"
import { observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import Challenge from "apps/challenge/models/Challenge"

export interface Props {
  store: BrandStore
  challenge: Challenge
  url: string
}

@observer
export default class ChallengeCenterMiniCard extends Component<Props> {
  challenge = this.props.challenge
  attendanceCount = this.challenge.attendanceCount
  milestones = this.challenge.milestones
  achievedMilestones = this.milestones.filter(
    milestone => this.attendanceCount >= milestone.goal
  )
  baseGoal = this.milestones[0].goal
  achievedMilestone = this.achievedMilestones.pop()
  unachievedBrandMilestones = (this.challenge.challengeType === "brand" && this.milestones.filter(
    milestone => this.attendanceCount < milestone.goal
  )) || []
  lowestUnachievedBrandMilestone = this.unachievedBrandMilestones.length ? this.unachievedBrandMilestones[0] : null
  currentBrandMilestone = this.achievedMilestone ? this.achievedMilestone : this.lowestUnachievedBrandMilestone
  lastMilestone = this.challenge.milestones[this.challenge.milestones.length - 1]
  defaultImage = this.challenge.challengeMilestoneDefaultImage
  displayedDescription = !this.challenge.optedIn
    // Use default challenge description if not opted in
    ? this.challenge.description
    : this.optedInDescription

  workoutsComplete = this.attendanceCount > this.lastMilestone.goal
    ? this.lastMilestone.goal
    : this.attendanceCount

  get optedInDescription(): string {
    return this.challenge.challengeType === "brand"
      // brand challenges use current milestone
      ? this.currentBrandMilestone && this.currentBrandMilestone[0] ? this.currentBrandMilestone[0]!.description : this.currentBrandMilestone!.description
      // user challenges return goal zero if segment challenge not completed
      : this.challenge.attendanceCount < this.challenge.milestones[1].goal
        ? this.challenge.milestones[0].description
        : this.challenge.milestones[1].description
  }

  render() {
    return (
      <>
        { this.milestones.length ? (
          <a href={this.props.url} className="challengeCenterMiniCard">
            <div className="challengeCenterMiniCard-wrap">
              { ((this.achievedMilestone && this.achievedMilestone.prizeImage) || this.defaultImage) && (
                  <img
                    className="challengeCenterMiniCard-wrap-image"
                    src={
                      this.achievedMilestone && this.achievedMilestone!.goal !== this.baseGoal
                      ? this.achievedMilestone!.prizeImage
                      : this.defaultImage
                    }
                    alt="milestone"
                  />
                )}
              <div className="challengeCenterMiniCard-wrap-right">
                <div className="challengeCenterMiniCard-wrap-right-title">{ this.challenge.name }</div>
                <div className="challengeCenterMiniCard-wrap-right-count">
                  <span className="challengeCenterMiniCard-wrap-right-count-numbers">{this.workoutsComplete}/{ this.lastMilestone!.goal }</span>
                  workouts complete
                </div>
              </div>
            </div>
            <div className="challengeCenterMiniCard-description">{ this.displayedDescription }</div>
          </a>
        ) : (
          <a href={this.props.url} className="challengeCenterMiniCard">
            <div className="challengeCenterMiniCard-wrap">
              <div className="challengeCenterMiniCard-wrap-right">
                <div className="challengeCenterMiniCard-wrap-right-title">{ this.challenge.name }</div>
                <div className="challengeCenterMiniCard-wrap-right-count">
                  Sorry, there are no milestones for this challenge
                </div>
              </div>
            </div>
          </a>
        )}
      </>
    )
  }
}
