import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("InstructorSummary")
export default class InstructorSummary {
  /**
   * ID of the class's instructor, for looking up details. The same human being can have multiple instructor ids if they teach at multiple locations.
   */
  @JsonProperty("id", String, true)
  id?: string = undefined!

  /**
   * ClubReady ID of the instructor. This will be the same for all instructors that are the same human and can be used to dedupe.
   */
  @JsonProperty("clubready_id", String, true)
  clubreadyId?: string = undefined!

  /**
   * Display name of the instructor
   */
  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * URL to instructor's headshot image. Smaller and squarish image.
   */
  @JsonProperty("headshot_url", String, true)
  headshotUrl?: string = undefined!

  /**
   * Instructor's job title
   */
  @JsonProperty("title", String, true)
  title?: string = undefined!

  /**
   * URL for larger instructor image.
   */
  @JsonProperty("hero_url", String, true)
  heroUrl?: string = undefined!

  /**
   * Whether the instructor has a photo, extended bio information, and a webpage devoted to them.
   */
  @JsonProperty("show_bio", Boolean, true)
  showBio?: boolean = undefined!
}
