import * as React from "react"
import { Link } from "react-router-dom"

const AlertBanner: React.FunctionComponent = () => {
  const { alertBanner } = window.globals

  return (
    <>
      {alertBanner && alertBanner.enabled && (
        <div className="alert alert-primary row align-items-center mx-1 mt-3 mb-4">
          <div className="col-md-9">{alertBanner.description}</div>
          <div className="col-md-3 text-md-right">
            <Link className="btn btn-primary" to={{ pathname: alertBanner.link }} target="_blank">
              {alertBanner.cta_copy}
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default AlertBanner
