import * as React from "react"
import { observer, inject } from "mobx-react"
import { observable } from "mobx"

import BrandStore from "stores/BrandStore"
import { RouteComponentProps, Switch, Route } from "react-router"

import LocationRoute from "components/wrappers/LocationRoute"
import RewardsPage from "./RewardsPage"
import ClassPointsPage from "./ClassPointsPage"
import ActivityPage from "./ActivityPage"

export interface Props extends RouteComponentProps {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ClassPointsController extends React.Component<
  Props & RouteComponentProps
> {
  @observable optedIn: boolean = false
  @observable isLoaded: boolean = false

  public render() {
    const progressColors = {
      basic: '#ffffff',
      bronze: '#a56527',
      bronzeAlt: '#d68c47',
      silver: '#dbdbdb',
      gold: '#ffc12e'
    }

    return (
      <Switch>
        <LocationRoute
          path={`${this.props.match.path}/rewards`}
          render={props => (
            <RewardsPage {...props} store={this.props.store!} progressColors={progressColors} optedIn={this.optedIn} />
          )}
        />
        <LocationRoute
          path={`${this.props.match.path}/activity`}
          render={props => (
            <ActivityPage {...props} store={this.props.store!} />
          )}
        />
        <LocationRoute
          path={`${this.props.match.path}`}
          render={props => (
            <ClassPointsPage {...props} store={this.props.store!} progressColors={progressColors} optedIn={this.optedIn} />
          )}
        />
      </Switch>
    )
  }
}
