import React, { Component } from "react"
import { Link, Redirect } from "react-router-dom"
import { RouteComponentProps } from "react-router"
import Heading from "components/Heading"
import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import OnboardingStore from "apps/buy/stores/xpass/OnboardingStore"
import { parse } from "helpers/queryString"
import XpassBg from "images/xpass/xpass-bg.svg"

export interface Props {
  brandStore: BrandStore
}

/**
 * Offers page for users to redeem free xpass package upon registering
 */
export default class RedeemOfferPage extends Component<Props & RouteComponentProps> {
  onboardingStore = new OnboardingStore(this.props.brandStore)

  noOfferUrl() {
    const queryString = parse(this.props.location.search)
    return queryString.goTo || `/buy/${this.props.brandStore.userStore.session!.locationId}`
  }

  render() {
    return (
      <APILoader
        apiStore={this.onboardingStore}
        fetchProps={true}
        render={() => {
          const { allPackages } = this.onboardingStore
          const freePackage = allPackages.find(pkg => pkg.isXpassFree)

          return freePackage ? (
            <div className="redeem-offer mb-5 mt-md-5 text-center">
              <Heading center>{freePackage.promoTitle}</Heading>
              <p className="redeem-offer__description">
              {freePackage.promoDescription}
              </p>
              <div className="redeem-offer__card row">
                <div className="card-in delay-3 m-auto">
                  <div className="card text-left">
                    <img src={XpassBg} />
                    <div className="card-body">
                      {freePackage.promoName && (
                        <h1>
                          <span className="mb-0 d-block font-weight-normal">
                            {freePackage.promoName}
                          </span>
                        </h1>
                      )}
                    </div>
                    <div className="redeem-offer__expiration">{freePackage.promoFooter}</div>
                  </div>
                  <Link
                    className="btn btn-dark mt-4 mb-1"
                    to={`/buy/${
                      this.props.brandStore.userStore.session!.locationId
                    }/packages/${freePackage.id}`}
                  >
                    Sign Up
                  </Link>
                  <Link
                    className="btn btn-link text-center w-100"
                    to={this.noOfferUrl()}
                  >
                    <small className="text-muted">No, thanks</small>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <Redirect to={this.noOfferUrl()} />
          )
        }}
      />
    )
  }
}
