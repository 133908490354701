import * as Sentry from "@sentry/browser"
import { AxiosResponse, AxiosRequestConfig } from "axios"

type Context = { class?: string }

// all this just to track requests in breadcrumbs
export default class ApiClientBreadcrumbs {
  addRequestBreadcrumb(req: AxiosRequestConfig, ctx: Context = {}) {
    try {
      const method = req.method ? req.method.toUpperCase() : ""
      const data: Record<string, unknown> = {
        headers: req.headers,
        method,
        url: req.url,
      }
      if (req.data) {
        data.body = req.data
      }
      Sentry.addBreadcrumb({
        message: `${ctx.class || "APIClient"} ${method} ${req.url}`,
        category: "API Request",
        level: Sentry.Severity.Debug,
        data,
      })
    } catch (ex) {
      Sentry.captureException(ex)
    }
  }

  addResponseBreadcrumb(
    success: boolean,
    res: AxiosResponse,
    ctx: Context = {}
  ) {
    try {
      const req = res.config
      const method = req.method ? req.method.toUpperCase() : ""

      const msg = `${ctx.class || "APIClient"} ${method} ${req.url} [${
        res.status
      }] `

      Sentry.addBreadcrumb({
        message: msg,
        category: success ? "API Response" : "API Error",
        level: success ? Sentry.Severity.Debug : Sentry.Severity.Warning,
        data: {
          headers: res.headers,
          body: res.data,
          method,
          url: req.url,
          status_code: res.status,
        },
      })
    } catch (ex) {
      Sentry.captureException(ex)
    }
  }
}
