import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosPromise, AxiosError } from "axios"

import APIStore from "stores/APIStore"
import RoutingStore from "stores/RoutingStore"
import UserLookup, { Users } from "apps/auth/models/xpass/UserLookup"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"

export default class UserAuthStore extends APIStore {
  @observable userAccounts?: UserLookup
  @observable activeEmail: string = ""
  @observable verifiedEmails: Array<string> = []
  @observable verifiedAccounts: Users[] = []
  @observable isAdditional: boolean = false
  @observable claimedAccounts: Users[] = []
  @observable initialEmail?: string
  @observable mobileOauth: boolean = false
  @observable mobileBrand: string = ''
  @observable mobilePlatform: string = 'ios'
  @observable redirectLink?: string

  brands = [
    'akt',
    'clubpilates',
    'cyclebar',
    'purebarre',
    'rowhouse',
    'rumble',
    'stretchlab',
    'stride',
    'yogasix',
    'kinrgy'
  ]

  // For when user is directed to XPASS login/registration flow from brand portal
  param = new URLSearchParams(window.location.search)
  brandLocationId = this.param.get("brand_location_id")
  allowRedirect = this.param.get("redirect") && this.param.get("redirect") === "true" ? true : false
  brandLocationEmail = this.param.get("email")

  @computed get brandRedirectDomain() {
    const brand = this.brandLocationId && this.brandLocationId.split("-")[0]
    return brand && window.globals.brandDomains[brand]
  }

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("user_lookups", UserLookup),
  ])

  search(email: string) {
    const url = "/api/xpass/user_lookups"
    return this.api.post(url, { email })
  }

  async checkNonexistentUser(email: string) {
    const url = `/api/xpass/user_lookups/${email}`
    return await fetch(url)
      .then(async res => {
        return await res.json()
      })
      .catch(err => {
        this.handleError(err)
      })
  }

  async verifyEmail(email: string) {
    const url = "/api/v2/verify"
    const data = {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
    return await fetch(url, data)
      .then(async res => {
        if (res.ok) {
          return await res.json()
        }
        return false
      })
      .catch(err => {
        this.handleError(err)
      })
  }
  async verifyCode(token: string, email: string) {
    const url = `/api/v2/verify/${token}?email=${encodeURIComponent(email)}`
    return await fetch(url)
      .then(async res => {
        if (res.ok) {
          return await res.json()
        }
        return false
      })
      .catch(err => {
        this.handleError(err)
      })
  }

  // Initial claimable accounts under user's XPASS account email for claim accounts tab
  async matchedInitialAccounts(email: string) {
    return await this.api
      .post("/api/xpass/user_lookups", { email })
      .then(res => {
        if (res.status === 200) {
          return res.data
        }
        return []
      })
  }

  // Entered email to be verified
  @action setActiveEmail(email: string) {
    this.activeEmail = email
  }
  // Active emails that have been verified
  @action setVerifiedEmails(emails: Array<string>) {
    this.verifiedEmails = emails
  }
  // Accounts fetched from verified emails
  @action setVerifiedAccounts(verifiedAccounts: Users[]) {
    this.verifiedAccounts = verifiedAccounts
  }
  // Sets UserLookupForm to claim additional accounts
  @action setAddAdditional(isAdditional: boolean) {
    this.isAdditional = isAdditional
  }
  // Verified accounts claimed and combined for xpass user
  @action setClaimedAccounts(claimedAccounts: Users[]) {
    this.claimedAccounts = claimedAccounts
  }
  // Sets initial email to pre-populate in registration form
  @action setInitialEmail(email: string) {
    this.initialEmail = email
  }

  @action setXpassMobileData(routingStore: RoutingStore) {
    const { query } = routingStore
    const { mobile, brand, platform = 'ios' } = query
    if (mobile === 'true' && brand && this.brands.includes(brand)) {
      this.mobileOauth = true
      this.mobileBrand = brand
      this.mobilePlatform = platform
    }
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.userAccounts = res.data.user_lookups
    return res
  }

  @action.bound
  handleError(err: AxiosError) {
    console.log(err.response)
    if (err.response && err.response.status === 404) {
      return err.response
    }
    throw err
  }
}
