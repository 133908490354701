import * as React from "react"
import BrandStore from "stores/BrandStore"
import { observer, inject } from "mobx-react"

interface WaiverFieldProps {
  store?: BrandStore
  checked: boolean
  onChange(checked: boolean): void
}

const WaiverField: React.FunctionComponent<WaiverFieldProps> = ({
  store,
  checked,
  onChange,
}) => {
  return (
    <label className="text-left">
      <span>
        <input
          type="checkbox"
          checked={checked}
          onChange={e => onChange(e.currentTarget.checked)}
        />
      </span>{" "}
      <small>
        I have read and agreed to the{" "}
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            store!.uiStore.openModal({
              title: `Terms & Conditions for ${store!.brand.name} ${
                store!.locStore.currentLocation!.name
              }`,
              children: (
                <iframe
                  className="w-100 border-0"
                  style={{ height: "80vh" }}
                  src={store!.locStore.currentLocation!.waiverUrl}
                />
              ),
              modalShow: true,
              size: "lg",
            })
          }}
        >
          {`${store!.brand.name} Terms & Conditions of Service`}
        </a>
      </small>
    </label>
  )
}

export default inject((store: BrandStore) => ({ store }))(observer(WaiverField))
