import React, { useEffect, useState, Component } from "react"
import BrandStore from "stores/BrandStore"
import Purchase from "apps/account/models/Purchase"
import LocationSummary from "models/LocationSummary"
import { JsxElement } from "typescript"
import Package from "models/Package"
import PackagePlan from "models/PackagePlan"
import PurchaseStore from "apps/buy/stores/PurchaseStore"
import PurchasesStore from "apps/account/stores/PurchasesStore"
import PlanStore from "apps/buy/stores/PlanStore"
import Spinner from "components/Spinner"
import moment from "moment"

export interface Props {
  purchase: Purchase
  locationSummary: LocationSummary
  store: BrandStore
  purchasesStore: PurchasesStore
}

const ManageMembershipModal: React.FunctionComponent<Props> = ({
  purchase,
  locationSummary,
  store,
  purchasesStore
}) => {
  const [modalContent, setModalContent] = useState<JsxElement | any>()
  const [incentivePlan, setIncentivePlan] = useState<PackagePlan>()
  const [incentivePackage, setIncentivePackage] = useState<Package>()
  const uiStore = store.uiStore
  const planStore = purchasesStore.cancelIncentiveOffer && purchasesStore.cancelIncentiveOffer.packageId ? new PlanStore(store, purchasesStore.cancelIncentiveOffer.packageId, store!.locStore.currentLocation!) : null
  const purchaseStore = planStore ? new PurchaseStore(planStore, undefined, store.isXponential) : null
  const membershipExpireDate = moment().add(30, 'days').format(store!.styleClasses.PackageCancellationText_dateFormat)

  useEffect(() => {
    if (!modalContent) return

    uiStore.closeModal()
    setTimeout(() => { openModal() }, 300)
  }, [modalContent])

  useEffect(() => {
    if (!incentivePackage && !incentivePlan) return

    setModalContent(incentivePackageContent)
  }, [incentivePackage, incentivePlan])

  const abortModal = () => {
    store.track.event("purchase_cancel_tap abort", {
      loc: locationSummary,
      cancelledPkg: purchase.package,
    })
    uiStore.closeModal()
  }

  const manageClick = () => {
    store.track.event("purchase_cancel_tap manage membership", {
      loc: locationSummary,
      cancelledPkg: purchase.package,
    })
    setModalContent(manageMembershipContent)
  }

  const manageMembership = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    store.track.event("purchase_cancel_tap cancel membership", {
      loc: locationSummary,
      cancelledPkg: purchase.package,
    })

    // Always check for incentive offer before showing the cancellation window
    if (planStore && purchasesStore.cancelIncentiveOffer && purchase.package.id !== purchasesStore.cancelIncentiveOffer.packageId) {
      getIncentiveOffer()
    } else {
      // If cancellable is false, or if no incentive offer is available, show the appropriate modal content
      !purchase.cancellable
        ? setModalContent(notCancellableContent)
        : setModalContent(confirmCancelContent)
    }
  }

  const getIncentiveOffer = async () => {
    uiStore.closeModal()
    setModalContent(loading)

    await planStore!.fetch()
      .then(res => {
        if (planStore && planStore.package) {
          setIncentivePackage(planStore.package)
          setIncentivePlan(planStore.plan)
        } else {
          uiStore.openMessage(
            "message",
            `There was an error fetching offer details. Please try again.`
          )
        }
      })
      .catch(ex => {
        setModalContent(confirmCancelContent)
      })
  }

  const updatePurchase = async (updatePackage?: Package) => {
    uiStore.closeModal()
    setModalContent(loading)

    const url = updatePackage
      ? purchaseStore!.updatePurchase(purchase.id, updatePackage, incentivePlan!.todayTotal.formatted)
      : purchasesStore.cancelPurchase(purchase)

    updatePackage
      ? store.track.event("purchase_cancel_tap claim offer", {
          loc: locationSummary,
          claimedPkg: incentivePackage,
          claimedPlan: incentivePlan
        }, {
          offerName: incentivePackage!.name
        })
      : store.track.event("purchase_cancel_tap confirm", {
          loc: locationSummary,
          cancelledPkg: purchase.package,
        })

    const handleFailure = () => {
      updatePackage
          ? store.track.event("purchase_cancel claim offer failure", {
              loc: locationSummary,
              claimedPkg: incentivePackage,
              claimedPlan: incentivePlan
            }, {
              offerName: incentivePackage!.name
            })
          : store.track.event("purchase_cancel confirm failure", {
              loc: locationSummary,
              cancelledPkg: purchase.package,
            })
        uiStore.openMessage(
          "message",
          `There was an error ${
            updatePackage ? "updating" : "cancelling"
          } your plan. Please try again.`
        )
    }

    await url.then(res => {
      if (res.status === 200) {
        uiStore.closeModal()
        if (updatePackage) {
          store.track.event("purchase_cancel claim offer success", {
            loc: locationSummary,
            claimedPkg: incentivePackage,
            claimedPlan: incentivePlan
          }, {
            offerName: incentivePackage!.name
          })
          updateComplete()
        } else {
          store.track.event("purchase_cancel confirm success", {
            loc: locationSummary,
            cancelledPkg: purchase.package,
          })
          cancelComplete()
        }
      } else {
        handleFailure()
      }
    })
    .catch(ex => {
      handleFailure()
    })
  }

  const continueToCancel = () => {
    store.track.event("purchase_cancel_tap reject offer", {
      loc: locationSummary,
      claimedPkg: incentivePackage,
      claimedPlan: incentivePlan
    }, {
      offerName: incentivePackage!.name
    })
    purchase.cancellable
      ? setModalContent(confirmCancelContent)
      : setModalContent(notCancellableContent)
  }

  const openModal = () => {
    uiStore.openModal({
      children: modalContent,
      classes: 'membership-modal',
      modalShow: true,
    })
  }

  const CloseButton = () => {
    return (
      <button type="button" className="close" onClick={abortModal}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close</span>
      </button>
    )
  }

  const loading = () => {
    return (
      <div key={0} className="m-2">
        <Spinner key="spin" size="element" />
      </div>
    )
  }

  const manageMembershipContent = () => {
    return (
      <div key={0} className="m-2">
        <CloseButton key={`close`} />
        <div key={1} className="modal-title h2 mb-4">Manage Your Membership</div>
        <div key={2}>If you have questions or would like to upgrade or change your membership, please contact your studio{locationSummary.phone && ` at ${locationSummary.phone}`}.</div>
        <a key={3} href="#cancel-membership" className="d-block mt-4 text-center" onClick={e => manageMembership(e)}>Cancel Membership</a>
      </div>
    )
  }

  const incentivePackageContent = () => {
    return (
      <div key={0} className="m-2">
        <CloseButton key={`close`} />
        <div key={1} className="modal-title h2 mb-4">{store!.copy.incentiveOfferTitle}</div>
        <div key={2} className="modal__inner-content">
          {purchasesStore.cancelIncentiveOffer && (
            <>
              {purchasesStore.cancelIncentiveOffer.name && <div key={3} className="h5">{purchasesStore.cancelIncentiveOffer.name}</div>}
              {purchasesStore.cancelIncentiveOffer.description && <div key={4} className="">{purchasesStore.cancelIncentiveOffer.description}</div>}
              {(purchasesStore.cancelIncentiveOffer.name || purchasesStore.cancelIncentiveOffer.description) && <div key={5} className="hr"></div>}
              {incentivePackage && incentivePackage.name && (<div key={6} className="font-weight-bold">{incentivePackage.name}</div>)}
              {incentivePackage && incentivePackage.description && <div key={7} className="pb-3">{incentivePackage.description}</div>}
              {incentivePackage && incentivePackage.price.formatted && (<div key={8} className="">{incentivePackage.price.formatted}</div>)}
              <div key={9} className="modal__buttons d-flex flex-column flex-md-row pt-4 justify-content-between">
                <button key={10} className="btn btn-primary" onClick={() => updatePurchase(incentivePackage)}>Stay and Claim Offer</button>
                <button key={11} className="btn btn-light" onClick={() => continueToCancel()}>Continue to Cancel</button>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  const confirmCancelContent = () => {
    return (
      <div key={0} className="m-2">
        <CloseButton key={`close`} />
        <div key={1} className="modal-title h2 mb-4">Are you sure you want to cancel?</div>
        <div key={2} >
          You can freeze your membership instead and keep your rate as is. Rejoining in the future would be subject to current enrollment fees and membership pricing. Contact your studio for details on these options.
        </div>
        <div key={3} className="mt-2">
          If you cancel, it will be effective on {membershipExpireDate}. Cancellation fees may apply and next billing fees are applicable.
        </div>
        <div key={4} className="modal__buttons d-flex flex-column flex-md-row pt-4">
          <button key={5} className="btn btn-primary" onClick={abortModal}>No, Don't Cancel</button>
          <button key={6} className="btn btn-light" onClick={() => updatePurchase()}>Yes, Cancel</button>
        </div>
      </div>
    )
  }

  const notCancellableContent = () => {
    return (
      <div key={0} className="m-2">
        <CloseButton key={`close`} />
        <div key={1} className="modal-title h2 mb-4">To cancel your membership, contact your studio.</div>
        {locationSummary.phone && (<div key={2} className="modal-title modal-title__contact h2 mb-2">{locationSummary.phone}</div>)}
        {locationSummary.email && (<div key={3} className="modal-title modal-title__contact h2 mb-2">{locationSummary.email}</div>)}
        <div key={4} className="modal__buttons d-flex flex-column flex-md-row mt-3 pt-4">
          <button key={5} className="btn btn-light" onClick={uiStore.closeModal}>Close</button>
        </div>
      </div>
    )
  }

  const cancelComplete = () => {
    uiStore.openModal({
      children: (
        <div key={0} className="m-2">
          <CloseButton key={`close`} />
          <div key={1} className="modal-title h2 mb-4">Your membership has been canceled.</div>
          <div key={2} className="">We're sorry to see you go. Your membership will stop renewing on {membershipExpireDate}. Cancellation fees may apply.</div>
          <div key={3} className="modal__buttons d-flex flex-column flex-md-row pt-4">
            <button key={4} className="btn btn-light" onClick={uiStore.closeModal}>Close</button>
          </div>
        </div>
      ),
      classes: 'membership-modal',
      modalShow: true,
    })
  }

  const updateComplete = () => {
    uiStore.openModal({
      children: (
        <div key={0} className="m-2">
          <div key={1} className="modal-title h2 mb-4">Success!</div>
          <div key={2} className="">Your plan was updated successfully.</div>
        </div>
      ),
      classes: 'membership-modal',
      modalShow: true,
    })
    setTimeout(() => location.reload(), 800)
  }

  return (
    <button
      className="btn btn-light align-self-center mt-auto manage-membership"
      onClick={manageClick}
    >
      Manage Membership
    </button>
  )
}

export default ManageMembershipModal