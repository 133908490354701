import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import APIStore from "stores/APIStore"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import BrandStore from "stores/BrandStore"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import Booking from "models/Booking"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import { withAxiosError } from "helpers/errorHandling"
import { deserialize } from "helpers/serializationHelpers"
import BookingStatus from "models/BookingStatus"
import { BookingResponse } from "apps/book/stores/BookingStore"
import Room from "models/Room"
import LocationSummary from "models/LocationSummary"

export interface BookingErrorResponse extends BookingResponse {
  message?: string
  code?: string
}

// TODO: can probably combine this with BookingStore
export default class BookingCreationStore extends APIStore {
  @observable booking?: Booking
  @observable bookingStatus?: BookingStatus
  @observable seatId?: string
  @observable seatLabel?: string
  showUi = true
  isXponential = this.brandStore.isXponential

  api = this.createClient<BookingResponse>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    DeserializeMiddleware("booking_status", BookingStatus, true),
    DeserializeMiddleware("booking", Booking, true),
    DeserializeMiddleware("room", Room, true),
    DeserializeMiddleware("location", LocationSummary, true),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  protected get locationId() {
    if (this.booking) return this.booking.scheduleEntry.locationId
    return this.brandStore!.locStore.currentLocationId!
  }

  protected get baseUrl() {
    return this.isXponential ?
    `/api/xpass/v2/bookings` :
    `/api/v2/locations/${this.locationId}/bookings`
  }

  constructor(public brandStore: BrandStore) {
    super()
  }

  book(scheduleEntryId: string, seatId?: string, waitlist?: boolean, xpassLocationId?: string, userPremiumCost?: number | null) {
    if (this.isXponential) {
      return this.api.post('/api/xpass/v2/bookings/', {
        id: scheduleEntryId,
        location_id: xpassLocationId,
        user_premium_cost: userPremiumCost || null,
        platform: "web",
        seat_id: seatId
      })
    }
    return this.api.post(`${this.baseUrl}/${scheduleEntryId}`, {
      seat_id: seatId,
      waitlist,
    })
  }

  @action.bound
  setSeat(seatId?: string, seatLabel?: string) {
    // uncomment to enable toggling
    // if (this.seatId === seatId) seatId = undefined
    this.seatId = seatId
    this.seatLabel = seatLabel
  }

  @action.bound
  handleSuccess(res: AxiosResponse<BookingResponse>) {
    const location = res.data.location
    this.booking = res.data.booking
    this.bookingStatus = res.data.booking_status
    if ((this.booking = res.data.booking)) {
      this.booking.isBusy = false
      this.booking.scheduleEntry.location = location
    }
    if ((this.bookingStatus = res.data.booking_status)) {
      this.bookingStatus.scheduleEntry.location = location
    }
    return res
  }

  @action.bound
  handleError(ex: AxiosError<BookingErrorResponse>) {
    if (this.booking) this.booking.isBusy = false
    if (this.showUi) this.brandStore.uiStore.openError(ex)

    const res = withAxiosError(ex)
    if (res && res.data) {
      const location = res.data.location
      if (res.data.booking) {
        this.booking = deserialize(res.data.booking, Booking)
        this.booking.scheduleEntry.location = location
      }
      if (res.data.booking_status) {
        this.bookingStatus = deserialize(res.data.booking_status, BookingStatus)
        this.bookingStatus.scheduleEntry.location = location
      }
    }
    throw res
  }
}
