import * as React from "react"
import { observable } from "mobx"
import { observer } from "mobx-react"
import * as moment from "moment"
import Purchase from "apps/account/models/Purchase"

interface PurchasesTableProps {
  purchases: Purchase[],
  isXponential: boolean,
  purchasesTableDateFormat: string,
}

// This is a placeholder element to explore data
@observer
export default class PurchasesTable extends React.Component<
PurchasesTableProps
> {
  @observable expanded: Set<string> = new Set()

  handleDetailsToggle = (id: string) => (e: LinkEvent) => {
    e.preventDefault()
    if (this.expanded.has(id)) {
      this.expanded.delete(id)
    } else {
      this.expanded.add(id)
    }
  }

  render() {
    const { purchases, isXponential, purchasesTableDateFormat } = this.props
    return (
      <table className="table mt-4">
        <thead>
          <tr className="table-secondary">
            <th>Name</th>
            <th>Recurring</th>
            <th>Interval</th>
            <th>Expired?</th>
            <th>Paid Up-Front</th>
            <th>Recurring Price</th>
            <th>Total Price</th>
            <th>Agreed</th>
            <th>Cancelled</th>
            {!isXponential &&
              <th>Credits</th>
            }
            <th>Credits Expire</th>
            <th>Renews At</th>
          </tr>
        </thead>
        <tbody>
          {purchases.map(purchase => (
            <React.Fragment key={purchase.id}>
              <tr>
                <td>
                  <a href="#" onClick={this.handleDetailsToggle(purchase.id)}>
                    {purchase.package.name}
                  </a>
                </td>

                <td>{purchase.isRecurring ? "Recurring" : "No"}</td>
                <td>
                  {purchase.package.interval} ({purchase.package.intervalCount})
                </td>
                <td>{purchase.seemsExpired ? "Expired" : "No"}</td>
                <td>{purchase.paidUpfront.formatted}</td>
                <td>
                  {purchase.recurringPrice && purchase.recurringPrice.formatted}
                </td>
                <td>{purchase.totalPrice.formatted}</td>
                <td>
                  <DateInfo date={purchase.agreedAt} purchasesTableDateFormat={purchasesTableDateFormat} />
                </td>
                <td>
                  <DateInfo date={purchase.cancelledAt} purchasesTableDateFormat={purchasesTableDateFormat} />
                </td>
                {!isXponential &&
                  <td>
                    {purchase.creditCountRemaining} / {purchase.creditCountTotal}{" "}
                  </td>
                }
                <td>
                  <DateInfo date={purchase.creditsExpireAt} purchasesTableDateFormat={purchasesTableDateFormat} />
                </td>
                <td>
                  <DateInfo date={purchase.renewsAt} purchasesTableDateFormat={purchasesTableDateFormat} />
                </td>
              </tr>
              {this.expanded.has(purchase.id) && (
                <>
                  <tr>
                    <th>Invoices</th>
                    <td colSpan={9}>
                      <table className="table table-sm table-bordered">
                        <thead>
                          <tr className="table-secondary">
                            <th>Name</th>
                            <th>Status</th>
                            <th>Amount Due</th>
                            <th>Due At</th>
                            <th>Paid At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchase.invoices.map((invoice, i) => (
                            <tr key={i}>
                              <td>{invoice.name}</td>
                              <td>{invoice.status}</td>
                              <td>{invoice.amountDue.formatted}</td>
                              <td>{invoice.dueAt}</td>
                              <td>{invoice.paidAt}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {!!purchase.credits && !!purchase.credits.length && (
                    <tr>
                      <th>Credits</th>
                      <td colSpan={9}>
                        <table className="table table-sm table-bordered">
                          <thead>
                            <tr className="table-secondary">
                              <th>Name</th>
                              <th>Type</th>
                              <th>Expires At</th>
                              <th>Created At</th>
                              <th>On Hold?</th>
                            </tr>
                          </thead>
                          <tbody>
                            {purchase.credits.map(credit => (
                              <tr key={credit.id}>
                                <td>{credit.name}</td>
                                <td>{credit.creditType}</td>
                                <td>
                                  <DateInfo date={credit.expiresAt} purchasesTableDateFormat={purchasesTableDateFormat} />
                                </td>
                                <td>
                                  <DateInfo date={credit.createdAt} purchasesTableDateFormat={purchasesTableDateFormat} />
                                </td>
                                <td>{credit.onHold}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    )
  }
}

interface DateInfoProps {
  date?: moment.Moment
  purchasesTableDateFormat: string
  label?: string
}
const DateInfo = ({ date, purchasesTableDateFormat, label }: DateInfoProps) => {
  if (!date) return null

  return (
    <p className="my-0 text-right">
      {label && <strong>{label}: </strong>}
      <span className="text-nowrap">{moment(date).format(purchasesTableDateFormat)}</span>
    </p>
  )
}
