import * as React from "react"
import { observer, inject } from "mobx-react"
import cx from "classnames"
import { Swiper, SwiperSlide } from 'swiper/react'

import BrandStore from "stores/BrandStore"
import ChallengeStore from "apps/challenge/stores/ChallengeStore"
import SwiperButton from 'apps/challenge/components/SwiperButton';
import APILoader from "components/wrappers/APILoader"
import ChallengeCenterMiniCard from "./ChallengeCenterMiniCard"

interface Props {
  className?: string
  store: BrandStore
  challengeStore: ChallengeStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ChallengeCenterCard extends React.Component<Props> {

  handleOptInChallenge = (e: ButtonEvent) => {
    e.preventDefault()
    this.props.challengeStore.optIn()
  }

  render() {
    const challengeStore = this.props.challengeStore
    const challengeUrl = '/challenges'

    return (
      <div className={cx("card", this.props.className)}>
        <div className="card-header d-flex justify-content-between align-items-center achievements-card">
          <h5 className="achievements-card-title text-dark">Challenges</h5>
          <small><a href={challengeUrl}>View All</a></small>
        </div>
        <div className="card-body d-md-flex flex-column">
          <APILoader
            apiStore={challengeStore}
            elementSize="page"
            fetchProps={{ completed: false }}
            render={() => {
              const challenges = challengeStore.challenges

              return (
                <Swiper
                  spaceBetween={24}
                  slidesPerView={1}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                  className="challengeCenterCardsContainer"
                >
                  <SwiperButton direction="prev" />
                  <SwiperButton direction="next" />
                  { challenges && challenges.map((challenge, index) => {
                    return (
                      <SwiperSlide key={`slide-${index}`}>
                        <ChallengeCenterMiniCard
                          key={`challenge-${index}`}
                          store={this.props.store!}
                          challenge={challenge}
                          url={challengeUrl}
                        />
                      </SwiperSlide>
                    )
                  }) }
                </Swiper>
              )
            }}
          />
          <a className="btn btn-primary mt-auto mb-0 btn-block" href={challengeUrl}>All Challenges</a>
        </div>
      </div>
    )
  }
}
