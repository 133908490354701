import * as React from "react"
import * as cx from "classnames"

export interface Props {
  submitText?: React.ReactChild
  cancelText?: React.ReactChild
  disabled?: boolean
  className?: string
  // Rule is: alignment should match alignment of the page's header
  centered?: boolean
  showCancelButton?: boolean
  onSubmit?(e: ButtonEvent): void
  onCancel?(e: ButtonEvent): void
  form?: string
  isSingleCheckout?: boolean
  minWidth?: number
}

export default class FormButtons extends React.Component<Props, {}> {
  handleSubmit = (e: ButtonEvent) => {
    if (this.props.onSubmit) {
      e.preventDefault()
      this.props.onSubmit(e)
    }
  }

  handleCancel = (e: ButtonEvent) => {
    e.preventDefault()
    if (this.props.onCancel) {
      this.props.onCancel(e)
    }
  }

  public render() {
    const hasCancel = !!this.props.onCancel && this.props.showCancelButton
    const buttonStyle = { minWidth: 120 }

    return (
      <div className={this.props.className}>
        <div className={cx({ "row": !this.props.isSingleCheckout }, "mt-3")} style={{ minWidth: this.props.minWidth || 290 }}>
          <div
            className={cx({ "col-lg-12": !this.props.isSingleCheckout,
              "justify-content-center": this.props.centered,
              "text-center": this.props.centered,
            })}
          >
            <button
              className="btn btn-primary"
              style={buttonStyle}
              onClick={this.handleSubmit}
              disabled={this.props.disabled}
              type="submit"
              form={this.props.form}
            >
              {this.props.submitText || "Submit"}
            </button>
            {hasCancel && (
              <button
                className="btn btn-secondary ml-3 "
                style={buttonStyle}
                onClick={this.handleCancel}
                disabled={this.props.disabled}
                form={this.props.form}
              >
                {this.props.cancelText || "Cancel"}
              </button>
            )}
          </div>
        </div>

        {this.props.children && (
          <div className="row">
            <div className="col-md-12">{this.props.children}</div>
          </div>
        )}
      </div>
    )
  }
}
