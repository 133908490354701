import * as React from "react"
import { inject, observer } from "mobx-react"
import { InjectedFormikProps, withFormik, Form, FormikBag } from "formik"
import * as yup from "yup"

import BrandStore from "stores/BrandStore"
import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"
import PasswordUpdateStoreSPC from "apps/account/stores/xpass/PasswordUpdateStoreSPC"
import { LoginValues } from "apps/auth/stores/AuthStore"
import Spinner from "components/Spinner"

export interface BaseProps {
  store: BrandStore
}

export interface FormValuesSPC {
  password: string
  password_confirmation: string
}

export interface State {
  submitting: boolean
}

type Props = InjectedFormikProps<BaseProps, FormValuesSPC>

class SetPasswordSPCInner extends React.Component<Props, State, {}> {
  constructor(props: Props) {
    super(props)
    this.state = {
      submitting: false
    }
  }

  componentDidMount() {
    this.props.store!.uiStore.hideNavLinks()
    this.props.store!.uiStore.lockLocationPicker()
  }

  componentWillUnmount() {
      this.props.store!.uiStore.showNavLinks()
      this.props.store!.uiStore.unlockLocationPicker()
  }

  render() {
    const { isSubmitting } = this.props

    const onSubmit = async () => {
      const errors = await this.props.validateForm()
      if (JSON.stringify(errors) === '{}') {
        this.setState({submitting: true
        },()=>{
         this.props.handleSubmit()
        })
      }
    }

    return (
      <div className="spc">
        {!this.state.submitting && (
          <Form noValidate className="set-password float-in delay-3">
            <h2>Set Your Password</h2>
            <div className="row">
              <FormInput
                label="Choose a password"
                name="password"
                className="col"
                maxLength={128}
                minLength={6}
                type="password"
              />
            </div>
            <div className="row">
              <FormInput
                label="Confirm your password"
                name="password_confirmation"
                className="col"
                maxLength={128}
                minLength={6}
                type="password"
              />
            </div>
            <FormButtons submitText="Continue" disabled={isSubmitting} onSubmit={onSubmit} />
          </Form>
        )}
        {this.state.submitting &&
          <Spinner size={"page"} className="mx-auto brandCheckout-spinner" isSingleCheckout={true} />
        }
      </div>
    )
  }
}

const schema = yup.object<FormValuesSPC>().shape({
  password: yup
    .string()
    .min(6, "Your new password must be at least 6 characters long.")
    .max(128, "Your new password may not be more than 128 characters long.")
    .required("Please select a new password."),
  password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Your New Password and Confirmation must match."
    )
    .required("Please repeat your new password for confirmation."),
})

const handleSubmit = async (
  values: FormValuesSPC,
  formikBag: FormikBag<BaseProps, FormValuesSPC>
) => {
  const { props } = formikBag
  const { uiStore, authStore, userStore } = props.store!
  const updateStore: PasswordUpdateStoreSPC = new PasswordUpdateStoreSPC(formikBag.props.store!)
  const handleUpdate = (values: FormValuesSPC) => updateStore.update(values)
  const signInValues: LoginValues = {
      locationId: 'xponential-xpass',
      email: userStore.session!.email || '',
      password: values.password,
      mobileOauth: false,
      mobileBrand: '',
      mobilePlatform: '',
    }

  try {
    await handleUpdate(values)
    formikBag.resetForm()
  } catch (error) {
    const message =
      (error.response && error.response.data.message) ||
      "Sorry, there was a problem while updating your password. Please try again."

    uiStore.openMessage("error", message, "Error")
    formikBag.setSubmitting(false)
  }

  try {
    await authStore.signIn(signInValues)
    formikBag.props.store!.routingStore.history.push(`/simple-checkout/claim-account`)
  } catch (error) {
    const message =
      (error.response && error.response.data.message) ||
      "Sorry, there was a problem signing in. Please try again."

    uiStore.openMessage("error", message, "Error")
    formikBag.setSubmitting(false)
  }
}

const SetPasswordSPC = inject((store: BrandStore) => ({ store }))(
  observer(
    withFormik<BaseProps, FormValuesSPC & { store?: BrandStore }>({
      mapPropsToValues: (props) => {
        const user = props.store!.userStore.session!

        return {
          access_token: props.store!.routingStore.query.access_token,
          password: "",
          password_confirmation: "",
        }
      },
      validationSchema: schema,
      handleSubmit
    })(SetPasswordSPCInner)
  )
)

export default SetPasswordSPC
