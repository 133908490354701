import React, { Component } from "react"
import * as cx from "classnames"

interface LocalState {
  term: string
}
export interface SearchBarProps {
  onSearchTermChange: Function
  className?: string
}

export default class SearchBar extends Component<SearchBarProps, {}> {
  state: LocalState

  constructor(props: SearchBarProps) {
    super(props)
    this.state = { term: "" }
  }

  onInputChange(term: string) {
    this.setState({ term })
    this.props.onSearchTermChange(term)
  }

  render() {
    return (
      <div
        className={cx("input-group col-md-12 px-0 my-2", this.props.className)}
      >
        <input
          type="search"
          className="form-control input-lg px-1"
          placeholder="Studio Name, State, City, Zip"
          value={this.state.term}
          onChange={e => this.onInputChange(e.target.value)}
        />
      </div>
    )
  }
}
