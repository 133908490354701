import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosPromise } from "axios"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import { observable, action, computed } from "mobx"
import ScheduleEntry from "models/ScheduleEntry"
import XpassScheduleEntry from "apps/book/models/xpass/ScheduleEntry"
import LocationSummary from "models/LocationSummary"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import BrandStore from "stores/BrandStore"
import BookingsStore from "apps/bookings/stores/BookingsStore"
import Duration, { DurationProps } from "helpers/Duration"

interface ScheduleEntriesResponse {
  // meta: ScheduleEntryMeta
  schedule_entries: ScheduleEntry[]
}

export interface Params {
  start_date?: string
  end_date?: string
  class_category_id?: string
  instructor_id?: string
  class_type_id?: string
  locations?: string
}

export type FilterKey = "instructor" | "classType" | "classCategory"
export type Filter = { [key in FilterKey]?: string }

export default class ScheduleStore extends APIStore {
  @observable entries: ScheduleEntry[] = []
  // @observable metadata: ScheduleEntryMeta = {}
  @observable filters: Filter = {
    classType: "",
    instructor: "",
    classCategory: "",
  }

  @observable week: Duration
  param = this.brandStore.isXponential && new URLSearchParams(window.location.search)
  locationId = this.param && this.param.get("location_id")

  bookingsStore = new BookingsStore(this.brandStore, false)

  @computed
  get filteredEntries(): ScheduleEntry[] {
    return this.entries.filter((entry: ScheduleEntry) => {
      let catMatches = true
      const catFilter = this.filters.classCategory
      const catClass = entry.classCategory
      if (catFilter) {
        if (!catClass) {
          catMatches = false
        } else {
          catMatches = catClass.name === catFilter || catClass.id === catFilter
        }
      }

      const instrFilter = this.filters.instructor
      const instrClass = entry.instructor
      let instrMatches =
        !instrFilter || (instrClass && instrClass.name === instrFilter)

      return catMatches && instrMatches
    })
  }

  @computed
  get firstEntryId() {
    const firstEntry = this.filteredEntries.find(
      entry => entry.dateString === this.week.date
    )

    return firstEntry && firstEntry.id
  }

  // @computed
  // get days(): string[] {
  //   let days = []
  //   const { startAt, endAt } = this.week
  //   for (const d = startAt.clone(); d < endAt; d.add(1, "d")) {
  //     days.push(d.clone().format("YYYY-MM-DD"))
  //   }
  //   return days
  // }

  api = this.brandStore.isXponential ? 
  this.createClient([
    ResponseMiddleware(this.handleSuccess),
    // TODO: Fix caching. Moving two weeks forward, then two weeks back, breaks
    // MemoizeMiddleware,
    DeserializeMiddleware("schedule_entries", XpassScheduleEntry),
    TokenAuthMiddleware(this.brandStore.userStore)
]) : 
  this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("schedule_entries", ScheduleEntry)
  ])

  constructor(
    public location: LocationSummary,
    public brandStore: BrandStore,
    durationProps?: DurationProps
  ) {
    super()
    this.week = new Duration(durationProps)
  }

  fetch(params: Params = {}) {
    // const url = `/api/locations/${this.location.id}/schedule_entries`
    const url = this.brandStore.isXponential ? `/api/xpass/v2/schedule_entries` : `/api/v2/locations/${this.location.id}/schedule_entries`
    const urlParam = this.brandStore.isXponential ? {
      start_start_time: this.week.startDate,
      end_time: this.week.endDate,
      ...params,
    } : {
      start_date: this.week.startDate,
      end_date: this.week.endDate,
      ...params,
    }

    const promise = this.api.get(url, { params: urlParam })

    if (this.brandStore.userStore.isLoggedIn) {
      this.awaitBookings(promise)
    }

    return promise
  }

  @action.bound
  setDate(date: string) {
    this.week.date = date
  }

  @action.bound
  handleSuccess(res: AxiosResponse<ScheduleEntriesResponse>) {
    // this.metadata = res.data.meta
    this.entries = res.data.schedule_entries
    this.week.loaded = true
    return res
  }

  dispose() {
    this.bookingsStore.dispose()
    super.dispose()
  }

  // pairs booking ids to schedule entries
  // kind of a gross way to do it all-around
  protected awaitBookings(schedulePromise: AxiosPromise) {
    const params = this.brandStore.isXponential && this.locationId ? {
      start_date: this.week.startDate,
      end_date: this.week.endDate,
      location_id: this.locationId,
    } : {
      start_date: this.week.startDate,
      end_date: this.week.endDate,
      include_waitlist: true,
      location_id: this.location.id,
    }
    const bookingsPromise = this.bookingsStore.fetch(params)

    Promise.all([schedulePromise, bookingsPromise]).then(this.pairBookings)
  }

  @action.bound
  protected pairBookings() {
    for (const entry of this.entries) {
      const booking = this.bookingsStore.bookedBookings.find(
        b => b.scheduleEntry.clubreadyId === entry.clubreadyId
      )

      entry.booking = booking
      if (booking) booking.scheduleEntry = entry
    }
  }
}
