import React, { Component } from "react"
import { observer } from "mobx-react"
import cx from "classnames"

import APILoader from "components/wrappers/APILoader"
import DashboardApiStore from "apps/dashboard/stores/DashboardApiStore"
import BrandStore from "stores/BrandStore"
import AppleHealth from "images/apple_health/health.svg"
import AppleFitness from "images/apple_health/fitness.svg"
import AppleWatch from "images/apple_health/watch.svg"
import Tick from "images/icons/tick.svg"

interface Props {
  className?: string
  store: BrandStore
  dashboardStore: DashboardApiStore
}

const images = [
  { image: AppleHealth, alt: "Apple Health icon", description: "Track Data in Health App" },
  { image: AppleFitness, alt: "Apple Fitness+ icon", description: "Record workouts in Fitness App" },
  { image: AppleWatch, alt: "Apple Watch icon", description: "Close Activity Rings" }
]

@observer
export default class AppleHealthCard extends Component<Props> {
  render() {
    return (
      <div className={cx("card apple-health-card", this.props.className)}>
        <APILoader
          apiStore={this.props.dashboardStore}
          elementSize="element"
          render={() => {
            const { user } = this.props.dashboardStore
            const { brand, styleClasses } = this.props.store

            return (
              user && (
                <>
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="text-dark">Connect Health App</h5>
                  </div>
                  <div className="card-body flex-grow-1 d-flex flex-column align-items-center">
                    <div>
                      {images.map((item, index) => (
                        <img className="mx-1" src={item.image} alt={item.alt} key={index} />
                      ))}
                    </div>
                    <div>
                      <h5 className={cx("text-center mt-1 mb-0", styleClasses.AppleHealthCard__title)}>Connect Apple Health to:</h5>
                      <ul style={{ listStyleType: "none" }}>
                        {images.map((item, index) => (
                          <li key={index}>
                            <img src={Tick} style={{ paddingRight: "6px" }} alt="Tick symbol" />
                            <span className={styleClasses.AppleHealthCard__listItem} key={index}>{item.description}</span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <button
                      className="btn btn-primary mt-auto w-100"
                      onClick={() => {
                        if (brand.iosAppUrl) {
                          return (
                            window.open(brand.iosAppUrl, "_blank") ||
                            window.location.replace(brand.iosAppUrl)
                          )
                        }
                        return
                      }}
                      disabled={user.healthKitConnected}
                    >
                      {user.healthKitConnected
                        ? "Connected"
                        : "Set Up in iOS"}
                    </button>
                  </div>
                </>
              )
            )
          }}
        />
      </div>
    )
  }
}
