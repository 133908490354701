import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'
// stores
import ScheduleEntryLocationStore from 'apps/book/stores/xpass/ScheduleEntryLocationStore'
import ScheduleEntryStore from 'apps/book/stores/xpass/ScheduleEntryStore'
import BrandStore from 'stores/BrandStore'
// types
import FiltersStore, { FilterCategory } from 'apps/book/stores/xpass/FiltersStore'
// utils
import moment from 'moment'
import flatpickr from 'flatpickr'
import Slider, { Range } from "rc-slider"
// icons
import CalendarIcon from "images/xpass/icons/calendar.svg"
import closeIcon from "images/xpass/icons/close.svg"

interface CategoryFilterProps {
  title: string;
  categories: FilterCategory[]
  selectedCategories: string[] | string | null
  handleClick: (id: string) => void;
}

const Category = ({title, categories, selectedCategories, handleClick}: CategoryFilterProps) => {
  return (
    <>
      <h4 className="filters-modal__filter-title">{title}</h4>
      <div className="filters-modal__categories filter-section">
        {categories.map((category) => {
          const isSelected = selectedCategories && selectedCategories.includes(category.id)
          return (
          <div className={`filters-modal__category ${isSelected? "selected-category" : "" }`} key={category.id} onClick={() => handleClick(category.id)}>
            <p className="filters-modal__category-title">{category.name}</p>
          </div>
        )})}
      </div>
    </>
  )
}


interface FilterModalProps {
  store: BrandStore;
  filtersStore: FiltersStore;
  activeTab: string;
  filterEntries: Function;
  scheduleEntryStore: ScheduleEntryStore;
  scheduleEntryStoreHot: ScheduleEntryStore;
  scheduleEntryStoreNow: ScheduleEntryStore;
}

export const FiltersModal = observer(({
  store,
  filtersStore,
  activeTab,
  filterEntries,
  scheduleEntryStore,
  scheduleEntryStoreHot,
  scheduleEntryStoreNow,
}: FilterModalProps) => {

  function handleCategoryClick(id: string) {
    const isDuplicate = filtersStore.selectedCategories.includes(id)
    const updatedCategories = isDuplicate ? filtersStore.selectedCategories.filter((x) => x !== id) : [...filtersStore.selectedCategories, id]
    filtersStore.setSelectedCategories(updatedCategories)
  }
  function handleBodyFocusClick(id: string) {
    const isDuplicate = filtersStore.selectedBodyFocuses.includes(id)
    const updatedBodyFocuses = isDuplicate ? filtersStore.selectedBodyFocuses.filter((x) => x !== id) : [...filtersStore.selectedBodyFocuses, id]
    filtersStore.setSelectedBodyFocuses(updatedBodyFocuses)
  }
  function handleIntensityClick(id: string) {
    const isDuplicate = filtersStore.selectedIntensities.includes(id)
    const updatedIntensities = isDuplicate ? filtersStore.selectedIntensities.filter((x) => x !== id) : [...filtersStore.selectedIntensities, id]
    filtersStore.setSelectedIntensities(updatedIntensities)
  }
  function applyFilters() {
    scheduleEntryStore.setStartTimeRange(filtersStore.startTimeRange)
    scheduleEntryStoreHot.setStartTimeRange(filtersStore.startTimeRange);
    [scheduleEntryStore, scheduleEntryStoreHot, scheduleEntryStoreNow].forEach((store) => {
      store.setDistance(filtersStore.distance)
      store.setModalities(filtersStore.selectedCategories)
      store.setBodyFocuses(filtersStore.selectedBodyFocuses)
      store.setIntensities(filtersStore.selectedIntensities)
      store.setExcludePremium(filtersStore.isBookableWithToken)
      store.setRequireEligibleToken(filtersStore.isBookableWithToken)
      filtersStore.selectedBrands.length
        ? store.setBrands(filtersStore.selectedBrands)
        : store.setBrands([])
      store.setIsFiltered(true)
    });
    filterEntries([scheduleEntryStore, scheduleEntryStoreHot, scheduleEntryStoreNow])
    store.uiStore.setHasAppliedFilters(filtersStore.hasFilters)
    store.uiStore.hideFiltersModal()
  }

  function toggleBookableWithToken() {
    filtersStore.setIsBookableWithToken(!filtersStore.isBookableWithToken)
  }

  return (
    <div className="filters-modal-wrapper">
      <div className="filters-modal">
        <div className="filters-modal__header">
          <p>Filters</p>
          <button className="filters-modal__close-button" onClick={() => store.uiStore.hideFiltersModal()}>
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="filters-modal__body">
          <DistanceFilter filtersStore={filtersStore} />
          {(activeTab === "value" || activeTab === "stretch") && <StartTimeFilter filtersStore={filtersStore} /> }
          <div className="filters-modal__token my-2">
            <input checked={filtersStore.isBookableWithToken} onChange={toggleBookableWithToken} type="checkbox" id="token-checkbox" />
            <label htmlFor="token-checkbox">Book Now</label>
          </div>
          {(activeTab === "value" || activeTab === "featured" || activeTab === "now") &&
          <Category
              title="Categories"
              categories={filtersStore.categories}
              selectedCategories={filtersStore.selectedCategories}
              handleClick={handleCategoryClick}
            />
          }
          {activeTab !== "stretch" && activeTab !== "random" && (
            <>
              <h4 className="filters-modal__filter-title">Brands</h4>
                <div className="filters-modal__brands filter-section">
                  {filtersStore.brands.map((brand) => {
                    return (
                      <div
                        className={`filters-modal__brand ${filtersStore.selectedBrands.includes(brand.id) ? "selected" : ""}`}
                        key={brand.id}
                        onClick={() => filtersStore.setSelectedBrands(brand.id)}
                      >
                        <div className="brand-image">
                          <img className="brand-logo" src={brand.logo} alt={`${brand.name}-logo`} />
                          <img className="brand-preview" src={brand.preview} alt={`${brand.name}-preview`} />
                        </div>
                        <p className="brand-name">{brand.name}</p>
                      </div>
                      )
                    })}
                </div>
              </>
          )}
          {/* Hiding filters from view. If this does not get added back to the application, a full removal from the code base can be done. */}
          {/* {(activeTab === "value" || activeTab === "featured" || activeTab === "now") &&
            <>
              <Category title="Body Focus" categories={filtersStore.bodyFocuses} selectedCategories={filtersStore.selectedBodyFocuses} handleClick={handleBodyFocusClick}/>
              <Category title="Intensity" categories={filtersStore.intensities} selectedCategories={filtersStore.selectedIntensities} handleClick={handleIntensityClick}/>
            </>
          } */}
        </div>
        <div className="filters-modal__controls d-flex">
          <button className="filters-modal__button reset" onClick={() => filtersStore.resetFilters()}>Clear All</button>
          <button className="filters-modal__button apply" onClick={applyFilters}>Apply Filters</button>
        </div>
      </div>
    </div>
  )
})


interface DateFilterProps {
  scheduleEntryStore: ScheduleEntryStore
  scheduleEntryStoreHot: ScheduleEntryStore
  scheduleEntryLocationStore: ScheduleEntryLocationStore
  filterEntries: Function
  dateFormat: string
}

export const DateFilter: React.FC<DateFilterProps> = (props) => {
  const inputRef = React.createRef<HTMLInputElement>()
  const currentDate = moment().format("MM/DD/YYYY").toString()
  const maxDate = moment().add(13, "days").format("MM/DD/YYYY")
  const startDate = props.scheduleEntryStore.startDate ? props.scheduleEntryStore.startDate : currentDate
  const [dateSelected, setDateSelected] = useState(startDate)
  const [filterEnabled, setFilterEnabled] = useState<boolean | undefined>(undefined)
  let defaultDate = moment(dateSelected, "MM/DD/YYYY").format("MM/DD/YYYY")
  const filterEntriesByDate = (date: string) => {
    [props.scheduleEntryStore, props.scheduleEntryStoreHot].forEach((store) => {
      store.setStartDate(date)
      store.setIsFiltered(true)
      store.setHighlightedStudio(undefined)
    })
    props.scheduleEntryLocationStore.resetDisabledLocations()
    props.scheduleEntryLocationStore.resetEnabledLocations()
    props.scheduleEntryLocationStore.fetch()
    props.filterEntries([props.scheduleEntryStore, props.scheduleEntryStoreHot])
  }
  const onChange = (selectedDate: Date[]) => {
    const date = moment(selectedDate[0], "MM/DD/YYYY").format("MM/DD/YYYY")
    setDateSelected(date)
    if (dateSelected.length !== 0 && date !== dateSelected) {
      filterEntriesByDate(date)
    }
  }

  useEffect(() => {
    const { isFiltered } = props.scheduleEntryStore
    // Reset selectedDate when filters are reset
    if (isFiltered) {
      setFilterEnabled(true)
    }
    if (!isFiltered && filterEnabled) {
      setDateSelected(currentDate)
      setFilterEnabled(undefined)
    }
  }, [props.scheduleEntryStore.isFiltered])

  useEffect(() => {
    flatpickr(inputRef.current!, {
      enableTime: false,
      dateFormat: props.dateFormat,
      minDate: currentDate,
      maxDate,
      disableMobile: true,
      defaultDate: defaultDate,
      onChange: selectedDate => onChange(selectedDate),
      onClose: selectedDate => onChange(selectedDate)
    })
  })

    return (
      <div className={"book-class-subnav__date-filter default-date"}>
      <input type="text" ref={inputRef} placeholder="Today" />
      {dateSelected === currentDate ? (
        <p>Today</p>
      ) : (
        <p>{moment(dateSelected, "MM/DD/YYYY").format("ddd, MMM D")}</p>
      )}
      <a className="book-class-subnav__calendar-icon">
        <img src={CalendarIcon} />
      </a>
    </div>
    )
  }


interface TimeFilterProps {
  filtersStore: FiltersStore
}

const StartTimeFilter: React.FunctionComponent<TimeFilterProps> = ({filtersStore}) => {
  const convertTime = (num: number) => {
    const time = (num * 15) / 60 + 5
    const splitTime = time.toString().split(".")
    const toMinutes = Number("." + splitTime[1]) * 60
    const setMinutes = isNaN(toMinutes) ? "00" : toMinutes
    const setHour = Number(splitTime[0])
    return `${setHour}:${setMinutes}`
  }
  const [startTimes, setStartTimes] = useState(filtersStore.startTimeRange)

  useEffect(() => {
    setStartTimes(filtersStore.startTimeRange)
  }, [filtersStore.startTimeRange])

  return (
    <div className="filters-modal__time my-2">
      <div className="filters-modal__slider d-flex justify-content-between">
        <h4 className="filters-modal__filter-title">Start Time</h4>
        <p>
          {moment(convertTime(startTimes[0]), "H:mm").format("h:mm a")} -{" "}
          {moment(convertTime(startTimes[1]), "H:mm").format("h:mm a")}
        </p>
      </div>
      <div className="filters-modal__range px-2">
        <Range
          value={startTimes}
          min={0}
          max={68}
          trackStyle={[{ backgroundColor: "#212C39" }]}
          handleStyle={[
            {
              backgroundColor: "#212C39",
              borderColor: "#fff",
              height: "24px",
              width: "24px",
              marginTop: "-10px",
            },
            {
              backgroundColor: "#212C39",
              borderColor: "#fff",
              height: "24px",
              width: "24px",
              marginTop: "-10px",
            },
          ]}
          railStyle={{ backgroundColor: "#C4C4C4" }}
          activeDotStyle={{ boxShadow: "0 0 0 5px #fff" }}
          onChange={range => setStartTimes(range)}
          onAfterChange={range => filtersStore.setStartTimeRange(range)}
        />
      </div>
    </div>
  )
}


interface DistanceFilterProps {
  filtersStore: FiltersStore
}

const DistanceFilter: React.FunctionComponent<DistanceFilterProps> = ({filtersStore}) => {
  const [distance, setDistance] = useState(filtersStore.distance)

  useEffect(() => {
    autorun(() => {
      setDistance(filtersStore.distance)
    })
  }, [filtersStore.distance])

  return (
    <div className="filters-modal__distance mt-2 mb-5">
      <div className="filters-modal__slider d-flex justify-content-between">
        <h4 className="filters-modal__filter-title mb-2">Distance</h4>
      </div>
      <div className="filters-modal__range px-2">
        <Slider
          value={distance}
          min={0}
          max={4}
          marks={{
            0: "0.5 mi",
            1: "1 mi",
            2: "3 mi",
            3: "5 mi",
            4: "10 mi +",
          }}
          trackStyle={{ backgroundColor: "#212C39" }}
          handleStyle={{
            backgroundColor: "#212C39",
            borderColor: "#fff",
            height: "24px",
            width: "24px",
            marginTop: "-10px",
          }}
          railStyle={{ backgroundColor: "#C4C4C4" }}
          activeDotStyle={{ boxShadow: "0 0 0 5px #fff" }}
          onChange={value => setDistance(value)}
          onAfterChange={value => filtersStore.setDistance(value)}
        />
      </div>
    </div>
  )
}
