import React, { useLayoutEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import cx from "classnames"
import BrandStore from "stores/BrandStore"
import PurchasesStore from "apps/account/stores/PurchasesStore"
import Package from "models/Package"
import Purchase from "apps/account/models/Purchase"
import LocationSummary from "models/LocationSummary"
import PackageRenewalText from "apps/buy/components/PackageRenewalText"
import { ConfirmDialog } from "components/ConfirmDialog"
import PurchaseStore from "apps/buy/stores/PurchaseStore"
import PlanStore from "../stores/PlanStore"
import ReactTooltip from "react-tooltip"
import helpIcon from "images/icons/help.svg"
import ClaimAccountModalOnBook from "apps/dashboard/ClaimAccountModal"

interface PackageCardProps {
  pkg: Package
  locationSummary: LocationSummary
  queryString?: string
  store: BrandStore
  purchasesStore?: PurchasesStore
  purchaseDisabled: boolean
  updateType?: string
  purchase?: Purchase
  activePurchases?: Purchase[]
  onClick?(): void
  filterKey?: string
  isXpassEligible?: boolean
}

const PackageCard: React.FunctionComponent<PackageCardProps> = props => {
  const {
    pkg,
    locationSummary,
    queryString,
    store,
    purchasesStore,
    purchaseDisabled,
    updateType,
    activePurchases,
    onClick,
    filterKey,
    isXpassEligible
  } = props
  const { uiStore, copy } = store
  const planStore = new PlanStore(store, pkg.id, store!.locStore.currentLocation!)
  const purchaseStore = new PurchaseStore(planStore, undefined, store.isXponential)
  let billCycle = pkg.intervalCount === 365 && pkg.interval === 'day' ? '/ year' : '/ month'
  if (!pkg.isRecurring) billCycle = ''

  const activePurchase = purchasesStore && purchasesStore.purchases.find(
    purchase => purchase.package.id === pkg.id && !purchase.isCancelled
  )
  const hasMultiplePurchases = activePurchases && activePurchases.length > 1
  const [selectedForChange, setSelectedForChange] = useState<Purchase | undefined>(activePurchases && activePurchases[0] || activePurchase || undefined)
  const hasLoaded = useRef(true)
  useLayoutEffect(() => {
    if (hasLoaded.current) {
      hasLoaded.current = false
      return
    }
    updateType && handleModal(updateType)
  }, [selectedForChange])

  const updatePurchase = async (type: string, planPkg: Package) => {
    const currentPurchase = selectedForChange
      ? [selectedForChange]
      : purchasesStore && purchasesStore.purchases.filter(purchase => !purchase.isCancelled)
    const cancelPurchase = purchasesStore && purchasesStore.purchases.filter( purchase =>
      purchase.package.id === planPkg.id &&
      purchase.package.isMembership &&
      !purchase.isCancelled
    )

    const isCancel = type === "Cancel"
    const url = isCancel && cancelPurchase
      ? purchasesStore!.cancelPurchase(cancelPurchase[0])
      : purchaseStore.updatePurchase(currentPurchase![0].id, pkg)

    await url.then(res => {
      if (res.status === 200) {
        uiStore.openModal({
          children: (
            <div className="text-center" key={1}>
              <h3 className="py-3" key={2}>Success!</h3>
              <p className="text-center pb-3" key={3}>Your plan was {isCancel ? "cancelled" : "updated"} successfully.</p>
            </div>
          ),
          modalShow: true,
        })

        setTimeout(function() {
          window.location.href = "/account/memberships"
        }, 1000)
      } else {
        uiStore.openMessage(
          "message",
          `There was an error ${
            isCancel ? "cancelling" : "updating"
          } your plan. Please try again.`
        )
      }
    })
  }

  const handleSelectChange = (e: any) => {
    e.persist();
    const target = e.target
    if (activePurchases) {
      setSelectedForChange(activePurchases[target.value])
    }
  }

  const isChecked = (p: Purchase) => {
    const shouldItBeChecked = selectedForChange && p.package.id === selectedForChange.package.id
    return shouldItBeChecked
  }

  const getChangeType = (currentSeq: number, changeSeq: number) => {
    return currentSeq > changeSeq ? 'upgrade' : 'downgrade'
  }

  const getChangeAction = (currentSeq: number, changeSeq: number) => {
    return currentSeq > changeSeq ? 'upgraded' : 'downgraded'
  }

  const getIntervalName = (interval?: string) => {
    switch (interval) {
      case 'year': return '(Yearly)'
      case 'month': return '(Monthly)'
      case 'day': return '(Daily)'
      default: return ''
    }
  }

  const handleModal = (type: string) => {
    const changeType = getChangeType(pkg.seq, selectedForChange!.package.seq)
    const changeAction = getChangeAction(pkg.seq, selectedForChange!.package.seq)
    uiStore.openModal({
      title: `${type} Subscription`,
      modalShow: true,
      classes: hasMultiplePurchases ? 'xpass-package-change-modal' : '',
      children: (
        <>
          {hasMultiplePurchases &&
            <ConfirmDialog
              key={0}
              okLabel={`Yes, ${changeType} It`}
              cancelLabel={`Don't ${changeType}`}
              okHandler={() => {
                updatePurchase(type, pkg)
              }}
              cancelHandler={uiStore.closeModal}
              className="xpass-package-modal"
              allowDisabledState={true}
            >
              <div key={1} className="text-left">
                <p key={2} className="">Choose the plan you wish to cancel when changed:</p>
                <div key={3} className="xpass-package-change-modal__inputContainer my-2">
                  {activePurchases && activePurchases.map((p,i) => (
                    <label key={p.package.id} className="xpass-package-change-modal__label mb-3">
                      <input  key={p.package.name} type="radio" value={i} defaultChecked={isChecked(p)} onChange={handleSelectChange} name="packageToChange" />
                      {p.package.name} <span key={p.package.seq} className="text-capitalize">
                        (<PackageRenewalText key={`${p.package.seq}1`} pkg={p.package} store={store} purchase={p} pad={false} />)
                      </span>
                    </label>
                  ))}
                </div>
                <p key={5} className="mb-4">You will be {changeAction} to <strong key={6}>{pkg.name}</strong>.</p>
              </div>
            </ConfirmDialog>
          }
          {!hasMultiplePurchases &&
            <ConfirmDialog
              key={1}
              okLabel={`Yes, ${type} It`}
              cancelLabel={`Don't ${type}`}
              okHandler={() => {
                updatePurchase(type, pkg)
              }}
              cancelHandler={uiStore.closeModal}
              className="xpass-package-modal"
              allowDisabledState={true}
            >
              <div className="text-left" key={2}>
                <p className="pb-2" key={3}>
                  Do you want to <span className="text-lowercase" key={4}>{type}</span>{" "}
                  your subscription to{" "}
                  <strong key={5}>{pkg.name}</strong>?
                </p>
              </div>
            </ConfirmDialog>
          }
        </>
      ),
    })
  }

  const onClickHandler = () => {
    onClick && onClick()
    document.location.replace(`/buy/${locationSummary.id}/packages/${pkg.id}${queryString}`);

  }

  const showAccountClaimModal = () => {
    props.store!.track.event("blocker modal_popup")
    uiStore.openModal({
      children: <ClaimAccountModalOnBook
        store={props.store!}
        UIStore={uiStore}
      />,
      modalShow: true,
    })
  }

  return (
    <div className="col-md-6 col-lg-4 col-xl-3 px-2 py-3">
    <div
      className={cx(
        "card",
        store.styleClasses.PackageCard__card,
      )}
    >
      {filterKey !== "tokens" ? <div
        className={cx(
          "card-body d-flex flex-column justify-content-center p-lg-4",
          store.isXponential ? "p-3 text-left" : "p-2 text-center"
        )}
      >
        <div
          className={cx(
            "justify-content-center",
            store.styleClasses.PackageCard__cardText,
          )}
        >
          {store.isXponential && pkg.mostPopular &&
            <span className="package-top package-top__popular">Most Popular</span>
          }

          {store.isXponential && pkg.bestValue &&
            <span className="package-top package-top__value">Best Value</span>
          }

          <span
            className={cx(
              "h3 card-title mr-2",
              store.styleClasses.PackageCard__cardTextPackageName,
              store.isXponential ? "my-3" : "my-2"
            )}
          >
            {pkg.name}
          </span>

          {(!store.isXponential && !(store.brandId === "rumble")) && (
            <span className="d-md-none h3 my-2 mr-2">{` - `}</span>
          )}

          {store.isXponential ? (
            <span className="my-2 my-lg-4 package-price text-dark">
              {pkg.previousPriceText && <s>${pkg.previousPriceText}</s>}
              ${pkg.priceText || pkg.price.numeric}<span className="package-billcycle">{billCycle}</span>
            </span>
          ) : (
            <span className="my-2 my-lg-4 package-price text-dark">
              {pkg.price.formatted}
            </span>
          )}

          {store.isXponential && pkg.description && (
            <span className="package-description d-block">
              {pkg.description}
            </span>
          )}

          {store.isXponential && pkg.valueProps && (
            <ul className="package-props">
              {pkg.valueProps.map((prop, i) => (
                <li key={`prop-${pkg.seq}-${i}`}>
                  {prop.text}
                  {prop.toolTip &&
                    <>
                      <span
                        className="p-1"
                        data-tip data-for={`peakRateTooltip-${pkg.seq}-${i}`}
                        key={`tip-${pkg.seq}-${i}`}
                      >
                        <img key={`icon-${pkg.seq}-${i}`} src={helpIcon} />
                      </span>
                      <ReactTooltip
                        id={`peakRateTooltip-${pkg.seq}-${i}`}
                        key={`peakRateTooltip-${pkg.seq}-${i}`}
                        type="info"
                        effect="solid"
                        place="top"
                        backgroundColor="#000"
                      >
                        <span key={`text-${pkg.seq}-${i}`}>{prop.toolTip}</span>
                      </ReactTooltip>
                    </>
                  }
                </li>
              ))}
            </ul>
          )}
        </div>

        <div
          className={cx(
            "align-self-center mt-auto",
            store.isXponential && "package-action"
          )}
        >
          {updateType ? (
            <button
              className="btn btn-primary"
              disabled={purchaseDisabled}
              onClick={isXpassEligible || !store.isXponential || updateType === "Cancel" ? () => handleModal(updateType) : showAccountClaimModal}
            >
              {updateType} Plan
            </button>
          ) : (
            <div
              className={cx(
                "btn btn-primary token-package-cta",
                store.isXponential ? "mt-4" : "",
                purchaseDisabled ? "disabled" : ""
              )}
              onClick={isXpassEligible || !store.isXponential ? onClickHandler : showAccountClaimModal}
            >
              Buy Now
            </div>
          )}

          <div
            className={cx(
              "d-block package-caption",
              store.isXponential ? "mt-3 text-center" : "mt-2"
            )}
          >
            {store.isXponential && pkg.packageType === 'membership' && pkg.isRecurring ? (
              <span>
                {(pkg.intervalCount! === 1 && pkg.interval === 'month') &&
                  `Auto-renews ${pkg.interval}ly`}
                {(pkg.intervalCount! === 365 && pkg.interval === 'day') &&
                  "Auto-renews yearly"}
              </span>
            ) : (
              <PackageRenewalText pkg={pkg} store={store} purchase={activePurchase} pad={true} />
            )}
          </div>
        </div>
      </div> :
        <div
          className={cx(
            "card-body d-flex flex-column justify-content-center p-lg-4 align-items-center",
            store.isXponential ? "p-3 text-left" : "p-2 text-center"
          )}>

          <div
            className={cx(
              "justify-content-center",
              store.styleClasses.PackageCard__cardText,
            )}
          >

          </div>
          <span
            className={cx(
              "token-package-dark-text token-package-title",
              store.styleClasses.PackageCard__cardTextPackageName,
            )}
          >
            {pkg.name}
          </span>
          <span className="token-package-dark-text m-auto">
            {pkg.price.formatted}
          </span>
          <div
            className={cx(
              "btn btn-primary token-package-cta",
            )}
            onClick={isXpassEligible ? onClickHandler : showAccountClaimModal}
          >
            Buy
          </div>
        </div>}
    </div>
    </div>
  )
}

export default PackageCard