import * as React from "react"
import moment from "moment"
import Duration from "helpers/Duration"
import { Link } from "react-router-dom"
import * as cx from "classnames"
import { parse, stringify } from "helpers/queryString"
import BrandStore from "stores/BrandStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import Svg from "components/Svg"
export interface Props {
    week: Duration
    pathname: string
    search: string
    className?: string
    compactLabels?: boolean
    hidePast?: boolean
    store?: BrandStore
    scheduleEntryStore?: ScheduleEntryStore
}

function shortenDay(index: string) {
    switch (index) {
        case "0":
            return "Sun"
        case "1":
            return "Mon"
        case "2":
            return "Tue"
        case "3":
            return "Wed"
        case "4":
            return "Thu"
        case "5":
            return "Fri"
        case "6":
            return "Sat"
    }

    return ""
}

function dayStrings(week: Duration) {
    let days = []
    const { startAt, endAt } = week
    for (const d = startAt.clone(); d < endAt; d.add(1, "d")) {
        days.push(d.clone().format("YYYY-MM-DD"))
    }
    return days
}

const ScheduleDayNav: React.FunctionComponent<Props> = ({
    week,
    pathname,
    search: originalSearch,
    compactLabels,
    hidePast,
    className,
    store,
    scheduleEntryStore,
}) => {
    const days = dayStrings(week)
    const today = moment().format("YYYY-MM-DD")
    const { startAt, prevDate, nextDate, through } = week
    const linkClass = "text-decoration-none h4"
    const r = store!.routingStore

    let showFuture = true
    let showPast = true

    const maxEndDate = moment(scheduleEntryStore!.maxEndDate, "YYYY-MM-DDTH:mm").format("YYYY-MM-DD")
    const endOfWeek = through.endOf("day").utc().format("YYYY-MM-DD")
    if (moment(maxEndDate).isSame(endOfWeek) || moment(endOfWeek).isAfter(maxEndDate)) {
        showFuture = false
    }

    const startDate = moment().format("YYYY-MM-DD")
    const startOfWeek = startAt.format("YYYY-MM-DD")
    if (moment(startDate).isSame(startOfWeek) || moment(startOfWeek).isBefore(startDate)) {
        showPast = false
    }

    return (
        <div className="row m-0 mb-3" style={{ border: '1px #E7E7E7 solid' }}>
            <div className="col text-center px-0 py-3 d-flex align-items-center justify-content-center" style={{ paddingRight: '0px', paddingLeft: '0px', borderRight: '1px #E7E7E7 solid' }}>
                {week.date && week.date > today ?
                    <Link
                        to={r.mergeQuery({ date: today })}
                        title="Today"
                        className={linkClass}
                    >
                        <Svg name="caret-left" size="16" />
                    </Link>
                    :
                    <Svg name="caret-left" size="16" color="#DBDBDB" />
                }
            </div>

            {days &&
                days.map(day => {
                    const isSelected = day === week.date
                    const date = moment(day)
                    const search = stringify({
                        ...parse(originalSearch),
                        date: date.format("YYYY-MM-DD"),
                    })

                    return (
                        <Link
                            key={`entry-${date.format("MMDD")}`}
                            className={cx(
                                "details-schedule-day",
                                "col",
                                "text-center",
                                "px-1",
                                "py-3",
                                "text-decoration-none",
                                { "schedule-day-selected": isSelected }
                            )}
                            to={{ pathname, search }}
                        >
                            <h3
                                className={cx(
                                    "m-0",
                                    { "text-regular": !isSelected },
                                    { "text-dark": isSelected }
                                )}
                            >
                                {day === today ? (
                                    <span className="d-lg-inline">Today</span>
                                    ) : (
                                    <span className="d-lg-inline">
                                        <div>{shortenDay(date.format("d"))}</div>
                                        <div>{date.format(store!.styleClasses.ScheduleDayNav__dateFormat)}</div>
                                    </span>
                                    )}
                            </h3>

                        </Link>
                    )
                })}

            <div className="col order-3 order-lg-3 text-center px-0 py-3 d-flex align-items-center justify-content-center" style={{ paddingRight: '0px', paddingLeft: '0px', borderLeft: '1px #E7E7E7 solid' }}>
                {showFuture ?
                    <Link to={r.mergeQuery({ date: nextDate })} className={linkClass}>
                        <Svg name="caret-right" size="16" />
                    </Link>
                    :
                    <Svg name="caret-right" size="16" color="#DBDBDB" />
                }
            </div>
        </div>
    )
}

export default ScheduleDayNav
