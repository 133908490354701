import * as React from "react"
import * as moment from "moment"
import { observer, inject } from "mobx-react"
import capitalize from "lodash/capitalize"

import BookingsStore from "apps/bookings/stores/BookingsStore"
import BrandStore from "stores/BrandStore"
import APILoader from "components/wrappers/APILoader"
import { RouteComponentProps, Switch, Route } from "react-router"
import MySchedulePage from "apps/bookings/components/MySchedulePage"
import Booking, { AnyBooking } from "models/Booking"
import ChangeSeatPage from "apps/bookings/components/ChangeSeatPage"
import { ConfirmDialog } from "components/ConfirmDialog"
import ErrorPage from "components/ErrorPage"
import { EventEntities } from "models/TrackingSchemas"

export interface Props extends RouteComponentProps {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class MyBookingsController extends React.Component<Props, {}> {
  bookingsStore = new BookingsStore(this.props.store!)

  componentWillUnmount() {
    this.bookingsStore.dispose()
  }

  // probably want to handle this not here?
  // for instance, if they navigate away partway through, need to cancel
  handleCancelClick = (booking: AnyBooking) => {
    const isWaitlist = booking.status === "waitlisted"
    const isServiceBooking = booking.type === "service_booking"
    const { copy, uiStore, track, authStore, isXponential } = this.props.store!

    const trackingEntities: EventEntities =
      booking.type === "service_booking"
        ? { service_booking: booking }
        : { booking }

    const classType = isServiceBooking ? copy.session : copy.class
    const message = isWaitlist
      ? "Are you sure you want to remove yourself from the waitlist?"
      : booking.freeCancelable
      ? `${copy.bookingCancellationBody.replace("{CLASS/SESSION}", classType)}`
      : `${copy.bookingLateCancellationBody.replace("{CLASS/SESSION}", classType)}`

    track.event(
      isWaitlist ? "waitlist_remove from waitlist" : "booking_cancel class",
      trackingEntities
    )

    uiStore.openModal({
      title: copy.bookingCancellationTitle.replace("{CLASS/SESSION}", capitalize(classType)),
      modalShow: true,
      size: "md",
      children: (
        <ConfirmDialog
          okLabel={copy.bookingCancellationConfirmButton}
          cancelLabel={copy.bookingCancellationDeclineButton}
          okHandler={() => {
            uiStore.closeModal()
            this.doCancel(booking, trackingEntities, isXponential)
          }}
          cancelHandler={uiStore.closeModal}
          okButtonClass="btn btn-primary btn-md mr-3 dialog-button"
          cancelButtonClass="btn btn-primary btn-md dialog-button"
        >
          <div className="text-left px-4">
            <p>{message}</p>
          </div>
        </ConfirmDialog>
      ),
    })
  }

  doCancel = (booking: AnyBooking, trackingEntities: object, isXponential: boolean) => {
    const isWaitlist = booking.status === "waitlisted"
    const params = booking.freeCancelable ? {} : { late_cancel: 1 }
    const { track, uiStore } = this.props.store!

    track.event(
      isWaitlist
        ? "waitlist_confirm remove from waitlist"
        : "book_confirm cancellation",
      trackingEntities
    )

    this.bookingsStore.cancel(booking, params).then(res => {
      this.bookingsStore.fetch({
        start_date: moment().startOf("day").toISOString(),
        include_waitlist: true,
      })

      if (isXponential ? res.data.success : res.status === 200) {
        uiStore.openMessage(
          "message",
          `Your booking for ${booking.scheduleEntry.title} was cancelled successfully.`
        )
        track.event(
          isWaitlist ? "remove waitlist_success" : "cancel_success",
          trackingEntities
        )
      } else {
        uiStore.openError(res.data.message)
        track.event(
          isWaitlist ? "remove waitlist_failure" : "cancel_failure",
          trackingEntities
        )
      }
    }, ex => {
      // we specifically DON'T want to capture errors from "then.", so we
      // use a second argument to `then` rather than a `.catch()`
      uiStore.openError(
        "We encountered a problem cancelling your booking. We're investigating it, in the meantime please refresh the page and try again."
      )
      track.event(
        isWaitlist ? "remove waitlist_failure" : "cancel_failure",
        trackingEntities
      )
      throw ex
    })
  }

  handleWaitlistBookingClick = (booking: Booking) => {
    this.bookingsStore.cancel(booking).then(() => {
      this.props.store!.routingStore.push(booking.scheduleEntry.bookingPath!)
    })
  }

  handleCheckinClick = (booking: AnyBooking) => {
    this.bookingsStore.checkIn(booking)
  }

  handleRemovecheckinClick = (booking: AnyBooking) => {
    this.bookingsStore.removeCheckin(booking)
  }

  handleBookingChanged = (booking: Booking) => {
    this.bookingsStore.refresh(booking)
  }

  public render() {
    const baseUrl = this.props.match.url
    return (
      <APILoader
        apiStore={this.bookingsStore}
        elementSize="page"
        fetchProps={{
          start_date: moment()
            .startOf("day")
            .toISOString(),
          include_waitlist: true,
        }}
        render={() => {
          // needs to be dereferenced here to trigger rerender
          const bookings = this.bookingsStore.bookedBookings
          const serviceBookings = this.bookingsStore.bookedServiceBookings
          return (
            <Switch>
              <Route
                exact
                path={baseUrl}
                render={() => (
                  <MySchedulePage
                    store={this.props.store!}
                    bookings={bookings}
                    serviceBookings={serviceBookings}
                    baseUrl={baseUrl}
                    onCancelClick={this.handleCancelClick}
                    onCheckinClick={this.handleCheckinClick}
                    onRemoveCheckinClick={this.handleRemovecheckinClick}
                    handleWaitlistBookingClick={this.handleWaitlistBookingClick}
                  />
                )}
              />
              {/* this could be made to work outside the APILoader, if helpful */}
              <Route
                exact
                path={`${baseUrl}/:scheduleEntryId/seat`}
                render={({ match }) => {
                  const booking = this.bookingsStore.bookings.find(
                    b => b.scheduleEntry.id === match.params.scheduleEntryId
                  )!
                  if (!booking) {
                    // TODO: handle this for real
                    return (
                      <ErrorPage
                        title="Booking Not Found"
                        message="We couldn't find that booking. Please try again or contact your studio if you need further assistance."
                        linkTo="/bookings"
                        linkTitle="Back to My Schedule"
                      />
                    )
                  }

                  return (
                    <ChangeSeatPage
                      booking={booking}
                      onBookingChanged={this.handleBookingChanged}
                    />
                  )
                }}
              />
            </Switch>
          )
        }}
      />
    )
  }
}
