import React from "react"
import {
  Route,
  RouteProps,
  Redirect,
  matchPath,
  RouteComponentProps,
} from "react-router-dom"
import { inject, observer } from "mobx-react"
import getIn from "lodash/get"

import { setFriendlyRoute, popFriendlyRoute } from "services/friendlyRoute"
import BrandStore from "stores/BrandStore"
import { isArrayLike, computed } from "mobx"
import { loadState, clearState } from "services/savedState"
import { getLocal, setLocal } from "utils/LocalStorage"

import WarningMessage from "components/WarningMessage"

export interface Props extends RouteProps {
  store?: BrandStore
  publicOnly?: boolean
  shouldRedirect?: boolean
  locationInPath?: boolean
  // set this redirect them to somewhere specific after they log in or out
  roadblockPath?: string
  // if we want to login at a specific location (from the url) that is outside of the stored location
  ignoreStoredLocation?: boolean
}

// Redirects to login if page is private and / if page is public-only (eg login)
// shows a flash of header unfortunately
@inject((store: BrandStore) => ({ store }))
@observer
export default class RestrictedRoute extends React.Component<Props, {}> {
  static defaultProps = { publicOnly: false, shouldRedirect: true }
  locStore = this.props.store!.locStore

  @computed get pathString() {
    return isArrayLike(this.props.path) ? this.props.path[0] : this.props.path
  }

  // AKA isRouteValidForLoggedInStatus() or something
  // whether, given their logged-in status, they can access the route in question
  get isAuthorized() {
    const { userStore } = this.props.store!
    const publicOnly = this.props.publicOnly

    // Block the route if the location needs a waiver for LaFitness
    // The Covid waiver path was moved to the roadblockPath in the parent route
    return userStore!.isLoggedIn !== publicOnly && !this.needsLaFitnessRedirect
  }

  // return true if the user is logged-in but needs to sign a covid waiver
  // before doing anything else on the site
  get needsCovidRedirect() {
    const { path } = this.props
    const { session } = this.props.store!.userStore
    const publicOnly = this.props.publicOnly
    const { currentLocation } = this.props.store!.locStore
    const alreadyOnWaiver = path && path.indexOf("/covid") === 0
    const covidWaiverAccepted = getLocal("covidWaiverAccepted")

    return (
      !publicOnly &&
      !alreadyOnWaiver &&
      session &&
      currentLocation &&
      !!currentLocation.covidWaiver &&
      currentLocation.covidWaiverEnabled &&
      (!covidWaiverAccepted && !session.covidWaiverAcceptedAt) &&
      !this.locStore.currentLocation!.comingSoon
    )
  }

  get needsLaFitnessRedirect() {
    const { path } = this.props
    const publicOnly = this.props.publicOnly
    const { currentLocation } = this.props.store!.locStore
    const alreadyOnDisclaimer = path && path.indexOf("/la-fitness-disclaimer") === 0

    const laFitnessAuth = getLocal("laFitnessAuth", {}) as object
    const id = getIn(currentLocation,"id", "")
    const laFitnessAuthAccepted = Object.keys(laFitnessAuth).includes(id)

    return (
      !publicOnly &&
      !alreadyOnDisclaimer &&
      currentLocation &&
      !!currentLocation.laFitness &&
      !laFitnessAuthAccepted
    )
  }

  // return the path to a waiver if the user needs to sign it
  get waiverRedirect() {
    const { currentLocation } = this.props.store!.locStore
    if(this.needsLaFitnessRedirect) return `/la-fitness-disclaimer/${currentLocation!.id}`
    if(this.needsCovidRedirect) return `/covid-waiver/${currentLocation!.id}`
    return null
  }

  get authPath() {
    let auth = '/auth'
    if (
      this.props.store!.isXponential &&
      !this.isAuthorized &&
      this.props.store!.routingStore.location.pathname.startsWith('/buy/xponential-xpass/packages')
    ) {
      auth = '/auth/register'
    }
    const queryLocationId = this.props.store!.routingStore.query.location_id
    return queryLocationId ? `${auth}?location_id=${queryLocationId}` : auth
  }

  get redirectPath() {
    // TODO: use userStore.lastEmail to put their email address into the query
    // string if they just got bounced.
    let path: string
    const { session } = this.props.store!.userStore
    const { currentLocation } = this.props.store!.locStore
    const covidAcceptedAt = session && session.covidWaiverAcceptedAt

    if (this.props.publicOnly) {
      // Bypass the roadBlock path if the user already accepted and is logging in again
      // Due to the roadBlockpath, it would show the covid waiver again
      let { roadblockPath } = this.props
      const covidWaiverEnabled = getIn(currentLocation, "covidWaiverEnabled", false)
      if (roadblockPath === "/covid-waiver" && (covidAcceptedAt || !covidWaiverEnabled)) {
        roadblockPath = undefined
      }
      path = roadblockPath || popFriendlyRoute() || "/"
    } else {
      const imperativeRedirect = loadState("imperativeRedirect", true)
      setFriendlyRoute(imperativeRedirect)
      clearState("imperativeRedirect", true)

      // if they're trying to hit a specific location we should store that
      // so they are, by default, prompted to log in to it
      // except DON'T do it if they already have a stored location
      const { routingStore, locStore } = this.props.store!
      if (
        this.props.locationInPath &&
        (!locStore.loadStoredLocation() || this.props.ignoreStoredLocation)
      ) {
        const match = matchPath<{ locationId?: string }>(
          routingStore.location.pathname,
          this.props
        )
        if (match && match.params.locationId) {
          locStore.storeLocation(match.params.locationId)
        }
      }

      path = this.props.roadblockPath || this.waiverRedirect || this.authPath
    }
    return path
  }

  componentWillMount() {
    const { publicOnly, shouldRedirect } = this.props
    if (publicOnly && shouldRedirect && !this.isAuthorized) {
      setFriendlyRoute()
    }
  }

  render() {
    const {
      store,
      publicOnly,
      shouldRedirect,
      locationInPath,
      roadblockPath,
      path,
      ...routeProps
    } = this.props

    const { isAppleWatch, session } = store!.userStore
    const userClubreadyId = session && session.clubreadyId
    const eywAffiliateLink = store!.eywAffiliateLink()

    let covidWaiverErrorMessage = undefined
    if (getIn(this.props!.store!.userStore!, "session.covidWaiverError") && !(path && path.indexOf("/covid") === 0))
      covidWaiverErrorMessage = "COVID Waiver acceptance upload failed. You will be prompted to accept the waiver again."

    if (this.isAuthorized) {
      return (
        <>
          <WarningMessage message={covidWaiverErrorMessage} />
          <Route {...routeProps} />
        </>
      )
    } else if (isAppleWatch && userClubreadyId && eywAffiliateLink) {
      // If Apple Watch login/registration landing, redirect user to affiliate link
      window.location.href = `${eywAffiliateLink}${userClubreadyId}`
      return null
    } else if (this.props.shouldRedirect) {
      const redirectPath = this.redirectPath
      return <Redirect to={redirectPath} from={this.pathString} />
    } else {
      return null
    }
  }
}
