import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosError } from "axios"
import { observable, action } from "mobx"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import BrandStore from "stores/BrandStore"
import Package from "models/Package"

export default class OnboardingStore extends APIStore {
  @observable allPackages: Package[] = []
  authenticated = true

  constructor(public brandStore: BrandStore, authenticated: boolean = true) {
    super()
    this.authenticated = authenticated
  }

  get api() {
    if (this.authenticated) {
      return this.createClient([
        ResponseMiddleware(this.handleSuccess),
        TokenAuthMiddleware(this.brandStore.userStore, true),
        DeserializeMiddleware("packages", Package),
      ])
    }

    return this.createClient([
      ResponseMiddleware(this.handleSuccess),
      DeserializeMiddleware("packages", Package),
    ])
  }

  fetch() {
    return this.api.get("/api/xpass/v3/offer_slots/onboarding")
  }

  @action.bound
  handleSuccess(res: AxiosResponse<{ packages: Package[] }>) {
    this.allPackages = res.data.packages
    return res
  }

  @action.bound
  handleError(err: AxiosError) {
    console.log(err.response)
    if (err.response && err.response.status === 404) {
      this.allPackages = []
      return err.response
    }
    throw err
  }
}
