import {
  BrandData,
  baseSettings,
  baseStyles,
  baseStyleClasses,
  baseCopy,
  BrandCopy,
  BrandSettings,
  BrandStyles,
  BrandStyleClasses,
  BadgeBitmap,
  eywAffiliateLinks,
} from "themes/base"

import badgeImage from "images/badges/kinrgy-badge.png"
import logoUrl from "images/logos/kinrgy-logo.svg"
import bannerImage from "images/dashboard/kinrgy_banner_card.jpg"
import appleWatchIcon from "images/apple_health/watch/kinrgy.svg"
import classpointsHero from "images/classpoints/classpoints-hero_kinrgy.png"
import brandIcon from "images/classpoints/brands/PB.svg"

import badge10Active from "images/badges/kinrgy/Kinrgy-10-Classes-Active.png"
import badge50Active from "images/badges/kinrgy/Kinrgy-50-Classes-Active.png"
import badge100Active from "images/badges/kinrgy/Kinrgy-100-Classes-Active.png"
import badge250Active from "images/badges/kinrgy/Kinrgy-250-Classes-Active.png"
import badge500Active from "images/badges/kinrgy/Kinrgy-500-Classes-Active.png"
import badge750Active from "images/badges/kinrgy/Kinrgy-750-Classes-Active.png"
import badge1000Active from "images/badges/kinrgy/Kinrgy-1000-Classes-Active.png"

import badge10Inactive from "images/badges/kinrgy/Kinrgy-10-Classes-Inactive.png"
import badge50Inactive from "images/badges/kinrgy/Kinrgy-50-Classes-Inactive.png"
import badge100Inactive from "images/badges/kinrgy/Kinrgy-100-Classes-Inactive.png"
import badge250Inactive from "images/badges/kinrgy/Kinrgy-250-Classes-Inactive.png"
import badge500Inactive from "images/badges/kinrgy/Kinrgy-500-Classes-Inactive.png"
import badge750Inactive from "images/badges/kinrgy/Kinrgy-750-Classes-Inactive.png"
import badge1000Inactive from "images/badges/kinrgy/Kinrgy-1000-Classes-Inactive.png"

const settings: BrandSettings = {
  ...baseSettings,
  showGiftCardBalanceLink: false,
}

const copy: BrandCopy = {
  ...baseCopy,
  seat: "Class",
  bookClass: "Reserve",
  classPackages: "Our memberships offer a better value than class packs. Call your local studio to find out more about our membership options and specials."
}

const styles: BrandStyles = {
  ...baseStyles,
  badgeImage,
  logoUrl,
  bannerImage,
  appleWatchIcon,
  classpointsHero,
  brandIcon
}

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
}

const badges: BadgeBitmap[] = [
  {
    label: "10 Classes",
    value: 10,
    active: badge10Active,
    inactive: badge10Inactive,
  },
  {
    label: "50 Classes",
    value: 50,
    active: badge50Active,
    inactive: badge50Inactive,
  },
  {
    label: "100 Classes",
    value: 100,
    active: badge100Active,
    inactive: badge100Inactive,
  },
  {
    label: "250 Classes",
    value: 250,
    active: badge250Active,
    inactive: badge250Inactive,
  },
  {
    label: "500 Classes",
    value: 500,
    active: badge500Active,
    inactive: badge500Inactive,
  },
  {
    label: "750 Classes",
    value: 750,
    active: badge750Active,
    inactive: badge750Inactive,
  },
  {
    label: "1000 Classes",
    value: 1000,
    active: badge1000Active,
    inactive: badge1000Inactive,
  }
]

const kinrgy: BrandData = {
  settings: settings,
  styles,
  styleClasses,
  copy,
  dashboardCards: [
    "NextClassCard",
    "TodaysClassCard",
    "AchievementsCard",
    "AttendanceCard",
    "BadgeCard",
    "ClassPointsCard",
    "ProfileCard",
    "MobileAppCard",
    "MobileOfferCard",
    "PromoCards",
  ],
  badges,
  metrics: [],
  summaryMetrics: [],
  eywAffiliateLinks: eywAffiliateLinks["kinrgy"],
  eywLegalLink: "https://www.kinrgystudios.com/terms-earn-your-watch",
  eywParticipatingLink: "https://www.kinrgystudios.com/earn-your-watch-locations"
}

export default kinrgy
