import { JsonObject, JsonProperty } from "json2typescript"
import { computed } from "mobx"
import DateConverter from "utils/DateConverter"
import { Moment } from "moment"
import XpassScheduleEntry from "apps/book/models/xpass/ScheduleEntry"
import ServiceBooking from "apps/book/models/ServiceBooking"

export type AnyBooking = XpassBooking | ServiceBooking

export type BookingStatusEnum =
  | "unbooked"
  | "booked"
  | "cancelled_refund"
  | "cancelled_no_refund"
  | "completed"
  | "waitlisted"
  | "noshow"
  | "rescheduled"
  | "club_rescheduled"
  | "club_cancelled"

export type BookingWindow = "bookable" | "too_early" | "too_late"

export type CancellationWindow = "free" | "penalty" | null

export const bookingStatusMap: Partial<
  {
    [k in BookingStatusEnum]: { booked: boolean; cancelled: boolean }
  }
> = {
  // booked
  booked: { booked: true, cancelled: false },
  waitlisted: { booked: true, cancelled: false },
  completed: { booked: true, cancelled: false },
  noshow: { booked: true, cancelled: false },

  // cancelled
  cancelled_refund: { booked: false, cancelled: true },
  cancelled_no_refund: { booked: false, cancelled: true },
  rescheduled: { booked: false, cancelled: true },
  club_rescheduled: { booked: false, cancelled: true },
  club_cancelled: { booked: false, cancelled: true },

  // neither
  unbooked: { booked: false, cancelled: false },
}

@JsonObject("Booking")
export default class XpassBooking {
  /**
   * A tag to identify the type, as bookings vs. waitlist bookings look a little
   * different, as do their schedule entries.
   */
  @JsonProperty("type", String)
  type: "booking" | "waitlist" = undefined!

  /**
   * Note: `waitlisted` and `unbooked` should probably never show up here,
   * since this is a booking.
   *
   */
  @JsonProperty("status", String)
  status: BookingStatusEnum = undefined!
  /**
   * ClubReady ID of the _booking_, not of the schedule entry. Null for waitlisted entries.
   *
   */
  @JsonProperty("clubready_id", String, true)
  clubreadyId?: string = undefined

  @JsonProperty("schedule_entry", XpassScheduleEntry)
  scheduleEntry: XpassScheduleEntry = undefined!

  /**
   * When they booked, or in the case of waitlists, when they added themselves to the waitlist.
   *
   */
  @JsonProperty("booked_at", DateConverter, true)
  bookedAt?: Moment = undefined

  /**
   * PIQ seat number of the booking, if applicable.
   */
  @JsonProperty("seat_id", String, true)
  seatId?: string = undefined

  /**
   * PIQ seat label of the booking, if applicable. This is specifically for Rumble.
   */
  @JsonProperty("seat_label", String, true)
  seatLabel?: string = undefined

  @JsonProperty("waitlist_position", Number, true)
  waitlistPosition?: number = undefined

  /**
   * XPASS credits used to book the class.
   */
  @JsonProperty("xpass_credits", Number, true)
  xpassCredits?: number = undefined!

  /**
   * First class credits
   */
  @JsonProperty("first_class_credits", Number, true)
  firstClassCredits?: number = undefined!

  @computed get key() {
    return `${this.clubreadyId}-${this.scheduleEntry.id}-${this.status}`
  }

  @computed get id() {
    return this.scheduleEntry.id
  }

  // see also ScheduleEntry.ts

  @computed get isBusy() {
    return this.scheduleEntry.isBusy
  }
  set isBusy(isBusy: boolean | undefined) {
    this.scheduleEntry.isBusy = isBusy
  }

  @computed get freeCancelable() {
    return this.scheduleEntry.freeCancelable || this.status === "waitlisted"
  }

  // tri-value: null/undefined means "don't know"
  @computed get canBookFromWaitlist() {
    if (this.status !== "waitlisted") return false
    if (this.scheduleEntry.tooLate) return false
    if (!this.scheduleEntry.inFuture) return false
    if (this.scheduleEntry.freeSpots === 0) return false
    if (this.scheduleEntry.freeSpots === undefined) return undefined
    return true
  }

  @computed get isBooked() {
    const stat = bookingStatusMap[this.status]
    return stat && stat.booked
  }
}
