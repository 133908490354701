import * as React from "react"
import { observer } from "mobx-react"

import BrandStore from "stores/BrandStore"
import Heading from "components/Heading"
import PasswordUpdateForm from "apps/account/components/PasswordUpdateForm"
import { FormValues } from "apps/account/components/PasswordUpdateForm"
import PasswordUpdateStore from "apps/account/stores/PasswordUpdateStore"

export interface Props {
  brandStore: BrandStore
}

@observer
export default class PasswordUpdatePanel extends React.Component<Props, {}> {
  updateStore: PasswordUpdateStore = new PasswordUpdateStore(
    this.props.brandStore
  )

  handleUpdate = (values: FormValues) => this.updateStore.update(values)

  render() {
    const { brandStore } = this.props
    return (
      <div className="row my-4">
        <div className="col-lg-8">
          <Heading>Update Your Password</Heading>
          <PasswordUpdateForm
            brandStore={brandStore}
            handleUpdate={this.handleUpdate}
          />
        </div>
      </div>
    )
  }
}
