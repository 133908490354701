import * as React from "react"
import { inject, observer } from "mobx-react"
import PlanStore from "apps/buy/stores/PlanStore"
import NotHomeWarning from "components/NotHomeWarning"
import { Link } from "react-router-dom"
import BrandStore from "stores/BrandStore"
import BackToBrandSite from "apps/auth/components/BackToBrandSite"
import { ucfirst } from "helpers/stringHelper"
import Offer from "models/Offer"

interface Props {
  planStore: PlanStore
  offer?: Offer
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class MissingPackagePanel extends React.Component<Props, {}> {
  handleLogoutClick = (e: LinkEvent) => {
    e.preventDefault()
    this.props.store!.authStore.signOut(this.props.planStore.locationSummary.id)

    this.props.store!.track.event("cross club_tap logout", {
      loc: this.props.planStore.locationSummary,
      pkg: this.props.planStore.package,
      plan: this.props.planStore.plan,
    })
  }

  public render() {
    const { planStore, store, offer } = this.props
    let title: string
    let body: React.ReactNode

    const noun = offer ? "offer" : "package"
    const verb = offer ? "redeem" : "purchase"

    if (
      planStore.errorCode === "ineligible_specific" &&
      planStore.errorMessage
    ) {
      title = `Ineligible to ${ucfirst(verb)} ${ucfirst(noun)}`
      body = <p>{planStore.errorMessage}</p>
    } else if (
      planStore.errorCode === "ineligible_specific" ||
      planStore.errorCode === "ineligible"
    ) {
      title = `Ineligible to ${ucfirst(verb)} ${ucfirst(noun)}`
      body = (
        <p>
          It appears you're ineligible for this {noun}. It may be because this
          was a limited time offer, a free trial that can only be redeemed once,
          or it may be for a studio other than your home studio.
        </p>
      )
    } else {
      title = `${ucfirst(noun)} Not Found`
      body = (
        <p>
          We couldn't find this {noun}. It may have been discontinued, or there
          may be a mistake in the URL.
        </p>
      )
    }

    return (
      <div className="col-md-6">
        {offer && <BackToBrandSite forceShow />}

        <h2 className="my-3">{title}</h2>

        {!!offer && <p className="text-muted">{offer.name}</p>}

        <NotHomeWarning link={!offer} className="text-danger">
          . This {noun} is for{" "}
          <strong>
            {store!.brand.name} {planStore.locationSummary.name}
          </strong>
          . To {verb} this {noun}, you will need to{" "}
          <a href="#" className="text-danger" onClick={this.handleLogoutClick}>
            sign out
          </a>{" "}
          and register an account at or sign in to {store!.brand.name}{" "}
          {planStore.locationSummary.name}.
        </NotHomeWarning>

        {body}

        {planStore.crossClubIneligible ? (
          <div className="mt-4">
            <a
              href="#"
              className="btn btn-secondary"
              onClick={this.handleLogoutClick}
            >
              Sign Out
            </a>
          </div>
        ) : (
          <div className="mt-4">
            {offer ? (
              <button className="btn btn-secondary" disabled>
                Continue
              </button>
            ) : (
              <Link className="btn btn-primary" to="/buy">
                View {planStore.locationSummary.name} Memberships
              </Link>
            )}
          </div>
        )}
      </div>
    )
  }
}
