import * as React from "react"
import cx from "classnames"
import { InjectedFormikProps, withFormik, Form } from "formik"
import * as yup from "yup"
import { observer, inject } from "mobx-react"

import SharedAccountControls from "apps/auth/components/SharedAccountControls"
import BrandStore from "stores/BrandStore"
import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"
import { ForgotPasswordValues } from "apps/auth/stores/AuthStore"
import { AxiosError } from "axios"
import LocationSelectorPanelField from "./LocationSelectorPanelField"
import { PageName, TrackingEvent } from "models/TrackingSchemas"
import PageTracker from "components/wrappers/PageTracker"

export interface Props {
  pageName: PageName
  subheadBlock?: React.ReactNode
  store?: BrandStore
  lockLocation?: boolean
  onPageChange?(page: string): void
}

export interface Events {
  submit: TrackingEvent,
  success: TrackingEvent,
  failure: TrackingEvent,
}

@observer
class ForgotPasswordForm extends React.Component<
  InjectedFormikProps<Props, ForgotPasswordValues>
> {
  public render() {
    const { pageName, isSubmitting, onPageChange, store } = this.props
    const { isXponential } = store!
    const currentPage = this.props.pageName === 'auth > create password'
      ? 'create_password'
      : 'forgot_password'
    let copy = isXponential
      ? {
          heading: 'Reset Your Password',
          cta: 'Send Instructions'
        }
      : {
          heading: 'Forgot Your Password?',
          cta: 'Send Password Reset Instructions'
        }
    if (currentPage === 'create_password') {
      copy = {
          heading: 'Create Your Password',
          cta: 'Create Password' 
        }
    }
    
    return (
      <div className={cx({"mt-5 mx-auto col-lg-10 col-xl-8 form-border" :isXponential})}>
        <h1 className="text-center">{copy.heading}</h1>
        <PageTracker name={pageName} />
        {this.props.subheadBlock}
        <Form
          noValidate
          className={cx(
            isXponential
              ? "col-md-8 col-lg-5 mx-auto"
              : "col-md-10 offset-md-1 col-lg-6 offset-lg-3 grid-in"
          )}
        >

        {!isXponential && (
          <LocationSelectorPanelField disabled={this.props.lockLocation} />
        )}

          {currentPage === 'create_password' && 
            <div className="text-center">If you are unable to create an account with your existing email or have not yet set a password, enter your email address below to create a new password.</div>
          }

          <FormInput
            label={isXponential ? "Email Address" : "Your Email Address"}
            placeholder={isXponential ? "Enter address" : "Your Email Address"}
            name="email"
            type="email"
            autoComplete="email"
          />

          <FormButtons
            submitText={copy.cta}
            disabled={isSubmitting}
            centered
          >
            <SharedAccountControls
              className="mt-3"
              onPageChange={onPageChange}
              currentPage={currentPage}
              brandText={this.props.store!.brand.name}
            />
          </FormButtons>
        </Form>
      </div>
    )
  }
}

const schema = yup.object<ForgotPasswordValues>().shape({
  locationId: yup.string().required(),
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
})

const FormikWrapper = withFormik<
  Props & { store?: BrandStore },
  ForgotPasswordValues
>({
  mapPropsToValues: props => ({
    locationId: props.store!.locStore.currentLocation
      ? props.store!.locStore.currentLocation!.id
      : "",
    email: props.store!.routingStore.query.email || "",
  }),
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: schema,
  handleSubmit: (values, formikBag) => {
    const { authStore, uiStore, track, locStore, isXponential } = formikBag.props.store!
    const events: Events = formikBag.props.pageName === 'auth > create password'
      ? { 
          submit: 'create pw_tap submit',
          success: 'create pw_success',
          failure: 'create pw_failure',
        }
      : {
          submit: 'forgot pw_tap submit',
          success: 'forgot pw_success',
          failure: 'forgot pw_failure',
        }

    authStore
      .forgotPassword(values)
      .then(res => {
        formikBag.setSubmitting(false)
        if (res.data && res.data.errors) {
          formikBag.setErrors(res.data.errors)
          track.event(events.failure, { loc: locStore.currentLocation })
        } else {
          uiStore.openModal({
            type: "message",
            message:
              isXponential ?
                "If an account exists for the address provided, you will receive an email with instructions to reset your password." :
                "We sent you an email with instructions to reset your password. If you haven't received this email within a few minutes, please check your spam folder.",
            modalShow: true,
            size: "sm",
            title: 
              isXponential ?
                "" :
                "Check your email"
          })
          track.event(events.success, { loc: locStore.currentLocation })
        }
      })
      .catch((ex: AxiosError) => {
        formikBag.setSubmitting(false)

        if (ex.response && ex.response.status === 404) {
          uiStore.openModal({
            type: "error",
            title: "Something went wrong",
            message:
              isXponential ?
                "We were unable to find an XPASS user with that email address. Please check your email address." :
                "We were unable to find a user at this studio with that email address. Please check your email address and studio. If you have accounts at any other studios, you will receive an email with further instructions.",
            // message: e.response!.data.message,
            modalShow: true,
          })
          track.event(events.failure, { loc: locStore.currentLocation })
        } else if (
          ex.response &&
          ex.response.status === 502 &&
          ex.response.data &&
          ex.response.data.code === "no_piq"
        ) {
          uiStore.openModal({
            type: "error",
            title: "Something went wrong",
            message: ex.response.data.message,
            modalShow: true,
          })
        } else {
          uiStore.openModal({
            type: "error",
            title: "Something went wrong",
            message:
              "We couldn't send you a password reset email! We're looking into the problem, but please try again or contact your studio for support.",
            // message: e.response!.data.message,
            modalShow: true,
            locationSummary: formikBag.props.store!.locStore.currentLocation,
          })
          track.event(events.failure, { loc: locStore.currentLocation })
          throw ex
        }
      })
    track.event(events.submit, { loc: locStore.currentLocation })
  },
})

export default inject((store: BrandStore) => ({ store }))(
  observer(FormikWrapper(ForgotPasswordForm))
)
