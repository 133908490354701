import React from "react"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"


interface Props {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class BrandPurchaseSuccess extends React.Component<Props>  {
  constructor(props:Props){
    super(props)
  }

  componentDidMount() {
    this.props.store!.uiStore.hideNavLinks()
    localStorage.removeItem("newUser")
 }

 componentWillUnmount() {
    this.props.store!.uiStore.showNavLinks()
 }


 render(){

    const locationName = this.props.store!.locStore!.currentLocation!.name
    
    return (
      <div className="text-center">
          <h2 className="claim-accounts-header">Your Purchase Was Successful</h2>
                <p>We can't wait to see you at the studio! Click below to book:</p>
          <a href="/book" className="btn btn-primary mt-auto mb-0">
          Book Now
          </a>
      </div>
    )
  }
 
}
