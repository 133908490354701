interface Props {
  src: string
  type?: string
  id?: string
  charset?: string
  appendHead?: boolean
}

const thirdPartyScript = ({
  src,
  id = undefined,
  type = undefined,
  charset = undefined,
  appendHead = true,
}: Props) => {
  const theScript = document.createElement("script")
  theScript.src = src
  theScript.type = type || "text/javascript"
  if (id) theScript.id  = id
  if (charset) theScript.charset = charset
  if (appendHead) {
    document.head.appendChild(theScript)
  } else {
    document.body.appendChild(theScript)
  }
}

export default thirdPartyScript;
