import * as React from "react"
import cx from "classnames"
import BrandStore from "stores/BrandStore"
import Goal, { GoalId } from "models/Goal"
import { observer } from "mobx-react"
import Svg from "components/Svg"
import GoalStore from "apps/dashboard/stores/GoalStore"
import { Button } from "react-bootstrap"

interface Props {
  store: BrandStore
  goalStore: GoalStore
}

@observer
class GoalSetter extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  get goal() {
    return this.props.goalStore.goal!
  }

  componentWillUnmount() {
    this.goal.reset()
  }

  handleSubmit = (e: ButtonEvent) => {
    e.preventDefault()
    this.props.goalStore.save()
    this.props.store.uiStore.closeModal()
    this.props.store!.track.event("home_set goal", {
      goal: this.props.goalStore.goal,
    })
  }

  increment = (e: ButtonEvent) => {
    e.preventDefault()
    this.goal.increment()
  }

  decrement = (e: ButtonEvent) => {
    e.preventDefault()
    this.goal.decrement()
  }

  handleChange = (e: InputEvent) => {
    this.goal.setTarget(Number(e.currentTarget.value))
  }

  render() {
    const goal = this.goal
    const { copy, styles } = this.props.store

    return (
      <>
        <div>
          <div
            className={cx("position-absolute")}
            style={{ top: 12, right: 12, zIndex: 10, cursor: "pointer" }}
            onClick={() => {
              this.props.store.uiStore.closeModal()
            }}
          >
            <Svg name="button-close" size="24" />
          </div>
        </div>
        <div className="text-center mt-2">
          <img
            className="mr-3"
            src={styles.badgeImage}
            alt={`${this.props.store.brand.name} Badge`}
            style={{ height: "40px" }}
          />
          <h4 className="d-inline">Set or Edit Your Goal</h4>
          <hr />
        </div>
        <div className="text-center">
          <small>{`I would like to ${String(
            copy.attend
          ).toLowerCase()}`}</small>
          <div
            className={cx(
              "goal-setter",
              "d-flex",
              "justify-content-center",
              "mx-auto"
            )}
          >
            <button onClick={this.decrement}>-</button>
            <input
              className={cx("text-center", "d-inline", "col-3")}
              type="number"
              value={goal.target}
              onChange={this.handleChange}
            />
            <button onClick={this.increment}>+</button>
          </div>
          <small>{copy.classes} per week.</small>

          <div className="card-footer text-center">
            <button
              className="btn btn-primary w-100"
              onClick={this.handleSubmit}
              disabled={goal.target === undefined || goal.target < 0}
            >
              Save your goal
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default GoalSetter
