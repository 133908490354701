import { observable, action } from "mobx"
import { AxiosResponse, AxiosError, AxiosPromise } from "axios"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"
import LocationSummary from "models/LocationSummary"
import ServiceBooking from "apps/book/models/ServiceBooking"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import { deserialize } from "helpers/serializationHelpers"
import { withAxiosError } from "helpers/errorHandling"

export interface ServiceBookingUpdateResponse {
  booking: ServiceBooking
  location: LocationSummary
}

export default class ServiceBookingUpdateStore extends APIStore {
  @observable booking: ServiceBooking

  api = this.createClient<ServiceBookingUpdateResponse>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    DeserializeMiddleware("booking", ServiceBooking, true),
    DeserializeMiddleware("location", LocationSummary, true),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(public brandStore: BrandStore, booking: ServiceBooking) {
    super()
    this.brandStore = brandStore
    this.booking = booking
  }

  protected get baseUrl() {
    return this.brandStore.isXponential ? `/api/xpass/v2/bookings` : `/api/v2/locations/${this.booking.locationId}/bookings`
  }

  fetch() {
    return this.api.get(`${this.baseUrl}/${this.booking.id}`)
  }

  cancel(params?: {}): AxiosPromise<any> {
    this.booking.isBusy = true
    const url = `${this.baseUrl}/${this.booking.id}`

    if (this.brandStore.isXponential) {
      const locationId = { location_id: this.booking.locationId }
      return this.api.delete(url, {
        params: {
          ...params, ...locationId
        }
      })
    }
    return this.api.delete(url, {
      params: params,
    })
  }

  checkIn(): AxiosPromise<any> {
    if (process.env.ENABLE_CHECKINS === "1") {
      this.booking.isBusy = true
      return this.api.post(`${this.baseUrl}/${this.booking.id}/checkins`)
    } else {
      return Promise.reject()
    }
  }

  removeCheckin(): AxiosPromise<any> {
    if (process.env.ENABLE_CHECKINS === "1") {
      this.booking.isBusy = true
      return this.api.delete(`${this.baseUrl}/${this.booking.id}/checkins`)
    } else {
      return Promise.reject()
    }
  }

  @action.bound
  handleSuccess(res: AxiosResponse<ServiceBookingUpdateResponse>) {
    if (res.data.booking) {
      this.booking = res.data.booking
      this.booking.location = res.data.location
    }
    this.booking.isBusy = false

    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    this.booking.isBusy = false
    this.brandStore.uiStore.openError(ex)

    const res = withAxiosError(ex)
    if (res && res.data.booking && res.data.location) {
      this.booking = deserialize(res.data.booking, ServiceBooking)
      this.booking.location = deserialize(res.data.location, LocationSummary)
    }

    throw ex
  }
}
