import * as React from "react"
import * as cx from "classnames"
import { observer } from "mobx-react"
import { action, observable } from "mobx"
import PlanStore from "apps/buy/stores/PlanStore"
import { AxiosResponse, AxiosError } from "axios"
import { isApiError } from "helpers/errorHandling"
import GiftCardStore from "apps/buy/stores/GiftCardStore"

export interface Props {
  planStore: PlanStore
  giftCardStore: GiftCardStore
}

@observer
export default class GiftCardForm extends React.Component<Props, {}> {
  // this is why we have formik oh well
  // refactor to formik if this gets any more complex
  @observable value: string = ""
  @observable isDirty: boolean = false

  componentWillUnmount() {
    this.props.giftCardStore.dispose()
  }

  @action.bound
  handleGiftCardChange(e: InputEvent) {
    this.isDirty = true
    this.value = e.currentTarget.value
  }

  handleGiftCardSubmit = (e: FormEvent) => {
    e.preventDefault()
    this.isDirty = false
    this.props.giftCardStore.addCardNumber(this.value)
  }

  handleGiftCardRemove = (e: ButtonEvent) => {
    e.preventDefault()
    this.value = ""
    this.isDirty = false
    this.props.giftCardStore.removeCardNumber()
  }

  @action.bound
  handleGiftCardError(ex: AxiosError) {
    if (isApiError(ex.response) && ex.response.data.code === "invalid_code") {
      console.error(ex.response)
    } else {
      throw ex
    }
  }

  public render() {
    const { planStore } = this.props
    const { giftCardStore } = this.props
    const status = this.props.giftCardStore.status
    const isLoading = status === "loading"
    const isValid = !isLoading && !!giftCardStore.giftCardNumber
    const isInvalid = status === "error" && !this.isDirty
    const doesGiftCardCoverFullPaymentAmount = giftCardStore.todayTotal <= 0

    const inputClass = cx("form-control", {
      "is-valid": isValid,
      "is-invalid": isInvalid,
    })

    // gross, but going to be miserable to QA w/o this
    // fine to remove at some point after this is launched
    // if you're reading this and this project is launched, congratulations!
    // also, sorry!
    const isTestbrand = planStore.brandStore.brandId === "testbrand"

    if (giftCardStore.giftCardNumber) {
      return (
        <Wrapper
          text="remove"
          disabled={isLoading}
          isValid={isValid}
          isInvalid={isInvalid}
          doesGiftCardCoverFullPaymentAmount={doesGiftCardCoverFullPaymentAmount}
          onClick={this.handleGiftCardRemove}
        >
          <input
            type="text"
            className={inputClass}
            value={isLoading ? "" : giftCardStore.giftCardNumber}
            disabled
          />
        </Wrapper>
      )
    }

    return (
      <form onSubmit={this.handleGiftCardSubmit}>
        <Wrapper
          text="Apply"
          disabled={isLoading || !this.value}
          isValid={isValid}
          isInvalid={isInvalid}
          doesGiftCardCoverFullPaymentAmount={doesGiftCardCoverFullPaymentAmount}
        >
          <input
            type="text"
            className={inputClass}
            placeholder="Gift card"
            value={this.value}
            onChange={this.handleGiftCardChange}
            disabled={isLoading || !!giftCardStore.giftCardNumber}
          />
        </Wrapper>
      </form>
    )
  }
}

interface WrapperProps {
  text: string
  disabled?: boolean
  isValid?: boolean
  isInvalid?: boolean
  doesGiftCardCoverFullPaymentAmount?: boolean
  onClick?(e: ButtonEvent): void
}

const Wrapper: React.SFC<WrapperProps> = ({
  children,
  text,
  disabled,
  isValid,
  isInvalid,
  doesGiftCardCoverFullPaymentAmount,
  onClick,
}) => (
  <div>
    <hr className="mt-4 mb-4" />
    <div className="input-group">
      {children}
      <div className="input-group-append">
        <button
          className="btn btn-secondary"
          disabled={disabled}
          onClick={onClick}
        >
          {text}
        </button>
      </div>
      <small className={`text-left mt-2 ${isValid ? `text-muted` : `text-danger`}`}>{
        isValid && !doesGiftCardCoverFullPaymentAmount ?
          `The gift card entered will only cover part of your purchase. The remainder will be applied to your payment method on file.` :
          isInvalid ?
            `There was a problem fetching the gift card balance. The card number may not be valid.` :
            ``
      }</small>
    </div>
  </div>
)
