import React from "react"
import { Link } from "react-router-dom"
import BrandStore from "stores/BrandStore"
import UIStore from "stores/UIStore"
import cx from "classnames"
import closeIcon from "images/xpass/icons/close.svg"
import VerifyAccessCodeStore from "apps/xpass/stores/VerifyAccessCodeStore"

export interface Props {
  store: BrandStore
  UIStore: UIStore
  hasNoToken?: boolean
  showSkip?: boolean
  notModal?: boolean
}

interface State {
  activationCode: string
  accessCodeSuccess: boolean | undefined
}

class ClaimAccountModalOnBook extends React.Component<Props> {

  constructor(props: Props) {
    super(props)
  }

  verifyAccessCodeStore = new VerifyAccessCodeStore(this.props.store!.userStore)

  state: State = {
    activationCode: "",
    accessCodeSuccess: undefined
  }

  setActivationCode = (code: string) => {
    this.setState({ activationCode: code })
  }

  onModalClose = (triggeringEvent: string) => {{
    if(triggeringEvent === "claimAccount"){
      this.props.store!.track.event("blocker modal_tap claim account")
    }
    this.props.UIStore.closeModal()
  }}

  verifyAccessCode(code: string) {
    this.props.store!.track.event("activation code_tap submit", {}, {activationCode:code})
    this.verifyAccessCodeStore.verify(code)
    .then((res) => {
      if (res.data.success) {
        this.setState({accessCodeSuccess: true})
        this.props.store!.track.event("activation code_success")
        if (this.props.notModal) this.props.store!.routingStore.history.push("/book/xponential-xpass")
        this.props.store!.uiStore.closeModal()
        window.location.reload()
      } else {
        this.setState({accessCodeSuccess: false})
        this.props.store!.track.event("activation code_failure")
      }
    }).catch(() => {
      this.props.store!.track.event("activation code_failure")
      this.setState({accessCodeSuccess: false})
    })
  }

  render() {
    const hasError = this.state.accessCodeSuccess === false
    const { hasNoToken, showSkip, notModal } = this.props || false
    return (
      <div className={cx("claim-account-modal text-center p-4", {"not-modal": notModal})}>
        <div
        className="claim-account-modal__close"
        onClick={() => this.onModalClose("closeIconClicked")}
        >
          <img src={closeIcon} alt="Close modal" />
        </div>
        <div className="claim-account-modal__top-content">
          {hasNoToken ? (
            <h2>Looks like you don't have a Token that meets the requirements of this class.</h2>
          ) : (
            <>
              <h2>In order to {!notModal && (<>purchase or</>)} book on XPASS you need to be a member at one of our brands. </h2>
              <p>Claim an existing member account.</p>
            </>
          )}
        </div>
        <div className="claim-account-modal__middle-content">
          <Link
            className={`btn btn-primary btn-primary-large align-self-center w-100 ${hasNoToken ? "mb-3" : ""}`}
            to={hasNoToken ? "/buy/xponential-xpass" : "/account/claim"}
            onClick={() => this.onModalClose(hasNoToken ? "buyTokens" : "claimAccount")}
            >
            {hasNoToken ? "Buy Tokens" : "Claim your Account"}
          </Link>
        </div>
        {!hasNoToken && (
          <>
            <div className="border-top border-light my-5" />
            <div className="claim-account-modal__bottom-content">
              <p className="p-0 mb-4">Or enter an activation code to proceed.</p>
              <div className="form-group">
                <label htmlFor="activation-code">Activation Code</label>
                <input
                  className={`form-control ${hasError && "is-invalid"}`}
                  id="activation-code"
                  name="activation-code"
                  onChange={e => this.setActivationCode(e.target.value)}
                  type="text"
                  value={this.state.activationCode}
                />
              {hasError && <p className="mt-1 claim-account-modal__error">Invalid code</p>} 
              </div>

              <button
                className="btn btn-primary btn-primary-large align-self-center w-100 mt-auto"
                onClick={() => this.verifyAccessCode(this.state.activationCode)}
                >
                Submit
              </button>
            </div>
          </>
        )}
        {showSkip && (
          <>
            <div className="border-top border-light my-5" />
            <Link className="" to={{pathname: `/`}}>Skip</Link>
          </>
        )}

      </div>
    )
  }

}

export default ClaimAccountModalOnBook