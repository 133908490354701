import * as React from "react"
import { RouteComponentProps } from "react-router"
import { observer } from "mobx-react"
import APILoader from "components/wrappers/APILoader"
import LocationSummary from "models/LocationSummary"
import ServiceBookingStore from "apps/book/stores/ServiceBookingStore"
import BookingInfoCard from "apps/book/components/BookingInfoCard"
import PageTracker from "components/wrappers/PageTracker"
import { Link, Redirect } from "react-router-dom"
import { toServiceBookingProps } from "helpers/queryString"

export interface Props extends RouteComponentProps<{ serviceTypeId?: string }> {
  locationSummary: LocationSummary
  serviceBookingStore: ServiceBookingStore
}

@observer
export default class ServiceBookingSuccessPage extends React.Component<Props> {
  get bookingPropsFromQueryString() {
    return toServiceBookingProps(this.props.location.search)
  }

  render() {
    const { serviceBookingStore } = this.props
    const bookingProps = this.bookingPropsFromQueryString

    return (
      <APILoader
        apiStore={serviceBookingStore}
        fetchProps={bookingProps}
        alreadyLoaded={serviceBookingStore.isLoadedFor(bookingProps)}
        render={() => <SuccessPageInner {...this.props} />}
      />
    )
  }
}

interface InnerProps extends RouteComponentProps<{ serviceTypeId?: string }> {
  locationSummary: LocationSummary
  serviceBookingStore: ServiceBookingStore
}

class SuccessPageInner extends React.Component<InnerProps> {
  public render() {
    const { locationSummary, serviceBookingStore } = this.props
    const {
      brandStore,
      scheduleEntrySummary,
      serviceType,
    } = serviceBookingStore

    if (!serviceBookingStore.booking) return <Redirect to="/bookings" />

    // NOTE: the UI below is very very similar to the BookingSuccessPage
    // That component has some class-specific logic still, but the UI itself could be shared
    return (
      <div>
        <BookingInfoCard
          title="Your Booking is Confirmed!"
          scheduleEntry={scheduleEntrySummary}
          subtitle={`${serviceType!.name}`}
          locationSummary={this.props.locationSummary}
          brand={brandStore.brand}
          copy={brandStore.copy}
          store={brandStore}
        >
          <PageTracker name="service booking > success" />
          <hr />
          <div className="confirmed-links">
            {process.env.FEATURE_MVP_ONLY !== "1" && (
              <div className="confirm-link">
                <a href="#">Add to Calendar</a>
              </div>
            )}
            <div className="confirm-link">
              <a href={locationSummary.directionsUrl} target="_blank">
                Get Directions
              </a>
            </div>
            {locationSummary.email && (
              <div className="confirm-link">
                <a href={`mailto:${locationSummary.email}`}>Contact Location</a>
              </div>
            )}
          </div>
          <div className="mt-4 text-center">
            <Link className="btn btn-primary w-100" to={`/bookings`}>
              View My Schedule
            </Link>
          </div>
        </BookingInfoCard>
      </div>
    )
  }
}
