import {
  BrandData,
  baseSettings,
  baseStyles,
  baseStyleClasses,
  baseCopy,
  BrandCopy,
  baseDashboardCards,
  BrandStyles,
  BrandStyleClasses,
  BrandDashboardCards,
  BadgeBitmap,
  BrandSettings,
  eywAffiliateLinks,
} from "themes/base"

import logoUrl from "images/logos/yogasix-logo.png"
import badgeImage from "images/badges/yogasix-badge.png"
import bannerImage from "images/dashboard/yogasix-banner-card.jpg"
import appPromoImage_Video from "images/video/yogasix-app-promo.png"
import videoLandingLogo from "images/video/yogasix-go-logo.png"
import { Badge } from "themes/brands"
import appleWatchIcon from "images/apple_health/watch/yogasix.svg"
import classpointsHero from "images/classpoints/classpoints-hero_yogasix.png"
import brandIcon from "images/classpoints/brands/Y6.svg"

import badge1Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_1_Active@1x.png"
import badge60Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_60_Active@1x.png"
import badge100Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_100_Active@1x.png"
import badge250Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_250_Active@1x.png"
import badge500Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_500_Active@1x.png"
import badge750Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_750_Active@1x.png"
import badge1000Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_1000_Active@1x.png"
import badge1250Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_1250_Active@1x.png"
import badge1500Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_1500_Active@1x.png"
import badge1750Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_1750_Active@1x.png"
import badge2000Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_2000_Active@1x.png"
import badge2250Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_2250_Active@1x.png"
import badge2500Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_2500_Active@1x.png"
import badge2750Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_2750_Active@1x.png"
import badge3000Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_3000_Active@1x.png"
import badge3250Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_3250_Active@1x.png"
import badge3500Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_3500_Active@1x.png"
import badge3750Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_3750_Active@1x.png"
import badge4000Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_4000_Active@1x.png"
import badge4250Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_4250_Active@1x.png"
import badge4500Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_4500_Active@1x.png"
import badge4750Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_4750_Active@1x.png"
import badge5000Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_5000_Active@1x.png"
import badge5250Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_5250_Active@1x.png"
import badge5500Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_5500_Active@1x.png"
import badge5750Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_5750_Active@1x.png"
import badge6000Active from "images/badges/yogasix/active/Y6Warrior2022_Badges_6000_Active@1x.png"

import badge1Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_1_Inactive@1x.png"
import badge60Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_60_Inactive@1x.png"
import badge100Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_100_Inactive@1x.png"
import badge250Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_250_Inactive@1x.png"
import badge500Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_500_Inactive@1x.png"
import badge750Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_750_Inactive@1x.png"
import badge1000Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_1000_Inactive@1x.png"
import badge1250Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_1250_Inactive@1x.png"
import badge1500Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_1500_Inactive@1x.png"
import badge1750Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_1750_Inactive@1x.png"
import badge2000Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_2000_Inactive@1x.png"
import badge2250Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_2250_Inactive@1x.png"
import badge2500Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_2500_Inactive@1x.png"
import badge2750Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_2750_Inactive@1x.png"
import badge3000Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_3000_Inactive@1x.png"
import badge3250Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_3250_Inactive@1x.png"
import badge3500Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_3500_Inactive@1x.png"
import badge3750Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_3750_Inactive@1x.png"
import badge4000Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_4000_Inactive@1x.png"
import badge4250Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_4250_Inactive@1x.png"
import badge4500Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_4500_Inactive@1x.png"
import badge4750Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_4750_Inactive@1x.png"
import badge5000Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_5000_Inactive@1x.png"
import badge5250Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_5250_Inactive@1x.png"
import badge5500Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_5500_Inactive@1x.png"
import badge5750Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_5750_Inactive@1x.png"
import badge6000Inactive from "images/badges/yogasix/inactive/Y6Warrior2022_Badges_6000_Inactive@1x.png"

// import bannerImage from "images/dashboard/yogasix_banner_card.jpg"

let dashboardCards: BrandDashboardCards
if (window.globals.y6WarriorPromotion) {
  dashboardCards = [
    "NextClassCard",
    "TodaysClassCard",
    // "AttendanceCard",

    // Disable at end of Feb and enable Achievements card?
    "AchievementsCard",
    "BadgeCard",
    "AppleHealthCard",
    "ClassPointsCard",
    "ProfileCard",
    "MobileAppCard",
    "MobileOfferCard",
    "PromoCards",
  ]
} else if (window.globals.y6FebPromotion) {
  dashboardCards = [
    "NextClassCard",
    "TodaysClassCard",
    // "AttendanceCard",

    // Disable at end of Feb and enable Achievements card?
    "YogaSixFebruaryChallengeCard",
    // "AchievementsCard",
    "BadgeCard",
    "AppleHealthCard",
    "ClassPointsCard",
    "ProfileCard",
    "MobileAppCard",
    "MobileOfferCard",
    "PromoCards",
  ]
} else {
  dashboardCards = baseDashboardCards
}

const copy: BrandCopy = {
  ...baseCopy,
  badgeTitle: "Warrior Program",
  seat: "Class",
  bookClass: "Book this class",
  saveChanges: "Save",
  paymentMethod: "Payment Details",
  packages: "Class Packs",
}

const styles: BrandStyles = {
  ...baseStyles,
  badgeImage,
  logoUrl,
  bannerImage,
  appPromoImage_Video,
  videoLandingLogo,
  appleWatchIcon,
  classpointsHero,
  brandIcon
}

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
}

const badges: BadgeBitmap[] = [
  {
    label: "First Class",
    value: 1,
    active: badge1Active,
    inactive: badge1Inactive,
  },
  {
    label: "60 Classes",
    value: 60,
    active: badge60Active,
    inactive: badge60Inactive,
  },
  {
    label: "100 Classes",
    value: 100,
    active: badge100Active,
    inactive: badge100Inactive,
  },
  {
    label: "250 Classes",
    value: 250,
    active: badge250Active,
    inactive: badge250Inactive,
  },
  {
    label: "500 Classes",
    value: 500,
    active: badge500Active,
    inactive: badge500Inactive,
  },
  {
    label: "750 Classes",
    value: 750,
    active: badge750Active,
    inactive: badge750Inactive,
  },
  {
    label: "1000 Classes",
    value: 1000,
    active: badge1000Active,
    inactive: badge1000Inactive,
  },
  {
    label: "1250 Classes",
    value: 1250,
    active: badge1250Active,
    inactive: badge1250Inactive,
  },
  {
    label: "1500 Classes",
    value: 1500,
    active: badge1500Active,
    inactive: badge1500Inactive,
  },
  {
    label: "1750 Classes",
    value: 1750,
    active: badge1750Active,
    inactive: badge1750Inactive,
  },
  {
    label: "2000 Classes",
    value: 2000,
    active: badge2000Active,
    inactive: badge2000Inactive,
  },
  {
    label: "2250 Classes",
    value: 2250,
    active: badge2250Active,
    inactive: badge2250Inactive,
  },
  {
    label: "2500 Classes",
    value: 2500,
    active: badge2500Active,
    inactive: badge2500Inactive,
  },
  {
    label: "2750 Classes",
    value: 2750,
    active: badge2750Active,
    inactive: badge2750Inactive,
  },
  {
    label: "3000 Classes",
    value: 3000,
    active: badge3000Active,
    inactive: badge3000Inactive,
  },
  {
    label: "3250 Classes",
    value: 3250,
    active: badge3250Active,
    inactive: badge3250Inactive,
  },
  {
    label: "3500 Classes",
    value: 3500,
    active: badge3500Active,
    inactive: badge3500Inactive,
  },
  {
    label: "3750 Classes",
    value: 3750,
    active: badge3750Active,
    inactive: badge3750Inactive,
  },
  {
    label: "4000 Classes",
    value: 4000,
    active: badge4000Active,
    inactive: badge4000Inactive,
  },
  {
    label: "4250 Classes",
    value: 4250,
    active: badge4250Active,
    inactive: badge4250Inactive,
  },
  {
    label: "4500 Classes",
    value: 4500,
    active: badge4500Active,
    inactive: badge4500Inactive,
  },
  {
    label: "4750 Classes",
    value: 4750,
    active: badge4750Active,
    inactive: badge4750Inactive,
  },
  {
    label: "5000 Classes",
    value: 5000,
    active: badge5000Active,
    inactive: badge5000Inactive,
  },
  {
    label: "5250 Classes",
    value: 5250,
    active: badge5250Active,
    inactive: badge5250Inactive,
  },
  {
    label: "5500 Classes",
    value: 5500,
    active: badge5500Active,
    inactive: badge5500Inactive,
  },
  {
    label: "5750 Classes",
    value: 5750,
    active: badge5750Active,
    inactive: badge5750Inactive,
  },
  {
    label: "6000 Classes",
    value: 6000,
    active: badge6000Active,
    inactive: badge6000Inactive,
  },
]

const brand: BrandData = {
  settings: baseSettings,
  styles,
  styleClasses,
  copy,
  dashboardCards,
  badges,
  metrics: [],
  summaryMetrics: [],
  includeTerms: false,
  eywAffiliateLinks: eywAffiliateLinks["yogasix"],
  eywLegalLink: "https://www.yogasix.com/terms-earn-your-watch",
  eywParticipatingLink: "https://www.yogasix.com/earn-your-watch-locations"
}

export default brand
