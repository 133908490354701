import Cookies from "js-cookie"

// the backend sets a cookie when the user logs in, so that the brand site
// can find out the user's favorites and stuff.
//
// this cookie deleter deletes it when we log out.
// the backend is in charge of setting the cookie, so we don't need a
// cookie-setter. just a cookie deleter.
export default function deleteUserCookie() {
  const domainParts = document.location.hostname.split(".").slice(-2)
  const domain = `.${domainParts.join(".")}`
  Cookies.remove("xpo_user", { domain })
}
