import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action, computed } from "mobx"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import BrandStore from "stores/BrandStore"
import UserProfile from "models/UserProfile"
import NotificationSetting from "models/NotificationSetting"

interface ProfileResponse {
  user_profile: UserProfile
  notification_settings: NotificationSetting[]
}

export default class ProfileStore extends APIStore {
  @observable profile?: UserProfile
  @observable notificationSettings?: NotificationSetting[]

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("user_profile", UserProfile),
    DeserializeMiddleware("notification_settings", NotificationSetting),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(public brandStore: BrandStore) {
    super()
  }

  fetch() {
    return this.api.get(`/api/user_profiles`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<ProfileResponse>) {
    this.profile = res.data.user_profile
    this.notificationSettings = res.data.notification_settings

    return res
  }
}
