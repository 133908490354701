import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("ScheduleEntryLocation")
export default class ScheduleEntryLocation {
  @JsonProperty("brand_id", Number)
  brandId: number = undefined!

  @JsonProperty("brand_slug", String)
  brandSlug: string = undefined!

  @JsonProperty("slug", String)
  slug: string = undefined!

  @JsonProperty("site_slug", String)
  siteSlug: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("enabled", Boolean)
  enabled: boolean = undefined!

  @JsonProperty("coming_soon", Boolean)
  comingSoon: boolean = undefined!

  @JsonProperty("address", String, true)
  address?: string = undefined

  @JsonProperty("address2", String, true)
  address2?: string = undefined

  @JsonProperty("city", String, true)
  city?: string = undefined

  @JsonProperty("state", String, true)
  state?: string = undefined

  @JsonProperty("zip", String, true)
  zip?: string = undefined

  @JsonProperty("country_code", String)
  countryCode: string = undefined!

  @JsonProperty("lat", Number)
  lat: number = undefined!

  @JsonProperty("lng", Number)
  lng: number = undefined!

  @JsonProperty("description", String, true)
  description?: string = undefined

  @JsonProperty("classes_available", Boolean)
  classesAvailable: boolean = undefined!

  @JsonProperty("services_available", Boolean)
  servicesAvailable: boolean = undefined!

  @JsonProperty("xpass_opted_in", Boolean)
  xpassOptedIn: boolean = undefined!

  @JsonProperty("image_url", String)
  imageUrl: string = undefined!
}
