import * as React from "react"
import { inject, observer } from "mobx-react"

import { PageName } from "models/TrackingSchemas"
import BrandStore from "stores/BrandStore"

export interface Props {
  name: PageName
  title?: string
  // NOTE: changing extra won't track an addtl pageview
  extra?: {}
  children?: React.ReactNode
  store?: BrandStore
}

// this is a component whose sole purpose is to track what page we're on
// it could also be used to set the page title or do other things that
// relate to the logical concept of a "page"
@inject((store: BrandStore) => ({ store }))
@observer
export default class PageTracker extends React.Component<Props, {}> {
  componentDidMount() {
    const { name, extra, title } = this.props
    this.props.store!.routingStore.setCurrentPage({ name, title, extra })
  }

  componentWillUnmount() {
    // I think it probably makes sense to do this
    // avoids accidentally tracking the last page
    // could result in accidentally NOT tracking the current page if the
    // tracker gets unmounted
    if (this.props.store!.routingStore.pageName === this.props.name) {
      this.props.store!.routingStore.setCurrentPage({})
    }
  }

  public render() {
    return this.props.children || null
  }
}
