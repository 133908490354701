import { JsonObject, JsonProperty } from "json2typescript"
import RoomCell, { RoomCellConcrete } from "models/RoomCell"

@JsonObject("Room")
export default class Room {
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * How long the room is (in number of cells) in the x-axis
   *
   */
  @JsonProperty("width", Number)
  width: number = undefined!

  /**
   * How long the room is (in number of cells) in the y-axis
   *
   */
  @JsonProperty("height", Number)
  height: number = undefined!

  @JsonProperty("cells", [[RoomCellConcrete]])
  cells: RoomCell[][] = [[]]
  /**
   * A mapping of seat numbers to [x, y] coordinates in the grid, for easier lookup.
   *
   */
  // @JsonProperty("seat_index", Object)
  // seatIndex: {
  //   [seatId: string]: [number, number]
  // } = undefined!
}
