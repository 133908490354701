import * as React from "react"
import cx from "classnames"
import { InjectedFormikProps, withFormik, Form } from "formik"
import * as yup from "yup"
import { observer, inject } from "mobx-react"

import BrandStore from "stores/BrandStore"
import UserAuthStore from "../stores/xpass/UserAuthStore"
import SharedAccountControls from "apps/auth/components/SharedAccountControls"
import FormInput from "components/forms/FormInput"

import FormButtons from "components/forms/FormButtons"
import FormAlert from "components/forms/FormAlert"
import WaiverField from "apps/auth/components/WaiverField"
import { action, observable } from "mobx"
import LocationSelectorPanelField from "apps/auth/components/LocationSelectorPanelField"
import {
  phoneNumberValidator,
  nameValidator,
  birthDateValidator,
  passwordValidator,
  passwordConfirmationValidator,
} from "helpers/validations"
import { withAxiosErrorMessage } from "helpers/errorHandling"
import UserRegistration from "apps/auth/models/UserRegistration"
import handleRegistrationSubmit from "apps/auth/helpers/handleRegistrationSubmit"
import { PageName } from "models/TrackingSchemas"
import PageTracker from "components/wrappers/PageTracker"
import FormDatePicker from "components/forms/FormDatePicker"

// TODO: humanize field names in error messages

type FormValues = UserRegistration

export interface Props {
  pageName: PageName
  subheadBlock?: React.ReactNode
  store?: BrandStore
  userAuthStore: UserAuthStore
  lockLocation?: boolean
  submitText?: string
  onPageChange?(page: string): void
}

@inject((store: BrandStore) => ({ store }))
@observer
class ProspectFormInner extends React.Component<
  InjectedFormikProps<Props, FormValues>
> {
  @observable termsAndConditionsChecked = false

  componentDidUpdate() {
    // why don't the other ones need this?
    this.props.setFieldValue(
      "locationId",
      this.props.store!.locStore.currentLocation!.id
    )
  }

  @action.bound
  onTermsAndConditionsCheck(checked: boolean) {
    this.termsAndConditionsChecked = checked
  }

  public render() {
    const {
      pageName,
      errors,
      isSubmitting,
      onPageChange,
      submitText,
      store,
    } = this.props
    const base = "base" // TODO: Fix this type
    return (
      <div>
        <Form
          noValidate
          className={cx(
            "col-md-10",
            "offset-md-1",
            "col-lg-6",
            "offset-lg-3",
            "col-xl-8",
            "offset-xl-2",
            "grid-in"
          )}
        >
          {this.props.subheadBlock}

          {!this.props.subheadBlock && (
            <h2 className="text-center mb-0">{store!.copy.createAccount}</h2>
          )}

          <SharedAccountControls
            className="mb-3"
            onPageChange={onPageChange}
            currentPage="register"
            showForgotPassword={false}
          />

          <PageTracker name={pageName} />

          <FormAlert message={errors[base]} />
          <LocationSelectorPanelField
            className="col-lg-12"
            disabled={this.props.lockLocation}
          />
          <div className="row">
            <FormInput
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              maxLength={50}
              className="col-lg-6 required"
            />
            <FormInput
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              maxLength={50}
              className="col-lg-6 required"
            />
          </div>
          <div className="row">
            <FormInput
              label="Email Address"
              name="email"
              type="email"
              autoComplete="email"
              maxLength={100}
              className="col-lg-6 required"
            />
            <FormInput
              label="Phone"
              name="phoneMain"
              placeholder="(XXX) XXX-XXXX"
              autoComplete="mobile tel-national"
              className="col-lg-6 required"
              maxLength={20}
            />
          </div>
          <div className="row">
            <FormInput
              label="Password"
              name="password"
              type="password"
              autoComplete="password"
              className="col-lg-6 required"
            />
            <FormInput
              label="Repeat Password"
              name="passwordConfirmation"
              type="password"
              autoComplete="password"
              className="col-lg-6 required"
            />
          </div>
          <div className="row">
            <FormDatePicker
              label="Date of Birth"
              name="birthDate"
              autoComplete="bday"
              className="col required"
            />
          </div>

          <div className="mb-4 text-left">
            <WaiverField
              checked={this.termsAndConditionsChecked}
              onChange={this.onTermsAndConditionsCheck}
            />
          </div>

          <FormButtons
            submitText={submitText || "Sign Up"}
            disabled={isSubmitting || !this.termsAndConditionsChecked}
            centered
          >
            {/* <SharedAccountControls
              className="mt-3"
              onPageChange={onPageChange}
              currentPage="register"
            /> */}
          </FormButtons>
        </Form>
      </div>
    )
  }
}

const schema = yup.object<FormValues>().shape({
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
  phoneMain: phoneNumberValidator.required().label("Phone"),
  password: passwordValidator.required().label("Password"),
  passwordConfirmation: passwordConfirmationValidator,
  birthDate: birthDateValidator.required().label("Date of Birth"),
  locationId: yup.string().required(),
})

const ProspectForm = withFormik<Props & { store?: BrandStore }, FormValues>({
  mapPropsToValues: ({ store }) => ({
    firstName: "",
    lastName: "",
    // email: `test${Math.round(Math.random() * 100000)}@testmail.com`,
    email: "",
    phoneMain: "",
    password: "",
    passwordConfirmation: "",
    birthDate: "",
    locationId: store!.locStore.currentLocation!.id,

    base: "",
  }),
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: schema,
  handleSubmit: (values, formikBag) => {
    handleRegistrationSubmit(true, values, formikBag)
  },
})(ProspectFormInner)

export default ProspectForm
