import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"
import * as moment from "moment"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

import APIStore from "stores/APIStore"
import UserStore from "stores/UserStore"
import UserCoordinatesStore from "apps/book/stores/xpass/UserCoordinatesStore"
import ScheduleEntry, { ScheduleEntryMeta } from "apps/book/models/xpass/ScheduleEntry"
import ScheduleEntryLocation from "apps/book/models/xpass/ScheduleEntryLocation"

import aktIcon from "images/xpass/icons/brands/akt.png"
import bftIcon from "images/xpass/icons/brands/bft.png"
import clubpilatesIcon from "images/xpass/icons/brands/clubpilates.png"
import cyclebarIcon from "images/xpass/icons/brands/cyclebar.png"
import purebarreIcon from "images/xpass/icons/brands/purebarre.png"
import rowhouseIcon from "images/xpass/icons/brands/rowhouse.png"
import rumbleIcon from "images/xpass/icons/brands/rumble.png"
import stretchlabIcon from "images/xpass/icons/brands/stretchlab.png"
import strideIcon from "images/xpass/icons/brands/stride.png"
import yogasixIcon from "images/xpass/icons/brands/yogasix.png"
import kinrgyIcon from "images/xpass/icons/brands/kinrgy.png"

import aktInactiveIcon from "images/xpass/icons/brands/inactive/akt.png"
import bftInactiveIcon from "images/xpass/icons/brands/inactive/bft.png"
import clubpilatesInactiveIcon from "images/xpass/icons/brands/inactive/clubpilates.png"
import cyclebarInactiveIcon from "images/xpass/icons/brands/inactive/cyclebar.png"
import purebarreInactiveIcon from "images/xpass/icons/brands/inactive/purebarre.png"
import rowhouseInactiveIcon from "images/xpass/icons/brands/inactive/rowhouse.png"
import rumbleInactiveIcon from "images/xpass/icons/brands/inactive/rumble.png"
import stretchlabInactiveIcon from "images/xpass/icons/brands/inactive/stretchlab.png"
import strideInactiveIcon from "images/xpass/icons/brands/inactive/stride.png"
import yogasixInactiveIcon from "images/xpass/icons/brands/inactive/yogasix.png"
import kinrgyInactiveIcon from "images/xpass/icons/brands/inactive/kinrgy.png"

export interface ScheduleEntryResponse {
  scheduleEntries: ScheduleEntry[]
  scheduleEntriesMeta: ScheduleEntryMeta
}

export default class ScheduleEntryStore extends APIStore {
  @observable scheduleEntries?: ScheduleEntry[]
  @observable scheduleEntriesMeta?: ScheduleEntryMeta
  @observable highlightedStudio: ScheduleEntryLocation | undefined
  @observable filteredLocations: ScheduleEntryLocation[] = []
  @observable page: number = 1
  @observable perPage: number = 20
  @observable startDate: string = ""
  @observable startTimeRange: Array<number> = []
  @observable selectedDistance: number = 4
  @observable selectedBrands: Array<string> = []
  @observable selectedModalities: Array<string> = []
  @observable selectedIntensities: Array<string> = []
  @observable selectedBodyFocuses: Array<string> = []
  @observable peakClassesEnabled: boolean = false
  @observable freeClassesEnabled: boolean = false
  @observable isDragged: boolean = false
  @observable isPaginated: boolean = false
  @observable isFiltered: boolean = false
  @observable isLoadingEntries: boolean = false
  @observable responseStatus?: number
  @observable isRandom: boolean = false
  @observable excludePremium: boolean = false
  @observable requireEligibleToken: boolean = false
  @observable filterBy24Hours: boolean = false

  currentDate = moment().format("YYYY-MM-DD")
  startTime = moment("5:00", "H:mm").format("H:mm")
  currentEndOfDay = moment(`${this.currentDate}T22:00`, 'YYYY-MM-DDTH:mm').utc().format('YYYY-MM-DDTH:mm')
  maxEndDate = moment().add(13, 'days').utc().format('YYYY-MM-DDTH:mm')

  api = this.createClient<ScheduleEntryResponse>([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("schedule_entries", ScheduleEntry),
    DeserializeMiddleware("meta", ScheduleEntryMeta, true),
    TokenAuthMiddleware(this.userStore)
  ])

  constructor(protected userCoordinatesStore: UserCoordinatesStore, protected userStore: UserStore) {
    super()
  }

  getEndOfDay() {
    if (this.startDate) {
      const endOfDay = moment(`${this.startDate}T22:00`, 'MM/DD/YYYYTH:mm').utc().format('YYYY-MM-DDTH:mm')
      return `&end_time=${endOfDay}`
    }
    return `&end_time=${this.currentEndOfDay}`
  }

  @computed get timeParam() {
    // If 24 hours filter is selected and no start date is selected or today is selected, return 24 hours from now
    const isTodaySelected = moment().format("MM/DD/YYYY") === this.startDate
    if (this.filterBy24Hours && (!this.startDate || isTodaySelected)) {
      const now = moment().utc();
      const in24Hours = moment().utc().add(24, 'hours');
      const startDateTime = `start_start_time=${now.format("YYYY-MM-DDTH:mm")}`;
      const endDateTime = `&end_start_time=${in24Hours.format("YYYY-MM-DDTH:mm")}`;
      return `${startDateTime}${endDateTime}`;
    }

    const formatDate = moment(this.startDate, 'MM/DD/YYYY').utc().format('YYYY-MM-DD')
    const startDate = this.startDate ? formatDate : this.currentDate
    const currentDate =  `start_start_time=${moment(`${startDate}T${this.startTime}`, 'YYYY-MM-DDTH:mm').utc().format("YYYY-MM-DDTH:mm")}`
    const startStartTime = this.startTimeRange && this.startTimeRange.length > 0 ? moment(`${startDate}T${this.startTimes(this.startTimeRange)[0]}`, 'YYYY-MM-DDTH:mm').utc().format("YYYY-MM-DDTH:mm") : undefined
    const startEndTime = this.startTimeRange && this.startTimeRange.length > 0 ? moment(`${startDate}T${this.startTimes(this.startTimeRange)[1]}`, 'YYYY-MM-DDTH:mm').utc().format("YYYY-MM-DDTH:mm") : undefined

    const startDateTime = startStartTime && startEndTime ? `start_start_time=${startStartTime}&end_start_time=${startEndTime}` : currentDate
    const endOfDayDateTime = startEndTime ? '' : this.getEndOfDay()

    return `${startDateTime}${endOfDayDateTime}`
  }

  @computed get locationsParam() {
    return this.highlightedStudio ? `&locations=${this.highlightedStudio.slug}` : ""
  }

  @computed get distanceParam() {
    return this.distanceMiles && this.distanceMiles !== 10 ? `&distance=${this.distanceMiles}` : ""
  }

  @computed get brandsParam() {
    return this.selectedBrands && this.selectedBrands.length > 0 ? `&brands=${this.selectedBrands.join(',')}` : ""
  }

  @computed get intensitiesParam() {
    return this.selectedIntensities && this.selectedIntensities.length > 0 ? `&intensities=${this.selectedIntensities.join(',')}` : ""
  }

  @computed get modalitiesParam() {
    return this.selectedModalities && this.selectedModalities.length > 0 ? `&modalities=${this.selectedModalities.join(',')}` : ""
  }

  @computed get bodyFocusesParam() {
    return this.selectedBodyFocuses && this.selectedBodyFocuses.length > 0 ? `&body_focuses=${this.selectedBodyFocuses.join(',')}` : ""
  }

  @computed get randomParam() {
    return this.isRandom ? `&random=true` : ""
  }

  @computed get excludePremiumParam() {
    return this.excludePremium ? `&exclude_premium=true` : ""
  }

  @computed get requireEligibleTokenParam() {
    return this.requireEligibleToken ? `&require_eligible_token=true` : ""
  }

  @computed get pageParam() {
    const page = this.page ? `&page=${this.page}` : ""
    const perPage= this.perPage ? `&per_page=${this.perPage}` : ""
    return `${page}${perPage}`
  }

  @computed get coordinatesParam() {
    const { userCoordinates, locationCoordinates } = this.userCoordinatesStore
    return locationCoordinates ? `&lng=${locationCoordinates.lng}&lat=${locationCoordinates.lat}` : userCoordinates ? `&lng=${userCoordinates.lng}&lat=${userCoordinates.lat}`  : ""
  }

  // Converts index value of selected distance to numerical value in miles
  @computed get distanceMiles() {
    const convertDistance = (num: number) => {
      switch (num) {
        case 0:
          return 0.5
        case 1:
          return 1
        case 2:
          return 3
        case 3:
          return 5
        case 4:
          return 10
        default:
          return 10
      }
    }
    return convertDistance(this.selectedDistance)
  }

  // Converts index range of selected times
  startTimes(range: Array<number>) {
    const convertTime = (num: number) => {
      const time = (num * 15/60) + 5
      const splitTime = time.toString().split('.')
      const toMinutes = Number('.' + splitTime[1]) * 60
      const setMinutes = isNaN(toMinutes) ? '00' : toMinutes
      const setHour = Number(splitTime[0])
      return `${setHour}:${setMinutes}`
    }
    return [convertTime(range[0]), convertTime(range[1])]
  }

  getBrandIcon(brand: string) {
    switch (brand) {
      case "akt":
        return aktIcon
      case "bft":
        return bftIcon
      case "clubpilates":
        return clubpilatesIcon
      case "cyclebar":
        return cyclebarIcon
      case "purebarre":
        return purebarreIcon
      case "rowhouse":
        return rowhouseIcon
      case "rumble":
        return rumbleIcon
      case "stretchlab":
        return stretchlabIcon
      case "stride":
        return strideIcon
      case "yogasix":
        return yogasixIcon
      case "kinrgy":
        return kinrgyIcon
      case "akt-inactive":
        return aktInactiveIcon
      case "bft-inactive":
        return bftInactiveIcon
      case "clubpilates-inactive":
        return clubpilatesInactiveIcon
      case "cyclebar-inactive":
        return cyclebarInactiveIcon
      case "purebarre-inactive":
        return purebarreInactiveIcon
      case "rowhouse-inactive":
        return rowhouseInactiveIcon
      case "rumble-inactive":
        return rumbleInactiveIcon
      case "stretchlab-inactive":
        return stretchlabInactiveIcon
      case "stride-inactive":
        return strideInactiveIcon
      case "yogasix-inactive":
        return yogasixInactiveIcon
      case "kinrgy-inactive":
        return kinrgyInactiveIcon
      // should set a different default icon..
      default:
        return aktIcon
    }
  }

  fetch() {
    const timeParam = this.timeParam
    const pageParam = this.pageParam
    const coordinatesParam = this.coordinatesParam
    this.isLoadingEntries = true

    const url = `/api/xpass/v2/schedule_entries?${timeParam}${pageParam}${coordinatesParam}`
    return this.api.get(url).then(res => {
      return res
    }).catch((err) => {
      this.handleError(err)
    })
  }

  fetchByFilters(isPaginated?: boolean) {
    if (isPaginated) {
      this.isLoadingEntries = false
    } else {
      this.isLoadingEntries = true
    }

    const url = `/api/xpass/v2/schedule_entries?${this.timeParam}${this.locationsParam}${this.distanceParam}${this.brandsParam}${this.pageParam}${this.coordinatesParam}${this.intensitiesParam}${this.modalitiesParam}${this.bodyFocusesParam}${this.randomParam}${this.excludePremiumParam}${this.requireEligibleTokenParam}`
    return this.api.get(url).then(res => {
      return res
    }).catch((err) => {
      this.handleError(err)
    })
  }

  // Selected studio location
  @action setHighlightedStudio(studio: ScheduleEntryLocation | undefined) {
    this.highlightedStudio = studio
  }
  // Studio locations in selected city
  @action setFilteredLocations(locations: ScheduleEntryLocation[]) {
    this.filteredLocations = locations
  }
  // Page count number
  @action setPage(page: number) {
    this.page = page
  }
  // Selected start date
  @action setStartDate(date: string) {
    this.startDate = date
  }
  // Filter by 24 hours
  @action set24HoursFilter(filter: boolean) {
    this.filterBy24Hours = filter
  }
  // Index values of selected start times
  @action setStartTimeRange(startTimeRange: Array<number>) {
    this.startTimeRange = startTimeRange
  }
  // Index value of selected distance
  @action setDistance(distance: number) {
    this.selectedDistance = distance
  }
  // Sets string of selected brands
  @action setBrands(brands: Array<string>) {
    this.selectedBrands = brands
  }
  @action setModalities(modalities: Array<string>) {
    this.selectedModalities = modalities
  }
  @action setIntensities(intensities: Array<string>) {
    this.selectedIntensities = intensities
  }
  @action setBodyFocuses(bodyFocuses: Array<string>) {
    this.selectedBodyFocuses = bodyFocuses
  }
  // Sets peak classes state
  @action setPeakClassesEnabled(isEnabled: boolean) {
    this.peakClassesEnabled = isEnabled
  }
  // Sets free classes state
  @action setFreeClassesEnabled(isEnabled: boolean) {
    this.freeClassesEnabled = isEnabled
  }
  // Sets map drag state
  @action setIsDragged(isDragged: boolean) {
    this.isDragged = isDragged
  }
  // Sets paginated state
  @action setIsPaginated(isPaginated: boolean) {
    this.isPaginated = isPaginated
  }
  // Sets filtered state
  @action setIsFiltered(isFiltered: boolean) {
    this.isFiltered = isFiltered
  }
  @action setIsRandom(isRandom: boolean) {
    this.isRandom = isRandom
  }
  @action setExcludePremium(excludePremium: boolean) {
    this.excludePremium = excludePremium
  }
  @action setRequireEligibleToken(requireEligibleToken: boolean) {
    this.requireEligibleToken = requireEligibleToken
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.responseStatus = res.status
    this.isLoadingEntries = false
    this.scheduleEntries = res.data.schedule_entries
    this.scheduleEntriesMeta = res.data.meta
    return res
  }

  @action.bound
  handleError(err: AxiosError) {
    console.log(err.response)
    this.responseStatus = err.response && err.response.status
    if (err.response && err.response.status === 404) {
      this.scheduleEntries = []
      return err.response
    }
    throw err
  }
}
