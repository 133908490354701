import * as React from "react"
import { action, computed } from "mobx"

import BaseStore from "stores/BaseStore"
import LocationsModal from "apps/location_finder/components/LocationsModal"
import BrandStore from "stores/BrandStore"
import LocationsStore from "stores/LocationsStore"
import LocationSummary from "models/LocationSummary"

type LocationFilter = (location: LocationSummary) => Boolean

// global UI store for the location picker
// this is for the _outer_ location picker, not the modal
export default class LocationPickerStore extends BaseStore {
  // this guy gets his own LocationsStore
  modalLocationsStore = new LocationsStore(this.store)

  filter?: LocationFilter

  constructor(public store: BrandStore) {
    super()
  }

  @computed get anyLocations() {
    return !!(
      this.store.locStore.currentLocation &&
      this.store.locStore.locations.length
    )
  }

  @computed get currentLocation() {
    return this.store.locStore.currentLocation
  }

  @computed get menuLocations() {
    return this.store.locStore.locations.filter(
      location =>
        location.id !== this.currentLocation!.id &&
        (!this.filter || this.filter!(location))
    )
  }

  @action
  setFilter(newFilter: LocationFilter) {
    this.filter = newFilter
  }

  @action
  clearFilter() {
    this.filter = undefined
  }

  dispose() {
    this.modalLocationsStore.dispose()
    super.dispose()
  }

  handleModalSelect = (location: LocationSummary) => {
    this.store.locationsStore.addLocations([location])

    this.changeCurrentLocation(location.id)
    this.store.uiStore.closeModal()
    this.store.track.event("studio map_tap studio", { loc: location })
  }

  handleSelect = async (locationId: string) => {
    const { locStore, uiStore } = this.store

    if (!locationId) return
    if (locationId !== "all") {
      await this.changeCurrentLocation(locationId)
      this.store.track.event("studio menu_tap studio", {
        loc: this.store.locStore.locations.find(loc => loc.id === locationId),
      })
      return
    }

    console.log('all')
    this.modalLocationsStore.fetch({ geoip: true })

    uiStore.openModal({
      children: (
        <LocationsModal
          store={this.store}
          onSelect={this.handleModalSelect}
          modalLocationsStore={this.modalLocationsStore}
        />
      ),
      modalShow: true,
      size: "xl",
    })
    this.store.track.event("studio menu_more")
  }

  getLocationLink(locationId: string) {
    if (
      this.store.locStore.isInLocationRoute &&
      this.store.locStore.currentLocationId
    ) {
      // ghetto but I think it always works? we can't get at the match from here
      // note that it wipes out the query string, hash, state
      return this.store.routingStore.location.pathname.replace(
        this.store.locStore.currentLocationId,
        locationId
      )
    }
    return undefined
  }

  // this maybe belongs on CurrentLocationStore
  //
  // would be nice to have every URL have the location in its path, rather than
  // doing this
  async changeCurrentLocation(locationId: string) {
    const newUrl = this.getLocationLink(locationId)
    if (newUrl) {
      // starts over from the beginning, so we don't need to setCurrent
      this.store.routingStore.push(newUrl)
    } else if (this.store.locStore.hasLocationQuery) {
      // still need to setCurrent if only the query string changed - same
      // route will still match
      this.store.routingStore.updateQuery({ location_id: locationId })
    } else {
      this.store.locStore.setCurrent({ locationId })
    }
  }
}
