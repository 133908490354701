import toPairs from "lodash/toPairs"
import fromPairs from "lodash/fromPairs"
import { ServiceBookingProps } from "apps/book/stores/ServiceBookingStore"

export type QueryString = Record<string, string | undefined>

export function parse(search: string) {
  const params = search.substr(1)
  const tokens = params.split("&")

  return fromPairs(tokens.map(kv => kv.split("=").map(decodeURIComponent)))
}

export function stringify(query: QueryString) {
  return toPairs(query)
    .filter(pair => pair.every(x => !!x))
    .map(pair => pair.map(v => encodeURIComponent(v!)).join("="))
    .join("&")
}

// TODO: Find a more appropriate place for these two functions
export function toServiceBookingProps(rawQuery: string): ServiceBookingProps {
  const query = parse(rawQuery)
  return {
    durationId: query.duration_id || query.durationId,
    instructorId: query.instructor_id || query.instructorId,
    startsAt: query.starts_at || query.startsAt,
    locationId: query.location_id || query.locationId,
    userPremiumCost: query.user_premium_cost || query.userPremiumCost,
  }
}

export function fromServiceBookingProps(
  props: ServiceBookingProps,
  additionalParameters: object = {}
): string {
  return stringify({
    duration_id: props.durationId,
    instructor_id: props.instructorId,
    starts_at: props.startsAt,
    location_id: props.locationId,
    userPremiumCost: props.userPremiumCost ? props.userPremiumCost.toString() : undefined,
    ...additionalParameters,
  })
}
