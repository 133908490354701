import * as React from "react"
import { observer, inject } from "mobx-react"
import capitalize from "lodash/capitalize"
import { RouteComponentProps } from "react-router"
import LocationSummary from "models/LocationSummary"
import ScheduleEntry from "models/ScheduleEntry"

import BrandStore from "stores/BrandStore"
import ScheduleStore from "apps/book/stores/ScheduleStore"
import PageTracker from "components/wrappers/PageTracker"
import Heading from "components/Heading"
import ResponsiveTable from "components/ResponsiveTable"
import APILoader from "components/wrappers/APILoader"
import BookButton from "apps/book/components/BookButton"
import ClassCategoryStore from "apps/book/stores/ClassCategoryStore"
import HeaderAddressPanel from "components/HeaderAddressPanel"
import QueryStringMonitor from "apps/book/stores/QueryStringMonitor"
import ScheduleWeekNav from "apps/book/components/ScheduleWeekNav"
import Offer from "models/Offer"
import OfferStore from "stores/OfferStore"
import BackToBrandSite from "apps/auth/components/BackToBrandSite"
import { computed, observable } from "mobx"

export interface Props
  extends RouteComponentProps<{ classCategoryId: string }> {
  store?: BrandStore
  locationSummary: LocationSummary
  // scheduleStore: ScheduleStore
  onBookingCheck: (
    entry: ScheduleEntry,
    offerId?: string
  ) => (e: ButtonEvent) => void
}

// A mini version of the schedule that shows a single class at a time
@inject((store: BrandStore) => ({ store }))
@observer
export default class MiniSchedulePage extends React.Component<Props> {
  private scheduleStore = new ScheduleStore(
    this.props.locationSummary,
    this.props.store!,
    {
      anchor: "day",
    }
  )

  private qsMonitor = new QueryStringMonitor(
    this.scheduleStore,
    this.props.store!
  )

  @computed
  get offerId() {
    return this.props.store!.routingStore.query.offer
  }

  componentDidMount() {
    // must be in DIDmount because the previous page may
    // do the opposite in its own unmount
    this.props.store!.uiStore.lockLocationPicker()
    this.props.store!.uiStore.hideNavLinks()
  }

  componentWillUnmount() {
    this.props.store!.uiStore.unlockLocationPicker()
    this.props.store!.uiStore.showNavLinks()
    this.qsMonitor.dispose()
    this.scheduleStore.dispose()
  }

  public render() {
    const { match, store, locationSummary } = this.props
    const copy = store!.copy
    const entries = this.scheduleStore.filteredEntries.filter(e => !e.tooLate)
    const classCategoryId = match.params.classCategoryId

    const missingPureBarreIntroClass = entries.length < 1 && this.offerId === 'free-intro-class-first_timer' && store!.brandId === "purebarre"

    return (
      <div>
        <PageTracker name={"offer > schedule"} extra={{ classCategoryId }} />
        <PageHeader {...this.props} offerId={this.offerId} />
        <HeaderAddressPanel locationSummary={locationSummary} showName />
        {missingPureBarreIntroClass &&
          <>
            <h2>We just booked our last spot. But good news! We are adding more classes.</h2><p>We will be in touch soon to get you booked into an upcoming class.</p>
          </>
        }
        {!missingPureBarreIntroClass &&
          <ScheduleWeekNav
            week={this.scheduleStore.week}
            className="mt-3 mb-2 mb-lg-3"
            compactLabels
            hidePast
          />
        }
        <APILoader
          apiStore={this.scheduleStore}
          alreadyLoaded={this.scheduleStore.week.loaded}
          fetchProps={{ class_category_id: classCategoryId }}
          key={this.scheduleStore.week.startDate}
          elementSize="page"
          render={() => (
            <>
              {!missingPureBarreIntroClass &&
                <ResponsiveTable
                  rows={entries.map(entry => ({ entry }))}
                  rowKey={({ entry }) => entry.id}
                  // intersperses date headings
                  // unused
                  // divider={(last, cur, cols) => {
                  //   if (!last || last.entry.dateString !== cur.entry.dateString) {
                  //     return (
                  //       <tr>
                  //         <td colSpan={cols}>
                  //           <h5 className="alert alert-light shadow-sm m-0 py-1 d-inline-block text-dark">
                  //             {cur.entry.startsAt.format("dddd, MMMM D")}
                  //           </h5>
                  //         </td>
                  //       </tr>
                  //     )
                  //   } else {
                  //     return null
                  //   }
                  // }}
                  columns={[
                    {
                      heading: copy.class,
                      cell: ({ entry }) => (
                        <>
                          <h3 className="mb-0">{entry.title}</h3>
                          {entry.subtitle && (
                            <div>
                              <strong>{entry.subtitle}</strong>
                            </div>
                          )}
                        </>
                      ),
                    },
                    {
                      heading: "When",
                      cell: ({ entry }) => (
                        <>
                          <strong>{entry.startsAt.format("dddd, MMMM D")}</strong>
                          <br />
                          {entry.startTime}
                      &ndash;
                          {entry.endTime}
                        </>
                      ),
                    },
                    {
                      heading: copy.instructor,
                      cell: ({ entry }) =>
                        entry.instructor && entry.instructor.name,
                    },
                    {
                      key: "isFull",
                      cell: ({ entry }) => (
                        <span className="h5 m-0">
                          {entry.freeSpots! <=
                            store!.brandData.settings.freeSpotsThreshold &&
                            !entry.isOver
                            ? entry.isFull
                              ? `${capitalize(copy.class)} is Full`
                              : entry.spotsString
                            : ""}
                        </span>
                      ),
                    },
                    {
                      key: "book",
                      type: "button",
                      cell: ({ entry }) => (
                        <BookButton
                          entry={entry}
                          copy={copy}
                          onClick={this.props.onBookingCheck(entry, this.offerId)}
                        />
                      ),
                    },
                  ]}
                />
              }
            </>
          )}
        />


        {this.scheduleStore.status === "loaded" && entries.length > 0 && (
          <ScheduleWeekNav
            week={this.scheduleStore.week}
            className="mt-3 mb-3"
            compactLabels
            hidePast
          />
        )}
      </div>
    )
  }
}

@observer
class PageHeader extends React.Component<Props & { offerId?: string }> {
  @observable offer?: Offer

  private classCategoryStore?: ClassCategoryStore
  private offerStore?: OfferStore

  constructor(props: Props) {
    super(props)
    const offerId = this.props.offerId
    if (offerId) {
      if (
        offerId &&
        this.props.location.state &&
        this.props.location.state[offerId]
      ) {
        this.offer = this.props.location.state[offerId]
      } else {
        this.offerStore = new OfferStore(
          { type: "id", id: offerId },
          this.props.locationSummary
        )
      }
    } else {
      this.classCategoryStore = new ClassCategoryStore(
        this.props.match.params.classCategoryId
      )
    }
  }

  componentWillUnmount() {
    if (this.classCategoryStore) this.classCategoryStore.dispose()
    if (this.offerStore) this.offerStore.dispose()
  }

  public render() {
    if (this.offer) {
      return <OfferHeader offer={this.offer} />
    } else if (this.offerStore && !this.offer) {
      return (
        <APILoader
          apiStore={this.offerStore}
          alreadyLoaded
          render={() => <OfferHeader offer={this.offerStore!.offer!} />}
          renderLoading={() => <Heading>Upcoming Schedule</Heading>}
          renderError={() => <></>}
        />
      )
    } else {
      return (
        <APILoader
          apiStore={this.classCategoryStore!}
          alreadyLoaded
          render={() => (
            <>
              <Heading>
                {this.classCategoryStore!.classCategory!.name} Schedule
              </Heading>
              <p>{this.classCategoryStore!.classCategory!.description}</p>
            </>
          )}
          renderLoading={() => <Heading>Upcoming Schedule</Heading>}
        />
      )
    }
  }
}

const OfferHeader: React.SFC<{ offer: Offer }> = ({ offer }) => (
  <div>
    <>
      <BackToBrandSite />
      <Heading>{offer.name}</Heading>
      <p>{offer.description}</p>
    </>
  </div>
)
