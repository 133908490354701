import * as React from "react"
import cx from "classnames"
import { InjectedFormikProps, withFormik, Form } from "formik"
import * as yup from "yup"
import { observer, inject } from "mobx-react"
import SharedAccountControls from "apps/auth/components/SharedAccountControls"
import { AxiosError } from "axios"
import { isAuthError } from "helpers/errorHandling"
import BrandStore from "stores/BrandStore"
import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"
import FormAlert from "components/forms/FormAlert"
import LocationsStore from "stores/LocationsStore"
import { RouteComponentProps, Switch, Route, Redirect } from "react-router"
import APILoader from "components/wrappers/APILoader"
import { Link } from "react-router-dom"
import Heading from "components/Heading"
import { PageName } from "models/TrackingSchemas"
import PageTracker from "components/wrappers/PageTracker"

export interface Props extends RouteComponentProps {
  pageName: PageName
  store?: BrandStore
  onPageChange?(page: string): void
}

interface InnerProps extends Props {
  email: string
  locationsStore: LocationsStore
}

// The idea here is that we'll look up a list of locations that have a user with
// this email and then surface them to the user to pick between. If none we
// give them all the locations and they can register at one.
// Same as Fueled. Unsure if needed.

const EmailLookupForm: React.SFC<
  InjectedFormikProps<InnerProps, { email: string }>
> = observer(
  ({
    pageName,
    touched,
    errors,
    isSubmitting,
    onPageChange,
    store,
    locationsStore,
    match,
    values,
  }) => (
    <div
      className={cx(
        "col-md-10",
        "offset-md-1",
        "col-lg-8",
        "offset-lg-2",
        "col-xl-6",
        "offset-xl-3"
      )}
    >
      <div className="row">
        <PageTracker name={pageName} />
        <Form noValidate className="grid-in col-lg-10 offset-lg-1">
          <Heading>Welcome!</Heading>
          <FormAlert message={errors["base"]} />
          {/* <LocationSelectorField locked={this.props.lockLocation} /> */}
          <FormInput
            placeholder="Email Address"
            label="Enter your email address to get started"
            name="email"
            type="email"
          />

          <FormButtons
            submitText="Next Step"
            disabled={isSubmitting}
            centered
            className="float-in delay-4"
          >
            <SharedAccountControls
              className="mt-3"
              onPageChange={onPageChange}
              brandText={store!.brand.name}
              currentPage="login"
            />
          </FormButtons>
        </Form>
      </div>
    </div>
  )
)

const EmailLocationResults = ({
  locationsStore,
  email,
  store,
  match,
  onPageChange,
}: InnerProps) => (
  <div
    className={cx(
      "col-md-10",
      "offset-md-1",
      "col-lg-8",
      "offset-lg-2",
      "col-xl-6",
      "offset-xl-3"
    )}
  >
    <APILoader
      apiStore={locationsStore}
      fetchProps={{ email: email, geoip: true }}
      alreadyLoaded
      key={email}
      render={() => {
        if (!locationsStore.locations.length) {
          return (
            <div>
              <p className="text-center">
                We could not find any {store!.brand.name} accounts for {email}.
                <br />
                <Link
                  to={{
                    pathname: "/auth/register",
                    search: `email=${email}`,
                  }}
                >
                  {store!.copy.createAccount}
                </Link>
                <br />
                <Link
                  to={{
                    pathname: match.path,
                    search: `email=${email}`,
                  }}
                >
                  Try a different email address
                </Link>
              </p>
            </div>
          )
        }
        return (
          <div className="cards-in">
            <p className="text-center">
              We found accounts for <strong>{email}</strong> at the following
              studios:
            </p>

            {locationsStore.locations.map(loc => {
              const markerColor = "6c757d"
              const zoom = 15
              const token = window.globals.mapboxAccessToken
              const mapUrl = `https://api.mapbox.com/v4/mapbox.emerald/pin-l-marker+${markerColor}(${loc.lng},${loc.lat})/${loc.lng},${loc.lat},${zoom}/300x150@2x.png?access_token=${token}`
              return (
                <div
                  className="card mb-3"
                  key={loc.id}
                  style={{ minHeight: 200 }}
                >
                  <Link
                    className="flex-grow-1 d-flex text-decoration-none text-reset"
                    to={{
                      pathname: "/auth/login",
                      search: `email=${email}`,
                    }}
                  >
                    <div
                      className="w-50"
                      style={{
                        background: `no-repeat center / cover`,
                        backgroundImage: `url('${mapUrl}')`,
                      }}
                    />
                    <div className="card-body w-50 p-3">
                      <h4>
                        {store!.brand.name} {loc.name}
                      </h4>
                      <address className="mb-0">
                        {loc.address} {loc.address2}
                        <br />
                        {loc.city}, {loc.state} {loc.zip}
                      </address>
                    </div>
                  </Link>
                </div>
              )
            })}
            <p className="text-center mt-3">
              <Link
                to={{
                  pathname: match.path,
                  search: `email=${email}`,
                }}
              >
                Try a different email address
              </Link>
            </p>
            <SharedAccountControls
              className="mt-3"
              onPageChange={onPageChange}
              brandText={store!.brand.name}
              currentPage="login"
            />
          </div>
        )
      }}
    />
  </div>
)

const schema = yup.object<{ email: string }>().shape({
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
})

const FormikWrapper = withFormik<InnerProps, { email: string }>({
  mapPropsToValues: props => ({
    email: props.email,
  }),
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: schema,
  handleSubmit: (values, formikBag) => {
    formikBag.props.locationsStore.locations = []
    formikBag.props.locationsStore.setStatus("idle")
    formikBag.props.history.push(
      `${formikBag.props.match.path}/results?email=${values.email}`
    )
  },
})

@inject((store: BrandStore) => ({ store }))
@observer
export default class EmailLookupOuter extends React.Component<Props, {}> {
  locationsStore = new LocationsStore(this.props.store!)

  public render() {
    const { store, match } = this.props
    const email = store!.routingStore.query.email
    return (
      <Switch>
        <Route
          path={match.path}
          exact
          render={() => {
            const InnerForm = FormikWrapper(EmailLookupForm)
            return (
              <InnerForm
                {...this.props}
                email={email || ""}
                locationsStore={this.locationsStore}
              />
            )
          }}
        />
        <Route
          path={`${match.path}/results`}
          exact
          render={() => {
            if (email) {
              return (
                <EmailLocationResults
                  email={email}
                  {...this.props}
                  locationsStore={this.locationsStore}
                />
              )
            } else {
              return <Redirect to={match.path + `?email=${email}`} />
            }
          }}
        />
      </Switch>
    )
  }
}
