import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action } from "mobx"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import BrandStore from "stores/BrandStore"
import ClassToken from "models/xpass/ClassToken"

interface TokensResponse {
  class_tokens: ClassToken[]
}

export default class TokensStore extends APIStore {
  @observable classTokens: ClassToken[] = []
  @observable isLoadingTokens: boolean = false

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("class_tokens", ClassToken),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(protected brandStore: BrandStore) {
    super()
  }

  @action
  fetch(showOnlyActiveTokens: boolean = true) {
    this.isLoadingTokens = true
    let params = { only_active: showOnlyActiveTokens }
    return this.api.get(`/api/xpass/tokens`, { params })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<TokensResponse>) {
    this.classTokens = res.data.class_tokens
    this.isLoadingTokens = false
    return res
  }
}