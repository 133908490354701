import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("XpassUserRegistration")
export default class XpassUserRegistration {
  @JsonProperty("email", String)
  email: string = undefined!

  @JsonProperty("password", String)
  password?: string = undefined

  @JsonProperty("first_name", String)
  firstName: string = undefined!

  @JsonProperty("last_name", String)
  lastName: string = undefined!

  @JsonProperty("birth_date", String, true)
  birthDate?: string = undefined

  @JsonProperty("phone_main", String)
  phoneMain?: string = undefined!

  @JsonProperty("zip", String, true)
  zip?: string = undefined

  base?: string = undefined!

  platform? = "web"
}
