import * as React from "react"
import { action } from "mobx"
import { observer, inject } from "mobx-react"
import * as cx from "classnames"
import moment from "moment"

import BrandStore from "stores/BrandStore"
import LocationSummary from "models/LocationSummary"

import APILoader from "components/wrappers/APILoader"
import CustomPills from "components/CustomPills"
import ResponsiveTable from "components/ResponsiveTable"

import ServiceType from "apps/book/models/ServiceType"
import ServiceScheduleEntry from "apps/book/models/ServiceScheduleEntry"
import ScheduleWeekNav from "apps/book/components/ScheduleWeekNav"
import ScheduleDayNav from "apps/book/components/ScheduleDayNav"
import QueryStringMonitor from "apps/book/stores/QueryStringMonitor"
import ServiceScheduleStore from "apps/book/stores/ServiceScheduleStore"
import ServiceBooking from "../models/ServiceBooking"

import tokenIcon from "images/xpass/token.png"

// Rows in the table may be schedule entries OR existing bookings
type ServiceEntryIsh = ServiceScheduleEntry | ServiceBooking

interface RouteInfo {
  pathname: string
  search: string
}

export interface ServiceTypeScheduleProps {
  // TODO: if this needs to be able to load standalone it will need to take
  // an id

  allServiceTypes: Array<ServiceType>
  serviceType: ServiceType
  locationSummary: LocationSummary
  store?: BrandStore
  isBusy: boolean
  routeInfo: RouteInfo
  xpassLocationId?: string
  onBookClick(entry: ServiceScheduleEntry): void
  onServiceTypeSelect(event: SelectEvent): void
  updateQuery(key: string, value: string): void
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ServiceTypeSchedule extends React.Component<
  ServiceTypeScheduleProps
> {
  scheduleStore = new ServiceScheduleStore(
    this.props.serviceType.id,
    this.props.locationSummary,
    this.props.store!,
    this.props.updateQuery
  )
  qsMonitor = new QueryStringMonitor(this.scheduleStore, this.props.store!)

  get hasFilters() {
    return (
      this.scheduleStore.instructors.length > 1 ||
      this.scheduleStore.durations.length > 1 ||
      this.props.allServiceTypes.length > 1
    )
  }

  componentWillMount() {
    this.scheduleStore.dispose()
  }

  componentWillUnmount() {
    this.scheduleStore.dispose()
    this.qsMonitor.dispose()
  }

  handleBookClick = (entry: ServiceEntryIsh) => (e: ButtonEvent) => {
    e.preventDefault()
    const { session } = this.props.store!.userStore
    const { isFrozen } = session!
    if (isFrozen) {
      this.props.store!.uiStore.seenAccountWarning = false
      return this.props.store!.uiStore.openAccountWarning(session!)
    }

    if (this.isScheduleEntry(entry)) {
      this.props.onBookClick(entry)
    }
  }

  @action.bound
  handleInstructorSelect(e: SelectEvent) {
    this.props.updateQuery("instructorId", e.currentTarget.value || "")
  }

  @action.bound
  handleDurationSelect(val: string) {
    this.props.updateQuery("durationId", val)
  }

  isScheduleEntry(entry: ServiceEntryIsh): entry is ServiceScheduleEntry {
    return "notBookable" in entry
  }

  isEntryBookable(entry: ServiceEntryIsh) {
    const { isFrozen } = this.props.store!.userStore.session!

    return (
      !this.props.isBusy && this.isScheduleEntry(entry) && !entry.notBookable && !isFrozen
    )
  }

  serviceTypeName(entry: ServiceEntryIsh) {
    if (this.isScheduleEntry(entry)) {
      return this.props.serviceType.name
    } else {
      return entry.scheduleEntry.title
    }
  }

  entryButtonText(entry: ServiceEntryIsh) {
    const { isFrozen } = this.props.store!.userStore.session!
    if (this.isScheduleEntry(entry)) {
      return entry.notBookable ? "Booking Closed" : isFrozen ? "Account Frozen" : "Book"
    } else {
      return "Booked"
    }
  }

  render() {
    const {
      allServiceTypes,
      onServiceTypeSelect,
      serviceType,
      store,
    } = this.props
    const { week } = this.scheduleStore
    const { date } = week
    const { pathname, search } = this.props.routeInfo

    const placeholder = (
      <div>
        <h3 className="mt-5">{`No ${
          store!.copy.session
        }s open for booking on ${moment(date).format(store!.styleClasses.ServiceTypeSchedule__sessionDateFormat)}`}</h3>
        <p className="p-0">
          {` Please choose a different date or filter by different criteria to display available ${
            store!.copy.session
          }s${store!.isXponential ? "." : ` at ${store!.locStore.currentLocation!.name}.`}`}
        </p>
      </div>
    )

    return (
      <div>
        {/* TODO: Can we move this API loader down further in the tree so only the table of slots shows as loading? */}
        <APILoader
          alreadyLoaded={this.scheduleStore.isLoaded}
          apiStore={this.scheduleStore}
          fetchProps={store!.isXponential && this.props.xpassLocationId}
          key={String(date)}
          elementSize={this.props.xpassLocationId ? "element" : "page"}
          render={() => {
            const instructorHeading = store!.isXponential ? store!.copy.serviceInstructor : store!.copy.instructor

            return (
              <div>
                {this.hasFilters && (
                  <div className="row form-inline mb-3 slide-in delay-3 align-items-center">
                    {allServiceTypes.length > 1 && (
                      <div className="col-sm-12 mb-3">
                        Session Type:{" "}
                        <select
                          className={cx(
                            "schedule-filter",
                            "form-control",
                            "custom-select",
                            "mt-2",
                            "mt-lg-0"
                          )}
                          value={serviceType.id}
                          onChange={onServiceTypeSelect}
                        >
                          {allServiceTypes.map(stOption => (
                            <option key={stOption.id} value={stOption.id}>
                              {stOption.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {this.scheduleStore.durations.length > 1 && (
                      <div className="col-md-5 col-lg-4 mr-auto">
                        <CustomPills
                          options={this.scheduleStore.durations.map(
                            duration => [
                              duration.id,
                              `${duration.durationMinutes} min`,
                            ]
                          )}
                          selected={this.scheduleStore.durationId!}
                          onChange={this.handleDurationSelect}
                          className="custom-pills-sm mb-0"
                        />
                      </div>
                    )}

                    {this.scheduleStore.instructors.length > 1 && (
                      <div className="col-auto">
                        <FilterSelect
                          items={this.scheduleStore.instructors}
                          placeholder={`Any ${store!.copy.serviceInstructor}`}
                          value={this.scheduleStore.instructorId}
                          optionText={instructor => instructor.name}
                          onChange={this.handleInstructorSelect}
                        />
                      </div>
                    )}
                  </div>
                )}

                <ScheduleWeekNav week={week} className="mt-5 mb-4 mb-lg-5" />
                <ScheduleDayNav
                  week={week}
                  pathname={pathname}
                  search={search}
                  styleClasses={store!.styleClasses}
                />

                <div
                  className={cx(
                    "service-schedule-table-wrapper",
                    "mb-5",
                    store!.isXponential && "pb-5 pb-md-3"
                  )}
                >
                  <ResponsiveTable
                    className="responsive-table-compact pt-2 table-striped service-schedule-table"
                    rows={this.scheduleStore.filteredEntries.map(entry => ({
                      entry,
                    }))}
                    rowKey={({ entry }) => entry.key}
                    emptyPlaceholder={placeholder}
                    columns={[
                      {
                        heading: "When",
                        cell: ({ entry }) => (
                          <>
                            {/* {entry.startsAt.format("dddd, MMMM D")} */}
                            <span className="time">{entry.startTime}</span>{" "}
                            {entry.startsAt.format(store!.styleClasses.ServiceTypeSchedule__whenDateFormat)}
                            {/* &ndash; */}
                            {/* {entry.endTime} */}
                          </>
                        ),
                      },
                      {
                        heading: "Duration",
                        cell: ({ entry }) => (
                          <div>
                            {this.serviceTypeName(entry)}
                            {" - "}
                            {entry.serviceDuration.durationMinutes} Minutes{" "}
                          </div>
                        ),
                      },
                      {
                        heading: instructorHeading,
                        cell: ({ entry }) => (
                          <strong className="mb-0">
                            {entry.instructor!.name}
                          </strong>
                        ),
                      },

                      ...(store!.isXponential ? [{
                          heading: "",
                          cell: ({ entry }: { entry: ServiceScheduleEntry }) => (
                            <div>
                              <img src={tokenIcon} alt="token" />
                              {entry.userPremiumCost && entry.userPremiumCost.raw > 0 ? `+ $${entry.userPremiumCost.raw}` : "" }
                            </div>
                          ),
                        }] : []),

                      {
                        key: "book",
                        type: "button",
                        cell: ({ entry }) => (
                          <button
                            className="btn btn-primary btn-sm text-nowrap px-0"
                            onClick={this.handleBookClick(entry)}
                            disabled={!this.isEntryBookable(entry)}
                            type="button"
                          >
                            {this.entryButtonText(entry)}
                          </button>
                        ),
                      },
                    ]}
                  />
                </div>
                {/* <GridView entries={this.scheduleStore.filteredEntries} /> */}
              </div>
            )
          }}
        />
      </div>
    )
  }
}

interface FilterSelectProps<T extends { id?: string }> {
  value?: string
  items: T[]
  placeholder: string
  optionText(item: T): string
  onChange(e: SelectEvent): void
}

@observer
class FilterSelect<T extends { id?: string }> extends React.Component<
  FilterSelectProps<T>
> {
  render() {
    const { value, items, onChange, optionText, placeholder } = this.props

    return (
      <select
        className={cx(
          "schedule-filter",
          "form-control",
          "custom-select",
          "mt-2"
          // "mt-lg-0",
          // "ml-sm-4"
        )}
        value={value}
        onChange={onChange}
      >
        <option value="">{placeholder}</option>

        {items.map(item => (
          <option key={item.id} value={item.id}>
            {optionText(item)}
          </option>
        ))}
      </select>
    )
  }
}

/////////////////////////////////////////////////////////////////
// UNUSED - FOR DEBUGGING ONLY
/////////////////////////////////////////////////////////////////

const hours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
const hourHeight = 80

const entryOffset = (entry: ServiceScheduleEntry) => {
  const { hours, minutes } = entry.startsAt.toObject()
  return (hours - 6) * 80 + (minutes / 60) * 80
}

const entryHeight = (entry: ServiceScheduleEntry) => {
  const dur = entry.endsAt.diff(entry.startsAt)
  return (dur / (1000 * 3600)) * 80
}

const GridView = ({ entries }: { entries: ServiceScheduleEntry[] }) => {
  return (
    <div style={{ height: 2000, width: "100%", display: "flex" }}>
      <div>
        {hours.map(hr => (
          <div
            key={hr}
            style={{ height: hourHeight, width: 80, border: "1px #000 solid" }}
          >
            {hr}
          </div>
        ))}
      </div>
      <div style={{ position: "relative" }}>
        {entries.map(entry => (
          <div
            key={entry.key}
            style={{
              position: "absolute",
              top: entryOffset(entry),
              height: entryHeight(entry),
              width: 200,
              background: "#ccc",
            }}
          >
            {entry.instructor!.name}
          </div>
        ))}
      </div>
    </div>
  )
}
