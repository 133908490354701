import { observable, action } from "mobx"
import { AxiosResponse, AxiosError } from "axios"
import APIStore from "stores/APIStore"
import {Location} from "models/LocationBrands"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"
import UserStore from "stores/UserStore"

export interface LocationBrandsResponse {
    locations?: Location[]
}

export default class LocationBrandsStore extends APIStore {
  @observable locationsCount?: number
  @observable brandsCount?: number
  @observable locations?: Location[] = []
  @observable brands?: string[]

  api = this.createClient<LocationBrandsResponse>([
    ResponseMiddleware(this.handleSuccess),
    MemoizeMiddleware,
    DeserializeMiddleware("locations", Location),
    TokenAuthMiddleware(this.userStore),
  ])

  constructor(protected userStore: UserStore) {
    super()
  }

  fetch() {
    return this.api.get(`/api/xpass/dashboard/location_brands`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    // Remove xponential brand from list if it exists
    const brands = res.data.brands.filter((brand: string) => brand !== "xponential")
    const brandsCount = brands.length
    const locations = res.data.locations.filter((loc: Location) => loc.brandId !== "xponential")
    const locationsCount = locations.length

    this.brands = brands
    this.brandsCount = brandsCount
    this.locations = locations
    this.locationsCount = locationsCount
    return res
  }
}