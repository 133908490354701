import * as React from "react"
import cx from "classnames"
import { observer } from "mobx-react"
import PaymentSource from "models/PaymentSource"
import bankIcon from "images/icons/bank.svg"
import ccIcons from "helpers/ccIcons"
import checkmark from "images/icons/checkmark.svg"
import paymentCardBackground from "images/payment_cards/payment-card-bg.svg"
import { toJS } from "mobx"
import BrandStore from "stores/BrandStore"

interface PaymentPreviewPanelProps {
  paymentSource: any
  isPaymentPage: boolean
  handleForm: (e: React.MouseEvent, type: string, paymentSource: any) => void
  onClick: () => void
}

interface Props {
  paymentSources?: PaymentSource[]
  isPaymentPage?: boolean
  store?: BrandStore
}

const PaymentPreviewPanel: React.SFC<PaymentPreviewPanelProps & Props> = ({
  paymentSources,
  handleForm,
  onClick,
  isPaymentPage,
  store
}) => {
  const paymentType = store!.settings.disallowACHPayment ? ["card"] : ["card", "ach"];
  const handleClick = (
    e: React.MouseEvent,
    type: string,
    paymentSource: any
  ) => {
    e.preventDefault()
    onClick()
    handleForm(e, type, paymentSource)
  }

  const renderPaymentSources = (sources: any) => {
    let paymentSourceLength = 0
    if (sources) {
      const payment = toJS(sources)
      paymentSourceLength = Object.keys(payment).length
    }

    function defaultCard() {
      return (
        <>
          {paymentType.map((type, i) => {
            const typeName =
              type === "card" ? "Credit/Debit Card" : "Bank Account"
            return (
              <div className={cx({ "placeholder-payment--margin": !isPaymentPage }, "placeholder-payment mb-5")} key={i}>
                <h3>{typeName}</h3>
                <div className={cx({ "placeholder-payment-card--width": !isPaymentPage }, "placeholder-payment-card")}>
                  <div className="payment-card-body d-flex flex-column p-3">
                    <div className="d-flex justify-content-between mb-5">
                      <a
                        className="ml-auto"
                        href="add-payment"
                        onClick={e => handleClick(e, type, null)}
                      >
                        Add {typeName}
                      </a>
                      <span className="placeholder-payment-plus" />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )
    }

    const bankAccountType = (bankAccountType: string) => {
      switch (bankAccountType) {
        case "pc":
          return "Personal Checking Account"
        case "ps":
          return "Personal Savings Account"
        case "bc":
          return "Business Checking Account"
        default:
          return "Personal Checking Account"
      }
    }

    const paymentTypeName = (paymentType: string, accountType: string) => {
      return paymentType === "card"
        ? "Credit/Debit Card"
        : bankAccountType(accountType)
    }

    switch (paymentSourceLength) {
      case 0:
        return defaultCard()
      case 1:
        const absentType = sources[0].type === "card" ? "ach" : "card"
        const absentTypeName =
          absentType === "card" ? "Credit/Debit Card" : "Bank Account"

        return (
          <>
            <div className="mb-5">
              <h3>
                {!store!.isXponential && paymentTypeName(sources[0].type, sources[0].bankAccountType)}
              </h3>
              <PaymentCard
                paymentSource={sources[0]}
                isPaymentPage={isPaymentPage}
                onClick={onClick}
                handleForm={e => handleClick(e, sources[0].type, sources[0])}
              />
            </div>
            {!isPaymentPage && !store!.isXponential && !store!.settings.disallowACHPayment && (
              <div>
                <h3>{absentTypeName}</h3>
                <div className="placeholder-payment-card placeholder-payment-card--width mb-5">
                  <div className="payment-card-body d-flex flex-column p-3">
                    <div className="d-flex justify-content-between mb-5">
                      <a
                        className="ml-auto"
                        href="add-payment"
                        onClick={e => handleClick(e, absentType, null)}
                      >
                        Add {absentTypeName}
                      </a>
                      <span className="placeholder-payment-plus" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      case 2:
        const defaultIndex = sources.findIndex(
          (item: any) => item.isDefault === true
        )
        return (
          <>
            {isPaymentPage ? (
              <>
                <div className="mb-5">
                  <h3>
                    {paymentTypeName(
                      sources[defaultIndex].type,
                      sources[defaultIndex].bankAccountType
                    )}
                  </h3>
                  <PaymentCard
                    paymentSource={sources[defaultIndex]}
                    isPaymentPage={isPaymentPage}
                    onClick={onClick}
                    handleForm={e =>
                      handleClick(
                        e,
                        sources[defaultIndex].type,
                        sources[defaultIndex]
                      )
                    }
                  />
                </div>
              </>
            ) : (
              <>
                {sources.map((item: any, i: number) => {
                  return (
                    <div className="mb-5" key={i}>
                      <h3>
                        {paymentTypeName(item.type, item.bankAccountType)}
                      </h3>
                      <PaymentCard
                        paymentSource={item}
                        isPaymentPage={isPaymentPage}
                        onClick={onClick}
                        handleForm={e => handleClick(e, item.type, item)}
                      />
                    </div>
                  )
                })}
              </>
            )}
          </>
        )
      default:
        return defaultCard()
    }
  }

  return (
    <div className="text-capitalize">
      <div className={cx({ "d-md-flex": !isPaymentPage })}>
        {renderPaymentSources(paymentSources)}
      </div>
    </div>
  )
}

const PaymentCard: React.FunctionComponent<Required<PaymentPreviewPanelProps>> = ({
  paymentSource,
  isPaymentPage,
  handleForm,
}) => {
  const ccIcon = ccIcons.find(([slug, src]) => slug === paymentSource.ccType)
  const paymentSourceName = paymentSource.type === "card" ? "Card" : "Bank"
  const name = `${paymentSource.firstName} ${paymentSource.lastName}`
  let icon = ccIcon! && ccIcon![1]
  let lastDigits = `•••• •••• •••• ${paymentSource.ccLast4}`
  let ccExpiration = `${paymentSource.ccExpMonth}/${paymentSource.ccExpYear}`
  let accountText = ""

  if (paymentSource.type === "ach") {
    icon = bankIcon
    lastDigits = paymentSource.bankAccountNumberLast4
    ccExpiration = ""
    accountText = "Account Ending "
  }

  const isDefault = paymentSource.isDefault ? (
    <span className="payment-card-default-icon">
      <img src={checkmark} alt="Default payment icon" />
    </span>
  ) : (
    ""
  )

  return (
    <div
      className={cx({ "payment-card--width": !isPaymentPage }, "payment-card card bg-white mr-md-4")}
      style={{
        background: `url(${paymentCardBackground}) no-repeat center / auto 100%`,
      }}
    >
      <div className="payment-card-body d-flex flex-column p-3">
        <div className="d-flex justify-content-between mb-5">
          {icon && <img src={icon} width="48" height="30" alt={icon} />}
          <a
            className="ml-auto"
            href="update-payment"
            onClick={e => handleForm(e, paymentSource.type, paymentSource)}
          >
            Update {paymentSourceName}
          </a>
          {isDefault}
        </div>

        <div className="row mt-auto">
          <div className="col-12">
            {accountText}
            <span style={{ letterSpacing: 4 }}>{lastDigits}</span>
          </div>
        </div>
        <div className="row mt-3">
          <span className="col-8">{name}</span>
          <span className="col-4 text-right">{ccExpiration}</span>
        </div>
      </div>
    </div>
  )
}

export default observer(PaymentPreviewPanel)
