import * as React from "react"
import cx from "classnames"
import { observer, inject } from "mobx-react"
import BaseStore from "stores/BaseStore"
import CurrentLocationStore from "stores/CurrentLocationStore"
import BrandStore from "stores/BrandStore"
import { FormikProps, connect } from "formik"
import Dropdown from "react-bootstrap/Dropdown"
import eatBsPrefix from "helpers/eatBsPrefix"
import Svg from "components/Svg"
import { LIST_LENGTH } from "stores/LocationsStore"

export interface Props {
  disabled?: boolean
  store?: BrandStore
  className?: string
}
type InnerProps = Props & { formik: FormikProps<{ locationId: string }> }

// This is the location picker used in the auth forms
@inject((store: BrandStore) => ({ store }))
@observer
class LocationSelectorPanelField extends React.Component<InnerProps, {}> {
  // manually updates the parent form if the global currentLocation changes
  fieldStore = new LocationFieldStore(
    this.props.store!.locStore,
    this.props.formik
  )

  componentWillUnmount() {
    this.fieldStore.dispose()
  }

  handleLocationSelect = (locationId: string) => () => {
    this.props.store!.locationPickerStore.handleSelect(locationId)
    this.props.store!.track.event("select studio_tap studio", {
      loc: this.props.store!.locStore.locations.find(
        loc => loc.id === locationId
      ),
    })
  }

  locationName(locationName: string) {
    const name = locationName.includes('™') ? locationName.replace('™', '') : locationName
    return name
  }

  public render() {
    if (this.props.store!.isXponential) return null

    const { locStore, brand, locationPickerStore } = this.props.store!
    const className = this.props.className
    const loc = locStore.currentLocation!
    // console.log(locationPickerStore.menuLocations)
    return (
      <>
        {/* daniel doesn't like this label, but can restore if desired */}
        {/* <label>{this.props.disabled ? "" : "Select your home studio"}</label> */}
        <div
          className={`location-selector-panel card bg-white p-2 px-4 mb-3 w-100 ${className || ""}`}
          style={{ overflow: "visible", zIndex: 1 }}
        >
          <div className="d-flex justify-content-between row">
            {loc && (
              <div className="col-sm">
                <div className={this.props.disabled ? "h3" : "h4"}>
                  {brand.name} {this.locationName(loc.name)}
                </div>
                <div className="location-selector-panel__address">
                  {loc.address} {loc.address2 ? loc.address2 : ""}
                  <br />
                  {loc.city} {loc.state} {loc.zip}
                </div>
              </div>
            )}
            <Dropdown
              className={cx("small", { "d-none": this.props.disabled })}
            >
              <Dropdown.Toggle
                id="location-selector-panel-field"
                as={eatBsPrefix("div")}
                className="col text-link-color"
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                More Studios
              </Dropdown.Toggle>

              <Dropdown.Menu className="small" alignRight>
                {locationPickerStore.menuLocations
                  .slice(0, LIST_LENGTH)
                  .map(({ id, name }) => (
                    <Dropdown.Item
                      key={id}
                      onSelect={this.handleLocationSelect(id)}
                    >
                      {name}
                    </Dropdown.Item>
                  ))}

                <Dropdown.Item onSelect={this.handleLocationSelect("all")}>
                  <div className="d-flex justify-content-between align-items-center">
                    View All
                    <Svg className="ml-1" name="caret-right" size="12" />
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </>
    )
  }
}

class LocationFieldStore extends BaseStore {
  constructor(
    locStore: CurrentLocationStore,
    formik: FormikProps<{ locationId: string }>
  ) {
    super()
    this.autorun(() => {
      if (locStore.currentLocation) {
        formik.setFieldValue("locationId", locStore.currentLocation!.id)
      }
    })
  }
}

export default connect<Props, { locationId: string }>(
  LocationSelectorPanelField
)
