import {
  BrandData,
  BrandStyleClasses,
  baseSettings,
  baseStyles,
  baseStyleClasses,
  baseCopy,
  baseDashboardCards,
  eywAffiliateLinks,
} from "themes/base"

import logoUrl from "images/logos/stretchlab-logo.svg"
import bannerImage from "images/dashboard/stretchlab-banner-bg.jpg"
import appPromoImage_Video from "images/video/stretchlab-app-promo.png"
import videoLandingLogo from "images/video/stretchlab-go-logo.png"
import appleWatchIcon from "images/apple_health/watch/stretchlab.svg"

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
  AttendanceCard__dateFormat: "DD/MM/YYYY",
  BillingHistory__dateFormat: "DD/MM/YY",
  BookClassSubNav__dateFormat: "d/m/YY",
  ChallengeCard__dateFormat: "DD/MM/YYYY",
  FiltersModal__dateFormat: "d/m/Y",
  FormDatePickerEnhanced__dateFormat: "d/m/Y",
  MySchedulePage__dateFormat: "dddd, DD/MM",
  NextClassCard__dateFormat: "DD/MM/YY",
  PackageRenewalText_dateFormat: "D/M/YYYY",
  PurchaseCard__dateFormat: "DD/MM/YYYY",
  PurchasesTable__dateFormat: "DD/MM/YYYY",
  ScheduleEntryFilters__dateFormat: "d/m/Y",
  ScheduleWeekNav__dateFormat: "D/M/YY",
  ScheduleDayNav__dateFormat: "DD/MM",
  ServiceMembershipsCard__dateFormat: "D/M/YY",
  ServiceTypeSchedule__sessionDateFormat: "D/M/YYYY",
  ServiceTypeSchedule__whenDateFormat: "D/M",
}

const brand: BrandData = {
  settings: {
    ...baseSettings,
    serviceBooking: true,
    packagesFirstOnBooking: true,
    disallowACHPayment: true,
    billingCountryCode: "AU"
  },
  styles: {
    ...baseStyles,
    bannerImage,
    logoUrl,
    appPromoImage_Video,
    videoLandingLogo,
    appleWatchIcon,
  },
  styleClasses,
  copy: {
    ...baseCopy,
    class: "session",
    classPackages: "Multiple Options Available",
    classes: "sessions",
    mainMenuClasses: "sessions",
    credit: "session",
    credits: "sessions",
    findAClass: "Book Now",
    groupClasses: "Group Stretches",
    oneOnOneSessions: "One-On-One Stretches",
    seat: "Session",
    seats: "Spots",
    instructor: "Flexologist",
    instructors: "Flexologists",
    packages: "Single Sessions",
    bookingErrorTitle: "Session Credit Needed",
    buyRedirectText: "Get Session Credit",
    noUpcomingClassesBooked: "It looks like you don't have any upcoming {CLASSES} booked. Let’s get your next stretch booked! Click below to view schedule",
    distanceUnit: "km",
    phonePlaceholder: "Enter number here",
    weightUnit: "kgs",
    zipLabel: "Postal Code",
  },
  dashboardCards: [
    "NextClassCard",
    "ProfileCard",
    "AchievementsCard",
    "MobileAppCard",
    "MobileOfferCard",
    "ClassPointsCard",
    "PromoCards",
  ],
  badges: [],
  metrics: [],
  summaryMetrics: [],
  eywAffiliateLinks: eywAffiliateLinks["stretchlab"],
  eywLegalLink: "https://www.stretchlab.com.au/terms-earn-your-watch",
  eywParticipatingLink: "https://www.stretchlab.com.au/earn-your-watch-locations"
}

export default brand
