import { JsonObject, JsonProperty } from "json2typescript"

interface Playlists {
  Classes?: string
  Collections?: string
  Programs?: string
  Live?: string
}

@JsonObject("Brand")
export default class Brand {
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * Display name of the brand
   */
  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("amplitude_api_key", String, true)
  amplitudeApiKey?: string = undefined

  /**
   * URL of the brand's marketing website.
   */
  @JsonProperty("site_url", String)
  siteUrl: string = undefined!

  /**
   * App Store link for this brand's iOS app.
   */
  @JsonProperty("ios_app_url", String, true)
  iosAppUrl?: string = undefined

  /**
   * Google Play Store link for this brand's Android app.
   */
  @JsonProperty("android_app_url", String, true)
  androidAppUrl?: string = undefined

  @JsonProperty("external_location_map_url", String, true)
  externalLocationMapUrl?: string = undefined

  @JsonProperty("show_vod_menu", Boolean, true)
  showVodMenu?: boolean = false

  @JsonProperty("allow_buy_again", Boolean, true)
  allowBuyAgain?: boolean = true

  @JsonProperty("allow_vod_only_subscriptions", Boolean, false)
  allowVodOnly?: boolean = false

  @JsonProperty("condensed_booking_flow", Boolean, false)
  condensedBookingFlow?: boolean = false
}
