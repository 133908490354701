import * as React from "react"
import { Link } from "react-router-dom"
import { observer, inject } from "mobx-react"
import * as moment from "moment"
import cx from "classnames"
import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import BookingsStore from "apps/bookings/stores/BookingsStore"
import DashboardApiStore from "apps/dashboard/stores/DashboardApiStore"
import LocationBrandsStore from "apps/dashboard/stores/LocationBrandsStore"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import Icon from "images/xpass/icons/brands/xpass.png"
import PurchasesStore from "apps/account/stores/PurchasesStore"
import { brandsTotal } from "constants/index"
import { BrandCopy } from "themes/base"

interface Props {
  store?: BrandStore
  className?: string
  locationBrandsStore: LocationBrandsStore
  dashboardStore: DashboardApiStore
  bookingsStore: BookingsStore
  balancesStore: BalancesStore
  copy?: BrandCopy
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class MyStatsCard extends React.Component<Props> {
  purchasesStore = new PurchasesStore(this.props.store!)

  componentDidMount() {
    this.purchasesStore.fetch()
  }

  componentWillUnmount() {
    this.purchasesStore.dispose()
  }

  render() {
    const { className, locationBrandsStore, store, dashboardStore, bookingsStore, balancesStore } = this.props
    const { copy } = store!
    const activePurchase = this.purchasesStore.purchases.filter(
      purchase =>
        purchase.isRecurring &&
        !purchase.scheduledCancelDateUtc &&
        !purchase.package.isXpassFree
    )

    return (
      <div className={cx("card my-stats-card", className)}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="card-title">My Stats</h4>
          <small>
            <Link to={`/history/attendance/${store!.userStore.session!.locationId}`}>
              View Stats
          </Link>
          </small>
        </div>
        <APILoader
          apiStore={this.props.locationBrandsStore}
          elementSize="element"
          render={() => {
            const myStats = [
              {
                title: "Classes",
                count: bookingsStore.classCompletedCount
              },
              {
                title: "Studios",
                count: locationBrandsStore.locationsCount
              },
              {
                title: "XPASS Brands",
                count: `${locationBrandsStore.brandsCount}/${brandsTotal}`
              },
            ]
            const tokensCount = balancesStore.balances && balancesStore.balances.classTokenCount

            return (
              <>
                <span className="border-top border-light" />
                <div className="my-stats-card__stats d-flex justify-content-between card-header">
                  {myStats.map(stat => (
                    <div key={stat.title}>
                      <p className="my-stats-card__count mb-0">{stat.count}</p>
                      <p className="mb-0">{stat.title}</p>
                    </div>
                  ))}
                </div>
                <span className="border-top border-light" />
                <div className="card-header">
                  <h4 className="card-title">{copy.dashCardMembership}</h4>
                </div>
                {dashboardStore!.user &&
                  <div className="my-stats-card__membership d-flex">
                    <img src={Icon} />
                    <div className="d-flex flex-column justify-content-center">
                      <p className="mb-0">XPASS Member since</p>
                      <p className="mb-0">
                        {moment(dashboardStore!.user!.createdAt).local().format("MMMM DD, YYYY")}
                      </p>
                      <p className="mb-0">
                          <>
                            {tokensCount && tokensCount > 0 ? `${tokensCount} available ${tokensCount > 1 ? "tokens" : "token"}` : ""}
                          </>
                      </p>
                    </div>
                  </div>
                }
              </>
            )
          }}
        />
      </div>
    )
  }

}
