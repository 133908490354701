import { JsonObject, JsonProperty } from "json2typescript"
import ClassStatsBase from "apps/history/models/ClassStatBase"

@JsonObject("WorkoutClass")
export default class WorkoutClass extends ClassStatsBase {
  type: "workout" = "workout"

  @JsonProperty("avg_rpm", Number, true)
  avgRpm: number = undefined!

  @JsonProperty("high_rpm", Number, true)
  highRpm: number = undefined!

  @JsonProperty("avg_power", Number, true)
  avgPower: number = undefined!

  @JsonProperty("high_power", Number, true)
  highPower: number = undefined!

  @JsonProperty("avg_pace", Number, true)
  avgPace: number = undefined!

  @JsonProperty("high_pace", Number, true)
  highPace: number = undefined!

  @JsonProperty("total_energy", Number, true)
  totalEnergy: number = undefined!

  @JsonProperty("distance", Number, true)
  distance: number = undefined!

  @JsonProperty("calories", Number)
  calories: number = undefined!

  @JsonProperty("avg_hr", Number, true)
  avgHr: number = undefined!

  @JsonProperty("active_min", Number, true)
  activeMin: number = undefined!

  @JsonProperty("source", String)
  source: string = undefined!

  @JsonProperty("rank", Number, true)
  rank: number = undefined!

  @JsonProperty("avg_hr_percent", Number, true)
  avgHrPercent: number = undefined!

  @JsonProperty("high_hr", Number, true)
  highHr: number = undefined!

  @JsonProperty("high_hr_percent", Number, true)
  highHrPercent: number = undefined!

  @JsonProperty("max_hr", Number, true)
  maxHr: number = undefined!

  @JsonProperty("iq_points", Number, true)
  iqPoints: number = undefined!

  @JsonProperty("zones", [Number], true)
  zones: [number, number, number, number, number] = undefined!

  @JsonProperty("zone_times", [Number], true)
  zoneTimes: [number, number, number, number, number] = undefined!
}
