// import User from "models/User"
import { action, observable } from "mobx"
import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import UserStore from "stores/UserStore"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"


export default class VerifyAccessCodeStore extends APIStore {
  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    TokenAuthMiddleware(this.userStore)
  ])

  @observable accessCodeSuccess?: boolean

  constructor(protected userStore: UserStore) {
    super()
  }

  // verifying access code for XPASS eligibility  
  verify(code: string) {
    const url = `/api/xpass/access_codes`
    return this.api.put(url, {access_code: code})
  }

  @action.bound
  handleSuccess(res: AxiosResponse<{ success: boolean }>) {
    this.accessCodeSuccess = res.data.success
    return res
  }
}
