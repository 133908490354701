import {
  baseSettings,
  BrandData,
  BrandCopy,
  BrandSettings,
  BrandStyles,
  BrandStyleClasses,
  baseCopy,
  BadgeBitmap,
  baseStyles,
  BrandDashboardCards,
  eywAffiliateLinks,
  baseStyleClasses,
} from "themes/base"

import logoUrl from "images/logos/rumble-logo.svg"
import badgeImage from "images/badges/rumble-badge.svg"
import bannerImage from "images/dashboard/rumble-banner-bg.png"
import appPromoImage_Video from "images/video/cyclebar-app-promo.png"
import videoLandingLogo from "images/video/cyclebar-go-logo.png"
import appleWatchIcon from "images/apple_health/watch/rumble.svg"

import badge10Active from "images/badges/rumble/milestone-10-Active-Rumble.png"
import badge25Active from "images/badges/rumble/milestone-25-Active-Rumble.png"
import badge50Active from "images/badges/rumble/milestone-50-Active-Rumble.png"
import badge100Active from "images/badges/rumble/milestone-100-Active-Rumble.png"
import badge250Active from "images/badges/rumble/milestone-250-Active-Rumble.png"
import badge500Active from "images/badges/rumble/milestone-500-Active-Rumble.png"
import badge750Active from "images/badges/rumble/milestone-750-Active-Rumble.png"
import badge1000Active from "images/badges/rumble/milestone-1000-Active-Rumble.png"
import badge1250Active from "images/badges/rumble/milestone-1250-Active-Rumble.png"
import badge1500Active from "images/badges/rumble/milestone-1500-Active-Rumble.png"

import badge10Inactive from "images/badges/rumble/milestone-10-Inactive-Rumble.png"
import badge25Inactive from "images/badges/rumble/milestone-25-Inactive-Rumble.png"
import badge50Inactive from "images/badges/rumble/milestone-50-Inactive-Rumble.png"
import badge100Inactive from "images/badges/rumble/milestone-100-Inactive-Rumble.png"
import badge250Inactive from "images/badges/rumble/milestone-250-Inactive-Rumble.png"
import badge500Inactive from "images/badges/rumble/milestone-500-Inactive-Rumble.png"
import badge750Inactive from "images/badges/rumble/milestone-750-Inactive-Rumble.png"
import badge1000Inactive from "images/badges/rumble/milestone-1000-Inactive-Rumble.png"
import badge1250Inactive from "images/badges/rumble/milestone-1250-Inactive-Rumble.png"
import badge1500Inactive from "images/badges/rumble/milestone-1500-Inactive-Rumble.png"

const settings: BrandSettings = {
  ...baseSettings,
  isPerformance: true,
  hasLeaderboard: false,
  hasWeight: false,
  hasShoeSize: false,
  showPromoOnEmptyPerf: false,
  allowBookingOnWaitlistClosed: true,
  showBuyRedirectButton: true,
  disallowACHPayment: true,
  billingCountryCode: "AU"
}

const styles: BrandStyles = {
  ...baseStyles,
  badgeImage,
  bannerImage,
  chartColor: "#d0021b",
  logoUrl,
  appPromoImage_Video,
  videoLandingLogo,
  appleWatchIcon,
}

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
  AppleHealthCard__listItem: "",
  AppleHealthCard__title: "pt-3 pb-2",
  AttendanceCard__dateFormat: "DD/MM/YYYY",
  AttendanceCard__locationName: "",
  AttendanceCard__progressHeader: "font-weight-normal",
  BillingHistory__dateFormat: "DD/MM/YY",
  BookClassSubNav__dateFormat: "d/m/YY",
  BookingInfoCard__entryTitle: "pt-4 px-4",
  BookingInfoCard__entrySubtitle: "px-4",
  BookingInfoCard__entryWaitlist: "px-4",
  BookingInfoCard__entryDescription: "px-4",
  BookingInfoCard__entryLocationName: "location-name mb-2 text-uppercase",
  BookingInfoCard__subtitle: "confirm-subtitle text-large mb-4",
  BookingSuccessPage__confirmedLinks: "px-4",
  BookingSuccessPage__viewScheduleLink: "pb-4 px-4",
  ChallengeCard__dateFormat: "DD/MM/YYYY",
  ConfirmBookingPage__confirmButton: "pb-4 px-4",
  FiltersModal__dateFormat: "d/m/Y",
  FormDatePickerEnhanced__dateFormat: "d/m/Y",
  HealthCheck__cardButton: "d-flex flex-column",
  MainMenu__container: "bg-white d-lg-flex",
  MySchedulePage__dateFormat: "dddd, DD/MM",
  MySchedulePage__entryStartDate: "",
  MySchedulePage__entrySeatLabel: "",
  NextClassCard__dateFormat: "DD/MM/YY",
  PackageCard__cardText: "d-block package-text pb-3",
  PackageCard__cardTextPackageName: "d-block",
  PackageRenewalText_dateFormat: "D/M/YYYY",
  PackagesList__memberships: "text-center",
  PaymentPage__purchaseCard: "h-100",
  PickSeatPage__instructorName: "pt-3",
  PickSeatPage__locationName: "mb-2",
  PickSeatPage__seat: "seat-rumble",
  PickSeatPage__seatTaken: "seat-unavailable-rumble",
  PickSeatPage__seatSelected: "seat-selected-rumble",
  PickSeatPage__spotSelection: "spot-selection pt-4 pb-5",
  PurchaseCard__dateFormat: "DD/MM/YYY",
  PurchasesTable__dateFormat: "DD/MM/YYYY",
  PurchaseForm__button: "w-100",
  ScheduleEntryFilters__dateFormat: "d/m/Y",
  ScheduleWeekNav__dateFormat: "MMM D",
  ScheduleDayNav__dateFormat: "DD/MM",
  ScheduleWeekNav__link: "text-capitalize font-weight-normal",
  ServiceMembershipsCard__dateFormat: "D/M/YY",
  ServiceTypeSchedule__sessionDateFormat: "D/M/YYYY",
  ServiceTypeSchedule__whenDateFormat: "D/M",
  TodaysClassCard__favoriteHeartWrapper: "",
}

const copy: BrandCopy = {
  ...baseCopy,
  badgeTitle: "My Rumble Stats",
  bookingPageLabel: "Book a Class",
  buyCredits: "Buy Classes",
  instructor: "Trainer",
  instructors: "Trainers",
  location: "Location",
  locations: "Locations",
  membershipsClassText: "classes",
  membershipsButtonText: "Now",
  nextClassHeader: "",
  packageListHeader: "Membership Options",
  prevText: "Previous",
  seat: "Spot",
  seats: "Spots",
  noUpcomingClassesBooked:
    "Click below to book your next throwdown 👊 .",
  bookingCancellationTitle: "ARE YOU SURE YOU WANT TO CANCEL?",
  bookingLateCancellationBody: "😢 You may incur a late penalty if you are outside of the allowable cancellation window set by your home studio.",
  bookingCancellationConfirmButton: "Confirm Cancellation",
  bookingSelectHeader: "YOU'RE ALMOST THERE.",
  bookingSelectCopy: "Book this class now.",
  bookingSelectButton: "Select a spot.",
  distanceUnit: "km",
  phonePlaceholder: "Enter number here",
  weightUnit: "kgs",
  zipLabel: "Postal Code",
}

const dashboardCards: BrandDashboardCards = [
  "NextClassCard",
  "TodaysClassCard",
  "AchievementsCard",
  "BadgeCard",
  "AttendanceCard",
  "ClassPointsCard",
  "ProfileCard",
  "MobileAppCard",
  "MobileOfferCard",
  // "AppleHealthCard",
  "PromoCards",
]

const badges: BadgeBitmap[] = [
  {
    label: "10 Classes",
    value: 10,
    active: badge10Active,
    inactive: badge10Inactive,
  },
  {
    label: "25 Classes",
    value: 25,
    active: badge25Active,
    inactive: badge25Inactive,
  },
  {
    label: "50 Classes",
    value: 50,
    active: badge50Active,
    inactive: badge50Inactive,
  },
  {
    label: "100 Classes",
    value: 100,
    active: badge100Active,
    inactive: badge100Inactive,
  },
  {
    label: "250 Classes",
    value: 250,
    active: badge250Active,
    inactive: badge250Inactive,
  },
  {
    label: "500 Classes",
    value: 500,
    active: badge500Active,
    inactive: badge500Inactive,
  },
  {
    label: "750 Classes",
    value: 750,
    active: badge750Active,
    inactive: badge750Inactive,
  },
  {
    label: "1000 Classes",
    value: 1000,
    active: badge1000Active,
    inactive: badge1000Inactive,
  },
  {
    label: "1250 Classes",
    value: 1250,
    active: badge1250Active,
    inactive: badge1250Inactive,
  },
  {
    label: "1500 Classes",
    value: 1500,
    active: badge1500Active,
    inactive: badge1500Inactive,
  },
]

const rumble: BrandData = {
  settings,
  styles,
  styleClasses,
  copy,
  dashboardCards,
  badges,
  metrics: [],
  summaryMetrics: [],
  eywAffiliateLinks: eywAffiliateLinks["rumble"],
  eywLegalLink: "https://www.doyourumble.com.au/terms-earn-your-watch",
  eywParticipatingLink: "https://www.doyourumble.com.au/earn-your-watch-locations"
}

export default rumble
