import React, { Component } from 'react'
import { observer } from "mobx-react"
import { Link } from "react-router-dom"
// Stores
import BrandStore from 'stores/BrandStore'
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import ScheduleEntryLocationStore from "apps/book/stores/xpass/ScheduleEntryLocationStore"
import UserCoordinatesStore from "apps/book/stores/xpass/UserCoordinatesStore"
import ServiceLocationStore from "apps/book/stores/xpass/ServiceLocationStore"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import BookingsStore from 'apps/bookings/stores/BookingsStore'
import ServiceTypesStore from 'apps/book/stores/ServiceTypesStore'
import FavoritesStore from 'apps/account/stores/FavoritesStore'
import FiltersStore from 'apps/book/stores/xpass/FiltersStore'
// Components
import APILoader from 'components/wrappers/APILoader'
import BookClassSubNav from './BookClassSubNav'
import {AppointmentList, ClassList, StudioList} from './EntryList'
import LocationSummary from 'models/LocationSummary'
import StudioLoader from "images/xpass/studio-loader-2.svg"
import StudioFeaturedLoader from "images/xpass/studio-featured.svg"
import EntryMobileLoader from "images/xpass/entry-loader-mobile.svg"
import ClassFeaturedLoader from "images/xpass/class-featured.svg"
import ClassLoader from "images/xpass/class-loader-2.svg"
import { FiltersModal } from './FiltersModal'
import ScheduleEntriesMap from './ScheduleEntriesMap'
// Models
import ScheduleEntry from 'apps/book/models/xpass/ScheduleEntry'
import ScheduleEntryLocation from 'apps/book/models/xpass/ScheduleEntryLocation'
import ServiceLocation from 'apps/book/models/xpass/ServiceLocation'
import { SubmenuTab } from 'stores/UIStore'
// Utils
import { times } from 'lodash'
import moment from 'moment'
// icons
import MapIcon from 'images/xpass/icons/map.svg'
import { getLocal, setLocal } from 'utils/LocalStorage'


export interface ClassBookingTabsProps {
  store: BrandStore
  userCoordinatesStore: UserCoordinatesStore
  scheduleEntryStore: ScheduleEntryStore
  scheduleEntryStoreHot: ScheduleEntryStore
  scheduleEntryStoreNow: ScheduleEntryStore
  serviceLocationStore: ServiceLocationStore
  scheduleEntryLocationStore: ScheduleEntryLocationStore
  balancesStore: BalancesStore
  filtersStore: FiltersStore
  onBookingModal: Function
  filterEntries: Function
  resetFilters: Function
  handleActiveTab: Function
  showFiltersModal: boolean
  activeTab: SubmenuTab
  selectedCity: string
}

@observer
class ClassBookingTabs extends Component<ClassBookingTabsProps> {
  userCoordinates = this.props.userCoordinatesStore.userCoordinates
  bookingsStore = new BookingsStore(this.props.store)
  serviceTypesStore = new ServiceTypesStore(this.props.store)
  favoriteLocationsStore = new FavoritesStore(
    "locations",
    "locations",
    LocationSummary,
    this.props.store.userStore
  )

  componentDidMount() {
    this.props.scheduleEntryLocationStore.fetch()
    this.props.store.uiStore.setSearchSuggestions(this.props.scheduleEntryLocationStore.scheduleEntryLocations)
    this.bookingsStore.fetch()
    this.fetchHotClasses()
    this.fetchNowClasses()
  }

  componentDidUpdate(prevProps: Readonly<ClassBookingTabsProps>) {
    if (!this.props.store.uiStore.nav.searchSuggestions.length && !this.props.scheduleEntryLocationStore.isLoadingLocations) {
      this.props.store.uiStore.setSearchSuggestions(this.props.scheduleEntryLocationStore.scheduleEntryLocations)
    }
    if (this.props.selectedCity && prevProps.selectedCity !== this.props.selectedCity) {
      this.onCitySelect(
        this.props.scheduleEntryLocationStore,
        [this.props.scheduleEntryStore, this.props.scheduleEntryStoreHot, this.props.scheduleEntryStoreNow],
        this.props.selectedCity
        )
    }
    if (this.props.activeTab !== prevProps.activeTab) {
      switch (this.props.activeTab) {
        case 'featured':
          this.fetchHotClasses()
          this.fetchNowClasses()
          break
        case 'value':
          this.fetchHotClasses()
          break
        case 'now':
          this.fetchNowClasses()
          break
        case 'stretch':
          this.props.filtersStore.resetFilters()
          this.props.resetFilters()
          this.props.serviceLocationStore.fetch()
          break
        default:
          break
      }
    }

  }

 // convert current time to range index for ScheduleEntryStore, where 1 range point = 15 minutes
  getCurrentTimeIndex() {
    const joinTime = moment().hours() + moment().minutes() / 60
    const timeIndex = Math.floor((joinTime - 5) * 4)
    return timeIndex > 60 ? 0 : timeIndex
  }
  
  fetchHotClasses() {
    this.props.scheduleEntryStoreHot.setExcludePremium(true)
    this.props.scheduleEntryStoreHot.set24HoursFilter(true)
    this.props.scheduleEntryStoreHot.fetchByFilters()
  }

  fetchNowClasses() {
    this.props.scheduleEntryStoreNow.setStartTimeRange([this.getCurrentTimeIndex(), this.getCurrentTimeIndex() + 8])
    this.props.scheduleEntryStoreNow.fetchByFilters()
  }

  fetchRandomClass() {
    this.props.scheduleEntryStore.setIsRandom(true)
    this.props.scheduleEntryStore.fetchByFilters()
  }

  onCitySelect(scheduleEntryLocationStore: ScheduleEntryLocationStore, scheduleEntryStores: ScheduleEntryStore[], city: string) {
    const filterByCity = scheduleEntryLocationStore.scheduleEntryLocations.filter(location => {
      return `${location.city!}, ${location.state!}` === city
    })
    const centerCoordinates = scheduleEntryLocationStore.findCenterCoordinates(
      filterByCity
    )
    this.props.userCoordinatesStore.setLocationCoordinates({
      lng: centerCoordinates.longitude,
      lat: centerCoordinates.latitude,
    } || this.props.userCoordinatesStore.defaultCoordinates)

    scheduleEntryLocationStore.setSelectedCity(city);
    scheduleEntryStores.forEach(scheduleEntryStore => {
      scheduleEntryStore.setIsPaginated(false)
      scheduleEntryStore.setPage(1)
      scheduleEntryStore.setFilteredLocations(filterByCity)
      scheduleEntryStore.setHighlightedStudio(undefined)
      scheduleEntryStore.setIsFiltered(true)
      scheduleEntryStore.fetchByFilters()
    })
    this.props.filterEntries([this.props.scheduleEntryStore])
  }

  componentWillUnmount() {
    this.bookingsStore.dispose()
    this.props.store.uiStore.setSelectedLocation('')
  }

  renderSection(section: SubmenuTab) {
      const { isLoadingLocations, optedInLocations } = this.props.scheduleEntryLocationStore
      const { scheduleEntries, isLoadingEntries } = this.props.scheduleEntryStore
      const {scheduleEntries: scheduleEntriesHot, isLoadingEntries: isLoadingEntriesHot } = this.props.scheduleEntryStoreHot
      const {scheduleEntries: scheduleEntriesNow, isLoadingEntries: isLoadingEntriesNow } = this.props.scheduleEntryStoreNow
      const { serviceLocations } = this.props.serviceLocationStore

      switch (section) {
        case 'featured':
          return (
            <>
              {
                !isLoadingEntriesNow && !isLoadingEntriesHot && scheduleEntriesHot && scheduleEntriesNow && !scheduleEntriesHot.length && !scheduleEntriesNow.length && (
                  <div className="text-center pt-4">
                  <h3>Expand your radius or search a different location to find a workout.</h3>
                  </div>
                )
              }
              <TabLoader
                isLoading={isLoadingEntriesHot}
                entries={scheduleEntriesHot}
                entryType="class"
                isCarousel
                handleActiveTab={this.props.handleActiveTab}
                tabComponent={
                  <ClassList
                    title="Popular Classes Near You"
                    store={this.props.store}
                    scheduleEntries={scheduleEntriesHot!}
                    scheduleEntryStore={this.props.scheduleEntryStoreHot}
                    balancesStore={this.props.balancesStore}
                    bookedBookings={this.bookingsStore.bookedBookings}
                    onBookingModal={this.props.onBookingModal}
                    isCarousel
                    handleActiveTab={this.props.handleActiveTab}
                    linkedTab="value"
                  />
                }
              />
              <TabLoader
                isLoading={isLoadingLocations}
                entries={this.favoriteLocationsStore.favorites}
                entryType="studio"
                isCarousel
                handleActiveTab={this.props.handleActiveTab}
                tabComponent={
                  <StudioList
                    title="Your Favorites"
                    brandStore={this.props.store}
                    scheduleEntryStore={this.props.scheduleEntryStore}
                    userCoordinates={this.userCoordinates}
                    favoriteLocationsStore={this.favoriteLocationsStore}
                    scheduleEntryLocationStore={this.props.scheduleEntryLocationStore}
                    isCarousel
                    linkedTab='favorites'
                    isFavoritesTab
                    handleActiveTab={this.props.handleActiveTab}
                  />
                }
              />
              <TabLoader
                isLoading={isLoadingLocations}
                entries={optedInLocations}
                entryType="studio"
                isCarousel
                handleActiveTab={this.props.handleActiveTab}
                tabComponent={
                  <StudioList
                    title="Studios Nearby"
                    brandStore={this.props.store}
                    scheduleEntryStore={this.props.scheduleEntryStore}
                    userCoordinates={this.userCoordinates}
                    favoriteLocationsStore={this.favoriteLocationsStore}
                    scheduleEntryLocationStore={this.props.scheduleEntryLocationStore}
                    isCarousel
                    handleActiveTab={this.props.handleActiveTab}
                    linkedTab="studios"
                  />
                }
              />
              <TabLoader
                isLoading={isLoadingEntriesNow}
                entries={scheduleEntriesNow}
                entryType="class"
                isCarousel
                handleActiveTab={this.props.handleActiveTab}
                tabComponent={
                  <ClassList
                    title="Classes Starting Soon"
                    store={this.props.store}
                    scheduleEntries={scheduleEntriesNow!}
                    scheduleEntryStore={this.props.scheduleEntryStoreNow}
                    balancesStore={this.props.balancesStore}
                    bookedBookings={this.bookingsStore.bookedBookings}
                    onBookingModal={this.props.onBookingModal}
                    isCarousel
                    handleActiveTab={this.props.handleActiveTab}
                    linkedTab="now"
                  />
                }
              />
            </>
          )
        case 'value':
          return (
            <TabLoader
              isLoading={isLoadingEntriesHot}
              entries={scheduleEntriesHot}
              entryType="class"
              handleActiveTab={this.props.handleActiveTab}
              tabComponent={
                <ClassList
                  store={this.props.store}
                  hasSort
                  scheduleEntryStore={this.props.scheduleEntryStoreHot}
                  balancesStore={this.props.balancesStore}
                  scheduleEntries={scheduleEntriesHot!}
                  bookedBookings={this.bookingsStore.bookedBookings}
                  onBookingModal={this.props.onBookingModal}
                />
              }
            />
          )
        case 'favorites':
          return (
            <StudioList
              brandStore={this.props.store}
              scheduleEntryStore={this.props.scheduleEntryStore}
              userCoordinates={this.userCoordinates}
              favoriteLocationsStore={this.favoriteLocationsStore}
              scheduleEntryLocationStore={this.props.scheduleEntryLocationStore}
              isFavoritesTab
              handleActiveTab={this.props.handleActiveTab}
            />
          )
        case 'stretch':
          return (
            <TabLoader
              isLoading={isLoadingEntries}
              entries={serviceLocations}
              entryType="class"
              handleActiveTab={this.props.handleActiveTab}
              tabComponent={
                <AppointmentList
                  store={this.props.store}
                  scheduleEntryStore={this.props.scheduleEntryStore}
                  scheduleEntryLocationStore={this.props.scheduleEntryLocationStore}
                  serviceLocations={serviceLocations}
                  serviceLocationStore={this.props.serviceLocationStore}
                  serviceTypesStore={this.serviceTypesStore}
                />
              }
            />
          )
        case 'studios':
          return (
            <TabLoader
              isLoading={isLoadingLocations}
              entries={optedInLocations}
              entryType="studio"
              handleActiveTab={this.props.handleActiveTab}
              tabComponent={
                <StudioList
                  brandStore={this.props.store}
                  scheduleEntryStore={this.props.scheduleEntryStore}
                  favoriteLocationsStore={this.favoriteLocationsStore}
                  userCoordinates={this.userCoordinates}
                  scheduleEntryLocationStore={this.props.scheduleEntryLocationStore}
                />
              }
            />
          )
        case 'now':
          return (
            <TabLoader
              isLoading={isLoadingEntriesNow}
              entries={scheduleEntriesNow}
              entryType="class"
              handleActiveTab={this.props.handleActiveTab}
              tabComponent={
                <ClassList
                store={this.props.store}
                scheduleEntryStore={this.props.scheduleEntryStoreNow}
                balancesStore={this.props.balancesStore}
                scheduleEntries={scheduleEntriesNow!}
                bookedBookings={this.bookingsStore.bookedBookings}
                onBookingModal={this.props.onBookingModal}
              />
              }
            />
          )
        default:
          return null
        }
      }

    render() {
      return this.renderSection(this.props.activeTab) || null
    }
}

interface TabLoaderProps {
  isLoading: boolean
  entries: ScheduleEntry[] | ScheduleEntryLocation[] | ServiceLocation[] | LocationSummary[] | undefined
  tabComponent: React.ReactNode
  entryType: "class" | "studio" | "appointment"
  isCarousel?: boolean
  handleActiveTab: Function
}

function TabLoader({
  isLoading,
  entries,
  tabComponent,
  entryType,
  isCarousel,
  handleActiveTab
}: TabLoaderProps) {
  return (
    <>
    {entries && !isLoading ? (
      entries.length > 0 ? (
        tabComponent
      ) : !isCarousel && (
        <div className="text-center pt-4">
          <h3>No results to show</h3>
          <p>{entryType === 'studio' ? 'No studios found for this selection.' : 'There are no seats at this time.'} Keep exploring. If there’s a will there’s a way.</p>
          <button className="xpass-cta xpass-cta-large" onClick={() => handleActiveTab('featured')}>Explore Workouts</button>
        </div>
      )
    ) : !isCarousel ? (
      <div className="list-item-loader d-flex flex-column mx-auto mt-5">
        {times(5, index => (
          <div key={index}>
          <img src={entryType === "studio" ? StudioLoader : ClassLoader} className="my-3 w-100 desktop-loader" />
          <img src={EntryMobileLoader} className="my-3 w-100 mobile-loader" />
          </div>
        ))}
      </div>
    ) : (
      <div className="featured-item-loader d-flex mx-auto mt-5">
        {times(5, index => (
          <img key={index} src={entryType === "studio" ? StudioFeaturedLoader : ClassFeaturedLoader} className="w-100" />
        ))}
      </div>
    )}
  </>
  )
}

export interface BookClassPageProps {
  onBookingModal: Function
  brandStore: BrandStore
  subBrand?: string
}
@observer
export default class BookClassPage extends Component<BookClassPageProps> {
  noNavGapSections: string[] = ["featured", "hot", "now", "favorites"]
  userCoordinatesStore = new UserCoordinatesStore()
  balancesStore = new BalancesStore(this.props.brandStore)
  scheduleEntryStore = new ScheduleEntryStore(this.userCoordinatesStore, this.props.brandStore.userStore)
  scheduleEntryStoreHot = new ScheduleEntryStore(this.userCoordinatesStore, this.props.brandStore.userStore)
  scheduleEntryStoreNow = new ScheduleEntryStore(this.userCoordinatesStore, this.props.brandStore.userStore)
  serviceLocationStore = new ServiceLocationStore(this.scheduleEntryStore)
  scheduleEntryLocationStore = new ScheduleEntryLocationStore(this.scheduleEntryStore, this.userCoordinatesStore)
  filtersStore = new FiltersStore()
  state = {
    classTokenCount: 0
  }

  componentDidMount() {
    this.balancesStore.fetch()
    .then(res => this.setState({ classTokenCount: res.data.balances.classTokenCount }));
    this.filtersStore.fetch()
    // cache memberType for 15 minutes
    if (this.props.brandStore.userStore.session!.memberType !== 'member') {
      const xpassMember = getLocal<any>("xpassMember")
      if (xpassMember && xpassMember.expiry > Date.now()) return

      this.props.brandStore.userStore.fetch().then(res => {
        const currentTime = Date.now()
        // Expiry time = 15 minutes in milliseconds
        const expiry = currentTime + (15 * 60000)
        const data = { value: { data: res.data.user.memberType }, expiry }
        setLocal("xpassMember", data)
      })
      .catch(err => console.error(err))
    }
  }

  componentWillUnmount() {
    this.balancesStore.dispose()
  }

  filterEntries = (stores?: ScheduleEntryStore[]) => {
    const highlightedStudio = this.scheduleEntryStore.highlightedStudio
    if (!highlightedStudio) {
      this.scheduleEntryLocationStore.resetDisabledLocations()
      this.scheduleEntryLocationStore.resetEnabledLocations()
      this.scheduleEntryLocationStore.fetch()
    }
    if (stores) {
      stores.forEach(store => {
        store.setIsPaginated(false)
        store.fetchByFilters()
      })
    } else {
      this.scheduleEntryStore.setIsPaginated(false)
      this.scheduleEntryStore.fetchByFilters()
    }
    this.serviceLocationStore.fetch()
  }

  resetFilters = () => {
    // Schedule entry stores
    [this.scheduleEntryStore, this.scheduleEntryStoreHot, this.scheduleEntryStoreNow].forEach(store => {
      store.setDistance(4)
      store.setBrands([])
      store.setPeakClassesEnabled(false)
      store.setFreeClassesEnabled(false)
      store.setHighlightedStudio(undefined)
      store.setFilteredLocations([])
      store.setStartDate("")
      store.setPage(1)
      store.setIsFiltered(false)
    })
    this.scheduleEntryStore.setStartTimeRange([])
    this.scheduleEntryStoreHot.setStartTimeRange([])
    this.props.brandStore.uiStore.setHasAppliedFilters(false)
  }

  handleActiveBrand = (brand: string) => {
      this.filtersStore.setSelectedBrands(brand)
      this.scheduleEntryStore.setBrands(this.filtersStore.selectedBrands)
      this.scheduleEntryStoreHot.setBrands(this.filtersStore.selectedBrands)
      this.scheduleEntryStoreNow.setBrands(this.filtersStore.selectedBrands)
      this.props.brandStore.uiStore.setHasAppliedFilters(this.filtersStore.hasFilters)
      this.filterEntries([this.scheduleEntryStore, this.scheduleEntryStoreHot, this.scheduleEntryStoreNow])
  }

  handleActiveTab = (tab: SubmenuTab) => {
    this.props.brandStore.uiStore.setActiveTab(tab)
    this.props.brandStore.uiStore.hideFiltersModal()
    this.filtersStore.hideMap()
  }


render() {
  return (
    <APILoader
      apiStore={this.userCoordinatesStore}
      elementSize="page"
      render={() => {
        return (
          <div className="book-class-page">
            <BookClassSubNav
              activeTab={this.props.brandStore.uiStore.nav.activeTab}
              isBrandsActive={this.props.brandStore.uiStore.isBrandsNavActive}
              selectedBrands={this.filtersStore.selectedBrands}
              showDateFilter={this.props.brandStore.uiStore.showDateFilter}
              handleActiveTab={this.handleActiveTab}
              handleActiveBrand={this.handleActiveBrand}
              filtersStore={this.filtersStore}
              scheduleEntryStore={this.scheduleEntryStore}
              scheduleEntryStoreHot={this.scheduleEntryStoreHot}
              scheduleEntryLocationStore={this.scheduleEntryLocationStore}
              filterEntries={this.filterEntries}
              dateFormat={this.props.brandStore.styleClasses.BookClassSubNav__dateFormat}
              />
            <div className={`container tabs-container ${this.props.brandStore.uiStore.nav.activeTab === "featured" ? "featured-tab" : ""}`}>
              <div className="token-count my-4 flex align-items-center">
                <div className="token-count__number flex justify-content-center align-items-center">{this.state.classTokenCount}</div>
                <p>{this.state.classTokenCount === 1 ? "Token" : "Tokens"}</p>
              </div>
              <ClassBookingTabs
                store={this.props.brandStore}
                scheduleEntryStore={this.scheduleEntryStore}
                scheduleEntryStoreHot={this.scheduleEntryStoreHot}
                scheduleEntryStoreNow={this.scheduleEntryStoreNow}
                scheduleEntryLocationStore={this.scheduleEntryLocationStore}
                serviceLocationStore={this.serviceLocationStore}
                userCoordinatesStore={this.userCoordinatesStore}
                balancesStore={this.balancesStore}
                filtersStore={this.filtersStore}
                onBookingModal={this.props.onBookingModal}
                activeTab={this.props.brandStore.uiStore.nav.activeTab}
                showFiltersModal={this.props.brandStore.uiStore.nav.showFiltersModal}
                filterEntries={this.filterEntries}
                resetFilters={this.resetFilters}
                handleActiveTab={this.handleActiveTab}
                selectedCity={this.props.brandStore.uiStore.nav.selectedLocation}
                />
            </div>
            {this.props.brandStore.uiStore.nav.activeTab === 'featured'
              && this.props.brandStore.userStore.session!.memberType !== 'member'
              && <div className={`book-class-page__banner ${process.env.SHOW_STYLEGUIDE === "1" ? "" : "no-styleguide"}`}>
                  <h4><b>Save over 50%</b> by becoming a member | <Link to="/buy/xponential-xpass">JOIN TODAY</Link></h4>
                 </div>
            }
            {this.props.brandStore.uiStore.nav.showFiltersModal &&
              <FiltersModal
                store={this.props.brandStore}
                activeTab={this.props.brandStore.uiStore.nav.activeTab}
                filtersStore={this.filtersStore}
                filterEntries={this.filterEntries}
                scheduleEntryStore={this.scheduleEntryStore}
                scheduleEntryStoreHot={this.scheduleEntryStoreHot}
                scheduleEntryStoreNow={this.scheduleEntryStoreNow}
              />
            }
            {this.filtersStore.showMap &&
              <ScheduleEntriesMap
                store={this.props.brandStore}
                userCoordinatesStore={this.userCoordinatesStore}
                scheduleEntryStore={this.scheduleEntryStore}
                scheduleEntryLocationStore={this.scheduleEntryLocationStore}
                filterEntries={this.filterEntries}
              />
            }
            <button
              className={`map-btn d-flex align-items-center justify-content-center ${this.props.brandStore.uiStore.nav.activeTab === 'featured' ? "map-btn__featured" : ""}`}
              onClick={() => this.filtersStore.toggleMap()}
            >
              <img src={MapIcon}/>
              <p className="mb-0 ml-1">{this.filtersStore.showMap ? "List": "Map"}</p>
            </button>
          </div>
        )
      }
      }
    />
  )
}
}
