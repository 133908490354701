import { observable, action } from "mobx"
import { AxiosResponse, AxiosError } from "axios"
import ServiceLocation from "apps/book/models/xpass/ServiceLocation"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import APIStore from "stores/APIStore"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"

export default class ServiceLocationStore extends APIStore {
  @observable serviceLocations: ServiceLocation[] = []

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("service_locations", ServiceLocation),
  ])

  constructor(private scheduleEntryStore: ScheduleEntryStore) {
    super()
  }

  fetch() {
    const { timeParam, locationsParam, distanceParam, brandsParam, coordinatesParam } = this.scheduleEntryStore
    const url = `/api/xpass/service_entries?${timeParam}${locationsParam}${distanceParam}${brandsParam}${coordinatesParam}`
    return this.api.get(url)
  }

  fetchByLocation(date: string, locationId: string) {
    return this.api.get(`/api/xpass/service_entries?start_start_time=${date}&locations=${locationId}`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.serviceLocations = res.data.service_locations
    return res
  }

  @action.bound
  handleError(err: AxiosError) {
    console.error(err)
    throw err
  }
}
