import {
  BrandData,
  baseSettings,
  baseStyles,
  baseCopy,
  baseDashboardCards,
  BadgeSvg,
  BadgeBitmap,
  baseStyleClasses,
} from "themes/base"

import logoUrl from "images/logos/testbrand-logo.png"
import numberFormatter, { formatNumber } from "helpers/numberFormatter"
import { Metric } from "apps/history/models/Stats"
import videoLandingLogo from "images/video/purebarre-go-logo.png"

import bannerImage from "images/dashboard/purebarre_banner_card.jpg"
import badgeImage from "images/badges/akt-badge.svg"
import badge1Active from "images/badges/akt/milestone-0-Active-AKT.png"
import badge50Active from "images/badges/akt/milestone-1-Active-AKT.png"
import badge250Active from "images/badges/akt/milestone-2-Active-AKT.png"
import badge500Active from "images/badges/akt/milestone-3-Active-AKT.png"

import badge1Inactive from "images/badges/akt/milestone-0-Inactive-AKT.png"
import badge50Inactive from "images/badges/akt/milestone-1-Inactive-AKT.png"
import badge250Inactive from "images/badges/akt/milestone-2-Inactive-AKT.png"
import badge500Inactive from "images/badges/akt/milestone-3-Inactive-AKT.png"

const badges: BadgeBitmap[] = [
  {
    label: "First Class",
    value: 50000,
    active: badge1Active,
    inactive: badge1Inactive,
  },
  {
    label: "50 Classes",
    value: 250000,
    active: badge50Active,
    inactive: badge50Inactive,
  },
  {
    label: "250 Classes",
    value: 500000,
    active: badge250Active,
    inactive: badge250Inactive,
  },
  {
    label: "500 Classes",
    value: 1000000,
    active: badge500Active,
    inactive: badge500Inactive,
  },
]

const metrics: Metric[] = [
  {
    key: "avgHr",
    label: "Average Heart Rate",
    logo: "perf-distance",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "avgHrPercent",
    label: "Average HR %",
    logo: "perf-distance",
    logoSize: 30,
    format: numberFormatter({ suffix: "%" }),
  },
  {
    key: "highHr",
    label: "Max Heart Rate",
    logo: "perf-distance",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "highHrPercent",
    label: "Max HR %",
    logo: "perf-distance",
    logoSize: 30,
    format: numberFormatter({ suffix: "%" }),
  },
  {
    key: "iqPoints",
    label: "Points",
    logo: "perf-distance",
    logoSize: 30,
    format: formatNumber,
  },
  {
    key: "calories",
    label: "Calories",
    logo: "perf-distance",
    logoSize: 30,
    format: formatNumber,
  },
]

const brand: BrandData = {
  settings: {
    ...baseSettings,
    isPerformance: true,
    serviceBooking: true,
    showAddToCalendarButton: true,
  },
  styles: {
    ...baseStyles,
    chartColor: "#60259e",
    logoUrl,
    badgeImage,
    bannerImage,
    videoLandingLogo,
  },
  styleClasses: {
    ...baseStyleClasses
  },
  copy: { ...baseCopy, myBest: "Top" },
  dashboardCards: baseDashboardCards,
  badges,
  metrics,
  summaryMetrics: [],
}

export default brand
