import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import GenericSummary from "models/GenericSummary"
import UserStore from "stores/UserStore"

export default class FavoritesStore<
  T extends Constructor<GenericSummary>
> extends APIStore {
  @observable favorites: InstanceType<T>[] = []
  // @observable favoritesIds: string[] = []
  @observable selectedItemId?: string = undefined
  @observable renderKey?: string = undefined

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware(this.responseKey, this.entityType),
    TokenAuthMiddleware(this.userStore),
  ])

  constructor(
    public responseKey: string,
    public userKey: "locations" | "classCategories" | "instructors" | "seats",
    public entityType: T,
    protected userStore: UserStore
  ) {
    super()
    if (this.userStore.session) {
      this.favorites = (this.userStore.session![this.userKey] ||
        []) as InstanceType<T>[]
    }
  }

  protected get baseUrl() {
    return `/api/favorite_${this.responseKey}`
  }

  fetch = () => {
    return this.api.get(this.baseUrl)
  }

  add = (id: string) => {
    return this.api.put(`${this.baseUrl}/${id}`)
  }

  // TODO: Complete favorite seat implementation
  addSeat = (seatId: string, roomId: string, locationId: string) => {
    return this.api.put(
      `/api/locations/${locationId}/favorite_seats/${roomId}/${
        this.responseKey
      }`,
      {
        seat_id: seatId,
      }
    )
  }

  remove = (id: string) => {
    return this.api.delete(`${this.baseUrl}/${id}`)
  }

  toggle = (id: string) => {
    return this.favorites.find(f => id === f.id) ? this.remove(id) : this.add(id)
  }

  // Triggers a re-render whenever a location is unfavorited
  setRenderKey = () => {
    this.renderKey = this.renderKey === undefined ? "" : undefined
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.favorites = res.data[this.responseKey]
    this.userStore.session![this.userKey] = this.favorites
    return res
  }

  @action.bound
  setSelectedItemId(id: string) {
    this.selectedItemId = id
  }
}
