import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosError } from "axios"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import { observable, action, computed } from "mobx"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import getIn from "lodash/get"
import BookingStatus from "models/BookingStatus"
import XpassBookingStatus from "models/xpass/XpassBookingStatus"
import LocationSummary from "models/LocationSummary"
import BrandStore from "stores/BrandStore"
import Booking from "models/Booking"
import XpassBooking from "models/xpass/XpassBooking"
import Room from "models/Room"

export interface BookingResponse {
  booking_status?: BookingStatus
  booking?: Booking
  room?: Room
  location?: LocationSummary
}

export interface Params {
  id?: string
  locationId?: string
}

export default class BookingStore extends APIStore {
  @observable booking?: Booking
  @observable bookingStatus?: BookingStatus
  @observable room?: Room
  isXponential = this.brandStore.isXponential

  api = this.createClient<BookingResponse>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    DeserializeMiddleware("booking_status", BookingStatus, true),
    DeserializeMiddleware("booking", Booking, true),
    DeserializeMiddleware("room", Room, true),
    DeserializeMiddleware("location", LocationSummary, true),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  // TODO: maybe not needed
  @computed get scheduleEntry() {
    if (this.bookingStatus) {
      return this.bookingStatus.scheduleEntry
    }
    if (this.booking) {
      return this.booking.scheduleEntry
    }
    return undefined
  }

  constructor(
    public locationSummary: LocationSummary,
    private brandStore: BrandStore
  ) {
    super()
  }

  fetch(params: Params = {}) {
    const locationSummary = getIn(this.locationSummary, "id", undefined)
    const url = this.isXponential
      ? `/api/xpass/v2/bookings/${params.id}?location_id=${params.locationId}&from_web=true`
      : `/api/v2/locations/${locationSummary}/bookings/${params.id}`
    return this.api.get(url)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<BookingResponse>) {
    const location = res.data.location
    this.booking = res.data.booking
    this.bookingStatus = res.data.booking_status
    this.room = res.data.room

    if ((this.booking = res.data.booking)) {
      this.booking.isBusy = false
      this.booking.scheduleEntry.location = location
    }
    if ((this.bookingStatus = res.data.booking_status)) {
      this.bookingStatus.scheduleEntry.location = location
    }
    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    console.error(ex)
    // TODO: if this is a 403 we want to redirect them through a login/registration
    // flow of some sort.
    throw ex
  }
}
