import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("Brand")
export class Brand {
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("site_url", String, true)
  siteUrl?: string = undefined

  @JsonProperty("portal_url", String, true)
  portalUrl?: string = undefined

  @JsonProperty("ios_app_url", String, true)
  iosAppUrl?: string = undefined

  @JsonProperty("android_url", String, true)
  androidUrl?: string = undefined

  @JsonProperty("seq", Number, true)
  seq?: number = undefined!
}

@JsonObject("HomePromo")
export default class HomePromo {
  @JsonProperty("id", Number)
  id: number = undefined!

  @JsonProperty("google_ad", Boolean, true)
  googleAd: boolean = false

  @JsonProperty("title", String, true)
  title?: string = undefined

  @JsonProperty("description", String, true)
  description?: string = undefined

  @JsonProperty("cta", String, true)
  cta?: string = undefined!

  @JsonProperty("seq", Number, true)
  seq?: number = undefined!

  @JsonProperty("external_url", String, true)
  externalUrl?: string = undefined!

  @JsonProperty("home_promo_card_background_image_url", String, true)
  homePromoCardBackgroundImageUrl?: string = undefined!

  @JsonProperty("brand", Brand, true)
  brand?: Brand = undefined!

  @JsonProperty("display_id", String, true)
  displayId?: string = undefined!

  @JsonProperty("width", Number, true)
  width?: number = undefined!

  @JsonProperty("height", Number, true)
  height?: number = undefined!

  @JsonProperty("slot_path", String, true)
  slotPath?: string = undefined!
}
