import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import LocationSummary from "models/LocationSummary"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export interface OtpCodesResponse {
  success: boolean,
  error: Error
}

export default class OtpCodesStore extends APIStore {
  @observable otpCodes: any

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(public brandStore: BrandStore) { super() }

  checkEmail() {
    return this.api.get(`/api/otp_codes/check_email_verification`, 
      {
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        }
      }
    )
  }

  emailOtpCode = () => {
    return this.api.post(`/api/otp_codes/`)
  }

  submitOtpCode = (otpCode: string) => {
    return this.api.put(`/api/otp_codes/`, { otp_code: otpCode },
      {
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        }
      }
    )
  }

  optIn = (location: string, birthDate: string) => {
    return this.api.post(`/api/class_points/opt_ins`,
      {
        home_location_id: location,
        birth_date: birthDate,
        platform: "web"
      }
    )
  }

  @action.bound
  handleSuccess(res: AxiosResponse<OtpCodesResponse>) {
    return res
  }
}
