import * as React from "react"

export interface ConfirmDialogProps {
  message?: string // If present it'll be the displayed error or message.
  children?: React.ReactNode // If present, children to be displayed in the Modal body.
  okLabel?: string
  cancelLabel?: string
  okButtonClass?: string
  cancelButtonClass?: string
  className?: string
  allowDisabledState?: boolean // If true, buttons will be disabled on ok button click.
  okHandler: () => void
  cancelHandler: () => void
}

export const ConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = ({
  message,
  children,
  okLabel,
  cancelLabel,
  okButtonClass,
  cancelButtonClass,
  className,
  allowDisabledState,
  okHandler,
  cancelHandler,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <div className={className}>
      <div className="text-center">
        {message && <p className={"text-center"}>{message}</p>}
        {children}
      </div>
      <div>
        <hr />
        <div className="d-flex justify-content-center">
          <button
            className={okButtonClass || "btn btn-primary btn-md mr-3 "}
            onClick={e => {
              e.preventDefault()
              okHandler()
              if (allowDisabledState) setIsLoading(true)
            }}
            disabled={isLoading}
          >
            {okLabel}
          </button>
          <button
            className={cancelButtonClass || "btn btn-secondary btn-md"}
            onClick={e => {
              e.preventDefault()
              cancelHandler()
            }}
            disabled={isLoading}
          >
            {cancelLabel}
          </button>
        </div>
      </div>
    </div>
  )
}
