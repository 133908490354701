/* eslint-disable @typescript-eslint/no-explicit-any */

export type LocalStorageKey = "testTimeout" | "xpassS3Locations" | "TFID" | "covidWaiverAccepted" | "laFitnessAuth" | "laFitnessBooking" | "laFitnessPurchase" | "xpassMember"

const parseValue = <T>(value: Nullable<string>, fallback: T) => {
  if (value === null) {
    return fallback
  }

  try {
    return JSON.parse(value) as T
  } catch {
    // TODO: Pass to sentry
    // console.warn(`Failed to parse string "${value}". Falling back to "${fallback}".`);

    return fallback
  }
}

const setItem = (key: LocalStorageKey, data: any) => {
  localStorage.setItem(key, JSON.stringify(data))
}

/**
 * Sets local storage with type safety
 * @param key The local storage key
 * @param data The local storage value
 */
export const setLocal = (key: LocalStorageKey, data: any) => setItem(key, data)

/**
 * Retrieves the value from local storage with type safety
 * @param key The local storage key
 * @param fallback - (optional) The default value to return if the key is not found. Defaults to null.
 */
export const getLocal = <T>(key: LocalStorageKey, fallback: any = null) => {
  const value: Nullable<string> = localStorage.getItem(key)

  return parseValue<T>(value, fallback)
}

/**
 * Removes the value from local storage with type safety
 * @param key The local storage key
 */
export const removeLocal = (key: LocalStorageKey) =>
  localStorage.removeItem(key)
