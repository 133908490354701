import React from "react"
import BrandStore from "stores/BrandStore"
import PackagesStore from "apps/buy/stores/xpass/PackagesStore"
import APILoader from "components/wrappers/APILoader"
import GlobeIcon from "images/xpass/icons/globe.svg"
import BicycleIcon from "images/xpass/icons/bicycle.svg"
import HandshakeIcon from "images/xpass/icons/handshake.svg"

export interface Props {
  brandStore: BrandStore
}

const xpassDetails = [
  "Access premium fitness studios nationwide",
  "Book using a single login and membership",
  "Change your plan or add on credits any time",
]

const offerDetails = [
  {
    description: (
      <>
        Gain access to the{" "}
        <strong>best boutique fitness studios nationwide.</strong>
      </>
    ),
    icon: GlobeIcon,
    iconAlt: "Globe icon",
  },
  {
    description: (
      <>
        From boxing to barre, rowing to running,{" "}
        <strong>XPASS makes you stronger, never bored.</strong>
      </>
    ),
    icon: BicycleIcon,
    iconAlt: "Bicycle icon",
  },
  {
    description: (
      <>
        Book and manage your classes in <strong>one user-friendly app.</strong>
      </>
    ),
    icon: HandshakeIcon,
    iconAlt: "Handshake icon",
  },
]

export default class XpassMarketingPage extends React.Component<Props> {
  xpassDomain = Object.assign(window.globals.brandDomains).xponential
  packagesStore = new PackagesStore(this.props.brandStore, false)

  render() {
    const { email, locationId } = this.props.brandStore.userStore.session!
    const encodedEmail = encodeURIComponent(email)
    const appendBrand = this.props.brandStore.brand.id !== 'xponential' ? `&brand=${this.props.brandStore.brand.id}` : ''
    const registerUrl = `${this.xpassDomain}/auth/register/account?brand_location_id=${locationId}&email=${encodedEmail}&redirect=false${appendBrand}`
    const loginUrl = `${this.xpassDomain}/auth/login?brand_location_id=${locationId}&redirect=false`
    const { xpassMarketingIcons } = this.props.brandStore.styles
    const icons = Object.keys(xpassMarketingIcons).map(
      k => xpassMarketingIcons[k]
    )

    return (
      <APILoader
        apiStore={this.packagesStore}
        fetchProps={true}
        render={() => {
          const { allPackages } = this.packagesStore
          const freePackage = allPackages.find(pkg => pkg.isXpassFree)

          if (!freePackage) {
            window.location.href = loginUrl
            return null
          }

          return (
            <div className="xpass-marketing d-lg-flex justify-content-lg-between mb-3 mt-3 mt-lg-5">
              <div>
                <h1>Elevate your fitness experience.</h1>
                <ul className="d-flex flex-wrap w-100 p-0 mb-0 mb-lg-2 brandIconList">
                  {icons.map((icon, index) => {
                    return (
                      <li className="pb-2 pb-lg-0" key={index}>
                        <img className="brandIcons" src={icon} />
                      </li>
                    )
                  })}
                </ul>
                <div className="xpass-marketing__details">
                  <p>
                    XPASS gives you access to a wide variety of fitness classes
                    at premium boutique studios. Simple, flexible. Ready to
                    sweat with us?
                  </p>
                  <p>With an XPASS plan, you can:</p>
                  <ul className="p-0 m-0">
                    {xpassDetails.map((detail, index) => (
                      <li className="detail" key={index}>
                        {detail}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="xpass-marketing__offer mt-5 mt-lg-0">
                <h2>
                  <strong>{freePackage.promoName}</strong>
                  <span className="d-block">{freePackage.promoTitle}</span>
                </h2>
                <div>
                  {offerDetails.map((detail, index) => {
                    return (
                      <div
                        className="detail d-flex align-items-center"
                        key={index}
                      >
                        <div>
                          <img src={detail.icon} alt={detail.iconAlt} />
                        </div>
                        <p className="m-0">{detail.description}</p>
                      </div>
                    )
                  })}
                  <div className="text-center">
                    <a href={registerUrl} className="btn btn-primary w-100">
                      Get Started
                    </a>
                    <p className="pt-2 pb-3 m-0">
                      Already have an account?{" "}
                      <a
                        href={loginUrl}
                        className="pl-1"
                        style={{ color: "#16acbb", height: "52px" }}
                      >
                        Sign in with XPASS
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}
