// This component is similar to BookingInfoCard
// This is specifically for the book and confirm flow
// where right after the user purchases the package, she is
// taken directly to the final screen instead of having to
// confirm the class manually.
// This component displays the plan purchased on the right
// with title, subtitle and CTA(s) on the left

import * as React from "react"
import * as cx from "classnames"
import { observer } from "mobx-react"
import { Moment } from "moment"
import nl2br from "helpers/nl2br"

import LocationSummary from "models/LocationSummary"
import InstructorSummary from "models/InstructorSummary"
import Brand from "models/Brand"

import Heading from "components/Heading"
import { BrandCopy, BrandStyleClasses } from "themes/base"
import ErrorMessage from "components/ErrorMessage"

import { PlanInfo, GiftCardInfo } from "apps/buy/components/PaymentPage"

export interface ScheduleEntrySummary {
  title: string
  subtitle?: string
  startsAt: Moment
  startTime: string
  endTime: string
  instructor?: InstructorSummary
}

interface Props {
  title: React.ReactNode
  subtitle?: React.ReactNode
  email?: string
  scheduleEntry?: ScheduleEntrySummary
  brand: Brand
  locationSummary: LocationSummary
  seatId?: string
  seatLabel?: string
  waitlistPosition?: number
  waitlistIsEstimate?: boolean
  errorMessage?: string
  copy: BrandCopy
  planInfo?: PlanInfo
  giftCardInfo?: GiftCardInfo
  styleClasses: BrandStyleClasses
}

const BookAndConfirmInfoCard: React.SFC<Props> = ({
  title,
  subtitle,
  scheduleEntry,
  brand,
  locationSummary,
  children,
  seatId,
  seatLabel,
  copy,
  waitlistPosition,
  waitlistIsEstimate,
  planInfo,
  giftCardInfo,
  errorMessage,
  email,
  styleClasses,
}) => {
  if (!scheduleEntry) {
    return null
  }

  return (
    <div>
      <div className="row">
        {errorMessage && (
          <div className="w-100">
            <ErrorMessage message={errorMessage} />
          </div>
        )}
        <div className="col-md-7">
        <Heading center>{title}</Heading>
          {subtitle && (
            // make it a little narrow than the card when centered (shrug)
            <p className="text-large text-sm-center px-sm-2 mb-4 slide-in delay-1">
              {subtitle}
            </p>
          )}

          <div className="confirm-body mb-5 card-in delay-3 col-lg-10 offset-lg-1 col-md-12">
            <div className="card">
              <div className="card-body text-left px-4">
                <div className="row">
                  <div className="col-md-7">
                    <h3 className={cx("mb-0", styleClasses.BookingInfoCard__entryTitle)}>{scheduleEntry.title}</h3>
                    {scheduleEntry.subtitle && (
                      <p className={styleClasses.BookingInfoCard__entrySubtitle}>
                        <strong>{scheduleEntry.subtitle}</strong>
                      </p>
                    )}
                    {waitlistPosition && (
                      <div className={cx("mt-n2 mt-3 text-18", styleClasses.BookingInfoCard__entryWaitlist)}>
                        <strong>
                          {waitlistIsEstimate ? "Estimated " : ""}#{waitlistPosition}{" "}
                          on Waitlist
                        </strong>
                      </div>
                    )}

                    <div className={cx("mt-3 mb-4 text-18", styleClasses.BookingInfoCard__entryDescription)}>
                      <p className="mt-3 mb-4 text-18">
                        <strong>
                          {brand.name} {locationSummary.name}
                        </strong>
                        <br />
                        {scheduleEntry.startsAt.format("dddd MMMM Do, YYYY")}
                        <br />
                        {scheduleEntry.startTime} - {scheduleEntry.endTime}
                      </p>

                      {scheduleEntry.instructor && (
                        <div className="text-dark">
                          <span className="font-weight-bold">{copy.instructor}:</span>{" "}
                          {scheduleEntry.instructor.name}
                        </div>
                      )}
                      {seatId && (
                        <div>
                          <span className="font-weight-bold">{copy.seat}:</span>{" "}
                          {seatLabel || seatId}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {planInfo &&
          <PackageSummary
            planInfo={planInfo}
            giftCardInfo={giftCardInfo}
            email={email}
          />}
      </div>
    </div>
  )
}

interface PackageSummaryProps {
  planInfo?: PlanInfo
  giftCardInfo?: GiftCardInfo
  email?: string
}

const PackageSummary: React.SFC<PackageSummaryProps> = ({ planInfo, giftCardInfo, email }) => {
  const pkg = planInfo!.pkg!
  const plan = planInfo!.plan!
  const promoCode = planInfo!.promoCode
  const discount = planInfo!.discount!
  const originalPrice = planInfo!.originalPrice!
  const giftCardPaymentAmount = giftCardInfo && giftCardInfo.paymentAmount
  const giftCardTodayTotal = giftCardInfo && giftCardInfo.todayTotal
  const giftCardOriginalPrice = giftCardInfo && giftCardInfo.originalPrice

  const {name, description } = pkg
  const { payments, fees, todayTax, todayTotal } = plan
  return (
    <div className={cx("col-md-5 ml-md-auto text-center")}>
      <div className="card">
        <div className="card-body">
          {/* Title */}
          <h3 className="card-title">{name}</h3>

          {/* Description */}
          { nl2br(description) }

          <hr className="mt-4 mb-4" />

          {/* Subtotal */}
          {payments && payments
          .filter(p => p.isDueToday)
          .map((payment, i) => (
            <div className="row payment-order-line" key={i}>
              <div className="col-md-8 text-left">Subtotal </div>
              <div className="col-md-4 text-right">
                {payment.subtotal.formatted}
              </div>
            </div>
          ))}

          {/* Fees */}
          {fees && fees.length > 0 && <hr />}
          {fees && fees
            .filter(f => f.isDueToday)
            .map((fee, i) => (
              <div className="row payment-order-line" key={i}>
                <div className="col-md-8 text-left">{fee.name} </div>
                <div className="col-md-4 text-right">{fee.subtotal.formatted}</div>
              </div>
            ))}

          {/* Tax */}
          {todayTax && (
            <div className="row payment-order-line">
              <div className="col-md-8 text-left">Tax </div>
              <div className="col-md-4 text-right">{todayTax.formatted} </div>
            </div>
          )}

          <hr />

          {/* Discount */}
          {((promoCode && discount > 0 && originalPrice) || (giftCardPaymentAmount !== undefined && giftCardPaymentAmount > 0 && giftCardOriginalPrice)) && (
            <>
              <div className="row payment-order-total text-muted">
                <div className="col-md-8 text-left">Original Total </div>
                <div className="col-md-4 text-right">
                  {giftCardPaymentAmount && giftCardPaymentAmount > 0 && giftCardOriginalPrice ?
                    giftCardOriginalPrice.formatted :
                    originalPrice.formatted
                  }
                </div>
              </div>
            </>
          )}
          {promoCode && discount > 0 && (
            <>
              <div className="row payment-order-line">
                <div className="col-md-8 text-left text-success">
                  Discount Code {promoCode}
                </div>
                <div className="col-md-4 text-right text-success">
                  - ${discount.toFixed(2)}
                </div>
              </div>
            </>
          )}
          {giftCardPaymentAmount !== undefined && giftCardPaymentAmount > 0 && (
            <>
              <div className="row payment-order-line">
                <div className="col-md-8 text-left text-success">
                  Gift Card
                </div>
                <div className="col-md-4 text-right text-success">
                  - ${giftCardPaymentAmount.toFixed(2)}
                </div>
              </div>
            </>
          )}

          {/* Order Total */}
          {(todayTotal || giftCardTodayTotal !== undefined) && (
            <div className="row payment-order-total">
              <div className="col-md-8 text-left ">Order Total </div>
              <div className="col-md-4 text-right">{giftCardTodayTotal ? `$${giftCardTodayTotal.toFixed(2)}` : todayTotal.formatted}</div>
            </div>
          )}
        </div>
      </div>
      {email &&
        <p className="mt-3">
          {`A receipt has been sent to ${email}`}
        </p>
      }
    </div>
  )
}
export default observer(BookAndConfirmInfoCard)
