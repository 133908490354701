import * as React from "react"
import { RouteComponentProps, Switch, Redirect, Route } from "react-router"
import getIn from "lodash/get"
import BrandStore from "stores/BrandStore"
import LocationSummary from "models/LocationSummary"
import ServiceTypesStore from "apps/book/stores/ServiceTypesStore"
import { observable } from "mobx"
import { observer, inject } from "mobx-react"
import { computed } from "mobx"
import ServiceBookingStore from "apps/book/stores/ServiceBookingStore"
import ServiceSchedulePage from "apps/book/components/ServiceSchedulePage"
import ServiceBookingConfirmPage from "apps/book/components/ServiceBookingConfirmPage"
import ServiceBookingSuccessPage from "apps/book/components/ServiceBookingSuccessPage"
import ServiceBookAndConfirmSuccessPage from "apps/book/components/ServiceBookAndConfirmSuccessPage"
import ServiceScheduleEntry from "apps/book/models/ServiceScheduleEntry"
import BookingFlowModal from "apps/book/components/xpass/BookingFlowModal"
import HealthCheck from "apps/book/components/HealthCheck"
import APILoader from "components/wrappers/APILoader"
import { toServiceBookingProps } from "helpers/queryString"
import { loadState } from "services/savedState"
import { LATEST_BOOKING_PATH_KEY } from "apps/book/stores/QueryStringMonitor"
import { fromServiceBookingProps } from "helpers/queryString"

export interface Props extends RouteComponentProps<{ serviceTypeId?: string }> {
  store?: BrandStore
  locationSummary: LocationSummary
  healthCheckConfirmed: boolean
}

export interface State {
  healthCheckConfirmed: boolean
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ServiceBookingController extends React.Component<Props, State> {
  @observable
  serviceTypesStore = new ServiceTypesStore(this.props.store!, this.props.locationSummary)

  @observable
  serviceBookingStore = new ServiceBookingStore(
    this.props.locationSummary,
    this.props.store!
  )
  @observable purchaseUrl?: string
  @observable purchaseRedirect?: boolean

  /**
   * If a health check is required and hasn't been done, we'll show an intermediate
   * confirm screen so the user can agree to COVID-19 precautions. This happens
   * in a few different circumstances due to various user flows:
   * - User on a schedule page clicks a book button, API call discovers they need
   *   to purchase a package: we show the dialog _before_ redirecting them to the
   *   payment flow
   * - User on a schedule page clicks a book button, already has a package, gets
   *   directed to the class booking confirm page: we show the dialog instead of
   *   rendering the confirm page (until they agree, then we show the confirm page)
   * - User lands on the booking confirm page directly. Depending on whether the
   *   user need a package, the same two cases ^ will apply once the booking
   *   status API call returns
   */
  @computed get needsHealthCheck() {
    // TODO: Setting this to `false` for now. If Xpo wants to get rid of this for good,
    // get rid of this and clean up all the code with `healthCheckConfirmed` and `handleHealthCheckConfirm`
    return false

    // const { healthCheckConfirmed } = this.state

    // return (
    //   !healthCheckConfirmed
    // )
  }

  handleHealthCheckCancel = (_event: ButtonEvent) => {
    if (this.purchaseUrl) {
      this.purchaseUrl = undefined
    }

    const { match } = this.props
    const { serviceBookingStore } = this
    let { pathname = "", search = "" } =
      loadState(LATEST_BOOKING_PATH_KEY, true) || {}

    const date = serviceBookingStore.bookingStatus!.scheduleEntry.dateString
    pathname = pathname || match.url.replace("/confirm", "")
    search = search || `date=${date}`

    this.props.history.replace({ pathname, search })
    serviceBookingStore.reset()
  }

  handleHealthCheckConfirm = (_event: ButtonEvent) => {
    if (this.purchaseUrl) {
      this.purchaseRedirect = true
    }

    const { locationSummary: loc } = this.props
    this.props.store!.track.event("health check confirm", { loc })
    this.setState({ healthCheckConfirmed: true })
  }

  get serviceTypeId() {
    return this.props.match.params.serviceTypeId
  }

  get bookAndConfirm() {
    return getIn(this.props.location, 'state.bookAndConfirm', false)
  }

  get planInfo() {
    return getIn(this.props.location, 'state.planInfo', undefined)
  }

  get giftCardInfo() {
    return getIn(this.props.location, 'state.giftCardInfo', undefined)
  }

  get bookingPropsFromQueryString() {
    return toServiceBookingProps(this.props.location.search)
  }

  constructor(props: Props & RouteComponentProps) {
    super(props)
    this.state = {
      healthCheckConfirmed: props.healthCheckConfirmed,
    }
  }

  setPurchaseUrl = (purchaseUrl: string) => {
    this.purchaseUrl = purchaseUrl
  }

  componentDidMount() {
    const planInfo = getIn(this.props.location, "state.planInfo", undefined)

    // if user came from purchase flow, they already agreed to the health check
    const healthCheckConfirmed = Boolean(planInfo)

    this.setState({ healthCheckConfirmed })
  }

  componentWillUnmount() {
    this.serviceTypesStore.dispose()
    this.serviceBookingStore.dispose()
  }

  handleServiceBookingCheck = (entry: ServiceScheduleEntry) => {
    const { packageNeeded, readyToBook, bookingProps } = this.serviceBookingStore
    const search = fromServiceBookingProps(bookingProps!)
    this.props.store!.uiStore.closeModal()

    if (packageNeeded) {
      const path = `/buy/${this.props.locationSummary.id}`
      this.props.history.push(`${path}?${search}`)
    } else if (readyToBook) {
      const path = `${this.props.match.url.replace(/\/$/, "")}/confirm`
      this.props.history.push(`${path}?${search}`)
    }
  }

  handleBookingModal = (entry: ServiceScheduleEntry) => {
    this.props.store!.uiStore.openModal({
      children: (
        <BookingFlowModal
          {...this.props}
          store={this.props.store}
          handleHealthCheckConfirm={this.handleHealthCheckConfirm}
          handleHealthCheckCancel={this.handleHealthCheckCancel}
          serviceBookingCheck={this.handleServiceBookingCheck}
          entry={entry}
        />
      ),
      modalShow: true,
    })
  }

  public render() {
    if (!this.props.store!.settings.serviceBooking) {
      return <Redirect to={`/book/${this.props.locationSummary.id}`} />
    }

    // Redirect to purchase page if credit needed modal button clicked + health check confirmed
    // TODO: Remove this is health check is no longer needed. Ignore purchaseRedirect if not using health check.
    //if (this.purchaseUrl && this.purchaseRedirect) {
    if (this.purchaseUrl) {
      return <Redirect to={`${this.purchaseUrl}`} />
    }

    const { serviceBookingStore } = this
    const { packageNeeded } = this.serviceBookingStore

    const { match } = this.props
    const bookingProps = this.bookingPropsFromQueryString

    return (
      <Switch>
        <Route
          path={`${match.path}/confirm`}
          exact
          render={props => {
            // Prevent showing another health check here if XPASS service booking flow
            const param = new URLSearchParams(window.location.search)
            const healthChecked = param.get("health_checked")

            if (this.needsHealthCheck && !healthChecked) {
              return(
                <APILoader
                  apiStore={serviceBookingStore}
                  fetchProps={bookingProps}
                  alreadyLoaded={serviceBookingStore.isLoadedFor(bookingProps)}
                  render={() => {
                    return(
                      <HealthCheck
                        store={this.props.store}
                        onConfirm={this.handleHealthCheckConfirm}
                        onCancel={this.handleHealthCheckCancel}
                      />
                    )
                  }}
                />
              )
            }
            return (
              <ServiceBookingConfirmPage
                {...props}
                store={this.props.store!}
                locationSummary={this.props.locationSummary}
                serviceBookingStore={this.serviceBookingStore}
              />
            )
          }}
        />
        <Route
          path={`${match.path}/success`}
          exact
          render={props => {
            return !this.bookAndConfirm
              ? <ServiceBookingSuccessPage
                  {...props}
                  locationSummary={this.props.locationSummary}
                  serviceBookingStore={this.serviceBookingStore}
                />
              : <ServiceBookAndConfirmSuccessPage
                  {...props}
                  locationSummary={this.props.locationSummary}
                  serviceBookingStore={this.serviceBookingStore}
                  planInfo={this.planInfo}
                  giftCardInfo={this.giftCardInfo}
                />
          }}
        />
        <Route
          path={`${match.path}`}
          exact
          render={props => {
            if (this.needsHealthCheck && packageNeeded && this.purchaseUrl) {
              return (
                <HealthCheck
                  store={this.props.store}
                  onConfirm={this.handleHealthCheckConfirm}
                  onCancel={this.handleHealthCheckCancel}
                />
              )
            }
            return (
              <ServiceSchedulePage
                {...props}
                locationSummary={this.props.locationSummary}
                serviceTypeId={this.serviceTypeId}
                serviceTypesStore={this.serviceTypesStore}
                serviceBookingStore={this.serviceBookingStore}
                onBookingModal={this.handleBookingModal}
                setPurchaseUrl={this.setPurchaseUrl}
              />
            )
          }}
        />
      </Switch>
    )
  }
}
