import * as React from "react"
import { inject, observer } from "mobx-react"
import { observable } from "mobx"

import BrandStore from "stores/BrandStore"
import PaymentSourcesStore from "apps/account/stores/PaymentSourcesStore"
import InvoicesStore from "apps/account/stores/InvoicesStore"

import APILoader from "components/wrappers/APILoader"
import BillingHistoryPanel from "apps/account/components/BillingHistoryPanel"
import PaymentSourceUpdateStore from "apps/account/stores/PaymentSourceUpdateStore"
import PaymentSourcePanel from "apps/account/components/PaymentSourcePanel"
import Heading from "components/Heading"
import { RouteComponentProps } from "react-router"
import PageTracker from "components/wrappers/PageTracker"

export interface Props extends RouteComponentProps {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class BillingPage extends React.Component<Props, {}> {
  paymentSourcesStore = new PaymentSourcesStore(this.props.store!)
  paymentSourceUpdateStore = new PaymentSourceUpdateStore(
    this.paymentSourcesStore
  )
  invoicesStore = new InvoicesStore(this.props.store!)

  @observable formShown = false

  handleToggleFormClick = () => {
    this.formShown = !this.formShown
  }

  render() {
    const {
      copy,
      userStore: { session },
      brandData: { settings },
      isXponential,
    } = this.props.store!

    return (
      <div className="account-billing col">
        <Heading>{`${copy.paymentMethod}`}</Heading>
        <PageTracker name="account > billing" />

        <div>
          <PaymentSourcePanel
            className="mb-5"
            isBillingPage={true}
          />

          {settings.billingHistoryEnabled && (
            <div className="mb-5">
              <h1>Billing History</h1>
              <APILoader
                elementSize="page"
                apiStore={this.invoicesStore}
                render={() => (
                  <BillingHistoryPanel
                    store={this.props.store}
                    invoices={this.invoicesStore.invoices}
                    accessToken={session!.accessToken}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
