// models the state of an HTTP request, as well as some helper methods
// for doing algebra with them
export type LoadStatus = "idle" | "loading" | "loaded" | "error"

export function isLoadedOrError(status: LoadStatus) {
  return status === "loaded" || status === "error"
}

export function lsAnd(status: LoadStatus, isLoaded: boolean): LoadStatus {
  if (status === "loaded" && !isLoaded) {
    return "loading"
  }
  return status
}

export function lsAll(...statuses: LoadStatus[]): LoadStatus {
  if (statuses.some(s => s === "idle")) {
    return "idle"
  } else if (statuses.some(s => s === "loading")) {
    return "loading"
  } else if (statuses.some(s => s === "error")) {
    return "error"
  }
  return "loaded"
}
