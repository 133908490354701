import { LoadStatus } from "utils/LoadStatus"
import { Middleware } from "services/Middleware"

// Sets the status on the APIStore. This should usually be first in the stack
// since we don't want to set status to loaded until everything else is done
export default function SetStatusMiddleware(receiver: {
  setStatus(status: LoadStatus): unknown
}) {
  const middleware: Middleware = (_, next) => {
    receiver.setStatus("loading")
    return next()
      .then(res => {
        receiver.setStatus("loaded")
        return res
      })
      .catch(ex => {
        receiver.setStatus("error")
        throw ex
      })
  }
  return middleware
}
