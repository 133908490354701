import React from "react"
import { Redirect } from "react-router"

import BrandStore from "stores/BrandStore"
import CovidWaiverStore from "stores/CovidWaiverStore"
import { popFriendlyRoute } from "services/friendlyRoute"
import Spinner from "components/Spinner"
import { LocalStorageKey, getLocal, setLocal } from "utils/LocalStorage"
import Svg from "components/Svg"
import logoUrl from "images/logos/la-fitness-logo.svg"

interface RouteInfo {
  pathname: string
}

interface Props {
  brandStore: BrandStore
  eywRedirect?: boolean
  type: string
  location: RouteInfo
}

interface State {
  accepted: boolean
  loading: boolean
}

export default class BlockerPage extends React.Component<Props, State, RouteInfo> {

  waiverStore = new CovidWaiverStore(this.props.brandStore.userStore)
  state = {
    accepted: false,
    loading: false,
  }

  get destination() {
    const { location } = this.props

    // the friendlyRoute is where the user was trying to go before they got redirected here
    // see RestrictedRoute for more context on how that works
    if (location && (this.keyName === "laFitnessBooking" || this.keyName === "laFitnessPurchase")) {
      return location.pathname
    }

    return popFriendlyRoute() || "/"
  }

  get keyName(): LocalStorageKey {
    const { type } = this.props
    switch (type) {
      case "laFitnessAuth":
      case "laFitnessBooking":
      case "laFitnessPurchase":
        return type;
      default:
        return "covidWaiverAccepted";
    }
  }

  get laFitnessTypes() {
    return ["laFitnessAuth", "laFitnessBooking", "laFitnessPurchase"]
  }

  componentDidMount() {
    const { uiStore, userStore } = this.props.brandStore
    const userClubreadyId = userStore.session!.clubreadyId
    const eywAffiliateLink = this.props.brandStore!.eywAffiliateLink()
    if (this.props.eywRedirect && userClubreadyId && eywAffiliateLink) {
      window.location.href = `${eywAffiliateLink}${userClubreadyId}`
    }

    uiStore.hideNavLinks()
    uiStore.lockLocationPicker()
  }

  componentWillUnmount() {
    const { uiStore } = this.props.brandStore
    uiStore.showNavLinks()
    uiStore.unlockLocationPicker()
  }

  handleCovidAgree = () => {
    this.setState({ loading: true })
    this.waiverStore.recordWaiverAgreement().then(res => {
      this.setState({ accepted: true })
    })
    setLocal(this.keyName, true)
  }

  handleLaFitnessAgree = () => {
    this.setState({ loading: true, accepted: true })

    const { currentLocation } = this.props.brandStore.locStore
    const keyName = this.keyName

    const obj = { [currentLocation!.id] : true }

    // Append if other locations exists
    // { "la_fitness_auth": { "clubpilates-demo-ak": true, "clubpilates-ny": true } }
    setLocal(keyName, { ...getLocal(keyName), ...obj })
  }

  render() {
    const { currentLocation } = this.props.brandStore.locStore
    const { accepted, loading } = this.state
    const { type } = this.props
    if (accepted) {
      return <Redirect to={this.destination} />
    }

    if (this.laFitnessTypes.includes(type)) {
      return (
        <div className="row justify-content-center">
          <div className="col-md-5 shadow-lg p-5 mb-5 mt-5 bg-white rounded text-center la-fitness-blocker">
            <p className="text-center title">INSIDE LA | FITNESS</p>
            <img src={logoUrl} className="mb-4" alt="" />
            <p className="text-left description">{currentLocation!.laFitnessWarning}</p>
            <a href={currentLocation!.laFitnessLinkUrl} className="d-block description" target="_blank">
              {currentLocation!.laFitnessLink}
              <Svg name="caret-right" className="pl-2" size="12" />
            </a>
              {loading ? (
                <Spinner size="element" />
              ) : (
                <button className="btn btn-primary mt-4" onClick={this.handleLaFitnessAgree}>
                  OK
                </button>
              )}
          </div>
        </div>
      )
    }

    return (
      <div>
        <h2>Covid-19 Liability Waiver</h2>
        <div
          className="my-4"
          dangerouslySetInnerHTML={{ __html: currentLocation!.covidWaiver }}
        />
        {loading ? (
          <Spinner size="element" />
        ) : (
          <button className="btn btn-primary" onClick={this.handleCovidAgree}>
            I Agree
          </button>
        )}
      </div>
    )
  }
}
