import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react';
// components
import {ClassListItem, ClassItem, AppointmentListItem, StudioListItem, StudioItem} from './EntryListItem';
import APILoader from 'components/wrappers/APILoader';
import SwiperButton from './SwiperButton';
// stores
import BrandStore from 'stores/BrandStore';
import ScheduleEntryStore from 'apps/book/stores/xpass/ScheduleEntryStore';
import BalancesStore from 'apps/buy/stores/xpass/BalancesStore';
import ScheduleEntryLocationStore from 'apps/book/stores/xpass/ScheduleEntryLocationStore';
import ServiceTypesStore from 'apps/book/stores/ServiceTypesStore';
import ServiceLocationStore from 'apps/book/stores/xpass/ServiceLocationStore';
import FavoritesStore from 'apps/account/stores/FavoritesStore';
// models
import ScheduleEntry from "apps/book/models/xpass/ScheduleEntry"
import Booking from 'models/Booking';
import UserCoordinates from 'apps/book/models/xpass/UserCoordinates';
import ScheduleEntryLocation from 'apps/book/models/xpass/ScheduleEntryLocation';
import ServiceLocation from 'apps/book/models/xpass/ServiceLocation';
import LocationSummary from 'models/LocationSummary';
// utils
import * as geolib from 'geolib';
import Spinner from 'components/Spinner';
import { Swiper, SwiperSlide } from 'swiper/react';


const slugBrandName = {
  akt: "AKT",
  bft: "BFT",
  clubpilates: "Club Pilates",
  cyclebar: "CycleBar",
  purebarre: "Pure Barre",
  rowhouse: "Row House",
  stretchlab: "StretchLab",
  stride: "Stride Fitness",
  yogasix: "YogaSix",
  kinrgy: 'KINRGY',
}

interface ClassListProps {
    title?: string;
    store: BrandStore;
    hasSort?: boolean;
    scheduleEntryStore: ScheduleEntryStore
    balancesStore: BalancesStore
    scheduleEntries: ScheduleEntry[]
    bookedBookings: Booking[]
    onBookingModal: Function
    isCarousel?: boolean
    handleActiveTab?: Function
    linkedTab?: string
    extraFilters?: Function
    isSingleEntry?: boolean
}

export const ClassList:React.FC<ClassListProps> = ({
  title,
  store,
  hasSort, 
  scheduleEntryStore,
  balancesStore,
  scheduleEntries,
  bookedBookings,
  onBookingModal,
  isCarousel, 
  handleActiveTab,
  linkedTab, 
  extraFilters,
  isSingleEntry
}) => {
  const entries = scheduleEntries!.slice()
  const loader = useRef(null)
  const [listEntries, setListEntries] = useState(entries)
  const [isFetching, setIsFetching] = useState(false)
  const [moreEntries, setMoreEntries] = useState(true)
  const [sortOption, setSortOption] = useState('')
  const { freeClassBookings, freeClassesPurchased, balances } = balancesStore
  const hasFreeClasses = freeClassesPurchased && balances && balances.freeClasses > 0

  const fetchCall = async () => {
    const pageNext = scheduleEntryStore.scheduleEntriesMeta!.pagination!
      .nextPage

    if (moreEntries && pageNext) {
      extraFilters && extraFilters(scheduleEntryStore)
      scheduleEntryStore.setIsPaginated(true)
      scheduleEntryStore.setPage(scheduleEntryStore.page + 1)
      scheduleEntryStore
        .fetchByFilters(true)
        .then(() => {
          const {
            scheduleEntries,
            scheduleEntriesMeta,
          } = scheduleEntryStore
          const nextPage = scheduleEntriesMeta!.pagination!.nextPage
          if (!nextPage) {
            setMoreEntries(false)
          }
          setListEntries((prevState: ScheduleEntry[]) => [
            ...prevState,
            ...scheduleEntries!,
          ])
          setIsFetching(false)
        })
        .catch(err => {
          console.log(err)
          setIsFetching(false)
        })
    }
  }

  useEffect(() => {
    if (!isSingleEntry) {
    const metadata = scheduleEntryStore!.scheduleEntriesMeta!.pagination!
    if (!metadata.nextPage) {
      setMoreEntries(false)
    }

    const observerItem = new IntersectionObserver(
      ([item]) => {
        if (item.isIntersecting) {
          setIsFetching(true)
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    )

    if (loader && loader.current!) {
      observerItem.observe(loader.current!)
    }
    return () => {
      if (loader && loader.current!) {
        observerItem.unobserve(loader.current!)
      }
      }
    } else return
  }, [])

  useEffect(() => {
    if (!isFetching || isCarousel) return
    setTimeout(() => {
      fetchCall()
    }, 2000)
  }, [isFetching])

  useEffect(() => {
    if (hasSort) {
      if (sortOption === 'low-high') {
        setListEntries(entries.sort((a, b) => a.userPremiumCost.raw - b.userPremiumCost.raw))
      }
      if (sortOption === 'high-low') {
        setListEntries(entries.sort((a, b) => b.userPremiumCost.raw - a.userPremiumCost.raw))
      }
      if (sortOption === 'reset') {
        setListEntries(entries)
      }
    }
  })

  const classItems = listEntries.map((entry: ScheduleEntry) => {
    const logoUrl = scheduleEntryStore.getBrandIcon(entry.brand.id)
    const bookedEntry = bookedBookings.find(booking => booking.id === entry.id)
    const bookedLocations = freeClassBookings && freeClassBookings.map(booking => booking.scheduleEntry.location.id)
    const isFree = entry instanceof ScheduleEntry && hasFreeClasses && entry.isXpassFree && bookedLocations
    ? !bookedLocations.includes(entry.location.id)
    : false
    const locationId = store!.userStore.session!.locationId
    return {logoUrl, bookedEntry, isFree, entry, onBookingModal, locationId}
  })
  const hasPremiumPaidClasses = listEntries.find((entry) => entry.isPremiumPaid)
  return (
    <>
      {!isCarousel ? (
        <div className="class-list">
            <div className={`class-list__top-content d-flex justify-content-end`}>
                {title && <h2>{title}</h2>}
                {/* {hasSort && <div className="class-list__sort">Sort By <Svg name="caret-down" size={15}/></div>} */}
                {hasSort && (
                  <div className={`class-list__sort ${sortOption}`}>
                    <select defaultValue="" onChange={(e) => setSortOption(e.target.value)}>
                      <option value="low-high">Price: Low-high</option>
                      <option value="high-low">Price: High-low</option>
                      <option value="reset">{sortOption === 'reset' ? 'Sort By' : 'Reset'}</option>
                      <option value="" disabled hidden>Sort By</option>
                    </select>
                  </div>
                )}
            </div>
            <div className="class-list__items d-flex flex-column align-items-center">
                {classItems && classItems.map((item) => <ClassListItem key={item.entry.id} {...item} />)}
            </div>
            {isSingleEntry && (
              <div className="d-flex justify-content-center mt-5">
                <button className="xpass-cta xpass-cta__reversed xpass-cta-large shuffle-btn" onClick={() => scheduleEntryStore.fetchByFilters()}>Shuffle Again</button>
              </div>
            )}
            <div ref={loader}>
              {moreEntries && (
                <div className="my-5">
                  {isFetching && <Spinner size="element" />}
                </div>
              )}
          </div>
          {hasPremiumPaidClasses && <p className="paid-premium mt-5 mb-0">* You have a cash balance equal to this amount</p>}
        </div>
      ) 
      : handleActiveTab && linkedTab && classItems.length > 0 && title && (
          <EntriesCarousel title={title} classItems={classItems} linkedTab={linkedTab} handleActiveTab={handleActiveTab} hasPremiumPaidClasses={!!hasPremiumPaidClasses}/>
      )
      }
    </>
  )
}


interface StudioListProps {
  title?: string;
  brandStore: BrandStore
  scheduleEntryLocationStore: ScheduleEntryLocationStore
  scheduleEntryStore: ScheduleEntryStore
  favoriteLocationsStore: FavoritesStore<typeof LocationSummary>
  userCoordinates: UserCoordinates | undefined
  isFavoritesTab?: boolean
  isCarousel?: boolean
  handleActiveTab?: Function
  linkedTab?: string
}

export const StudioList:React.FC<StudioListProps> = observer(({
  title, 
  brandStore,
  scheduleEntryStore,
  scheduleEntryLocationStore,
  favoriteLocationsStore,
  userCoordinates,
  isFavoritesTab = false,
  isCarousel,
  handleActiveTab,
  linkedTab
}) => {
   
  const { scheduleEntryLocations, optedInLocations } = scheduleEntryLocationStore
  const locations = isFavoritesTab
  ? scheduleEntryLocations.filter(
      loc => favoriteLocationsStore.favorites.find(item => item.id === loc.slug)
    )
  : optedInLocations

  const userLongLat = { latitude: userCoordinates!.lat, longitude: userCoordinates!.lng }
  
  const studioItems:StudioItem[] = locations.map((location: ScheduleEntryLocation) => {
    const logoUrl = scheduleEntryStore.getBrandIcon(location.brandSlug)
    const title = `${slugBrandName[location.brandSlug] || ''} ${location.name}`
    const path = `/book/${brandStore.userStore.session!.locationId}/location/${location.slug}`
    const distance = Math.round(geolib.getDistance(userLongLat, { latitude: location.lat, longitude: location.lng }) * 0.00621371) / 10
    const isFavoriteStudio = favoriteLocationsStore.favorites.find(item => item.id === location.slug) !== undefined
    const toggleFavorite = favoriteLocationsStore.toggle
    return {logoUrl, title, path, distance, isFavoriteStudio, location, toggleFavorite}
  })

  return (
    <>
    {!isCarousel && locations.length > 0 ? (
    <div className="class-list">
        <div className="class-list__top-content d-flex justify-content-start">
            {title && <h2>{title}</h2>}
        </div>
        <div className="class-list__items d-flex flex-column align-items-center">
            {studioItems.map((studio) => <StudioListItem key={studio.location.slug} scheduleEntryStore={scheduleEntryStore} {...studio} />)}
        </div>
    </div>
    )  : handleActiveTab && linkedTab && studioItems.length > 0 && title && (
      <EntriesCarousel title={title} studioItems={studioItems} linkedTab={linkedTab} handleActiveTab={handleActiveTab}/>
      )
    }
    {isFavoritesTab && !isCarousel && locations.length === 0 && (
      <div className="d-flex flex-column align-items-center pt-4">
        <h3>You haven’t favorited any studios yet</h3>
        <p>Explore studios near you to find what you love the most!</p>
        {handleActiveTab && (
          <button className="xpass-cta xpass-cta-large" onClick={() => handleActiveTab("studios")}>Explore Studios</button>
        )}
      </div>
    )}
    </>
  )
})

interface AppointmentListProps {
  title?: string;
  store: BrandStore
  serviceLocationStore: ServiceLocationStore
  serviceLocations: ServiceLocation[]
  scheduleEntryStore: ScheduleEntryStore
  scheduleEntryLocationStore: ScheduleEntryLocationStore
  serviceTypesStore: ServiceTypesStore
}

export const AppointmentList = observer(({
  store,
  title,
  serviceLocations,
  serviceLocationStore,
  scheduleEntryStore,
  scheduleEntryLocationStore,
  serviceTypesStore
}: AppointmentListProps) => {
  return (
    <div className="appointment-list">
      <div className="appointment-list__top-content d-flex justify-content-start mb-4">
        {title && <h2>{title}</h2>}
      </div>
      <div className="appointment-list__items d-flex flex-column align-items-center">
      {serviceLocations.map((serviceLocation, index) => {
        return (
          <APILoader
            key={index}
            apiStore={serviceLocationStore}
            render={() => (
              <AppointmentListItem
                store={store}
                scheduleEntryStore={scheduleEntryStore}
                scheduleEntryLocationStore={scheduleEntryLocationStore}
                serviceTypesStore={serviceTypesStore}
                serviceLocation={serviceLocation}
              />
            )}
          />
        )
      })}
      </div>
    </div>
  )
})

interface Props {
  title: string;
  classItems?: ClassItem[];
  onBookingModal?: Function;
  studioItems?: StudioItem[];
  handleActiveTab: Function;
  linkedTab: string;
  hasPremiumPaidClasses?: boolean;
}

export default function EntriesCarousel({classItems, studioItems, title, handleActiveTab, linkedTab, hasPremiumPaidClasses}: Props) {
  if (classItems && classItems.length > 10) {
      classItems.length = 10
  }
  if (studioItems && studioItems.length > 10) {
      studioItems.length = 10
  }

return (
  <div className="class-list-carousel__container position-relative">
      <div className="class-list-carousel__top-content d-flex justify-content-between">
          <h2>{title}</h2>
          <a href="javascript:void(0)" onClick={() => handleActiveTab(linkedTab)}>View All</a>
      </div>
      <Swiper
          slidesPerView="auto"
          spaceBetween={20}
          keyboard={{
          enabled: true
          }}
          className="swiper-gradient mySwiper class-list-carousel"
          >
        <SwiperButton direction="prev" />
        <SwiperButton direction="next" />
        {classItems && classItems.map((item) => <SwiperSlide key={item.entry.id}><ClassListItem isCarousel {...item} /></SwiperSlide>)}
        {studioItems && studioItems.map((item) => <SwiperSlide key={item.location.slug}><StudioListItem isCarousel {...item} /></SwiperSlide>)}
      </Swiper>
      {classItems && hasPremiumPaidClasses && <p className="paid-premium mt-2 mb-0">* You have a cash balance equal to this amount</p>}
  </div>
)
}