import React, {useEffect} from 'react'
// media assets
import logoUrl from "images/logos/xpass-logo-dark.svg"
import heroPhoneUrl from "images/xpass/landing_page/hero-phone.png"
import milestonesUrl from "images/xpass/landing_page/walk-to-win.png"
import carUrl from "images/xpass/landing_page/car.png"
import streakUrl from "images/xpass/landing_page/achievements.png"
import streakUrlMobile from "images/xpass/landing_page/achievements-mobile.png"
import profileScreenUrl from "images/xpass/landing_page/profile-screen-phone.png"
import phoneWithShadowUrl from "images/xpass/landing_page/phone-with-shadow.png"
import appStoreUrl from "images/xpass/landing_page/app-store-button.png"
// constants
const APP_LINK = "https://apps.apple.com/us/app/xpass-walk-to-win/id1542112136" 

export default function XpassAppLP() {
  useEffect(() => {
    document.body.classList.add("xpass-lp")
    return () => {
      document.body.classList.remove("xpass-lp")
    }
  })
  return (
    <div>
      <nav className="xpass-lp__nav">
        <div className="container">
          <div className="xpass-lp__nav__logo">
            <img src={logoUrl} alt="Xpass logo" />
          </div>
        </div>
      </nav>
      <section className="xpass-lp__hero">
        <div className="container">
          <div className="xpass-lp__content xpass-lp__hero-content">
            <div className="xpass-lp__hero-logo">
              <img src={logoUrl} alt="xpass logo" />
            </div>
            <header className="xpass-lp__heading xpass-lp__hero-title">Daily Fitness:<br/> Rewarded.</header>
            <DownloadButton />
            <div className="xpass-lp__hero-illustration">
              <img src={heroPhoneUrl} alt="Phone with XPASS app" />
            </div>
          </div>
        </div>
      </section>
      <section className="xpass-lp__milestones">
        <div className="container">
          <div className="xpass-lp__content xpass-lp__milestones-content">
            <div className="xpass-lp__milestones-title">
              <header className="xpass-lp__heading xpass-lp__milestones-heading">Earn rewards through consistency.</header>
              <p className="xpass-lp__copy xpass-lp__milestones-subheading">Unlock exclusive rewards by walking 5K steps a day or taking your favorite fitness class.</p>
            </div>
            <div className="xpass-lp__milestones-illustration">
              <img src={milestonesUrl} alt="Xpass app with Walk to Win milestones screen" />
            </div>
          </div>
        </div>
      </section>
      <section className="xpass-lp__win-a-car">
        <div className="container">
          <div className="xpass-lp__content xpass-lp__win-a-car-content">
            <div className="xpass-lp__win-a-car__media-bg">
              <div className="xpass-lp__win-a-car__info">
                <header className="xpass-lp__heading xpass-lp__win-a-car-heading">Win a Porsche* and Drive Your Fitness Goals Forward!</header>
                <p className="xpass-lp__copy xpass-lp__win-a-car-subheading">Compete to Win the Princess Medallion Porsche Taycan and join the Fitness Revolution.</p>
                <DownloadButton />
              </div>
            </div>
            <div className="xpass-lp__win-a-car__illustration">
              <img  src={carUrl} alt="Car illustration" />
              <p>Princess Medallion Porsche Taycan</p>
            </div>
          </div>
        </div>
      </section>
      <section className="xpass-lp__streak">
        <div className="container">
          <div className="xpass-lp__content xpass-lp__streak-content">
            <div className="xpass-lp__streak__info">
              <header className="xpass-lp__heading xpass-lp__streak-heading">The longer your streak, <br/>the bigger your rewards.</header>
              <p className="xpass-lp__copy xpass-lp__streak-subheading">Each badge you earn unlocks discounts for major rewards and points toward fitness classes.</p>
              <DownloadButton />
            </div>
            <div className="xpass-lp__streak__illustration">
              <img className="xpass-lp__streak__illustration-img" src={streakUrl} alt="Achievements streak" />
              <img className="xpass-lp__streak__illustration-img-mobile" src={streakUrlMobile} alt="Achievements streak" />
            </div>
          </div>
        </div>
      </section>
      <section className="xpass-lp__claim">
        <div className="container">
          <div className="xpass-lp__content xpass-lp__claim-content">
            <div className="xpass-lp__claim__media">
              <img src={profileScreenUrl} alt="Phone with Xpass app profile" />
            </div>
            <div className="xpass-lp__claim__info">
              <header className="xpass-lp__heading xpass-lp__claim-heading">Claim your username and unlock your player’s card!</header>
              <p className="xpass-lp__copy xpass-lp__claim-subheading">Sign up to claim your username and unlock your player’s card, where you can show off your favorite badges and rewards and track your fitness achievements in one place</p>
              <DownloadButton />
            </div>
          </div>
        </div>
      </section>
      <section className="xpass-lp__download">
        <div className="container">
          <div className="xpass-lp__content xpass-lp__download-content">
            <div className="xpass-lp__download__info">
              <header className="xpass-lp__heading xpass-lp__download-heading">
                You Deserve It <br/>
                Because You Earned It.<br/>
                Download XPASS <br/>
                Today.
              </header>
              <a className="xpass-lp__download__button" href={APP_LINK} target="_blank">
                <img src={appStoreUrl} alt="Download on the App Store" />
              </a>
            </div>
            <div className="xpass-lp__download__media">
              <img src={phoneWithShadowUrl} alt="Phone with Xpass app" />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

function DownloadButton(){
  return (
    <a className="btn btn-primary xpass-lp__cta" href={APP_LINK} target="_blank">Download the app &gt;</a>
  )
}