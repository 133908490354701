import * as React from "react"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import { RouteComponentProps, Redirect } from "react-router"
import AuthLocationsLoader from "apps/auth/components/AuthLocationsLoader"

export interface Props extends RouteComponentProps<{ offerSlot: string }> {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class OfferSlotController extends React.Component<Props> {
  public render() {
    if (this.props.store!.locStore.currentLocation) {
      return (
        <Redirect
          to={`/offers/slot/${this.props.match.params.offerSlot}/${
            this.props.store!.locStore.currentLocationId
          }`}
        />
      )
    } else {
      // this will update the path so the return value doesn't matter
      return <AuthLocationsLoader render={() => ""} />
    }
  }
}
