import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosError } from "axios"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import { observable, action, computed } from "mobx"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"
import ClassType from "models/ClassType"
import BrandStore from "stores/BrandStore"

export default class ClassTypesStore extends APIStore {
  @observable classTypes: ClassType[] = []

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    MemoizeMiddleware,
    DeserializeMiddleware("class_types", ClassType),
  ])

  constructor(public brandStore: BrandStore) {
    super()
  }

  fetch() {
    return this.api.get(`/api/brands/${this.brandStore.brandId}/class_types`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<{ class_types: ClassType[] }>) {
    this.classTypes = res.data.class_types
    return res
  }
}
