import * as React from "react"
import { observer, inject } from "mobx-react"
import { RouteComponentProps, Switch, Route } from "react-router"
import { getLocal } from "utils/LocalStorage"
import getIn from "lodash/get"

import LocationSummary from "models/LocationSummary"
import BookingInfoStore from "apps/buy/stores/BookingInfoStore"
import PackagesStore from "apps/buy/stores/PackagesStore"
import PackagesList from "apps/buy/components/PackagesList"
import PaymentPage from "apps/buy/components/PaymentPage"
import PaymentV2Page from "apps/buy/components/PaymentV2Page"
import APILoader from "components/wrappers/APILoader"
import Breadcrumbs from "components/Breadcrumbs"
import { parse } from "helpers/queryString"
import BrandStore from "stores/BrandStore"
import { observable } from "mobx"
import CrossClubRoadblockStore from "apps/buy/stores/CrossClubRoadblockStore"
import BlockerPage from "components/BlockerPage"

export interface Props extends RouteComponentProps {
  store?: BrandStore
}

// TODO: Figure out how to use routing state properly
// w/o having to declare these special types
interface RoutingState {
  forceBackURL?: string
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class PurchaseFlowV2Controller extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.store!.isXponential) this.props.store!.routingStore.history.push('/')
    this.props.store!.uiStore.hideNavLinks()
    this.props.store!.uiStore.lockLocationPicker()
  }

  componentWillUnmount() {
    this.props.store!.uiStore.showNavLinks()
    this.props.store!.uiStore.unlockLocationPicker()
  }

  componentDidUpdate(prevProps: Props) {
    const oldQuery = parse(prevProps.location.search)
    const newQuery = parse(this.props.location.search)
    const oldSid = oldQuery.schedule_entry_id
    const sid = newQuery.schedule_entry_id
    const oldDurationId = oldQuery.durationId
    const durationId = newQuery.durationId
  }

  public render() {
    const path = this.props.match.path

    const routingState = (this.props.location.state || {}) as RoutingState
    const { forceBackURL } = routingState

    return (
      <>
        <Switch>
          <Route
            path={`${path}/:packageId`}
            exact
            render={paymentPageProps => {
              return (
                <PaymentV2Page
                  packageId={paymentPageProps.match.params.packageId}
                />
              )
            }}
          />
        </Switch>
      </>
    )
  }
}
