import * as React from "react"
import cx from "classnames"
import { inject, observer } from "mobx-react"
import { AxiosError } from "axios"
import * as Sentry from "@sentry/browser"

import Svg from "components/Svg"
import BrandStore from "stores/BrandStore"
import LocationSummary from "models/LocationSummary"

type SvgProps = React.HTMLProps<SVGSVGElement>

interface Props extends Pick<SvgProps, Exclude<keyof SvgProps, "size">> {
  store?: BrandStore
  type: "class" | "instructor" | "location" | "seat"
  id: string
  size?: string
  wrapperClass?: string
  setRenderKey?: Function
  favoriteLocations?: LocationSummary[]
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class FavoriteHeart extends React.Component<Props> {
  get type() {
    switch (this.props.type) {
      case "class":
        return "favoriteClassCategoriesStore"
      case "instructor":
        return "favoriteInstructorsStore"
      case "location":
        return "favoriteLocationsStore"
      case "seat":
        return "favoriteSeatsStore"
    }
  }

  handleClick = (id: string) => (e: React.MouseEvent) => {
    e.stopPropagation()
    const { [this.type]: favoritesStore } = this.props.store!.userStore

    favoritesStore.toggle(id).then(() => {
      if (this.props.setRenderKey) {
        this.props.setRenderKey()
      }
    }).catch((ex: AxiosError) => {
      if (ex.response!.status === 400) {
        Sentry.captureException(ex)
        console.error(ex)

        this.props.store!.uiStore.openMessage(
          "error",
          ex.response!.data.message,
          "Something went wrong"
        )
      } else {
        throw ex
      }
    })
  }

  render() {
    const { id, type, store, wrapperClass, setRenderKey, favoriteLocations, ...props } = this.props
    const favorites =
      store!.isXponential && favoriteLocations
        ? favoriteLocations
        : store!.userStore[this.type].favorites
    const isFavorite = favorites.find(
      favorite => favorite.id === id
    )

    return (
      <span onClick={this.handleClick(id)} className={wrapperClass}>
        <Svg
          {...props}
          className="d-block text-primary cursor-pointer favorite-heart"
          name={isFavorite ? "heart-full" : "heart-empty"}
        />
        {this.props.children}
      </span>
    )
  }
}
