import * as React from "react"
import { observer, inject } from "mobx-react"
import cx from "classnames"

import BrandStore from "stores/BrandStore"
import { Link } from "react-router-dom"
import Heading from "components/Heading"
import RoomMapPanel from "apps/book/components/RoomMapPanel"
import ScheduleEntry from "models/ScheduleEntry"
import Svg from "components/Svg"
import FavoriteHeart from "components/FavoriteHeart"
import Room from "models/Room"
import PageTracker from "components/wrappers/PageTracker"
import ScheduleEntryStore from "../stores/xpass/ScheduleEntryStore"
import UserCoordinatesStore from "../stores/xpass/UserCoordinatesStore"

export interface Props {
  scheduleEntry: ScheduleEntry
  room: Room
  backPath: string
  seatId?: string
  seatLabel?: string
  disabled?: boolean
  store?: BrandStore
  onSubmit(): void
  onCancel?(e: LinkEvent): void
  onChange(seatId: string, seatLabel?: string): void
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class PickSeatPage extends React.Component<Props, {}> {
  isXponential = this.props.store!.isXponential
  userCoordinatesStore = this.isXponential ? new UserCoordinatesStore() : undefined
  scheduleEntryStore = this.userCoordinatesStore ? new ScheduleEntryStore(this.userCoordinatesStore, this.props.store!.userStore) : undefined

  handleSubmit = (e: ButtonEvent) => {
    e.preventDefault()
    this.props.onSubmit()
  }

  seatName = (brand: string) => {
    switch(brand) {
      case "cyclebar":
      case "cyclebar_aus":
        return "Bike"
      default:
        return "Spot"
    }
  }

  public render() {
    const {
      room,
      seatId,
      seatLabel,
      scheduleEntry,
      disabled,
      onChange,
      onCancel,
    } = this.props

    const { brand, brandId, locStore, copy, styleClasses } = this.props.store!
    const isRumble = brandId === "rumble"
    const locationSummary = locStore.currentLocation!
    const seatType = this.isXponential ? this.seatName(scheduleEntry.brand.id) : copy.seat

    const title = this.isXponential ? (
      <>
        <h2 className="mb-0">{scheduleEntry.title}</h2>
        {scheduleEntry.instructor && (
         <p>with {scheduleEntry.instructor!.name}</p>
        )}
      </>
    ) : (
      <h3 className="mb-0">{scheduleEntry.title}</h3>
    )

    return (
      <div className={cx(this.isXponential ? "container my-5" : "")}>
        {(!this.isXponential && !isRumble) && (
          <Heading>Select a {copy.seat}</Heading>
        )}
        <PageTracker name="book > pick seat" />
        <div className={cx("row", styleClasses.PickSeatPage__spotSelection)}>
          <div className="col-lg-3 slide-in delay-1">
            {(this.isXponential || isRumble) && (
              <h1 className="slide-in">
                {copy.bookingSelectHeader.replace("{SPOT}", this.seatName(scheduleEntry.location!.brandId))}
              </h1>
            )}

            {isRumble && (
              <p>{copy.bookingSelectCopy}</p>
            )}

            {this.isXponential ? (
              <>
                {this.scheduleEntryStore && (
                  <div className="mt-4 mb-2" style={{ maxWidth: "48px" }}>
                    <img
                      className="w-100"
                      src={this.scheduleEntryStore.getBrandIcon(
                        scheduleEntry.location!.brandId
                      )}
                      alt={`${scheduleEntry.location!.brandId}-icon`}
                    />
                  </div>
                )}
                {title}
                <p className="mt-3 text-sm">
                  <strong>
                    {scheduleEntry.brand.name} {scheduleEntry.location!.name}
                  </strong>
                  <br />
                  {scheduleEntry.startsAt.format("dddd MMMM Do, YYYY")}
                  <br />
                  {scheduleEntry.startTime} – {scheduleEntry.endTime}
                </p>
              </>
            ) : (
              <>
                {title}
                {scheduleEntry.subtitle && (
                  <p>
                    <strong>{scheduleEntry.subtitle}</strong>
                  </p>
                )}
                <div className="mt-3">
                  <p className={styleClasses.PickSeatPage__locationName}>
                    <strong>
                      {brand.name} {locationSummary.name}
                    </strong>
                  </p>
                  <p>
                    {scheduleEntry.startsAt.format("dddd MMMM Do, YYYY")}
                    <br />
                    {scheduleEntry.startTime} – {scheduleEntry.endTime}
                  </p>
                </div>

                {scheduleEntry.instructor && (
                  <>
                    <h3 className={styleClasses.PickSeatPage__instructorName}>
                      Your {copy.instructor}
                    </h3>
                    <p>{scheduleEntry.instructor.name}</p>
                    {/* TODO: Add instructor picture. */}
                    {/* <p>Instructor picture go here.</p> */}
                  </>
                )}
              </>
            )}
          </div>
          <div className={cx("col-lg-8 offset-lg-1 grid-in")}>
            <RoomMapPanel
              store={this.props.store}
              room={room}
              onSelect={onChange}
              selectedSeat={seatId}
              selectedSeatLabel={seatLabel}
            />
            <div
              className="room-map-legend bg-white w-100 my-5 d-flex justify-content-left align-items-center p-4"
              style={{ height: "53px" }}
            >
              <div className="mr-3 d-inline text-muted">
                <Svg name={styleClasses.PickSeatPage__seat} size="14" /> Available
              </div>
              <div className="mr-3 d-inline text-muted">
                <Svg name={styleClasses.PickSeatPage__seatTaken} size="14" /> Unavailable
              </div>
              {/* TODO: Finish implementing seat favorite */}
              <div className="mr-3 d-inline text-muted">
                {this.isXponential ? (
                  <Svg name="seat-selected-xpass" size="14" />
                ) : (
                  <Svg name={styleClasses.PickSeatPage__seatSelected} size="14" />
                )}{" "}
                Selected
              </div>
              {(seatId && debugUI && !this.isXponential && !isRumble) && (
                <div className="d-inline text-muted ml-auto">
                  <FavoriteHeart
                    type="seat"
                    id={seatId}
                    width="14"
                    height="14"
                  />
                  <Svg name="heart-empty" size="14" className="mr-2" />
                  Save as favorite {this.props.store!.copy.seat}
                </div>
              )}
            </div>

            <div className="text-right mb-5 pb-5">
              <Link
                className="btn btn-secondary mr-2"
                onClick={onCancel}
                to={this.props.backPath}
              >
                Back
              </Link>

              <button
                className="btn btn-primary"
                disabled={!seatId || disabled}
                onClick={this.handleSubmit}
              >
                {copy.bookingSelectButton.replace("{SPOT}", seatType)}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
