import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

import Reward from "apps/classpoints/models/Reward"

interface Response {
  eventRewards: Reward[]
  pointRewards: Reward[]
  featuredReward: Reward
  earnedPoints: Reward[]
  pointValues: Reward[]
}

export default class EarnStore extends APIStore {
  @observable eventRewards: Reward[] = []
  @observable pointRewards: Reward[] = []
  @observable featuredReward: Reward = new Reward
  @observable earnedPoints: Reward[] = []
  @observable pointValues: Reward[] = []

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("event_rewards", Reward),
    DeserializeMiddleware("point_rewards", Reward),
    DeserializeMiddleware("featured_reward", Reward),
    DeserializeMiddleware("earned_points", Reward),
    DeserializeMiddleware("point_values", Reward),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])
  
  constructor(public brandStore: BrandStore) { super() }

  fetch() {
    return this.api.get(`/api/class_points/dashboard/earn`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response | any>) {
    this.eventRewards = res.data.event_rewards
    this.pointRewards = res.data.point_rewards
    this.featuredReward = res.data.featured_reward
    this.earnedPoints = res.data.earned_points
    this.pointValues = res.data.point_values
    return res.data
  }
}
