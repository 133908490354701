import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"

import AbstractStatsStore from "apps/history/stores/AbstractStatsStore"
import WorkoutSummary from "apps/history/models/WorkoutSummary"
import WorkoutClass from "apps/history/models/WorkoutClass"
import { UserSession } from "models/UserSession"

interface Response {
  summary_stats: WorkoutSummary
  class_stats: WorkoutClass[]
  user?: UserSession
}

export default class WorkoutStatsStore extends AbstractStatsStore<Response> {
  type = "workout" as const

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    MemoizeMiddleware,
    DeserializeMiddleware("summary_stats", WorkoutSummary, true),
    DeserializeMiddleware("class_stats", WorkoutClass),
    TokenAuthMiddleware(this.userStore),
  ])

  fetch() {
    return this.api.get(`/api/workout_stats`)
  }
}
