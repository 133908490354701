import * as React from "react"
import { observer } from "mobx-react"
import { InjectedFormikProps, withFormik, Form, FormikBag } from "formik"
import * as yup from "yup"

import BrandStore from "stores/BrandStore"
import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"

export interface BaseProps {
  brandStore?: BrandStore
  handleUpdate(values: FormValues): void
}

export interface FormValues {
  old_password: string
  new_password: string
  new_password_confirmation: string
}

type Props = InjectedFormikProps<BaseProps, FormValues>

class PasswordUpdateInner extends React.Component<Props, {}> {
  render() {
    const { isSubmitting } = this.props

    return (
      <Form noValidate className="float-in delay-3">
        <div className="row">
          <FormInput
            label="Current Password"
            name="old_password"
            className="col required"
            maxLength={128}
            minLength={6}
            type="password"
          />
        </div>
        <div className="row">
          <FormInput
            label="New Password"
            name="new_password"
            className="col required"
            maxLength={128}
            minLength={6}
            type="password"
          />
        </div>
        <div className="row">
          <FormInput
            label="Confirm New Password"
            name="new_password_confirmation"
            className="col required"
            maxLength={128}
            minLength={6}
            type="password"
          />
        </div>
        <FormButtons submitText="Update Password" disabled={isSubmitting} />
      </Form>
    )
  }
}

const schema = yup.object<FormValues>().shape({
  old_password: yup
    .string()
    .required("Please provide your old password for authentication."),
  new_password: yup
    .string()
    .min(6, "Your new password must be at least 6 characters long.")
    .max(128, "Your new password may not be more than 128 characters long.")
    .required("Please select a new password."),
  new_password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref("new_password"), null],
      "Your New Password and Confirmation must match."
    )
    .required("Please repeat your new password for confirmation."),
})

const handleSubmit = async (
  values: FormValues,
  formikBag: FormikBag<BaseProps, FormValues>
) => {
  const { props } = formikBag
  const { uiStore } = props.brandStore!

  try {
    await props.handleUpdate(values)
    uiStore.openMessage("message", "Your password has been updated.", "Success")
    formikBag.resetForm()
  } catch (error) {
    const message =
      (error.response && error.response.data.message) ||
      "Sorry, there was a problem while updating your password. Please try again."

    uiStore.openMessage("error", message, "Error")
    formikBag.setSubmitting(false)
  }
}

export default observer(
  withFormik<BaseProps, FormValues>({
    validationSchema: schema,
    mapPropsToValues: () => ({
      old_password: "",
      new_password: "",
      new_password_confirmation: "",
    }),
    handleSubmit,
  })(PasswordUpdateInner)
)
