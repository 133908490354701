import React, { Component } from "react"
import cx from "classnames"
import Svg from "components/Svg"
import range from "lodash/range"
import { formatNumber } from "helpers/numberFormatter"

interface Props {
  target: number
  now: number
  labelCount: number
  className?: string
  labelFormatter(val: number): string
}

export default class GoalProgressBar extends Component<Props> {
  static defaultProps: Partial<Props> = {
    labelFormatter: formatNumber,
  }

  render() {
    const { target, now, labelCount, labelFormatter } = this.props
    const progress = Math.min((now * 100) / target, 100)
    // would be better to either make these show increments of the progress
    // or just hide them by default
    const labels = range(labelCount).map(i =>
      labelFormatter((i / (labelCount - 1)) * target)
    )

    return (
      <div className={this.props.className}>
        <div className="goal-bar">
          <div className="goal-bar-track">
            <div
              className="goal-bar-progress"
              style={{ width: `${progress}%` }}
            />
          </div>

          <div className="goal-bar-ui">
            <div className="goal-bar-markers">
              {labels.map((l, i) => (
                <Svg key={i} name="progress-step" size="4" />
              ))}
            </div>
            <div className="goal-bar-labels">
              {labels.map((l, i) => (
                <div className="outer" key={i}>
                  <div className="inner">{l}</div>
                </div>
              ))}
            </div>
            <div
              className="goal-bar-scrubber-wrapper"
              style={{ width: `${progress}%` }}
            >
              <div title={now.toString(10)} className="goal-bar-scrubber" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
