import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import APIStore from "stores/APIStore"
import { isApiError, withAxiosError } from "helpers/errorHandling"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import BrandStore from "stores/BrandStore"
import PlanStore from "apps/buy/stores/PlanStore"
import PackagePlan from "models/PackagePlan"
import LocationSummary from "models/LocationSummary"
import Offer from "models/Offer"
import GiftCard from "models/GiftCard"

export interface Response {
  gift_card: GiftCard
}

// mimics a lot from PlanStore and PromoCodeStore
export default class GiftCardStore extends APIStore {
  @observable giftCardNumber?: string
  @observable acctToken?: string
  @observable balance?: number

  @observable errorMessage?: string
  @observable errorCode?: string

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    TokenAuthMiddleware(this.brandStore.userStore),
    DeserializeMiddleware("gift_card", GiftCard),
  ])

  @computed
  get plan() {
    return this.planStore.plan!
  }
  set plan(plan: PackagePlan) {
    this.planStore.plan = plan
  }

  @computed get paymentAmount() {
    if (!this.balance) return 0

    if (this.balance >= this.plan!.todayTotal.numeric) return this.plan!.todayTotal.numeric

    return this.balance
  }

  @computed get todayTotal() {
    return this.plan!.todayTotal.numeric - this.paymentAmount
  }

  constructor(
    public planStore: PlanStore,
    public brandStore: BrandStore,
    public packageId: string,
    public locationSummary: LocationSummary,
    public offer?: Offer,
  ) {
    super()
    this.status = planStore.status
  }

  fetchBalance(giftCardNumber?: string) {
    if (this.planStore.crossClubIneligible) {
      return this.planStore.respondIneligible()
    }

    const url = `/api/locations/${this.locationSummary.id}/gift_cards/${giftCardNumber}`

    return this.api
      .get(url)
      .then(res => {
        // only add once it's been successfully applied
        if (res.data.gift_card.balance > 0) {
          this.giftCardNumber = giftCardNumber
          this.acctToken = res.data.gift_card.acctToken
          this.balance = res.data.gift_card.balance
        } else {
          this.status = "error"
        }
      })
  }

  @action.bound
  addCardNumber(cardNumber: string) {
    return this.fetchBalance(cardNumber).catch(this.handleCardNumberError)
  }

  @action.bound
  removeCardNumber() {
    this.giftCardNumber = undefined
    this.acctToken = undefined
    this.balance = undefined
  }

  @action.bound
  handleCardNumberError(ex: AxiosError) {
    console.log(ex.response)
    if (!isApiError(ex.response)) {
      throw ex
    }
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response>) {
    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    const res = withAxiosError(ex)
    if (res) {
      this.errorCode = res.data.code
      this.errorMessage = res.data.message
    }
    throw ex
  }
}
