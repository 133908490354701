import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("MobilePromo")
export default class MobilePromo {
  @JsonProperty("headline", String, true)
  headline: string = undefined!

  @JsonProperty("description", String, true)
  description?: string = undefined

  @JsonProperty("cta", String, true)
  cta: string = undefined!

  @JsonProperty("cta_deeplink", String, true)
  ctaDeeplink: string = undefined!

  @JsonProperty("background_image_url", String, true)
  backgroundImageUrl: string = undefined!

  @JsonProperty("external_url", String, true)
  externalUrl: string = undefined!

  @JsonProperty("use_deeplink", Boolean, true)
  useDeeplink: boolean = undefined!

  @JsonProperty("web_promo_enabled", Boolean, true)
  webPromoEnabled: boolean = undefined!
}
