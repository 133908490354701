import * as React from "react"
import { observer, inject } from "mobx-react"
import ScheduleEntry from "models/ScheduleEntry"
import { BrandCopy } from "themes/base"
import BookingUpdateStore from "apps/bookings/stores/BookingUpdateStore"
import BrandStore from "stores/BrandStore"
import Booking from "models/Booking"
import { ucfirst } from "helpers/stringHelper"

export interface BookButtonProps {
  entry: ScheduleEntry
  copy: BrandCopy
  store?: BrandStore
  className?: string
  onClick(e: ButtonEvent): void
}

// might make sense to combine the disabled logic into this
export function buttonText(entry: ScheduleEntry, copy: BrandCopy, isFrozen?: boolean) {
  if (entry.isOver) return `${ucfirst(copy.class)} Over`
  if (entry.booking && entry.booking.status === "booked") {
    return `Booked`
  }
  if (entry.tooLate) return `Booking Closed`
  if (entry.booking && entry.booking.status === "waitlisted") {
    if (entry.tooLateToWait && !entry.isFull) {
      // TODO: this copy is used to determine functionality.
      // Don't change it, or better yet, refactor the below so it isn't
      // anymore.
      return `Book from Waitlist`
    } else {
      const { waitlistPosition } = entry.booking
      return waitlistPosition ? `Waitlisted #${waitlistPosition}` : "Waitlisted"
    }
  }
  if (entry.tooEarly) return `Too Early`
  if (isFrozen) return `Account Frozen`
  if (entry.isFull) {
    if (entry.canWaitlist) return `Join Waitlist`
    else return `${ucfirst(copy.class)} Full`
  }
  return copy.bookClass
}

const BookButton: React.SFC<BookButtonProps> = 
  ({ entry, onClick, copy, store, className }) => {
    const { isFrozen } = store!.userStore.session!
    const text = buttonText(entry, copy, isFrozen)

    const disabled =
      entry.isBusy ||
      (entry.notBookable &&
        (!entry.booking ||
          !entry.booking.canBookFromWaitlist ||
          text !== "Book from Waitlist")) ||
        isFrozen

    // TODO: this is terrible. instead what we should be doing is anytime they
    // hit the booking flow and are eligible to book from waitlist, they
    // should get a modal offering them that option.
    if (text === "Book from Waitlist" && entry.booking) {
      const onClickProp = onClick
      onClick = (e: ButtonEvent) => {
        e.preventDefault()
        entry.isBusy = true

        const updateStore = new BookingUpdateStore(
          store!,
          entry.booking as Booking
        )
        updateStore.cancel().then(() => {
          updateStore.dispose()
          onClickProp(e)
        })
      }
    }

    return (
      <button
        type="button"
        className={`btn btn-primary text-nowrap ${className}`}
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
      >
        {text}
      </button>
    )
  }

export default inject((store: BrandStore) => ({ store }))(observer(BookButton))
