import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("ServiceDuration")
export default class ServiceDuration {
  /**
   * Uniquely identifies the service/duration combo. Used to get details about availability and to book. ClubReady `sessionSizeId`.
   */
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * Duration of the service in minutes. ClubReady `durationInMinutes`.
   */
  @JsonProperty("duration_minutes", Number)
  durationMinutes: number = undefined!
}
