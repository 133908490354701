import React, { Component } from "react"
import * as cx from "classnames"

import BrandStore from "stores/BrandStore"
import { observer, inject } from "mobx-react"
import { observable } from "mobx"
import { saveState, loadState, clearState } from "services/savedState"

interface RememberMeProps {
  store?: BrandStore
  // TODO: why are these optional? also why are they props?
  rememberKey?: string // Local storage key
  emailKey?: string // Local storage key
  className?: string
}

class RememberMe extends Component<RememberMeProps> {
  @observable checked: boolean = false
  email = ""

  componentDidMount() {
    this.checked = !!loadState(this.props.rememberKey!)
    this.email = loadState(this.props.emailKey!)
  }

  handleChange = (e: InputEvent) => {
    this.checked = e.currentTarget.checked
    if (this.checked) {
      saveState(this.props.rememberKey!, true)
      saveState(this.props.emailKey!, this.email || "")
    } else {
      clearState(this.props.rememberKey!)
      clearState(this.props.emailKey!)
    }
  }

  render() {
    return (
      <label className={cx("text-left", this.props.className)}>
        <span className="mr-2">
          <input
            type="checkbox"
            checked={this.checked}
            onChange={this.handleChange}
          />
        </span>
        <small>Remember Me</small>
      </label>
    )
  }
}

export default inject((store: BrandStore) => ({ store }))(observer(RememberMe))
