import { JsonObject, JsonProperty } from "json2typescript"
import { FormFieldDefaults } from "utils/FormFields"

@JsonObject("UserProfile")
export default class UserProfile {
  /**
   * Email is used as the username in Xponential.
   */
  // @JsonProperty("email", String)
  // email: string = undefined!

  @JsonProperty("first_name", String)
  firstName: string = undefined!

  @JsonProperty("last_name", String)
  lastName: string = undefined!

  @JsonProperty("address", String, true)
  address?: string = undefined!

  /**
   * Optional second line of street address.
   */
  @JsonProperty("address2", String, true)
  address2?: string = undefined!

  @JsonProperty("city", String, true)
  city?: string = undefined!

  @JsonProperty("state", String, true)
  state?: string = undefined!

  @JsonProperty("zip", String, true)
  zip?: string = undefined!

  /**
   * This should generally be their cell.
   */
  @JsonProperty("phone_main", String, true)
  phoneMain?: string = undefined!

  @JsonProperty("phone_home", String, true)
  phoneHome?: string = undefined!

  @JsonProperty("phone_work", String, true)
  phoneWork?: string = undefined!

  /**
   * Weight in pounds
   */
  @JsonProperty("weight", Number, true)
  weight?: number = undefined!

  /**
   * Height in _inches_
   */
  @JsonProperty("height", Number, true)
  height?: number = undefined!

  @JsonProperty("birth_date", String, true)
  birthDate?: string = undefined

  @JsonProperty("gender", String, true)
  gender?: string = undefined!
  /**
   * Emergency contact name.
   */
  @JsonProperty("emergency_name", String, true)
  emergencyName?: string = undefined!

  /**
   * Emergency contact phone number.
   */
  @JsonProperty("emergency_phone", String, true)
  emergencyPhone?: string = undefined!

  /**
   * **Cyclebar**: whether they need to rent cycling shoes. More relevant on a
   * per-class basis than as a profile setting.
   */
  @JsonProperty("need_shoes", Boolean, true)
  needShoes?: boolean = undefined!

  /**
   * **Cyclebar**: their shoe size. Only relevant if they need shoes.
   */
  @JsonProperty("shoe_size", String, true)
  shoeSize?: string = undefined!

  /**
   * Their leaderboard username, should they wish to appear on the leaderboard.
   */
  @JsonProperty("screen_name", String, true)
  screenName?: string = undefined!

  /**
   * Whether to show their username on the in-class performance leaderboard.
   */
  @JsonProperty("hide_metrics", Boolean, true)
  hideMetrics?: boolean = undefined!

  /**
   * Whether or not the user has opted into classpoints
   */
  @JsonProperty("class_points_opted_in", Boolean, true)
  classPointsOptedIn?: boolean = undefined!
}

type ProfileFields = Omit<UserProfile, "forAmplitude">

// TODO: this is all so gross I want it to go away
export const userProfileFields: FormFieldDefaults<ProfileFields> = {
  firstName: "",
  lastName: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  phoneMain: "",
  phoneHome: "",
  phoneWork: "",
  weight: ("" as unknown) as number,
  height: ("" as unknown) as number,
  birthDate: "",
  gender: "",
  emergencyName: "",
  emergencyPhone: "",
  needShoes: false,
  shoeSize: "",
  screenName: "",
  hideMetrics: false,
  classPointsOptedIn: false,
}
