import visaIcon from "images/payment_cards/credit_cards/visa.png"
import mastercardIcon from "images/payment_cards/credit_cards/mastercard.png"
import amexIcon from "images/payment_cards/credit_cards/amex.png"
import discoverIcon from "images/payment_cards/credit_cards/discover.png"

const ccIcons: [string, string][] = [
  ["visa", visaIcon],
  ["mastercard", mastercardIcon],
  ["amex", amexIcon],
  ["discover", discoverIcon],
]

export default ccIcons
