import * as React from "react"
import cx from "classnames"
import { InjectedFormikProps, withFormik, Form } from "formik"
import * as yup from "yup"
import { observer, inject } from "mobx-react"
import getIn from "lodash/get"
import ReactTooltip from "react-tooltip"

import BrandStore from "stores/BrandStore"
import PaymentSource from "models/PaymentSource"
import FormInput from "components/forms/FormInput"
import PaymentSourceRequest from "apps/account/models/PaymentSourceRequest"
import PaymentSourceUpdateStore from "apps/account/stores/PaymentSourceUpdateStore"

import ccIcons from "helpers/ccIcons"
import FormButtons from "components/forms/FormButtons"
import FormAlert from "components/forms/FormAlert"
import PaymentSourcesStore from "../stores/PaymentSourcesStore"
import { nameValidator } from "helpers/validations"
import FormCheck from "react-bootstrap/FormCheck"
import { observable } from "mobx"

const isDefaultSource = (paymentSourcesStore?: PaymentSourcesStore) => {
  const { isDefaultSource: defaultSource, isNewSource, paymentSources } = paymentSourcesStore!
  return isNewSource ||
    (defaultSource &&
      paymentSources &&
      getIn(paymentSources[0], "type") === 'card')
}

// TODO: humanize field names in error messages

export interface Props {
  store?: BrandStore
  paymentSourceUpdateStore: PaymentSourceUpdateStore
  paymentSourcesStore?: PaymentSourcesStore
  paymentSource?: PaymentSource
  isPaymentPage: boolean
  onCancel(): void
}

type FormValues = PaymentSourceRequest

@observer
class InnerForm extends React.Component<
  InjectedFormikProps<Props, FormValues>
> {
  @observable isDefault =
    (this.props.paymentSource && this.props.paymentSource.isDefault) || false

  handleCancel = (e: ButtonEvent) => {
    e.preventDefault()
    this.props.onCancel()
  }

  handleCheckbox = () => {
    this.isDefault = !this.isDefault
    this.props.setFieldValue("isDefault", this.isDefault)
  }

  formatCcNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.replace(/[^\d]/g, "")
    const ccNumberPartial = [
      value.slice(0, 4),
      value.slice(4, 8),
      value.slice(8, 12),
      value.slice(12, 16),
    ].filter(v => v)

    this.props.setFieldValue("ccNumber", ccNumberPartial.join(" "))
  }

  formatNumbersOnly = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.setFieldValue(
      e.currentTarget.name,
      e.currentTarget.value.replace(/[^\d]/g, "")
    )

  public render() {
    const { touched, errors, isSubmitting } = this.props
    return (
      <Form
        noValidate
        className={cx({
          "col-md-10 col-lg-8 col-xl-6": !this.props.isPaymentPage,
          "col-md-11 col-xl-8": this.props.isPaymentPage,
        })}
      >
        <FormAlert message={errors.base!} />

        <div className="row">
          <div className="col mb-3 text-left">
            {ccIcons.map(([slug, src]) => (
              <img
                src={src}
                key={slug}
                alt={slug}
                width="48"
                height="30"
                className="mr-2 mb-2"
              />
            ))}
          </div>
        </div>

        <div className="row">
          <FormInput
            label="Payer First Name"
            name="firstName"
            autoComplete="cc-given-name"
            className="col-lg-6"
            maxLength={50}
          />
          <FormInput
            label="Payer Last Name"
            name="lastName"
            autoComplete="cc-family-name"
            className="col-lg-6"
            maxLength={50}
          />
        </div>

        <div className="row">
          <FormInput
            label="Credit / Debit Card Number"
            placeholder="XXXX XXXX XXXX XXXX"
            name="ccNumber"
            autoComplete="cc-number"
            className="col-lg-12"
            onChange={this.formatCcNumber}
            maxLength={20}
          />
        </div>

        <div className="row cc-details">
          <FormInput
            label="Expiration Month"
            name="ccExpMonth"
            autoComplete="cc-exp-month"
            placeholder="MM"
            className="col-lg-4"
            onChange={this.formatNumbersOnly}
            maxLength={2}
          />
          <FormInput
            label="Expiration Year"
            name="ccExpYear"
            autoComplete="cc-exp-year"
            placeholder="YYYY"
            className="col-lg-4"
            onChange={this.formatNumbersOnly}
            maxLength={4}
          />
          <FormInput
            label="Security Code"
            name="ccSecurityCode"
            autoComplete="cc-csc"
            placeholder="XXX"
            className="col-lg-4"
            onChange={this.formatNumbersOnly}
            maxLength={4}
            tooltipId="cvv-tooltip"
          />
          <ReactTooltip
            id="cvv-tooltip"
            type="info"
            effect="solid"
            overridePosition={({ left, top }, _e, _t, node) => {
              return {
                top,
                left: typeof node === "string" ? left : Math.max(left, 0),
              }
            }}
            resizeHide={false}
          >
            <span className="tooltip-message">
              The security code is a 3-digit number on the back of your card
            </span>
          </ReactTooltip>
        </div>

        <div className="row">
          <FormInput
            label="Postal Code"
            name="zip"
            autoComplete="billing postal-code"
            className="col-lg-6"
            maxLength={10}
          />
        </div>
        <div className="d-md-flex align-items-center">
          <FormButtons
            submitText="Save"
            onCancel={this.props.onCancel}
            disabled={isSubmitting}
            showCancelButton={true}
          />
          {!this.props.isPaymentPage && !this.props.store!.isXponential ? (
            <div className="pl-md-4 pl-lg-5 pt-3">
              <FormCheck
                type="checkbox"
                label="Make Default Payment Method"
                className="font-weight-bold"
                checked={isDefaultSource(this.props.paymentSourcesStore) || this.isDefault}
                disabled={isDefaultSource(this.props.paymentSourcesStore)}
                onChange={this.handleCheckbox}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Form>
    )
  }
}

const schema = yup.object<FormValues>().shape({
  isDefault: yup.boolean(),
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  ccNumber: yup
    .string()
    .label("Credit / Debit Card Number")
    .required(),
  ccSecurityCode: yup
    .number()
    .required()
    .label("Security Code"),
  ccExpMonth: yup
    .number()
    .required()
    .label("Expiration Month")
    .min(1)
    .max(12),
  ccExpYear: yup
    .number()
    .required()
    .label("Expiration Year")
    .min(new Date().getFullYear()),
  zip: yup
    .string()
    .required()
    .label("Postal Code"),
})

const BillingForm = inject((store: BrandStore) => ({ store }))(
  observer(
    withFormik<Props & { store?: BrandStore }, FormValues>({
      mapPropsToValues: ({ store, paymentSource, paymentSourcesStore }) => {
        const source = paymentSource
        const user = store!.userStore.session!

        return {
          isDefault: getIn(source, ["isDefault"], false) ||
            isDefaultSource(paymentSourcesStore) ||
            false,
          firstName: getIn(source, ["firstName"], user.firstName || ""),
          lastName: getIn(source, ["lastName"], user.lastName || ""),
          ccNumber: "",
          ccSecurityCode: "",
          ccExpMonth: getIn(source, ["ccExpMonth"], "") || "",
          ccExpYear: getIn(source, ["ccExpYear"], "") || "",
          zip: getIn(source, ["zip"], "") || "",
          countryCode: getIn(source, ["countryCode"], store!.settings.billingCountryCode) || store!.settings.billingCountryCode,
          type: "card",
        }
      },
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: schema,
      handleSubmit: (values, formikBag) => {
        const { paymentSourceUpdateStore, onCancel, store } = formikBag.props

        paymentSourceUpdateStore
          .update(values)
          .then(res => {
            formikBag.setSubmitting(false)
            if (res.data.errors) {
              formikBag.setErrors(res.data.errors)
              store!.track.event("add new credit card_failure")
            } else {
              onCancel()
              store!.track.event("add new credit card_success")
            }
            return res
          })
          .catch(ex => {
            formikBag.setSubmitting(false)
            formikBag.props.store!.uiStore.openError(ex)
            store!.track.event("add new credit card_failure")
            throw ex
          })
        store!.track.event("purchase_add new credit card")
      },
    })(InnerForm)
  )
)

export default BillingForm
