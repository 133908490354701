import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("Balances")
export default class Balances {

  @JsonProperty("class_token_count", Number)
  classTokenCount: number = undefined!

  @JsonProperty("purchased_token_count", Number)
  purchasedTokenCount: number = undefined!

  @JsonProperty("free_classes", Number)
  freeClasses: number = undefined!
}
