import * as React from "react"
import * as cx from "classnames"
import CountUp from "react-countup"
import GoalProgressBar from "apps/dashboard/GoalProgressBar"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import { easeInCubic } from "helpers/easings"

interface Props {
  store?: BrandStore
  progress: number
  className?: string
  progressBarClassName?: string
}

const BadgeProgressPanel: React.FunctionComponent<Props> = ({
  store,
  progress,
  className,
  progressBarClassName,
}) => {
  const nextBadge = store!.nextBadgeInfo(progress)
  if (!nextBadge) {
    return (
      <div className="py-3">
        <div className="pb-2"><small>No data available</small></div>
        <GoalProgressBar
          target={0}
          now={0}
          className={"goal-bar-disabled"}
          labelCount={0}
        />
      </div>
    )
  }

  const { progressPercent, badge, formatter } = nextBadge
  const labelCount: number = badge.value > 1 ? 3 : 2
  return (
    <>
      <div className={className}>
        <CountUp end={progressPercent} duration={1.25} easingFn={easeInCubic} />
        % to goal of {badge.label}
      </div>
      {/* this is probably pretty fragile */}

      <GoalProgressBar
        target={badge.value}
        now={progress}
        className={progressBarClassName}
        labelFormatter={formatter}
        labelCount={labelCount}
      />
      <p className="text-sm mt-2">
        You're making great progress towards your goal!
      </p>
    </>
  )
}

export default inject((store: BrandStore) => ({ store }))(
  observer(BadgeProgressPanel)
)
