import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import UserStore from "stores/UserStore"
import Deal from "models/xpass/Deal"
import ClassToken from "models/xpass/ClassToken"

interface Response {
  class_token: ClassToken
  deal: Deal
}


export default class RewardsStore extends APIStore {
  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("deal", Deal),
    DeserializeMiddleware("class_token", ClassToken),
    TokenAuthMiddleware(this.userStore),
  ])

  @observable deal?: Deal
  @observable classToken?: ClassToken

  constructor(protected userStore: UserStore) {
    super()
  }

  @action
  fetch() {
    return this.api.get(`/api/xpass/dashboard/rewards`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response>) {
    this.deal = res.data.deal
    this.classToken = res.data.class_token
    return res
  }
}
