import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosError } from "axios"
import { action, observable, computed } from "mobx"

import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import BrandStore from "stores/BrandStore"
import PaymentSource from "models/PaymentSource"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"

export interface PaymentSourceResponse {
  payment_sources?: PaymentSource[]
}

export default class PaymentSourceStore extends APIStore {
  @observable paymentSources?: PaymentSource[]
  @observable hasPaymentSource?: boolean

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    // TODO: will this work right with a 204?
    DeserializeMiddleware("payment_sources", PaymentSource),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  @computed get isNewSource() {
    return this.paymentSources!.length === 0
  }

  @computed get isDefaultSource() {
    return this.paymentSources!.length < 2
  }

  constructor(public brandStore: BrandStore) {
    super()
  }

  fetch() {
    return this.api.get(`/api/v2/payment_sources`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<PaymentSourceResponse>) {
    this.paymentSources = res.data.payment_sources
    this.hasPaymentSource = true
    return res
  }

  @action.bound
  handleError(res: AxiosError) {
    if (res.response && res.response.status === 404) {
      // this just means they don't have a payment source
      this.paymentSources = []
      this.hasPaymentSource = false
      return res.response
    }
    throw res
  }
}
