import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("WorkoutSummary")
export default class WorkoutSummary {
  @JsonProperty("avg_power", Number, true)
  avgPower: number = undefined!

  @JsonProperty("avg_pace", Number, true)
  avgPace: number = undefined!

  @JsonProperty("high_pace", Number, true)
  highPace: number = undefined!

  @JsonProperty("avg_rpm", Number, true)
  avgRpm: number = undefined!

  @JsonProperty("total_energy", Number, true)
  totalEnergy: number = undefined!

  @JsonProperty("avg_hr", Number, true)
  avgHr: number = undefined!

  @JsonProperty("avg_hr_percent", Number, true)
  avgHrPercent: number = undefined!

  @JsonProperty("max_hr", Number, true)
  maxHr: number = undefined!

  @JsonProperty("distance", Number, true)
  distance: number = undefined!

  @JsonProperty("calories", Number, true)
  calories: number = undefined!

  @JsonProperty("active_min", Number, true)
  activeMin: number = undefined!

  @JsonProperty("iq_points", Number, true)
  iqPoints: number = undefined!

  @JsonProperty("zones", [Number], true)
  zones: [number, number, number, number, number] = undefined!
}
