import React, { useState } from "react";
import Svg from "components/Svg";
import { useSwiper } from "swiper/react";

interface Props {
    direction: "prev" | "next";
    isNav?: boolean;
}

export default function SwiperButton({direction, isNav} : Props) {
  const [isBtnVisible, setIsBtnVisible] = useState(direction === 'next')
  const swiper = useSwiper()

  swiper.on('reachEnd', (e) => {
    e.el.classList.remove('swiper-gradient')
    direction === 'next' && setIsBtnVisible(false)
  })
  swiper.on('reachBeginning', () => {
    direction === 'prev' && setIsBtnVisible(false)
  })
  swiper.on('fromEdge', (e) => {
    e.el.classList.add('swiper-gradient')
    direction === 'prev' && !e.isBeginning && setIsBtnVisible(true)
    direction === 'next' && !e.isEnd && setIsBtnVisible(true)
  })

  return isBtnVisible
  ? (
    <div onClick={(e) => {
      e.stopPropagation()
      direction === "next" ? swiper.slideNext() : swiper.slidePrev()
    }}
      className={`${isNav ? "subnav__carousel-btn" : "class-list__carousel-btn" } ${direction}`}>
      <Svg name={direction === "next" ? "caret-right" : "caret-left" } size="18" />
    </div>
  ) : null
}