export function easeInSine(t: number, b: number, c: number, d: number) {
  return -c * Math.cos((t / d) * (Math.PI / 2)) + c + b
}

export function easeInOutCubic(t: number, b: number, c: number, d: number) {
  if ((t /= d / 2) < 1) return (c / 2) * t * t * t + b
  return (c / 2) * ((t -= 2) * t * t + 2) + b
}
export function easeInCubic(t: number, b: number, c: number, d: number) {
  return c * (t /= d) * t * t + b
}
export function easeInQuart(t: number, b: number, c: number, d: number) {
  return c * (t /= d) * t * t * t + b
}
export function easeInCirc(t: number, b: number, c: number, d: number) {
  return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b
}
export function easeInExpo(t: number, b: number, c: number, d: number) {
  return t == 0 ? b : c * Math.pow(2, 10 * (t / d - 1)) + b
}
