import * as React from "react"
import cx from "classnames"
import { observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import GoalSetter from "./GoalSetter"
import GoalProgressBar from "./GoalProgressBar"
import APILoader from "components/wrappers/APILoader"

interface Props {
  className?: string
  store: BrandStore
}

@observer
class AttendanceCard extends React.Component<Props> {
  handleSetGoalClick = (e: LinkEvent) => {
    e.preventDefault()

    this.props.store!.uiStore.openModal({
      // title: `Set or Edit Your Goal`,
      children: (
        <GoalSetter
          store={this.props.store}
          goalStore={this.props.store.userStore.attendanceGoalStore}
        />
      ),
      modalShow: true,
      size: "md",
    })
  }

  render() {
    const { userStore, styles, styleClasses, copy, brand } = this.props.store
    const { attendanceGoalStore } = userStore

    return (
      <div className={cx("card", this.props.className)}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="text-dark">{ copy.myWeeklyGoal }</h5>
          {attendanceGoalStore.goal && (
            <small>
              <a href="#set-goal" onClick={this.handleSetGoalClick}>
                {attendanceGoalStore.goal.target <= 0 ? "Set" : "Update"} Goal
              </a>
            </small>
          )}
        </div>
        <APILoader
          apiStore={attendanceGoalStore}
          stayLoaded={!!userStore.attendanceGoalStore.goal}
          elementSize="element"
          propagateErrors
          render={() => {
            const { progress, target } = attendanceGoalStore.goal!
            const progressDisplayText =
              progress > target ? progress : `${progress}/${target}`

            return (
              <div className="card-body d-flex flex-column">
                {target <= 0 && (
                  <>
                    <div className="d-flex align-items-start">
                      <img
                        className="mr-4"
                        src={styles.badgeImage}
                        alt={`${brand.name} Badge`}
                        style={{ maxWidth: "32px", maxHeight: "42px" }}
                      />

                      <div className="flex-grow">
                        <h4>Set Up Your Weekly Goal</h4>
                        <p>{copy.attendanceCard}</p>
                      </div>
                    </div>
                    <a
                      className="btn btn-primary mt-auto"
                      href="#set-goal"
                      onClick={this.handleSetGoalClick}
                    >
                      Set Weekly Goal
                    </a>
                  </>
                )}

                {target > 0 && (
                  <>
                    <h5>Keep it up!</h5>

                    <p className="card-text mb-2">
                      {`You've ${copy.attended} ${progress} ${
                        progress !== 1 ? copy.classes : copy.class
                      } this week`}
                    </p>

                    <div className="mt-auto">
                      <div className="d-flex align-items-center mb-2">
                        <img
                          className="mr-3 col-2 p-1"
                          src={styles.badgeImage}
                          alt={`${brand.name} Badge`}
                          style={{ height: "50%" }}
                        />
                        <h1 className={cx("mb-1 d-inline", styleClasses.AttendanceCard__progressHeader)}>{progressDisplayText}</h1>
                      </div>

                      <GoalProgressBar
                        className="mt-3"
                        target={target}
                        now={progress}
                        labelCount={target + 1}
                      />
                    </div>
                  </>
                )}
              </div>
            )
          }}
        />
      </div>
    )
  }
}

export default AttendanceCard
