import { JsonObject, JsonProperty } from "json2typescript"
import moment, { Moment } from "moment-timezone"
import DateConverter from "utils/DateConverter"
import { computed } from "mobx"
import ScheduleEntryBase from "models/ScheduleEntryBase"

import markerPin from "images/markers/pin.png"
import aktPin from "images/xpass/markers/active-selected/akt.png"
import bftPin from "images/xpass/markers/active-selected/bft.png"
import clubpilatesPin from "images/xpass/markers/active-selected/clubpilates.png"
import cyclebarPin from "images/xpass/markers/active-selected/cyclebar.png"
import purebarrePin from "images/xpass/markers/active-selected/purebarre.png"
import rowhousePin from "images/xpass/markers/active-selected/rowhouse.png"
import rumblePin from "images/xpass/markers/active-selected/rumble.png"
import stretchlabPin from "images/xpass/markers/active-selected/stretchlab.png"
import stridePin from "images/xpass/markers/active-selected/stride.png"
import yogasixPin from "images/xpass/markers/active-selected/yogasix.png"
import kinrgyPin from "images/xpass/markers/active-selected/kinrgy.png"
import { Location } from "./Location"

@JsonObject("Brand")
export class Brand {
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!
}

@JsonObject("Instructor")
export class Instructor {
  @JsonProperty("id", String, true)
  id?: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("clubready_id", String, true)
  clubreadyId: string = undefined!

  @JsonProperty("headshot_url", String, true)
  headshotUrl?: string = undefined!

  @JsonProperty("hero_url", String, true)
  heroUrl?: string = undefined!
}

@JsonObject("UserPremiumCost")
export class UserPremiumCost {
  @JsonProperty("formatted", String)
  formatted: string = undefined!

  @JsonProperty("numeric", Number)
  numeric: number = undefined!

  @JsonProperty("currency_code", String)
  currencyCode: string = undefined!

  @JsonProperty("raw", Number)
  raw: number = undefined!

}

@JsonObject("ScheduleEntry")
export default class ScheduleEntry extends ScheduleEntryBase {
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("title", String)
  title: string = undefined!

  @JsonProperty("alt_title", String, true)
  altTitle?: string = undefined!

  @JsonProperty("starts_at", DateConverter)
  startsAtUtc: Moment = undefined!

  @JsonProperty("ends_at", DateConverter)
  endsAtUtc: Moment = undefined!

  @JsonProperty("booking_opens_at", DateConverter)
  bookingOpensAt: Moment = undefined!

  @JsonProperty("booking_closes_at", DateConverter)
  bookingClosesAt: Moment = undefined!

  @JsonProperty("check_in_from", DateConverter, true)
  checkInFrom?: Moment = undefined!

  @JsonProperty("check_in_until", DateConverter, true)
  checkInUntil?: Moment = undefined!

  @JsonProperty("duration", Number)
  duration: number = undefined!

  @JsonProperty("clubready_id", String)
  clubreadyId: string = undefined!

  @JsonProperty("clubready_name", String)
  clubreadyName: string = undefined!

  @JsonProperty("piq_id", String)
  piqId: string = undefined!

  @JsonProperty("is_xpass_free", Boolean, true)
  isXpassFree: boolean = undefined!

  @JsonProperty("is_xpass_dynamic", Boolean, true)
  isXpassDynamic: boolean = undefined!

  @JsonProperty("description", String)
  description: string = undefined!

  @JsonProperty("cancellation_policy", String, true)
  cancellationPolicy: string = undefined!

  @JsonProperty("class_image", String, true)
  classImage: string = undefined!

  @JsonProperty("location", Location)
  location: Location = undefined!

  @JsonProperty("brand", Brand)
  brand: Brand = undefined!

  @JsonProperty("instructor", Instructor)
  instructor: Instructor = undefined!

  // @JsonProperty("location_id", String, true)
  // locationId: string = undefined!

  // @JsonProperty("clubready_class_type_id", String, true)
  // clubreadyClassTypeId: string = undefined!

  @JsonProperty("xpass_eligible", Boolean, true)
  xpassEligible: boolean = undefined!

  @JsonProperty("has_valid_token", Boolean, true)
  hasValidToken: boolean = undefined!

  @JsonProperty("user_premium_cost", UserPremiumCost)
  userPremiumCost: UserPremiumCost = undefined!

  @JsonProperty("is_premium_paid", Boolean, true)
  isPremiumPaid?: boolean = undefined!

  @JsonProperty("target_package_id", String, true)
  targetPackageId: string = undefined!


  @computed get mapPin() {
    const images = [
      { imageUrl: aktPin, brandId: "akt" },
      { imageUrl: bftPin, brandId: "bft" },
      { imageUrl: clubpilatesPin, brandId: "clubpilates" },
      { imageUrl: cyclebarPin, brandId: "cyclebar" },
      { imageUrl: purebarrePin, brandId: "purebarre" },
      { imageUrl: rowhousePin, brandId: "rowhouse" },
      { imageUrl: rumblePin, brandId: "rumble" },
      { imageUrl: stretchlabPin, brandId: "stretchlab" },
      { imageUrl: stridePin, brandId: "stride" },
      { imageUrl: yogasixPin, brandId: "yogasix" },
      { imageUrl: kinrgyPin, brandId: "kinrgy" },
      { imageUrl: markerPin, brandId: "default" },
    ]
    return images.find((img) => img.brandId === this.brand.id)
  }

  @computed get startsAt() {
    return moment.tz(this.startsAtUtc, this.location.timezone)
  }

  @computed get endsAt() {
    return moment.tz(this.endsAtUtc, this.location.timezone)
  }

  /**
   * Include below properties used in non-XPASS ScheduleEntry.ts model so that we can reuse booking flow components for XPASS
   */
  get tooEarly() {
    return this.bookingOpensAt > moment()
  }
  get tooLate() {
    return this.bookingClosesAt < moment() || this.startsAt < moment()
  }
  get inFuture() {
    return this.startsAt > moment()
  }
  get isOver() {
    return this.endsAt < moment()
  }
  get canCheckIn() {
    return this.checkInFrom && this.checkInFrom <= moment()
  }

  // XPASS does not have a waitlist option
  get waitlistUntil() {
    return null
  }
  get canWaitlist() {
    return false
  }
  get waitlistSize() {
    return undefined
  }
  get tooLateToWait() {
    return true
  }

  // Indicates whether the class is attended in-person or virtually.
  get locationType() {
    return null
  }
  // ID of the room this class will take place in.
  get roomId() {
    return null
  }
  // The maximum size of the class. Use in conjunction with `free_spots` to determine if the class is full.
  get capacity() {
    return undefined
  }
  // The number of open spots left.
  get freeSpots() {
    return undefined
  }

  // type: "schedule" | "roster" | "booking" | "waitlist" = undefined!
  get type() {
    return "booking"
  }

  get freeCancelUntil() {
    return null
  }

  get freeCancelable() {
    return null
  }

  get startsAtDate() {
    return this.startsAt.format("ddd, MMM Do, YYYY")
  }

  @computed get timeRange() {
    return `${this.startsAt.format("ddd, MMM Do, YYYY")} • ${this.startsAt.format("h:mma")}-${this.endsAt.format("h:mma")}`
  }

  @computed get startTime() {
    return this.startsAt.format("h:mma")
  }
  @computed get endTime() {
    return this.endsAt.format("h:mma")
  }
  @computed get startTimeTZ() {
    return this.startsAt.format("h:mma z")
  }
  @computed get timezone() {
    return this.startsAt.format("z")
  }

  @computed get dateString() {
    return this.startsAt.format("YYYY-MM-DD")
  }

  @computed get isFull() {
    if (this.freeSpots == null) return undefined
    return this.freeSpots === 0
  }

  @computed get bookingStatusCode() {
    if (this.tooEarly) return "early" as const
    if (this.tooLate) return "late" as const
    if (this.isFull && !this.canWaitlist) return "full" as const
    if (this.isBooked) return "booked" as const
    return undefined
  }

  @computed get tooEarlyToBook() {
    return moment(this.startsAt, "YYYY-MM-DD").isAfter(moment().add(7, "days").format("YYYY-MM-DD"))
  }

  @computed get notBookable() {
    return (
      this.tooEarly ||
      this.tooLate ||
      this.isBooked ||
      (this.isFull && !this.canWaitlist) ||
      this.isBusy
    )
  }

  @computed get totalSpots() {
    return this.capacity
  }

  @computed get spotsString() {
    if (this.freeSpots === 0) return "Fully Booked"
    if (this.freeSpots === 1) return "1 Spot Open"
    if (this.freeSpots) return `${this.freeSpots} Spots Open`
    return ""
  }
}

@JsonObject("Pagination")
export class Pagination {
  @JsonProperty("current_page", Number)
  currentPage: number = undefined!

  @JsonProperty("next_page", Number)
  nextPage: number = undefined!

  @JsonProperty("per_page", Number)
  perPage: number = undefined!

  @JsonProperty("prev_page", Number)
  prevPage: number = undefined!

  @JsonProperty("total_count", Number)
  totalCount: number = undefined!

  @JsonProperty("total_pages", Number)
  totalPages: number = undefined!
}

@JsonObject("ScheduleEntryMeta")
export class ScheduleEntryMeta {
  @JsonProperty("pagination", Pagination)
  pagination?: Pagination = undefined!
}
