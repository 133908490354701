import { JsonObject, JsonProperty } from "json2typescript"
import ServiceDuration from "apps/book/models/ServiceDuration"
import moment, { Moment } from "moment"
import * as momenttz from "moment-timezone"
import DateConverter from "utils/DateConverter"
import InstructorSummary from "models/InstructorSummary"
import LocationSummary from "models/LocationSummary"
import { computed, observable } from "mobx"
import { formatTime } from "helpers/dateHelper"
import { UserPremiumCost } from "./xpass/ScheduleEntry"

@JsonObject("ServiceScheduleEntry")
export default class ServiceScheduleEntry {
  @JsonProperty("id", String, true)
  id: string = undefined!

  /**
   * Calculated from ClubReady `availabilityStartUtc`, `durationInMinutes`, etc.
   */
  @observable
  @JsonProperty("starts_at", DateConverter)
  startsAt: Moment = undefined!

  /**
   * ClubReady `availabilityEndUtc`, `durationInMinutes`, etc
   */
  @observable
  @JsonProperty("ends_at", DateConverter)
  endsAt: Moment = undefined!

  /**
   * time before which bookings can't be made
   */
  @observable
  @JsonProperty("booking_opens_at", DateConverter, true)
  bookingOpensAt: Moment = undefined!

  /**
   * time after which bookings can't be made
   */
  @observable
  @JsonProperty("booking_closes_at", DateConverter, true)
  bookingClosesAt: Moment = undefined!

  /**
   * time until which the user can cancel w/o penalty
   */
  @observable
  @JsonProperty("free_cancel_until", DateConverter)
  freeCancelUntil: Moment = undefined!

  /**
   * XPO ID of the instructor. This is optional, just in case there's an instructor who we don't have in our system.
   */
  @JsonProperty("instructor_id", String, true)
  instructorId?: string = undefined!

  /**
   * CR ID of the instructor
   */
  @JsonProperty("clubready_instructor_id", String)
  clubreadyInstructorId: string = undefined!

  @JsonProperty("service_duration", ServiceDuration)
  serviceDuration: ServiceDuration = undefined!

  @JsonProperty("check_in_from", DateConverter, true)
  checkInFrom?: Moment = undefined

  /**
   * XPASS properties
   */
  @JsonProperty("brand_id", String, true)
  brandId?: string = undefined!

  @JsonProperty("brand_name", String, true)
  brandName?: string = undefined!

  @JsonProperty("location_id", String, true)
  locationId?: string = undefined

  @JsonProperty("location_name", String, true)
  locationName?: string = undefined!

  @JsonProperty("is_xpass_free", Boolean, true)
  isXpassFree?: boolean = undefined!

  @JsonProperty("xpass_eligible", Boolean, true)
  xpassEligible?: boolean = undefined!

  @JsonProperty("user_premium_cost", UserPremiumCost, true)
  userPremiumCost?: UserPremiumCost = undefined!

  @JsonProperty("is_premium_paid", Boolean, true)
  isPremiumPaid?: boolean = undefined!

  @JsonProperty("has_valid_token", Boolean, true)
  hasValidToken?: boolean = undefined!

  @JsonProperty("target_package_id", String, true)
  targetPackageId?: string = undefined!

  // always set this when building an SA
  instructor?: InstructorSummary

  // always set this when displaying bookings
  location?: LocationSummary

  // always set this when displaying bookings
  title?: String

  // always set this when displaying bookings
  subtitle?: String

  @observable isBusy?: boolean

  get key() {
    return `${this.instructorId}-${this.startsAt.toString()}-${
      this.serviceDuration.id
    }`
  }

  @computed
  get dateString() {
    return this.startsAt.format("YYYY-MM-DD")
  }

  @computed
  get startTime() {
    return formatTime(this.startsAt)
  }

  @computed
  get endTime() {
    return formatTime(this.endsAt)
  }

  @computed
  get startTimeTZ() {
    return this.location && this.location.timezone
      ? momenttz.tz(this.startsAt, this.location.timezone).format("h:mma z")
      : this.startTime
  }

  @computed
  get timezone() {
    return this.location && this.location.timezone
      ? momenttz.tz(this.startsAt, this.location.timezone).format("z")
      : ""
  }

  @computed
  get canCheckIn() {
    return Boolean(this.checkInFrom && this.checkInFrom <= moment())
  }

  get tooEarly() {
    return this.bookingOpensAt > moment()
  }

  get tooLate() {
    return this.bookingClosesAt < moment() || this.startsAt < moment()
  }

  get inFuture() {
    return this.startsAt > moment()
  }

  get isOver() {
    return this.endsAt < moment()
  }

  @computed get notBookable() {
    return this.tooEarly || this.tooLate || this.isBusy
  }

  // for compatability in certain displays
  get classCategory() {
    return undefined
  }
}
