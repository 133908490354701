import React, { Component, useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"
import { Form, withFormik, InjectedFormikProps } from "formik"
import { observable } from "mobx"
import { inject, observer } from "mobx-react"
import * as yup from "yup"

import BrandStore from "stores/BrandStore"
import UserCoordinatesStore from "apps/book/stores/xpass/UserCoordinatesStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"
import UserClaimsStore from "apps/account/stores/xpass/UserClaimsStore"
import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"
import FormAlert from "components/forms/FormAlert"
import UserLookup, { Users } from "apps/auth/models/xpass/UserLookup"
import thirdPartyScript from "helpers/thirdPartyScript"
import Spinner from "components/Spinner"

export interface Props {
  store?: BrandStore
  userAuthStore: UserAuthStore
  scheduleEntryStore?: ScheduleEntryStore
  userClaimsStore?: UserClaimsStore
  handleClick: Function
  claimStatus?: string
}

// ===========================
// Claim Accounts Status
// ===========================
const ClaimStatus = (props: Props) => {
  const claimSuccess = props.claimStatus === "claim_success"
  const slugBrandName = {
    'akt': 'AKT',
    'bft': 'BFT',
    'clubpilates': 'Club Pilates',
    'cyclebar': 'Cycle Bar',
    'purebarre': 'Pure Barre',
    'rowhouse': 'Row House',
    'rumble': 'Rumble',
    'stretchlab': 'StretchLab',
    'stride': 'Stride',
    'yogasix': 'YogaSix',
    'kinrgy': 'KINRGY',
  }

  // For when user is directed to claim accounts page from dashboard and claims brand location account
  const { brandLocationId, allowRedirect, brandRedirectDomain } = props.userAuthStore
  const brandLocationClaimed = brandLocationId && props.userClaimsStore && props.userClaimsStore.userClaims.find(account => account.locationId === brandLocationId)
  const brandName = brandLocationId && slugBrandName[brandLocationId!.split("-")[0]]

  const handleClick = () => {
    props.handleClick()
  }

  const title = claimSuccess
    ? brandLocationClaimed
      ? "Account(s) Linked"
      : "Success"
    : "Error"
  const message = claimSuccess
    ? brandLocationClaimed
      ? `Your accounts have been successfully linked. You can now login to any of your linked ${brandName} studios using your XPASS Login.`
      : "Your accounts have been claimed!"
    : "There was an error claiming your accounts. Please try again."
  const buttonText = brandLocationClaimed && allowRedirect ? `Return to ${brandName}` : "Finish"

  return (
    <div className="claim-account__modal text-center my-4">
      <div className="mb-4">
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
      <button className="btn btn-primary d-block mx-auto" onClick={handleClick}>
        {buttonText}
      </button>

      {brandLocationClaimed && allowRedirect && (
        <div className="mt-3">
          <span className="link light-link" onClick={() => location.reload()}>
            Stay in XPASS
          </span>
        </div>
      )}
    </div>
  )
}

// ===========================
// Claim Accounts
// ===========================
const ClaimAccount = (props: Props) => {
  const { userAccounts, verifiedAccounts } = props.userAuthStore
  const count = userAccounts && userAccounts.count
  const matchedAccounts = userAccounts && userAccounts.users
  const [hasVerifiedAccounts, setHasVerifiedAccounts] = useState(false)
  const [checkedAccounts, setCheckedAccounts] = useState(verifiedAccounts)
  const [hasCheckedAccounts, setHasCheckedAccounts] = useState(true)
  const [isChecking, setIsChecking] = useState(false)

  useEffect(() => {
    const { activeEmail, verifiedEmails } = props.userAuthStore
    if (verifiedEmails.some((email: string) => email === activeEmail)) {
      setHasVerifiedAccounts(true)
    }
  })

  const handleCheckbox = (e: any, account: Users) => {
    const checked = [...checkedAccounts]
    if (e.target.checked) {
      if (!hasCheckedAccounts) {
        setHasCheckedAccounts(true)
      }
      checked.push(account)
    } else {
      const index = checked.findIndex(item => item.id === account.id)
      checked.splice(index, 1)
    }
    setCheckedAccounts(checked)
  }

  const handleVerify = () => {
    const email = props.userAuthStore.activeEmail

    props.userAuthStore.verifyEmail(email)
    props.handleClick("verify_email")
  }

  const handleClaim = () => {

    const accounts = checkedAccounts.map(account => account.id)
    const { brandLocationId } = props.userAuthStore

    if (props.userClaimsStore) {
      if (accounts.length > 0) {
        setIsChecking(true)
        props.userClaimsStore.create(accounts, brandLocationId!).then(res => {
          if (res.status === 200) {
            props.handleClick("claim_success")
          } else {
            props.handleClick("claim_failure")
          }
        })
      } else {
        setHasCheckedAccounts(false)
      }
    }
  }

  // Matching Accounts
  let title = "Matching Accounts Found"
  let copy = (
    <>
      <p className="my-0 mx-4">
        We found{" "}
        <span className="bold">
          {count === 1 ? `${count} studio account` : `${count} studio accounts`}{" "}
        </span>
        associated with this email address.
      </p>
      <p className="my-0 mx-4">Please verify your email address to continue.</p>
    </>
  )
  let accountsList = matchedAccounts

  // Claim Accounts
  if (hasVerifiedAccounts) {
    title = "Claim Accounts"
    copy = (
      <p className="my-0 mx-4">
        Check or uncheck the accounts you would like to claim and combine into
        your XPASS account.
      </p>
    )
    accountsList = props.userAuthStore.verifiedAccounts!
  }

  return (
    <div className="claim-account text-center my-4 pb-0">
      <h2 className="mb-3">{title}</h2>
      <div>{copy}</div>

      <div className="claim-account__list mx-0 mx-md-3">
        {accountsList &&
          accountsList.map((account: Users, index: number) => {
            return (
              <div
                className="claim-account__list-item d-flex px-3 px-md-4"
                key={index}
              >
                {props.scheduleEntryStore && (
                  <div>
                    <img
                      className="w-100"
                      src={props.scheduleEntryStore.getBrandIcon(
                        account.brandSlug
                      )}
                      alt={`${account.brandSlug}-icon`}
                    />
                  </div>
                )}

                <div className="text-left ml-3 ml-md-4 pr-5 pr-md-0 mr-5">
                  <h3 className="text-auto">
                    {account.brandName} {account.locationName}
                  </h3>
                  <p className="m-0">{account.email}</p>
                </div>

                {hasVerifiedAccounts && (
                  <div className="claim-account__checkbox">
                    <input
                      type="checkbox"
                      className={`claim-account__checkbox-item ${
                        checkedAccounts!.some(
                          (item: Users) => item.id === account.id
                        )
                          ? "checked"
                          : ""
                      }`}
                      checked={checkedAccounts!.some(
                        (item: Users) => item.id === account.id
                      )}
                      onChange={e => handleCheckbox(e, account)}
                    />
                    <div className="checkbox" />
                  </div>
                )}
              </div>
            )
          })}

        {!hasCheckedAccounts && <p className="alert alert-danger">No accounts have been selected</p>}
      </div>

      { isChecking
        ? <Spinner size={"element"} />
        : (
          <button
            className="btn btn-primary d-block mx-auto mt-4"
            onClick={hasVerifiedAccounts ? handleClaim : handleVerify}
          >
            {hasVerifiedAccounts ? "Claim Accounts" : "Send Verification Email"}
          </button>
        )
      }
    </div>
  )
}

// ===========================
// Verify Accounts
// ===========================
class VerifyAccountFormInner extends Component<
  InjectedFormikProps<Props, { verificationCode: string }>
> {
  handleResend = () => {
    const email = this.props.userAuthStore.activeEmail
    this.props.userAuthStore.verifyEmail(email)
  }

  render() {
    const { errors, isSubmitting } = this.props
    const base = "base"

    return (
      <div className="my-4">
        <h2 className="text-center">Verification Code</h2>
        <p className="text-center my-0 mx-4">
          We sent a 6-digit code to the email you provided. Enter it here to
          verify your account.
        </p>

        <Form noValidate className="mt-4 pt-2 col-10 mx-auto">
          <FormAlert message={errors[base]} classNames="my-2" />
          <FormInput
            className="mb-1"
            placeholder="Enter code"
            name="verificationCode"
            type=""
          />
           <div className="text-center mt-3">
            <small>
              <span className="link light-link" onClick={this.handleResend}>
                Resend Code
              </span>
            </small>
          </div>
          <FormButtons
            submitText="Verify Account"
            disabled={isSubmitting}
            centered
            className="float-in delay-4 pt-1 d-flex justify-content-center"
          />
        </Form>
      </div>
    )
  }
}

const VerifyAccountForm = withFormik<Props, { verificationCode: string }>({
  mapPropsToValues: () => ({
    verificationCode: "",
  }),
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: yup.object<{ verificationCode: string }>().shape({
    verificationCode: yup
      .string()
      .required()
      .min(6, "Verification code must be 6 characters.")
      .max(6, "Verification code must be 6 characters.")
      .label("Verification Code"),
  }),
  handleSubmit: (values, formikBag) => {
    const { locStore, track } = formikBag.props.store!
    const loc = locStore.currentLocation

    formikBag.props.userAuthStore
      .verifyCode(values.verificationCode, formikBag.props.userAuthStore.activeEmail)
      .then(res => {
        if (res.success) {
          const { activeEmail } = formikBag.props.userAuthStore
          const matchedAccounts = formikBag.props.userAuthStore.userAccounts!
          .users

          formikBag.props.userAuthStore.setVerifiedEmails([activeEmail])
          formikBag.props.userAuthStore.setVerifiedAccounts(matchedAccounts)
          formikBag.props.handleClick("verify_success")

          track.event(
            "verification_success",
            { loc },
            { cta: "verify account" }
          )
        } else {
          const message = `${res.error}. Please try again.`
          formikBag.setSubmitting(false)
          formikBag.setFieldError("base", message)
          track.event(
            "verification_failure",
            { loc },
            { cta: "verify account" }
          )
        }
      })
      .catch(err => {
        formikBag.setSubmitting(false)
        if (err.response && err.response.data) {
          formikBag.setFieldError("base", err.response.data.message)
        }
        formikBag.setFieldError(
          "base",
          "Invalid verification code. Please try again."
        )
        track.event("verification_failure", { loc }, { cta: "verify account" })
      })
  },
})(VerifyAccountFormInner)


/**
 * @name ClaimAccountsPurchase
 * @type Component
 */

export interface ClaimAccountsPurchaseProps {
  store?: BrandStore
}
interface ClaimAccountsPurchaseState {
  displayLoading: boolean,
  isSkipped: boolean
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ClaimAccountsPurchase extends Component<
  ClaimAccountsPurchaseProps,
  ClaimAccountsPurchaseState
> {
  userCoordinatesStore = new UserCoordinatesStore()
  scheduleEntryStore = new ScheduleEntryStore(this.userCoordinatesStore, this.props.store!.userStore)
  userAuthStore = new UserAuthStore()
  userClaimsStore = new UserClaimsStore(this.props.store!)
  @observable initialClaimableAccounts?: UserLookup

  @observable status: string = ""

  // State
  state = {
    displayLoading: false,
    isSkipped: false
  }

  componentDidMount() {
    const { email } = this.props.store!.userStore.session!
    this.userAuthStore.setActiveEmail(email)
    this.handleEmailCheck(email)
    thirdPartyScript({
      id: "sleeknoteScript",
      src: "//sleeknotecustomerscripts.sleeknote.com/56834.js",
      charset: "utf-8",
    })
  }

  componentWillUnmount() {
    this.userAuthStore.dispose()
  }

  handleClick = (status: string) => {
    this.status = status
  }

  handleSkip = () => {
    this.setState({
      isSkipped: true
    })
  }

  handleEmailCheck = (tmpEmail:string) => {
    this.setState({
      displayLoading: true
    })
     this.userAuthStore
       .search(tmpEmail)
       .then(res => {
         if (res.status === 200) {
           if (res.data.user_lookups.count > 0) {
             this.handleClick("valid_email")
           } else {
             // No matching or unclaimed accounts found under this email
              this.handleSkip()
           }

         } else {
           // There was an error. Please try again.
           this.handleSkip()
         }

         this.setState({
          displayLoading: false
        })
       })
       .catch(err => {
         // There was an error. Please try again.
         this.setState({
          displayLoading: false
        })
        this.handleSkip()
       })
   }

   handleProgress = () => {
    switch (this.status) {
      case "":
        return (
          <div>Loading...</div>
        )
      case "valid_email":
      case "verify_success":
        return (
          <ClaimAccount
            store={this.props.store}
            userAuthStore={this.userAuthStore}
            scheduleEntryStore={this.scheduleEntryStore}
            userClaimsStore={this.userClaimsStore}
            handleClick={this.handleClick}
          />
        )
      case "verify_email":
        return (
          <VerifyAccountForm
            store={this.props.store!}
            userAuthStore={this.userAuthStore}
            handleClick={this.handleClick}
          />
        )
      case "claim_success":
      case "claim_failure":
        return (
          <ClaimStatus
            store={this.props.store!}
            userAuthStore={this.userAuthStore}
            userClaimsStore={this.userClaimsStore}
            handleClick={this.handleSkip}
            claimStatus={this.status}
          />
        )
      default:
        return <></>
    }
  }


  render() {
    return (
      <div className="flex flex-column align-items-center">
        {
          this.state.isSkipped === false
          ? (
            <>
              <h2 className="claim-accounts-header">Congrats on your purchase!</h2>
              <p>Now let's setup your account</p>
              {
                this.state.displayLoading === true ? (
                  <Spinner size="element" />
                ) : (
                  <div className="border-light-basic">
                    { this.handleProgress() }
                    {
                      this.status !== "" && this.status !== "claim_success" && this.status !== "claim_failure"
                      ? (
                        <div className="text-center">
                          <a
                            className="btn"
                            href="#"
                            onClick={()=>this.handleSkip()}
                          >
                              Skip &amp; Finish
                          </a>
                        </div>
                        )
                      : null
                    }
                  </div>
                )
              }
            </>
            )
          : (
            <div className="text-center flex flex-column align-items-center">
              <h2 className="claim-accounts-header">Welcome to XPASS</h2>
              <p>
                Your membership is now active <br />
                View the schedule to book your first class
              </p>
              <br />
              <div>
                <a href="/book/xponential-xpass" className="btn btn-primary mt-5 mt-md-auto text-color-light">Book a Class</a>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}
