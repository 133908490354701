import * as yup from "yup"
import * as moment from "moment"
import stateNames from "./stateNames"

// need to augment definition here for reasons I don't totally understand
// this approach:
// https://github.com/jquense/yup/issues/312
// leads to:
// https://github.com/microsoft/TypeScript/issues/18588
// alternative approach is something like this in package.json:
// "postinstall": "rm -rf ./node_modules/@types/{node,glob}"
declare module "yup" {
  interface Schema<T> {
    equalTo(ref: T | Ref, msg: TestOptionsMessage): this
  }
}

const nameValidator = yup.string().matches(/^[\D]+$/, "Enter a valid name")

const passwordValidator = yup.string().min(6)

yup.addMethod(yup.string, "equalTo", function(ref, msg) {
  return yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg || "${path} must be the same as ${reference}",
    params: {
      reference: ref.path,
    },
    test: function(value: any) {
      return value === this.resolve(ref)
    },
  })
})

const passwordConfirmationValidator = yup
  .string()
  .equalTo(yup.ref("password"), "Passwords must match.")
  .label("Confirm Password")
  .required()

const phoneNumberValidator = yup
  .string()
  .matches(/^[0-9()-\s\+\.#]+$/, "Enter a valid phone number")
  .test(
    "is-complete-phone",
    "Enter a valid phone number",
    v => typeof v === "undefined" || v.replace(/[^0-9]/g, "").length >= 10
  )

const dateValidator = yup
  .string()
  .test("is-date-fmt", "Please enter a valid date.", v =>
    moment(v, "YYYY-M-D", true).isValid()
  )

const birthDateValidator = dateValidator
  .test("is-future-birth-date", "Invalid birth date.", v =>
    moment(v, "YYYY-M-D").isBefore(new Date())
  )
  .test("is-past-birth-date", "Birth date can't be that far in the past!", v =>
    moment(v, "YYYY-M-D").isAfter(new Date("1900-01-01"))
  )
  .test("is-current-birth-date", "Birth date can't be the current date", v =>
    !moment(v, "YYYY-M-D").isSame(new Date(), "day")
  )

const stateValidator = yup
  .string()
  .length(2)
  .test("test-name", "Must be a valid state two-letter abbreviation.", v =>
    v ? Object.keys(stateNames).indexOf(v) > -1 : true
  )
const zipValidator = yup.string().min(5)
// regex graveyard:
// /^(19|20)\d\d-(\d)?\d-(\d)?\d$/
// /^(((0)?[1-9])|((1)[0-2]))(\/)(0?[1-9]|[12][0-9]|3[01])(\/)\d{4}$/,

export {
  nameValidator,
  passwordValidator,
  passwordConfirmationValidator,
  dateValidator,
  birthDateValidator,
  phoneNumberValidator,
  stateValidator,
  zipValidator,
}
