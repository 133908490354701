import * as React from "react"
import { Link, LinkProps } from "react-router-dom"
import { observer } from "mobx-react"

export interface Props extends LinkProps {
  to: string
  external: boolean
}

// an "a" tag if "external" is true, otherwise a RR Link tag
const LinkOrAnchor: React.SFC<Props> = ({
  external,
  to,
  replace,
  ...props
}) => {
  if (external) {
    return <a {...props} href={to} />
  } else {
    return <Link {...props} replace={replace} to={to} />
  }
}

export default observer(LinkOrAnchor)
