import React, { FunctionComponent, ReactNode } from "react"
import cx from "classnames"
import { inject, observer } from "mobx-react"
import capitalize from "lodash/capitalize"

import BrandStore from "stores/BrandStore"
import DashboardApiStore from "apps/dashboard/stores/DashboardApiStore"
import { Link } from "react-router-dom"
import APILoader from "components/wrappers/APILoader"

import BadgeProgressPanel from "apps/history/components/BadgeProgressPanel"
import SummaryStat from "apps/history/components/SummaryStat"
import { Metric } from "apps/history/models/Stats"
import { ucfirst } from "helpers/stringHelper"
import { BadgeBitmap } from "themes/base"

interface BadgeProps {
  badgeTitle: string | undefined
  children?: Element | ReactNode
}
const BadgeCardTitle: React.FC<BadgeProps> = ({ badgeTitle, children }) => (
  <div className="card-header d-flex justify-content-between align-items-center">
    <h5 className="text-dark">{badgeTitle}</h5>
    {children}
  </div>
)

interface Props {
  className?: string
  store?: BrandStore
  dashboardStore: DashboardApiStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class BadgeCard extends React.Component<Props> {
  handleHistoryClick = (_: LinkEvent) => {
    this.props.store!.track.event("home_tap view history")
  }

  handleBookClick = (_: LinkEvent) => {
    this.props.store!.track.event("home_tap book")
  }

  showBadge = (progress: number, value: number, currentIndex: number) => {
    const badges = this.props.store!.badges as BadgeBitmap[]
    const nextBadge = currentIndex + 1 < badges.length ? currentIndex + 1 : badges.length - 1
    // - Always show the last 6 badges
    // - Always show the last completed milestone (as active), and none before (unless down to the last 6)
    // - Show all unachieved milestones (as inactive)
    return progress <= value
           || progress <= badges[nextBadge].value
           || currentIndex >= (badges.length - 7)
  }

  render() {
    const {copy, settings} = this.props.store!
    const metric: Metric = {
      key: "attendance",
      label: `Total ${ucfirst(copy.classes)} Taken`,
      // we won't be using this. kind of a hack
      logo: "",
      logoSize: 0,
    }
    // Get the progress from dashboardStore
    const { user } = this.props.dashboardStore
    const progress = (user && user.attendanceCount) || 0
    return (
      <div className={cx("card", this.props.className)}>
        {!user && <BadgeCardTitle badgeTitle={copy.badgeTitle} />}
        <APILoader
          apiStore={this.props.dashboardStore}
          elementSize="element"
          propagateErrors
          render={() => {
            return (
              <>
                <BadgeCardTitle badgeTitle={copy.badgeTitle}>
                  <small>
                    {progress > 0 ? (
                      <Link to={`/history`} onClick={this.handleBookClick}>
                        View Activity
                      </Link>
                    ) : (
                      <Link to={`/book`} onClick={this.handleHistoryClick}>
                        Book a {ucfirst(copy.class)}
                      </Link>
                    )}
                  </small>
                </BadgeCardTitle>
                {progress > 0 ? (
                  <div className="card-body d-md-flex flex-column">
                    <SummaryStat
                      metric={metric}
                      label={metric.label + ": "}
                      statWrapperClassName="mb-3"
                      labelClassName="h4 mb-0 d-inline"
                      valueClassName="h4 text-primary d-inline"
                      hideIcon
                      values={[progress]}
                    />
                    <BadgeProgressPanel
                      progress={progress}
                      className="h5 mb-3"
                    />
                    <div className="d-flex justify-content-between mt-auto overflow-auto">
                      {(this.props.store!.badges as BadgeBitmap[]).map(
                        (badge, index) => this.showBadge(progress, badge.value, index) ? (
                          <img
                            src={
                              progress >= badge.value
                                ? badge.active
                                : badge.inactive
                            }
                            width={settings.badgeBitmapWidth}
                            height={settings.badgeBitmapHeight}
                            key={`bdg-${badge.label}`}
                            className="progress-badge"
                          />
                        ) : ('')
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="card-body flex-grow-1 d-flex flex-column align-content-center">
                    <h4>Book Your First {capitalize(copy.class)}</h4>
                    <p>
                      Your stats and badges will appear here once you complete
                      your first {copy.class}.
                    </p>
                    <Link className="btn btn-primary mt-auto" to="/book">
                      Book a {capitalize(copy.class)}
                    </Link>
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    )
  }
}
