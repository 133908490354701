import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import APIStore from "stores/APIStore"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import PurchaseSummary from "apps/account/models/PurchaseSummary"
import Package from "models/Package"
import PlanV2Store from "apps/buy/stores/PlanV2Store"
import GiftCardStore from "apps/buy/stores/GiftCardStore"
import { isFormError } from "helpers/errorHandling"

interface PurchaseResponse {
  purchase: PurchaseSummary
}

export default class PurchaseV2Store extends APIStore {
  @observable purchase?: PurchaseSummary

  api = this.createClient<PurchaseResponse>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    TokenAuthMiddleware(this.planV2Store.brandStore.userStore),
    DeserializeMiddleware("purchase", PurchaseSummary),
  ])

  @computed get plan() {
    return this.planV2Store.plan!
  }

  @computed get pkg() {
    // pkg will be optional some day
    return this.planV2Store.package!
  }

  @computed get promoCode() {
    return this.planV2Store.promoCode
  }

  constructor(public planV2Store: PlanV2Store) {
    super()
  }

  makePurchase() {
    const url =`/api/xpass/packages/${this.planV2Store.packageId}/purchases`

    return this.api.post(url, {
        payment_amount: this.plan.todayTotal.numeric,
        promo_code: this.planV2Store.promoCode,
      }
    )
  }

  updatePurchase(purchaseId: string, pkg: Package) {
    return this.api.put(`/api/xpass/purchases/${purchaseId}`, {
      payment_amount: pkg.price.raw,
      target_package_id: pkg.id,
    })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<PurchaseResponse>) {
    this.purchase = res.data.purchase
    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    const uiStore = this.planV2Store.brandStore.uiStore
    // TODO: nicer error handling
    if (isFormError(ex.response)) {
      this.planV2Store.brandStore.uiStore.openMessage(
        "error",
        ex.response.data.error_messages.join("\n"),
        "Something went wrong"
      )
      throw ex
    }

    const message = ex.response && ex.response.data.message
      ? ex.response.data.message
      : "Something went wrong"
    if (message !== "Must be brand member") {
      uiStore.openMessage(
        "error",
        message
      )
    }
    throw ex
  }
}
