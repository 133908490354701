import { AxiosResponse } from "axios"
import { observable, action } from "mobx"

import APIStore from "stores/APIStore"
import BrandStore from "stores/BrandStore"

import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

import Reward from "apps/classpoints/models/Reward"

interface Response {
  earnedRewards: Reward[]
}

export default class EarnedRewardsStore extends APIStore {
  @observable earnedRewards: Reward[] = []

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("earned_rewards", Reward),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])
  
  constructor(public brandStore: BrandStore) { super() }

  fetch() {
    return this.api.get(`/api/class_points/v2/earned_rewards`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response | any>) {
    this.earnedRewards = res.data.earned_rewards
    return res.data
  }
}
