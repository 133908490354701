import { JsonObject, JsonProperty } from "json2typescript"
import Price from "models/Price"

@JsonObject("PurchaseSummary")
export default class PurchaseSummary {
  /**
   * A unique id for the purchase
   */
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("payment_amount", Price)
  paymentAmount: Price = undefined!

  /**
   * Unique id of the package.
   */
  @JsonProperty("package_id", String)
  packageId: string = undefined!

  @JsonProperty("promo_code", String, true)
  promoCode?: string = undefined
}
