import * as React from "react"
import { observer, inject } from "mobx-react"
import { observable, get, set } from "mobx"
import Dropdown from "react-bootstrap/Dropdown"
import sortBy from "lodash/sortBy"
// import AddToCalendar from 'apps/bookings/components/ReactAddToCalendar'
import AddToCalendar from 'react-add-to-calendar'
import "react-add-to-calendar/dist/react-add-to-calendar"

import BrandStore from "stores/BrandStore"
import Booking, { AnyBooking } from "models/Booking"
import ServiceBooking from "apps/book/models/ServiceBooking"
import { Link } from "react-router-dom"
import Heading from "components/Heading"
import cx from "classnames"
import TableMenu from "components/TableMenu"
import { ucfirst } from "helpers/stringHelper"
import PageTracker from "components/wrappers/PageTracker"
import XpassBg from "images/xpass/xpass-bg2.svg"
import tokenIcon from "images/xpass/token.png"

export interface Props {
  store?: BrandStore
  bookings: Booking[]
  serviceBookings: ServiceBooking[]
  baseUrl: string
  onCancelClick(booking: AnyBooking): void
  onCheckinClick(booking: AnyBooking): void
  onRemoveCheckinClick(booking: AnyBooking): void
  handleWaitlistBookingClick(booking: Booking): void
}

@observer
export default class MySchedulePage extends React.Component<Props, {}> {
  @observable
  calendarDropdownOpen = {}

  @observable
  bookingsToShow:{ booking: any; location: any; isNewDay: boolean; }[] = []

  handleAddToCalendarClick = (clubreadyId: string) => {
    set(this.calendarDropdownOpen, `${clubreadyId}`, true)
  }

  handleCloseCalendarClick = (clubreadyId: string) => {
    set(this.calendarDropdownOpen, `${clubreadyId}`, false)
  }

  closeAllCalendarDropdowns = (e: MouseEvent) => {
    let { calendarDropdownOpen } = this
    const target = e.target as HTMLTextAreaElement

    if (
      target &&
      target.className &&
      typeof target.className === "string" &&
      target.className.includes("add-to-calendar")
    ) {
      return
    }
    Object.keys(this.calendarDropdownOpen).forEach(function(key) {
      set(calendarDropdownOpen, key, false)
    })
  }

  componentDidMount() {
    this.sortBookingsToShow()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.bookings !== prevProps.bookings) {
      this.sortBookingsToShow()
    }
  }

  sortBookingsToShow = () => {
    let defaultPreviousDate = ""
    const allBookings = [...this.props.bookings, ...this.props.serviceBookings]
    this.bookingsToShow = sortBy(allBookings, b => b.scheduleEntry.startsAt).map(
      booking => {
        const location = booking.scheduleEntry.location!
        const isNewDay = booking.scheduleEntry.dateString !== defaultPreviousDate
        set(this.calendarDropdownOpen, booking.clubreadyId!, false )

        defaultPreviousDate = booking.scheduleEntry.dateString

        return { booking, location, isNewDay }
      }
    )

    document.addEventListener("click", this.closeAllCalendarDropdowns, false);
  }

  render() {
    const { userStore, brandData, brand, isXponential, styleClasses } = this.props.store!
    const { accessToken } = userStore.session!
    const {
      copy,
      settings: { printMySchedule },
    } = brandData
    const { bookingsToShow } = this
    const slugBrandName = {
      'akt': 'AKT',
      'clubpilates': 'Club Pilates',
      'cyclebar': 'CycleBar',
      'purebarre': 'Pure Barre',
      'rowhosue': 'Row House',
      'stretchlab': 'StretchLab',
      'stride': 'Stride',
      'yogasix': 'YogaSix',
      'kinrgy': 'KINRGY',
    }

    return (
      <>
        <div className="my-schedule" style={{marginBottom: "5rem"}}>
          <div className="row">
            <div className="col-sm-6">
              <Heading>My Schedule</Heading>
            </div>
            {printMySchedule && bookingsToShow.length > 0 && (
              <div className="col-sm-6 text-sm-right">
                <a
                  className="btn btn-primary btn-sm px-4"
                  target="_blank"
                  href={`/api/my_schedule.pdf?access_token=${accessToken}`}
                >
                  Print Schedule
                </a>
              </div>
            )}
          </div>
          <PageTracker name="my schedule" />
          {bookingsToShow.length > 0 ? (
            <table className="table fade-in delay2">
              <tbody className="my-schedule-tbody rows-in">
                {bookingsToShow.map(({ booking, location, isNewDay }, i) => (
                  <React.Fragment key={booking.key}>
                    {isNewDay && (
                      <tr>
                        <td
                          className={cx(
                            "my-schedule-date",
                            "px-0",
                            i === 0 ? "pt-2" : "pt-4",
                            "pb-3",
                            "border-bottom-0",
                            {
                              "border-top-0": i === 0,
                            }
                          )}
                          colSpan={5}
                        >
                          <h5 className={cx("m-0 py-1 d-inline-block text-dark", styleClasses.MySchedulePage__entryStartDate)}>
                            {booking.scheduleEntry.startsAt.format(styleClasses.MySchedulePage__dateFormat)}
                          </h5>
                        </td>
                      </tr>
                    )}
                    <tr className="my-schedule-tr py-md-1">
                      <td className="my-schedule-class px-0 py-0 py-lg-3">
                        <div className="schedule-title mb-2">
                          <h3 className="mb-0">{booking.scheduleEntry.title}</h3>
                          {booking.scheduleEntry.subtitle && (
                            <div>
                              <strong>{booking.scheduleEntry.subtitle}</strong>
                            </div>
                          )}
                        </div>
                        <div className="schedule-location">
                          {isXponential ? (
                            <>
                              {slugBrandName[location.brandId]} {location.name}
                            </>
                          ) : (
                            <>
                              {brand.name} {location.name}
                            </>
                          )}
                        </div>
                        <a href={location.directionsUrl} target="_blank">
                          Get Directions
                        </a>
                      </td>
                      <td className="my-schedule-time px-0 py-0 py-lg-3">
                        {booking.scheduleEntry.startTime}
                        &ndash;
                        {booking.scheduleEntry.endTime}&nbsp;
                        {isXponential && booking.scheduleEntry.timezone}
                        {debugUI && (
                          <>
                            <br />
                            {booking.scheduleEntry.startsAt.toISOString()}
                          </>
                        )}
                      </td>
                      <td className="my-schedule-instructor px-0 py-0 py-lg-3">
                        <strong>{copy.instructor}: </strong>
                        {booking.scheduleEntry.instructor &&
                          booking.scheduleEntry.instructor.name ?
                          booking.scheduleEntry.instructor.name :
                          "No Instructor"
                        }
                      </td>
                      <td className="my-schedule-seat px-0 py-0 py-lg-3">
                        {booking.status === "waitlisted" &&
                        booking.type !== "service_booking" ? (
                          booking.canBookFromWaitlist &&
                          booking.scheduleEntry.bookingPath ? (
                            <div>
                              <button
                                className="btn btn-sm btn-primary text-nowrap"
                                disabled={booking.isBusy}
                                onClick={e => {
                                  e.preventDefault()
                                  this.props.handleWaitlistBookingClick(booking)
                                }}
                              >
                                Book from Waitlist
                              </button>
                            </div>
                          ) : (
                            <>
                              <strong>Waitlisted</strong>&nbsp;
                              {booking.waitlistPosition &&
                                `#${booking.waitlistPosition}`}
                            </>
                          )
                        ) : (
                          booking.type !== "service_booking" &&
                          booking.seatId && (
                            <>
                              <span>{ucfirst(copy.seat)}:</span>&nbsp;
                              <span className={styleClasses.MySchedulePage__entrySeatLabel}>
                                {booking.seatLabel || booking.seatId}
                              </span>
                            </>
                          )
                        )}
                        {debugUI && (
                          <>
                            <br />
                            {booking.status}
                            <br />
                            <small>{booking.id}</small>
                          </>
                        )}
                      </td>
                      {isXponential && (
                        <td className="my-schedule-points d-block px-0 py-0 py-lg-3">
                          {(booking.scheduleEntry !== null) && (
                            <>
                              <strong className="text-capitalize">
                                Cost: &nbsp;
                              </strong>
                              <img src={tokenIcon} alt="token" />
                              &nbsp;
                              {booking.scheduleEntry.userPremiumCost.raw ? `+ $${booking.scheduleEntry.userPremiumCost.raw}` : ""}
                            </>
                          )}
                        </td>
                      )}
                      <td className="my-schedule-actions px-0 py-0 py-lg-3">
                        <div className="d-flex justify-content-end">
                          <BookingMenu
                            store={this.props.store}
                            booking={booking}
                            baseUrl={this.props.baseUrl}
                            onCancelClick={this.props.onCancelClick}
                            onCheckinClick={this.props.onCheckinClick}
                            onRemoveCheckinClick={this.props.onRemoveCheckinClick}
                            onAddToCalendarClick={this.handleAddToCalendarClick}
                            onCloseCalendarClick={this.handleCloseCalendarClick}
                            calendarDropdownOpen={this.calendarDropdownOpen}
                          />
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="fade-in delay-2 col-xl-4 col-lg-6 col-md-8 p-0 ">
              <h3>{`It looks like you don't have any ${copy.classes} scheduled.`}</h3>
              <p className="mb-2 mb-lg-5">
                {` Click the button below to view the entire ${
                  this.props.store!.locStore.currentLocation!.name
                } schedule and book your next ${copy.class}.`}
              </p>

              <Link
                className="btn btn-primary mb-5"
                to="/book"
                onClick={() => {
                  this.props.store!.track.event("my schedule_tap book")
                }}
              >
                Book a {ucfirst(copy.class)}
              </Link>
            </div>
          )}
        </div>
        {isXponential && bookingsToShow.length === 0 && (
          <div className="my-schedule-background">
            <img src={XpassBg} alt="XPASS logo" />
          </div>
        )}
      </>
    )
  }
}

interface BookingMenuProps {
  store?: BrandStore
  booking: AnyBooking
  baseUrl: string
  onCancelClick(booking: AnyBooking): void
  onCheckinClick(booking: AnyBooking): void
  onRemoveCheckinClick(booking: AnyBooking): void
  onAddToCalendarClick(clubreadyId: string): void
  onCloseCalendarClick(clubreadyId: string): void
  calendarDropdownOpen: object
}
const BookingMenu: React.SFC<BookingMenuProps> = observer(
  ({
    store,
    booking,
    baseUrl,
    onCancelClick,
    onCheckinClick,
    onRemoveCheckinClick,
    onAddToCalendarClick,
    onCloseCalendarClick,
    calendarDropdownOpen,
  }) => {
    const checkinsEnabled = process.env.ENABLE_CHECKINS === "1"
    const shouldShowMenu = checkinsEnabled || booking.scheduleEntry.inFuture
    if (!shouldShowMenu) {
      return null
    }

    const typeName =
      booking.type === "service_booking"
        ? store!.copy.session
        : store!.copy.class

    const entry = booking.scheduleEntry
    const eventTitle = typeof(entry.title) == "object" ? entry.title.toString() : entry.title
    const event = {
      title: eventTitle,
      description: "description" in entry ? entry.description : "",
      location: `${entry.location!.city}, ${entry.location!.state}`,
      startTime: entry.startsAt.format('YYYY-MM-DDTHH:mm:ssZ'),
      endTime: entry.endsAt.format('YYYY-MM-DDTHH:mm:ssZ')
    }
    const calendarListItems = [
      { apple: 'Apple' },
      { google: 'Google' },
      { outlook: 'Outlook' },
      { outlookcom: 'Outlook.com' },
      { yahoo: 'Yahoo' }
    ]
    const bookingCalendarDropdownOpen = calendarDropdownOpen[`${booking.clubreadyId}`]
    const notCancellable = booking.type === "service_booking" &&
      process.env.NO_PRIVATE_SESSION_CANCEL_BRANDS &&
      process.env.NO_PRIVATE_SESSION_CANCEL_BRANDS.split(',').indexOf(store!.brandId) !== -1

    return (
      <>
      <TableMenu
        name="Actions"
        id={`schedule-action-${booking.key}`}
        isBusy={booking.isBusy}
        disabledWith={
          booking.status === "completed" && !checkinsEnabled
            ? "Checked In"
            : undefined
        }
      >
        {checkinsEnabled &&
          (booking.status === "completed" ? (
            <Dropdown.Item
              as="button"
              onClick={() => onRemoveCheckinClick(booking)}
            >
              Cancel Checkin
            </Dropdown.Item>
          ) : (
            <Dropdown.Item as="button" onClick={() => onCheckinClick(booking)}>
              {booking.scheduleEntry.canCheckIn
                ? "Check In"
                : `Check In From ${
                    booking.scheduleEntry.checkInFrom
                      ? booking.scheduleEntry.checkInFrom.format(
                          `${store!.styleClasses.MySchedulePage__dateFormat} h:mma`
                        )
                      : "??"
                  }`}
            </Dropdown.Item>
          ))}

        {booking.type !== "service_booking" &&
          booking.seatId &&
          booking.scheduleEntry.inFuture && (
            <Dropdown.Item
              as="button"
              onClick={() =>
                store!.routingStore.history.push(
                  store!.isXponential
                    ? `${baseUrl}/${booking.scheduleEntry.id}/seat?location_id=${booking.scheduleEntry.locationId}`
                    : `${baseUrl}/${booking.scheduleEntry.id}/seat`
                )
              }
            >
              Change Your {store!.copy.seat}
            </Dropdown.Item>
          )}

        {booking.scheduleEntry.inFuture && (
          <>
          {store!.settings.showAddToCalendarButton &&
            <Dropdown.Item as="button" className="add-to-calendar" onClick={() => onAddToCalendarClick(booking.clubreadyId!)}>
              Add to Calendar
            </Dropdown.Item>
          }
          {!notCancellable &&
            <Dropdown.Item as="button" onClick={() => onCancelClick(booking)}>
              Cancel This {ucfirst(typeName)}
            </Dropdown.Item>
          }
          </>
        )}
      </TableMenu>
      {bookingCalendarDropdownOpen &&
        <div className="show dropdown">
          <div className="border-0 shadow-sm dropdown-menu show dropdown-menu-right" style={{minWidth: "unset", position: "absolute", top: 0, left: 0 }}>
            <AddToCalendar
              event={event}
              buttonClassClosed="d-none"
              optionsOpen={bookingCalendarDropdownOpen}
              listItems={calendarListItems}
              afterItemClick={onCloseCalendarClick}
              clubreadyId={booking.clubreadyId!}
            />
          </div>
        </div>
      }
      </>
    )
  }
)
