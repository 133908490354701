import * as React from "react"
import { inject, observer } from "mobx-react"
import cx from "classnames"

import BrandStore from "stores/BrandStore"
import UserProfile from "models/UserProfile"
import ProfileStore from "apps/account/stores/ProfileStore"
import APILoader from "components/wrappers/APILoader"
import NotificationSetting from "models/NotificationSetting"
import ProfileForm from "apps/account/components/ProfileForm"
import ProfileUpdateStore from "apps/account/stores/ProfileUpdateStore"
import SettingsPanel from "apps/account/components/SettingsPanel"
import NotificationsPanel from "apps/account/components/NotificationsPanel"
import Heading from "components/Heading"
import { RouteComponentProps } from "react-router"
import PageTracker from "components/wrappers/PageTracker"
import PasswordUpdatePanel from "apps/account/components/PasswordUpdatePanel"
import { BrandCopy } from "themes/base"

export interface Props extends RouteComponentProps {
  store?: BrandStore
  copy?: BrandCopy
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class ProfilePage extends React.Component<Props, {}> {
  profileStore = new ProfileStore(this.props.store!)

  handleNotificationChange = (
    enabled: boolean,
    settingId: string,
    settingVia: string
  ) => {
    if (!this.profileStore.notificationSettings) return

    const setting = this.profileStore.notificationSettings.find(
      s => s.id === settingId
    )
    if (!setting) return

    setting.via[settingVia] = enabled
  }

  render() {
    const { copy } = this.props.store!
    return (
      <div className="account-profile col-lg-8">
        <Heading>{copy.titleProfileInfo}</Heading>
        <PageTracker name="account > profile" />

        <APILoader
          apiStore={this.profileStore}
          onLoad={() => {
            if (this.profileStore.profile) {
              this.props.store!.track.setUserProps({
                profile: this.profileStore.profile,
              })
            }
          }}
          render={() => {
            const notifications = this.profileStore.notificationSettings!
            const profileUpdateStore = new ProfileUpdateStore(this.profileStore)

            return (
              <>
                <ProfileForm
                  profileStore={this.profileStore}
                  profileUpdateStore={profileUpdateStore}
                />

                {/* Disable settings for MVP
                <h2 className="mt-5">Notification Settings</h2>
                <NotificationsPanel
                  notifications={notifications}
                  onChange={this.handleNotificationChange}
                />

                <h2 className="mt-5">Global Settings</h2>
                <SettingsPanel settings={[]} />
                */}
              </>
            )
          }}
        />
        <PasswordUpdatePanel brandStore={this.props.store!} />
      </div>
    )
  }
}
