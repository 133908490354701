import { observable, action } from "mobx"
import BrandStore from "stores/BrandStore"
import Booking from "models/Booking"
import BookingCreationStore from "apps/book/stores/BookingCreationStore"
import LocationSummary from "models/LocationSummary"

export default class BookingUpdateStore extends BookingCreationStore {
  @observable booking: Booking
  location: LocationSummary

  constructor(brandStore: BrandStore, booking: Booking) {
    super(brandStore)
    this.booking = booking
    this.seatId = booking.seatId
    this.seatLabel = booking.seatLabel
    this.location = booking.scheduleEntry.location!
  }

  fetch() {
    if (this.isXponential) {
      return this.api.get(
        `${this.baseUrl}/${this.booking.id}?location_id=${this.booking.scheduleEntry.locationId}&from_web=true`
      )
    }
    return this.api.get(`${this.baseUrl}/${this.booking.id}`)
  }

  update() {
    if (this.isXponential) {
      return this.api.put(`${this.baseUrl}/${this.booking.id}`, {
        location_id: this.booking.scheduleEntry.locationId,
        seat_id: this.seatId,
        platform: "web",
      })
    }
    return this.api.put(`${this.baseUrl}/${this.booking.id}`, {
      seat_id: this.seatId,
    })
  }

  cancel(params?: {}) {
    if (this.brandStore.isXponential) {
      params!['location_id'] = this.booking.scheduleEntry.locationId
    }
    this.booking.isBusy = true
    return this.api.delete(`${this.baseUrl}/${this.booking.id}`, {
      params: params,
    })
  }

  checkIn() {
    if (process.env.ENABLE_CHECKINS === "1") {
      this.booking.isBusy = true
      return this.api.post(`${this.baseUrl}/${this.booking.id}/checkins`)
    } else {
      return Promise.reject()
    }
  }

  removeCheckin() {
    if (process.env.ENABLE_CHECKINS === "1") {
      this.booking.isBusy = true
      return this.api.delete(`${this.baseUrl}/${this.booking.id}/checkins`)
    } else {
      return Promise.reject()
    }
  }
}
