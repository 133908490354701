import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("GiftCard")
export default class GiftCard {
  @JsonProperty("acct_token", String)
  acctToken: string = undefined!

  @JsonProperty("balance", Number)
  balance: number = undefined!
}
