import * as React from "react"
import cx from "classnames"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import Svg from "components/Svg"
import { TrackingEvent } from "models/TrackingSchemas"
import iosIcon from "images/icons/ios-icon.svg"
import androidIcon from "images/icons/android-icon.svg"
import MobileOfferStore from "apps/dashboard/stores/MobileOfferStore"
import APILoader from "components/wrappers/APILoader"

export interface BannerAltCardLink {
  label: string
  url?: string
  target?: string
}

interface BannerAltCardEvent {
  track?: TrackingEvent
  url?: string
}

interface Props {
  store?: BrandStore
  className?: string
  title: string
  description?: string
  overlay?: boolean
  // global URL, makes whole card clicky
  url?: string
  // global tracking event
  track?: TrackingEvent
  // individual links
  links?: BannerAltCardLink[]
  hasPlayButton?: boolean
  bannerImage: string
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class BannerAltCard extends React.Component<Props> {
  handleLinkClick = ({ track, url }: BannerAltCardEvent) => (_: LinkEvent) => {
    if (!track) return

    const store = this.props.store!
    store.track.event(
      track,
      {
        loc: store.locStore.currentLocation,
      },
      { linkUrl: url }
    )
  }

  render() {
    const xpassHeader = {fontSize: "24px", lineHeight: "28.8px"}
    const isXponential = this.props.store!.isXponential
    const links = this.props.links
      ? this.props.links.filter(({ url }) => url)
      : []

    const inner = (
      <>
        <div
          className={cx(
            "card-body",
            "d-flex",
            "flex-column",
            "justify-content-center",
            "align-items-center",
          )}
          style={{ zIndex: 1 }}
        >
          <div className={cx("text-center", !isXponential && "text-white")}>
            {this.props.title &&
              <h2 className={cx("d-block", "mb-3", !isXponential && "text-white")}><strong>{this.props.title}</strong></h2>
            }
            {this.props.description &&
              <div className={cx("d-block", "mb-3", !isXponential && "text-white")}>{this.props.description}</div>
            }
            {!!links && (
              <div className={cx("mt-4", isXponential ? "row d-flex pt-2 justify-content-center" : "d-block banner-card-links")}>
                {links
                  .filter(l => l.url && l.label)
                  .map((link, i) => (
                    <a
                      href={link.url}
                      key={i}
                      target={link.target || '_blank'}
                      className={cx(isXponential ? "btn btn-dark" : "text-white d-block")}
                      onClick={this.handleLinkClick(link)}
                    >
                      <span>{link.label}</span>
                    </a>
                  ))}
              </div>
            )}
          </div>

          <div
            className="btn btn-link align-self-end"
            style={{ position: "absolute", bottom: "1.7rem" }}
          >
            {this.props.hasPlayButton && <Svg name="play" size="20" />}
          </div>
        </div>
      </>
    )

    const background = `url(${this.props.bannerImage}) center / cover`
    const { overlay = "overlay" } = this.props
    const wrapperClass = cx(
      "card",
      overlay,
      "banner-card",
      this.props.className
    )
    const xpassWrapperClass = cx(
      "card",
      "banner-card",
      this.props.className
    )

   if (this.props.url && !links) {
      return (
        <a
          className={cx( isXponential ? xpassWrapperClass : wrapperClass, "text-decoration-none")}
          href={this.props.url}
          target="_blank"
          onClick={this.handleLinkClick(this.props)}
          style={{
            background,
          }}
        >
          {inner}
        </a>
      )
    } else {
      return (
        <div
          className={cx( isXponential ? xpassWrapperClass : wrapperClass, "text-decoration-none")}
          style={{
            background,
          }}
        >
          {inner}
        </div>
      )
    }
  }
}

type MobileOfferCardProps = { className?: string; store?: BrandStore; }
const MobileOfferCard: React.FunctionComponent<MobileOfferCardProps> = inject(
  (store: BrandStore) => ({ store })
)(
  observer(({ className, store }) => {
    const mobileOfferStore = new MobileOfferStore(store!)

    return (
      <APILoader
        apiStore={mobileOfferStore}
        render={() => {
          const promoEnabled = mobileOfferStore.promoEnabled
          let hasPromo = promoEnabled
          let mobilePromo = undefined
          let label, url = ''
          let links = [{
            label: '',
            url: ''
          }]

          // If not promoEnabled, it breaks mobilePromo so we have to protect against it
          if (promoEnabled) {
            mobilePromo = mobileOfferStore.mobilePromo
            label = mobilePromo!.cta || ''
            url = mobilePromo!.externalUrl || ''
            links = [{
              label: label,
              url: url
            }]
            hasPromo = promoEnabled && mobilePromo!.webPromoEnabled && !!mobilePromo!.externalUrl
          }

          return hasPromo && (
            <>
                <BannerAltCard
                  className={className}
                  title={mobilePromo!.headline}
                  description={mobilePromo!.description || ''}
                  bannerImage={mobilePromo!.backgroundImageUrl}
                  links={links}
                />
            </>
          )
        }}
      />
    )
  }
))

export { MobileOfferCard }
