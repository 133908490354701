import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("Status")
export class Status {
  @JsonProperty("description", String)
  description: string = undefined!

  @JsonProperty("earned", Boolean, true)
  earned?: boolean = undefined!

  @JsonProperty("tier", String, true)
  tier?: string = undefined!

  @JsonProperty("title", String)
  title: string = undefined!

  @JsonProperty("points_to_next_status", Number, true)
  pointsToNextStatus?: number = undefined!
}

@JsonObject("StatusMain")
export class StatusMain {
  @JsonProperty("current_status", Status)
  currentStatus: Status = undefined!

  @JsonProperty("has_unlimited_membership", Boolean)
  hasUnlimitedMembership: boolean = false

  @JsonProperty("is_member", Boolean)
  isMember: boolean = false

  @JsonProperty("membership_status", Status)
  membershipStatus: Status = undefined!

  @JsonProperty("next_status", Status)
  nextStatus: Status = undefined!
}

@JsonObject("Summary")
export class Summary {
  @JsonProperty("active_points", Number)
  activePoints: number = undefined!

  @JsonProperty("current_milestone", Number)
  currentMilestone: number = undefined!

  @JsonProperty("current_term_ends_at", String)
  currentTermEndsAt: string = undefined!

  @JsonProperty("current_tier", String)
  currentTier: string = undefined!

  @JsonProperty("lifetime_points", Number)
  lifetimePoints: number = undefined!

  @JsonProperty("total_milestones", Number)
  totalMilestones: number = undefined!

  @JsonProperty("renew_tier", String)
  renewTier: string = undefined!
}

@JsonObject("Banner")
export class Banner {
  @JsonProperty("banner", String)
  banner: string = undefined!
}

@JsonObject("Tier")
export class Tier {
  @JsonProperty("tier_name", String)
  tierName: string = undefined!

  @JsonProperty("slug", String)
  slug: string = undefined!

  @JsonProperty("membership_text", String)
  membershipText: string = undefined!

  @JsonProperty("description", [])
  description: [string]= undefined!

  @JsonProperty("title", String)
  title: string = undefined!

  @JsonProperty("points", Number)
  points: number = undefined!

  @JsonProperty("text_color_hex", String)
  textColorHex: string = undefined!

  @JsonProperty("background_color_hex", String)
  backgroundColorHex: string = undefined!

  @JsonProperty("membership_required", Boolean)
  membershipRequired: boolean = undefined!
}

@JsonObject("MembershipDetails")
export class MembershipDetails {
  @JsonProperty("title", String)
  title: string = undefined!

  @JsonProperty("subtitle", String)
  subtitle: string = undefined!

  @JsonProperty("tiers", [Tier])
  tiers: Tier[] = undefined!
}

@JsonObject("DashboardMain")
export default class DashboardMain {
  @JsonProperty("banner", String)
  banner: string = undefined!

  @JsonProperty("status", StatusMain)
  status: StatusMain = undefined!

  @JsonProperty("summary", Summary)
  summary: Summary = undefined!

  @JsonProperty("membership_details", MembershipDetails)
  membershipDetails: MembershipDetails = undefined!
}
