import {
  BrandData,
  BrandStyleClasses,
  baseSettings,
  baseStyles,
  baseStyleClasses,
  baseCopy,
  baseDashboardCards,
  eywAffiliateLinks,
} from "themes/base"

import logoUrl from "images/logos/stretchlab-logo.svg"
import bannerImage from "images/dashboard/stretchlab-banner-bg.jpg"
import appPromoImage_Video from "images/video/stretchlab-app-promo.png"
import videoLandingLogo from "images/video/stretchlab-go-logo.png"
import appleWatchIcon from "images/apple_health/watch/stretchlab.svg"
import classpointsHero from "images/classpoints/classpoints-hero_stretchlab.png"
import brandIcon from "images/classpoints/brands/SL.svg"

const styleClasses: BrandStyleClasses = {
  ...baseStyleClasses,
}

const brand: BrandData = {
  settings: {
    ...baseSettings,
    serviceBooking: true,
    packagesFirstOnBooking: true,
  },
  styles: {
    ...baseStyles,
    bannerImage,
    logoUrl,
    appPromoImage_Video,
    videoLandingLogo,
    appleWatchIcon,
    classpointsHero,
    brandIcon
  },
  styleClasses,
  copy: {
    ...baseCopy,
    class: "session",
    classPackages: "Multiple Options Available",
    classes: "sessions",
    mainMenuClasses: "sessions",
    credit: "session",
    credits: "sessions",
    findAClass: "Book Now",
    groupClasses: "Add-Ons",
    oneOnOneSessions: "One-On-One Stretches",
    seat: "Session",
    seats: "Spots",
    instructor: "Flexologist",
    instructors: "Flexologists",
    packages: "Single Sessions",
    bookingErrorTitle: "Session Credit Needed",
    buyRedirectText: "Get Session Credit",
    noUpcomingClassesBooked: "It looks like you don't have any upcoming {CLASSES} booked. Let’s get your next stretch booked! Click below to view schedule",
    classPointsBullet2: "Get retail discounts, complimentary guest credits, and more.",
  },
  dashboardCards: [
    "NextClassCard",
    "ProfileCard",
    "AchievementsCard",
    "MobileAppCard",
    "MobileOfferCard",
    "ClassPointsCard",
    "PromoCards",
  ],
  badges: [],
  metrics: [],
  summaryMetrics: [],
  eywAffiliateLinks: eywAffiliateLinks["stretchlab"],
  eywLegalLink: "https://www.stretchlab.com/terms-earn-your-watch",
  eywParticipatingLink: "https://www.stretchlab.com/earn-your-watch-locations"
}

export default brand
