import * as React from "react"
import { observer, inject } from "mobx-react"
import * as Sentry from "@sentry/browser"
import { AxiosError, AxiosPromise, AxiosResponse } from "axios"
import { observable } from "mobx"

import Fetchable from "utils/Fetchable"
import Spinner, { Size } from "components/Spinner"
import ErrorMessage from "components/ErrorMessage"
import { isApiError, withAxiosError } from "helpers/errorHandling"

// TODO: taking loading and error components as props

export interface APIRenderProps<T extends Fetchable> {
  apiStore: T
}

export interface Callback<T extends Fetchable> {
  (props: APIRenderProps<T> & { ex?: AxiosError | Error }): React.ReactNode
}

export interface Props<T extends Fetchable> extends APIRenderProps<T> {
  // ignored, but can be used to trigger a re-render
  key?: any

  fetchProps?: any
  // don't refetch if the store is already loaded
  alreadyLoaded?: boolean
  // sometimes if we update something that's already loaded we don't want to show a loader
  stayLoaded?: boolean
  // determines size of spinner and error message. defaults to "page"
  elementSize?: Size
  // by default this component serves as an error boundary. set to true to disable
  // this behavior, errors raised in this component will be re-thrown and can be
  // caught further up
  propagateErrors?: boolean
  render: Callback<T>
  renderError?: Callback<T>
  renderLoading?: Callback<T>

  onLoad?(res: AxiosResponse): void
}

@observer
export default class APILoader<T extends Fetchable> extends React.Component<
  Props<T>,
  {}
> {
  promise?: Promise<any>
  @observable ex?: AxiosError | Error

  componentDidMount() {
    if (this.props.alreadyLoaded && this.props.apiStore.status === "loaded") {
      return
    }

    if (this.props.fetchProps) {
      this.promise = this.props.apiStore.fetch(this.props.fetchProps)
    } else {
      this.promise = this.props.apiStore.fetch()
    }

    if (this.props.onLoad) {
      this.promise = this.promise.then(this.props.onLoad)
    }

    this.promise = this.promise.catch(ex => {
      this.ex = ex
      console.error(ex)
      Sentry.captureException(ex)
    })
  }

  componentDidCatch(ex: Error, info: React.ErrorInfo) {
    console.error(ex, info)
    Sentry.withScope(s => {
      s.setExtra("errorInfo", info)
      Sentry.captureException(ex)
    })
    this.ex = ex
  }

  public render() {
    const { apiStore } = this.props
    if (apiStore.status === "error" || this.ex) {
      if (this.props.renderError) return this.props.renderError({ apiStore })
      if (this.props.propagateErrors && this.ex) throw this.ex
      const res = withAxiosError(this.ex)
      if (res) {
        const data = res.data as { event_id?: string; message?: string }
        return (
          <ErrorMessage
            size={this.props.elementSize}
            message={data.message}
            id={data.event_id}
          />
        )
      }
      return <ErrorMessage size={this.props.elementSize} />
    }

    if (apiStore.status !== "loaded" && !this.props.stayLoaded) {
      if (this.props.renderLoading)
        return this.props.renderLoading({ apiStore })

      return (
        <div className="text-center spinner">
          <Spinner size={this.props.elementSize} />
        </div>
      )
    }

    const content = this.props.render({ apiStore })
    if (this.props.elementSize === "page") {
      return <div className="fade-in">{content}</div>
    }
    return content
  }
}
