import * as React from "react"
import { inject, observer } from "mobx-react"
import capitalize from "lodash/capitalize"
import cx from "classnames"
import * as moment from "moment"
import { Link } from "react-router-dom"
import LocationSummary from "models/LocationSummary"
import Purchase from "apps/account/models/Purchase"
import BrandStore from "stores/BrandStore"
import PackageRenewalText from "apps/buy/components/PackageRenewalText"
import Price from "models/Price"
import ManageMembershipModal from "./ManageMembershipModal"
import Package from "models/Package"
import PurchasesStore from "apps/account/stores/PurchasesStore"

export interface Props {
  store?: BrandStore
  purchase: Purchase
  locationSummary: LocationSummary
  classText: ClassText
  activePurchase?: boolean
  purchasesStore: PurchasesStore
}

type ClassText = {
  one: string
  many: string
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class PurchaseCard extends React.Component<Props, {}> {
  handleClick = () => {
    this.props.store!.track.event("packages_tap buy", {
      pkg: this.props.purchase.package,
      loc: this.props.locationSummary,
    })
  }

  render() {
    const { purchase, locationSummary, classText, purchasesStore } = this.props
    const { brand, isXponential } = this.props.store!
    const { package: pkg } = purchase
    const { allowBuyAgain } = brand

    return (
      <div className="col-md-6 col-lg-4 col-xl-3 p-3">
        <div className={cx("card h-100", isXponential && "xpass-purchase-card")}>
          <div className="card-body d-flex flex-column justify-content-center text-center">
            <div className="mb-auto">
              <h3 >{pkg.name}</h3>

              {isXponential &&
                <div className="my-2 my-lg-4 package-price text-dark">
                  {maybePrice(purchase.recurringPrice, purchase.totalPrice)}
                </div>
              }
            </div>

            <div className="my-4">
              {!isXponential &&
                <>
                  {pkg.isUnlimited ? (
                    <div className="package-price text-dark">—</div>
                  ) : (
                    <div className="package-price text-dark">
                      {purchase.creditCountRemaining}/
                      {purchase.creditCountTotal || "?"}
                    </div>
                  )}
                  <div className="h5 text-muted">
                    {/* TODO: styling */}
                    {pkg.isUnlimited
                      ? `Unlimited ${capitalize(classText.many)}`
                      : `${capitalize(classText.many)} Remaining`}
                  </div>
                </>
              }

              <div className="mt-3 package-caption">
                <PackageRenewalText
                  pkg={pkg}
                  store={this.props.store!}
                  expiresAt={purchase.creditsExpireAt}
                  purchase={purchase}
                />
              </div>

              {!isXponential && !purchase.isCancelled && (
                <div className="mt-3 text-dark">
                  <span className="h4">
                    {maybePrice(purchase.recurringPrice, purchase.totalPrice)}
                  </span>
                </div>
              )}
            </div>

            {!isXponential || isXponential && this.props.activePurchase && (
              <BuyAgainButton
                purchase={purchase}
                locationSummary={locationSummary}
                store={this.props.store!}
                onClick={this.handleClick}
                allowBuyAgain={allowBuyAgain}
              />
            )}

            {!isXponential && purchase.package.isRecurring && !purchase.isCancelled && (
              <ManageMembershipModal
                key={11}
                purchase={purchase}
                locationSummary={locationSummary}
                store={this.props.store!}
                purchasesStore={purchasesStore}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

interface BuyAgainButtonProps {
  purchase: Purchase
  locationSummary: LocationSummary
  store: BrandStore
  allowBuyAgain?: boolean
  onClick?(): void
}

const BuyAgainButton: React.SFC<BuyAgainButtonProps> = ({
  locationSummary,
  purchase,
  store,
  onClick,
  allowBuyAgain,
}) => {
  // Do not show any button if cannot be purchased again or
  // "allow_buy_again" is turned off in brand setting
  if (!purchase.canPurchaseAgain || !allowBuyAgain) return null

  const showRenewButton =
    purchase.package.isRecurring &&
    purchase.package.interval &&
    purchase.package.intervalCount &&
    purchase.renewsAt

  const addCreditCopy = store.isXponential ? 'Buy Again' : 'Add a Credit'

  // Show Renew Button if the conditions are met
  if (showRenewButton) {
    return (
      <button disabled className="btn btn-secondary align-self-center mt-auto">
        Renews {moment(purchase.renewsAt).format(store!.styleClasses.BillingHistory__dateFormat)}
      </button>
    )
  }

  // If brand setting is turned on for "allow_buy_again", show "Add a Credit"
  return (
    <Link
      className="btn btn-primary align-self-center mt-auto"
      to={`/buy/${locationSummary.id}/packages/${purchase.package.id}`}
      onClick={onClick}
    >
      {addCreditCopy}
    </Link>
  )
}

// takes n prices
// returns the first one that's valid, or returns an empty string
// if the first valid one is zero.
// I think this is the strange display behavior we want
function maybePrice(...prices: Array<Price | undefined>) {
  for (const price of prices) {
    if (!price || !price.formatted) continue
    if (price.numeric <= 0) return ""
    return price.formatted
  }
  return ""
}
