import * as React from "react"

export default function nl2br(text?: string) {
  if (text === undefined) return <React.Fragment />
  const lines = text.split("\n").map((line, i) => (
    <React.Fragment key={i}>
      {i !== 0 && <br />}
      {line}
    </React.Fragment>
  ))
  return <span>{lines}</span>
}
