import Axios, { AxiosResponse, AxiosError, Cancel } from "axios"

export type MessageRes = {
  message: string
  code?: string
  event_id?: string
}

export type FormRes = {
  errors: Record<string, string[]>
  error_messages: string[]
}

// Validation-type errors (hopefully this is all of them)
export const FORM_ERROR_CODES = [400, 422]

export function isApiError<T>(
  response?: AxiosResponse<T>
): response is AxiosResponse<T> {
  return !!(
    response &&
    response.status &&
    response.data &&
    typeof response.data === "object"
  )
}

export function isCancelledPromise(ex: Error | Cancel | undefined) {
  // return ex && ex.message && ex.message === "cancelled"
  return ex instanceof Axios.Cancel
}

export const withAxiosError = <T = MessageRes>(
  error: Error | AxiosError<T> | undefined
) => {
  if (!error) return undefined

  if (!error || typeof error !== "object" || !("response" in error)) {
    return undefined
  }

  if (error.response && isApiError(error.response)) {
    return error.response
  }
  return undefined
}

export const withAxiosErrorMessage = (
  error: Error | AxiosError<MessageRes> | undefined
): AxiosResponse<MessageRes> | undefined => {
  const response = withAxiosError<MessageRes>(error)
  if (response && "message" in response.data) {
    return response
  }
  return undefined
}

export function isAuthError(
  response?: AxiosResponse
): response is AxiosResponse<MessageRes> {
  return isApiError(response) && response.status === 403 // && response.data.error
}

export const isFormError = (
  response?: AxiosResponse
): response is AxiosResponse<FormRes> => {
  return (
    isApiError(response) &&
    FORM_ERROR_CODES.indexOf(response.status) !== -1 &&
    !!response.data.errors
  )
}
