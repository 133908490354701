import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("LogbookStatus")
export default class LogbookStatus {
  /**
   * Flag to indicate the Logbook connection status
   */
  @JsonProperty("is_connected", Boolean, true)
  isConnected?: boolean = undefined!

  /**
   * Flag to indicate if Logbook connection has error
   */
  @JsonProperty("has_error", Boolean, true)
  hasError?: boolean = undefined!

  /**
   * Flag to indicate if the Logbook connection was recently established
   */
  @JsonProperty("recently_connected", Boolean, true)
  recentlyConnected?: boolean = undefined!
}
