import { JsonConvert, OperationMode, ValueCheckingMode } from "json2typescript"
import * as Sentry from "@sentry/browser"
import withSentryScope from "helpers/withSentryScope"
// Note: all model classes need to have a name passed: @JsonObject("ClassName")
// here's a search/replace regex for doing it:
// search:
//    @JsonObject\n(.* class (\w+).*)
// replace:
//    @JsonObject("$2")\n$1

// nice to haves for json2typescript:
// - better error messages
// - ability to disable all runtime type checking
// - finalize methods (or similar) run after serialization/deserialization

export type Deserializable<T> = Constructor<T, []>

export const jsonConvert = new JsonConvert(
  OperationMode.ENABLE,
  process.env.STRICT_NULL_CHECKS === "1"
    ? ValueCheckingMode.DISALLOW_NULL
    : ValueCheckingMode.ALLOW_NULL
)

export function deserialize<T>(data: {}, klass: Deserializable<T>): T {
  try {
    return jsonConvert.deserializeObject(data, klass)
  } catch (wrapped) {
    const error = new DeserializationError(wrapped, klass.name, data)
    console.error(error.info)
    throw error
  }
}

export function deserializeArray<T>(data: {}[], klass: Deserializable<T>): T[] {
  // should really use JsonConvert.deserializeArray, this is a hack to get
  // smaller data in the Sentry errors
  return data.map(d => deserialize(d, klass))
}

export function serialize(data: Object): Object {
  return jsonConvert.serialize(data)
}

class DeserializationError extends Error {
  name = "DeserializationError"
  info?: string

  constructor(wrapped: Error, className: string, public obj: {}) {
    super(`Failed to deserialize ${className}`)

    // trying to set the stack does some weird shit in sentry
    // if (wrapped.stack) {
    //   this.stack = wrapped.stack.replace(wrapped.message, "")
    //   // .split("\n")
    //   // .slice(0, -2)
    //   // .join("\n")
    //   console.log(this.stack)
    // }

    this.info = wrapped.message
      .split("\n")
      .map(line => {
        return line.replace(/\t/g, "  ")
      })
      .filter(line => !line.match(/^\s*$/) && !line.match(/^\s*{/))
      .join("\n")
  }
}
