import APIStore from "stores/APIStore"
import { AxiosResponse } from "axios"
import { observable, action } from "mobx"
import DeserializeRootMiddleware from "services/middleware/DeserializeRootMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import BrandStore from "stores/BrandStore"
import ClassToken from "models/xpass/ClassToken"


export default class TokenDetailStore extends APIStore {
  @observable token?: ClassToken

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeRootMiddleware(ClassToken),
    TokenAuthMiddleware(this.brandStore.userStore),
  ])

  constructor(protected brandStore: BrandStore) {
    super()
  }

  @action
  fetch(id: string) {
    return this.api.get(`/api/xpass/tokens/${id}`)
  }

  @action.bound
  handleSuccess(res: AxiosResponse) {
    this.token = res.data
    return res.data
  }
}