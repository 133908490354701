import APIStore from "stores/APIStore"
import { AxiosResponse, AxiosError } from "axios"
import { action, observable } from "mobx"

import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import PaymentSourceRequest from "apps/account/models/PaymentSourceRequest"
import PaymentSource from "models/PaymentSource"
import { jsToRuby } from "helpers/objectKeys"
import FormErrorMiddleware from "services/middleware/FormErrorMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import PaymentSourcesStore, {
  PaymentSourceResponse,
} from "apps/account/stores/PaymentSourcesStore"

export default class PaymentSourceUpdateStore extends APIStore {
  api = this.createClient([
    FormErrorMiddleware,
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("payment_sources", PaymentSource),
    TokenAuthMiddleware(this.paymentSourcesStore.brandStore.userStore),
  ])

  constructor(public paymentSourcesStore: PaymentSourcesStore) {
    super()
  }

  update(body: PaymentSourceRequest) {
    const values = jsToRuby(body as {})
    
    return this.api.put("/api/v2/payment_sources", values)
  }

  @action.bound
  handleSuccess(res: AxiosResponse<PaymentSourceResponse>) {
    this.paymentSourcesStore.paymentSources = res.data.payment_sources
    this.paymentSourcesStore.hasPaymentSource = true
    return res
  }
}
