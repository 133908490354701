import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("UserCoordinates")
export default class UserCoordinates {
  @JsonProperty("lat", Number)
  lat: number = undefined!

  @JsonProperty("lng", Number)
  lng: number = undefined!
}
