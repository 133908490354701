import { JsonObject, JsonProperty } from "json2typescript"
import { observable } from "mobx"

@JsonObject("NotificationSettingVia")
export class NotificationSettingVia {
  @observable
  @JsonProperty("sms", Boolean, true)
  sms?: boolean = undefined!

  @observable
  @JsonProperty("email", Boolean, true)
  email?: boolean = undefined!

  @observable
  @JsonProperty("push", Boolean, true)
  push?: boolean = undefined!
}

@JsonObject("NotificationSetting")
export default class NotificationSetting {
  @JsonProperty("id", String)
  id: string = undefined!

  @JsonProperty("name", String)
  name: string = undefined!

  @JsonProperty("description", String, true)
  description?: string = undefined!

  @observable
  @JsonProperty("via", NotificationSettingVia)
  via: NotificationSettingVia = undefined!
}
