import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("UserRegistration")
export default class UserRegistration {
  @JsonProperty("first_name", String)
  firstName: string = undefined!

  @JsonProperty("last_name", String)
  lastName: string = undefined!
  /**
   * Email is used as the username in Xponential.
   */
  @JsonProperty("email", String)
  email: string = undefined!

  /**
   * The user's password
   */
  @JsonProperty("password", String, true)
  password?: string = undefined

  /**
   * ID of the user's home location.
   * example:
   * rowhouse-chelsea
   */
  @JsonProperty("location_id", String)
  locationId: string = undefined!

  @JsonProperty("phone_main", String)
  phoneMain: string = undefined!

  @JsonProperty("birth_date", String, true)
  birthDate?: string = undefined

  passwordConfirmation?: string = ""
  base: string = undefined!

  platform? = "web"
}
