import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("HeartRateSummary")
export default class HeartRateSummary {
  /**
   * Total calories burned.
   */
  @JsonProperty("calories", Number)
  calories: number = undefined!

  /**
   * Overall average heart rate.
   */
  @JsonProperty("avg_hr", Number)
  avgHr: number = undefined!

  /**
   * Overall average heart rate as percent of target (max) HR.
   */
  @JsonProperty("avg_hr_percent", Number)
  avgHrPercent: number = undefined!

  /**
   * Total "Target Points" for AKT - get one for every minute they're over 70% or something. Goal is 30 per class.
   *
   */
  @JsonProperty("iq_points", Number)
  iqPoints: number = undefined!

  /**
   * Overall lifetime percentage of time spent in each zone (1-5)
   */
  @JsonProperty("zones", [Number])
  zones: [number, number, number, number, number] = undefined!
}
