import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("ServiceTypeSummary")
export default class ServiceTypeSummary {
  /**
   * ClubReady `serviceId`
   */
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * ClubReady `serviceName`
   */
  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * ClubReady `serviceDesc`
   */
  @JsonProperty("description", String, true)
  description?: string = undefined!

  /**
   * ClubReady `displayOrder`
   */
  @JsonProperty("seq", Number, true)
  seq?: number = undefined!

  /**
   * ClubReady `allowCrossClubBooking`
   */
  @JsonProperty("allow_cross_club_booking", Boolean, true)
  allowCrossClubBooking?: boolean = undefined!

  /**
   * Slots, relative to the hour, when a booking for this service can start. So
   * `"00"` would mean it could be booked at 12:00, 1:00, 2:00, etc. `"30"`
   * would be 12:30, 1:30, 2:30, etc.
   *
   * Currently always in 15m increments. Corresponds to ClubReady `onthehour`,
   * `quarterpast`, `halfhour`, `quartertill`.
   *
   */
  @JsonProperty("valid_offsets", [String], true)
  slots: string[] = []
}
