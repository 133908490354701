import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("XpassV2FormRequest")
export default class XpassV2FormRequest {
  @JsonProperty("email", String)
  email: string = undefined!

  @JsonProperty("password", String)
  password?: string = undefined!

  @JsonProperty("first_name", String)
  firstName: string = undefined!

  @JsonProperty("last_name", String)
  lastName: string = undefined!

  @JsonProperty("phone_main", String)
  phoneMain?: string = undefined!

  @JsonProperty("zip", String, true)
  zip: string = undefined!

  /**
   * Payment source type
   */
   @JsonProperty("type", String, true)
   type: string = undefined!

  /**
   * Whether this is the default payment source
   */
   @JsonProperty("is_default", Boolean, true)
   isDefault: boolean = undefined!

  /**
   * Their credit card number, as a string.
   */
   ccNumber?: string // ^\d{8,24}$
  /**
  * Credit card CVV code.
  */
   ccSecurityCode?: string // ^\d{3,4}$
  /**
  * Two-digit month of CC expiration, as a string.
  */
   ccExpMonth?: string // ^\d\d$
  /**
  * Four-digit year of CC expiration, as a string.
  */
   ccExpYear?: string // ^\d\d\d\d$
  /**
  * First name on the created
  */

  base?: string = undefined!

  platform? = "web"
}
