import { observable, action, computed } from "mobx"
import { AxiosResponse, AxiosError } from "axios"
import getIn from "lodash/get"
import APIStore from "stores/APIStore"
import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import PackagePlan from "models/PackagePlan"
import LocationSummary from "models/LocationSummary"
import Offer from "models/Offer"
import Package from "models/Package"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import BrandStore from "stores/BrandStore"
import { isApiError, withAxiosError } from "helpers/errorHandling"

export interface Response {
  plan: PackagePlan
  package: Package
}

export default class PlanStore extends APIStore {
  @observable plan?: PackagePlan
  @observable package?: Package
  @observable promoCode?: string
  @observable giftCardNumber?: string

  @observable errorMessage?: string
  @observable errorCode?: string

  @observable originalPlan?: PackagePlan

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    TokenAuthMiddleware(this.brandStore.userStore),
    DeserializeMiddleware("plan", PackagePlan),
    DeserializeMiddleware("package", Package),
  ])

  @computed get discountApplied() {
    return (
      !!this.promoCode &&
      !!this.plan &&
      !!this.originalPlan &&
      this.originalPlan.todayTotal.numeric > this.plan.todayTotal.numeric
    )
  }

  @computed get originalPrice() {
    if (!this.discountApplied) return undefined

    return this.originalPlan!.todayTotal
  }

  @computed get discount() {
    if (!this.discountApplied) return 0

    return this.originalPrice!.numeric - this.plan!.todayTotal.numeric
  }

  @computed get crossClubIneligible() {
    return !this.brandStore.locStore.isHomeLocation && this.offer
  }

  constructor(
    public brandStore: BrandStore,
    public packageId: string,
    public locationSummary: LocationSummary,
    public offer?: Offer
  ) {
    super()
  }

  fetch(promoCode?: string) {
    if (this.crossClubIneligible) {
      return this.respondIneligible()
    }
    const locationSummary = getIn(this.locationSummary, "id", undefined)
    const url = this.brandStore.isXponential
      ? `/api/xpass/v3/packages/${this.packageId}`
      : `/api/locations/${locationSummary}/packages/${this.packageId}`

    return this.api
      .get(
        url,
        { params: { promo_code: promoCode } }
      )
      .then(() => {
        // only add once it's been successfully applied
        this.promoCode = promoCode
      })
  }

  @action.bound
  handleSuccess(res: AxiosResponse<Response>) {
    this.plan = res.data.plan
    this.package = res.data.package
    if (!this.originalPlan) this.originalPlan = res.data.plan

    return res
  }

  @action.bound
  handleError(ex: AxiosError) {
    const res = withAxiosError(ex)
    if (res) {
      this.errorCode = res.data.code
      this.errorMessage = res.data.message
    }
    throw ex
  }

  respondIneligible() {
    this.errorCode = "ineligible_specific"
    this.errorMessage = `An account at ${this.locationSummary.name} is required to proceed.`
    this.status = "error"
    return Promise.resolve()
  }
}
