import * as React from "react"
import { inject, observer } from "mobx-react"

import BrandStore from "stores/BrandStore"
import HomePromoStore from "./stores/HomePromoStore"
import PromoCard from "apps/dashboard/PromoCard"
import CardWrapper from "components/wrappers/CardWrapper"
import GooglePromoCard from "./GooglePromoCard"

interface Props {
  className?: string
  store?: BrandStore
  homePromoStore: HomePromoStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class PromoCards extends React.Component<Props> {
  render() {
    const { className, homePromoStore } = this.props
    const homePromoCards = homePromoStore.homePromoCards

    return (
      <>
        {homePromoCards && homePromoCards.map((card, i) => (
          <CardWrapper key={`PromoCards-${i}`}>
            {card.googleAd ? <GooglePromoCard card={card} /> : <PromoCard className={className} card={card} /> }
          </CardWrapper>
        ))}
      </>
    )
  }
}
