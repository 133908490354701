import * as React from "react"
import { observer } from "mobx-react"
import ErrorBoundary from "components/wrappers/ErrorBoundary"

interface CardWrapperProps {
  className?: string
}
const CardWrapper: React.FunctionComponent<CardWrapperProps> = ({ children, className }) => (
  <div className={className || "dashboard-card col-md-8 col-lg-6 col-xl-4 p-3"}>
    <ErrorBoundary
      error={props => (
        <div className="card h-100">
          {/* <div className="card-header bg-danger"> */}

          {/* </div> */}
          <div className="card-body text-center">
            <h3 className="alert-heading">An Error Has Occurred</h3>
            <p>We apologize for the inconvenience.</p>
            {/* TODO: real message */}
          </div>
        </div>
      )}
    >
      {children}
    </ErrorBoundary>
  </div>
)

export default observer(CardWrapper)
