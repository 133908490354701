import React, { Component } from "react"
import { observer } from "mobx-react"
import * as moment from "moment"
import BrandStore from "stores/BrandStore"
import PurchasesStore from "apps/account/stores/PurchasesStore"

export interface Props {
  store: BrandStore
  purchasesStore: PurchasesStore
}

@observer
export default class ServiceScheduleModal extends Component<Props> {
  render() {
    const { nonExpiredPurchases } = this.props.purchasesStore
    const dateFormat = this.props.store.styleClasses.ServiceMembershipsCard__dateFormat

    return (
      <>
        {nonExpiredPurchases && nonExpiredPurchases.length > 0 && (
          <>
            <h2>My Memberships</h2>
            <div className="pb-4">
              {nonExpiredPurchases.map(purchase => {
                const isRenewable = purchase.renewsAt
                return (
                  <div
                    className="col-md-6 col-lg-4 col-xl-3 card mb-2"
                    key={purchase.id}
                  >
                    <div className="px-1 pt-3 pb-2">
                      <h3 className="mb-0">{purchase.package.name}</h3>
                      <div className={`d-flex ${isRenewable ? "justify-content-between" : "justify-content-end"}`}>
                        {isRenewable && (
                          <p className="mb-0 small">
                            Renews: {moment(purchase.renewsAt).format(dateFormat)}
                          </p>
                        )}
                        <p className="mb-0 small">
                          Expires: {moment(purchase.creditsExpireAt).format(dateFormat)}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </>
    )
  }
}
