import React, { useEffect, useState } from "react"
import { Redirect, RouteComponentProps } from "react-router-dom"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"
import { Users } from "apps/auth/models/xpass/UserLookup"

import aktIcon from "images/xpass/icons/brands/akt.png"
import bftIcon from "images/xpass/icons/brands/bft.png"
import clubpilatesIcon from "images/xpass/icons/brands/clubpilates.png"
import cyclebarIcon from "images/xpass/icons/brands/cyclebar.png"
import purebarreIcon from "images/xpass/icons/brands/purebarre.png"
import rowhouseIcon from "images/xpass/icons/brands/rowhouse.png"
import rumbleIcon from "images/xpass/icons/brands/rumble.png"
import stretchlabIcon from "images/xpass/icons/brands/stretchlab.png"
import strideIcon from "images/xpass/icons/brands/stride.png"
import yogasixIcon from "images/xpass/icons/brands/yogasix.png"
import kinrgyIcon from "images/xpass/icons/brands/kinrgy.png"

export interface ClaimAccountProps extends RouteComponentProps {
  userAuthStore: UserAuthStore
}

const ClaimAccount = (props: ClaimAccountProps) => {
  const { userAccounts, verifiedAccounts } = props.userAuthStore
  const count = userAccounts && userAccounts.count
  const matchedAccounts = userAccounts && userAccounts.users
  const [hasVerifiedAccounts, setHasVerifiedAccounts] = useState(false)
  const [checkedAccounts, setCheckedAccounts] = useState(verifiedAccounts)

  useEffect(() => {
    const { activeEmail, verifiedEmails } = props.userAuthStore

    // Check if active email has been verified
    if (verifiedEmails.some(email => email === activeEmail)) {
      setHasVerifiedAccounts(true)
    }
  })

  const setBrandIcon = (brand: string) => {
    switch (brand) {
      case "akt":
        return aktIcon
      case "bft":
        return bftIcon
      case "clubpilates":
        return clubpilatesIcon
      case "cyclebar":
        return cyclebarIcon
      case "purebarre":
        return purebarreIcon
      case "rowhouse":
        return rowhouseIcon
      case "rumble":
        return rumbleIcon
      case "stretchlab":
        return stretchlabIcon
      case "stride":
        return strideIcon
      case "yogasix":
        return yogasixIcon
      case "kinrgy":
        return kinrgyIcon
      default:
        return aktIcon
    }
  }

  const handleCheckbox = (e: any, account: Users) => {
    const checked = [...checkedAccounts]
    if (e.target.checked) {
      checked.push(account)
    } else {
      const index = checked.findIndex(item => item.id === account.id)
      checked.splice(index, 1)
    }
    setCheckedAccounts(checked)
  }

  const handleVerify = () => {
    const email = props.userAuthStore.activeEmail
    props.userAuthStore.verifyEmail(email)
    props.history.push("/auth/register/verify")
  }

  const handleAdditional = () => {
    props.userAuthStore.setAddAdditional(true)
    props.history.push("/auth/register")
  }

  const handleClaim = () => {
    props.userAuthStore.setClaimedAccounts(checkedAccounts!)
    props.history.push("/auth/register/account")
  }

  const handleCancelClaim = () => {
    props.history.push("/auth/register/account")
  }

  // Matching Accounts
  let title = "Matching Accounts Found"
  let copy = (
    <>
      <p className="m-0">
        We found{" "}
        <span className="bold">
          {count === 1 ? `${count} studio account` : `${count} studio accounts`}{" "}
        </span>
        associated with this email address.
      </p>
      <p>Please verify your email address to continue.</p>
    </>
  )
  let accountsList = matchedAccounts

  // Claim Accounts
  if (hasVerifiedAccounts) {
    title = "Claim Accounts"
    copy = (
      <p className="m-0">
        Check or uncheck the accounts you would like to claim and combine into
        one XPASS account.
      </p>
    )
    accountsList = props.userAuthStore.verifiedAccounts!
  }

  // Redirect user in the case state is lost on page refresh
  if (!props.userAuthStore.initialEmail) {
    return (
      <Redirect to="/" />
    )
  }

  return (
    <div className="claim-account text-center mt-5">
      <h1>{title}</h1>
      <div className="col-md-6 mx-auto">{copy}</div>

      {!hasVerifiedAccounts && (
        <>
          <button
            className="btn btn-primary d-block mx-auto"
            onClick={handleVerify}
          >
            Send Verification Email
          </button>
          <small>
            <span className="link" onClick={handleCancelClaim}>
              Don't claim accounts and continue
            </span>
          </small>
        </>
      )}

      <div className="claim-account__list">
        {accountsList && accountsList.map((account, index) => {
          return (
            <div className="claim-account__list-item d-flex" key={index}>
              <div>
                <img
                  className="w-100"
                  src={setBrandIcon(account.brandSlug)}
                  alt={`${account.brandSlug}-icon`}
                />
              </div>
              <div className="text-left ml-4 mr-5">
                <h3 className="text-auto">
                  {account.brandName} {account.locationName}
                </h3>
                <p className="m-0">{account.email}</p>
              </div>

              {hasVerifiedAccounts && (
                <div className="claim-account__checkbox">
                  <input
                    type="checkbox"
                    className={`claim-account__checkbox-item ${
                      checkedAccounts!.some(
                        (item: Users) => item.id === account.id
                      )
                        ? "checked"
                        : ""
                    }`}
                    checked={checkedAccounts!.some(
                      (item: Users) => item.id === account.id
                    )}
                    onChange={e => handleCheckbox(e, account)}
                  />
                  <div className="checkbox" />
                </div>
              )}
            </div>
          )
        })}
      </div>

      {hasVerifiedAccounts && (
        <>
          <p className="mb-4">
            <small>
              Missing accounts at other XPASS studios?
              <span
                onClick={handleAdditional}
                className="link light-link d-block"
              >
                You can look up additional email addresses
              </span>
            </small>
          </p>
          <button
            className="btn btn-primary d-block mx-auto"
            onClick={handleClaim}
          >
            Claim Accounts and Combine
          </button>
          <small>
            <span className="link" onClick={handleCancelClaim}>
              Don't claim accounts and continue
            </span>
          </small>
        </>
      )}
    </div>
  )
}

export default ClaimAccount
