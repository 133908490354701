import * as React from "react"
import cx from "classnames"
import { InjectedFormikProps, withFormik, Form } from "formik"
import { Redirect } from "react-router-dom"
import * as yup from "yup"
import { observer, inject } from "mobx-react"
import SharedAccountControls from "apps/auth/components/SharedAccountControls"
import BrandStore from "stores/BrandStore"
import UserAuthStore from "../stores/xpass/UserAuthStore"
import FormInput from "components/forms/FormInput"

import FormButtons from "components/forms/FormButtons"
import UserRegistration from "apps/auth/models/UserRegistration"
import XpassUserRegistration from "apps/auth/models/xpass/XpassUserRegistration"
import FormAlert from "components/forms/FormAlert"
import FormDatePicker from "components/forms/FormDatePicker"
import FormDatePickerEnhanced from "components/forms/FormDatePickerEnhanced"
import { observable, action } from "mobx"
import LocationSelectorPanelField from "./LocationSelectorPanelField"
import WaiverField from "apps/auth/components/WaiverField"
import {
  phoneNumberValidator,
  nameValidator,
  birthDateValidator,
  passwordValidator,
  passwordConfirmationValidator,
} from "helpers/validations"
import handleRegistrationSubmit from "apps/auth/helpers/handleRegistrationSubmit"
import { PageName } from "models/TrackingSchemas"
import PageTracker from "components/wrappers/PageTracker"

// TODO: humanize field names in error messages

type FormValues = UserRegistration | XpassUserRegistration

export interface Props {
  store?: BrandStore
  userAuthStore: UserAuthStore
  pageName: PageName
  lockLocation?: boolean
  subheadBlock?: React.ReactNode
  onPageChange?(page: string): void
}

export interface State {
  revealPassword: boolean
  disableRevealPassword: boolean
}


@inject((store: BrandStore) => ({ store }))
@observer
class RegistrationFormInner extends React.Component<
 InjectedFormikProps<Props, FormValues>, State
> {
  constructor(props: InjectedFormikProps<Props, FormValues>) {
    super(props)
  }

  @observable termsAndConditionsChecked = false
  @action.bound
  onTermsAndConditionsCheck(checked: boolean) {
    this.termsAndConditionsChecked = checked
  }

  public render() {
    const {
      pageName,
      errors,
      isSubmitting,
      lockLocation,
      onPageChange,
      store,
      subheadBlock,
    } = this.props

    const { isXponential, userStore } = store!
    const { brandLocationEmail } = this.props.userAuthStore
    const defaultEmail =
      this.props.userAuthStore && this.props.userAuthStore.initialEmail
        ? this.props.userAuthStore.initialEmail
        : brandLocationEmail
          ? brandLocationEmail
          : undefined

    if (isXponential && !defaultEmail) {
      return (
        <Redirect to="/" />
      )
    }

    const queryParams = new URLSearchParams(document.location.search);

    return (
      <div
        className={cx(
          isXponential
            ? "col-md-8 col-lg-7 col-xl-8 mt-5 mx-auto"
            : "col-lg-8 offset-lg-2 col-xl-6 offset-xl-3"
        )}
      >
        <Form noValidate className="grid-in form-border">
          {subheadBlock ? (
            subheadBlock
          ) : (
            <>
            {
              queryParams.has('statusText') && queryParams.get('statusText') === 'Unavailable'
              ? (
                <>
                  <h1 className="text-center">Can't Redeem Offer</h1>
                  <p className="text-center">This offer isn't available at {store!.brand.name} {store!.locStore.currentLocation!.name} but you can still purchase a package at this location.</p>
                </>
              )
              : <h1 className="text-center">{store!.copy.createAccount}</h1>
            }
            </>

          )}
          <PageTracker name={pageName} />
          <FormAlert message={errors.base} />
          <div className="row">
            <FormInput
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              className="col-lg-6 required"
              maxLength={50}
            />
            <FormInput
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              className="col-lg-6 required"
              maxLength={50}
            />
          </div>
          <div className="row">
            {isXponential ? (
              <FormInput
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                className="col-lg-6 required"
                maxLength={100}
                disabled
                placeholder={defaultEmail}
              />
            ) : (
              <FormInput
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                className="col-lg-6 required"
                maxLength={100}
              />
            )}
            <FormInput
              label="Phone"
              name="phoneMain"
              placeholder={store!.copy.phonePlaceholder}
              autoComplete="mobile tel-national"
              className="col-lg-6 required"
              maxLength={20}
            />
          </div>
          <div className="row">
            {isXponential && (
              <FormInput
                label="Zip Code"
                placeholder="Zip Code"
                name="zip"
                autoComplete="postal-code"
                className="col-lg-6 required"
                maxLength={10}
              />
            )}
            {isXponential &&
              <FormDatePickerEnhanced
                label="Birthday"
                name="birthDate"
                autoComplete="bday"
                className="col-lg-6 dob-component required"
                dateFormat={store!.styleClasses.FormDatePickerEnhanced__dateFormat}
              />
            }
          </div>
          <div className="row">
            <FormInput
              label="Password"
              name="password"
              type="password"
              autoComplete="password"
              className="col-lg-6 required"
              maxLength={100}
            />
            <FormInput
              label={isXponential ? "Confirm Password" : "Repeat Password"}
              name="passwordConfirmation"
              type="password"
              autoComplete="password"
              maxLength={30}
              className="col-lg-6 required"
            />
          </div>
          {!userStore.isAppleWatch && !isXponential && (
            <div className="row">
              <FormDatePicker
                label="Date of Birth"
                name="birthDate"
                autoComplete="bday"
                className={cx(
                  "col",
                  isXponential ? "" : "required"
                )}
              />
            </div>
          )}

          {!isXponential && (
            <div className="row col-lg-12 mx-0 px-0" style={{ zIndex: 1 }}>
              <LocationSelectorPanelField
                className="col-lg-12"
                disabled={lockLocation}
              />
            </div>
          )}

          <div className="mb-4 text-left">
            <WaiverField
              checked={this.termsAndConditionsChecked}
              onChange={this.onTermsAndConditionsCheck}
            />
          </div>

          <FormButtons
            submitText={userStore.isAppleWatch ? "Go to apple.com" : "Sign Up"}
            disabled={isSubmitting || !this.termsAndConditionsChecked}
            centered
            minWidth={100}
          >
            <SharedAccountControls
              className="mt-3"
              onPageChange={onPageChange}
              currentPage="register"
            />
          </FormButtons>
        </Form>
      </div>
    )
  }
}

const schema = yup.object<FormValues>().shape({
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
  phoneMain: phoneNumberValidator.required().label("Mobile Phone"),
  password: passwordValidator.required().label("Password"),
  passwordConfirmation: passwordConfirmationValidator,
  birthDate: birthDateValidator.required().label("Date of Birth"),
  locationId: yup.string().required(),
})

const appleWatchSchema = yup.object<FormValues>().shape({
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
  phoneMain: phoneNumberValidator.required().label("Mobile Phone"),
  password: passwordValidator.required().label("Password"),
  passwordConfirmation: passwordConfirmationValidator,
  birthDate: yup.string().label("Date of Birth"),
  locationId: yup.string().required(),
})

const xpassSchema = yup.object<FormValues>().shape({
  firstName: nameValidator.required().label("First Name"),
  lastName: nameValidator.required().label("Last Name"),
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
  phoneMain: phoneNumberValidator.required().label("Mobile Phone"),
  password: passwordValidator.required().label("Password"),
  passwordConfirmation: passwordConfirmationValidator,
  zip: yup
    .string()
    .required()
    .label("Zip Code"),
  birthDate: birthDateValidator.required().label("Date of Birth"),
})

const RegistrationForm = withFormik<Props & { store?: BrandStore, userAuthStore?: UserAuthStore }, FormValues>(
  {
    mapPropsToValues: ({ store, userAuthStore }) => {
      if (store!.isXponential) {
        return {
          email: userAuthStore!.initialEmail || userAuthStore!.brandLocationEmail || "",
          firstName: "",
          lastName: "",
          phoneMain: "",
          zip: "",
          password: "",
          passwordConfirmation: "",
          birthDate: "",
        }
      }
      return {
        locationId: store!.locStore.currentLocation!.id,
        email: store!.routingStore.query.email || "",
        firstName: "",
        lastName: "",
        phoneMain: "",
        password: "",
        passwordConfirmation: "",
        birthDate: "",
        base: "",
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: (props: Props) => {
      const { isXponential, userStore } = props.store!
      return isXponential
        ? xpassSchema
        : userStore.isAppleWatch ? appleWatchSchema : schema
    },
    handleSubmit: (values, formikBag) => {
      if (formikBag.props.store!.isXponential) {
        const { claimedAccounts } = formikBag.props.userAuthStore!
        const claimedAccountIds =
          claimedAccounts && claimedAccounts.length > 0
            ? claimedAccounts.map(account => account.id)
            : undefined

        handleRegistrationSubmit(false, values, formikBag, claimedAccountIds)
      } else {
        handleRegistrationSubmit(false, values, formikBag)
      }
    },
  }
)(RegistrationFormInner)

export default RegistrationForm
