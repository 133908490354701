import { Metric } from "apps/history/models/Stats"
import { SvgName } from "components/Svg"
import { formatNumber, FormatNumber } from "helpers/numberFormatter"
import bannerImage from "images/dashboard/generic.jpg"
import appleWatchLogo from "images/logos/logo_apple-watch.png"

import appleAppStoreImage from "images/dashboard/apple_app_store.png"
import googlePlayStoreImage from "images/dashboard/google_play_store.png"

import aktVODIcon from "images/vod_icons/akt.svg"
import bftVODIcon from "images/vod_icons/bft.svg"
import cyclebarVODIcon from "images/vod_icons/cyclebar.svg"
import clubpilatesVODIcon from "images/vod_icons/clubpilates.svg"
import purebarreVODIcon from "images/vod_icons/purebarre.svg"
import rowhouseVODIcon from "images/vod_icons/rowhouse.svg"
import rumbleVODIcon from "images/vod_icons/rumble.svg"
import stretchlabVODIcon from "images/vod_icons/stretchlab.svg"
import strideVODIcon from "images/vod_icons/stride.svg"
import yogasixVODIcon from "images/vod_icons/yogasix.svg"

export const baseSettings = {
  isPerformance: false, // not actually used
  hasLeaderboard: false,
  hasWeight: false,
  hasShoeSize: false,
  showPromoOnEmptyPerf: false,
  serviceBooking: false,
  morePackagesInStore: false,
  packagesFirstOnBooking: false,
  freeSpotsThreshold: 5,
  billingHistoryEnabled: true,
  vodEarlySubscriberDiscount: 5,
  printMySchedule: false,
  showAddToCalendarButton: false,
  showRemainingCredit: true,
  allowBookingOnWaitlistClosed: false,
  showBuyRedirectButton: false,
  alwaysShowAppleWatchIcon: false,
  badgeBitmapHeight: 48,
  badgeBitmapWidth: 48,
  disallowACHPayment: false,
  billingCountryCode: "US",
  showClassPoints: window.globals.classPointsEnabled || false,
  classPointsTermsUrl: "https://www.classpoints.com/terms-of-use",
  classPointsLowestTierName: 'Basic',
  classPointsLowestTierId: 'basic',
  showGiftCardBalanceLink: true,
}

export const baseStyles = {
  chartColor: "#000000",
  bannerImage: bannerImage,
  appleWatchLogo: appleWatchLogo,
  // might not quite work as we might want two different formatters - one for
  // progress bar and one for showing it standalone
  badgeFormatter: formatNumber as FormatNumber,
  appleAppStoreImage,
  googlePlayStoreImage,
  aktVODIcon,
  bftVODIcon,
  cyclebarVODIcon,
  clubpilatesVODIcon,
  purebarreVODIcon,
  rowhouseVODIcon,
  rumbleVODIcon,
  stretchlabVODIcon,
  strideVODIcon,
  yogasixVODIcon,
  vodIcons: {
    aktVODIcon,
    bftVODIcon,
    cyclebarVODIcon,
    clubpilatesVODIcon,
    purebarreVODIcon,
    rowhouseVODIcon,
    rumbleVODIcon,
    stretchlabVODIcon,
    strideVODIcon,
    yogasixVODIcon,
  },
  xpassMarketingIcons: {
    purebarreVODIcon,
    clubpilatesVODIcon,
    cyclebarVODIcon,
    yogasixVODIcon,
    stretchlabVODIcon,
    aktVODIcon,
    bftVODIcon,
    strideVODIcon,
    rowhouseVODIcon,
    rumbleVODIcon,
  }
}

export const baseStyleClasses = {
  AppleHealthCard__listItem: "small",
  AppleHealthCard__title: "py-3",
  AttendanceCard__dateFormat: "MM/DD/YYYY",
  AttendanceCard__locationName: "name",
  AttendanceCard__progressHeader: "",
  BillingHistory__dateFormat: "MM/DD/YY",
  BookClassSubNav__dateFormat: "m/d/YY",
  BookingInfoCard__container: "",
  BookingInfoCard__entryTitle: "",
  BookingInfoCard__entrySubtitle: "",
  BookingInfoCard__entryWaitlist: "",
  BookingInfoCard__entryDescription: "",
  BookingInfoCard__entryLocationName: "mb-0",
  BookingInfoCard__subtitle: "text-large mb-4",
  BookingSuccessPage__confirmedLinks: "",
  BookingSuccessPage__viewScheduleLink: "",
  ChallengeCard__dateFormat: "MM/DD/YYYY",
  ConfirmBookingPage__confirmButton: "",
  FiltersModal__dateFormat: "m/d/Y",
  FormDatePickerEnhanced__dateFormat: "m/d/Y",
  HealthCheck__card: "mb-5",
  HealthCheck__cardBody: "text-left",
  HealthCheck__cardButton: "",
  MainMenu__container: "bg-white",
  MySchedulePage__dateFormat: "dddd, MM/DD",
  MySchedulePage__entryStartDate: "alert alert-light shadow-sm",
  MySchedulePage__entrySeatLabel: "badge badge-primary p-2",
  NextClassCard__dateFormat: "MM/DD/YY",
  PackageCard__card: "h-100",
  PackageCard__cardText: "d-flex d-md-block",
  PackageCard__cardTextPackageName: "d-md-block",
  PackageCancellationText_dateFormat: "MMMM D, YYYY",
  PackageRenewalText_dateFormat: "M/D/YYYY",
  PackagesList__memberships: "",
  PaymentPage__purchaseCard: "",
  PickSeatPage__instructorName: "",
  PickSeatPage__locationName: "mb-0",
  PickSeatPage__seat: "seat",
  PickSeatPage__seatTaken: "seat-unavailable",
  PickSeatPage__seatSelected: "seat-selected",
  PickSeatPage__spotSelection: "",
  PurchaseCard__dateFormat: "MM/DD/YYYY",
  PurchasesTable__dateFormat: "MM/DD/YYYY",
  PurchaseForm__button: "",
  ScheduleEntryFilters__dateFormat: "m/d/Y",
  ScheduleDayNav__dateFormat: "MM/DD",
  ScheduleWeekNav__dateFormat: "M/D/YY",
  ScheduleWeekNav__link: "",
  ServiceMembershipsCard__dateFormat: "M/D/YY",
  ServiceTypeSchedule__sessionDateFormat: "M/D/YYYY",
  ServiceTypeSchedule__whenDateFormat: "M/D",
  TodaysClassCard__favoriteHeartWrapper: "mr-2 mr-md-3",
}

export const baseCopy = {
  activity: "Activity",
  addOns: "Add-Ons",
  tokens: "tokens",
  attend: "attend",
  attendanceCard: "Set a weekly attendance goal and track your progress.",
  attended: "attended",
  badgeTitle: "My All-Time Goals",
  bookClass: "Book",
  bookingCancellationTitle: "Cancel {CLASS/SESSION}",
  bookingCancellationBody: "Are you sure you want to cancel this {CLASS/SESSION}?",
  bookingLateCancellationBody: "Are you sure? This is a Late Cancellation, so credits used towards this {CLASS/SESSION} will not be refunded, and a penalty may be assessed.",
  bookingCancellationConfirmButton: "Yes, Cancel It",
  bookingCancellationDeclineButton: "Don't Cancel",
  bookingSelectHeader: "Select a {SPOT}",
  bookingSelectCopy: "",
  bookingSelectButton: "Select this {SPOT}",
  bookingErrorTitle: "Class Credit Needed",
  bookingLabel: "Please select one of the options below to book.",
  bookingPageLabel: "",
  buyCredits: `Get Started`,
  buyRedirectText: "Get Credits",
  class: "class",
  classes: "classes",
  classPackages: "Here are our class memberships and packages.",
  comingSoonOffersDescription: "Browse our limited-time pre-open offers.",
  comingSoonOffersCta: "Offers",
  distanceUnit: "mi",
  phonePlaceholder: "(XXX) XXX-XXXX",
  weightUnit: "lbs",
  zipLabel: "ZIP Code",
  createAccount: "Create an Account",
  credit: "credit",
  credits: "credits",
  dashCardBrands: "Brands",
  dashCardRewards: "Rewards",
  dashCardMembership: "Membership",
  findAClass: "Find a Class",
  groupClasses: "Group Classes",
  incentiveOfferTitle: "Wait, we have an offer for you.",
  instructor: "Instructor",
  instructors: "Instructors",
  serviceInstructor: "Instructor",
  location: "Studio",
  locations: "Studios",
  seat: "Seat",
  seats: "Seats",
  user: "User",
  users: "Users",
  mainMenuClasses: "classes",
  memberships: "Memberships",
  membershipsClassText: "credits",
  membershipsButtonText: "",
  mobileAppAvailable: "Download our Mobile App",
  myBest: "My Best",
  myWeeklyGoal: "My Weekly Goal",
  nextClassHeader: "View",
  oneOnOneSessions: "1:1 Sessions",
  packages: "Packages",
  packageListHeader: "",
  paymentMethod: "Payment Method",
  prevText: "Prev",
  print: "Print",
  purchases: "Memberships",
  saveChanges: "Save Changes",
  session: "session",
  titleProfileInfo: "Profile Information",
  vodPlatform: "On Demand",
  vodOfferDescription:
    "Experience {BRAND_NAME} from the comfort of your own home.\n\n{BRAND_NAME} GO features more than {GO_HOURS} hours of audio and video classes available, with new classes coming online each week.\n\nAfter your trial, {BRAND_NAME} GO is only {GO_PRICE}/month.",
  noUpcomingClassesBooked: "It looks like you don't have any upcoming {CLASSES} booked. It's important to keep up with your goals.",
  classPointsBullet1: "Earn points by attending classes and other activities.",
  classPointsBullet2: "Win exclusive gifts ranging from Classes to Cruises.",
  classPointsBullet3: "100% and always Free.",
}

export const baseDashboardCards: BrandDashboardCards = [
  "NextClassCard",
  "TodaysClassCard",
  "AttendanceCard",
  "BadgeCard",
  "ProfileCard",
  "MobileAppCard",
  "MobileOfferCard",
  "OfferCard",
]

export type BrandSettings = typeof baseSettings
export type BrandStyles = typeof baseStyles & {
  logoUrl: string
  badgeImage?: string
  challengeBadge?: string
  bannerImage?: string
  appPromoImage_Perf?: string
  appPromoImage_Video?: string
  videoLandingLogo?: string
  appleWatchIcon?: string
  classpointsHero?: string
  brandIcon?: string
  indigoHero?: string
  appStore?: string
  googlePlayStore?: string
}
export type BrandStyleClasses = typeof baseStyleClasses
export type BrandCopy = typeof baseCopy

export interface BrandData {
  settings: BrandSettings
  styles: BrandStyles
  styleClasses: BrandStyleClasses
  copy: BrandCopy
  dashboardCards: BrandDashboardCards
  badges: Badge[]
  metrics: Metric[]
  summaryMetrics: Metric[]
  heartRateZoneColors?: HeartRateZoneColors[]
  logbookEnabled?: boolean
  includeTerms?: boolean
  eywAffiliateLinks?: Object
  eywLegalLink?: string
  eywParticipatingLink?: string
  googlePlayStoreLink?: string
  appStoreLink?: string
}

export type DashboardCardName =
  | "NextClassCard"
  | "TodaysClassCard"
  | "AttendanceCard"
  | "MembershipCard"
  | "ProfileCard"
  | "PerformanceCard"
  | "MobileAppCard"
  | "MobileOfferCard"
  | "BadgeCard"
  | "AchievementsCard"
  | "YogaSixFebruaryChallengeCard"
  | "RewardsCard"
  | "MyStatsCard"
  | "MyStudioCard"
  | "AppleHealthCard"
  | "OfferCard"
  | "PromoCards"
  | "ClassPointsCard"

export type BrandDashboardCards = DashboardCardName[]

export interface Badge {
  value: number
  label: string
}

export interface BadgeSvg extends Badge {
  svg: SvgName
}

export interface BadgeBitmap extends Badge {
  active: string
  inactive: string
}

export interface AchievementCard {
  heading: React.ReactNode
  body: React.ReactNode
  icon: string
}

export interface AchievementOptIn extends AchievementCard {
  name: string
  cta: string
}

export interface Achievement extends AchievementCard {
  value: number
}

export interface HeartRateZoneColors {
  name: string
  color: string
}

export const eywAffiliateLinks = {
  clubpilates: {
    US: {
      clubready_referral_type_id: "109905",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2600732/435031/7613?u=https%3A%2F%2Fwww.apple.com%2Fwatch%2F&subid1=",
    },
    CA: {
      clubready_referral_type_id: "109911",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2600732/435395/7636?u=https%3A%2F%2Fwww.apple.com%2Fca%2Fwatch%2F&subid1=",
    },
  },
  cyclebar: {
    US: {
      clubready_referral_type_id: "109902",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2611027/435031/7613?u=https%3A%2F%2Fwww.apple.com%2Fwatch%2F&subid1=",
    },
    CA: {
      clubready_referral_type_id: "109903",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2611027/435395/7636?u=https%3A%2F%2Fwww.apple.com%2Fca%2Fwatch%2F&subid1=",
    },
  },
  purebarre: {
    US: {
      clubready_referral_type_id: "109904",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2600795/435031/7613?u=https%3A%2F%2Fwww.apple.com%2Fwatch%2F&subid1=",
    },
    CA: {
      clubready_referral_type_id: "109912",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2600795/435395/7636?u=https%3A%2F%2Fwww.apple.com%2Fca%2Fwatch%2F&subid1=",
    },
  },
  akt: {
    US: {
      clubready_referral_type_id: "109906",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2600730/435031/7613?u=https%3A%2F%2Fwww.apple.com%2Fwatch%2F&subid1=",
    },
  },
  rowhouse: {
    US: {
      clubready_referral_type_id: "109907",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2606194/435031/7613?u=https%3A%2F%2Fwww.apple.com%2Fwatch%2F&subid1=",
    },
  },
  stretchlab: {
    US: {
      clubready_referral_type_id: "109909",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2600422/435031/7613?u=https%3A%2F%2Fwww.apple.com%2Fwatch%2F&subid1=",
    },
  },
  stride: {
    US: {
      clubready_referral_type_id: "109908",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2600724/435031/7613?u=https%3A%2F%2Fwww.apple.com%2Fwatch%2F&subid1=",
    },
  },
  yogasix: {
    US: {
      clubready_referral_type_id: "109910",
      eyw_affiliate_link:
        "https://apple.sjv.io/c/2600733/435031/7613?u=https%3A%2F%2Fwww.apple.com%2Fwatch%2F&subid1=",
    },
  },
}
