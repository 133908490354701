import { observable, action } from "mobx"
import { AxiosResponse, AxiosError } from "axios"

import UserCoordinates from "apps/book/models/xpass/UserCoordinates"
import APIStore from "stores/APIStore"

import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"

export interface UserCoordinatesResponse {
  coordinates: UserCoordinates
}

export default class UserCoordinatesStore extends APIStore {
  @observable userCoordinates: UserCoordinates | undefined
  @observable locationCoordinates: UserCoordinates | undefined
  @observable defaultCoordinates?: UserCoordinates

  api = this.createClient([
    ResponseMiddleware(this.handleSuccess),
    DeserializeMiddleware("coordinates", UserCoordinates),
  ])

  fetch() {
    const url = '/api/xpass/coordinates'
    return this.api.get(url)
  }

  // Center point of studio locations in selected city
  @action setLocationCoordinates(coordinates: UserCoordinates | undefined) {
    this.locationCoordinates = coordinates
  }

  // Set center point on map
  @action setCoordinates(coordinates: UserCoordinates) {
    this.userCoordinates = coordinates
  }

  @action.bound
  handleSuccess(res: AxiosResponse<UserCoordinatesResponse>) {
    // Preset coordinates to Scottsdale (Zipcode: 85250)
    const presetCoordinates = { lat: 33.5393397227932, lng: -111.9234559325 }
    const isPreset = window.globals.xpassPresetCoordinates
    const coordinates = isPreset ? presetCoordinates : res.data.coordinates

    this.userCoordinates = coordinates
    if (!this.defaultCoordinates) {
      this.defaultCoordinates = coordinates
    }
    return res
  }

  @action.bound
  handleError(err: AxiosError) {
    console.error(err)
    throw err
  }
}
