import * as React from "react"
import { inject, observer } from "mobx-react"
import { Link } from "react-router-dom"
// import * as cx from "classnames"

import BrandStore from "stores/BrandStore"

interface Props {
  store?: BrandStore
  link: boolean
  className: string
  linkClassName: string
}

// defaultProps don't typecheck if annotated as React.SFC
const NotHomeWarning = (props: React.PropsWithChildren<Props>) => {
  const { locStore, userStore, locationPickerStore } = props.store!
  let { className, linkClassName, link } = props
  if (locStore.isHomeLocation || !userStore.session) {
    return null
  }

  if (link) {
    const locationLink = locationPickerStore.getLocationLink(
      userStore.session!.locationId
    )
    if (!locationLink) {
      return null
    }

    return (
      <p className={className}>
        Your home studio is{" "}
        <Link to={locationLink} className={linkClassName}>
          {props.store!.brand.name} {userStore.homeLocation!.name}
        </Link>
        {props.children}
      </p>
    )
  } else {
    return (
      <p className={className}>
        Your home studio is{" "}
        <strong>
          {props.store!.brand.name} {userStore.homeLocation!.name}
        </strong>
        {props.children}
      </p>
    )
  }
}

NotHomeWarning.defaultProps = {
  link: true,
  className: "text-danger",
  linkClassName: "text-danger",
}

export default inject((store: BrandStore) => ({
  store,
}))(observer(NotHomeWarning))
