import React, { useEffect, useRef, useState } from 'react'
import * as moment from "moment"

import BrandStore from "stores/BrandStore"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import UserStore from "stores/UserStore"
import DashboardMainStore from "apps/classpoints/stores/DashboardMainStore"
import ActivityStore from "apps/classpoints/stores/ActivityStore"
import Activity, { ActivityMeta } from "../models/Activity"

import Heading from "components/Heading"
import PageTracker from "components/wrappers/PageTracker"
import backArrow from "images/classpoints/arrow-narrow-left.svg"
import Spinner from 'components/Spinner'

export interface Props extends RouteComponentProps {
  store?: BrandStore
}

export default function ActivityPage(props: Props) {
  const store = props.store
  const loader = useRef(null)
  const userStore = new UserStore(store!)
  const dashboardMainStore = new DashboardMainStore(store!)
  const [banner, setBanner] = useState('')
  const activityStore = new ActivityStore(store!)
  const [activity, setActivity] = useState(activityStore.activity)
  const [activityMeta, setActivityMeta] = useState<ActivityMeta>()
  const [isFetching, setIsFetching] = useState(true)
  const [moreEntries, setMoreEntries] = useState(true)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const fetchCall = async () => {
    if (hasLoaded) {
      const pageNext = activityMeta!.pagination!.nextPage
      if (moreEntries && pageNext) {
        activityStore.setPage(pageNext)
      }
    }

    if (!hasLoaded || moreEntries) {
      activityStore.fetch()
        .then(() => {
          const {activity, activityMeta} = activityStore
          if (!activityMeta!.pagination!.nextPage) {
            setMoreEntries(false)
          }
          setActivity((prevState: Activity[]) => [
            ...prevState,
            ...activity!,
          ])
          setActivityMeta(activityMeta)
          setIsFetching(false)
          if (!hasLoaded) setHasLoaded(true)
        })
        .catch(err => {
          console.log(err)
          setIsFetching(false)
        })
    }
  }

  useEffect(() => {
    // Render banner when it loads
  }, [banner])

  useEffect(() => {
    if (hasLoaded && activity && activityMeta) {
      const metadata = activityMeta!.pagination
      if (!metadata!.nextPage) {
        setMoreEntries(false)
      }

      const observerItem = new IntersectionObserver(
        ([item]) => {
          if (item.isIntersecting) {
            setIsFetching(true)
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.3,
        }
      )

      if (loader && loader.current!) {
        observerItem.observe(loader.current!)
      }

      return () => {
        if (loader && loader.current!) {
          observerItem.unobserve(loader.current!)
        }

        // dispose on unmount
        userStore.dispose()
        dashboardMainStore.dispose()
      }
    } else return
  }, [hasLoaded, activity, activityMeta])

  useEffect(() => {
    if (!isFetching) return
    setTimeout(() => {
      fetchCall()
    }, 2000)
  }, [isFetching])

  useEffect(() => {
    if (!initialized) {
      userStore.fetch().then(() => {
        const optedIn = userStore.session!.classPointsOptedIn || false
        if (!optedIn) props.history.push('/classpoints')
      })
      dashboardMainStore.fetch().then(() => {
        setBanner(dashboardMainStore.banner || '')
      })
      setInitialized(true)
    }
  }, [])

  return (
    <>
      {banner && (
        <div className="classpoints-banner">{banner}</div>
      )}
      <div className={`row dashboard-hero justify-content-center ${banner && 'pt-3'}`}>
        <Link to="/classpoints" className="classpoints-back-link d-flex col col-12 align-items-center back-arrow text-decoration-none text-dark mt-4">
          <img src={backArrow} className="pr-2" /> ClassPoints<span className="tm">™</span> Home
        </Link>
        <Heading className="h1 col col-12 text-small">ClassPoints<span className="tm">™</span> Activity</Heading>
        <PageTracker name="classpoints > activity" />
      </div>

      <div className="classpoints classpoints-rewards row">
        {activity.map((a, i) => (
          <ActivityRow key={`${a.id}_${i}`} activity={a} />
        ))}
        {!activity.length && !isFetching && (
          <div className="d-flex flex-column col-12 pt-5 pb-4">
            No activity found.
          </div>
        )}
      </div>
      <div className="loader" ref={loader}>
        {moreEntries && (
          <div className="pb-5">
            {isFetching && <Spinner size="element" />}
          </div>
        )}
      </div>
    </>
  )
}

const ActivityRow = ({activity}: {activity: Activity}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div className="d-flex flex-column col-12 pt-2 pb-4">
      <div className="d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap">
        <div className="classpoints-activity__left d-flex align-items-center font-24 font-600">{activity.name}</div>
        {activity.description && (
          <button className="classpoints-activity__center btn-none d-flex align-items-start" onClick={() => setIsOpen(!isOpen)}>
            <span className={`arrow ${isOpen ? 'open' : ''}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M8.10084 8.6249L6.73364 10.0249L12.5931 16.0249L18.4525 10.0249L17.0853 8.6249L12.5931 13.2249L8.10084 8.6249Z" fill="black"/>
              </svg>
            </span>
            <span className="pl-2 color-green">{activity.rewardsText}</span>
          </button>
        )}
        {!activity.description && (
          <div className="classpoints-activity__center d-flex align-items-start">
            <span className="pl-2 color-green">{activity.rewardsText}</span>
          </div>
        )}
        <div className="classpoints-activity__right d-flex align-items-center justify-content-start justify-content-md-end font-14 py-2 py-md-0 pr-md-4">{moment(activity.earnedAtDate).format('M/D/yyyy')}</div>
      </div>
      {activity.description && (
        <div className={`classpoints-rewards__drawer font-14 pt-3 ${isOpen && 'open'}`}>
          <div>{activity.description}</div>
        </div>
      )}
    </div>
  )
}
