import * as React from "react"
import cx from "classnames"
import { InjectedFormikProps, withFormik, Form } from "formik"
import * as yup from "yup"
import { observer, inject } from "mobx-react"
import SharedAccountControls, {
  AccountLink,
} from "apps/auth/components/SharedAccountControls"
import { AxiosError } from "axios"
import { LoginValues as FormValues } from "apps/auth/stores/AuthStore"
import { isAuthError } from "helpers/errorHandling"
import BrandStore from "stores/BrandStore"
import UserAuthStore from "apps/auth/stores/xpass/UserAuthStore"
import FormInput from "components/forms/FormInput"
import FormButtons from "components/forms/FormButtons"
import FormAlert from "components/forms/FormAlert"
import LocationSelectorPanelField from "./LocationSelectorPanelField"
import { passwordValidator } from "helpers/validations"
import RememberMe from "./RememberMe"
import { clearState, saveState, loadState } from "services/savedState"
import { Link } from "react-router-dom"
import { PageName } from "models/TrackingSchemas"
import PageTracker from "components/wrappers/PageTracker"
import { setFriendlyRoute } from "services/friendlyRoute"

export interface Props {
  pageName: PageName
  subheadBlock?: React.ReactNode
  store?: BrandStore
  userAuthStore?: UserAuthStore
  lockLocation?: boolean
  rememberKey?: string
  emailKey?: string
  storedEmail?: string
  openXpassModal?: Function
  onPageChange?(page: string): void
}

export interface State {
  revealPassword: boolean
  disableRevealPassword: boolean
}

@observer
class LoginForm extends React.Component<InjectedFormikProps<Props, FormValues>, State> {
  constructor(props: InjectedFormikProps<Props, FormValues>) {
    super(props)
    this.state = {
      revealPassword: false,
      disableRevealPassword: true,
    }
  }

  revealPasswordToggle = () => {
    this.setState(prevState => ({ revealPassword: !prevState.revealPassword }));
  }

  handleChange = () => {
    this.setState({ disableRevealPassword: this.props.values.password.length <= 0 })
  }

  componentWillMount() {
    const isAppleWatch = this.props.store!.routingStore.query.eyw
    if (isAppleWatch) {
      this.props.store!.userStore.isAppleWatch = isAppleWatch === "true"
    }
  }

  handleXpass = (e: ButtonEvent) => {
    window.location.href = window.globals.brandDomains['xponential'] + '/auth/login?redirect=true&location_id=xponential-xpass&brand_location_id=' + this.props.initialValues.locationId
  }

  render() {
    const {
      pageName,
      touched,
      errors,
      isSubmitting,
      onPageChange,
      rememberKey,
      emailKey,
      store,
      openXpassModal,
    } = this.props

    const { isXponential, userStore } = store!
    const base = "base" // TODO: Fix this type
    let signInTitle = "Sign In"

    if (this.props.store!.routingStore.query.post_purchase) {
      signInTitle = "Purchase Success. Now Sign in to Book."
    } else if (this.props.store!.routingStore.query.already_purchased) {
      signInTitle = "Already Purchased. Sign In."
    }

    return (
      <Form
        noValidate
        className={cx(
          isXponential
            ? "col-md-8 col-lg-5 mt-5 mx-auto form-border"
            : "col-md-10 offset-md-1 col-lg-6 offset-lg-3 grid-in try-xpass"
        )}
      >
        {userStore.isAppleWatch && (
          <div className="text-center py-3">
            <img
              src={store!.brandData.styles.logoUrl}
              alt={`${store!.brand.name} logo`}
              style={{ width: "100%", maxWidth: "200px" }}
            />
            <div className="pt-3">
              <div className="h4 pt-3 font-weight-normal mb-0">
                Sign in before you purchase your new Apple Watch and be eligible
                for our&nbsp;
                <strong>Earn Your Watch Challenge</strong>.*
              </div>
              <div className="h4 font-weight-normal">
                Not a member?{" "}
                <Link to="/auth/register" className="text-dark">
                  Create an Account
                </Link>
              </div>
            </div>
          </div>
        )}

        {this.props.subheadBlock}

        <div
          className={cx("text-center", "slide-in", {
            h1: !this.props.subheadBlock,
            "mb-3": !this.props.subheadBlock,
            "d-none": !!this.props.subheadBlock,
          })}
        >
          {signInTitle}
        </div>
        {(this.props.store!.routingStore.query.post_purchase || this.props.store!.routingStore.query.already_purchased) && (
          <div className="text-center">
            <p>If you're new to our studio, or have never signed in before, click "Forgot your password" to reset your password and sign in to book.</p>
          </div>
        )}
        <PageTracker name={pageName} />
        {this.props.subheadBlock && (
          <SharedAccountControls
            className="mb-3"
            onPageChange={onPageChange}
            currentPage="login"
            brandText={this.props.store!.brand.name}
            showForgotPassword={false}
          />
        )}

        {!isXponential && (
          <LocationSelectorPanelField disabled={this.props.lockLocation} />
        )}

        <FormAlert message={errors[base]} />

        <FormInput
          label="Email Address"
          name="email"
          type="email"
          autoComplete="email"
          className=""
          maxLength={100}
        />
        <div className="row">
          <div className="col-12" style={{ marginRight: "-13%" }}>
            <FormInput
              label="Password"
              name="password"
              type={this.state.revealPassword ? "input" : "password"}
              autoComplete="password"
              className="mb-2 "
              onKeyUp={this.handleChange}
              maxLength={100}
            />
          </div>
          {isXponential && (
            <>
              {this.state.disableRevealPassword && (
                <p className="col-push-2 reveal-password">
                  {
                    isXponential
                    ? (
                      <span className='input-btn-password blank-password-reveal d-block'></span>
                    )
                    : <span>&nbsp;Reveal&nbsp;</span>
                  }
                </p>
              )}
              {!this.state.disableRevealPassword && (
                <small
                  className="col-push-2"
                  style={{ paddingTop: "31px", marginLeft: "-4%", zIndex: 1 }}
                >
                  <Link
                    to={`/auth/forgot_password`}
                    className={`account-controls-link input-btn-password ${isXponential && this.state.revealPassword ? 'password-hide' : 'password-reveal'}`}
                    onClick={e => {
                      e.preventDefault()
                      this.revealPasswordToggle()
                    }}
                  >
                    <span>
                    {this.state.revealPassword ? "Hide" : "Reveal"}
                    </span>
                    
                  </Link>
                </small>
              )}
            </>
          )}
        </div>
                    
      <div className="flex space-between mt-3">
      <RememberMe
          rememberKey={rememberKey}
          emailKey={emailKey}
        />

      <small className="d-block text-right">
          <AccountLink
            onPageChange={onPageChange}
            currentPage="login"
            page="forgot_password"
          >
            Forgot your password?
          </AccountLink>
        </small>
      </div>
      

        {userStore.isAppleWatch ? (
          <div className="text-center">
            <FormButtons
              submitText="Go to apple.com"
              disabled={isSubmitting}
              centered
              className="float-in delay-4 eyw-login"
            />
            <div className="pt-3 eyw-login">
              <Link className="btn btn-dark" to="/auth/register">
                Create an Account
              </Link>
            </div>
            <div className="pt-3">
              <small>
                *Participating locations only,{" "}
                <a
                  href={store!.brandData.eywParticipatingLink || "#"}
                  target="_blank"
                >
                  view here
                </a>
                . Apple Watch purchase alone does not enter you into the
                challenge, please review the program's{" "}
                <a href={store!.brandData.eywLegalLink || "#"} target="_blank">
                  terms and conditions
                </a>
                .
              </small>
            </div>
            <div className="pt-3">
              <a
                href={store!.eywAffiliateLink(true) || "#"}
                className="text-dark h6 font-weight-normal"
              >
                {`No thanks, I just want an Apple Watch and will not be
                participating in ${store!.brand.name} Challenges.`}
              </a>
            </div>
            <div className="pt-5 pb-3">
              <img
                className="w-100"
                src={store!.brandData.styles.appleWatchLogo}
                alt="Apple Watch logo"
                style={{ maxWidth: "100px" }}
              />
            </div>
          </div>
        ) : (
          <FormButtons
            submitText="Sign In"
            disabled={isSubmitting}
            centered
            className="float-in delay-4"
            minWidth={100}
          >
            {!this.props.subheadBlock && (
              <SharedAccountControls
                className="mt-3"
                onPageChange={onPageChange}
                currentPage="login"
                brandText={this.props.store!.brand.name}
                showForgotPassword={false}
                userAuthStore={this.props.userAuthStore}
              />
            )}
          </FormButtons>
        )}

        {/* XPASS login redirect */}
        {!isXponential && !window.globals.xpassPhase3Disabled && (
          <>
            <div className="text-center p-2 mt-2"> - OR - </div>
            <div className="float-in delay-4">
              <div className="row mt-3">
                <div className="col-lg-12 justify-content-center text-center">
                  <button
                    className="xpass-login-button"
                    type="button"
                    onClick={this.handleXpass}
                  >
                    Sign in with XPASS
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="shared-account-controls mt-3">
                  <small id="xpass-link">
                    Learn more about{" "}
                    <Link
                      to={`/auth/register`}
                      className="account-controls-link"
                      onClick={e => {
                        if (openXpassModal) {
                          e.preventDefault()
                          openXpassModal()
                        }
                      }}
                    >
                      XPASS login
                    </Link>
                  </small>
                </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Form>
    )
  }
}

const schema = yup.object<FormValues>().shape({
  locationId: yup.string().required(),
  email: yup
    .string()
    .required()
    .label("Email Address")
    .email(),
  password: passwordValidator.required().label("Password"),
})

const FormikWrapper = withFormik<Props, FormValues>({
  mapPropsToValues: props => ({
    locationId: props.store!.locStore.currentLocation
      ? props.store!.locStore.currentLocation!.id
      : "",
    email: props.store!.routingStore.query.email || props.storedEmail || "",
    password: "",
    mobileOauth: props.userAuthStore!.mobileOauth,
    mobileBrand: props.userAuthStore!.mobileBrand,
    mobilePlatform: props.userAuthStore!.mobilePlatform,
  }),
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: schema,
  handleSubmit: (values, formikBag) => {
    const { authStore, uiStore, locStore, track, isXponential } = formikBag.props.store!
    const rememberMe: boolean = loadState(formikBag.props.rememberKey!)
    const vodOfferFlow: boolean = loadState("vodOfferFlow", true)
    const loc = locStore.currentLocation
    // brandLocationId and allowRedirect for when user is directed to XPASS login from brand portal
    const { redirectLink, brandLocationId, allowRedirect, brandRedirectDomain } = formikBag.props.userAuthStore!

    // If the user is here to buy a VOD package, send them to that flow
    if (vodOfferFlow && formikBag.props.store!.vodPurchaseFlowEnabled()) {
      setFriendlyRoute("/vod/packages")
      clearState("vodOfferFlow", true)
    }

    authStore
      .signIn(values, brandLocationId!)
      .then(res => {
        if (isXponential && brandLocationId && !res.data.user.brand_user) {
          formikBag.props.store!.userStore.brandLocationId = brandLocationId

          if (allowRedirect) {
            formikBag.props.store!.userStore.brandRedirect = true
          }
        }

        formikBag.setSubmitting(false)
        saveState(formikBag.props.emailKey!, rememberMe ? values.email : "")
        track.event("authentication_success", { loc }, { cta: "sign in" })
        return res
      })
      .catch((ex: AxiosError) => {
        formikBag.setSubmitting(false)
        if (isAuthError(ex.response)) {
          if (ex.response.data.code && ex.response.data.code === "not_found") {
            formikBag.setFieldError("email", "Wrong email or location")
            formikBag.setFieldError("base", ex.response.data.message)
            track.event("authentication_failure", { loc }, { cta: "sign in" })
          } else {
            formikBag.setFieldError("password", "Wrong password or email")

            const msg = (
              <>
                <strong>Incorrect password or email.</strong> If this is your
                first time using the new website, you may need to{" "}
                <Link
                  to={`/auth/forgot_password?email=${values.email}`}
                  className="alert-link"
                >
                  reset your password
                </Link>
                .
              </>
            )
            // tslint:disable-next-line: no-any
            formikBag.setFieldError("base", (msg as any) as string)
          }
          track.event("authentication_failure", { loc }, { cta: "sign in" })
          // TODO: show list of possible locations
        } else if (
          ex.response &&
          ex.response.status === 502 &&
          ex.response.data.code &&
          ex.response.data.code === "no_piq"
        ) {
          uiStore.openModal({
            type: "error",
            title: "Something went wrong",
            message: ex.response.data.message,
            modalShow: true,
          })
          track.event("authentication_failure", { loc }, { cta: "sign in" })
        } else {
          uiStore.openError(
            "We encountered a problem logging you in. Please try again or contact your studio for support.",
            loc
          )
          track.event("authentication_failure", { loc }, { cta: "sign in" })
          throw ex
        }
      })
    track.event("log in_tap sign in", { loc }, { cta: "sign in" })
  },
})

export default inject((store: BrandStore) => ({ store }))(
  observer(FormikWrapper(LoginForm))
)
