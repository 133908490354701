import * as React from "react"
import * as Sentry from "@sentry/browser"
import { observer } from "mobx-react"
import { observable } from "mobx"
import ErrorMessage from "components/ErrorMessage"

export type Props = {
  children: React.ReactNode
  // TODO: this should pass the error through
  error?(props: ErrorHandlerProps): React.ReactNode
}

export type ErrorHandlerProps = {
  error: Error
  info: React.ErrorInfo
}

@observer
export default class ErrorBoundary extends React.Component<Props> {
  @observable hasError = false
  @observable error?: Error
  @observable info?: React.ErrorInfo

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // tslint:disable-next-line:no-console
    this.error = error
    this.info = info
    this.hasError = true
    console.error(error, info)
    const id = Sentry.captureException(error)
    if (window.globals.sentryDialog) {
      Sentry.showReportDialog({ eventId: id })
    }
  }

  render() {
    if (this.hasError) {
      if (this.props.error) {
        return this.props.error({ error: this.error!, info: this.info! })
      } else {
        return <ErrorMessage />
      }
    }
    return this.props.children || null
  }
}
