import * as React from "react"
import { RouteComponentProps, Redirect } from "react-router"
import { observer } from "mobx-react"
import getIn from "lodash/get"
import * as cx from "classnames"
import APILoader from "components/wrappers/APILoader"
import PageTracker from "components/wrappers/PageTracker"
import LocationSummary from "models/LocationSummary"
import BrandStore from "stores/BrandStore"
import ServiceBookingStore from "apps/book/stores/ServiceBookingStore"
import BookingInfoCard from "apps/book/components/BookingInfoCard"
import ServiceBookAndConfirmSuccessPage from "apps/book/components/ServiceBookAndConfirmSuccessPage"
import { LATEST_BOOKING_PATH_KEY } from "apps/book/stores/QueryStringMonitor"
import { toServiceBookingProps } from "helpers/queryString"
import { loadState } from "services/savedState"
import { getLocal, removeLocal } from "utils/LocalStorage"
import Spinner from "components/Spinner"

export interface Props extends RouteComponentProps<{ serviceTypeId?: string }> {
  store: BrandStore
  locationSummary: LocationSummary
  serviceBookingStore: ServiceBookingStore
}

interface State {
  serviceBooked: boolean
}

@observer
export default class ServiceBookingConfirmPage extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)
    this.state = { serviceBooked: false }
  }

  get bookAndConfirm() {
    return getIn(this.props.location, "state.bookAndConfirm", false)
  }

  get planInfo() {
    return getIn(this.props.location, "state.planInfo", undefined)
  }

  get giftCardInfo() {
    return getIn(this.props.location, "state.giftCardInfo", undefined)
  }

  get bookingPropsFromQueryString() {
    return toServiceBookingProps(this.props.location.search)
  }

  handleConfirm = (e: ButtonEvent) => {
    e.preventDefault()
    this.performBook()
  }

  performBook = () => {
    // TODO: Remove this once QA is done for purchase-and-book flow
    // TODO: Remove setTimeout below
    const timeOut = getLocal<string>("testTimeout")
    const timeOutInt = timeOut ? parseInt(timeOut, 10) : 0
    removeLocal("testTimeout")
    setTimeout(() => {
      const { serviceBookingStore, store } = this.props
      serviceBookingStore
        .book()
        .then(() => {
          this.setState({ serviceBooked: true })
          this.handleSuccess()
        })
        .catch(() => {
          const errorProps = {
            title: "Something went wrong",
            message: "This session is no longer available.",
          }
          store.uiStore.openModal({
            ...errorProps,
            type: "error",
            modalShow: true,
          })
          this.handleCancel()
        })
    }, timeOutInt)
  }

  /**
   * If we've stored the latest schedule the user looked at, we'll go back there,
   * otherwise we go back to the service schedule for this booking's date
   */
  handleCancel = (_event?: ButtonEvent) => {
    const { match, serviceBookingStore } = this.props
    let { pathname = "", search = "" } =
      loadState(LATEST_BOOKING_PATH_KEY, true) || {}

    const date = serviceBookingStore.bookingStatus!.scheduleEntry.dateString
    pathname = pathname || match.url.replace("/confirm", "")
    search = search || `date=${date}`

    this.props.history.replace({ pathname, search })
    serviceBookingStore.reset()
  }

  handleSuccess = () => {
    this.props.history.push({
      pathname: this.props.match.url.replace("/confirm", "/success"),
      search: this.props.location.search,
      state: {
        bookAndConfirm: this.bookAndConfirm,
        planInfo: this.planInfo,
        giftCardInfo: this.giftCardInfo,
      },
    })
  }

  handleStoreUpdate = async () => {
    const { serviceBookingStore } = this.props
    if (serviceBookingStore.packageNeeded) {
      // we're redirecting in render, no other action required
      return
    }

    if (!serviceBookingStore.bookingStatus!.canBook) {
      return this.handleCancel()
    }

    if (this.bookAndConfirm) {
      // Immediate booking:
      // wait for the booking to be completed, then continue through
      // and the next clause will redirect to the success page if appropriate
      await this.performBook()
    }

    if (serviceBookingStore.booking) {
      this.handleSuccess()
    }
  }

  renderError = () =>
    this.bookAndConfirm ? (
      <ServiceBookAndConfirmSuccessPage
        {...this.props}
        locationSummary={this.props.locationSummary}
        serviceBookingStore={this.props.serviceBookingStore}
        planInfo={this.planInfo}
        giftCardInfo={this.giftCardInfo}
      />
    ) : (
      <></>
    )

  public render() {
    const { serviceBookingStore } = this.props
    const bookingProps = this.bookingPropsFromQueryString

    if (serviceBookingStore.packageNeeded) {
      return (
        <Redirect
          push
          to={{
            pathname: `/buy/${this.props.locationSummary.id}`,
            search: this.props.location.search,
          }}
        />
      )
    }

    return (
      <APILoader
        apiStore={serviceBookingStore}
        fetchProps={bookingProps}
        alreadyLoaded={serviceBookingStore.isLoadedFor(bookingProps)}
        onLoad={this.handleStoreUpdate}
        renderError={this.renderError}
        render={() => (
          <ConfirmPageInner
            {...this.props}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
            bookAndConfirm={this.bookAndConfirm}
          />
        )}
      />
    )
  }
}

interface InnerProps extends RouteComponentProps<{ serviceTypeId?: string }> {
  locationSummary: LocationSummary
  serviceBookingStore: ServiceBookingStore
  bookAndConfirm: boolean
  onCancel(event?: ButtonEvent): void
  onConfirm(event: ButtonEvent): void
}

class ConfirmPageInner extends React.Component<InnerProps> {
  render() {
    const { serviceBookingStore, bookAndConfirm } = this.props

    const {
      brandStore,
      isBusy,
      scheduleEntrySummary,
      serviceType,
    } = serviceBookingStore

    return bookAndConfirm ? (
      <Spinner />
    ) : (
      <div>
        <BookingInfoCard
          title="Confirm Booking"
          scheduleEntry={scheduleEntrySummary}
          subtitle={`${serviceType!.name}`}
          locationSummary={this.props.locationSummary}
          brand={brandStore.brand}
          copy={brandStore.copy}
          store={brandStore}
        >
          <PageTracker name="service booking > confirm" />
          <div className={cx(brandStore.isXponential && "confirm-action", "mt-4 text-center")}>
            <button
              className="btn btn-primary w-100 mb-3"
              onClick={this.props.onConfirm}
              disabled={isBusy}
            >
              Confirm Booking
            </button>
            <button
              className={cx("text-muted btn btn-link", {"mt-0 p-0" : brandStore.isXponential })}
              onClick={this.props.onCancel}
            >
              <small>Cancel</small>
            </button>
          </div>
        </BookingInfoCard>
      </div>
    )
  }
}
