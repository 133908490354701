import ResponseMiddleware from "services/middleware/ResponseMiddleware"
import DeserializeMiddleware from "services/middleware/DeserializeMiddleware"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"
import MemoizeMiddleware from "services/middleware/MemoizeMiddleware"

import CyclingSummary from "apps/history/models/CyclingSummary"
import CyclingClass from "apps/history/models/CyclingClass"
import AbstractStatsStore from "apps/history/stores/AbstractStatsStore"
import { UserSession } from "models/UserSession"

interface Response {
  summary_stats: CyclingSummary
  class_stats: CyclingClass[]
  user?: UserSession
}

export default class CyclingStatsStore extends AbstractStatsStore<Response> {
  type = "cycling" as const

  api = this.createClient<Response>([
    ResponseMiddleware(this.handleSuccess, this.handleError),
    MemoizeMiddleware,
    DeserializeMiddleware("summary_stats", CyclingSummary, true),
    DeserializeMiddleware("class_stats", CyclingClass),
    TokenAuthMiddleware(this.userStore),
  ])

  fetch() {
    return this.api.get(`/api/performance_stats/cycling`)
  }
}
