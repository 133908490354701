// title and location info shared by service and regular schedule
import * as React from "react"
import { observer, inject } from "mobx-react"

import BrandStore from "stores/BrandStore"

import Heading from "components/Heading"
import LocationSummary from "models/LocationSummary"
import HeaderAddressPanel from "components/HeaderAddressPanel"

export interface Props {
  store?: BrandStore
  locationSummary: LocationSummary
}

const ScheduleHeader: React.FunctionComponent<Props> = ({ store, locationSummary }) => {
  return (
    <>
      <div className="row">
        <Heading className="col">{locationSummary && locationSummary.name} Schedule</Heading>
      </div>

      <HeaderAddressPanel locationSummary={locationSummary} />
    </>
  )
}

export default inject((store: BrandStore) => ({ store }))(
  observer(ScheduleHeader)
)
