import { JsonObject, JsonProperty } from "json2typescript"

@JsonObject("ClassCategoryDetail")
export default class ClassCategoryDetail {
  /**
   * ID of the class category if it exists. It usually should.
   */
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * The name of the category of the class.
   */
  @JsonProperty("name", String)
  name: string = undefined!

  /**
   * Summary of the class category.
   */
  @JsonProperty("summary", String, true)
  summary?: string = undefined

  /**
   * An image for the class category.
   */
  @JsonProperty("card_image_url", String, true)
  cardImageUrl?: string = undefined!

  /**
   * Description of the class category.
   */
  @JsonProperty("description", String, true)
  description?: string = undefined!
}
