import { JsonObject, JsonProperty } from "json2typescript"
import { computed } from "mobx"
import DateConverter from "utils/DateConverter"
import moment, { Moment } from "moment"
import { BookingStatusEnum, bookingStatusMap } from "models/Booking"
import InstructorSummary from "models/InstructorSummary"
import LocationSummary from "models/LocationSummary"
import ServiceScheduleEntry from "apps/book/models/ServiceScheduleEntry"
import ServiceDuration from "apps/book/models/ServiceDuration"
import { capitalize } from "lodash"

@JsonObject("ServiceBooking")
export default class ServiceBooking {
  /**
   * XPO id of the service booking. It will look like service_{clubready_id}
   */
  @JsonProperty("id", String)
  id: string = undefined!

  /**
   * A tag to identify the type, as bookings vs. waitlist bookings look a little
   * different, as do their schedule entries.
   */
  @JsonProperty("type", String)
  type: "service_booking" = undefined!

  /**
   * Status of the booking. Note that some of the available options (e.g. 'waitlisted')
   * will probalby never show up for service bookings
   */
  @JsonProperty("status", String)
  status: BookingStatusEnum = undefined!

  /**
   * The name of the service booked
   */
  @JsonProperty("service_name", String)
  serviceName: string = undefined!

  /**
   * XPO location id
   */
  @JsonProperty("location_id", String)
  locationId: string = undefined!

  /**
   * ClubReady ID of the service booking
   */
  @JsonProperty("clubready_id", String, true)
  clubreadyId?: string = undefined

  /**
   * When they booked the session
   */
  @JsonProperty("booked_at", DateConverter, true)
  bookedAt?: Moment = undefined

  /**
   * The session's instructor
   */
  @JsonProperty("instructor", InstructorSummary, true)
  instructor?: InstructorSummary = undefined

  @JsonProperty("schedule_entry", ServiceScheduleEntry)
  serviceScheduleEntry: ServiceScheduleEntry = undefined!

  /**
   *  XPASS credits used to book the class. Only applicable to XPASS bookings.
   */
  @JsonProperty("xpass_credits", Number, true)
  xpassCredits?: number = undefined!

  // this should be set by the booking store upon fetching bookings
  location?: LocationSummary = undefined

  // whether some action is underway on the booking
  isBusy: boolean = false

  @computed get key() {
    return `${this.clubreadyId}-${this.status}`
  }

  @computed get isBooked() {
    const stat = bookingStatusMap[this.status]
    return stat && stat.booked
  }

  @computed get humanReadableStatus() {
    switch (this.status) {
      case "cancelled_no_refund":
      case "cancelled_refund":
      case "club_cancelled":
        return "Cancelled"
      case "noshow":
        return "No show"
      case "club_rescheduled":
        return "Rescheduled by club"
      default:
        return capitalize(this.status)
    }
  }

  @computed get freeCancelable() {
    return this.scheduleEntry.freeCancelUntil > moment()
  }

  @computed get startsAt() {
    return this.scheduleEntry.startsAt
  }

  @computed get startTime() {
    return this.scheduleEntry.startTime
  }

  @computed get serviceDuration() {
    return this.scheduleEntry.serviceDuration
  }

  @computed get scheduleEntry() {
    const scheduleEntry = this.serviceScheduleEntry
    scheduleEntry.title = this.serviceName
    scheduleEntry.subtitle = `${this.serviceScheduleEntry.serviceDuration.durationMinutes} minutes`
    scheduleEntry.location = this.location
    scheduleEntry.instructor = this.instructor

    return scheduleEntry
  }

  @computed get seatId() {
    return undefined
  }
}
