import React, { useEffect, useState } from 'react'
import SearchIcon from "images/xpass/icons/search-pin.svg"
import FilterIcon from "images/xpass/icons/filter.svg"
import FilterActiveIcon from "images/xpass/icons/filter-active.svg"
import { uniqBy } from 'lodash'
import ScheduleEntryLocation from 'apps/book/models/xpass/ScheduleEntryLocation'
import Autosuggest from 'react-autosuggest'
import BrandStore from 'stores/BrandStore'
import { autorun } from 'mobx'

interface BookClassNavFiltersProps {
  store: BrandStore;
  activeTab: string;
}

export const BookClassNavFilters = ({store, activeTab}: BookClassNavFiltersProps) => {
    const locationsData = store.uiStore.nav.searchSuggestions.slice()
    const citySelected = store.uiStore.nav.selectedLocation
    const [searchTerm, setSearchTerm] = useState(citySelected)
    const [locations, setLocations] = useState(locationsData)
    const [noSuggestions, setNoSuggestions] = useState(false)
    const [closeSearchOptions, setCloseSearchOptions] = useState(false)
    const [activeIcon, setActiveIcon] = useState(false)


    useEffect(() => {
      autorun(() => {
        setActiveIcon(store.uiStore.nav.hasAppliedFilters)
        return 
      })

    },[store.uiStore.nav.hasAppliedFilters])
    // https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-autosuggest
    function escapeRegexCharacters(str: string) {
      return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    }
  
    const getSuggestions = (term: string) => {
      const filteredLocations = uniqBy(locationsData, e => [e.city!.toLowerCase(), e.state!.toLowerCase()].join())
      const escapedValue = escapeRegexCharacters(term.trim())
      if (escapedValue === "") {
        return []
      }
      const regex = new RegExp("^" + escapedValue, "i")
      return filteredLocations.filter(
        location =>
          regex.test(`${location.city!}, ${location.state!}`) ||
          regex.test(location.city!) ||
          regex.test(location.state!)
      )
    }
  
    const getSuggestionValue = (location: ScheduleEntryLocation) => {
      return `${location.city!}, ${location.state!}`
    }
  
    const renderSuggestion = (location: ScheduleEntryLocation) => {
      return (
        <div className="suggestion d-flex align-items-center">
          <img src={SearchIcon} alt="Search result icon" style={{ paddingRight: "18px" }}/>
          <div>{location.city}, {location.state}</div>
        </div>
      )
    }
  
    const shouldRenderSuggestions = (term: string) => {
      const trimTerm = term.trim()
      if (trimTerm === trimTerm.toUpperCase()) {
        return trimTerm.length > 1
      }
      return trimTerm.length > 2
    }
  
    const handleFocus = () => {
      if (citySelected === searchTerm) {
        setSearchTerm("")
      }
    }
  
    const handleBlur = () => {
      setCloseSearchOptions(true)
      if (citySelected) {
        setSearchTerm(citySelected)
      } else {
        setSearchTerm("")
      }
    }
  
    const handleChange = (e: any, value: { newValue: string }) => {
      setCloseSearchOptions(false)
      setSearchTerm(value.newValue)
    }
  
    const handleFilterClick = () => {
      store.uiStore.nav.showFiltersModal ? store.uiStore.hideFiltersModal() : store.uiStore.showFiltersModal()
    }

    const onSuggestionsFetchRequested = (term: { value: string }) => {
      const suggestions = getSuggestions(term.value)
      const isSearchBlank = term.value.trim() === ""
      setNoSuggestions(!isSearchBlank && suggestions.length === 0)
      setLocations(suggestions)
    }
  
    const onSuggestionsClearRequested = () => {
      setLocations([])
    }
  
    const onSuggestionSelected = (e: any, value: any) => {
      store.uiStore.setSelectedLocation(value.suggestionValue)
    }
  
    useEffect(() => {
      setSearchTerm(store.uiStore.nav.selectedLocation)
    }, [store.uiStore.nav.selectedLocation])

  return (
    <div className={`main-menu__location-search d-flex align-items-center ${activeTab === 'favorites' ? 'inactive' : ''}`}>
        <div className="main-menu__location-search__input d-flex">
            <div className="main-menu__search-icon mr-2">
                <img src={SearchIcon} />
            </div>
            <Autosuggest
                suggestions={locations}
                onSuggestionsFetchRequested={value =>
                onSuggestionsFetchRequested(value)
                }
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                shouldRenderSuggestions={value => shouldRenderSuggestions(value)}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={(e, value) => onSuggestionSelected(e, value)}
                inputProps={{
                placeholder: "City, State",
                value: searchTerm,
                onFocus: handleFocus,
                onBlur: handleBlur,
                onClick: handleFocus,
                onChange: (e, value) => handleChange(e, value),
                }}
                theme={{
                input: `${closeSearchOptions ? "close" : ""}`,
                suggestionsContainerOpen: 'suggestions-container',
                suggestionsList: 'suggestions-list',
                suggestion: 'suggestion',
                }}
            />
        </div>
        <div onClick={handleFilterClick} className="main-menu__location-search__filter mx-2">
            <img src={ activeIcon ? FilterActiveIcon : FilterIcon} />
        </div>
    </div>
  )
}
