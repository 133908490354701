import APIStore from "stores/APIStore"

import BrandStore from "stores/BrandStore"
import { FormValuesSPC } from "/apps/buy/components/xpass/SetPasswordSPC"
import TokenAuthMiddleware from "services/middleware/TokenAuthMiddleware"

export default class PasswordUpdateStore extends APIStore {
  api = this.createClient([TokenAuthMiddleware(this.brandStore.userStore)])

  constructor(public brandStore: BrandStore) {
    super()
  }

  update(values: FormValuesSPC) {
    return this.api.put("/api/xpass/spc_passwords", { ...values })
  }
}
