import React, { Component } from "react"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import { action, computed, observable } from "mobx"
import { observer, inject } from "mobx-react"
import * as cx from "classnames"
import * as moment from "moment"
import getIn from "lodash/get"

import APILoader from "components/wrappers/APILoader"
import BrandStore from "stores/BrandStore"
import LocationSummary from "models/LocationSummary"
import LocationDetailStore from "apps/xpass/stores/LocationDetailStore"
import ScheduleEntryStore from "apps/book/stores/xpass/ScheduleEntryStore"
import ScheduleStore from "apps/book/stores/ScheduleStore"
import BalancesStore from "apps/buy/stores/xpass/BalancesStore"
import BookingsStore from "apps/bookings/stores/BookingsStore"
import ServiceTypesStore from "apps/book/stores/ServiceTypesStore"
import ServiceBookingStore from "apps/book/stores/ServiceBookingStore"
import DetailsPageMap from "apps/xpass/DetailsPageMap"
import LocationDetailsSchedule from "apps/xpass/LocationDetailsSchedule"
import ServiceTypeSchedule from "apps/book/components/ServiceTypeSchedule"
import ServiceScheduleEntry from "apps/book/models/ServiceScheduleEntry"
import { fromServiceBookingProps } from "helpers/queryString"
import Default from "images/dashboard/about_hero_desktop.jpg"
import Spinner from "components/Spinner"
import FavoriteHeart from "components/FavoriteHeart"

export interface Props extends RouteComponentProps<{ locationId?: string }> {
  store?: BrandStore
  locationSummary: LocationSummary
  locationId?: string
  onBookingModal: Function
  scheduleStore: ScheduleStore
  scheduleEntryStore: ScheduleEntryStore
}
export interface State {
  showServiceSchedule: boolean
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class LocationDetails extends Component<Props, State> {
  locationDetailStore = new LocationDetailStore()
  balancesStore = new BalancesStore(this.props.store!)
  bookingsStore = new BookingsStore(this.props.store!)
  locationId = this.props.match.params.locationId!

  isServiceLocation: boolean = false
  param = new URLSearchParams(window.location.search)
  @observable serviceTypeId = this.param.get("serviceTypeId")
  @observable durationId = this.param.get("durationId")
  @observable instructorId = this.param.get("instructorId")
  serviceTypesStore = new ServiceTypesStore(this.props.store!)
  serviceBookingStore = new ServiceBookingStore(
    this.props.locationSummary,
    this.props.store!
  )

  componentWillMount() {
    this.balancesStore.fetch()
    this.bookingsStore.fetch()
    this.locationDetailStore.fetch(this.locationId).catch(err => {
      this.props.history.push("/error")
      throw err
    })

    if (this.props.store!.isServiceLocation(this.locationId)) {
      this.isServiceLocation = true
      this.setState({ showServiceSchedule: true })
    }
  }

  componentWillUnmount() {
    this.balancesStore.dispose()
    this.bookingsStore.dispose()
  }

  constructor(props: Props & RouteComponentProps) {
    super(props)

    this.state = {
      showServiceSchedule: this.serviceTypeId ? true : false,
    }
  }

  @computed get serviceType() {
    return this.serviceTypesStore.serviceTypes.find(
      st => st.id === this.serviceTypeId
    )
  }

  @computed get bookingQueryString() {
    return fromServiceBookingProps(this.serviceBookingStore.bookingProps!)
  }

  @action.bound
  updateQuery(key: string, value: string) {
    const { history, store } = this.props
    history.push(store!.routingStore.mergeQuery({ [key]: value }))
  }

  @action.bound
  handleServiceTypeSelect(e: SelectEvent) {
    const type = e.currentTarget.value
    this.serviceTypeId = type
    this.updateQuery("serviceTypeId", type)
  }

  showServiceSchedule(showServiceSchedule: boolean) {
    // Reset to default settings
    const today = moment().format("YYYY-MM-DD")
    this.updateQuery("date", today)
    this.updateQuery("instructorId", "")
    if (this.durationId) {
      const defaultDuration = this.serviceTypesStore.serviceTypes[0]
        .durations[0].id
      this.updateQuery("durationId", defaultDuration)
    }
    if (this.serviceTypeId) {
      const defaultService = this.serviceTypesStore.serviceTypes[0].id
      this.serviceTypeId = defaultService
      this.updateQuery("serviceTypeId", defaultService)
    }

    this.setState({ showServiceSchedule })
  }

  handleBookClick = (entry: ServiceScheduleEntry) => {
    // added extra condition to avoid this flow on xpass
    if (!this.props.store!.isXponential) {
      this.serviceBookingStore.fetchFromEntry(entry).then(res => {
        if (res.status === 200 && this.props.onBookingModal) {
          if (!res.data.service_booking_status.canBook) {
            const handleClick = () => {
              this.props.store!.uiStore.closeModal()
              if (
                res.data.service_booking_status.reasons[0].code ===
                "no_available_credit"
              ) {
                this.props.history.push(
                  `/buy/${this.props.locationSummary.id}?${this.bookingQueryString}`
                )
              }
            }
  
            const message =
              res.data.service_booking_status.reasons[0].code === "user_booked"
                ? "You have already booked a session at this time."
                : res.data.service_booking_status.reasons[0].message
  
            this.props.store!.uiStore.openModal({
              title: "Something went wrong",
              modalShow: true,
              size: "md",
              children: (
                <div className="text-center">
                  <p>{message}</p>
                  <button className="btn btn-primary" onClick={handleClick}>
                    OK
                  </button>
                </div>
              ),
            })
          } else {
            this.props.onBookingModal(entry)
          }
        } else {
          this.props.store!.uiStore.openError()
        }
      })
    } else {
      this.props.onBookingModal(entry)
    }
  }

  render() {
    const { store, scheduleStore } = this.props
    const { bookedBookings } = this.bookingsStore
    const {
      freeClassesPurchased,
      freeClassBookings,
      balances,
    } = this.balancesStore!
    const userBalances = { freeClassesPurchased, freeClassBookings, balances }

    const { upcomingClasses, location, details } = this.locationDetailStore
    const heroImageUrl =
      details && details.heroImageUrl ? details.heroImageUrl : Default
    const img = getIn(details, "mapPin", undefined)
    const brandId = getIn(details, "brandSlug", undefined)

    return (
      <>
        <div
          className="details-page my-5"
        >
          {!location && (
            <div className="pt-3 pt-md-4">
              <Spinner />
            </div>
          )}

          <div className="container">
            <div className="location-details-page__grid">
              <div className="location-details__head">
                {location && (
                  <div className="d-flex flex-column m-0">
                    {location && (
                        <img className="class-image" src={heroImageUrl} />
                      )}
                    <div className="d-flex align-items-center p-0 mb-lg-0 mt-3">
                      
                      <h2 className="details-page__title mb-0">
                        {details!.brandName} {location!.name}
                      </h2>
                      <div className="ml-2 favorite-heart-studio">
                        <FavoriteHeart
                          type="location"
                          id={location.id}
                          width="16"
                          height="16"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="location-details__body negative-margin">
                {location && this.isServiceLocation && (
                  <ul className="submenu nav mt-3">
                    <li
                      className={cx({
                        active: this.state.showServiceSchedule,
                      })}
                      onClick={() => this.showServiceSchedule(true)}
                    >
                      {store!.copy.oneOnOneSessions}
                    </li>
                    <li
                      className={cx({
                        active: !this.state.showServiceSchedule,
                      })}
                      onClick={() => this.showServiceSchedule(false)}
                    >
                      {store!.copy.groupClasses}
                    </li>
                  </ul>
                )}

                <div>
                  {this.isServiceLocation && this.locationId && (
                    <div
                      className={cx(
                        "mt-4",
                        location && this.state.showServiceSchedule
                          ? "visible"
                          : "hide"
                      )}
                    >
                      <APILoader
                        apiStore={this.serviceTypesStore}
                        fetchProps={this.locationId}
                        elementSize="element"
                        render={() => {
                          const type =
                            this.serviceType ||
                            this.serviceTypesStore.serviceTypes[0]

                          return (
                            <div>
                              <ServiceTypeSchedule
                                onBookClick={this.handleBookClick}
                                onServiceTypeSelect={
                                  this.handleServiceTypeSelect
                                }
                                allServiceTypes={
                                  this.serviceTypesStore.serviceTypes
                                }
                                locationSummary={this.props.locationSummary}
                                serviceType={type}
                                key={type.id}
                                isBusy={this.serviceBookingStore.isBusy}
                                routeInfo={this.props.location}
                                updateQuery={this.updateQuery}
                                xpassLocationId={this.locationId}
                              />
                            </div>
                          )
                        }}
                      />
                    </div>
                  )}

                  <div
                    className={cx(
                      "mt-4",
                      location && !this.state.showServiceSchedule
                        ? "visible"
                        : "hide"
                    )}
                  >
                    <APILoader
                      apiStore={scheduleStore}
                      fetchProps={{ locations: this.locationId }}
                      alreadyLoaded={scheduleStore.week.loaded}
                      key={scheduleStore.week.startDate}
                      elementSize="element"
                      render={() => {
                        return (
                          <>
                            {upcomingClasses && (
                                <LocationDetailsSchedule
                                  {...this.props}
                                  scheduleStore={this.props.scheduleStore}
                                  scheduleEntryStore={
                                    this.props.scheduleEntryStore!
                                  }
                                  onBookingModal={this.props.onBookingModal}
                                  locationId={this.locationId}
                                  userBalances={userBalances}
                                  bookedBookings={bookedBookings}
                                />
                            )}
                          </>
                        )
                      }}
                    />
                  </div>
                </div>

                {location && (
                  <>
                    {location.instructors!.length > 0 && (
                      <div className="row m-0">
                        <div className="col-md-12 p-0 pr-md-3 mb-lg-4 mb-xl-0">
                          <h3 className="details-page__section-title">
                            Our Instructors
                          </h3>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {location.instructors!.map((instructor, i) => (
                              <div key={i} style={{ margin: "10px" }}>
                                <Link
                                  to={`/book/${
                                    store!.userStore.session!.locationId
                                  }/instructor/${instructor.id}?location_id=${
                                    this.locationId
                                  }`}
                                >
                                  <img
                                    className="instructor-image"
                                    src={instructor.headshotUrl}
                                  />
                                </Link>
                                <p className="mb-0"> {instructor.name}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    <hr className="divider my-4" />
                    {location.whatToBring && (
                      <>
                        <div className="row m-0">
                          <div className="col-md-12 p-0 pr-md-3 mb-lg-4 mb-xl-0">
                            <h3 className="details-page__section-title">
                              What To Bring
                            </h3>
                            <p className="mb-0">{location.whatToBring}</p>
                          </div>
                        </div>
                        <hr className="divider my-4" />
                      </>
                    )}

                    {location.parkingInfo && (
                      <>
                        <div className="row m-0">
                          <div className="col-md-12 p-0 pr-md-3 mb-lg-4 mb-xl-0">
                            <h3 className="details-page__section-title">
                              Parking Info
                            </h3>
                            <p className="mb-0">{location.parkingInfo}</p>
                          </div>
                        </div>
                        <hr className="divider my-4" />
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="location-details__map">
              {img && brandId && (
                <DetailsPageMap
                  img={img}
                  brandId={brandId}
                  brandName={details!.brandName}
                  location={location!}
                  isDetailsPage
                />
              )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
