import { JsonObject, JsonProperty } from "json2typescript"
import ScheduleEntry from "models/ScheduleEntry"
import { computed } from "mobx"

export type ClassBookingReasonCode =
  | "can_book"
  | "late"
  | "early"
  | "full"
  | "credits"
  | "upstream_error_points"
  | "unknown"
  | "insufficient_credits"
  | "insufficient_points"
  | "token_missing"
  | "vip_early"
  | "vip_early_waitlist"
  | "vip_early_credits"
  | "vip_early_credits_waitlist"

@JsonObject("BookingStatus")
export default class BookingStatus {
  /**
   * A tag to identify the type, in case these end up in a heterogenous list with
   * other booking-type-things.
   */
  type = "booking_status" as const

  /**
   * A more limited version of the full `BookingStatus` enum. This should almost be `unbooked`
   * since if the user is waitlisted or booked you should be getting a full booking instead.
   * However, due to CR API bugs, this can happen.
   */
  @JsonProperty("status", String)
  status: "unbooked" | "booked" | "waitlisted" = undefined!

  /**
   * Whether the current user can book this class, though it doesn't tell us much as to why.
   *
   */
  @JsonProperty("can_book", Boolean)
  canBook: boolean = undefined!

  /**
   * If the user can't book, our best guess at why. Check the `status` enum first,
   * since a user may be `can_book` despite already having booked.
   */
  @JsonProperty("code", String)
  code: ClassBookingReasonCode = undefined!

  @JsonProperty("schedule_entry", ScheduleEntry)
  scheduleEntry: ScheduleEntry = undefined!

  @JsonProperty("message", String)
  message?: string = undefined!

  /**
   * This indicates the means by which the user will/has booked the class.
   * TODO: This is a placeholder - we'll hopefully get some better data on this (the specific credit used)
   * - Credit: The user has a credit specific to this class that will be used to book.
   * - SessionCreditGroup: The user has a credit of a more general type that will be used to book.
   * - AmenityMembership: Usually means they're on an unlimited plan and can book this class without using any credits.
   * - None: The user can't book this class and might need to buy a package first.
   *
   */
  @JsonProperty("source", String, true)
  source?:
    | "SessionCreditGroup"
    | "None"
    | "AmenityMembership"
    | "Credit" = undefined

  @computed get isBooked() {
    return ["completed", "booked", "waitlisted"].indexOf(this.status) !== -1
  }
}
