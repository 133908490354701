import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { inject, observer } from "mobx-react"
import BrandStore from "stores/BrandStore"
import cx from "classnames"
import aktLogo from "images/logos/akt-logo.svg"
import bftLogo from "images/logos/bft-logo.svg"
import clubpilatesLogo from "images/logos/clubpilates-logo.svg"
import cyclebarLogo from "images/logos/cyclebar-logo.svg"
import purebarreLogo from "images/logos/purebarre-logo.svg"
import rowhouseLogo from "images/logos/rowhouse-logo.svg"
import rumbleLogo from "images/logos/rumble-logo.svg"
import stretchlabLogo from "images/logos/stretchlab-logo.svg"
import strideLogo from "images/logos/stride-logo.svg"
import yogasixLogo from "images/logos/yogasix-logo.png"
import kinrgyLogo from "images/logos/kinrgy-logo.svg"
import xpoLogo from "images/logos/xponential-logo.png"
import pass from "images/logos/PASS.png"
import Svg from "components/Svg"
export interface Props extends RouteComponentProps {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
class SecondaryMainMenu extends React.Component<Props, {}> {
  brand = this.props.store!.routingStore.query.brand
  brandDomains = window.globals.brandDomains
  brandLogo = () => {
    switch (this.brand) {
      case "akt":
        return aktLogo
      case "bft":
        return bftLogo
      case "clubpilates":
        return clubpilatesLogo
      case "cyclebar":
      case "cyclebar_aus":
        return cyclebarLogo
      case "purebarre":
        return purebarreLogo
      case "rowhouse":
        return rowhouseLogo
      case "rumble":
        return rumbleLogo
      case "stretchlab":
        return stretchlabLogo
      case "stride":
        return strideLogo
      case "yogasix":
        return yogasixLogo
      case "kinrgy":
        return kinrgyLogo
      default:
        return xpoLogo
    }
  }

  // TODO
  // - BE work needs to be done to expose cross-platform access token
  // - Once that's exposed, we need to send the user to the correct member portal with access token
  // - to directly sign-in without prompting the user with login info
  targetLink = (xpass: boolean = false, hasXpassUser: boolean = false) => {
    if (xpass && !hasXpassUser) return `${this.brandDomains[this.props.store!.brandId]}/xpass`

    const base = xpass ? this.brandDomains['xponential'] : this.brandDomains[this.brand!]
    const appended = xpass ? `?brand=${this.props.store!.brandId}` : ""
    const pathname = this.props.store!.routingStore.location.pathname
    switch(true) {
      case (pathname.includes('book')):
        return `${base}/book${appended}`
      case (pathname.includes('buy')):
        return `${base}/buy${appended}`
      case (pathname.includes('bookings')):
        return `${base}/bookings${appended}`
      case (pathname.includes('history')):
        return `${base}/history${appended}`
      case (pathname.includes('account')):
        return `${base}/account${appended}`
      default:
        return `${base}${appended}`
    }
  }

  componentDidMount() {
    this.props.store!.userStore.fetch()
  }

  // 1. Determine whether the portal is XPASS or Brand
  //   - this.props.store!.isXponential
  // 2. If it's XPASS
  //   - Determine the brand it's coming from (from the URL `brand` query)
  // 3. If it's not XPASS
  //   - Determine if the brand user has XPASS account
  render() {
    const { isXponential, userStore } = this.props.store!
    const { session } = userStore
    // Don't show if the user is not logged in
    if (!session) return null

    // If the brand is Xponential and there is no "brand" in the URL, don't show anything
    if (isXponential && !this.brand) return null

    const xpassLink = {
      copy: session.xpassUser ? "Switch To" : "Try",
      link: this.targetLink(true, !!session.xpassUser)
    }

    return (
      <>
        <nav className={cx("navbar navbar-light secondary-nav", { "is-brand": !isXponential })}>
          <div className="container-fluid">
            {/* In the brand member portal, show Switch To/Try XPASS */}
            {!isXponential && (
              <ul
                className="nav navbar-nav ml-auto"
                style={{ marginBottom: "0px", padding: "10px" }}
              >
                <a href={xpassLink.link} className="text-decoration-none text-secondary font-weight-normal">
                  <li style={{ listStyleType: "none" }}>
                    {" "}
                    {xpassLink.copy}
                    <img
                      src={xpoLogo}
                      style={{ marginLeft: "5px", height: "20px" }}
                      alt="XPASS logo"
                    />
                    <img
                      src={pass}
                      style={{ height: "7.6px" }}
                      alt="XPASS logo"
                    />
                    <Svg
                      style={{
                        paddingLeft: "6px",
                        color: "black",
                      }}
                      name="caret-right"
                      size="12"
                    />
                  </li>
                </a>
              </ul>
            )}
            {/* In the XPASS member portal, show Back To {Brand} */}
            {isXponential && (
              <a href={this.targetLink()} className="text-decoration-none text-secondary font-weight-normal">
                <ul
                  className="nav"
                  style={{ marginBottom: "0px", padding: "10px" }}
                >
                  <li className="d-flex align-items-center" style={{ listStyleType: "none", whiteSpace: "nowrap" }}>
                    <Svg
                      style={{
                        paddingRight: "6px",
                        color: "black",
                      }}
                      name="caret-left"
                      size="12"
                    />
                    Back To
                    <img
                      src={this.brandLogo()}
                      style={{ marginLeft: "5px", height: "20px" }}
                      alt="XPASS logo"
                    />
                  </li>
                </ul>
              </a>
            )}
          </div>
        </nav>
      </>
    )
  }
}

export default withRouter(SecondaryMainMenu)
