import * as React from "react"
import { Link, RouteComponentProps } from "react-router-dom"
import { observer, inject } from "mobx-react"
import BrandStore from "stores/BrandStore"
import DealList from "./DealList"
import DealsStore from "apps/xpass/stores/DealsStore"
import FeaturedDealsStore from "apps/xpass/stores/FeaturedDealsStore"
import DealsLoader from "images/xpass/deals-loader.svg"
import RewardsFallback from "images/xpass/rewards-fallback.svg"
import TokensStore from "apps/xpass/stores/TokensStore"

const tabOptions = [
  { id: 'rewards', label: 'Rewards' },
  { id: 'myRewards', label: 'My Rewards' },
]

export interface Props extends RouteComponentProps {
  store?: BrandStore
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class MyRewardsPage extends React.Component<Props> {
  dealsStore = new DealsStore(this.props.store!.userStore)
  featuredDealsStore = new FeaturedDealsStore(this.props.store!.userStore)
  tokensStore = new TokensStore(this.props.store!)

  componentDidMount() {
    this.dealsStore.fetch()
    this.featuredDealsStore.fetch()
    this.tokensStore.fetch(true)
  }

  state = {
    activeTab: 'rewards'
  }

  setActiveTab = (activeTab: string) => {
    this.setState({activeTab})
  }

  render() {
    const isRewardsTab = this.state.activeTab === 'rewards'
    const areTokensLoading = this.tokensStore.isLoadingTokens
    const areDealsLoading = isRewardsTab ? this.featuredDealsStore.isLoadingDeals : this.dealsStore.isLoadingDeals
    const { deals } = this.dealsStore
    const { featuredDeals } = this.featuredDealsStore
    const dealsToShow = isRewardsTab ? featuredDeals : deals
    const tabLabel = isRewardsTab ? 'Rewards' : 'My Rewards'
    const tokens = this.tokensStore.classTokens

    return (
      <div className="container position-relative">
        <DealsNav activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
        <h1>{tabLabel}</h1>

        {areDealsLoading ? (
          <img src={DealsLoader} alt="loading deals" />
        ) : dealsToShow && dealsToShow.length > 0 ? (
          <DealList title={isRewardsTab ? 'Featured Deals' : 'My Deals'} deals={dealsToShow} />
        ) : (
          <>
            <h2 className="text-left mt-3">{isRewardsTab ? 'Featured Deals' : 'My Deals'}</h2>
            <div className="rewards-page__no-deals text-center">
              <img src={RewardsFallback} alt="no deals" />
              <div className="no-deals-message">
                <h4>No Deals at this time</h4>
                <p>Complete your daily steps to unlock new Deals.</p>
              </div>
            </div>
          </>
        )}

        {isRewardsTab && (
          areTokensLoading ? (
          <img src={DealsLoader} alt="loading tokens" />
        ) : tokens && tokens.length > 0 ? (
          <DealList title="Earned Tokens" classTokens={tokens} />
        ) : (
          <>
            <h2 className="text-left mt-3">Earned Tokens</h2>
            <div className="d-flex flex-column align-items-center mt-5">
              <h4>You have no earned tokens</h4>
              <p className="mt-1 mb-3">Click the button below to Buy Tokens</p>
              <Link
                to={{
                  pathname: `/buy/xponential-xpass`,
                  state: { context: "addTokens" }
                }}
                className="btn btn-primary"
              >
                Buy Tokens
              </Link>
            </div>
          </>
        )
      )}
      </div>
    )
  }
}


interface DealsNavProps {
  activeTab: string
  setActiveTab: (tab: string) => void
}


const DealsNav:React.FC<DealsNavProps> = ({ activeTab, setActiveTab }) => {
  return (
    <div>
      <ul
      className="deals-nav flex"
    >
        {tabOptions.map((tab) => (
          <NavItem
            key={tab.id}
            isActive={activeTab === tab.id}
            onClick={() => setActiveTab(tab.id)}
            label={tab.label}
          />
        ))}
      </ul>
    </div>
  );
};

interface NavItemProps {
  isActive: boolean;
  onClick: () => void;
  label: string;
}

const NavItem: React.FC<NavItemProps> = ({ isActive, onClick, label }) => {
  return (
    <li className={`deals-nav-item m-0 py-1 ${isActive ? 'active' : ''}`}>
      <a onClick={onClick}>{label}</a>
    </li>
  );
};
