import * as React from "react"
import { observable, action } from "mobx"
import { inject, observer } from "mobx-react"
import * as cx from "classnames"

import PurchaseSummary from "apps/account/models/PurchaseSummary"
import BrandStore from "stores/BrandStore"
import PlanStore from "apps/buy/stores/PlanStore"
import GiftCardStore from "apps/buy/stores/GiftCardStore"
import PurchaseStore from "apps/buy/stores/PurchaseStore"
import PaymentSourcesStore from "apps/account/stores/PaymentSourcesStore"

import ContractCheckbox from "apps/buy/components/ContractCheckbox"

export interface Props {
  store?: BrandStore
  planStore: PlanStore
  giftCardStore?: GiftCardStore
  paymentSourcesStore: PaymentSourcesStore
  onPurchase(purchase: PurchaseSummary): void
}

@inject((store: BrandStore) => ({ store }))
@observer
export default class PurchaseForm extends React.Component<Props, {}> {
  @observable contractChecked = false
  isXponential = this.props.store!.isXponential

  purchaseStore: PurchaseStore = new PurchaseStore(this.props.planStore, this.props.giftCardStore, this.isXponential)

  componentWillUnmount() {
    this.purchaseStore.dispose()
  }

  // Script:
  // <!-- AvantLink Sitewide script -->
  // <script type="text/javascript">
  // (function() {
  // var avm = document.createElement('script'); avm.type = 'text/javascript'; avm.async = true;
  // avm.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.avmws.com/1023521/';
  // var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(avm, s);
  // })();
  // </script>
  createAvantScript() {
    const avantScript = document.createElement("script")
    avantScript.type = "text/javascript"
    avantScript.async = true
    avantScript.src = ("https:" == document.location.protocol ? "https://" : "http://") + "cdn.avmws.com/1023521/"
    document.head.appendChild(avantScript)
  }

  @action.bound
  handleTermsAccept(e: InputEvent) {
    this.contractChecked = e.currentTarget.checked
  }

  // Script:
  // <!-- AvantLink Order Confirmation script-->
  // <script type="text/javascript">
  // var _AvantMetrics = _AvantMetrics || [];
  // _AvantMetrics.push(['order',{ order_id:'[ORDER_ID]', amount:'[ORDER_AMOUNT]', state:'[BILLING_STATE]', country:'[BILLING_COUNTRY]', ecc:'[COUPON_CODE]', new_customer:'[Y or N]' }]);
  // _AvantMetrics.push(['item',{ order_id:'[ORDER_ID]', parent_sku:'[ITEM_PARENT_SKU]', variant_sku:'[ITEM_VARIANT_SKU]', price:'[ITEM_PRICE]', qty:'[ITEM_QUANTITY]' }]);
  // </script>
  fireAvantConversionPixel(purchaseData: PurchaseSummary) {
    if (this.isXponential) {
      let amount = parseFloat(String(purchaseData.paymentAmount.raw || '0.01'))
      amount = isNaN(amount) ? 0.01 : amount
      const avantScript = document.createElement("script")
      avantScript.type = "text/javascript"
      avantScript.innerHTML = `\
        var _AvantMetrics = _AvantMetrics || [];\
        _AvantMetrics.push(['order',{ order_id:'${purchaseData.id}', amount:'${amount}', ecc:'${purchaseData.promoCode || ''}' }]);\
        _AvantMetrics.push(['item',{ order_id:'${purchaseData.id}', parent_sku:'${purchaseData.packageId}', price:'${amount}', qty:'1' }]);\
      `.replace(/  +/g, '')
      document.head.prepend(avantScript)
      // Fire this afterwards to trigger the conversion pixel that uses the array above
      setTimeout(() => { this.createAvantScript() }, 1000)
    }
  }

  handleBuyClick = (e: ButtonEvent) => {
    e.preventDefault()
    const loc = this.props.store!.locStore.currentLocation
    this.purchaseStore.makePurchase().then(
      res => {
        const purchaseData = res.data.purchase
        this.props.onPurchase(purchaseData)
        this.props.store!.track.revenue(
          "purchase_success",
          {
            loc: loc!,
            pkg: this.purchaseStore.pkg,
            plan: this.purchaseStore.plan,
          },
          {
            clubreadyAgreementId: purchaseData.id,
            purchasePromoCode: !!this.purchaseStore.promoCode,
          }
        )
        this.fireAvantConversionPixel(purchaseData)
      },
      error => {
        console.error(error)
        this.props.store!.track.event(
          "purchase_failure",
          {
            loc: loc,
            pkg: this.purchaseStore.pkg,
            plan: this.purchaseStore.plan,
          },
          {
            purchasePromoCode: !!this.purchaseStore.promoCode,
          }
        )
      }
    )
    this.props.store!.track.event(
      "purchase_tap purchase",
      {
        loc: loc,
        pkg: this.purchaseStore.pkg,
        plan: this.purchaseStore.plan,
      },
      {
        purchasePromoCode: !!this.purchaseStore.promoCode,
      }
    )
  }

  public render() {
    const { planStore, paymentSourcesStore, store } = this.props
    const pkg = planStore.package!

    const acceptedTerms =
      this.contractChecked || !planStore.package!.hasContract

    const canPay = paymentSourcesStore.hasPaymentSource || pkg.isFree

    const isThinking =
      this.purchaseStore.status === "loading" ||
      this.purchaseStore.status === "loaded"

    const canPurchase = canPay && acceptedTerms && !isThinking

    return (
      <>
        {pkg.hasContract && pkg.contractUrl && (
          <ContractCheckbox
            pkg={pkg}
            checked={this.contractChecked}
            onChange={this.handleTermsAccept}
          />
        )}
        <button
          className={cx("btn btn-primary", store!.styleClasses.PurchaseForm__button)}
          onClick={this.handleBuyClick}
          disabled={!canPurchase}
        >
          {this.props.children || "Complete Purchase"}
        </button>
      </>
    )
  }
}
